define("adt-wss/pods/components/dashboard/manage/location-widget/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LOADING_STATE = _exports.CANCEL_ACTION = _exports.REJECT_SWITCH_LOCATION_ACTION = _exports.REJECT_SET_DEFAULT_ACTION = _exports.REJECT_DELETE_ACTION = _exports.REJECT_EDIT_NAME_ACTION = _exports.RESOLVE_SWITCH_LOCATION_ACTION = _exports.RESOLVE_SET_DEFAULT_ACTION = _exports.RESOLVE_DELETE_ACTION = _exports.RESOLVE_EDIT_NAME_ACTION = _exports.CONFIRM_DELETE_ACTION = _exports.CONFIRM_DEFAULT_ACTION = _exports.SUBMIT_EDIT_NAME_ACTION = _exports.SWITCH_LOCATION_ACTION = _exports.SET_DEFAULT_ACTION = _exports.START_DELETE_ACTION = _exports.START_EDIT_NAME_ACTION = _exports.SUBMITTING_SWITCH_LOCATION_STATE = _exports.SUBMITTING_DEFAULT_STATE = _exports.SUBMITTING_DELETE_STATE = _exports.SUBMITTING_EDIT_NAME_STATE = _exports.SET_DEFAULT_STATE = _exports.DELETE_STATE = _exports.EDIT_NAME_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const EDIT_NAME_STATE = 'EDIT_NAME_STATE';
  _exports.EDIT_NAME_STATE = EDIT_NAME_STATE;
  const DELETE_STATE = 'DELETE_STATE';
  _exports.DELETE_STATE = DELETE_STATE;
  const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE';
  _exports.SET_DEFAULT_STATE = SET_DEFAULT_STATE;
  const SUBMITTING_EDIT_NAME_STATE = 'SUBMITTING_EDIT_NAME_STATE';
  _exports.SUBMITTING_EDIT_NAME_STATE = SUBMITTING_EDIT_NAME_STATE;
  const SUBMITTING_DELETE_STATE = 'SUBMITTING_DELETE_STATE';
  _exports.SUBMITTING_DELETE_STATE = SUBMITTING_DELETE_STATE;
  const SUBMITTING_DEFAULT_STATE = 'SUBMITTING_DEFAULT_STATE';
  _exports.SUBMITTING_DEFAULT_STATE = SUBMITTING_DEFAULT_STATE;
  const SUBMITTING_SWITCH_LOCATION_STATE = 'SUBMITTING_SWITCH_LOCATION_STATE';
  _exports.SUBMITTING_SWITCH_LOCATION_STATE = SUBMITTING_SWITCH_LOCATION_STATE;
  const START_EDIT_NAME_ACTION = 'START_EDIT_NAME_ACTION';
  _exports.START_EDIT_NAME_ACTION = START_EDIT_NAME_ACTION;
  const START_DELETE_ACTION = 'START_DELETE_ACTION';
  _exports.START_DELETE_ACTION = START_DELETE_ACTION;
  const SET_DEFAULT_ACTION = 'SET_DEFAULT_ACTION';
  _exports.SET_DEFAULT_ACTION = SET_DEFAULT_ACTION;
  const SWITCH_LOCATION_ACTION = 'SWITCH_LOCATION_ACTION';
  _exports.SWITCH_LOCATION_ACTION = SWITCH_LOCATION_ACTION;
  const SUBMIT_EDIT_NAME_ACTION = 'SUBMIT_EDIT_NAME_ACTION';
  _exports.SUBMIT_EDIT_NAME_ACTION = SUBMIT_EDIT_NAME_ACTION;
  const CONFIRM_DEFAULT_ACTION = 'CONFIRM_DEFAULT_ACTION';
  _exports.CONFIRM_DEFAULT_ACTION = CONFIRM_DEFAULT_ACTION;
  const CONFIRM_DELETE_ACTION = 'CONFIRM_DELETE_ACTION';
  _exports.CONFIRM_DELETE_ACTION = CONFIRM_DELETE_ACTION;
  const RESOLVE_EDIT_NAME_ACTION = 'RESOLVE_EDIT_NAME_ACTION';
  _exports.RESOLVE_EDIT_NAME_ACTION = RESOLVE_EDIT_NAME_ACTION;
  const RESOLVE_DELETE_ACTION = 'RESOLVE_DELETE_ACTION';
  _exports.RESOLVE_DELETE_ACTION = RESOLVE_DELETE_ACTION;
  const RESOLVE_SET_DEFAULT_ACTION = 'RESOLVE_SET_DEFAULT_ACTION';
  _exports.RESOLVE_SET_DEFAULT_ACTION = RESOLVE_SET_DEFAULT_ACTION;
  const RESOLVE_SWITCH_LOCATION_ACTION = 'RESOLVE_SWITCH_LOCATION_ACTION';
  _exports.RESOLVE_SWITCH_LOCATION_ACTION = RESOLVE_SWITCH_LOCATION_ACTION;
  const REJECT_EDIT_NAME_ACTION = 'REJECT_EDIT_NAME_ACTION';
  _exports.REJECT_EDIT_NAME_ACTION = REJECT_EDIT_NAME_ACTION;
  const REJECT_DELETE_ACTION = 'REJECT_DELETE_ACTION';
  _exports.REJECT_DELETE_ACTION = REJECT_DELETE_ACTION;
  const REJECT_SET_DEFAULT_ACTION = 'REJECT_SET_DEFAULT_ACTION';
  _exports.REJECT_SET_DEFAULT_ACTION = REJECT_SET_DEFAULT_ACTION;
  const REJECT_SWITCH_LOCATION_ACTION = 'REJECT_SWITCH_LOCATION_ACTION';
  _exports.REJECT_SWITCH_LOCATION_ACTION = REJECT_SWITCH_LOCATION_ACTION;
  const CANCEL_ACTION = 'CANCEL_ACTION';
  _exports.CANCEL_ACTION = CANCEL_ACTION;
  const LOADING_STATE = 'LOADING_STATE';
  _exports.LOADING_STATE = LOADING_STATE;
});