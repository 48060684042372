define("adt-wss/services/impersonation-data", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/http-resources", "adt-wss-common/utils/route-helpers"], function (_exports, _myadtHelpers, _httpResources, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const isFromDashboard = !!sessionStorage.getItem('csrCurrentCustomer');
  /**
   * Agent and account data for impersonation
   * @module Services/ImpersonationData
   * @prop {Boolean} isCsr @observes {@link module:Services/AuthInfo Services/AuthInfo.authInfo.csrUser}
   * @prop {Object} headerInfo
   * @prop {Object[]} picList
   * @prop {Object[]} registeredUsers
   * @prop {Boolean} hasDeclinedRegistration
   * @prop {Boolean} isNonRegisteredUser
   * @prop {Boolean} isFromDashboard
   * @prop {Timestamp} startTime
   */

  var _default = Ember.Service.extend({
    apiService: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    mmbIncidents: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    isCsr: Ember.computed.readOnly('authInfo.authInfo.csrUser'),
    csrHeader: null,
    headerInfo: Ember.computed.readOnly('csrHeader'),
    picList: null,
    registeredUsers: null,
    hasDeclinedRegistration: false,
    startTime: null,
    isNonRegisteredUser: Ember.computed.readOnly('csrHeader.csr.nonregisteredUser'),
    isFromDashboard,

    /**
     * Runs {@linkcode module:Services/ImpersonationData#getCsrHeader getCsrHeader} and
     * {@linkcode module:Services/ImpersonationData#getPicList getPicList}
     */
    populateAll() {
      return Ember.RSVP.hashSettled({
        headerInfo: this.getCsrHeaderInfo(),
        picList: this.getPicList(),
        assocEmails: this.getAssociatedEmails(),
        startTime: this.set('startTime', Date.now())
      }).then(_routeHelpers.useResolvedOnly);
    },

    setAssociatedEmails(data) {
      let list;

      if (Array.isArray(data)) {
        list = data.map((item, i) => {
          item.isSelected = i === 0;
          return item;
        });
      }

      return this.set('registeredUsers', list && list.length ? list : null);
    },

    /**
     * Retrieves data intended for the impersonation header.
     * Populates `headerInfo`, checks `sessionStorage.csrCurrentCustomer` to
     * determine `isFromDashboard`.
     * Unsets `sessionStorage.isLocationSelected` on reject
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getCsrHeaderInfo() {
      this.set('csrHeader', null);
      return this.get('apiService').request({
        resource: _httpResources.combined.getCsrHeader
      }).then(({
        data
      }) => this.set('csrHeader', data));
    },

    /**
     * Retrieves list of associated PICs
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getPicList() {
      this.set('picList', null);
      return this.get('apiService').request({
        resource: _httpResources.combined.getPicList
      }).then(({
        data
      }) => this.set('picList', data));
    },

    /**
     * Retrieves list of associted email addresses and indicates
     * if they've been registerd to MyADT
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getAssociatedEmails() {
      this.set('registeredUsers', null);
      return this.get('apiService').request({
        resource: _httpResources.combined.getAssociatedEmails
      }).then(({
        data
      }) => this.setAssociatedEmails(data));
    },

    /* === POSTS === */

    /**
     * Registers new user from impersonation
     * @instance
     *
     * @type {ServiceCall}
     * @param {Object} data
     * @param {Boolean} data.isRegistered
     * @param {Boolean} data.hasEmail
     * @param {Boolean} data.requestingRegistration
     * @param {String} data.email
     * @param {String[]} data.contactPreferences
     * @param {Boolean} data.registerMyADT
     * @return {ApiResponse}
     */
    registerAndEnroll(data) {
      return this.get('apiService').request({
        resource: _httpResources.combined.registerAndEnroll,
        data
      }).then(() => this.getAssociatedEmails());
    },

    /**
     * Declines registration and sets `hasDeclinedRegistration`
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    declineRegistration() {
      this.set('hasDeclinedRegistration', false);
      return this.get('apiService').request({
        resource: _httpResources.combined.declineRegistration
      }).then(() => this.set('hasDeclinedRegistration', true));
    },

    /**
     * Sets `hasDeclinedRegistration` from API
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    checkDeclinedRegistration() {
      this.set('hasDeclinedRegistration', false);
      return this.get('apiService').request({
        resource: _httpResources.combined.hasDeclinedRegistration
      }).then(({
        data
      }) => data);
    },

    /**
     * Сreate incident or issue
     * @instance
     *
     * @type {ServiceCall}
     * @param {String} transactionType
     * @param {String} comment
     * @return {Object}
     */
    createIncidentOrIssue(transactionType, comment) {
      return this.get('mmbIncidents').createIncidentOrIssue({
        customerNo: this.get('headerInfo.customer.custNo') || 0,
        siteNo: this.get('headerInfo.site.siteNo') || 0,
        duration: Date.now() - this.get('startTime'),
        transactionType,
        jobComments: comment
      }, true);
    },

    /**
     * Removes current user account.
     * Creates MMB Incident/Issue on resolve
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    removeWebUser() {
      const transactionType = 'MADREG';
      const jobComments = 'Deleted user\'s MyADT registration';
      return this.get('apiService').request({
        resource: _httpResources.combined.removeWebUser
      }).then(() => this.createIncidentOrIssue(transactionType, jobComments));
    },

    /**
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    endImpersonation() {
      const emailImpersonated = sessionStorage.getItem('emailImpersonated') === 'true';
      const emailImpersonatedInCSRCustomerDashboard = sessionStorage.getItem('emailImpersonatedInCSRCustomerDashboard') === 'true';
      const data = {
        emailImpersonated,
        emailImpersonatedInCSRCustomerDashboard
      };
      return this.get('apiService').combined.endImpersonation(data).then(() => data);
    },

    /**
     * @instance
     *
     * @type {ServiceCall}
     * @param {Object} requestData
     * @param {String} requestData.credential email address
     *
     * @return {ApiResponse}
     */
    impersonateRegisteredUser(requestData) {
      return this.endImpersonation().then(() => this.get('apiService').request({
        resource: _httpResources.combined.impersonateByEmail,
        data: requestData,
        redirectToLandingPage: false
      })).then(() => this.get('currentLocation').getAccountInfo()).then(() => {
        const {
          csrTeam,
          csrUsername
        } = this.getProperties('csrTeam', 'csrUsername');
        return _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'CSR IMPERSONATION', "CSR Team: ".concat(csrTeam), "CSR Username: ".concat(csrUsername)]);
      }).then(() => this.getCsrHeaderInfo()).then(() => this.getPicList());
    }

  });

  _exports.default = _default;
});