define("adt-wss/pods/components/index/mobile-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    showMobileNav: false,
    showAccountLinks: false,
    showAlarmLinks: false,
    showOrderLinks: false,
    showShopLinks: false,
    isAuthenticated: Ember.computed.readOnly('session.isAuthenticated'),
    resetMenu: Ember.observer('showMobileNav', 'isAuthenticated', function () {
      this.setProperties({
        showAccountLinks: false,
        showAlarmLinks: false,
        showOrderLinks: false,
        showShopLinks: false
      });
    }),
    actions: {
      invalidate() {
        this.get('session').invalidate(false, {
          message: 'invalidate from mobile-header'
        });
      },

      toggle(key) {
        this.toggleProperty(key);
      }

    }
  });

  _exports.default = _default;
});