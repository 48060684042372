define("adt-wss/pods/components/index/main-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xee0AcN/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"mainNavHeader\"],[11,\"class\",[29,[\"hide-mobile \",[28,\"if\",[[24,[\"isContractMode\"]],\"contract-mode\"],null]]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"limit-wrapper flex justify-start\"],[8],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"index\"]],{\"statements\":[[7,\"img\",true],[10,\"alt\",\"ADT logo\"],[11,\"src\",[29,[[22,\"rootURL\"],\"images/v2/logos/adt-logo.svg\"]]],[10,\"class\",\"adt-logo\"],[8],[9]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"nav\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"dashboard.index\"]],{\"statements\":[[0,\"MyADT Dashboard\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"dashboard.account\"]],{\"statements\":[[0,\"Account\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"dashboard.alarm\"]],{\"statements\":[[0,\"Alarm System\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"dashboard.manage\"]],{\"statements\":[[0,\"Locations\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"dashboard.shop\"]],{\"statements\":[[0,\"Order\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"help\"]],{\"statements\":[[0,\"Help\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"support-number\"],[8],[0,\"Support (800) 238-2727\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/index/main-nav/template.hbs"
    }
  });

  _exports.default = _default;
});