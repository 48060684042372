define("adt-wss/pods/components/dashboard/contract-renewal-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ba42+jTx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isTemplate1\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"indicator\"],[8],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"promo-alert\"],[8],[0,\"\\n\\t\\t\"],[7,\"h5\",true],[8],[0,\"To thank you for your loyalty, we would like to present you with a special discount of $\"],[1,[24,[\"content\",\"creditAmount\"]],false],[0,\"!\"],[9],[0,\"\\n\\t\\tPlease call \"],[1,[24,[\"content\",\"careHelplineNumber\"]],false],[0,\" to have your credit applied to your account.\\n\\t\\t\"],[7,\"i\",false],[12,\"class\",\"close-icon\"],[3,\"action\",[[23,0,[]],\"onCloseHandler\"]],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isTemplate2\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"indicator\"],[8],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"promo-alert\"],[8],[0,\"\\n\\t\\t\"],[7,\"h5\",true],[8],[0,\"To thank you for your loyalty, we would like to present you with a special discount of $\"],[1,[24,[\"content\",\"creditAmount\"]],false],[0,\"!\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"buttons\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"button button-blue-outline\"],[3,\"action\",[[23,0,[]],\"remindMeLaterClickHandler\"]],[8],[0,\"Remind Me Later\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"button button-blue\"],[3,\"action\",[[23,0,[]],\"learnMoreClickHandler\"]],[8],[0,\"Learn More\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"i\",false],[12,\"class\",\"close-icon\"],[3,\"action\",[[23,0,[]],\"onCloseHandler\"]],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/contract-renewal-alert/template.hbs"
    }
  });

  _exports.default = _default;
});