define("adt-wss/pods/components/three-g/forced-self-schedule/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.actions = _exports.states = void 0;
  const states = {
    IDLE: 'IDLE',
    WELCOME: 'WELCOME',
    INELIGIBLE: 'INELIGIBLE',
    INELIGIBLE_CHAT: 'INELIGIBLE_CHAT',
    OTHER_ISSUES_Q: 'OTHER_ISSUES_Q',
    COVID_Q: 'COVID_Q',
    GETTING_CUSTOMER_DATA: 'GETTING_CUSTOMER_DATA',
    GETTING_TIME_BLOCKS: 'GETTING_TIME_BLOCKS',
    CALENDAR: 'CALENDAR',
    TIME_SLOTS: 'TIME_SLOTS',
    SCHEDULING_APPT: 'SCHEDULING_APPT',
    SUCCESS: 'SUCCESS'
  };
  _exports.states = states;
  const actions = {
    GET_CUSTOMER_DATA: 'GET_CUSTOMER_DATA',
    VOID_ELIGIBILITY: 'VOID_ELIGIBILITY',
    CONFIRM_ELIGIBILITY: 'CONFIRM_ELIGIBILITY',
    SELECT_DATE: 'SELECT_DATE',
    SCHEDULE_APPT: 'SCHEDULE_APPT',
    RETURN_TO_CALENDAR: 'RETURN_TO_CALENDAR',
    CHAT: 'CHAT',
    CLOSE: 'CLOSE',
    CREATE_JOB: 'CREATE_JOB',
    GET_TIME_BLOCKS: 'GET_TIME_BLOCKS',
    RESOLVE_GET_CUSTOMER_DATA: 'RESOLVE_GET_CUSTOMER_DATA',
    RESOLVE_GET_TIME_BLOCKS: 'RESOLVE_GET_TIME_BLOCKS',
    RESOLVE_SCHEDULE: 'RESOLVE_SCHEDULE',
    CANCEL_WITHOUT_APPT: 'CANCEL_WITHOUT_APPT',
    REJECT: 'REJECT'
  };
  _exports.actions = actions;
});