define("adt-wss/pods/dashboard/account/route", ["exports", "adt-wss-common/utils/route-helpers"], function (_exports, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentLocation: Ember.inject.service(),
    billingData: Ember.inject.service(),
    contactData: Ember.inject.service(),
    statusMessages: null,
    hashSettled: Ember.RSVP.hashSettled,
    useResolvedOnly: _routeHelpers.useResolvedOnly,
    makeDeliverData: _routeHelpers.makeDeliverData,

    model() {
      const billingDeliverData = this.makeDeliverData(this, 'billingData');
      const contactDeliverData = this.makeDeliverData(this, 'contactData');
      let enrolledInEasyPay = null;
      let systemDownStatus = null;
      return this.hashSettled({
        permissions: this.get('currentLocation.permissions'),
        paperlessVisible: billingDeliverData('billingSystemDownStatus', billingData => billingData.checkBillingSystemDown()).then(status => {
          systemDownStatus = status;
          return billingDeliverData('billingModel.billingOptions.enrolledInEasyPay', billingData => billingData.getBillingOptions());
        }).then(enrollment => {
          enrolledInEasyPay = enrollment;
          return contactDeliverData('pmocData', contactData => contactData.getPMOC());
        }).then(({
          primary,
          billing
        }) => {
          const p = primary[0] || {};
          const b = billing[0] || {};
          const email = p.email || b.email;
          return email && [systemDownStatus, enrolledInEasyPay].every(c => !c);
        }),
        phoneConsents: contactDeliverData('phoneConsents', contactData => contactData.getCustomerPhoneConsents()),
        sharingPreferenceData: contactDeliverData('sharingPreferenceData', contactData => contactData.getSharingPreferenceData())
      }).then(this.useResolvedOnly);
    }

  });

  _exports.default = _default;
});