define("adt-wss/components/widget/paginated-list", ["exports", "adt-wss-common/components/widget/paginated-list"], function (_exports, _paginatedList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _paginatedList.default;
    }
  });
});