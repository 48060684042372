define("adt-wss/pods/dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c31/CflW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"dashboardWrapper\"],[8],[0,\"\\n\\t\"],[1,[28,\"navbar-header\",null,[[\"toggleSidebar\",\"logout\"],[[28,\"action\",[[23,0,[]],\"dispatch\",\"TOGGLE_SIDEBAR_ACTION\"],null],[28,\"action\",[[23,0,[]],\"dispatch\",\"SIGN_OUT_ACTION\"],null]]]],false],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"dashboard-content\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"dashboard/side-bar\",null,[[\"opened\",\"close\",\"logout\"],[[24,[\"sidebarOpened\"]],[28,\"action\",[[23,0,[]],\"dispatch\",\"CLOSE_SIDEBAR_ACTION\"],null],[28,\"action\",[[23,0,[]],\"dispatch\",\"SIGN_OUT_ACTION\"],null]]]],false],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\\t\\t\"],[1,[22,\"index/home-footer\"],false],[0,\"\\n\\t\\t\\t\"],[1,[22,\"index/site-footer\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"three-g/forced-self-schedule\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/template.hbs"
    }
  });

  _exports.default = _default;
});