define("adt-wss/instance-initializers/root-url", ["exports", "adt-wss/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.Component.reopen({
      rootURL: _environment.default.rootURL,
      aemHostUrl: _environment.default.aemHostUrl,
      crimeHostUrl: _environment.default.crimeHostUrl,
      hpfHostUrl: _environment.default.hpfHostUrl,
      hostedSecureID: _environment.default.hostedSecureID
    });
    Ember.Controller.reopen({
      rootURL: _environment.default.rootURL,
      aemHostUrl: _environment.default.aemHostUrl,
      crimeHostUrl: _environment.default.crimeHostUrl,
      hpfHostUrl: _environment.default.hpfHostUrl,
      hostedSecureID: _environment.default.hostedSecureID
    });
    Ember.Route.reopen({
      rootURL: _environment.default.rootURL,
      aemHostUrl: _environment.default.aemHostUrl,
      crimeHostUrl: _environment.default.crimeHostUrl,
      hpfHostUrl: _environment.default.hpfHostUrl,
      hostedSecureID: _environment.default.hostedSecureID
    });
  }

  var _default = {
    name: 'rootURL',
    initialize
  };
  _exports.default = _default;
});