define("adt-wss/pods/dashboard/account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RUyXbCsZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"section-title\"],[8],[7,\"span\",true],[8],[0,\"Account\"],[9],[7,\"span\",true],[10,\"class\",\"orange-line\"],[8],[9],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"alertShow\"]]],null,{\"statements\":[[1,[22,\"dashboard/account/one-time-payment/payment-messages\"],false]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showEasyPayPromoAlert\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"dashboard/account/easypay-enroll/payment-messages\",null,[[\"promotion\",\"onClose\"],[[24,[\"easyPayPromotion\"]],[28,\"action\",[[23,0,[]],\"closoEasyPayPromo\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[28,\"widget/status-messages\",null,[[\"messages\"],[[24,[\"dashboardStatusMessages\"]]]]],false],[0,\"\\n\"],[7,\"section\",true],[10,\"id\",\"dashboardAccount\"],[10,\"class\",\"inner-container\"],[8],[0,\"\\n\\t\"],[1,[28,\"dashboard-tabs\",null,[[\"links\"],[[24,[\"links\"]]]]],false],[0,\"\\n\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/account/template.hbs"
    }
  });

  _exports.default = _default;
});