define("adt-wss/pods/components/stupid/simple-iframe-scrolling/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xRoPY/xJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"iframe\",true],[11,\"src\",[29,[[22,\"url\"]]]],[11,\"id\",[29,[[22,\"iFrameID\"]]]],[10,\"width\",\"100%\"],[10,\"scrolling\",\"yes\"],[10,\"frameborder\",\"0\"],[8],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/stupid/simple-iframe-scrolling/template.hbs"
    }
  });

  _exports.default = _default;
});