define("adt-wss/services/event-tagging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Data that will be passed to tagCustomerEvent API
   * @module Services/eventTagging
   * @prop {String} billingSystem
   * @prop {Number} custNo
   * @prop {Number} siteNo
   * @prop {String} eventTypeId
   * @prop {String} subEventTypeId
   * @prop {String} clientType
   * @prop {String} browserTime
   */
  var _default = Ember.Service.extend({
    apiService: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    systemInformation: Ember.inject.service(),
    expresspayService: Ember.inject.service(),
    billingSystem: null,
    custNo: null,
    siteNo: null,
    eventTypeId: null,
    subEventTypeId: null,
    clientType: 'Web',
    browserTime: null,

    /**
    * Gathers required data and sends the specified event tags to the tagCustomerEvent API
     * console.log added for eventTypeId and subEventTypeId
    * @param {Object} eventInfo
     * @param {String} eventInfo.eventTypeId
     * @param {String} eventInfo.subEventTypeId
     * @param {siteRegistrations[]} siteRegistrations - will only be passed in for the registration event
     * @param {Number} siteRegistrations[].webAdress.custNo
     * @param {Number} siteRegistrations[].webAdress.siteNo
     * @param {String} siteRegistrations[].webAddress.billingSystem
    * @return {ApiResponse}
    */
    async sendEventTag(eventInfo, siteRegistrations, udf) {
      const today = new Date();
      const fullDate = today.toIntlString();
      const time = today.toTimeAmPm(false, true);
      const formatTime = "".concat(fullDate, " ").concat(time);
      let registrationCustNo = null;
      let registrationBillingSystem = null;
      let registrationSiteNo = null;

      if (siteRegistrations) {
        siteRegistrations.forEach(site => {
          if (site.webAddress.custNo) {
            registrationCustNo = site.webAddress.custNo;
            registrationBillingSystem = site.webAddress.billingSystem;
            registrationSiteNo = site.webAddress.siteNo;
          }
        });
      }

      const containsBillingSystem = registrationBillingSystem || this.get('expresspayService.billInfo.billingSystem') || this.get('currentLocation.profileInfo.billingSystem');
      const containsCustNo = registrationCustNo || Number(this.get('expresspayService.accountId')) || Number(this.get('currentLocation.profileInfo.accountId'));
      const requestData = {
        billingSystem: containsBillingSystem || null,
        custNo: containsCustNo || null,
        siteNo: registrationSiteNo || Number(this.get('systemInformation.selectedSystem.siteNo')) || null,
        eventTypeId: eventInfo.eventTypeId,
        subEventTypeId: eventInfo.subEventTypeId,
        clientType: 'Web',
        browserTime: formatTime,
        udf1: udf || null,
        udf2: eventInfo.udf2
      };
      console.log("eventTypeID: ".concat(requestData.eventTypeId), "subEventTypeId: ".concat(requestData.subEventTypeId));
      return this.get('apiService').myADT.sendEventTag(requestData);
    }

  });

  _exports.default = _default;
});