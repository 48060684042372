define("adt-wss/pods/components/dashboard/alarm/system-test/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/common-fsm-state"], function (_exports, _withFsm, _appDispatcher, _commonFsmState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.defaultProperties = void 0;
  const defaultProperties = {
    systemTestList: null
  };
  /**
   * @module Components/Dashboard/Alarm/SystemTest
   * @prop {String} elementId - ID attribute for div component wrapper
   * @prop {Array} systemTestList - contains list of system with information of its tests
   * @example
   * {@lang xml} {{ dashboard/alarm/system-test
  		systemTestList=null
  }}
   */

  _exports.defaultProperties = defaultProperties;

  var _default = Ember.Component.extend(_withFsm.default, defaultProperties, _commonFsmState.commonMixin, {
    elementId: 'dashboardSystemTest',
    systemInformation: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    actions: {
      refreshSystemTestList() {
        this.get('fsm').dispatch(_commonFsmState.FETCH_DATA_ACTION, true);
      }

    },
    systemTestList: null,
    isSplitIntegrationSystems: Ember.computed.oneWay('currentLocation.splitIntSystem'),
    splitIntsystemNoList: Ember.computed.oneWay('currentLocation.splitIntSystemNoList'),
    splitIntSystemMessage: Ember.computed.oneWay('currentLocation.splitIntSystemMessage'),

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    changeLocationHandler() {
      this.get('fsm').dispatch(_commonFsmState.FETCH_DATA_ACTION, true);
    },

    [_commonFsmState.SUCCESS_ACTION](systemInfoListFull) {
      this.set('systemTestList', systemInfoListFull);
    },

    [_commonFsmState.FAILURE_ACTION](error) {
      if (error && error.errors && error.errors[0] === 'Generic.error') {
        return this.showStatusMessage('Could not retrieve system information at this time.', 'error');
      }

      return this._super(...arguments);
    },

    [_commonFsmState.FETCH_DATA_ACTION](forceRequest) {
      return this.deliverDataAndDispatch('systemInformation.systemInfoListFull', systemInformation => systemInformation.getStandardAndSystemInfoLists(), _commonFsmState.SUCCESS_ACTION, _commonFsmState.FAILURE_ACTION, false, false, {
        forceRequest
      });
    }

  });

  _exports.default = _default;
});