define("adt-wss/pods/components/dashboard/account/enroll-paperless-statement/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D5lR2FgY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"loading-spinner\",[[24,[\"updating\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"IS_IDLE_STATE\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"paperless-statement-icon-wrap\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"paperless-transition-statements-btn\"],[3,\"action\",[[23,0,[]],\"dispatch\",[24,[\"constants\",\"TRANSITION_TO_PRE_ENROLL_ACTION\"]]]],[8],[0,\"\\n\\t\\t\\t\\tPaperless Billing Statements\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"IS_ENROLLED_STATE\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"paperless-statement-icon-wrap\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"leaf-icon\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[7,\"p\",true],[10,\"class\",\"enrolled-state-text\"],[8],[0,\"\\n\\t\\t\\t\\tYou are receiving\\n\\t\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"paperless-transition-statements-btn\"],[3,\"action\",[[23,0,[]],\"dispatch\",[24,[\"constants\",\"TRANSITION_TO_PRE_UNENROLL_ACTION\"]]]],[8],[0,\"\\n\\t\\t\\t\\t\\tpaperless statements\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"IS_PENDING_ENROLLING_STATE\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"section\",true],[10,\"class\",\"enroll-paperless-pending-state-wrap\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"paperless-statement-icon-wrap icon-top\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\",true],[10,\"class\",\"enrolled-state-text\"],[8],[0,\"\\n\\t\\t\\t\\t\\tBilling Paperless Statements\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[1,[28,\"widget/status-messages\",null,[[\"messages\"],[[24,[\"pendingStatusMessages\"]]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/account/enroll-paperless-statement/template.hbs"
    }
  });

  _exports.default = _default;
});