define("adt-wss/pods/components/dashboard/alarm/troubleshooting-links/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/fp", "adt-wss-common/utils/common-fsm-state"], function (_exports, _withFsm, _emberHelpers, _fp, _commonFsmState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TROUBLE_SHOOT_URL_KEY = 'troubleShootUrl';
  const routes = [{
    path: 'https://help.adt.com/s/',
    text: 'Search Help with Video Support',
    external: true
  }, {
    path: 'https://help.adt.com/s/article/Sensor-Battery-Replacement',
    type: 'find-sensor',
    text: 'Find a Sensor Battery Replacement',
    external: true
  }, {
    path: 'https://help.adt.com/s/article/Wi-Fi-Camera-Offline-Video-and-Troubleshooting-Instructions',
    type: 'wifi-camera',
    text: 'Troubleshooting WiFi Camera Offline',
    external: true
  }, {
    path: 'help.alpha',
    param: '116',
    type: 'trouble-codes',
    text: 'Trouble Codes',
    fromVariable: TROUBLE_SHOOT_URL_KEY,
    external: false
  }, {
    path: 'https://help.adt.com/s/article/General-Battery-Help',
    type: 'battery-information',
    text: 'Battery Information',
    external: true
  }, {
    path: 'help.alpha',
    param: '10',
    type: 'quick-guide',
    text: 'Quick Guide',
    fromVariable: 'quickGuideLink',
    external: false
  }, {
    path: 'help.alpha',
    param: '185',
    type: 'system-manual',
    text: 'System Manual',
    fromVariable: 'systemManualUrl',
    external: true
  }];

  const computeRoute = (system, root) => r => {
    if (r.fromVariable && system) {
      return (0, _fp.omix)(r, {
        fromVariable: system[r.fromVariable] || "".concat(root).concat(r.path.replace(/help\./g, 'help/'), "/").concat(r.param)
      });
    }

    return r;
  };

  const computeRoutes = (system, root, hideTroubleShootUrl) => routes.filter(s => hideTroubleShootUrl ? s.fromVariable !== TROUBLE_SHOOT_URL_KEY : true).map(computeRoute(system, root));
  /**
   * @module Components/Dashboard/Alarm/TroubleshootingLinks
   * @prop {Array} routes
   * @prop {String} routes.{i}.path - route path (url)
   * @prop {String} routes.{i}.param - route param
   * @prop {String} routes.{i}.text - text of link
   * @prop {String} routes.{i}.fromVariable - link which located in system object
   * @prop {Object} system - current system used in links
   * @prop {Boolean} hideTroubleShootUrl
   * @prop {Object} systemItem - current {i} system from systems (loop) used in links
   */


  var _default = Ember.Component.extend(_withFsm.default, _commonFsmState.commonMixin, {
    ET: Ember.inject.service('event-tagging'),
    routes: (0, _emberHelpers.computedApplyFunction)(computeRoutes, 'system', 'rootURL', 'hideTroubleShootUrl'),
    initAction: null,
    hideTroubleShootUrl: false,

    /**
     * Fetch data about currently viewed system
     * @func FETCH_DATA_ACTION
     * @return {Promise} selected system
     */
    [_commonFsmState.FETCH_DATA_ACTION]() {
      return this.deliverDataAndDispatch('systemInformation.selectedSystem', systemInformation => systemInformation.getSelectedSystem());
    },

    /**
     * Set system returned by FETCH_DATA_ACTION
     * @func SUCCESS_ACTION
     * @arg {Object} system
     */
    [_commonFsmState.SUCCESS_ACTION](system) {
      this.set('system', system);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      const {
        fsm,
        systemItem
      } = this.getProperties('fsm', 'systemItem');

      if (systemItem) {
        this.set('system', systemItem);
      } else {
        fsm.dispatch(_commonFsmState.FETCH_DATA_ACTION);
      }
    },

    actions: {
      linkClicked(type, link) {
        switch (type) {
          case 'find-sensor':
            this.get('ET').sendEventTag({
              eventTypeId: 'ASYSTRBL',
              subEventTypeId: 'SNSBATRE'
            }).then(() => {
              if (link) {
                window.location.href = link;
              }
            });
            break;

          case 'wifi-camera':
            this.get('ET').sendEventTag({
              eventTypeId: 'ASYSTRBL',
              subEventTypeId: 'TRBLWFCM'
            }).then(() => {
              if (link) {
                window.location.href = link;
              }
            });
            break;

          case 'trouble-codes':
            this.get('ET').sendEventTag({
              eventTypeId: 'ASYSTRBL',
              subEventTypeId: 'TRBLCODE'
            }).then(() => {
              if (link) {
                window.open(link, '_blank');
              }
            });
            break;

          case 'quick-guide':
            this.get('ET').sendEventTag({
              eventTypeId: 'ASYSTRBL',
              subEventTypeId: 'QGUIDE'
            }).then(() => {
              if (link) {
                window.open(link, '_blank');
              }
            });
            break;

          case 'system-manual':
            this.get('ET').sendEventTag({
              eventTypeId: 'ASYSTRBL',
              subEventTypeId: 'SYSMAN'
            }).then(() => {
              if (link) {
                window.open(link, '_blank');
              }
            });
            break;

          default:
            break;
        }
      }

    }
  });

  _exports.default = _default;
});