define("adt-wss/pods/components/dashboard/account/service-appointments-notifications/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GENERIC_ERROR_MESSAGE = _exports.REJECT_ACTION = _exports.RESOLVE_ACTION = _exports.UPDATE_EMAIL_ACTION = _exports.UPDATE_PHONE_ACTION = _exports.UPDATE_DATA_ACTION = _exports.UPDATING_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const UPDATING_STATE = 'UPDATING_STATE';
  _exports.UPDATING_STATE = UPDATING_STATE;
  const UPDATE_DATA_ACTION = 'UPDATE_DATA_ACTION';
  _exports.UPDATE_DATA_ACTION = UPDATE_DATA_ACTION;
  const UPDATE_PHONE_ACTION = 'UPDATE_PHONE_ACTION';
  _exports.UPDATE_PHONE_ACTION = UPDATE_PHONE_ACTION;
  const UPDATE_EMAIL_ACTION = 'UPDATE_EMAIL_ACTION';
  _exports.UPDATE_EMAIL_ACTION = UPDATE_EMAIL_ACTION;
  const RESOLVE_ACTION = 'RESOLVE_ACTION';
  _exports.RESOLVE_ACTION = RESOLVE_ACTION;
  const REJECT_ACTION = 'REJECT_ACTION';
  _exports.REJECT_ACTION = REJECT_ACTION;
  const GENERIC_ERROR_MESSAGE = 'Generic.error';
  _exports.GENERIC_ERROR_MESSAGE = GENERIC_ERROR_MESSAGE;
});