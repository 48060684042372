define("adt-wss/templates/modal-windows/suggest-feature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wYWXPH0F",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[0,\"\\n\\tPlease describe your idea below\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form myadt-form\"],[8],[0,\"\\n\\t\"],[1,[28,\"widget/input-wrapper\",null,[[\"label\",\"inputId\",\"inputClass\"],[\"Title\",\"featureTitle\",\"feature-title\"]]],false],[0,\"\\n\\t\"],[1,[28,\"widget/input-wrapper\",null,[[\"label\",\"inputId\",\"inputClass\"],[\"Feature description\",\"featureDescription\",\"feature-description\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"span\",true],[8],[0,\"*Your idea will appear after moderation\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/suggest-feature.hbs"
    }
  });

  _exports.default = _default;
});