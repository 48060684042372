define("adt-wss/pods/components/dashboard/preinstall-widget/due-installation/component", ["exports", "adt-wss-common/utils/route-helpers", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/fp", "adt-wss-common/utils/common-fsm-state"], function (_exports, _routeHelpers, _withFsm, _fp, _commonFsmState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    installationData: {},
    installationTotal: null
  };
  /**
   * @module Components/Dashboard/PreinstallWidget/DueInstallation
   * @prop {Object} installationData - data from API for pre-install modal window
   * @prop {string} installationTotal - data from API for pre-install total sum
   * @example
   * {@lang xml} {{ dashboard/preinstall-widget/due-installation
  		installationData: {},
  		installationTotal: null
  }}
   */

  var _default = Ember.Component.extend(defaultProps, _commonFsmState.commonMixin, _withFsm.default, {
    mwd: Ember.inject.service('modal-window-dispatcher'),
    appointmentData: Ember.inject.service(),
    billingData: Ember.inject.service(),

    [_commonFsmState.FETCH_DATA_ACTION]() {
      const appointmentList = this.get('appointmentData');
      const {
        jobNo,
        siteNo
      } = appointmentList.activeAppointments.find(el => el.preInstallJob);
      return this.deliverData('billingData.installationData', billingData => billingData.getBillingOnPreinstallData({
        jobNo,
        siteNo
      })).then((0, _routeHelpers.fsmDispatch)(_commonFsmState.SUCCESS_ACTION)(this)).catch((0, _routeHelpers.fsmDispatch)(_commonFsmState.FAILURE_ACTION)(this));
    },

    [_commonFsmState.SUCCESS_ACTION](item) {
      item = (0, _fp.omix)(item, {
        deposit: -item.deposit
      });
      this.set('installationData', item);
    },

    [_commonFsmState.FAILURE_ACTION](error) {
      console.error(error);
    },

    actions: {
      billingSummary(installationData) {
        const mwd = this.get('mwd');

        const onClose = () => {
          mwd.hide();
        };

        const callback = () => {
          this.get('router').transitionTo('dashboard.account');
          mwd.hide();
        };

        mwd.show({
          name: 'billing-summary',
          title: 'Billing Summary',
          body: {
            installationData
          },
          onClose,
          actions: [{
            caption: 'Done',
            color: 'blue',
            callback: onClose
          }]
        });
      }

    }
  });

  _exports.default = _default;
});