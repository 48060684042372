define("adt-wss/classes/web-user", ["exports", "adt-wss-common/utils/myadt-helpers"], function (_exports, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Class for {@link Services/WebUsers} property `webUsers`
   * @class
   * @name Classes/WebUser
   * @prop {Int} id
   * @prop {String} userName login email ddress
   * @prop {String} firstName
   * @prop {String} lastName
   * @prop {Boolean|Int} admin
   * @prop {String} createdByName
   * @prop {String} department
   * @prop {String} title
   * @prop {String|DateString} dateCreated
   * @prop {String|DateString} lastLogin
   * @prop {String} moreInfo
   * @prop {Int} pending 1 or 0
   * @prop {String|Int} primaryPhone
   * @prop {String|Int} secondaryPhone
   * @prop {Computed<String>} fullName
   * @prop {Computed<Date>} dateCreatedObj
   * @prop {Computed<Date>} lastLoginObj
   * @prop {Computed<String>} userComments from `moreInfo`
   * @prop {Computed<String>} primaryPhoneFormatted
   * @prop {Computed<String>} secondaryPhoneFormatted
   * @prop {Boolean} canDelete
   * @prop {Boolean} newUser
   */
  const WebUser = Ember.Object.extend({
    fullName: Ember.computed('firstName', 'lastName', function () {
      const {
        firstName,
        lastName
      } = this.getProperties('firstName', 'lastName');
      return [firstName, lastName].join(' ').trim();
    }),
    dateCreatedObj: Ember.computed('dateCreated', function () {
      const d = this.get('dateCreated');
      return d ? Date.createDate(d) : '';
    }),
    lastLoginObj: Ember.computed('lastLogin', function () {
      const d = this.get('lastLogin');
      return d ? Date.createDate(d) : '';
    }),
    primaryPhoneFormatted: Ember.computed('primaryPhone', function () {
      return _myadtHelpers.default.formatPhone(this.get('primaryPhone'), '-');
    }),
    secondaryPhoneFormatted: Ember.computed('secondaryPhone', function () {
      return _myadtHelpers.default.formatPhone(this.get('secondaryPhone'), '-');
    }),
    comments: Ember.computed.alias('moreInfo'),
    canDelete: true,
    newUser: false
  });
  var _default = WebUser;
  _exports.default = _default;
});