define("adt-wss/templates/modal-windows/system-test-wizard/duration-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qscZ2U8s",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"duration-settings-wrap\"],[8],[0,\"\\n\\t\"],[7,\"h6\",true],[10,\"class\",\"duration-settings-title\"],[8],[0,\"Set Duration for Test\"],[9],[0,\"\\n\\t\"],[1,[28,\"widget/custom-select\",null,[[\"class\",\"flat\",\"yellowArrow\",\"optionKey\",\"ariaPrefix\",\"items\",\"optValue\"],[\"system-test-wizard-duration-list\",[28,\"media\",[\"isSmartphone\"],null],\"true\",\"text\",\"Set Duration for Test\",[24,[\"body\",\"durationsList\"]],[28,\"mut\",[[24,[\"body\",\"duration\"]]],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/system-test-wizard/duration-settings.hbs"
    }
  });

  _exports.default = _default;
});