define("adt-wss/pods/components/dashboard/shop/battery-checkout/component", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss/services/battery-data", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/classes/status-message", "adt-wss-common/utils/common-fsm-state", "adt-wss/pods/components/dashboard/shop/battery-checkout/constants"], function (_exports, _emberHelpers, _batteryData, _withFsm, _myadtHelpers, _statusMessage, _commonFsmState, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fsmModel = {
    transitions: {
      [_commonFsmState.IDLE_STATE]: {
        [_constants.SUBMIT_ORDER_ACTION]: _commonFsmState.LOADING_STATE,
        [_constants.FETCH_PRICE_DATA_ACTION]: _constants.LOADING_PRICE_DATA_STATE
      },
      [_commonFsmState.LOADING_STATE]: {
        [_commonFsmState.SUCCESS_ACTION]: _constants.CONFIRMATION_STATE,
        [_commonFsmState.FAILURE_ACTION]: _commonFsmState.IDLE_STATE
      },
      [_constants.LOADING_PRICE_DATA_STATE]: {
        [_constants.SUCCESS_PRICE_DATA_ACTION]: _commonFsmState.IDLE_STATE,
        [_commonFsmState.FAILURE_ACTION]: _commonFsmState.IDLE_STATE
      }
    }
  };
  /**
   * @module Components/Dashboard/Shop/BatteryCheckout
   * Checkout form, takes a single `batteryID` as an argument
   * and processes user's order
   *
   * @prop {Int|String} batteryID
   * @prop {OrderBattery} battery computed from `batteryData.batteryList` and `batteryID`
   * @prop {String} shipping='std' | 'exp'
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    classNames: ['product-checkout-form'],
    batteryData: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    ET: Ember.inject.service('event-tagging'),
    currentLocation: Ember.inject.service(),
    systemInformation: Ember.inject.service(),
    router: Ember.inject.service(),
    fsmModel,
    orderDetails: null,
    battery: (0, _emberHelpers.computedApplyFunction)(_batteryData.setBatterySupportKey, 'orderDetails.myAdtBatteryDetails'),
    batteryID: null,
    system: null,
    profileInfo: Ember.computed.oneWay('currentLocation.profileInfo'),
    isLoading: (0, _emberHelpers.isState)(_commonFsmState.LOADING_STATE),
    isLoadingPriceData: (0, _emberHelpers.isState)(_constants.LOADING_PRICE_DATA_STATE),
    isConfirmation: (0, _emberHelpers.isState)(_constants.CONFIRMATION_STATE),
    showSummary: false,
    shipping: 'standard',
    checkShipping: Ember.observer('shipping', function () {
      const fsm = this.get('fsm');
      return fsm.dispatch(_constants.FETCH_PRICE_DATA_ACTION, undefined, true).then(data => fsm.dispatch(_constants.SUCCESS_PRICE_DATA_ACTION, data));
    }),
    usageAgreement: false,

    [_constants.FETCH_PRICE_DATA_ACTION]() {
      const {
        csNo,
        batteryId
      } = this.get('orderDetails');
      return this.get('batteryData').getBatteryOrderTaxes({
        csNo,
        batteryId,
        shippingOptionSelected: this.get('shipping')
      });
    },

    [_constants.SUBMIT_ORDER_ACTION](batteryData) {
      const {
        fsm,
        orderDetails,
        shipping
      } = this.getProperties('fsm', 'orderDetails', 'shipping');
      const {
        csNo,
        batteryId
      } = orderDetails;
      return this.get('batteryData').postBatteryOrderPlacement({
        csNo,
        batteryId,
        shippingOptionSelected: shipping
      }, orderDetails).then(data => {
        fsm.dispatch(_commonFsmState.SUCCESS_ACTION, data, true);
        const eventTagInfo = {
          eventTypeId: 'ORDBATT',
          subEventTypeId: 'ORDRCOMP'
        };
        this.get('ET').sendEventTag(eventTagInfo);
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Products', 'Battery Order', batteryData[0] + ': ' + batteryData[1]]);
      }).catch(results => fsm.dispatch(_commonFsmState.FAILURE_ACTION, results, true));
    },

    [_commonFsmState.SUCCESS_ACTION]() {
      this.get('router').transitionTo('dashboard.shop.checkout.thankyou');
    },

    [_constants.SUCCESS_PRICE_DATA_ACTION](data) {
      this.set('orderDetails', data);
    },

    [_commonFsmState.FAILURE_ACTION](results) {
      const errors = (0, _myadtHelpers.genericErrorHandler)(results);
      return this.set('statusMessages', errors.map(e => new _statusMessage.default(e, 'error')));
    },

    actions: {
      showRetailersModal(systemType, batteryName) {
        const mwd = this.get('mwd');

        const close = () => mwd.hide();

        mwd.show({
          title: 'Shop Other Retailers',
          name: 'other-retailers',
          body: this,
          onClose: close,
          actions: [{
            caption: 'Close',
            stl: 'outline',
            callback: close
          }]
        });
        const eventTagInfo = {
          eventTypeId: 'BATTMODL',
          subEventTypeId: 'RETCLICK'
        };
        this.get('ET').sendEventTag(eventTagInfo);
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Products', 'Battery Retailers Modal', 'Interested in: ' + systemType + ': ' + batteryName]);
      },

      submitOrder(batteryType, batteryName) {
        if (this.get('usageAgreement')) {
          this.get('fsm').dispatch(_constants.SUBMIT_ORDER_ACTION, [batteryType, batteryName], true);
        }
      },

      toggleSummary() {
        this.toggleProperty('showSummary');
      },

      notTheBatteryYouNeed() {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Products', 'Clicked \'Not the battery you need\'']);
        return true;
      }

    }
  });

  _exports.default = _default;
});