define("adt-wss/pods/components/dashboard/shop/yard-signs-decals/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/route-helpers", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss-common/classes/status-message", "adt-wss-common/utils/fp", "adt-wss/services/app-dispatcher", "adt-wss/pods/components/dashboard/shop/yard-signs-decals/constants"], function (_exports, _withFsm, _routeHelpers, _myadtHelpers, _emberHelpers, _statusMessage, _fp, _appDispatcher, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fsmModel = {
    transitions: {
      [_constants.IDLE_STATE]: {
        [_constants.LOAD_ORDER_INFO_ACTION]: _constants.LOADING_STATE,
        [_constants.POST_DECALS_ACTION]: _constants.LOADING_STATE,
        [_constants.POST_YARD_SIGNS_ACTION]: _constants.LOADING_STATE
      },
      [_constants.LOADING_STATE]: {
        [_constants.SUCCESS_ACTION]: _constants.IDLE_STATE,
        [_constants.SUCCESS_POST_ACTION]: _constants.IDLE_STATE,
        [_constants.FAILURE_POST_ACTION]: _constants.IDLE_STATE
      }
    }
  };
  const defaultProps = {
    addresses: null,
    orderInfo: null
  };
  /**
   * @module Components/Dashboard/Shop/YardSignsDecals
   * @prop {Array} addresses - user addresses
   * @prop {Array} orderInfo - user decals/yard signs order info
   * @example
   * {@lang xml} {{ dashboard/Shop/yard-signs-decals
  		addresses=null,
  		orderInfo=null
  	}}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    accountData: Ember.inject.service(),
    orderData: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    showOrderConfirm: false,
    dsm: Ember.inject.service('dashboard-status-messages'),
    fsmModel,
    classNames: ['decals-list'],
    yardValue: '0',
    decalsValue: '0',
    address: '',
    statusMessages: Ember.computed.alias('dsm.statusMessages'),
    currentState: _constants.IDLE_STATE,
    IS_UPDATING: (0, _emberHelpers.computedSome)('currentState', _constants.LOADING_STATE),
    initAction: _constants.LOAD_ORDER_INFO_ACTION,

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    changeLocationHandler() {
      this.get('fsm').dispatch(_constants.LOAD_ORDER_INFO_ACTION, true);
    },

    [_constants.SUCCESS_ACTION]({
      currentLocationData,
      orderInfoData
    }) {
      const addresObj = currentLocationData.address;
      const address = "".concat(addresObj.addressLine1, ", ").concat(addresObj.city, ", ").concat(addresObj.state, " ").concat(addresObj.zip);
      this.set('address', address);
      const listData = Object.keys(orderInfoData).map(key => (0, _fp.omix)({
        comboArray: orderInfoData[key].remaining === 2 ? [0, 1, 2] : [0, 1]
      }, orderInfoData[key]));
      this.set('orderInfo', listData);
    },

    [_constants.LOAD_ORDER_INFO_ACTION](forceRequest) {
      const currentLocationData = this.deliverData('accountData.accountInfo', accountData => accountData.getAccountInfo(), {
        forceRequest
      });
      const orderInfoData = this.deliverData('orderData.orderInfo', orderInfo => orderInfo.getOrderInfo(), {
        forceRequest
      });
      return Ember.RSVP.hashSettled({
        currentLocationData,
        orderInfoData
      }).then(_routeHelpers.useResolvedOnly).then((0, _routeHelpers.fsmDispatch)(_constants.SUCCESS_ACTION)(this));
    },

    [_constants.SUCCESS_POST_ACTION]() {
      this.toggleProperty('showOrderConfirm');
      this.set('statusMessages', [new _statusMessage.default('Your order has been processed.', 'success')]);
    },

    [_constants.FAILURE_POST_ACTION](response) {
      if (!response || !response.errors) return true;
      const errors = (0, _myadtHelpers.genericErrorHandler)(response);
      this.set('statusMessages', errors.map(m => new _statusMessage.default(m, 'error')));
    },

    [_constants.POST_DECALS_ACTION]() {
      const orderData = this.get('orderData');
      const decalsValue = this.get('decalsValue');
      const eventTagInfo = {
        eventTypeId: 'ORDDECL',
        subEventTypeId: 'ORDRD'
      };
      this.get('ET').sendEventTag(eventTagInfo);

      const sendAnalytics = () => (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Products', 'Ordered Decals', decalsValue]);

      return this.commonDispatcher(() => orderData.orderDecals(decalsValue).then(sendAnalytics), _constants.SUCCESS_POST_ACTION, _constants.FAILURE_POST_ACTION);
    },

    [_constants.POST_YARD_SIGNS_ACTION]() {
      const orderData = this.get('orderData');
      const yardValue = this.get('yardValue');
      const eventTagInfo = {
        eventTypeId: 'ORDYSIGN',
        subEventTypeId: 'ORDRD'
      };
      this.get('ET').sendEventTag(eventTagInfo);

      const sendAnalytics = () => (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Products', 'Ordered Yard Sign(s)', yardValue]);

      return this.commonDispatcher(() => orderData.orderYardsigns(yardValue).then(sendAnalytics), _constants.SUCCESS_POST_ACTION, _constants.FAILURE_POST_ACTION);
    }

  });

  _exports.default = _default;
});