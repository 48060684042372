define("adt-wss/pods/components/dashboard/account/contract-renewal/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.YEARS_HASH_TABLE = _exports.CONTRACT_RENEWAL_INSTRUCTIONS = _exports.CONTINUE_RENEWAL_ACTION = _exports.CLOSE_RENEWAL_MODAL_ACTION = _exports.ESCAPE_SIGN_PROCESS_ACTION = _exports.RENEW_ACTION = _exports.CLOSE_RENEWAL_OPTIONS_ACTION = _exports.SHOW_RENEWAL_OPTIONS_ACTIONS = _exports.SIGN_PROCESS_STATE = _exports.SHOW_CONTRACT_RENEWAL_MODAL_STATE = _exports.RENEWAL_OPTIONS_STATE = void 0;
  const RENEWAL_OPTIONS_STATE = 'RENEWAL_OPTIONS_STATE';
  _exports.RENEWAL_OPTIONS_STATE = RENEWAL_OPTIONS_STATE;
  const SHOW_CONTRACT_RENEWAL_MODAL_STATE = 'SHOW_CONTRACT_RENEWAL_MODAL_STATE';
  _exports.SHOW_CONTRACT_RENEWAL_MODAL_STATE = SHOW_CONTRACT_RENEWAL_MODAL_STATE;
  const SIGN_PROCESS_STATE = 'SIGN_PROCESS_STATE';
  _exports.SIGN_PROCESS_STATE = SIGN_PROCESS_STATE;
  const SHOW_RENEWAL_OPTIONS_ACTIONS = 'SHOW_RENEWAL_OPTIONS_ACTIONS';
  _exports.SHOW_RENEWAL_OPTIONS_ACTIONS = SHOW_RENEWAL_OPTIONS_ACTIONS;
  const CLOSE_RENEWAL_OPTIONS_ACTION = 'CLOSE_RENEWAL_OPTIONS_ACTION';
  _exports.CLOSE_RENEWAL_OPTIONS_ACTION = CLOSE_RENEWAL_OPTIONS_ACTION;
  const RENEW_ACTION = 'RENEW_ACTION';
  _exports.RENEW_ACTION = RENEW_ACTION;
  const ESCAPE_SIGN_PROCESS_ACTION = 'ESCAPE_SIGN_PROCESS_ACTION';
  _exports.ESCAPE_SIGN_PROCESS_ACTION = ESCAPE_SIGN_PROCESS_ACTION;
  const CLOSE_RENEWAL_MODAL_ACTION = 'CLOSE_RENEWAL_MODAL_ACTION';
  _exports.CLOSE_RENEWAL_MODAL_ACTION = CLOSE_RENEWAL_MODAL_ACTION;
  const CONTINUE_RENEWAL_ACTION = 'CONTINUE_RENEWAL_ACTION';
  _exports.CONTINUE_RENEWAL_ACTION = CONTINUE_RENEWAL_ACTION;
  const CONTRACT_RENEWAL_INSTRUCTIONS = [{
    text: 'Follow the guided DocuSign instructions on the next step to complete your renewal contract.'
  }, {
    text: "Once the contract is completed and signed,\n\t\t\t\t\t\t\t\t\tyour new contract will be emailed to you and available in your ADT account.\n\t\t\t\t\t\t\t\t\tPlease allow 24 hours for your new contract to appear under your account documents."
  }, {
    text: 'Your credit will be applied to your account within 10 days once your contract has been processed.'
  }];
  _exports.CONTRACT_RENEWAL_INSTRUCTIONS = CONTRACT_RENEWAL_INSTRUCTIONS;
  const YEARS_HASH_TABLE = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
    10: 'ten'
  };
  _exports.YEARS_HASH_TABLE = YEARS_HASH_TABLE;
});