define("adt-wss/pods/components/dashboard/account/easypay-enroll/component", ["exports", "adt-wss-common/classes/status-message", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/localization", "jquery", "adt-wss-common/utils/fp", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/common-fsm-state", "adt-wss/pods/components/dashboard/account/saved-accounts/component", "adt-wss/pods/components/dashboard/account/easypay-enroll/constants"], function (_exports, _statusMessage, _withFsm, _localization, _jquery, _fp, _myadtHelpers, _emberHelpers, _appDispatcher, _commonFsmState, _component, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.fsmModel = void 0;
  const fsmModel = {
    transitions: {
      [_commonFsmState.IDLE_STATE]: {
        [_constants.START_ENROLL_ACTION]: _constants.ENROLL_INIT_STATE,
        [_constants.CANCEL_ACTION]: _commonFsmState.IDLE_STATE
      },
      [_constants.ENROLL_INIT_STATE]: {
        [_constants.CONFIRM_START_ACTION]: _constants.ENROLL_PAYMENT_METHOD_STATE,
        [_constants.START_EDIT_EXP_ACTION]: _constants.EDIT_EXP_STATE,
        [_constants.CANCEL_ACTION]: _commonFsmState.IDLE_STATE,
        [_constants.STEP_BACK_ACTION]: _commonFsmState.IDLE_STATE
      },
      [_constants.ENROLL_PAYMENT_METHOD_STATE]: {
        [_constants.CANCEL_ACTION]: _commonFsmState.IDLE_STATE,
        [_commonFsmState.FAILURE_ACTION]: _constants.ENROLL_PAYMENT_METHOD_STATE,
        [_constants.LOADED_PAYMENT_FORM_ACTION]: _constants.ENROLL_PAYMENT_METHOD_STATE,
        [_constants.SET_PAYMENT_DETAILS_ACTION]: _constants.ENROLL_VALIDATION_STATE,
        [_constants.INITIATE_IVR_ACTION]: _commonFsmState.LOADING_STATE,
        [_constants.SET_PAYMENT_TYPE_ACTION]: _commonFsmState.LOADING_STATE,
        [_constants.STEP_BACK_ACTION]: _constants.ENROLL_INIT_STATE
      },
      [_constants.ENROLL_VALIDATION_STATE]: {
        [_constants.CANCEL_ACTION]: _commonFsmState.IDLE_STATE,
        [_commonFsmState.FAILURE_ACTION]: _constants.ENROLL_PAYMENT_METHOD_STATE,
        [_constants.VALIDATION_FAILURE_ACTION]: _constants.ENROLL_PAYMENT_METHOD_STATE,
        [_constants.STEP_BACK_ACTION]: _constants.ENROLL_PAYMENT_METHOD_STATE,
        [_constants.VALIDATE_EASYPAY_ACTION]: _commonFsmState.LOADING_STATE
      },
      [_constants.ENROLL_CONFIRMATION_STATE]: {
        [_constants.CANCEL_ACTION]: _commonFsmState.IDLE_STATE,
        [_commonFsmState.FAILURE_ACTION]: _constants.ENROLL_PAYMENT_METHOD_STATE,
        [_constants.STEP_BACK_ACTION]: _constants.ENROLL_PAYMENT_METHOD_STATE,
        [_constants.SUBMIT_ACTION]: _commonFsmState.LOADING_STATE,
        [_constants.SUBMIT_SWITCH_ACTION]: _commonFsmState.LOADING_STATE
      },
      [_constants.EDIT_EXP_STATE]: {
        [_constants.CANCEL_ACTION]: _commonFsmState.IDLE_STATE,
        [_commonFsmState.FAILURE_ACTION]: _constants.EDIT_EXP_STATE,
        [_constants.SUBMIT_EDIT_EXP_ACTION]: _commonFsmState.LOADING_STATE
      },
      [_commonFsmState.LOADING_STATE]: {
        [_constants.EDIT_SUCCESS_ACTION]: _commonFsmState.IDLE_STATE,
        [_constants.EDIT_FAILURE_ACTION]: _constants.EDIT_EXP_STATE,
        [_commonFsmState.FAILURE_ACTION]: _constants.ENROLL_CONFIRMATION_STATE,
        [_constants.LOADED_PAYMENT_FORM_ACTION]: _constants.ENROLL_PAYMENT_METHOD_STATE,
        [_commonFsmState.SUCCESS_ACTION]: _commonFsmState.IDLE_STATE,
        [_constants.VALIDATION_FAILURE_ACTION]: _constants.ENROLL_PAYMENT_METHOD_STATE,
        [_constants.VALIDATION_SUCCESS_ACTION]: _constants.ENROLL_CONFIRMATION_STATE,
        [_constants.RESOLVE_IVR_ACTION]: _constants.ENROLL_PAYMENT_METHOD_STATE,
        [_constants.REJECT_IVR_ACTION]: _constants.ENROLL_PAYMENT_METHOD_STATE
      }
    }
  };
  _exports.fsmModel = fsmModel;
  const defaultProps = {
    button: true,

    setPendingMessageVisibility() {
      console.warn('Please pass "setPendingMessageVisibility" method for "easypay-enroll" component');
    }

  };
  const acctTypeOpts = [{
    value: null,
    text: 'Please Select an Account Type'
  }, {
    value: 'C',
    text: 'Checking Account'
  }, {
    value: 'S',
    text: 'Savings Account'
  }];

  const advance = fsm => (type, manage) => {
    if (type === 'exp') {
      return fsm.dispatch(_constants.START_EDIT_EXP_ACTION);
    }

    return fsm.dispatch(_constants.CONFIRM_START_ACTION, {
      type,
      manage
    });
  };
  /** Sets payment type to 'bank' or 'card' from nav at top of form */


  const setPaymentType = fsm => type => fsm.dispatch(_constants.SET_PAYMENT_TYPE_ACTION, type);
  /** Sets account type to 'C' or 'S' from custom select in form */


  const updateAcctType = body => type => Ember.set(body, 'achDetails.accountType', type.value);
  /** Initiates IVR Flow */


  const submitAgentExtension = fsm => agentExtension => fsm.dispatch(_constants.INITIATE_IVR_ACTION, agentExtension);
  /**
   * @module Components/EasyPayEnroll
   * @prop {Boolean} isEnrolled
   * @prop {String} paymentType
   * @prop {Object} achDetails
   * @prop {Object} ccDetails
   * @prop {Object[]} accTypeOpts
   * @prop {String} acctTypeOpts.{value} 'C' or 'S'
   * @prop {String} acctTypeOpts.{text}
   * @prop {Object} fieldErrors
   * @prop {Function} setPendingMessageVisibility
   */


  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    tagName: 'a',
    classNameBindings: ['isEnrolled:button-blue:button-blue-outline', 'isEnrolled:button:angle-bracket-link'],
    href: '#',
    mwd: Ember.inject.service('modal-window-dispatcher'),
    billingData: Ember.inject.service(),
    paymentech: Ember.inject.service(),
    router: Ember.inject.service(),
    csrInfo: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    expresspayService: Ember.inject.service(),
    appDispatcher: Ember.inject.service(),
    dsm: Ember.inject.service('dashboard-status-messages'),
    ET: Ember.inject.service('event-tagging'),
    isLoading: Ember.computed.equal('currentState', _commonFsmState.LOADING_STATE),
    fsmModel,
    paymentTab: 'bank',
    paymentType: 'bank',
    achDetails: null,
    ccDetails: null,
    acctTypeOpts,
    bankAcctType: null,
    fieldErrors: null,
    isIvr: false,
    ivrInit: false,
    ivrType: null,
    agentExtension: Ember.computed.oneWay('csrInfo.csr.agentIvrContactNo'),

    init() {
      this._super(...arguments);

      this.reset(); // Hides and disables modal button when Paymentech form is visible

      this.addObserver('currentState', () => {
        if (this.get('currentState') === _constants.ENROLL_PAYMENT_METHOD_STATE && this.get('paymentTab') === 'card') {
          if (this.get('media.isSmartphone')) {
            (0, _jquery.default)('.easypay-enroll-modal').find('.button.button-blue').css({
              display: 'none'
            });
          } else {
            (0, _jquery.default)('.easypay-enroll-modal').find('.button.button-blue').css({
              zIndex: -1,
              opacity: 0
            }).attr('disabled', 'disabled');
          }
        } else if (this.get('media.isSmartphone')) {
          (0, _jquery.default)('.easypay-enroll-modal').find('.button.button-blue').css({
            display: 'unset'
          });
        } else {
          (0, _jquery.default)('.easypay-enroll-modal').find('.button.button-blue').css({
            zIndex: 0,
            opacity: 1
          }).removeAttr('disabled');
        }
      });
      const appDispatcher = this.get('appDispatcher');
      appDispatcher.subscribe(_appDispatcher.CRE_VALIDATION_ERROR_EVENT, this, _appDispatcher.CRE_VALIDATION_ERROR_EVENT);
      appDispatcher.subscribe(_appDispatcher.CRE_COMPLETE_VALIDATION_EVENT, this, _appDispatcher.CRE_COMPLETE_VALIDATION_EVENT);
      appDispatcher.subscribe(_appDispatcher.SHOW_EASYPAY_ENROLL_EVENT, this, _appDispatcher.SHOW_EASYPAY_ENROLL_EVENT);
    },

    willDestroyElement() {
      const appDispatcher = this.get('appDispatcher');
      appDispatcher.unsubscribe(_appDispatcher.CRE_VALIDATION_ERROR_EVENT, this, _appDispatcher.CRE_VALIDATION_ERROR_EVENT);
      appDispatcher.unsubscribe(_appDispatcher.CRE_COMPLETE_VALIDATION_EVENT, this, _appDispatcher.CRE_COMPLETE_VALIDATION_EVENT);
      appDispatcher.unsubscribe(_appDispatcher.SHOW_EASYPAY_ENROLL_EVENT, this, _appDispatcher.SHOW_EASYPAY_ENROLL_EVENT);
    },

    [_appDispatcher.SHOW_EASYPAY_ENROLL_EVENT]() {
      return this.get('fsm').dispatch(_constants.START_ENROLL_ACTION);
    },

    /**
     * Handles Paymentech failure
     * @method CRE_VALIDATION_ERROR_EVENT
     * @param {String[]} errors
     * Dispatches `FAILURE_ACTION`
     */
    [_appDispatcher.CRE_VALIDATION_ERROR_EVENT](errors) {
      const {
        fsm,
        mwd
      } = this.getProperties('fsm', 'mwd');
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);
      mwd.clearMessages();
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Error Messages', 'easypay-enroll', errors.map(er => (0, _localization.toExpandedString)(er)).join(' | ')]);
      return showErrors({
        errors,
        isHpf: true
      }, _commonFsmState.FAILURE_ACTION);
    },

    /**
     * Handles Paymentech success
     * Dispatches `SET_PAYMENT_DETAILS_ACTION`
     * @method CRE_COMPLETE_VALIDATION_EVENT
     * @param {Object} transaction
     * @param {String} transaction.uID
     */
    [_appDispatcher.CRE_COMPLETE_VALIDATION_EVENT](transaction) {
      const {
        uID
      } = transaction;
      const ctx = {
        uID,
        paymentType: 'card'
      };
      this.set('uIDReceived', true);
      this.set('uID', uID);
      this.get('mwd').clearMessages();
      this.get('fsm').dispatch(_constants.SET_PAYMENT_DETAILS_ACTION, ctx);
    },

    /**
     * Clears all data and modal messages
     */
    reset() {
      this.get('mwd').clearMessages();
      this.setProperties({
        achDetails: {
          routingNumber: '',
          accountNumber: '',
          accountNumber2: '',
          initials: 'WEB',
          accountType: '',
          savePayment: false,
          prepayNumber: null
        },
        ccDetails: {
          profileId: '',
          number: '',
          expirationMonth: '',
          expirationYear: '',
          billingZipCode: '',
          nameOnCard: '',
          streetAddress: '',
          savedCardType: '',
          savePayment: false,
          type: '',
          prepayNumber: null
        },
        bankAcctType: acctTypeOpts[0],
        fieldErrors: {
          accountType: false,
          routingNumber: false,
          accountNumber: false,
          accountNumber2: false,
          agentExtension: false
        }
      });
    },

    /**
     * @param {String} type 'bank'(def) | 'card' | 'appl'
     * Dispatches `LOADED_PAYMENT_FORM_ACTION`
     * @method SET_PAYMENT_TYPE_ACTION
     */
    [_constants.SET_PAYMENT_TYPE_ACTION](type) {
      const fsm = this.get('fsm');
      this.setProperties({
        paymentTab: type,
        paymentType: type === 'bank' ? 'bank' : 'card'
      });
      this.reset();
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackPageview', "easypay-enroll/step2/show".concat(type === 'bank' ? 'ACH' : 'CC')]);
      this.set('isIvr', type == 'ivr');
      this.set('ivrInit', false);
      return fsm.dispatch(_constants.LOADED_PAYMENT_FORM_ACTION, type, false);
    },

    /**
     * Opens first modal
     * Clicking the link will close it open the actual
     * enrollment modal
     * @method START_ENROLL_ACTION
     */
    [_constants.START_ENROLL_ACTION]() {
      const {
        mwd,
        fsm,
        isEnrolled,
        easypayDetails
      } = this.getProperties('mwd', 'fsm', 'isEnrolled', 'easypayDetails');

      const onClose = () => {
        fsm.dispatch(_constants.CANCEL_ACTION);
      };

      mwd.show({
        name: 'easypay-intro-modal',
        isFooterLine: false,
        title: isEnrolled ? 'Manage EasyPay' : 'ADT&reg; EasyPay',
        backButton: onClose,
        body: {
          isEnrolled,
          easypayDetails,
          advance: advance(fsm),
          terms: () => {
            onClose();
            this.get('router').transitionTo('index.usageagreement');
          },
          pendingChanges: this.get('billingData.billingModel.easypayDetails.changesMadeRecently')
        },
        onClose,
        actions: null
      });
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackPageview', 'easypay-enroll/step1']);
    },

    /**
     * Closes intro modal and opens enrollment modal
     * @method CONFIRM_START_ACTION
     * @param {String} type account type | 'bank', 'cc', 'ivr'
     * @param {Boolean} manage indicates if this is changing accounts
     */
    [_constants.CONFIRM_START_ACTION]({
      type,
      manage
    }) {
      const {
        mwd,
        fsm,
        currentLocation,
        csrInfo
      } = this.getProperties('mwd', 'fsm', 'currentLocation', 'csrInfo');

      if (type) {
        fsm.dispatch(_constants.SET_PAYMENT_TYPE_ACTION, type);
      }

      const body = this;
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);

      const hidePendingMessage = () => this.setPendingMessageVisibility(false);

      const nextStepClasses = (0, _emberHelpers.computedApplyFunction)((pt, ii) => {
        let out = ['easypay-next-step-link'];

        if (pt === 'card' && !ii && this.get('currentState') === _constants.ENROLL_PAYMENT_METHOD_STATE) {
          out = ['easypay-next-step-link cc-form'];
        }

        return out;
      }, 'paymentType', 'isIvr');

      const onClose = () => {
        this.get('fsm').dispatch(_constants.CANCEL_ACTION);
      };

      mwd.hide(); // hides intro modal

      Ember.set(body, 'setPaymentType', setPaymentType(fsm));
      Ember.set(body, 'updateAcctType', updateAcctType(this));
      Ember.set(body, 'submitAgentExtension', submitAgentExtension(fsm));
      Ember.set(body, 'stepBackAction', () => fsm.dispatch(_constants.STEP_BACK_ACTION));
      Ember.set(body, 'flowId', manage ? 'EASYPAY_SWITCH' : 'EASYPAY_NEW');
      Ember.set(body, 'accountId', currentLocation.profileInfo.accountId);
      Ember.set(body, 'isCsr', csrInfo.isCsr);
      /**
       * Uses a switch statement to check `currentState`
       * and act accordingly on "Next" button click
       * @param {Object} ctx `value` from modal context, should == `body`
       */

      const onEnter = () => {
        mwd.clearMessages();
        Ember.set(body, 'fieldErrors', {
          accountType: false,
          routingNumber: false,
          accountNumber: false,
          accountNumber2: false,
          agentExtension: false
        });
        const {
          currentState,
          ivrType
        } = this.getProperties('currentState', 'ivrType');
        const newType = this.get('paymentType');
        const confirmAction = manage ? _constants.SUBMIT_SWITCH_ACTION : _constants.SUBMIT_ACTION;
        /* eslint-disable default-case */

        switch (currentState) {
          case _constants.ENROLL_PAYMENT_METHOD_STATE:
            return fsm.dispatch(_constants.SET_PAYMENT_DETAILS_ACTION, manage).then(() => (0, _myadtHelpers.sendAnalyticEvent)(['_trackPageview', 'easypay-enroll/step3'])).then(hidePendingMessage).catch(({
              fe,
              errors
            }) => {
              if (fe) Ember.set(body, 'fieldErrors', fe);
              showErrors({
                errors
              }, _commonFsmState.FAILURE_ACTION);
            });

          case _constants.ENROLL_CONFIRMATION_STATE:
            if (body.usageAgreement) {
              return fsm.dispatch(confirmAction).then(data => {
                (0, _myadtHelpers.sendAnalyticEvent)(['_trackPageview', 'Manage FlexFi EasyPay', 'Payment Updated Successfully', "".concat(newType === 'bank' || ivrType === 'ivrBankAccount' ? 'ACH' : 'CC')]);
                return fsm.dispatch(_commonFsmState.SUCCESS_ACTION, data);
              }).then(hidePendingMessage).catch(results => {
                const pypemsg = [{
                  attribute: 'error',
                  label: 'error',
                  value: "easypay ".concat(manage ? 'modification' : 'enrollment', " error"),
                  visible: 'wpm'
                }];
                window.Pypestream('config', {
                  passthrough: JSON.stringify(pypemsg)
                });
                console.log("Pypestream msg sent: easypay ".concat(manage ? 'modification' : 'enrollment', " error"));
                (0, _myadtHelpers.sendAnalyticEvent)(['_trackPageview', 'Manage FlexFi EasyPay', 'Payment Updated Failed', "".concat(newType === 'bank' || ivrType === 'ivrBankAccount' ? 'ACH' : 'CC')]);
                return showErrors(results, _commonFsmState.FAILURE_ACTION);
              });
            }

            return showErrors({
              errors: 'Please accept the site usage agreement'
            });
        }
        /* eslint-enable default-case */

      };

      mwd.show({
        name: 'easypay-enroll-modal',
        title: (0, _emberHelpers.computedApplyFunction)(cs => {
          const mobile = this.get('media.isSmartphone');
          if (manage) return 'Manage EasyPay';
          if (mobile) return 'ADT EasyPay&reg;';
          if (cs === _constants.ENROLL_CONFIRMATION_STATE && mobile) return 'Confirm Payment Details';
          return 'Add a Payment Method';
        }, 'currentState'),
        body,
        backButton: () => body.stepBackAction(body.currentState, false),
        isFooterLine: true,
        disclaimer: 'Your payment information is encrypted',
        onClose,
        onEnter,
        statusMessages: [],
        actions: [{
          caption: (0, _emberHelpers.computedApplyFunction)(cs => cs === _constants.ENROLL_CONFIRMATION_STATE ? 'Finish' : 'Next', 'currentState'),
          classes: nextStepClasses,
          disabled: (0, _emberHelpers.computedApplyFunction)((isIvr, ivrInit) => isIvr && !ivrInit, 'isIvr', 'ivrInit'),
          callback: onEnter
        }]
      });
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackPageview', 'easypay-enroll/step2']);
    },

    /**
     * Validates the payment form through FE or receives from Paymentech,
     * then runs EasyPay validation
     * Dispatches `VALIDATE_EASYPAY_ACTION` on success or if `paymentType !== 'card'`
     * @method SET_PAYMENT_DETAILS_ACTION
     */
    [_constants.SET_PAYMENT_DETAILS_ACTION](manage) {
      const {
        paymentType,
        achDetails,
        ccDetails,
        ivrType,
        fsm
      } = this.getProperties('paymentType', 'achDetails', 'ccDetails', 'ivrType', 'fsm');

      if (paymentType === 'bank') {
        return this.validatePaymentDetails(achDetails).then(() => this.setProperties({
          achDetails,
          ccDetails: null
        })).then(() => fsm.dispatch(_constants.VALIDATE_EASYPAY_ACTION, manage));
      }

      if (this.get('isIvr')) {
        return this.get('billingData').getPayConfDetails_v2({
          flowId: manage ? 'EASYPAY_SWITCH' : 'EASYPAY_NEW',
          paymentType: ivrType || 'ivrPayment'
        }).then(data => {
          this.setProperties({
            ccDetails: {
              cardType: data.type,
              number: "******".concat(data.lastFourPaymentNo)
            },
            achDetails: null,
            ivrType: data.paymentType
          });
        }).then(() => fsm.dispatch(_constants.VALIDATE_EASYPAY_ACTION)).catch(results => fsm.dispatch(_constants.VALIDATION_FAILURE_ACTION, results));
      }

      this.setProperties({
        ccDetails,
        achDetails: null
      });
      return fsm.dispatch(_constants.VALIDATE_EASYPAY_ACTION);
    },

    /**
     * Submits agent extension for IVR payment process
     *
     * @function
     */
    [_constants.INITIATE_IVR_ACTION](agentExtension) {
      this.get('mwd').clearMessages();
      return this.billingData.initIvrPayment({
        agentIVRContactNo: agentExtension,
        paymentType: this.get('ivrType') || 'ivrPayment'
      }).then(() => this.get('fsm').dispatch(_constants.RESOLVE_IVR_ACTION)).catch(results => this.get('fsm').dispatch(_constants.REJECT_IVR_ACTION, results));
    },

    /**
     * If MyADT accepts IVR details, advances
     * @method RESOLVE_IVR_ACTION
     */
    [_constants.RESOLVE_IVR_ACTION]() {
      return this.set('ivrInit', true);
    },

    /**
     * If MyADT rejects IVR details, shows errors in modal
     * @method REJECT_IVR_ACTION
     * @param {Object} results
     */
    [_constants.REJECT_IVR_ACTION](results) {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);
      showErrors(results);
    },

    /**
     * Sends data to EasyPay validation
     * blocks advancement on reject
     * @method VALIDATE_EASYPAY_ACTION
     * @param {Boolean} manage indicates an existing EasyPay enrollment
     */
    [_constants.VALIDATE_EASYPAY_ACTION](manage) {
      const {
        achDetails,
        ccDetails,
        billingData,
        paymentType,
        fsm,
        easypayDetails
      } = this.getProperties('achDetails', 'ccDetails', 'billingData', 'paymentType', 'fsm', 'easypayDetails');

      if (paymentType === 'bank') {
        const easyPayData = {
          achDetails,
          ccDetails,
          paymentType,
          easypayDetails
        };
        const action = manage ? 'validateSwitchEasypayBank' : 'validateEasypay';
        return billingData[action](easyPayData).then(data => fsm.dispatch(_constants.VALIDATION_SUCCESS_ACTION, data)).catch(results => fsm.dispatch(_constants.VALIDATION_FAILURE_ACTION, results));
      }

      return fsm.dispatch(_constants.VALIDATION_SUCCESS_ACTION);
    },

    /**
     * If MyADT rejects payment details, shows errors in modal
     * @method VALIDATION_FAILURE_ACTION
     * @param {Object} results
     */
    [_constants.VALIDATION_FAILURE_ACTION](results) {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);
      showErrors(results);
    },

    /**
     * Submits validated payment info for enrollment
     * @method SUBMIT_ACTION
     */
    [_constants.SUBMIT_ACTION]() {
      const {
        achDetails,
        ccDetails,
        uID,
        billingData,
        paymentTab,
        ivrType,
        easypayDetails
      } = this.getProperties('achDetails', 'ccDetails', 'uID', 'billingData', 'paymentTab', 'ivrType', 'easypayDetails');
      const easyPayData = {
        achDetails,
        profileId: ccDetails ? ccDetails.profileId : '',
        uID,
        paymentType: paymentTab === 'ivr' ? ivrType : paymentTab,
        easypayDetails
      };
      return billingData.enrollEasypay(easyPayData);
    },

    [_constants.SUBMIT_SWITCH_ACTION]() {
      const {
        achDetails,
        ccDetails,
        uID,
        billingData,
        paymentTab,
        easypayDetails,
        ivrType
      } = this.getProperties('achDetails', 'ccDetails', 'uID', 'billingData', 'paymentTab', 'easypayDetails', 'ivrType');
      const achDetailsUpdated = (0, _fp.omix)(achDetails, {
        prepayNumber: easypayDetails.prepayNumber
      });
      const easyPayData = {
        achDetails: achDetailsUpdated,
        profileId: ccDetails ? ccDetails.profileId : '',
        uID,
        paymentType: paymentTab === 'ivr' ? ivrType : paymentTab,
        easypayDetails
      };
      const action = paymentTab === 'bank' || ivrType === 'ivrBankAccount' ? 'switchEasypayBank' : 'switchEasypayCard';
      return billingData[action](easyPayData);
    },

    /**
     * Handles successful enrollment
     * @method SUCCESS_ACTION
     * @param {Object} data
     * @param {String} data.confirmationNumber
     * @param {String} data.bankName
     * @param {String} data.notificationMessage
     * @param {String} data.pendingPaymentAmt
     * @param {String} data.balanceDueAmt
     * @param {String} data.newChargesAmt
     * @param {Boolean} data.balanceDue
     * @param {Boolean} data.paymentMadeToday
     * @param {Boolean} data.ccpayment
     */
    [_commonFsmState.SUCCESS_ACTION](data) {
      const {
        mwd,
        csrInfo
      } = this.getProperties('mwd', 'csrInfo');
      const type = this.get('paymentType') === 'bank' ? 'ACH' : 'CC';
      const successMsg = {
        head: 'EasyPay payment method successfully updated.',
        text: '<p>Automatic payments won\'t begin until your next billing cycle.</p>'
      };
      let msgType = 'success';

      if (csrInfo.isCsr) {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', "CSR ".concat(type, " Easypay Enrollment"), "CSR Team:".concat(csrInfo.csr.team), "CSR Username:".concat(csrInfo.csr.username)]);
      }

      if (data) {
        if (data.confirmationNumber) {
          successMsg.head = "You successfully enrolled in EasyPay. Transaction Number: ".concat(data.confirmationNumber);
        }

        if (data.ccpayment) {
          (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Easypay Enrollment', 'Enrollment Complete', 'Credit Card']);
        }

        if (data.notificationMessage) successMsg.text += "<p class=\"sub-text\">".concat(data.notificationMessage, "</p>");
        if (data.balanceDue) msgType = 'warn';
      }

      const expressPromo = this.get('expresspayService.expressPromo');

      if (expressPromo) {
        this.get('ET').sendEventTag({
          eventTypeId: 'EZPAYPRO',
          subEventTypeId: 'RTTOCHUD',
          udf2: 'EX_PAY'
        }, false, '1');
      }

      this.get('appDispatcher').sendEvent(_appDispatcher.EASYPAY_ENROLL_EVENT, null, this);
      this.reset();
      this.set('dsm.statusMessages', [new _statusMessage.default(successMsg, msgType)]);
      mwd.hide();
    },

    /**
     * @method CANCEL_ACTION
     */
    [_constants.CANCEL_ACTION]() {
      const router = this.get('router');
      this.reset();
      this.get('mwd').hide();

      if (router.currentRouteName === 'dashboard.account.payments.manageeasypay') {
        router.transitionTo('dashboard.account.payments.index');
      }
    },

    /**
     * Opens modal to edit CC expiration date
     */
    [_constants.START_EDIT_EXP_ACTION]() {
      const {
        mwd,
        fsm,
        easypayDetails
      } = this.getProperties('mwd', 'fsm', 'easypayDetails');
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);

      const onClose = () => {
        this.get('fsm').dispatch(_constants.CANCEL_ACTION);
        mwd.hide();
      };

      const exp = easypayDetails.ccExpDate.split('/');
      const body = {
        isLoading: false,
        yearOpts: _component.yearOpts,
        monthOpts: _component.monthOpts,
        yearError: false,
        monthError: false,
        expMonth: exp[0],
        expYear: exp[1]
      };
      /**
       * Completes upate action
       * dispatches success/failure
       */

      const callback = () => {
        const {
          expMonth,
          expYear
        } = body;
        mwd.clearMessages();
        Ember.set(body, 'isLoading', true);
        return fsm.dispatch(_constants.SUBMIT_EDIT_EXP_ACTION, {
          expMonth,
          expYear
        }).then(data => fsm.dispatch(_constants.EDIT_SUCCESS_ACTION, data)).then(this.setPendingMessageVisibility(false)).catch(results => {
          showErrors(results, _constants.EDIT_FAILURE_ACTION);
          return Ember.set(body, 'isLoading', false);
        });
      };

      mwd.show({
        name: 'edit-saved-account',
        title: 'Edit Expiration Date',
        body,
        onClose,
        statusMessages: [],
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: 'Update',
          callback
        }]
      });
    },

    /**
     * @param {Object} exp
     * @param {String} exp.expMonth
     * @param {String} exp.expYear
     */
    [_constants.SUBMIT_EDIT_EXP_ACTION](exp) {
      const easypayDetails = this.get('easypayDetails');
      const ccDetails = {
        profileId: null,
        number: easypayDetails.ccNumber,
        expirationMonth: exp.expMonth.toString(),
        expirationYear: exp.expYear.toString(),
        billingZipCode: easypayDetails.ccBillingZip,
        type: easypayDetails.ccType,
        nameOnCard: easypayDetails.nameOnCard,
        streetAddress: easypayDetails.streetAddress,
        initials: 'WEB',
        prepayNumber: easypayDetails.prepayNumber || null
      };
      return this.get('billingData').updateEasypayExpDate({
        ccDetails,
        easypayDetails
      });
    },

    /**
     * Success response for CC expiration update
     */
    [_constants.EDIT_SUCCESS_ACTION]() {
      const {
        mwd,
        csrInfo
      } = this.getProperties('mwd', 'csrInfo');
      const successMsg = 'EasyPay payment method successfully updated';

      if (csrInfo.isCsr) {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CSR Manage Easypay updateCCExpDate', "CSR Team:".concat(csrInfo.csr.team), "CSR Username:".concat(csrInfo.csr.username)]);
      }

      this.reset();
      this.set('dsm.statusMessages', [new _statusMessage.default(successMsg, 'success')]);
      mwd.hide();
    },

    /**
     * currently unimplemented
     * @method STEP_BACK_ACTION
     */
    [_constants.STEP_BACK_ACTION]() {
      const currentState = this.get('currentState');
      const stepBackActions = {
        ENROLL_PAYMENT_METHOD_STATE: 'ENROLL_INIT_ACTION',
        ENROLL_VALIDATION_STATE: 'ENROLL_PAYMENT_METHOD_ACTION',
        ENROLL_CONFIRMATION_STATE: 'ENROLL_PAYMENT_METHOD_ACTION',
        ENROLL_INIT_STATE: 'START_ENROLL_ACTION'
      };
      this.get('mwd').clearMessages();
      return this.get('fsm').dispatch(stepBackActions[currentState]);
    },

    /**
     * Validates payment details
     * @param {String} type
     * @returns {Object}
     * @example validatePaymentDetails('bank', false) ->
     * {
     * 	valid: false,
     * 	fe: {
     * 		accountType: false,
     * 		routingNumber: true,
     * 		accountNumber: false,
     * 		accountNumber2: false,
     * 	}
     * 	errors: ['Payment.errorRoutingNumberInvalid']
     * }
     */
    validatePaymentDetails(details) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        let isMissingInfo = false;
        const errors = [];
        const {
          routingNumber,
          accountNumber,
          accountNumber2
        } = details;
        const fe = {
          accountType: false,
          routingNumber: false,
          accountNumber: false,
          accountNumber2: false
        };
        Object.keys(details).forEach(key => {
          const value = details[key];
          const notSaveOrPrepay = !/save/.test(key) && !/prepay/.test(key);

          if (!value && notSaveOrPrepay) {
            fe[key] = true;
            isMissingInfo = true;
          }
        });

        if (routingNumber && !/^[\d]{9}$/.test(routingNumber)) {
          errors.push((0, _localization.toExpandedString)('Payment.errorRoutingNumberInvalid'));
          fe.routingNumber = true;
        }

        if (accountNumber && accountNumber2 && accountNumber !== accountNumber2) {
          errors.push((0, _localization.toExpandedString)('Payment.errorBankAccountNumbersNoMatch'));
          fe.accountNumber2 = true;
        }

        if (isMissingInfo) {
          errors.push((0, _localization.toExpandedString)('General.errorAllFieldsRequired'));
        }

        const valid = !Object.keys(fe).some(key => fe[key] === true);
        return valid ? Ember.run(null, resolve) : Ember.run(null, reject, {
          valid,
          fe,
          errors
        });
      });
    },

    /**
     * Starts enrollment or navigates to manageeasypay route
     */
    click() {
      const flexfiInstallmentData = this.get('flexfiInstallmentData');
      const isEnrolled = this.get('isEnrolled');

      if (!isEnrolled && (!flexfiInstallmentData || !flexfiInstallmentData.length)) {
        return this.get('fsm').dispatch(_constants.START_ENROLL_ACTION);
      }

      return this.get('router').transitionTo('dashboard.account.payments.manageeasypay');
    },

    advance(...args) {
      return advance(this.get('fsm'))(...args);
    }

  });

  _exports.default = _default;
});