define("adt-wss/templates/widget-section-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SQXIAaAL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"widget-section-icon\"],[8],[0,\"\\n\\t\"],[7,\"i\",true],[11,\"class\",[29,[[22,\"icon\"],\"-icon icon\"]]],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/widget-section-icon.hbs"
    }
  });

  _exports.default = _default;
});