define("adt-wss/pods/components/site-map/component", ["exports", "adt-wss-common/mixins/with-fsm"], function (_exports, _withFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const categories = [{
    category: 'Billing & Payments:',
    links: [{
      title: 'Make A Payment',
      url: 'dashboard.account.payments.onetimepayment'
    }, {
      title: 'Enroll in or Manage EasyPay',
      url: 'dashboard.account.payments.index'
    }, {
      title: 'Manage Saved Payment Account Information',
      url: 'dashboard.account.payments.savedpayments'
    }, {
      title: 'Billing Statements',
      url: 'dashboard.account.payments.index'
    }, {
      title: 'Paperless Billing',
      url: 'dashboard.account.payments.index'
    }, {
      title: 'Download Account History',
      url: 'dashboard.account.payments.index'
    }]
  }, {
    category: 'Certificates & Guarantees',
    links: [{
      title: 'Insurance Certificates',
      url: 'dashboard.account.documents'
    }, {
      title: 'Contracts',
      url: 'dashboard.account.documents'
    }, {
      title: 'Theft Protection Guarantee',
      external: true,
      url: 'https://www.myadt.com/static/TheftProtectionCert.pdf'
    }, {
      title: 'ADT Money Back Service Guarantee',
      external: true,
      url: 'https://www.myadt.com/static/SixMonthCert.pdf'
    }]
  }, {
    category: 'System Information',
    links: [{
      title: 'Alarm Activity',
      url: 'dashboard.alarm.history'
    }, {
      title: 'System Tests',
      url: 'dashboard.alarm.systemtest'
    }, {
      title: 'Sign Up for Monitoring Reports',
      url: 'dashboard.account.preferences'
    }, {
      title: 'Service Appointment Details',
      url: 'dashboard.alarm.appointments'
    }, {
      title: 'Travel Reminders',
      url: 'dashboard.account.preferences'
    }]
  }, {
    category: 'Contacts & Location Information',
    links: [{
      title: 'Emergency Call List',
      url: 'dashboard.account.contacts'
    }, {
      title: 'Manage Locations',
      url: 'dashboard.manage'
    }, {
      title: 'Email Preferences',
      url: 'dashboard.account.preferences'
    }, {
      title: 'Manage Verbal Passwords',
      url: 'dashboard.account.profile'
    }, {
      title: 'Change Mailing Address',
      url: 'dashboard.account.profile'
    }]
  }, {
    category: 'Ordering Equipment',
    links: [{
      title: 'System Batteries',
      url: 'dashboard.shop.productsservices.battery'
    }, {
      title: 'Battery Order Status',
      url: 'dashboard.shop.orderhistory'
    }, {
      title: 'Yard Signs & Decals',
      url: 'dashboard.shop.productsservices.decals'
    }]
  }, {
    category: 'Website',
    links: [{
      title: 'Help Center',
      external: true,
      url: 'https://help.adt.com/s/'
    }, {
      title: 'Site Usage Agreement',
      url: 'index.usageagreement'
    }, {
      title: 'MyADT Login Email & Password',
      url: 'dashboard.account.profile'
    }]
  }];
  /**
   * Help Center shows links for different categories for help
   *
   * @module Components/Help/HelpCenter
   * @property {Array} categories - links
   * @property {String} categories.{i}.category - Category text
   * @property {String} categories.{i}.icon - icon css class
   * @property {Array} categories.{i}.links - icon css class
   * @property {Array} categories.{i}.links.{j}.title - link te xt
   * @property {Array} categories.{i}.links.{j}.url - route path
   * @property {Array} categories.{i}.links.{j}.param - route param
   * @example
   * {@lang xml} {{help/help-center}}
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    categories
  });

  _exports.default = _default;
});