define("adt-wss/pods/dashboard/shop/checkout/adhesivekit/route", ["exports", "adt-wss-common/classes/status-message", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/fp", "adt-wss-common/utils/route-helpers", "adt-wss/services/app-dispatcher"], function (_exports, _statusMessage, _myadtHelpers, _fp, _routeHelpers, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    orderData: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    systemInformation: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    dsm: Ember.inject.service('dashboard-status-messages'),
    makeDeliverData: _routeHelpers.makeDeliverData,
    hashSettled: Ember.RSVP.hashSettled,
    useResolvedOnly: _routeHelpers.useResolvedOnly,
    parentRoute: 'dashboard.shop',

    beforeModel() {
      if (this.systemInformation.get('outOfService')) {
        this.transitionTo(this.parentRoute);
      }
    },

    model(params) {
      const orderData = this.get('orderData');
      const systemInformationDeliverData = this.makeDeliverData(this, 'systemInformation');
      const orderDetails = orderData.getAdhesiveKitsOrderPricing().then(data => (0, _fp.omix)(data, {
        quantityValue: params.quantityvalue
      })).catch(results => {
        const errors = (0, _myadtHelpers.genericErrorHandler)(results);
        this.transitionTo('/dashboard/orders');
        this.set('dsm.statusMessages', errors.map(error => new _statusMessage.default(error, 'error')));
      });
      const {
        profileInfo
      } = this.get('currentLocation');
      const systemInfoList = systemInformationDeliverData('systemInfoList', systemInformation => systemInformation.getSystemInfoList());
      return this.hashSettled({
        orderDetails,
        profileInfo,
        systemInfoList
      }).then(this.useResolvedOnly);
    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      this.refresh();
    },

    activate() {
      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Products', 'Page View', 'Adhesive Kit']);
    },

    deactivate() {
      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    }

  });

  _exports.default = _default;
});