define("adt-wss/services/order-data", ["exports", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/fp", "adt-wss-common/utils/myadt-helpers"], function (_exports, _appDispatcher, _fp, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const clearCacheArgs = {
    orderInfo: null,
    orderHistory: null,
    newSectionVisible: false,
    newsBannerUrl: '/api/v2/content/getNewProductPage'
  };

  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)(clearCacheArgs), {
    apiService: Ember.inject.service(),
    orderInfo: null,
    orderHistory: null,
    newSectionVisible: false,
    newsBannerUrl: '/api/v2/content/getNewProductPage',

    /**
     * Processes order history response
     * Since we have a few types of item details (e.g. "adhesiveKitDetails", "batteryDetails")
     * This method defines proper details object.
     *
     * @method
     * @prop {Array} list
     * @return {Array}
     */
    processOrderHistoryResponse(list = []) {
      return list.map(i => {
        const details = Object.values(i.itemDetails).find(Boolean);
        return (0, _fp.omix)(i, {
          itemDetails: (0, _fp.omix)({
            imageUrl: details.batteryImageUrl
          }, details)
        });
      });
    },

    /**
     * Gets order history
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getOrderHistory() {
      return this.get('apiService').myADT.getOrderHistory().then(({
        data: {
          orderHistory
        }
      }) => this.set('orderHistory', this.processOrderHistoryResponse(orderHistory)));
    },

    /**
     * Enroll user to the business reporting
     * Calls '/api/order/dsEnroll'
     *
     * @prop {Object} data
     * @return {Promise}
     */
    businessReportingEnroll(data = {}) {
      return this.get('apiService').myADT.enrollBusinessReporting(data).then(() => this.get('appDispatcher').sendEvent(_appDispatcher.UPDATE_APP_STATE_EVENT, {}, this));
    },

    /**
     * Gets info of remaining yard signs and decals user may order this year.
     */
    getOrderInfo() {
      this.set('orderInfo', null);
      return this.get('apiService').myADT.getOrderInfo().then(({
        data
      }) => this.set('orderInfo', data));
    },

    /**
     * Post decals order
     * @prop {Int} amount
     */
    orderDecals(amount) {
      return this.get('apiService').myADT.postDecals({
        amount
      }).then(() => this.getOrderInfo());
    },

    /**
     * Post yard signs order
     * @prop {Int} amount
     */
    orderYardsigns(amount) {
      return this.get('apiService').myADT.postYardsigns({
        amount
      }).then(() => this.getOrderInfo());
    },

    determNewsVisibility() {
      const NO_CONTENT_STATUS_CODE = 204;
      return this.get('apiService').pureRequest({
        url: this.get('newsBannerUrl')
      }).then((params = {
        xhr: {}
      }) => {
        const {
          xhr: {
            status
          }
        } = params;

        if (status === NO_CONTENT_STATUS_CODE) {
          return Promise.reject("Status code - ".concat(status));
        }

        return params;
      }).then(() => this.set('newSectionVisible', true)).catch(() => this.set('newSectionVisible', false));
    },

    /**
     * Gets tax info for adhesive kit shopping cart
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getAdhesiveKitsOrderTaxes(shippingOption) {
      return this.get('apiService').myADT.getAdhesiveKitsOrderTaxes(shippingOption).then(({
        data
      }) => data);
    },

    /**
     * Gets pricing info for adhesive kit shopping cart
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getAdhesiveKitsOrderPricing() {
      return this.get('apiService').myADT.getAdhesiveKitsOrderPricing().then(({
        data
      }) => data);
    },

    /**
     * Submits adhesive kit order
     * @instance
     *
     * @param {Object} orderData
     * @param {Object} fullOrder
     * @param {String} orderData.csNo
     * @param {String} orderData.shippingOptionSelected
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    postAdhesiveKitsOrderPlacement(orderData, fullOrder) {
      return this.get('apiService').myADT.postAdhesiveKitsOrderPlacement(orderData).then(({
        data
      }) => {
        if (fullOrder) {
          this.set('lastOrder', (0, _fp.omix)(fullOrder, {
            jobConfirmationNo: data.jobConfirmationNo,
            shippingOptionSelected: orderData.shippingOptionSelected
          }));
        }

        this.getOrderHistory();
        return data;
      });
    }

  });

  _exports.default = _default;
});