define("adt-wss/pods/dashboard/account/payments/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tg/5VbAs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"right hide-mobile\"],[8],[0,\"\\n\\t\"],[7,\"h5\",true],[10,\"class\",\"account-number\"],[8],[0,\"Account: \"],[7,\"strong\",true],[8],[1,[24,[\"model\",\"profileInfo\",\"accountId\"]],false],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"nav\",true],[10,\"class\",\"dashboard-sub-tab-nav\"],[8],[0,\"\\n\\t\"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",[29,[[28,\"if\",[[28,\"eq\",[[24,[\"currentBillingTab\"]],\"billingHistory\"],null],\"active\"],null]]]],[3,\"action\",[[23,0,[]],\"dispatch\",\"showBillingHistory\"]],[8],[0,\"Billing History\"],[9],[0,\"\\n\\t\"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",[29,[[28,\"if\",[[28,\"eq\",[[24,[\"currentBillingTab\"]],\"billingStatements\"],null],\"active\"],null]]]],[3,\"action\",[[23,0,[]],\"dispatch\",\"showBillingStatements\"]],[8],[0,\"Billing Statements\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"dashboard-sub-tab-content\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"currentBillingTab\"]],\"billingHistory\"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"dashboard/account/billing-history\",null,[[\"statusMessages\",\"accountId\",\"billingSystem\"],[[28,\"mut\",[[24,[\"billingStatusMessages\"]]],null],[24,[\"model\",\"profileInfo\",\"accountId\"]],[24,[\"model\",\"profileInfo\",\"billingSystem\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"currentBillingTab\"]],\"billingStatements\"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"dashboard/account/billing-statements\",null,[[\"statusMessages\",\"paperless\",\"paperlessVisible\",\"userId\"],[[28,\"mut\",[[24,[\"billingStatusMessages\"]]],null],[24,[\"model\",\"paperlessOption\"]],[24,[\"model\",\"paperlessVisible\"]],[24,[\"model\",\"authInfo\",\"userId\"]]]]],false],[0,\"\\n\\t\"]],\"parameters\":[]},null]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/account/payments/index/template.hbs"
    }
  });

  _exports.default = _default;
});