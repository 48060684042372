define("adt-wss/pods/components/billing/expresspay-form/component", ["exports", "adt-wss-common/utils/localization", "adt-wss-common/mixins/with-fsm", "adt-wss-common/classes/status-message", "ember-copy", "adt-wss/services/app-dispatcher", "adt-wss/pods/components/billing/expresspay-form/constants"], function (_exports, _localization, _withFsm, _statusMessage, _emberCopy, _appDispatcher, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    achDetails: {
      routingNumber: '',
      accountNumber: '',
      accountNumber2: '',
      initials: 'WEB',
      accountType: 'C'
    },
    ccDetails: {
      number: null,
      type: null
    },
    acctTypeOpts: [{
      value: 'C',
      text: 'Checking Account'
    }, {
      value: 'S',
      text: 'Savings Account'
    }],
    bankAcctType: {
      value: 'C',
      text: 'Checking Account'
    },
    fieldErrors: {
      acctNum: false,
      zip: false,
      accountType: false,
      routingNumber: false,
      accountNumber: false,
      accountNumber2: false,
      paymentAmount: false,
      paymentDate: false,
      uuid: false
    }
  };
  const fsmModel = {
    scrollToTop: true,
    transitions: {
      [_constants.states.IDLE]: {
        [_constants.actions.LOCATE]: _constants.states.FETCHING_ACCT_DATA,
        [_constants.actions.LOCATE_AUTH]: _constants.states.FETCHING_ACCT_DATA
      },
      [_constants.states.FETCHING_ACCT_DATA]: {
        [_constants.actions.LOCATE_FLEXFI]: _constants.states.FETCHING_FLEXFI,
        [_constants.actions.SELECT_ACCOUNT]: _constants.states.ACCOUNT_LIST,
        [_constants.actions.RESOLVE_ACCT_DATA]: _constants.states.PAYMENT_DETAILS,
        [_constants.actions.REJECT_ACCT_DATA]: _constants.states.IDLE
      },
      [_constants.states.FETCHING_FLEXFI]: {
        [_constants.actions.RESOLVE_FLEXFI]: _constants.states.ACCOUNT_LIST,
        [_constants.actions.REJECT_FLEXFI]: _constants.states.PAYMENT_DETAILS
      },
      [_constants.states.ACCOUNT_LIST]: {
        [_constants.actions.SELECT_ACCOUNT]: _constants.states.PAYMENT_DETAILS,
        [_constants.actions.RESET]: _constants.states.IDLE
      },
      [_constants.states.HAS_PAYMENT]: {
        [_constants.actions.RESET]: _constants.states.IDLE,
        [_constants.actions.SHOW_PENDING_PAYMENT_ERROR]: _constants.states.IDLE
      },
      [_constants.states.PAYMENT_DETAILS]: {
        [_constants.actions.INITIATE_IVR]: _constants.states.INITIATING_IVR,
        [_constants.actions.SET_PENDING_PAYMENT]: _constants.states.HAS_PAYMENT,
        [_constants.actions.SUBMIT_PAYMENT_DETAILS]: _constants.states.SUBMITTING_PAYMENT,
        [_constants.actions.SET_PAYMENT_TYPE]: _constants.states.SETTING_PAYMENT_TYPE,
        [_constants.actions.RESET]: _constants.states.IDLE,
        [_constants.actions.REJECT_PAYMENT]: _constants.states.PAYMENT_DETAILS
      },
      [_constants.states.INITIATING_IVR]: {
        [_constants.actions.RESOLVE_IVR]: _constants.states.PAYMENT_DETAILS,
        [_constants.actions.REJECT_IVR]: _constants.states.PAYMENT_DETAILS
      },
      [_constants.states.SETTING_PAYMENT_TYPE]: {
        [_constants.actions.PAYMENT_LOADED]: _constants.states.PAYMENT_DETAILS
      },
      [_constants.states.SUBMITTING_PAYMENT]: {
        [_constants.actions.RESOLVE_PAYMENT]: _constants.states.COMPLETED_PAYMENT,
        [_constants.actions.REJECT_PAYMENT]: _constants.states.PAYMENT_DETAILS
      },
      [_constants.states.COMPLETED_PAYMENT]: {
        [_constants.actions.RESET]: _constants.states.IDLE
      }
    },
    context: void 0
  };
  /**
   * Form component for route 'index.expresspay'
   * @module Components/ExpressPayForm
   *
   * @prop {Classes/StateMachine} fsm
   * @prop {Services/ExpressPayService} EPS expresspay-service
   * @prop {Services/Paymentech} paymentech
   * @prop {String} currentState for StateMachine
   * @prop {Boolean} isFetching
   * @prop {Int|String} adtAcctNo
   * @prop {String} adtAcctNoMasked
   * @prop {Boolean} confirmAcctNoError
   * @prop {Int|String} billingZipCode
   * @prop {ExpressPayBillInfo} billInfo
   * @prop {Object} flexFiInstallmentInfo
   * @prop {Object} selectedPaymentTarget either AR or FlexFi
   * @prop {Object} bankAcctType
   * @prop {String} bankAcctType.text
   * @prop {String} bankAcctType.value
   * @prop {String} paymentType
   * @prop {String} paymentTab
   * @prop {String} paymentAmount float, but as a string
   * @prop {String} paymentDate
   * @prop {String} confirmationNumber
   * @prop {String[]} statusMessages
   * @prop {String[]} formErrorMessage
   * @prop {Object} achDetails
   * @prop {Object} ccDetails
   * @prop {String} uID
   * @prop {Boolean} uIDReceived true after Paymentech returns data (needed if there is another error with the payment)
   * @prop {Boolean} ccDetailsReceived true after MyADT BE returns data
   * @prop {Object[]} acctTypeOpts
   * @prop {String} maskedAcctNum masked payment account number
   * @prop {Object} fieldErrors
   * @prop {String} uuid Remote Pay ID for agent generated payment authorization
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    attributeBindings: ['selenium-id'],
    'selenium-id': 'expresspay-form',
    EPS: Ember.inject.service('expresspay-service'),
    billingData: Ember.inject.service(),
    csrInfo: Ember.inject.service(),
    router: Ember.inject.service(),
    paymentech: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    expressAlertShow: false,
    fsmModel,
    currentState: _constants.states.IDLE,
    rejectCount: 0,
    flowId: Ember.computed('uuid', function () {
      const uuid = this.get('uuid');
      return uuid ? 'EXP_PAY_OEP' : 'EXP_PAY';
    }),
    isFetching: Ember.computed('currentState', function () {
      const state = this.get('currentState');
      return /ing/i.test(state);
    }),
    wifitcflag: Ember.computed('uuid', function () {
      const uuid = this.get('uuid');

      if (uuid.substring(0, 4) === 'WIFI') {
        return true;
      } else {
        return false;
      }
    }),
    selectedPaymentTarget: null,
    achDetails: (0, _emberCopy.copy)(defaultProps.achDetails),
    ccDetails: null,
    uID: null,
    acctTypeOpts: defaultProps.acctTypeOpts,
    bankAcctType: defaultProps.bankAcctType,
    maskedAcctNum: '',
    fieldErrors: (0, _emberCopy.copy)(defaultProps.fieldErrors),
    uIDReceived: false,
    ccDetailsReceived: false,
    billingZipCode: '',
    adtAcctNo: '',
    adtAcctNoMasked: Ember.computed('adtAcctNo', function () {
      return this.maskAcctNum(this.get('adtAcctNo'), 3);
    }),
    confirmAcctNoError: Ember.computed('accountNumber', 'accountNumber2', function () {
      const {
        accountNumber,
        accountNumber2
      } = this.getProperties('accountNumber', 'accountNumber2');
      return accountNumber && accountNumber2 && accountNumber !== accountNumber2;
    }),
    billInfo: Ember.computed.readOnly('EPS.billInfo'),
    flexFiInstallmentInfo: Ember.computed.readOnly('EPS.flexFiInstallmentInfo'),
    transformedPaymentTarget: Ember.computed('selectedPaymentTarget', function () {
      const spt = this.get('selectedPaymentTarget');

      if (spt) {
        const blockInstantPay = spt.allowOneTimeInstallmentPay === false;
        return {
          amountDue: spt.amountDue || spt.remainingBalance,
          installmentAmt: spt.installmentAmt,
          billingSystem: spt.billingSystem || 'MMB',
          hasPendingPayment: spt.hasPendingPayment,
          pendingPayment: spt.pendingPayment,
          dueDate: spt.dueDate || spt.latestPaymentDate,
          allowOneTimeInstallmentPay: !blockInstantPay,
          noteNo: spt.noteNo
        };
      }

      return {};
    }),
    hasPendingPayment: Ember.computed.readOnly('transformedPaymentTarget.hasPendingPayment'),
    blockedByPending: Ember.computed('hasPendingPayment', 'transformedPaymentTarget.billingSystem', function () {
      return this.get('hasPendingPayment') && !/mmb/i.test(this.get('transformedPaymentTarget.billingSystem'));
    }),
    paymentType: 'card',
    paymentTab: 'card',
    paymentAmount: '',
    paymentDate: '',
    paymentDateObj: Ember.computed('paymentDate', function () {
      return Date.createDate(this.get('paymentDate'));
    }),
    confirmationNumber: '',
    entryType: '',
    uuid: Ember.computed.alias('EPS.uuid'),
    isIvr: false,
    ivrType: null,
    agentExtension: Ember.computed.oneWay('csrInfo.csr.agentIvrContactNo'),
    statusMessages: null,
    formErrorMessage: '',

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.EXPRESS_PAY_EVENT, this, _appDispatcher.EXPRESS_PAY_EVENT);
      this.get('appDispatcher').subscribe(_appDispatcher.HIDE_EXPRESS_PAY_EVENT, this, _appDispatcher.HIDE_EXPRESS_PAY_EVENT);

      if (this.get('uuid')) {
        this.get('fsm').dispatch(_constants.actions.LOCATE_AUTH);
      }
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.EXPRESS_PAY_EVENT, this, _appDispatcher.EXPRESS_PAY_EVENT);
      this.get('appDispatcher').unsubscribe(_appDispatcher.HIDE_EXPRESS_PAY_EVENT, this, _appDispatcher.HIDE_EXPRESS_PAY_EVENT);
    },

    [_appDispatcher.EXPRESS_PAY_EVENT]() {
      this.set('expressAlertShow', true);
    },

    [_appDispatcher.HIDE_EXPRESS_PAY_EVENT]() {
      this.set('expressAlertShow', false);
    },

    /**
     * Returns account number masked, exposing only last 4 digits
     * @param {Int|String} num
     * @param {Int} len how many digits after mask
     * @return {String}
     * @example this.maskAcctNum(324987324, 4) -> '***7324';
     */
    maskAcctNum(num, len) {
      const acctNo = num.toString().trim();
      return "****".concat(acctNo.substr(-len));
    },

    /**
     * Resets form to account search
     * Clears errors
     */
    [_constants.actions.RESET]() {
      this.setProperties({
        statusMessages: null,
        fieldErrors: {
          acctNum: false,
          zip: false,
          accountType: false,
          routingNumber: false,
          accountNumber: false,
          accountNumber2: false,
          paymentAmount: false,
          paymentDate: false,
          uuid: false
        },
        achDetails: (0, _emberCopy.copy)(defaultProps.achDetails),
        ccDetails: null,
        uID: null,
        uIDReceived: false,
        ccDetailsReceived: false,
        paymentType: 'card',
        paymentTab: 'card'
      });
    },

    /**
     * Find billing data based on account number and zip code
     * Dispatches `RESOLVE_ACCT_DATA` or `rejectAcctData`
     * Includes validation
     */
    [_constants.actions.LOCATE]() {
      let {
        adtAcctNo,
        billingZipCode
      } = this.getProperties('adtAcctNo', 'billingZipCode');
      const {
        EPS,
        fsm
      } = this.getProperties('EPS', 'fsm');
      const errors = [];

      this[_constants.actions.RESET]();

      this.set('remote', false);
      adtAcctNo = adtAcctNo.trim();
      billingZipCode = billingZipCode.trim();

      if (adtAcctNo && billingZipCode) {
        if (/^[\d]*$/.test(adtAcctNo) && /^[\d]{5}$/.test(billingZipCode)) {
          return EPS.getBillInfo(adtAcctNo, billingZipCode).then(data => {
            this.set('selectedPaymentTarget', data);
            if (data.flexFiInstallment) return fsm.dispatch(_constants.actions.LOCATE_FLEXFI, data);
            if (data.preInstall) return fsm.dispatch(_constants.actions.SELECT_ACCOUNT, data);
            return fsm.dispatch(_constants.actions.RESOLVE_ACCT_DATA, data);
          }).catch(results => fsm.dispatch(_constants.actions.REJECT_ACCT_DATA, results));
        }

        if (!/^[\d]*$/.test(adtAcctNo)) {
          this.set('fieldErrors.acctNum', true);
          errors.push((0, _localization.toExpandedString)('Registration.accountNumberMustBeNumeric'));
        }

        if (!/^[\d]{5}$/.test(billingZipCode)) {
          this.set('fieldErrors.zip', true);
          errors.push((0, _localization.toExpandedString)('Payment.errorCCZipCodeLength'));
        }
      } else {
        if (!adtAcctNo) this.set('fieldErrors.acctNum', true);
        if (!billingZipCode) this.set('fieldErrors.zip', true);
        errors.push((0, _localization.toExpandedString)('General.errorAllFieldsRequired'));
      }

      return fsm.dispatch(_constants.actions.REJECT_ACCT_DATA, {
        errors
      });
    },

    [_constants.actions.LOCATE_FLEXFI]() {
      const {
        EPS,
        fsm
      } = this.getProperties('EPS', 'fsm');
      return EPS.getFlexFiInfo().then(data => fsm.dispatch(_constants.actions.RESOLVE_FLEXFI, data)).catch(results => fsm.dispatch(_constants.actions.REJECT_FLEXFI, results));
    },

    [_constants.actions.LOCATE_AUTH]() {
      const {
        uuid,
        fsm,
        EPS
      } = this.getProperties('uuid', 'fsm', 'EPS');

      this[_constants.actions.RESET]();

      if (/^[a-z0-9]{6,10}$/i.test(uuid)) {
        return EPS.getOepAuthInfo().then(data => {
          if (!data.remotePayIDValid) {
            return fsm.dispatch(_constants.actions.REJECT_ACCT_DATA, data);
          }

          this.set('entryType', data.entryType);
          return fsm.dispatch(_constants.actions.RESOLVE_ACCT_DATA, data);
        }).catch(results => {
          this.set('fieldErrors.uuid', true);
          return fsm.dispatch(_constants.actions.REJECT_ACCT_DATA, results);
        });
      }

      this.set('fieldErrors.uuid', true);
      return fsm.dispatch(_constants.actions.REJECT_ACCT_DATA, {
        errors: [uuid ? 'QuickPayOEP.notFound' : 'General.errorAllFieldsRequired']
      });
    },

    /**
     * @param {String} type 'bank'(def) | 'card' | 'ivr'
     */
    [_constants.actions.SET_PAYMENT_TYPE](type) {
      this.setProperties({
        paymentTab: type,
        paymentType: type === 'bank' ? 'bank' : 'card',
        isIvr: type === 'ivr'
      });
      return this.get('fsm').dispatch(_constants.actions.PAYMENT_LOADED, undefined, false);
    },

    /**
     * Sets payment type to 'C' or 'S'
     * scoped to widget/custom-select instance, so refs `parentView`
     * @param {String} type
     */
    updateAcctType(type) {
      // check if scoped to widget/custom-select instance
      const ach = this.get('achDetails') || this.get('parentView.achDetails');
      ach.accountType = type.value;
      this.set('achDetails', ach);
    },

    /**
     * Submits agent extension for IVR payment process
     * @function
     */
    [_constants.actions.INITIATE_IVR]() {
      const agentExtension = this.get('agentExtension');

      if (!agentExtension) {
        this.handleErrors({
          errors: ['Customer phone number required.']
        });
        return this.get('fsm').dispatch(_constants.actions.REJECT_IVR);
      }

      return this.billingData.initIvrPayment({
        agentIVRContactNo: this.get('agentExtension'),
        paymentType: this.get('ivrType') || 'ivrPayment'
      }).then(() => {
        this.set('ivrInit', true);
        return this.get('fsm').dispatch(_constants.actions.RESOLVE_IVR);
      }).catch(({
        errors
      }) => {
        this.handleErrors({
          errors
        });
        return this.get('fsm').dispatch(_constants.actions.REJECT_IVR);
      });
    },

    /**
     * Submits payment info, and CC/ACH details through EPS
     * Opens confirmation modal if validation passes
     * else dispatches `actions.REJECT_PAYMENT`
     */
    [_constants.actions.SUBMIT_PAYMENT_DETAILS]() {
      const {
        blockedByPending,
        fsm,
        paymentTab,
        uuid,
        uID,
        ivrType
      } = this.getProperties('blockedByPending', 'fsm', 'paymentTab', 'uuid', 'uID', 'ivrType');

      if (blockedByPending) {
        fsm.dispatch(_constants.actions.REJECT_PAYMENT, {
          errors: null
        });
      }

      const validation = this.validatePaymentDetails(paymentTab);
      this.set('fieldErrors', validation.fe);

      if (validation.valid) {
        const flowId = uuid ? 'EXP_PAY_OEP' : 'EXP_PAY';

        if (paymentTab !== 'bank') {
          return this.get('EPS').getPayConfDetails_v2({
            paymentType: paymentTab === 'ivr' ? ivrType || 'ivrPayment' : null,
            flowId,
            uID
          }).then(response => {
            const rData = response.data;
            if (rData.type == 'C') rData.type = 'Checking';
            if (rData.type == 'S') rData.type = 'Savings';
            const cardNum = "".concat(rData.type, " ending in ").concat(rData.lastFourPaymentNo);
            this.set('ccDetails', {
              type: rData.type,
              number: cardNum
            });
            this.set('maskedAcctNum', cardNum);
            this.set('ivrType', rData.paymentType);
            return rData;
          }).then(() => this.showConfirmationModal()).catch(response => fsm.dispatch(_constants.actions.REJECT_PAYMENT, response));
        }

        return this.showConfirmationModal();
      }

      return fsm.dispatch(_constants.actions.REJECT_PAYMENT, {
        errors: validation.errors
      });
    },

    /**
     * Displays all payment data before submitting
     * Shows overpayment warning
     */
    showConfirmationModal() {
      const {
        paymentType,
        paymentAmount,
        paymentTab,
        paymentDateObj,
        achDetails,
        uID,
        uuid,
        fsm,
        EPS,
        ivrType,
        transformedPaymentTarget
      } = this.getProperties('paymentType', 'paymentTab', 'paymentAmount', 'paymentDateObj', 'achDetails', 'uID', 'uuid', 'fsm', 'EPS', 'ivrType', 'transformedPaymentTarget');
      const paymentDetails = {
        paymentAmount: parseFloat(paymentAmount).toFixed(2),
        postDate: paymentDateObj.toUSAString('/'),
        deviceType: 'Web'
      };

      if (transformedPaymentTarget.noteNo) {
        paymentDetails.noteNo = transformedPaymentTarget.noteNo;
      }

      const mwd = this.get('mwd');
      let maskedAcctNum = '';
      let endpoint = 'payBill';

      if (uuid) {
        endpoint = 'oepAuth';
      }

      if (paymentType === 'bank') {
        const achTypes = {
          C: 'Checking',
          S: 'Savings'
        };
        const accountType = achTypes[achDetails.accountType];
        maskedAcctNum = this.set('maskedAcctNum', "".concat(accountType, " Account Number ").concat(this.maskAcctNum(achDetails.accountNumber, 4)));
      } else {
        maskedAcctNum = this.get('maskedAcctNum');
      }

      const onClose = () => {
        fsm.dispatch(_constants.actions.REJECT_PAYMENT, {
          errors: []
        });
        mwd.hide();
      };

      mwd.show({
        name: 'confirm-expresspay',
        title: uuid ? 'Confirm Payment Method' : 'Confirm Payment',
        body: {
          paymentType,
          paymentAmount,
          paymentDateObj,
          achDetails,
          uID,
          maskedAcctNum,
          overpay: paymentAmount > this.get('transformedPaymentTarget.amountDue'),
          transformedPaymentTarget: this.get('transformedPaymentTarget'),
          uuid
        },
        isFooterLine: false,
        onClose,
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: 'Submit Payment',
          callback: () => {
            mwd.hide();
            const paymentData = {
              paymentType: paymentTab === 'ivr' ? ivrType || 'ivrPayment' : paymentType,
              paymentDetails,
              achDetails,
              uID
            };
            return EPS[endpoint](paymentData).then(response => fsm.dispatch(_constants.actions.RESOLVE_PAYMENT, response)).catch(results => fsm.dispatch(_constants.actions.REJECT_PAYMENT, results));
          }
        }]
      });

      if (parseFloat(paymentAmount) > parseFloat(this.get('transformedPaymentTarget.amountDue'))) {
        mwd.showWarn('This payment will bring the amount due below zero, creating a credit balance.');
      }
    },

    /**
     * Validates payment details
     * @param {String} type
     * @returns {Object}
     * @example validatePaymentDetails('bank') ->
     * {
     * 	valid: false,
     * 	fe: {
     * 		accountType: false,
     * 		routingNumber: true,
     * 		accountNumber: false,
     * 		accountNumber2: false,
     * 		paymentAmount: false,
     * 		paymentDate: false
     * 	}
     * 	errors: ['Payment.errorRoutingNumberInvalid']
     * }
     */
    validatePaymentDetails(type) {
      if (['bank', 'card', 'ivr'].indexOf(type) === -1) {
        return {
          valid: false
        };
      }

      let valid = true;
      const fe = {
        accountType: false,
        routingNumber: false,
        accountNumber: false,
        accountNumber2: false,
        paymentAmount: false,
        paymentDate: false,
        uuid: false
      };
      const errors = [];
      let missingInfo = false;
      let details = {};

      if (type === 'bank') {
        details = this.get('achDetails');
      } else if (type === 'card') {
        details = {
          uID: this.get('uID')
        };
      }

      const {
        paymentAmount,
        paymentDateObj,
        paymentDate,
        transformedPaymentTarget
      } = this.getProperties('paymentAmount', 'paymentDateObj', 'paymentDate', 'transformedPaymentTarget');
      this.set('statusMessages', []);
      Object.keys(details).forEach(key => {
        if (!details[key]) {
          missingInfo = true;
          fe[key] = true;
        }
      });

      if (type === 'bank') {
        if (details.routingNumber && details.routingNumber.length !== 9) {
          errors.push((0, _localization.toExpandedString)('Payment.errorRoutingNumberInvalid'));
          fe.routingNumber = true;
        }

        if (details.accountNumber && details.accountNumber2 && details.accountNumber !== details.accountNumber2) {
          errors.push((0, _localization.toExpandedString)('Payment.errorBankAccountNumbersNoMatch'));
          fe.accountNumber2 = true;
        }
      }

      const amt = parseFloat(paymentAmount);
      const today = this.get('EPS.date');

      if (!this.get('uuid')) {
        if (!amt) {
          missingInfo = true;
          fe.paymentAmount = true;
        } else {
          const due = parseFloat(this.get('transformedPaymentTarget.amountDue'));

          if (transformedPaymentTarget.noteNo && amt > due) {
            errors.push((0, _localization.toExpandedString)('Payment.errorAmountGreaterThanBalance'));
            fe.paymentAmount = true;
          } else if (amt - due > 100) {
            errors.push((0, _localization.toExpandedString)('Payment.errorMoreThanOneHundredExcess'));
            fe.paymentAmount = true;
          } else if (amt > 5000 && due < amt) {
            errors.push((0, _localization.toExpandedString)('Payment.errorPaymentMoreThanFiveThousand'));
            fe.paymentAmount = true;
          } else if (amt > 99999.99) {
            errors.push((0, _localization.toExpandedString)('Payment.errorPaymentMoreThanHundredThousand'));
            fe.paymentAmount = true;
          } else if (amt > 10000 && this.get('transformedPaymentTarget.billingSystem') !== 'MMB') {
            errors.push((0, _localization.toExpandedString)('Payment.errorPaymentMoreThanTenThousand'));
            fe.paymentAmount = true;
          } else if (amt < 5 && (due <= 0 || due >= 5)) {
            errors.push((0, _localization.toExpandedString)('Payment.errorPaymentLessThanFive'));
            fe.paymentAmount = true;
          }
        }

        if (!paymentDate) {
          missingInfo = true;
          fe.paymentDate = true;
        } else if (/invalid/i.test(paymentDateObj)) {
          errors.push((0, _localization.toExpandedString)('Payment.errorPostDateInvalid'));
          fe.paymentDate = true;
        } else if (paymentDateObj < today || paymentDateObj.timeSpan(today).days > 30) {
          errors.push((0, _localization.toExpandedString)('Payment.errorPostDateRange'));
          fe.paymentDate = true;
        }
      }

      if (missingInfo) {
        errors.push((0, _localization.toExpandedString)('General.errorAllFieldsRequired'));
      }

      valid = !Object.keys(fe).some(key => fe[key] === true);
      return {
        valid,
        fe,
        errors
      };
    },

    /**
     * Shows Account List with FlexFi options
     * Shows warning and disables button if `blockedByPending === true`
     * @param {Object} data
     */
    [_constants.actions.RESOLVE_FLEXFI](data) {
      return true;
    },

    [_constants.actions.REJECT_FLEXFI](results) {
      return this.handleErrors(results);
    },

    /**
     * Shows payment form
     * Shows warning and disables button if `blockedByPending === true`
     * @param {Object} data
     */
    [_constants.actions.RESOLVE_ACCT_DATA](data) {
      if (data.hasPendingPayment && data.billingSystem !== 'MMB') {
        this.get('fsm').dispatch(_constants.actions.SET_PENDING_PAYMENT);
      } else {
        if (data.entryType === 'DIY') {
          this.get('fsm').dispatch(_constants.actions.SET_PAYMENT_TYPE, 'card');
        }
      }
    },

    [_constants.actions.REJECT_ACCT_DATA](results) {
      this.handleErrors(results);
    },

    /**
     * Sets success message, and `confirmationNumber`
     * @param {Object} data
     */
    [_constants.actions.RESOLVE_PAYMENT](data) {
      const msg = this.get('uuid') ? 'Success! You authorized a new payment method.' : 'Success! You submitted your payment.';
      this.set('statusMessages', [new _statusMessage.default(msg, 'success')]);
      this.set('confirmationNumber', data.confirmationNumber);
    },

    [_constants.actions.REJECT_PAYMENT](results) {
      this.incrementProperty('rejectCount');

      if (this.get('rejectCount') >= 2) {
        const pypemsg = [{
          attribute: 'error',
          label: 'error',
          value: 'payment error',
          visible: 'wpm'
        }];
        window.Pypestream('config', {
          passthrough: JSON.stringify(pypemsg)
        });
        console.log('Pypestream msg sent: payment error');
      }

      this.handleErrors(results);
    },

    /**
     * Handles error messaging
     * @param {Object} results
     * @param {String[]} results.errors error messages or localization keys
     * @param {Boolean} [isHpf] indicates if errors came from Paymentech
     */
    handleErrors(results) {
      const errors = [];
      const generic = 'Generic.error';
      let hasGeneric = false;

      if (!results.errors) {
        errors.push(new _statusMessage.default(['Generic.error'], 'error'));
      } else if (Array.isArray(results.errors)) {
        results.errors.forEach(error => {
          if (error === generic && hasGeneric) return;
          if (error === generic) hasGeneric = true;
          if (results.isHpf) error = "Paymentech.Errors.".concat(error);
          errors.push(new _statusMessage.default(error, 'error'));
        });
      } else {
        if (results.isHpf) results.errors = "Paymentech.Errors.".concat(results.errors);
        errors.push(new _statusMessage.default(results.errors, 'error'));
      }

      return this.set('statusMessages', errors);
    },

    actions: {
      /**
       * Bootstrap date picker doesn't propagate the value while keyboard
       * navigating. This will catch it on blur.
       * @param {*} context
       * @param {jQueryEvent} ev
       */
      setPaymentDateOnBlur(context, ev) {
        this.set('paymentDate', Date.createDate(ev.target.value));
      },

      /**
       * Dispatches payment submission
       * Stops if payment is pending
       */
      submitPaymentDetails() {
        if (this.get('blockedByPending')) return false;
        return this.get('fsm').dispatch([_constants.actions.SUBMIT_PAYMENT_DETAILS]);
      },

      /**
       * Passthrough to {@link Classes/StateMachine.dispatch}
       * @param {String} action
       * @param {*} data
       * @param {Boolean} scroll
       */
      dispatch(action, data, scroll) {
        this.get('fsm').dispatch(action, data, scroll);
      },

      register() {
        const {
          mwd,
          router
        } = this.getProperties('mwd', 'router');

        const onClose = () => router.transitionTo('/');

        mwd.show({
          name: 'register',
          title: 'Register on MyADT',
          body: {
            onClose
          },
          isFooterLine: false,
          onClose,
          actions: []
        });
      },

      /**
       * Used for enter key action
       */
      dispatchLocate() {
        if (this.get('remote')) {
          return this.get('fsm').dispatch(_constants.actions.LOCATE_AUTH);
        }

        return this.get('fsm').dispatch(_constants.actions.LOCATE);
      },

      submitAgentExtension() {
        return this.get('fsm').dispatch(_constants.actions.INITIATE_IVR);
      }

    },

    [_constants.actions.SHOW_PENDING_PAYMENT_ERROR]() {
      this.set('statusMessages', [new _statusMessage.default('We are unable to take a payment at this time due to a pending payment on your account.', 'error')]);
    },

    [_appDispatcher.CRE_ERROR_EVENT](errors) {
      this.get('fsm').dispatch(_constants.actions.REJECT_PAYMENT, {
        errors,
        isHpf: true
      }, true);
    },

    [_appDispatcher.CRE_COMPLETE_PAYMENT_EVENT](transaction) {
      this.set('uIDReceived', true);
      this.set('uID', transaction.uID);
      this.set('EPS.uID', transaction.uID);
      return this.get('fsm').dispatch(_constants.actions.SUBMIT_PAYMENT_DETAILS);
    },

    willDestroyElement() {
      const appDispatcher = this.get('appDispatcher');
      this.get('EPS').clear();
      appDispatcher.unsubscribe(_appDispatcher.CRE_ERROR_EVENT, this, _appDispatcher.CRE_ERROR_EVENT);
      appDispatcher.unsubscribe(_appDispatcher.CRE_COMPLETE_PAYMENT_EVENT, this, _appDispatcher.CRE_COMPLETE_PAYMENT_EVENT);
    },

    didReceiveAttrs() {
      const appDispatcher = this.get('appDispatcher');
      this.set('rejectCount', 0);
      appDispatcher.subscribe(_appDispatcher.CRE_ERROR_EVENT, this, _appDispatcher.CRE_ERROR_EVENT);
      appDispatcher.subscribe(_appDispatcher.CRE_COMPLETE_PAYMENT_EVENT, this, _appDispatcher.CRE_COMPLETE_PAYMENT_EVENT);
    }

  });

  _exports.default = _default;
});