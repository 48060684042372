define("adt-wss/pods/components/stupid/email-updater/component", ["exports", "adt-wss-common/classes/validator", "adt-wss-common/mixins/with-fsm", "adt-wss-common/classes/status-message", "adt-wss-common/utils/ember-helpers", "adt-wss/pods/components/stupid/email-updater/constants"], function (_exports, _validator, _withFsm, _statusMessage, _emberHelpers, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const createValidatorInstance = () => new _validator.default({
    email: {
      label: 'Email',
      rules: ['isEmail']
    }
  }, {
    messageCustomizer: (value, label) => "".concat(label, ": ").concat(value)
  });

  const fsmModel = {
    scrollToTop: false,
    transitions: {
      [_constants.IDLE_STATE]: {
        [_constants.SET_EDIT_MODE_ACTION]: _constants.EDIT_STATE,
        [_constants.DELETE_DATA_ACTION]: _constants.UPDATING_STATE
      },
      [_constants.EDIT_STATE]: {
        [_constants.CANCEL_EDIT_MODE_ACTION]: _constants.IDLE_STATE,
        [_constants.UPDATE_DATA_ACTION]: _constants.UPDATING_STATE
      },
      [_constants.UPDATING_STATE]: {
        [_constants.REJECT_ACTION]: _constants.EDIT_STATE,
        [_constants.RESOLVE_ACTION]: _constants.IDLE_STATE
      }
    }
  };
  const defaultProps = {
    email: '',
    label: '',
    alert: v => v,
    errorMessage: _constants.GENERIC_ERROR_MESSAGE,
    successMessage: _constants.SUCCESSFULLY_UPDATED_MESSAGE,

    submitUpdate() {
      console.error('Please pass submitUpdate method to stupid/email-updater component');
    },

    allowDelete: true,
    delete: v => v
  };
  /**
   * @module Components/Stupid/EmailUpdater
   * @deprecated
   * @prop {String} email
   * @prop {String} label
   * @prop {Function} submitUpdate
   * @prop {Function} alert
   * @prop {Function} delete - Delete handler
   * @prop {Object} data - Data which used in edit mode and will be sent on the request
   * @prop {String} successMesage - Message which is passed to alert method
   * @prop {String} errorMessage - Message which is passed to alert method
   * @prop {String[]} messages - Contains list of error messages
   * @prop {Boolean} allowDelete
   * @prop {String} inputClass
   * @example
   * {@lang xml} {{stupid/email-updater
   *   email="test@test.com"
   *   label="test"
   *   submitUpdate=(action "updateEmails")
   *   alert=(action "alert")
   *   successMessage="Success"
   *   erorMessage="Error"
   *   allowDelete=false
   *   delete=(action "deleteHandler")
   * }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    mwd: Ember.inject.service('modal-window-dispatcher'),
    fsmModel,
    validator: null,
    classNames: ['email-updater'],
    inputClass: 'email-updater-field',
    currentState: _constants.IDLE_STATE,
    IS_EDIT_STATE: Ember.computed.equal('currentState', _constants.EDIT_STATE),
    IS_IDLE_STATE: Ember.computed.equal('currentState', _constants.IDLE_STATE),
    IS_UPDATING_STATE: Ember.computed.equal('currentState', _constants.UPDATING_STATE),
    emailNotChanged: (0, _emberHelpers.computedApplyFunction)((a, b) => a === b, 'email', 'data.email'),
    data: null,
    messages: null,

    init(...args) {
      this._super(...args);

      this.set('validator', createValidatorInstance());
      this.get('validator').onValidate(({
        values
      }) => this.set('messages', values));
    },

    [_constants.SET_EDIT_MODE_ACTION]() {
      this.prepareEdit();
      Ember.run.schedule('afterRender', () => this.setFocus());
    },

    [_constants.DELETE_DATA_ACTION]() {
      const fsm = this.get('fsm');
      return this.delete().then(() => fsm.dispatch(_constants.RESOLVE_ACTION)).catch(() => fsm.dispatch(_constants.REJECT_ACTION));
    },

    [_constants.UPDATE_DATA_ACTION]() {
      const {
        fsm,
        validator,
        data
      } = this.getProperties(['validator', 'data', 'fsm']);
      const validationFailed = validator.validate(data);

      if (validationFailed) {
        return Promise.resolve(fsm.dispatch(_constants.REJECT_ACTION));
      }

      return this.submitUpdate(data).then(() => fsm.dispatch(_constants.RESOLVE_ACTION)).catch(r => fsm.dispatch(_constants.REJECT_ACTION, r));
    },

    [_constants.RESOLVE_ACTION]() {
      this.resetMessages();
      this.alert([new _statusMessage.default(this.get('successMessage'), 'success', true)]);
    },

    [_constants.REJECT_ACTION](results) {
      if (!results) return;
      const {
        errors = [this.get('errorMessage')]
      } = results || {};
      return this.alert(errors.map(e => new _statusMessage.default(e, 'error', true)));
    },

    [_constants.CANCEL_EDIT_MODE_ACTION]() {
      this.resetMessages();
    },

    prepareEdit() {
      const email = this.get('email');
      this.set('data', {
        email
      });
    },

    setFocus() {
      const {
        element,
        inputClass
      } = this.getProperties(['element', 'inputClass']);
      element.getElementsByClassName(inputClass)[0].focus();
    },

    resetMessages() {
      this.set('messages', null);
    },

    actions: {
      dispatch(action, data) {
        this.get('fsm').dispatch(action, data);
      },

      inputActionsHandler() {
        const emailNotChanged = this.get('emailNotChanged');
        const dispatch = this.actions.dispatch.bind(this);
        const action = emailNotChanged ? _constants.CANCEL_EDIT_MODE_ACTION : _constants.UPDATE_DATA_ACTION;
        return dispatch(action);
      }

    }
  });

  _exports.default = _default;
});