define("adt-wss/pods/components/index/site-footer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentYear: null,
    elementId: 'siteFooter',
    session: Ember.inject.service(),
    actions: {
      privacyPolicy() {
        return window.open('https://www.adt.com/about-adt/legal/privacy-policy');
      }

    },

    init() {
      this._super(...arguments);

      this.set('currentYear', new Date().getFullYear());
    }

  });

  _exports.default = _default;
});