define("adt-wss/pods/components/dashboard/alarm/system-test-item/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FAILURE_START_ACTION = _exports.SUCCESS_START_ACTION = _exports.LOADING_START_TEST_ACTION = _exports.LOADING_START_TEST_STATE = _exports.SUCCESS_EDIT_ACTION = _exports.IDLE_STATE = _exports.LOADING_STOP_TEST_STATE = _exports.LOADING_EDIT_TEST_STATE = _exports.LOADING_STOP_TEST_ACTION = _exports.LOADING_EDIT_TEST_ACTION = _exports.TEST_IN_PROGRESS_STATE = _exports.FAILURE_ACTION = _exports.SUCCESS_ACTION = _exports.SYSTEM_IS_ON_TEST_ACTION = void 0;
  const SYSTEM_IS_ON_TEST_ACTION = 'SYSTEM_IS_ON_TEST_ACTION';
  _exports.SYSTEM_IS_ON_TEST_ACTION = SYSTEM_IS_ON_TEST_ACTION;
  const SUCCESS_ACTION = 'SUCCESS_ACTION';
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
  const FAILURE_ACTION = 'FAILURE_ACTION';
  _exports.FAILURE_ACTION = FAILURE_ACTION;
  const TEST_IN_PROGRESS_STATE = 'TEST_IN_PROGRESS_STATE';
  _exports.TEST_IN_PROGRESS_STATE = TEST_IN_PROGRESS_STATE;
  const LOADING_EDIT_TEST_ACTION = 'LOADING_EDIT_TEST_ACTION';
  _exports.LOADING_EDIT_TEST_ACTION = LOADING_EDIT_TEST_ACTION;
  const LOADING_STOP_TEST_ACTION = 'LOADING_STOP_TEST_ACTION';
  _exports.LOADING_STOP_TEST_ACTION = LOADING_STOP_TEST_ACTION;
  const LOADING_EDIT_TEST_STATE = 'LOADING_EDIT_TEST_STATE';
  _exports.LOADING_EDIT_TEST_STATE = LOADING_EDIT_TEST_STATE;
  const LOADING_STOP_TEST_STATE = 'LOADING_STOP_TEST_STATE';
  _exports.LOADING_STOP_TEST_STATE = LOADING_STOP_TEST_STATE;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const SUCCESS_EDIT_ACTION = 'SUCCESS_EDIT_ACTION';
  _exports.SUCCESS_EDIT_ACTION = SUCCESS_EDIT_ACTION;
  const LOADING_START_TEST_STATE = 'LOADING_START_TEST_STATE';
  _exports.LOADING_START_TEST_STATE = LOADING_START_TEST_STATE;
  const LOADING_START_TEST_ACTION = 'LOADING_START_TEST_ACTION';
  _exports.LOADING_START_TEST_ACTION = LOADING_START_TEST_ACTION;
  const SUCCESS_START_ACTION = 'SUCCESS_START_ACTION';
  _exports.SUCCESS_START_ACTION = SUCCESS_START_ACTION;
  const FAILURE_START_ACTION = 'FAILURE_START_ACTION';
  _exports.FAILURE_START_ACTION = FAILURE_START_ACTION;
});