define("adt-wss/pods/components/dashboard/appointments-list/component", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/mixins/with-fsm", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/common-fsm-state"], function (_exports, _myadtHelpers, _withFsm, _appDispatcher, _commonFsmState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    classNames: ['appointments-list'],
    tagName: 'div',
    appointments: []
  };
  /**
   * Renders appointments list
   * @module Components/Dashboard/AppointmentsList
   * @prop {Boolean} isWidget - transform view for windget on dashboard page
   * @prop {Object[]} appointments - List of appointments
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, _commonFsmState.commonMixin, {
    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    changeLocationHandler() {
      this.get('fsm').dispatch(_commonFsmState.FETCH_DATA_ACTION, true);
    },

    [_commonFsmState.FETCH_DATA_ACTION](forceRequest) {
      const fetchAppointmentList = appointmentData => appointmentData.getAppointmentList().then(() => appointmentData.get('activeAppointments'));

      return this.hashDeliverDataAndDispatch({
        appointments: ['appointmentData.activeAppointments', fetchAppointmentList, {
          forceRequest
        }],
        customerEmail: ['appointmentData.customerEmail']
      });
    },

    [_commonFsmState.SUCCESS_ACTION]({
      appointments,
      customerEmail
    }) {
      if (this.get('isWidget') && appointments) {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Service Appointments', 'SvcApptDisplayedTopDashboard']);
      }

      this.setProperties({
        appointments,
        customerEmail
      });
    },

    [_commonFsmState.FAILURE_ACTION](error) {
      this.showStatusMessage(error, 'error');
    }

  });

  _exports.default = _default;
});