define("adt-wss/pods/404/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sDVDZtZQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"id\",\"dashboardWrapper\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"navbar-header\",null,[[\"toggleSidebar\",\"logout\"],[[28,\"action\",[[23,0,[]],\"toggleSidebar\"],null],[28,\"action\",[[23,0,[]],\"logout\"],null]]]],false],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"dashboard-content\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"dashboard/side-bar\",null,[[\"opened\",\"close\"],[[24,[\"sidebarOpened\"]],[28,\"action\",[[23,0,[]],\"closeSidebar\"],null]]]],false],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"id\",\"notFoundPage\"],[10,\"class\",\"content\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[22,\"not-found\"],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"id\",\"pageWrap\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"id\",\"indexContent\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"index/meta-header\"],false],[0,\"\\n\\t\\t\\t\"],[1,[22,\"index/mobile-header\"],false],[0,\"\\n\\t\\t\\t\"],[1,[22,\"index/main-nav\"],false],[0,\"\\n\\n\\t\\t\\t\"],[1,[22,\"not-found\"],false],[0,\"\\n\\n\\t\\t\\t\"],[1,[22,\"index/mobile-footer\"],false],[0,\"\\n\\t\\t\\t\"],[1,[22,\"index/home-footer\"],false],[0,\"\\n\\t\\t\\t\"],[1,[22,\"index/site-footer\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/404/template.hbs"
    }
  });

  _exports.default = _default;
});