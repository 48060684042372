define("adt-wss/pods/components/dashboard/account/monitoring-reports/component", ["exports", "ember-copy", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/route-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/fp", "adt-wss-common/classes/validator", "adt-wss-common/utils/myadt-helpers", "adt-wss/pods/components/dashboard/account/monitoring-reports/constants"], function (_exports, _emberCopy, _withFsm, _routeHelpers, _emberHelpers, _fp, _validator, _myadtHelpers, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.defaultProperties = void 0;
  const validator = new _validator.default({
    email: {
      label: 'Recipient Email',
      rules: ['isNotEmpty', 'isEmail']
    }
  });
  const fsmModel = {
    scrollToTop: false,
    transitions: {
      [_constants.IDLE_STATE]: {
        [_constants.UPDATE_MONITORING_REMINDER_ACTION]: _constants.UPDATING_STATE
      },
      [_constants.UPDATING_STATE]: {
        [_constants.REJECT_ACTION]: _constants.IDLE_STATE,
        [_constants.RESOLVE_ACTION]: _constants.IDLE_STATE
      }
    }
  };
  const defaultProperties = {
    reminder: {
      intervalInMonths: 0,
      email: ''
    },
    frequency: {
      text: 'Never',
      value: 0
    },
    timeRangeOptions: [{
      text: 'Never',
      value: 0
    }, {
      text: 'Monthly',
      value: 1
    }, {
      text: 'Every 2 Months',
      value: 2
    }, {
      text: 'Every 3 Months',
      value: 3
    }],
    updateMonitoringReports: () => {
      throw new Error('Please pass "updateMonitoringReports" into component');
    }
  };
  /**
   * @module Components/Dashboard/Account/MonitoringReports
   * @prop {Object} reminder
   * @prop {Boolean} opted - indicates if user is opted-in
   * @prop {Array.<{email: String, pending: ?String}>} primaryEmails
   * @prop {Function} updateMonitoringReports
   * @example
   * {@lang xml} {{ dashboard/account/monitoring-reports
  		reminder=monitoringReports
  		updateMonitoringReports=updateMonitoringReports
  		opted=opted
  		primaryEmails=primaryEmails
  }}
   */

  _exports.defaultProperties = defaultProperties;

  var _default = Ember.Component.extend(_withFsm.default, defaultProperties, {
    mwd: Ember.inject.service('modal-window-dispatcher'),
    currentState: _constants.IDLE_STATE,
    IS_UPDATING_STATE: (0, _emberHelpers.isState)(_constants.UPDATING_STATE),
    fsmModel,
    validator,
    tagName: '',
    isNeverSelected: Ember.computed.equal('frequency.value', 0),

    init(...args) {
      this._super(...args);

      const mwd = this.get('mwd');
      this.get('validator').onValidate(({
        messages,
        values
      }) => {
        mwd.setProperty('errors', messages);
        mwd.setProperty('messages', values);
      });
    },

    /**
     * Updates data if data has passed the validation
     * Calls `/api/v2/auth/history/inactivity` endpoint
     *
     * @function
     * @param {Object} data
     * @param {Object[]} data.reminder
     * @param {Object[]} data.frequency
     * @returns {Promise}
     */
    [_constants.UPDATE_MONITORING_REMINDER_ACTION]({
      data: reminder,
      frequency
    }) {
      const {
        updateMonitoringReports,
        validator: val,
        opted: prevOptValue
      } = this.getProperties('updateMonitoringReports', 'validator', 'opted');
      const validationFailed = val.validate({
        email: reminder.email
      });

      if (validationFailed) {
        return Promise.resolve((0, _routeHelpers.fsmDispatch)(_constants.REJECT_ACTION)(this)());
      }

      return this.commonDispatcher(() => updateMonitoringReports((0, _fp.omix)(reminder, {
        intervalInMonths: frequency.value
      })), _constants.RESOLVE_ACTION, _constants.REJECT_ACTION, true).then(() => {
        const optIn = Boolean(frequency.value);

        if (prevOptValue !== optIn) {
          this.sendOptTypeTrackingEvents(optIn);
        }
      });
    },

    /**
     * Handler for successful updating.
     * Calls `closeWindowHandler` method in order to close the modal window
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     */
    [_constants.RESOLVE_ACTION]() {
      this.closeWindowHandler();
    },

    /**
     * Handler for failed updating.
     * Calls `mwd.showError` method in order to show a failed message
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     * @param {Classes/AjaxResponse} results - object with errors from BE response
     * @returns {Promise} If results are `null` it means that requested data hasn't passed the FE validation,
     * 	in another case data hasn't passed BE validation.
     */
    [_constants.REJECT_ACTION](results) {
      if (!results) return Promise.reject();
      const mwd = this.get('mwd');
      const {
        errors = [_constants.GENERIC_ERROR_MESSAGE]
      } = results || {};
      mwd.stopLoading();
      mwd.showError(errors[0]);
      return Promise.reject();
    },

    /**
     * Resets state (checkboxes, selects, etc.) of the modal window
     * Used before closing of the modal window
     * Triggered on `closeWindowHandler`
     *
     * @function
     */
    resetModalWindowState() {
      const mwd = this.get('mwd');
      mwd.setProperty('errors', null);
      mwd.setProperty('messages', null);
    },

    /**
     * Resets state (checkboxes, selects, etc.) of the modal window
     * Used before closing of the modal window
     * Triggered on `closeWindowHandler`
     *
     * @function
     */
    closeWindowHandler() {
      this.resetModalWindowState();
      this.get('mwd').hide();
    },

    /**
     * Sends GA tracking
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     * @returns {undefined}
     */
    sendOptTypeTrackingEvents(adding) {
      if (adding) {
        return (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Preferences', 'Opt-in Monitoring Reports Click', 'Monitoring Reports Opt-in']);
      }

      return (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Preferences', 'Opt-out Monitoring Reports Click', 'Monitoring Reports Opt-out']);
    },

    actions: {
      /**
       * Opens modal window
       *
       * @function
       */
      showModalWindow() {
        const {
          fsm,
          mwd,
          reminder,
          timeRangeOptions
        } = this.getProperties('fsm', 'mwd', 'reminder', 'timeRangeOptions');
        const data = (0, _emberCopy.copy)(reminder, true);

        const closeModal = () => this.closeWindowHandler();

        this.set('data', data);
        this.set('frequency', timeRangeOptions.find(({
          value
        }) => value === Number(reminder.intervalInMonths)));

        const onEnter = () => fsm.dispatch(_constants.UPDATE_MONITORING_REMINDER_ACTION, this.getProperties('data', 'frequency'));

        mwd.show({
          title: 'Monitoring Reports',
          name: 'preferences/monitoring-reports',
          body: this,
          isFooterLine: false,
          onClose: closeModal,
          onEnter,
          actions: [{
            caption: 'Cancel',
            stl: 'outline',
            callback: closeModal
          }, {
            caption: 'Save',
            callback: onEnter
          }]
        });
      }

    }
  });

  _exports.default = _default;
});