define("adt-wss/pods/components/dashboard/account/payment-method-selection/component", ["exports", "adt-wss/pods/components/dashboard/account/one-time-payment/component", "adt-wss/pods/components/dashboard/account/one-time-payment/constants", "adt-wss/services/app-dispatcher"], function (_exports, _component, _constants, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable import/order */

  /* eslint-enable import/order */

  /**
   * @module Components/Dashboard/Account/PaymentMethodSelection
   * @augments class {@link Components/PaymentForm} OneTimePayment
   * @prop {Date} paymentDate
   * @prop {Boolean} visible
   * @prop {Object} errors
   * @prop {Function} selectAccount
   * @prop {Function} setCardFormVisibility
   * @prop {Function} showError
   * @prop {Function} moveToForwardStep
   * @example
   * {@lang xml} {{ dashboard/account/payment-method-selection
  		selectAccount=body.selectAccount
  		visible=body.isPaymentMethodSelectionState
  		setCardFormVisibility=body.setCardFormVisibility
  		errors=body.errors
  		showError=body.showError
  		moveToForwardStep=body.moveToForwardStep
  }}
   */
  var _default = _component.default.extend({
    classNameBindings: ['visible'],
    classNames: ['payment-method-selection'],
    statusMessages: null,
    permissions: Ember.computed.readOnly('currentLocation.permissions'),

    /* eslint-disable ember/no-observers */
    showCardFormObserver: Ember.observer('showCardForm', function () {
      this.setCardFormVisibility(this.showCardForm);
    }),

    /* eslint-enable ember/no-observers */

    /**
     * Extends `SELECT_ACCOUNT_ACTION` from `one-time-payment` component
     *
     * @function
     * @param {Object} account
    */
    [_constants.SELECT_ACCOUNT_ACTION](account) {
      this._super(account);

      this.selectAccount(account);
    },

    /**
     * Extends `CRE_ERROR_EVENT` handler from `one-time-payment` component
     * Event is triggered by {@link Services/Paymentech}
     *
     * @function
     * @param {Array} errors
    */
    [_appDispatcher.CRE_ERROR_EVENT](errors) {
      const {
        text
      } = this.handleErrors({
        errors,
        isHpf: true
      })[0];

      this._super(errors);

      this.showError(text);
    },

    /**
     * Extends `Services/Paymentech` handler from `one-time-payment` component
     * Event is triggered by {@link Services/Paymentech}
     * Sets `uIDReceived`
     *
     * @function
     * @param {Object} transaction
    */
    [_appDispatcher.CRE_COMPLETE_PAYMENT_EVENT](transaction) {
      this.set('uIDReceived', true);
      this.set('uID', transaction.uID);
      return this.get('billingData').getPayConfDetails({
        flowId: 'ONETIME_PAY',
        uID: transaction.uID
      }).then(response => {
        const rData = response.data;
        const cardNum = "".concat(rData.cardType, " ending in ").concat(rData.lastFourOfCC);
        this.set('ccDetailsReceived', true);
        return this.selectAccount({
          type: rData.cardType,
          number: cardNum,
          uID: transaction.uID,
          savePayment: this.get('shouldSaveAcct')
        });
      }).then(() => this.moveToForwardStep());
    },

    /**
     * Extends `CRE_COMPLETE_PAYMENT_EVENT` from `one-time-payment` component
     * Sets default account if accounts list is empty
     *
     * @function
     * @param {String} type - Payment type
    */
    [_constants.SET_PAYMENT_TYPE_ACTION](_type) {
      return this._super(_type).then(() => {
        const type = _type === 'bank' ? 'bank' : 'card';
        const filteredAccounts = this.get('filteredAccounts') || [];
        this.achDetails.paymentType = type;

        if (!filteredAccounts.length) {
          this.achDetails.newInstance = true;
          this.achDetails.savePayment = this.get('permissions.savedPayments');
          this.selectAccount(this.achDetails);
        } else if (_type !== 'ivr') {
          const mostRecent = filteredAccounts.find(fa => fa.mostRecentlyUsed);
          this.selectAccount(mostRecent || {
            accountType: null,
            savePayment: this.get('permissions.savedPayments')
          });
        } else {
          this.selectAccount({
            accountType: null,
            savePayment: this.get('permissions.savedPayments')
          });
        }
      });
    },

    /**
     * Submits agent extension for IVR payment process
     * 
     * @function
     */
    [_constants.INITIATE_IVR_ACTION]() {
      this.resetErrorsState();
      return this.billingData.initIvrPayment({
        agentIVRContactNo: this.get('agentExtension'),
        paymentType: this.get('ivrType') || 'ivrPayment'
      }).then(() => {
        this.set('ivrInit', true);
        return this.get('fsm').dispatch(_constants.RESOLVE_IVR_ACTION);
      }).catch(({
        errors
      }) => {
        const {
          text
        } = this.handleErrors({
          errors
        })[0];
        this.showError(text);
        return this.get('fsm').dispatch(_constants.REJECT_IVR_ACTION);
      });
    },

    init(...args) {
      this._super(...args);

      this.achDetails = {
        savePayment: this.get('permissions.savedPayments')
      };
    },

    actions: {
      /**
       * Extends `updateAcctType` action from `one-time-payment` component
       * Handler for selecting needed account type
       *
       * @function
       * @param {String} accountType
      */
      updateAcctType(accountType) {
        this.updateAcctType(accountType);
        this.selectAccount(this.achDetails);
      },

      /**
       * Extends `toggleSavedAccounts` action from `one-time-payment` component
       * Sets default account
       *
       * @function
      */
      toggleSavedAccounts() {
        this._super();

        if (this.get('selectedAcct') === 'new') {
          this.achDetails.newInstance = true;
          this.achDetails.savePayment = this.get('permissions.savedPayments');
        }

        this.selectAccount(this.achDetails);
      },

      submitAgentExtension() {
        return this.get('fsm').dispatch(_constants.INITIATE_IVR_ACTION);
      }

    }
  });

  _exports.default = _default;
});