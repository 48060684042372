define("adt-wss/services/system-information", ["exports", "adt-wss/config/environment", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/localization", "adt-wss-common/utils/fp", "adt-wss-common/utils/myadt-helpers"], function (_exports, _environment, _emberHelpers, _localization, _fp, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const buildStorageSystem = (systemId, inService, standardSystemList) => {
    if (systemId && inService) {
      const LS = window.localStorage;
      const standardSystem = (standardSystemList || []).find((0, _fp.itemEquals)(systemId)) || {};
      const systemNames = LS.getItem('myADT_system_names');
      const newSystemNames = [];

      if (standardSystem && standardSystem.systemStandardizedName) {
        if (systemNames) {
          const nameRE = new RegExp(standardSystem.systemStandardizedName, 'i');

          if (!nameRE.test(systemNames)) {
            newSystemNames.push(systemNames);
          }
        }

        newSystemNames.push(standardSystem.systemStandardizedName);
        LS.setItem('myADT_system_names', newSystemNames.join(','));
      }
    }
  };

  const clearCacheArgs = {
    systemInfoList: null,
    standardSystemList: null,
    allEquipment: null,
    hasLowBatterySignals: false,
    lowBatterySignals: null,
    systemTestList: null,
    systemList: null,
    selectedSystem: null,
    allSystemsForSite: null,
    systemInfoListFull: null
  };
  /**
   * @module Services/SystemInfo
   * @prop {Object[]} systemInfoList
   * @prop {Object[]} allSystemsForSite
   * @prop {Object[]} standardSystemList
   * @prop {Object[]} allEquipment
   * @prop {Object} lowBatterySignals
   * @prop {Object} lowBatterySignals.device
   * @prop {Object} lowBatterySignals.panel
   * @prop {Object} lowBatterySignals.unknown
   * @prop {Object} lowBatterySignals.{signalType}.message
   * @prop {Object} lowBatterySignals.{signalType}.total
   * @prop {Object[]} systemTestList
   * @prop {} hasLowBatterySignals
   */

  var _default = Ember.Service.extend(Ember.Evented, (0, _myadtHelpers.clearCache)(clearCacheArgs), {
    apiService: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    myadtSecurity: Ember.inject.service(),
    mmbIncidents: Ember.inject.service(),
    state: Ember.inject.service(),
    currentSystem: null,
    systemInfoList: null,
    standardSystemList: null,
    allEquipment: null,
    hasLowBatterySignals: false,
    lowBatterySignals: null,
    systemTestList: null,
    systemList: null,
    selectedSystem: null,
    allSystemsForSite: null,
    systemInfoListFull: null,
    cellUpgradeEligibilityDetails: null,

    /**
     * Fetch information of user systems from the systemList
     */
    userSystemList: Ember.computed.oneWay('systemInfoListFull'),

    /**
     * Determines out of service status
     */
    outOfService: (0, _emberHelpers.computedApplyFunction)(list => (list || []).every(s => !s.inService), 'systemInfoList'),

    /**
     * Merge information of alarmSystem/allSystemTestList with alarmSystem/systemIdentification/getSystemList
     */
    systemTestListFull: Ember.computed.oneWay('systemInfoListFull'),

    /**
     * Set selected system (currently viewed) by user during session
     * @param {Object} system
     */
    setSelectedSystem(system) {
      this.set('selectedSystem', system);

      if (system.csNo) {
        window.sessionStorage.setItem('selectedSystem', system.csNo);
      }
    },

    /**
     * Set selected system (currently viewed) by user during session
     * @param {Object} system
     */
    setSelectedSystemByCsNo(csNo) {
      const userSystemList = this.get('userSystemList');

      if (userSystemList && userSystemList.length > 0) {
        const foundSystem = userSystemList.find(sys => sys.csNo === csNo) || userSystemList[0];
        this.setSelectedSystem(foundSystem);
      }
    },

    filterAndMergeAlarmSystemInfo() {
      const {
        standardSystemList,
        systemInfoList
      } = this.getProperties('standardSystemList', 'systemInfoList');
      const mergedData = (0, _fp.filterAndMerge)(systemInfoList, [standardSystemList], ['systemId', 'csNo', 'systemImageUrl', 'troubleShootUrl']);
      return this.parseSystemInfoList(mergedData);
    },

    /**
     * Get selected system (currently viewed) by user during session
     * @return {Object} - currently viewed system
     */
    getSelectedSystem(alreadyCalled, noPromise) {
      const selectedSystemCsNo = window.sessionStorage.getItem('selectedSystem');
      const userSystemList = this.get('userSystemList');

      if (alreadyCalled) {
        return Ember.RSVP.Promise.resolve({});
      }

      if (userSystemList && userSystemList.length > 0) {
        const foundSystem = userSystemList.find(sys => sys.csNo === selectedSystemCsNo) || userSystemList[0];
        this.setSelectedSystem(foundSystem);
        return noPromise ? foundSystem : Ember.RSVP.Promise.resolve(foundSystem);
      }

      return this.getStandardSystemList();
    },

    /**
     * Creates MMB Issue
     * @param {String} code transaction type
     * @param {String} comment comment text
     * @return {Promise}
     */
    createIncident(code, comment) {
      return this.get('mmbIncidents').createIncidentOrIssue({
        customerNo: this.get('currentLocation.profileInfo.accountId'),
        siteNo: 0,
        duration: Date.now() - this.get('pageStartTime'),
        transactionType: code,
        jobComments: comment
      }, true);
    },

    /**
     * Retrieves `allEquipment`
     */
    getEquipmentList() {
      const equipment = this.get('allEquipment');
      if (equipment) return Ember.RSVP.Promise.resolve(equipment);
      return this.get('apiService').getJSON('equipment.json', true).then(({
        data
      }) => {
        this.set('allEquipment', data);
        return data;
      });
    },

    /**
     * Retrieves the sequence of '/alarmSystem/allCsList' and 'alarmSystem/systemIdentification/getSystemList'
     */
    getStandardAndSystemInfoLists() {
      return Ember.RSVP.Promise.all([this.getSystemInfoList(), this.getStandardSystemList()]).then(() => {
        this.set('systemInfoListFull', this.filterAndMergeAlarmSystemInfo());
      });
    },

    /**
     * Retrieves `standardSystemList`
     */
    getStandardSystemList() {
      if (this.get('standardSystemList')) {
        return Ember.RSVP.Promise.resolve(this.get('standardSystemList'));
      }

      return this.get('apiService').myADT.getStandardSystemList().then(({
        data
      }) => {
        this.set('standardSystemList', data.systemList);
        this.set('systemList', data);
        return data;
      });
    },

    // Behaviors

    /**
     * Returns equipment description or "Unknown"
     * @param {String} id equipment ID
     * @return {String}
     */
    getEquipmentDesc(id) {
      const retVal = this.get("allEquipment.".concat(id)) ? this.get("allEquipment.".concat(id, ".desc")) : null;
      if (retVal) return retVal;
      return 'Unknown';
    },

    /**
     * @param {Object[]} data API response data
     */
    setLowBattSignals(data) {
      const batteryUrl = "".concat(_environment.default.rootURL, "batteryInformation.html");
      const device = {
        total: 0,
        message: null
      };
      const panel = {
        total: 0,
        message: null
      };
      const unknown = {
        total: 0,
        message: null
      };

      if (data.length) {
        data.forEach(signal => {
          if (signal.hasLowBatterySignal) {
            this.set('hasLowBatterySignals', true);
            if (!signal.signalType) signal.signalType = 'Unknown';

            _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Proactive Notifications', 'Low Battery', signal.signalType]);

            switch (signal.signalType.toLowerCase()) {
              case 'device':
                device.total++;
                break;

              case 'panel':
                panel.total++;
                break;

              default:
                unknown.total++;
                break;
            }
          }
        });
      }
      /* eslint-disable no-template-curly-in-string */


      if (device.total === 1) {
        device.message = (0, _localization.toExpandedString)('LowBattery.deviceNotificationSys').replace('${batteryUrl}', batteryUrl);
      }

      if (device.total > 1) {
        device.message = (0, _localization.toExpandedString)('LowBattery.deviceNotificationPluralSys').replace('${numberOfSignals}', _myadtHelpers.default.digitToWord(device.total, true)).replace('${batteryUrl}', batteryUrl);
      }

      if (panel.total === 1) {
        panel.message = (0, _localization.toExpandedString)('LowBattery.panelNotificationSys').replace('${batteryUrl}', batteryUrl);
      }

      if (panel.total > 1) {
        panel.message = (0, _localization.toExpandedString)('LowBattery.panelNotificationPluralSys').replace('${numberOfSignals}', _myadtHelpers.default.digitToWord(panel.total, true)).replace('${batteryUrl}', batteryUrl);
      }

      if (unknown.total === 1) {
        unknown.message = (0, _localization.toExpandedString)('LowBattery.unknownNotificationSys').replace('${batteryUrl}', batteryUrl);
      }

      if (unknown.total > 1) {
        unknown.message = (0, _localization.toExpandedString)('LowBattery.unknownNotificationPluralSys').replace('${numberOfSignals}', _myadtHelpers.default.digitToWord(unknown.total, true)).replace('${batteryUrl}', batteryUrl);
      }
      /* eslint-enable no-template-curly-in-string */


      const signals = {
        device,
        panel,
        unknown
      };
      this.set('lowBatterySignals', signals);
      return Ember.RSVP.Promise.resolve(signals);
    },

    /**
     * Checks for myAlarm permissions then retrieves low battery signals
     * and `allCsList`; rejects if no permission
     * @emits module:Services/SystemInfo.systemInformationAPIComplete
     * @return {Promise<APIResponse[]>}
     */
    getSystemInformation() {
      if (this.get('currentLocation.permissions.myAlarm')) {
        return this.getSystemInfoServices().then(response => {
          this.trigger('systemInformationAPIComplete');
          return response;
        });
      }

      const address = this.get('currentLocation.profileInfo.address.addressLine1');
      return Ember.RSVP.reject({
        success: false,
        data: null,
        errors: ["This account does not have permission to view system information for ".concat(address)],
        messages: null
      });
    },

    /**
     * Retrieves low battery signals from API and
     * sets `lowBatterySignals`
     * @instance
     * @type {ServiceCall}
     * @returns {ApiResponse}
     */
    getLowBatterySignals() {
      this.set('lowBatterySignals', null);
      return this.get('apiService').myADT.getLowBatterySignals().then(({
        data
      }) => this.setLowBattSignals(data));
    },

    /**
     * Gets systems info for site (COI list)
     * sets `allSystemsForSite`
     * @instance
     * @type {ServiceCall}
     * @returns {ApiResponse}
     * @deprecated 19.02 // No longer general site list // only for COI
     */
    getAllSystemsForSite() {
      return this.get('apiService').myADT.getAllSystemsForSite().then(({
        data
      }) => this.set('allSystemsForSite', data || [])).catch(() => this.set('allSystemsForSite', []));
    },

    /**
     * @deprecated
     * @param {SystemTestAPI[]} response
     * @returns {SystemTest[]}
     */
    parseSystemTestData(response) {
      const data = response.map(system => {
        const value = (0, _fp.omix)(system);
        value.onTestLabel = (0, _localization.toExpandedString)(value.onTestLabel);
        value.expiration = Date.createDate(value.expireDate);
        value.effective = Date.createDate(value.effectiveDate);
        value.currentDate = Date.createDate(value.current);
        const hours = value.expiration.getFixedHour(true, true);
        const minutes = value.expiration.getFixedMinute(true);
        value.expirationString = "".concat(hours, ":").concat(minutes);
        value.effectiveMeridian = value.effective.getAmPm();
        value.expirationMeridian = value.expiration.getAmPm();
        value.startUITimerInMillis = 0;
        value.remainingTime = null;
        value.equipmentDesc = value.csNoAlias || this.getEquipmentDesc(value.equipmentId);

        if (value.systemId === 86 || value.systemId === 85) {
          value.pulse = false;
        }

        if (!value.systemImageUrl) {
          value.systemImageUrl = "".concat(_environment.default.rootURL, "images/v2/temp/dashboard-support-generic.jpg");
        }

        value.sysTestData = (value.sysTestData || []).map((sysData, index) => {
          const pValue = (0, _fp.omix)(sysData);
          pValue.expiration = Date.createDate(pValue.expireDate);
          pValue.effective = Date.createDate(pValue.effectiveDate);
          pValue.currentDate = Date.createDate(pValue.current);
          pValue.remainingTime = null;
          pValue.startUITimerInMillis = 0;
          pValue.extendHrs = {
            value: 1,
            text: '1 hour'
          };
          pValue.lastItem = index === value.sysTestData.length - 1;

          if (!!pValue.zones && pValue.zones.length > 1) {
            pValue.zones.sort((a, b) => a.zoneId - b.zoneId);
          }

          pValue.remainingTime = null;
          return pValue;
        });
        return value;
      });
      return data;
    },

    /**
     * Transforms new system data
     * updated for 19.02
     * @param {SystemAPI[]} data
     * @returns {AlarmSystem[]}
     */
    parseSystemInfoList(data) {
      const {
        standardSystemList
      } = this.getProperties('standardSystemList');
      const systems = data || [];
      const totalOOS = systems.filter(v => !v.inService).length;
      const systemInfoList = systems.map(system => {
        const value = (0, _fp.omix)(system);
        const {
          equipmentId,
          csNoAlias,
          systemImageUrl,
          systemId,
          inService,
          troubleShootUrl
        } = system;
        const equipmentDesc = csNoAlias || this.getEquipmentDesc(equipmentId);
        const nativeImage = Boolean(systemImageUrl);
        const imgUrl = systemImageUrl || "".concat(_environment.default.rootURL, "images/v2/temp/dashboard-support-generic.jpg");
        const standardSystem = standardSystemList && systemId ? (standardSystemList || []).find(sys => sys.systemId === systemId) || {} : {};
        let quickGuideLink = standardSystem.quickGuideLink ? standardSystem.quickGuideLink.replace(/http[s]?:\/\/[w]{0,3}[.]?myadt.com\//i, (0, _myadtHelpers.getOrigin)() + _environment.default.rootURL) : null;
        let helpUrl = troubleShootUrl ? troubleShootUrl.replace(/http[s]?:\/\/[w]{0,3}[.]?myadt.com\//i, (0, _myadtHelpers.getOrigin)() + _environment.default.rootURL) : null;
        buildStorageSystem(systemId, inService, standardSystemList);
        value.onTestLabel = (0, _localization.toExpandedString)(value.onTestLabel);
        value.expiration = Date.createDate(value.expireDate);
        value.effective = Date.createDate(value.effectiveDate);
        value.currentDate = Date.createDate(value.current);
        const hours = value.expiration.getFixedHour(true, true);
        const minutes = value.expiration.getFixedMinute(true);
        value.expirationString = "".concat(hours, ":").concat(minutes);
        value.effectiveMeridian = value.effective.getAmPm();
        value.expirationMeridian = value.expiration.getAmPm();
        value.startUITimerInMillis = 0;
        value.remainingTime = null;
        value.equipmentDesc = value.csNoAlias || this.getEquipmentDesc(value.equipmentId);

        if (value.systemId === 86 || value.systemId === 85) {
          value.pulse = false;
        }

        if (!value.systemImageUrl) {
          value.systemImageUrl = "".concat(_environment.default.rootURL, "images/v2/temp/dashboard-support-generic.jpg");
        }

        value.sysTestData = (value.sysTestData || []).map((sysData, index) => {
          const pValue = (0, _fp.omix)(sysData);
          pValue.expiration = Date.createDate(pValue.expireDate);
          pValue.effective = Date.createDate(pValue.effectiveDate);
          pValue.currentDate = Date.createDate(pValue.current);
          pValue.effectiveMeridian = pValue.effective.getAmPm();
          pValue.expirationMeridian = pValue.expiration.getAmPm();
          pValue.remainingTime = null;
          pValue.startUITimerInMillis = 0;
          pValue.extendHrs = {
            value: 1,
            text: '1 hour'
          };
          pValue.lastItem = index === value.sysTestData.length - 1;

          if (!!pValue.zones && pValue.zones.length > 1) {
            pValue.zones.sort((a, b) => a.zoneId - b.zoneId);
          }

          return pValue;
        });
        return (0, _fp.omix)(system, value, {
          equipmentDesc,
          nativeImage,
          systemImageUrl: imgUrl,
          isUnidentifiedSystem: system.systemId === null,
          troubleShootUrl: helpUrl,
          quickGuideLink
        });
      });

      if (totalOOS) {
        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'CustomerProfile', 'Out of Service', totalOOS]);

        if (totalOOS === systemInfoList.length) {
          localStorage.removeItem('myADT_system_names');
        }
      }

      const tildeItemsLast = systemInfoList.sort(e => e.csNo.indexOf('~'));
      return tildeItemsLast;
    },

    /**
     * Gets system info and populates `systemInfoList`
     * @instance
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getSystemInfoList() {
      this.set('systemInfoList', null);

      if (this.get('currentLocation.permissions.myAlarm')) {
        const {
          apiService
        } = this.getProperties('apiService');
        return apiService.myADT.getSystemInfoList().then(({
          data
        }) => {
          const finalSystemList = this.parseSystemInfoList(data);
          this.set('systemInfoList', data);
          return finalSystemList;
        }, results => {
          _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Service Not Available', 'Message shown: System Test/Info down']);

          return results;
        });
      }

      const address = this.get('currentLocation.profileInfo.address.addressLine1');
      return Ember.RSVP.reject({
        success: false,
        data: null,
        errors: ["This account does not have permission to view system information for ".concat(address)],
        messages: null
      });
    },

    /**
     * Gets low battery signals, service schedule,
     * and system info list
     * @return {Promise.all}
     */
    getSystemInfoServices() {
      return Ember.RSVP.Promise.all([this.getLowBatterySignals(), this.getSystemInfoList()]);
    },

    /**
     * Retrieves test data from API.
     * Sets `systemTestList`
     * @instance
     * @type {ServiceCall}
     * @return {ApiResponse}
     * @deprecated 19.02
     */
    getSystemTestData() {
      this.set('systemTestList', []);
      const {
        apiService
      } = this.getProperties('apiService');
      return apiService.myADT.getSystemTestData().then(response => {
        const data = this.parseSystemTestData(response.data);
        this.set('systemTestList', response.data);
        return data;
      });
    },

    /**
     * Does something that allows a window to open to
     * '/ReportServlet?reportType=System_Report', displaying
     * system info in .xlsx
     * @instance
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    exportSystemInfo() {
      return this.get('apiService').myADT.exportSystemInfo().then(({
        data
      }) => {
        this.createIncident('DSYSD', 'Downloaded system details');
        return data;
      });
    },

    /**
     * @instance
     * @type {ServiceCall}
     * @param {Object} sysData
     * @return {ApiResponse}
     */
    setSystem(sysData, {
      systemId
    } = {}) {
      this.set('systemInfoListFull', null);
      return this.get('apiService').myADT.setSystem(sysData).then(({
        data
      }) => data.isSuccess ? data : Ember.RSVP.Promise.reject(data)).then(() => {
        if (systemId) {
          _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', "/systemIdentification.html#!system='".concat(systemId, "&state=confirm")]);
        }
      }).finally(() => {
        this.createIncident('SYSU', "Updated system type for ".concat(sysData.csNo));
        this.getStandardAndSystemInfoLists();
      });
    },

    getCellUpgradeEligibilityDetails() {
      return this.get('apiService').myADT.getCellUpgradeEligibilityDetails().then(({
        data
      }) => this.set('cellUpgradeEligibilityDetails', data));
    }

  });

  _exports.default = _default;
});