define("adt-wss/services/current-location", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/localization", "adt-wss-common/utils/fp", "adt-wss/services/app-dispatcher"], function (_exports, _myadtHelpers, _emberHelpers, _localization, _fp, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const clearCacheArgs = {
    addresses: null,
    csNoList: null,
    initialWebAddressId: null,
    currentWebAddressId: null,
    isMtm: false,
    showSchedules: false,
    profileInfo: null,
    isContractMonNoBillNoContractAccount: false,
    splitIntSystem: false,
    splitIntSystemNoList: null,
    splitIntSystemMessage: null
  };
  /**
   * Data about user's currently selected site.
   * Contains address list (`addresses`), which should probably be moved
   * @module Services/CurrentLocation
   * @prop {Int|String} initialWebAddressId
   * @prop {Boolean} isMtm flag means we should display the Broadview Link. Very bad name. deprecated?
   * @prop {Boolean} showSchedules belongs in component
   * @prop {String} broadviewLinkMsg belongs in component. deprecated?
   * @prop {AccountInfo} profileInfo user profile. used all over the place
   * @prop {WebAddress[]} addresses
   * @prop {String[]} csNoList
   * @prop {PermissionsObject} permissions
   */

  var _default = Ember.Service.extend(Ember.Evented, (0, _myadtHelpers.clearCache)(clearCacheArgs), {
    store: Ember.inject.service(),
    state: Ember.inject.service(),
    session: Ember.inject.service(),
    apiService: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    csrInfo: Ember.inject.service(),
    router: Ember.inject.service(),
    systemInformation: Ember.inject.service(),
    broadviewLinkMsg: (0, _localization.toExpandedString)('Header.broadviewLink'),
    addresses: null,
    csNoList: null,
    initialWebAddressId: null,
    currentWebAddressId: null,
    isMtm: false,
    showSchedules: false,
    profileInfo: null,
    roles: (0, _emberHelpers.computedApplyFunction)(({
      coi,
      billing,
      orderEquipment
    }) => ({
      3: ![coi, billing, orderEquipment].some(Boolean)
    }), 'permissions'),
    firstName: (0, _emberHelpers.computedApplyFunction)(name => {
      let nameToStore = null;

      if (name) {
        const spl = name.split(' ');
        nameToStore = spl[0].indexOf(',') === -1 ? spl[0] : name;
        localStorage.setItem('myADT_storedFirstName', nameToStore);
      }

      return nameToStore;
    }, 'profileInfo.name'),
    cyberOnly: (0, _emberHelpers.computedApplyFunction)((permissions, profileInfo) => !permissions.myAlarm && profileInfo && profileInfo.cyberSecurity, 'permissions', 'profileInfo'),
    adtGoOnly: (0, _emberHelpers.computedApplyFunction)((permissions, profileInfo) => !permissions.myAlarm && profileInfo && profileInfo.adtgo, 'permissions', 'profileInfo'),
    noSystems: Ember.computed.or('cyberOnly', 'adtGoOnly'),
    adtGoOrCyberSecurity: Ember.computed.or('profileInfo.{cyberSecurity,adtgo}'),

    /**
     * Modifies address in session, triggers 'UPDATE_APP_STATE_EVENT'
     * @instance
     *
     * @param {Object} address
     * @param {String} address.webAddressId
     * @emits Services/AppDispatcher.UPDATE_APP_STATE_EVENT
     */
    changeAddress(address) {
      return this.modifyCurrentAddress(address).then(() => this.set('currentWebAddressId', address.webAddressId)).then(() => this.get('appDispatcher').sendEvent(_appDispatcher.UPDATE_APP_STATE_EVENT, address.webAddressId, this)).then(() => this.getAccountInfo());
    },

    /**
     * Sets permissions to true or false for current address
     * @param {String[]} privileges
     * @emits permissionsReceived
     */
    setPermissions(privileges) {
      const permissions = this.get('permissions');
      const privs = privileges.map(priv => priv.toLowerCase().camelize());
      Object.keys(permissions).forEach(p => {
        if (privs.indexOf(p) > -1) {
          Ember.set(permissions, p, true);
        } else {
          Ember.set(permissions, p, false);
        }
      });
      Ember.set(permissions, 'hideContacts', !permissions.paidDataServicesAdmin && permissions.paidDataServices);

      if (!permissions.billing) {
        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'CustomerProfile', 'No billing permissions']);
      }

      this.set('permissions', permissions);
      this.trigger('permissionsReceived');
      return Ember.RSVP.Promise.resolve(permissions);
    },

    /**
     * Processes profile info response to properly populate
     * `profileInfo`, `isMtm`, and `showSchedules`,
     * @param {Object} profileInfo API response
     * @param {Boolean} isInit
     * @emits profileInfoLoaded
     */
    processAcctInfo(profileInfo) {
      return new Ember.RSVP.Promise(resolve => {
        const name = profileInfo.name.name || '';
        const lastName = name.substr(name.indexOf(' ') + 1);
        const firstName = name.substr(0, name.indexOf(' '));
        const phone = (0, _myadtHelpers.addPhoneMask)(profileInfo.phone);

        const _profileInfo = (0, _fp.omix)(profileInfo, {
          lastName,
          firstName,
          phone,
          name
        });

        const conMonNoBill = profileInfo.contractMonNoBillNoContractAccount;
        const splitIntegrSystem = profileInfo.splitIntegrationSystem;
        const splitIntSysNoList = profileInfo.splitIntSystemNoList;
        const splitIntSystemMessageValue = profileInfo.splitIntSystemMessage;
        const {
          showBroadviewLink,
          isSchedulePresent,
          address: {
            webAddressId,
            privileges,
            siteType
          }
        } = _profileInfo;
        this.setProperties({
          profileInfo: _profileInfo,
          isMtm: showBroadviewLink,
          showSchedules: isSchedulePresent,
          currentWebAddressId: webAddressId,
          isContractMonNoBillNoContractAccount: conMonNoBill,
          splitIntSystem: splitIntegrSystem,
          splitIntSystemNoList: splitIntSysNoList,
          splitIntSystemMessage: splitIntSystemMessageValue
        });
        this.setPermissions(privileges);

        if (siteType && siteType.toUpperCase() === 'C') {
          _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'CustomerProfile', 'Commercial User']);
        }

        this.trigger('profileInfoLoaded');
        Ember.run(null, resolve, _profileInfo);
      });
    },

    /**
     * @type {ServiceCall}
     * @param {Object} address
     * @param {String} address.webAddressId
     * @return {ApiResponse}
     */
    modifyCurrentAddress(newAddress) {
      return this.get('apiService').myADT.modifyCurrentAddress({
        webAddressId: newAddress.webAddressId
      }).then(() => this.getAddresses()).then(() => {
        const addresses = this.get('addresses');
        const address = addresses.find(a => a.webAddressId == newAddress.webAddressId);
        this.setPermissions(address.privileges);
      });
    },

    /**
     * Gets address list.
     * address list in current location doesn't totally make sense,
     * but this is how it works for now... (cjones || 2016-12-07)
     */
    getAddresses() {
      this.set('addresses', null);
      return this.get('apiService').myADT.getAddresses().then(({
        data
      }) => this.set('addresses', data));
    },

    /**
     * Runs {@linkcode module:Services/CurrentLocation~processAccountInfo processAccountInfo} on resolve.
     * Sets 5 second timeout to invalidate on reject
     * @instance
     *
     * @type {ServiceCall}
     * @param {Boolean} isInit if true, populates `addresses` from API
     * @return {ApiResponse}
     */
    getAccountInfo(isInit) {
      if (isInit === true) {
        this.getAddresses();
      }

      return this.get('apiService').myADT.getAccountInfo().then(response => this.processAcctInfo(response.data, isInit)).then(profileInfo => this.setAgentOrCustomerId(profileInfo)).catch(results => {
        setTimeout(() => {
          if (this.get('session.isAuthenticated')) {
            this.get('session').invalidate(false, 'noAcctInfo');
          }
        }, 1000);
        return results;
      });
    },

    setAgentOrCustomerId(profileInfo) {
      let id = '';

      if (this.get('authInfo.csrUser')) {
        id += "Agent: ".concat(this.get('csrInfo.csr.username'), ", ");
      }

      id += "Customer #: ".concat(profileInfo.accountId);
      window.myADT.agentOrCustomerId = id;
    },

    init() {
      this._super(...arguments);

      this.permissions = {
        savedPayments: true,
        panelCodeChange: true,
        myAlarm: true,
        contacts: true,
        hideContacts: false,
        billing: true,
        reporting: true,
        coi: true,
        orderEquipment: true,
        paidDataServices: true,
        paidDataServicesAdmin: true,
        unregisteredUser: true,
        businessReportingEnrollable: true
      };
    }

  });

  _exports.default = _default;
});