define("adt-wss/pods/components/dashboard-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qcbpHx3l",
    "block": "{\"symbols\":[\"navItem\",\"route\"],\"statements\":[[4,\"scrollable-tabs\",null,null,{\"statements\":[[0,\"\\t\"],[7,\"nav\",true],[10,\"class\",\"section-tab-nav\"],[8],[0,\"\\n\"],[4,\"bs-nav\",null,[[\"type\"],[\"tabs\"]],{\"statements\":[[4,\"each\",[[28,\"if\",[[24,[\"media\",\"isSmartphone\"]],[24,[\"links\"]],[28,\"reverse\",[[24,[\"links\"]]],null]],null]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"item\"]],\"expected `navItem.item` to be a contextual component but found a string. Did you mean `(component navItem.item)`? ('adt-wss/pods/components/dashboard-tabs/template.hbs' @ L5:C7) \"],null]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"link-to\"]],\"expected `navItem.link-to` to be a contextual component but found a string. Did you mean `(component navItem.link-to)`? ('adt-wss/pods/components/dashboard-tabs/template.hbs' @ L6:C8) \"],null],[23,2,[\"route\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[1,[23,2,[\"text\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard-tabs/template.hbs"
    }
  });

  _exports.default = _default;
});