define("adt-wss/pods/components/session-logout-alert/component", ["exports", "adt-wss/services/app-dispatcher"], function (_exports, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    appDispatcher: Ember.inject.service(),
    session: Ember.inject.service(),
    opened: false,

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.PS_SESSION_INVALIDATED_EVENT, this, _appDispatcher.PS_SESSION_INVALIDATED_EVENT);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.PS_SESSION_INVALIDATED_EVENT, this, _appDispatcher.PS_SESSION_INVALIDATED_EVENT);
    },

    [_appDispatcher.PS_SESSION_INVALIDATED_EVENT]() {
      this.set('opened', true);
    },

    actions: {
      close() {
        this.set('opened', false);
      },

      logout() {
        return this.get('session').invalidate(false, 'user initiated');
      }

    }
  });

  _exports.default = _default;
});