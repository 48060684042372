define("adt-wss/pods/components/myadt-login/register/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RETURN_TO_REGISTRATION_ACTION = _exports.LOGGING_IN_STATE = _exports.REJECT_AUTH_ACTION = _exports.GO_TO_VERIFY_ACTION = _exports.GO_BACK_ACTION = _exports.RETURN_TO_IDLE_ACTION = _exports.RETURN_TO_LOGIN_ACTION = _exports.CONFIRM_EMAIL_ACTION = _exports.REJECT_SUBMIT_ACTION = _exports.RESOLVE_SUBMIT_ACTION = _exports.SUBMIT_ACTION = _exports.SELECT_LOCATION_ACTION = _exports.REJECT_LOCATE_ACTION = _exports.RESOLVE_LOCATE_ACTION = _exports.INIT_PREMIUM_ACTION = _exports.LOCATE_ACTION = _exports.COMPLETED_STATE = _exports.VERIFY_STATE = _exports.SUBMITTING_STATE = _exports.DETAILS_STATE = _exports.CHOOSE_LOCATION_STATE = _exports.FETCHING_ACCOUNT_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const FETCHING_ACCOUNT_STATE = 'FETCHING_ACCOUNT_STATE';
  _exports.FETCHING_ACCOUNT_STATE = FETCHING_ACCOUNT_STATE;
  const CHOOSE_LOCATION_STATE = 'CHOOSE_LOCATION_STATE';
  _exports.CHOOSE_LOCATION_STATE = CHOOSE_LOCATION_STATE;
  const DETAILS_STATE = 'DETAILS_STATE';
  _exports.DETAILS_STATE = DETAILS_STATE;
  const SUBMITTING_STATE = 'SUBMITTING_STATE';
  _exports.SUBMITTING_STATE = SUBMITTING_STATE;
  const VERIFY_STATE = 'VERIFY_STATE';
  _exports.VERIFY_STATE = VERIFY_STATE;
  const COMPLETED_STATE = 'COMPLETED_STATE';
  _exports.COMPLETED_STATE = COMPLETED_STATE;
  const LOCATE_ACTION = 'LOCATE_ACTION';
  _exports.LOCATE_ACTION = LOCATE_ACTION;
  const INIT_PREMIUM_ACTION = 'INIT_PREMIUM_ACTION';
  _exports.INIT_PREMIUM_ACTION = INIT_PREMIUM_ACTION;
  const RESOLVE_LOCATE_ACTION = 'RESOLVE_LOCATE_ACTION';
  _exports.RESOLVE_LOCATE_ACTION = RESOLVE_LOCATE_ACTION;
  const REJECT_LOCATE_ACTION = 'REJECT_LOCATE_ACTION';
  _exports.REJECT_LOCATE_ACTION = REJECT_LOCATE_ACTION;
  const SELECT_LOCATION_ACTION = 'SELECT_LOCATION_ACTION';
  _exports.SELECT_LOCATION_ACTION = SELECT_LOCATION_ACTION;
  const SUBMIT_ACTION = 'SUBMIT_ACTION';
  _exports.SUBMIT_ACTION = SUBMIT_ACTION;
  const RESOLVE_SUBMIT_ACTION = 'RESOLVE_SUBMIT_ACTION';
  _exports.RESOLVE_SUBMIT_ACTION = RESOLVE_SUBMIT_ACTION;
  const REJECT_SUBMIT_ACTION = 'REJECT_SUBMIT_ACTION';
  _exports.REJECT_SUBMIT_ACTION = REJECT_SUBMIT_ACTION;
  const CONFIRM_EMAIL_ACTION = 'CONFIRM_EMAIL_ACTION';
  _exports.CONFIRM_EMAIL_ACTION = CONFIRM_EMAIL_ACTION;
  const RETURN_TO_LOGIN_ACTION = 'RETURN_TO_LOGIN_ACTION';
  _exports.RETURN_TO_LOGIN_ACTION = RETURN_TO_LOGIN_ACTION;
  const RETURN_TO_IDLE_ACTION = 'RETURN_TO_IDLE_ACTION';
  _exports.RETURN_TO_IDLE_ACTION = RETURN_TO_IDLE_ACTION;
  const GO_BACK_ACTION = 'GO_BACK_ACTION';
  _exports.GO_BACK_ACTION = GO_BACK_ACTION;
  const GO_TO_VERIFY_ACTION = 'GO_TO_VERIFY_ACTION';
  _exports.GO_TO_VERIFY_ACTION = GO_TO_VERIFY_ACTION;
  const REJECT_AUTH_ACTION = 'REJECT_AUTH_ACTION';
  _exports.REJECT_AUTH_ACTION = REJECT_AUTH_ACTION;
  const LOGGING_IN_STATE = 'LOGGING_IN_STATE';
  _exports.LOGGING_IN_STATE = LOGGING_IN_STATE;
  const RETURN_TO_REGISTRATION_ACTION = 'RETURN_TO_REGISTRATION_ACTION';
  _exports.RETURN_TO_REGISTRATION_ACTION = RETURN_TO_REGISTRATION_ACTION;
});