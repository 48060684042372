define("adt-wss/pods/components/dashboard/account/travel-reminders/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GENERIC_ERROR_MESSAGE = _exports.REJECT_ACTION = _exports.RESOLVE_ACTION = _exports.DELETE_TRAVEL_REMINDER_ACTION = _exports.ADD_TRAVEL_REMINDER_ACTION = _exports.UPDATING_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const UPDATING_STATE = 'UPDATING_STATE';
  _exports.UPDATING_STATE = UPDATING_STATE;
  const ADD_TRAVEL_REMINDER_ACTION = 'ADD_TRAVEL_REMINDER_ACTION';
  _exports.ADD_TRAVEL_REMINDER_ACTION = ADD_TRAVEL_REMINDER_ACTION;
  const DELETE_TRAVEL_REMINDER_ACTION = 'DELETE_TRAVEL_REMINDER_ACTION';
  _exports.DELETE_TRAVEL_REMINDER_ACTION = DELETE_TRAVEL_REMINDER_ACTION;
  const RESOLVE_ACTION = 'RESOLVE_ACTION';
  _exports.RESOLVE_ACTION = RESOLVE_ACTION;
  const REJECT_ACTION = 'REJECT_ACTION';
  _exports.REJECT_ACTION = REJECT_ACTION;
  const GENERIC_ERROR_MESSAGE = 'Generic.error';
  _exports.GENERIC_ERROR_MESSAGE = GENERIC_ERROR_MESSAGE;
});