define("adt-wss/pods/dashboard/account/documents/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    promotionData: Ember.inject.service(),
    accountData: Ember.inject.service(),
    account: Ember.inject.controller('dashboard/account'),
    siteType: Ember.computed.readOnly('currentLocation.profileInfo.address.siteType'),
    contractRenewalOptions: Ember.computed.readOnly('promotionData.renewalOffers'),
    showContractRenewalOffers: true,
    actions: {
      setStatusMessages(messages) {
        this.set('account.dashboardStatusMessages', messages);
      },

      hideRenewalOffers() {
        this.set('showContractRenewalOffers', false);
      },

      sendContractRenewalEventTag(...params) {
        return this.promotionData.sendEventTag(...params);
      }

    }
  });

  _exports.default = _default;
});