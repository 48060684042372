define("adt-wss/pods/components/dashboard/account/one-time-payment/component", ["exports", "adt-wss/mixins/on-form-esc-handler", "ember-copy", "adt-wss-common/utils/localization", "adt-wss-common/classes/state-machine", "adt-wss-common/classes/status-message", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/fp", "adt-wss/config/environment", "adt-wss-common/utils/myadt-helpers", "adt-wss/services/app-dispatcher", "adt-wss/pods/components/dashboard/account/one-time-payment/constants"], function (_exports, _onFormEscHandler, _emberCopy, _localization, _stateMachine, _statusMessage, _emberHelpers, _fp, _environment, _myadtHelpers, _appDispatcher, constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.maskAcctNum = _exports.computeStartPaymentDate = _exports.renderPaymentIcon = _exports.accountTypes = _exports.acctTypeOpts = void 0;
  const {
    IDLE_STATE,
    SETTING_PAYMENT_TYPE_STATE,
    INTITIATING_IVR_STATE,
    SUBMITTING_STATE,
    COMPLETED_STATE,
    SET_PAYMENT_TYPE_ACTION,
    SELECT_ACCOUNT_ACTION,
    INITIATE_IVR_ACTION,
    LOADED_ACTION,
    SUBMIT_ACTION,
    RESOLVE_ACTION,
    REJECT_ACTION,
    RESOLVE_IVR_ACTION,
    REJECT_IVR_ACTION,
    EXIT_ACTION
  } = constants;
  const fsmModel = {
    transitions: {
      [IDLE_STATE]: {
        [SELECT_ACCOUNT_ACTION]: IDLE_STATE,
        [SUBMIT_ACTION]: SUBMITTING_STATE,
        [INITIATE_IVR_ACTION]: INTITIATING_IVR_STATE,
        [SET_PAYMENT_TYPE_ACTION]: SETTING_PAYMENT_TYPE_STATE,
        [EXIT_ACTION]: IDLE_STATE,
        [REJECT_ACTION]: IDLE_STATE // why is this here

      },
      [INTITIATING_IVR_STATE]: {
        [RESOLVE_IVR_ACTION]: IDLE_STATE,
        [REJECT_IVR_ACTION]: IDLE_STATE
      },
      [SETTING_PAYMENT_TYPE_STATE]: {
        [LOADED_ACTION]: IDLE_STATE
      },
      [SUBMITTING_STATE]: {
        [RESOLVE_ACTION]: IDLE_STATE,
        [REJECT_ACTION]: IDLE_STATE
      },
      [COMPLETED_STATE]: {
        [EXIT_ACTION]: IDLE_STATE
      }
    }
  };
  const acctTypeOpts = [{
    value: null,
    text: 'Please Select an Account Type'
  }, {
    value: 'C',
    text: 'Checking Account'
  }, {
    value: 'S',
    text: 'Savings Account'
  }];
  _exports.acctTypeOpts = acctTypeOpts;
  const accountTypes = {
    Visa: 'visa',
    MasterCard: 'mastercard',
    Amex: 'amex',
    Discover: 'discover',
    Bank: 'bank'
  };
  _exports.accountTypes = accountTypes;

  const renderPaymentIcon = accountType => {
    if (!accountType) return null;
    let acctType = accountType;
    if (accountType === 'American Express') acctType = 'Amex';
    if (accountType.toLowerCase() === 'mastercard') acctType = 'MasterCard';
    if (accountType === 'ACH') acctType = 'Bank';
    return accountTypes[acctType] || '';
  };

  _exports.renderPaymentIcon = renderPaymentIcon;

  const computeStartPaymentDate = () => {
    const now = new Date();
    return now.getHours() > 20 ? now.tomorrow().setMidnight() : now.setMidnight();
  };

  _exports.computeStartPaymentDate = computeStartPaymentDate;

  const filterAccounts = (savedAccounts, paymentType) => {
    const filter = paymentType === 'bank' ? a => /bank/i.test(a.accountType) : a => !/bank/i.test(a.accountType);
    return (savedAccounts || []).filter(filter);
  };

  const shouldShowCardForm = (type, show, filtered) => type === 'card' && (!show || !filtered.length);

  const isBlocked = (pending, billingSystem) => pending && billingSystem === 'Informix';
  /**
   * Returns account number masked, exposing only last 4 digits
   * @param {Int|String} num
   * @param {Int} len how many digits after mask
   * @return {String}
   * @example maskAcctNum(324987324, 4) -> '***7324';
   */


  const maskAcctNum = (num, len) => {
    const acctNo = num.toString().trim();
    return "****".concat(acctNo.substr(-len));
  };
  /**
   * Form component for basic bill payment
   * @module Components/PaymentForm
   *
   * @prop {Classes/StateMachine} fsm
   * @prop {Services/BillingData} billingData billing-data service
   * @prop {Services/CurrentLocation} currentLocation current-location service
   * @prop {Services/Paymentech} paymentech Paymentech service
   * @prop {PermissionsObject} permissions
   * @prop {String} currentState for StateMachine
   * @prop {Boolean} isFetching
   *
   * @prop {BillingModel.BillInfo} billInfo
   * @prop {Object} bankAcctType 'C' or 'S', used for ACH payments
   * @prop {String} bankAcctType.text
   * @prop {String} bankAcctType.value
   * @prop {Object[]} acctTypeOpts objects used for drop down
   * @prop {String} paymentType 'card' or 'bank'
   * @prop {String} paymentAmount float, but as a string
   * @prop {String} postDate
   * @prop {Computed<Date>} postDateObj
   * @prop {Date} currentDateObj sets to next day if after 9pm
   * @prop {String} confirmationNumber
   *
   * @prop {SavedAccount[]} savedAccounts
   * @prop {Computed<SavedAccount[]>} filteredAccounts returns accounts based on `paymentType`
   * @prop {Boolean} showSavedAccounts shows saved accounts under 'Payment Method'
   * @prop {Boolean} showCardForm shops CC frame if user has no saved payments or chooses add account
   *
   * @prop {Object} achDetails
   * @prop {Object} ccDetails
   * @prop {String} uID
   * @prop {Boolean} uIDReceived true after Paymentech returns data (needed if there is another error with the payment)
   * @prop {Boolean} ccDetailsReceived true after Paymentech returns data (needed if there is another error with the payment)
   * @prop {Object} fieldErrors
   * @prop {Computed<Boolean>} hasPendingPayment
   * @prop {Computed<Boolean>} blockedByPending only MMB customers may make add'l payments when `hasPendingPayment`
   * @prop {String} selectedAcct unique ID of selected account; def: 'new'
   * @prop {Boolean} shouldSaveAcct sets save status for both account types
   */


  _exports.maskAcctNum = maskAcctNum;

  var _default = Ember.Component.extend(_onFormEscHandler.default, {
    classNames: ['one-time-payment-module'],
    attributeBindings: ['selenium-id'],
    'selenium-id': 'payment-form',
    billingData: Ember.inject.service(),
    paymentech: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    csrInfo: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    dsm: Ember.inject.service('dashboard-status-messages'),
    router: Ember.inject.service(),
    currentState: IDLE_STATE,
    activeESCState: IDLE_STATE,
    cancelESCState: EXIT_ACTION,
    rejectCount: 0,
    genericImage: "".concat(_environment.default.rootURL, "images/v2/easypay-promotion/welcome.svg"),
    isFetching: (0, _emberHelpers.computedSome)('currentState', SETTING_PAYMENT_TYPE_STATE, SUBMITTING_STATE),
    positiveBalance: (0, _emberHelpers.computedApplyFunction)(balance => parseFloat(balance) > 0, 'billInfo.balance'),
    savedAccounts: Ember.computed.oneWay('billingData.savedAccounts'),
    billInfo: Ember.computed.readOnly('billingData.billingModel.billInfo'),
    filteredAccounts: (0, _emberHelpers.computedApplyFunction)(filterAccounts, 'savedAccounts', 'paymentType'),
    permissions: Ember.computed.readOnly('currentLocation.permissions'),
    enrolledInEasyPay: Ember.computed.readOnly('billingData.billingModel.billingOptions.enrolledInEasyPay'),
    statusMessages: Ember.computed.alias('dsm.statusMessages'),
    showSavedAccounts: false,
    showCardForm: (0, _emberHelpers.computedApplyFunction)(shouldShowCardForm, 'paymentTab', 'showSavedAccounts', 'filteredAccounts'),
    uIDReceived: false,
    ccDetailsReceived: false,
    hasPendingPayment: Ember.computed.bool('billInfo.pendingPayment'),
    blockedByPending: (0, _emberHelpers.computedApplyFunction)(isBlocked, 'hasPendingPayment', 'billInfo.billingSystem'),
    selectedAcct: 'new',
    shouldSaveAcct: true,
    agentExtension: Ember.computed.oneWay('csrInfo.csr.agentIvrContactNo'),
    ivrType: null,
    achDetails: null,
    ccDetails: null,
    uID: null,
    bankAcctType: null,
    fieldErrors: null,
    acctTypeOpts,
    paymentTab: 'card',
    paymentType: 'card',
    paymentAmount: 0,
    postDate: null,
    postDateObj: Ember.computed('postDate', function () {
      return Date.createDate(this.get('postDate'));
    }),
    confirmationNumber: '',
    ET: Ember.inject.service('event-tagging'),

    /**
     * Update payment data when selectedAcct is changed and populate it on init
     */
    observeSelectedAcct() {
      const {
        selectedAcct,
        achDetails,
        ccDetails
      } = this.getProperties('selectedAcct', 'achDetails', 'ccDetails');
      const ach = {
        routingNumber: '',
        accountNumber: '',
        accountNumber2: '',
        initials: 'WEB',
        accountType: '',
        savePayment: false
      };
      const cc = {
        profileId: '',
        number: '',
        expirationMonth: '',
        expirationYear: '',
        billingZipCode: '',
        nameOnCard: '',
        streetAddress: '',
        savedCardType: '',
        savePayment: false,
        type: ''
      };

      if (selectedAcct !== 'new') {
        this.set('shouldSaveAcct', false);

        if (/bank/i.test(selectedAcct.accountType)) {
          this.set('paymentType', 'bank');
          this.set('achDetails', (0, _fp.omix)(achDetails, {
            routingNumber: selectedAcct.routingNumber,
            accountNumber: selectedAcct.accountNumber,
            accountNumber2: selectedAcct.accountNumber,
            savePayment: false
          }));
          this.set('ccDetails', (0, _emberCopy.copy)(cc));
        } else {
          const expiry = selectedAcct.expiryDate.split('/');
          this.set('paymentType', 'card');
          this.set('ccDetails', (0, _fp.omix)(ccDetails, {
            nameOnCard: selectedAcct.name,
            profileId: selectedAcct.tokenId || selectedAcct.id,
            number: selectedAcct.accountNumber,
            expirationMonth: expiry[0],
            expirationYear: expiry[1],
            billingZipCode: selectedAcct.zip,
            savedCardType: selectedAcct.accountType,
            streetAddress: this.get('currentLocation.profileInfo.address.addressLine1'),
            type: selectedAcct.accountType,
            savePayment: false
          }));
          this.set('achDetails', (0, _emberCopy.copy)(ach));
        }
      } else {
        this.set('achDetails', (0, _emberCopy.copy)(ach));
        this.set('ccDetails', (0, _emberCopy.copy)(cc));
      }
    },

    /**
     * Resets form
     * Clears errors
     */
    reset(skipMessages) {
      let paymentAmount = this.get('billInfo.balance');
      if (parseFloat(paymentAmount) <= 0) paymentAmount = '0.00';
      this.setProperties({
        achDetails: {
          routingNumber: '',
          accountNumber: '',
          accountNumber2: '',
          initials: 'WEB',
          accountType: '',
          savePayment: false
        },
        ccDetails: {
          profileId: '',
          number: '',
          expirationMonth: '',
          expirationYear: '',
          billingZipCode: '',
          nameOnCard: '',
          streetAddress: '',
          savedCardType: '',
          savePayment: false,
          type: ''
        },
        bankAcctType: acctTypeOpts[0],
        fieldErrors: {
          accountType: false,
          routingNumber: false,
          accountNumber: false,
          accountNumber2: false,
          paymentAmount: false,
          postDate: false
        },
        paymentAmount,
        postDate: new Date(),
        shouldSaveAcct: true
      });

      if (!skipMessages) {
        this.set('statusMessages', (this.get('statusMessages') || []).filter(sm => sm.type !== 'error'));
      }
    },

    /**
     * @param {String} type 'bank'(def) | 'card' | 'ivr'
     */
    [SET_PAYMENT_TYPE_ACTION](type) {
      this.setProperties({
        paymentTab: type,
        paymentType: type === 'bank' ? 'bank' : 'card',
        isIvr: type === 'ivr'
      });

      if (!this.get('filteredAccounts').length) {
        this.set('selectedAcct', 'new');
        this.observeSelectedAcct();
      }

      return this.get('fsm').dispatch(LOADED_ACTION, undefined, false);
    },

    /**
     * Sets a saved account as the selected payment method
     * @param {SavedAccount} account
     */
    [SELECT_ACCOUNT_ACTION](account) {
      let savedAccounts = this.get('savedAccounts');
      this.set('selectedAcct', account);
      this.observeSelectedAcct();

      if (account.mostRecentlyUsed) {
        this.get('fsm').dispatch(SET_PAYMENT_TYPE_ACTION, /bank/i.test(account.accountType) ? 'bank' : 'card');
      }

      savedAccounts = savedAccounts.map(el => {
        if (el.uniqueId == account.uniqueId) {
          return (0, _fp.omix)(el, {
            mostRecentlyUsed: true
          });
        }

        return (0, _fp.omix)(el, {
          mostRecentlyUsed: false
        });
      });
      this.set('savedAccounts', savedAccounts);
    },

    initIvrPayment() {
      return this.get('billingData').initIvrPayment({
        agentIVRContactNo: this.get('agentExtension'),
        paymentType: this.get('ivrType') || 'ivrPayment'
      });
    },

    /**
     * Sets payment type to 'C' or 'S'
     * scoped to widget/custom-select instance, so refs `parentView`
     * @param {String} type
     */
    updateAcctType(type) {
      // check if scoped to widget/custom-select instance
      const ach = this.get('achDetails') || this.get('parentView.achDetails');
      this.set('achDetails', ach);
      this.set('achDetails.accountType', type.value);
    },

    /**
     * Creates object to submit to `billingData.payBill`
     * @returns {Object}
     * @example {
    		paymentDetails: {
    			paymentAmount: '50.32',
    			postDate: '06/14/2018',
    			selectedSavedAccountId: '1234',
    			deviceType: 'Web',
    		},
    		achDetails,
    		ccDetails
    	}
     */
    createPaymentInformationObject() {
      const {
        selectedAcct,
        permissions,
        paymentAmount,
        postDateObj,
        achDetails,
        ccDetails,
        isIvr,
        ivrType
      } = this.getProperties('selectedAcct', 'permissions', 'paymentAmount', 'postDateObj', 'achDetails', 'ccDetails', 'isIvr', 'ivrType');

      if (!permissions.savedPayments || selectedAcct !== 'new') {
        this.set('shouldSaveAcct', false);
      }

      const paymentData = {
        paymentDetails: {
          paymentAmount: parseFloat(paymentAmount).toFixed(2),
          postDate: postDateObj.toUSAString('/'),
          selectedSavedAccountId: selectedAcct.id || null,
          deviceType: 'Web'
        },
        paymentType: isIvr === 'ivr' ? ivrType || 'ivrPayment' : null,
        achDetails,
        ccDetails: {
          profileId: ccDetails ? ccDetails.profileId : '',
          uID: this.get('uID'),
          savePayment: ccDetails.savePayment
        }
      };

      if (selectedAcct && selectedAcct !== 'new') {
        this.set('paymentType', /bank/i.test(selectedAcct.accountType) ? 'bank' : 'card');
      }

      const omission = this.get('paymentType') === 'bank' || isIvr && ivrType === 'ivrBankAccount' ? 'ccDetails' : 'achDetails';
      return (0, _fp.omit)(paymentData, omission);
    },

    /**
     * Submits payment info, and CC/ACH details through billingData
     * Opens confirmation modal if validation passes
     * else dispatches `rejectPayment`
     */
    [SUBMIT_ACTION](isAmountField) {
      const {
        fsm,
        paymentType,
        selectedAcct
      } = this.getProperties('fsm', 'paymentType', 'selectedAcct');

      if (isAmountField) {
        this.actions.parseDollarToFloat.call(this);
      }

      if (this.get('blockedByPending')) {
        return fsm.dispatch(REJECT_ACTION, {
          errors: null
        }, this.get('media.isSmartphone'));
      }

      const validation = this.validatePaymentDetails(paymentType, selectedAcct !== 'new');
      this.set('fieldErrors', validation.fe);

      if (validation.valid) {
        return this.showConfirmationModal();
      }

      return fsm.dispatch(REJECT_ACTION, {
        errors: validation.errors
      }, this.get('media.isSmartphone'));
    },

    /**
     * Displays all payment data before submitting
     * Shows overpayment warning
     */
    showConfirmationModal() {
      const paymentData = this.createPaymentInformationObject();
      const {
        paymentType,
        paymentAmount,
        postDateObj,
        achDetails,
        ccDetails,
        uID,
        shouldSaveAcct,
        positiveBalance,
        enrolledInEasyPay
      } = this.getProperties('paymentType', 'paymentAmount', 'postDateObj', 'achDetails', 'ccDetails', 'uID', 'shouldSaveAcct', 'positiveBalance', 'enrolledInEasyPay');
      const mwd = this.get('mwd');
      let maskedAcctNum = '';

      if (paymentType === 'bank') {
        const achTypes = {
          C: 'Checking',
          S: 'Savings'
        };
        const accountType = achTypes[achDetails.accountType] || 'Bank';
        maskedAcctNum = "".concat(accountType, " Account Number ").concat(maskAcctNum(achDetails.accountNumber, 4));
        achDetails.savePayment = shouldSaveAcct;
      } else {
        maskedAcctNum = ccDetails.number || '';
      }

      const onClose = () => {
        this.get('fsm').dispatch(REJECT_ACTION, {
          errors: []
        });
        mwd.hide();
      };

      mwd.show({
        name: 'confirm-expresspay',
        title: positiveBalance && !enrolledInEasyPay ? 'Confirm Payment' : 'Confirm Additional Payment',
        body: {
          paymentType,
          paymentAmount,
          paymentDateObj: postDateObj,
          achDetails,
          ccDetails: {
            profileId: ccDetails ? ccDetails.profileId : '',
            uID,
            savePayment: shouldSaveAcct
          },
          maskedAcctNum,
          overpay: paymentAmount > this.get('billInfo.balance'),
          billInfo: this.get('billInfo')
        },
        isFooterLine: false,
        onClose,
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: 'Submit Payment',
          callback: () => {
            mwd.hide();
            return this.get('billingData').payBill(paymentData).then(response => {
              this.get('fsm').dispatch(RESOLVE_ACTION, response);
            }).catch(results => {
              this.get('fsm').dispatch(REJECT_ACTION, results, this.get('media.isSmartphone'));
            });
          }
        }]
      });

      if (parseFloat(paymentAmount) > parseFloat(this.get('billInfo.balance'))) {
        mwd.showWarn('This payment will bring the amount due below zero, creating a credit balance.');
      }
    },

    /**
     * Validates payment details
     * @param {String} type
     * @returns {Object}
     * @example validatePaymentDetails('bank', false) ->
     * {
     * 	valid: false,
     * 	fe: {
     * 		accountType: false,
     * 		routingNumber: true,
     * 		accountNumber: false,
     * 		accountNumber2: false,
     * 		paymentAmount: false,
     * 		postDate: false
     * 	}
     * 	errors: ['Payment.errorRoutingNumberInvalid']
     * }
     */
    validatePaymentDetails(type, savedAccout) {
      if (type !== 'bank' && type !== 'card') return {
        valid: false
      };
      let valid = true;
      const fe = {
        accountType: false,
        routingNumber: false,
        accountNumber: false,
        accountNumber2: false,
        paymentAmount: false,
        postDate: false
      };
      const errors = [];
      let missingInfo = false;
      const {
        paymentAmount,
        postDateObj,
        postDate,
        showSavedAccounts,
        achDetails,
        ccDetails,
        currentDateObj: today,
        billInfo: {
          balance,
          billingSystem
        }
      } = this.getProperties('paymentAmount', 'postDateObj', 'postDate', 'showSavedAccounts', 'achDetails', 'ccDetails', 'currentDateObj', 'billInfo');
      const details = type === 'bank' ? achDetails : ccDetails;
      this.set('statusMessages', null);

      if (!savedAccout) {
        Object.keys(details).forEach(key => {
          if (!details[key] && !/save/.test(key) && !/name/.test(key)) {
            missingInfo = true;
            fe[key] = true;
          } else if (key === 'accountType' && details[key] === acctTypeOpts[0]) {
            missingInfo = true;
            fe[key] = true;
          }
        });
      }

      if (type === 'bank') {
        if (details.routingNumber && details.routingNumber.length !== 9) {
          errors.push((0, _localization.toExpandedString)('Payment.errorRoutingNumberInvalid'));
          fe.routingNumber = true;
        }

        if (details.accountNumber && details.accountNumber2 && details.accountNumber !== details.accountNumber2) {
          errors.push((0, _localization.toExpandedString)('Payment.errorBankAccountNumbersNoMatch'));
          fe.accountNumber2 = true;
        }
      }

      const amt = parseFloat(paymentAmount);

      if (!amt) {
        missingInfo = true;
        fe.paymentAmount = true;
      } else {
        const due = parseFloat(balance);

        if (amt > 5000 && due < amt) {
          errors.push((0, _localization.toExpandedString)('Payment.errorPaymentMoreThanFiveThousand'));
          fe.paymentAmount = true;
        } else if (amt > 99999.99) {
          errors.push((0, _localization.toExpandedString)('Payment.errorPaymentMoreThanHundredThousand'));
          fe.paymentAmount = true;
        } else if (amt > 10000 && billingSystem !== 'MMB') {
          errors.push((0, _localization.toExpandedString)('Payment.errorPaymentMoreThanTenThousand'));
          fe.paymentAmount = true;
        } else if (amt < 5 && (due <= 0 || due >= 5)) {
          errors.push((0, _localization.toExpandedString)('Payment.errorPaymentLessThanFive'));
          fe.paymentAmount = true;
        }
      }

      if (!postDate) {
        missingInfo = true;
        fe.postDate = true;
      } else if (/invalid/i.test(postDateObj)) {
        errors.push((0, _localization.toExpandedString)('Payment.errorPostDateInvalid'));
        fe.postDate = true;
      } else if (postDateObj < today || postDateObj.timeSpan(today).days > 30) {
        errors.push((0, _localization.toExpandedString)('Payment.errorPostDateRange'));
        fe.postDate = true;
      }

      if (missingInfo) {
        errors.push((0, _localization.toExpandedString)(showSavedAccounts && fe.accountNumber ? 'Choose a payment method' : 'General.errorAllFieldsRequired'));
      }

      valid = !Object.keys(fe).some(key => fe[key] === true);
      return {
        valid,
        fe,
        errors
      };
    },

    /**
     * Sets success message, and `confirmationNumber`
     * @param {Object} data
     * @deprecated for `payment-options` component?
     */
    [RESOLVE_ACTION](data) {
      if (this.get('shouldSaveAcct')) {
        this.get('billingData').getSavedAccounts();
      }

      this.send('routeRefresh');
      this.get('fsm').dispatch(EXIT_ACTION);
      this.set('billingData.afterPaymentState', true);
      this.set('confirmationNumber', data.confirmationNumber);

      if (data.displayEasyPaySignupModal) {
        this.showModal(this.genericImage);
      }

      if (data.displayEasyPaySignupBanner) {
        this.get('appDispatcher').sendEvent(_appDispatcher.ONE_TIME_PAYMENT_EVENT);
      }

      this.set('statusMessages', [new _statusMessage.default({
        head: 'Payment successfully submitted.',
        text: "Confirmation: ".concat(data.confirmationNumber)
      }, {
        type: 'success',
        static: true
      }, 50000, data.confirmationNumber, this.get('router.currentRouteName'))]);
    },

    promotionEvent(event) {
      return this.get('billingData').promotionEvent({
        event
      });
    },

    showModal(genericImage) {
      const mwd = this.get('mwd');

      const onClose = () => {
        mwd.hide();
        this.promotionEvent('X');
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'EasyPay Promotion', 'X', '1 time payment']);
        this.get('ET').sendEventTag({
          eventTypeId: 'EZPAYPRO',
          subEventTypeId: 'ALRTCLSD',
          udf2: '1 x PAYMENT'
        }, false, '1');
      };

      const onEnroll = () => {
        mwd.hide();
        this.get('appDispatcher').sendEvent(_appDispatcher.SHOW_EASYPAY_ENROLL_EVENT);
        this.promotionEvent('ENROLL');
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'EasyPay Promotion', 'Enroll in EasyPay', '1 time payment']);
      };

      const onRemind = () => {
        mwd.hide();
        this.promotionEvent('REMIND_ME_LATER');
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'EasyPay Promotion', 'Remind me Later', '1 time payment']);
        this.get('ET').sendEventTag({
          eventTypeId: 'EZPAYPRO',
          subEventTypeId: 'CLKDRLTR',
          udf2: '1 x PAYMENT'
        }, false, '1');
      };

      const onRedirect = () => {
        this.get('router').transitionTo('index.usageagreement');
      };

      mwd.show({
        name: 'easypay-notification',
        title: 'Payment Successfully Submitted',
        body: {
          genericImage
        },
        onClose,
        actions: [{
          caption: 'Enroll in EasyPay',
          color: 'blue',
          callback: onEnroll
        }, {
          caption: 'Remind me later',
          stl: 'outline',
          callback: onRemind
        }, {
          caption: 'Terms and conditions',
          stl: 'outline',
          callback: onRedirect
        }]
      });
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'EasyPay Promotion', 'Modal pops', '1 time payment']);
      this.get('ET').sendEventTag({
        eventTypeId: 'EZPAYPRO',
        subEventTypeId: 'ALRTDISP',
        udf2: '1 x PAYMENT'
      }, false, '1');
    },

    /**
     * @param {Object} results
     */
    [REJECT_ACTION](results) {
      this.incrementProperty('rejectCount');

      if (this.get('rejectCount') >= 2) {
        const pypemsg = [{
          attribute: 'error',
          label: 'error',
          value: 'payment error',
          visible: 'wpm'
        }];
        window.Pypestream('config', {
          passthrough: JSON.stringify(pypemsg)
        });
        console.log('Pypestream msg sent: payment error');
      }

      this.handleErrors(results);
    },

    [EXIT_ACTION]() {
      this.reset();
      this.get('router').transitionTo('dashboard.account.payments');
    },

    /**
     * Handles error messaging
     * @param {Object} results
     * @param {String[]} results.errors error messages or localization keys
     * @param {Boolean} [isHpf] indicates if errors came from Paymentech
     */
    handleErrors(results) {
      const errors = [];
      const generic = 'Generic.error';
      let hasGeneric = false;

      if (!results.errors) {
        errors.push(new _statusMessage.default('Generic.error', 'error'));
      } else if (Array.isArray(results.errors)) {
        results.errors.forEach(error => {
          if (error === generic && hasGeneric) return;
          if (error === generic) hasGeneric = true;
          if (results.isHpf) error = "Paymentech.Errors.".concat(error);
          errors.push(new _statusMessage.default(error, 'error'));
        });
      } else {
        if (results.isHpf) results.errors = "Paymentech.Errors.".concat(results.errors);
        errors.push(new _statusMessage.default(results.errors, 'error'));
      }

      this.set('statusMessages', errors);
      return errors;
    },

    actions: {
      /**
       * Passthrough to {@link Classes/StateMachine.dispatch}
       * @param {String} action
       * @param {*} data
       * @param {Boolean} scroll
       */
      dispatch(action, data, scroll) {
        this.get('fsm').dispatch(action, data, scroll);
      },

      toggleSavedAccounts() {
        this.toggleProperty('showSavedAccounts');

        if (!this.get('showSavedAccounts')) {
          this.setProperties({
            selectedAcct: 'new',
            shouldSaveAcct: true
          });
        }
      },

      routeRefresh() {
        // eslint-disable-next-line ember/closure-actions
        this.sendAction('routeRefresh');
      },

      parseDollarToFloat() {
        this.set('paymentAmount', parseFloat(this.get('paymentAmount') || 0).toFixed(2));
      },

      renderPaymentIcon
    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      this.get('billingData').getSavedAccounts();
    },

    /**
     * @param {Object} errors
     */
    [_appDispatcher.CRE_ERROR_EVENT](errors) {
      this.get('fsm').dispatch(REJECT_ACTION, {
        errors,
        isHpf: true
      }, true);
    },

    /**
     * @param {Object} transaction
     */
    [_appDispatcher.CRE_COMPLETE_PAYMENT_EVENT](transaction) {
      this.set('uIDReceived', true);
      this.set('uID', transaction.uID);
      return this.get('billingData').getPayConfDetails({
        flowId: 'ONETIME_PAY',
        uID: transaction.uID
      }).then(response => {
        const rData = response.data;
        const cardNum = "".concat(rData.cardType, " ending in ").concat(rData.lastFourOfCC);
        return this.selectAccount({
          type: rData.cardType,
          number: cardNum,
          uID: transaction.uID
        });
      }).then(() => this.get('fsm').dispatch(SUBMIT_ACTION));
    },

    [_appDispatcher.ONE_TIME_PAYMENT_SUCCESSFUL](data) {
      if (data.displayEasyPaySignupModal) {
        this.showModal(this.genericImage);
      }

      if (data.displayEasyPaySignupBanner) {
        this.get('appDispatcher').sendEvent(_appDispatcher.ONE_TIME_PAYMENT_EVENT);
      }
    },

    willDestroyElement() {
      const appDispatcher = this.get('appDispatcher');
      appDispatcher.unsubscribe(_appDispatcher.CRE_ERROR_EVENT, this, _appDispatcher.CRE_ERROR_EVENT);
      appDispatcher.unsubscribe(_appDispatcher.CRE_COMPLETE_PAYMENT_EVENT, this, _appDispatcher.CRE_COMPLETE_PAYMENT_EVENT);
      appDispatcher.unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    init() {
      this._super(...arguments);

      const appDispatcher = this.get('appDispatcher');
      this.keyDown = this.onEscHandler.bind(this);
      appDispatcher.subscribe(_appDispatcher.CRE_ERROR_EVENT, this, _appDispatcher.CRE_ERROR_EVENT);
      appDispatcher.subscribe(_appDispatcher.CRE_COMPLETE_PAYMENT_EVENT, this, _appDispatcher.CRE_COMPLETE_PAYMENT_EVENT);
      appDispatcher.subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
      appDispatcher.subscribe(_appDispatcher.ONE_TIME_PAYMENT_SUCCESSFUL, this, _appDispatcher.ONE_TIME_PAYMENT_SUCCESSFUL); // Settings

      fsmModel.scrollToTop = this.get('media.isMobile');
      fsmModel.context = this;
      this.fsm = _stateMachine.default.create(fsmModel);
      this.currentDateObj = computeStartPaymentDate();
      this.set('rejectCount', 0); // actual initializing

      this.reset(true);
      const {
        savedAccounts,
        permissions
      } = this.getProperties('savedAccounts', 'permissions');
      (savedAccounts || []).forEach(acc => {
        if (acc.mostRecentlyUsed) {
          this.get('fsm').dispatch(SELECT_ACCOUNT_ACTION, acc);
        }
      });

      const shouldShowSavedAccts = () => {
        if (savedAccounts && permissions.savedPayments) {
          this.set('showSavedAccounts', true);
        }
      };

      if (!savedAccounts && permissions.savedPayments) {
        this.get('billingData').getSavedAccounts().then(shouldShowSavedAccts).catch(console.error);
      } else {
        shouldShowSavedAccts();
      }
    }

  });

  _exports.default = _default;
});