define("adt-wss/pods/index/home/route", ["exports", "adt-wss/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    rootURL: _environment.default.rootURL,
    session: Ember.inject.service(),
    apiService: Ember.inject.service(),
    authInfo: Ember.inject.service(),

    didRender() {
      document.getElementById('base-iframe').onload = window.helpIframeOnload;
    },

    afterModel() {
      const session = this.get('session');

      if (!this.get('authInfo.authInfo.csrUser')) {
        if (session.isAuthenticated) {
          session.invalidate(false, 'Invalidate on landing');
        } else {
          this.get('apiService').myADT.invalidateSession();
        }
      }
    }

  });

  _exports.default = _default;
});