define("adt-wss/services/state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Originally intended to manage just the loading state
   * TODO: Handle more sitewide states than that
   * @module Services/State
   * @prop {Int} count
   * @prop {Boolean} isLoading true if `count` > 0
   */
  var _default = Ember.Service.extend({
    count: 0,
    isLoading: Ember.computed('count', function () {
      return this.get('count') > 0;
    }),

    /**
     * Increases `count` by 1
     * @instance
     */
    incLoading() {
      this.incrementProperty('count');
    },

    /**
     * Decreases `count` by 1
     * @instance
     */
    decLoading() {
      if (this.get('count') > 0) this.decrementProperty('count');
    }

  });

  _exports.default = _default;
});