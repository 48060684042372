define("adt-wss/services/user-idle", ["exports", "ember-user-activity/services/user-idle"], function (_exports, _userIdle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userIdle.default.extend({
    csrInfo: Ember.inject.service(),
    IDLE_TIMEOUT: 300000
  });

  _exports.default = _default;
});