define("adt-wss/pods/components/ps/payment-form/component", ["exports", "adt-wss-common/classes/fsm-component", "ember-copy", "adt-wss-common/utils/localization", "adt-wss-common/classes/status-message", "adt-wss-common/utils/myadt-helpers"], function (_exports, _fsmComponent, _emberCopy, _localization, _statusMessage, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  const CRE_ERROR_EVENT = 'CRE_ERROR_EVENT';
  const CRE_COMPLETE_PAYMENT_EVENT = 'CRE_COMPLETE_PAYMENT_EVENT';
  const IDLE_STATE = 'IDLE_STATE';
  const LOCATING_STATE = 'LOCATING_STATE';
  const READY_STATE = 'READY_STATE';
  const VALIDATING_STATE = 'VALIDATING_STATE';
  const CONFIRMATION_STATE = 'CONFIRMATION_STATE';
  const SUBMITTING_STATE = 'FETCHING_STATE';
  const LOCATE_ACTION = 'LOCATE_ACTION';
  const VALIDATE_ACTION = 'VALIDATE_ACTION';
  const VALIDATED_ACTION = 'VALIDATED_ACTION';
  const INVALIDATED_ACTION = 'INVALIDATED_ACTION';
  const SUBMIT_ACTION = 'SUBMIT_ACTION';
  const RESET_ACTION = 'RESET_ACTION';
  const REJECT_LOCATE_ACTION = 'REJECT_LOCATE_ACTION';
  const RESOLVE_LOCATE_ACTION = 'RESOLVE_LOCATE_ACTION';
  const REJECT_PAYMENT_ACTION = 'REJECT_PAYMENT_ACTION';
  const RESOLVE_PAYMENT_ACTION = 'RESOLVE_PAYMENT_ACTION';
  const defaultProps = {
    achDetails: {
      routingNumber: '',
      accountNumber: '',
      accountNumber2: '',
      initials: 'WEB',
      accountType: 'C'
    },
    ccDetails: {
      number: null,
      type: null
    },
    bankAcctType: {
      value: 'C',
      text: 'Checking Account'
    },
    fieldErrors: {
      acctNum: false,
      zip: false,
      accountType: false,
      routingNumber: false,
      accountNumber: false,
      accountNumber2: false,
      paymentAmount: false,
      paymentDate: false,
      uuid: false
    }
  };
  /**
   * Returns account number masked, exposing only last 4 digits
   * @param {Int|String} num
   * @param {Int} len how many digits after mask
   * @return {String}
   * @example this.maskAcctNum(324987324, 4) -> '***7324';
   */

  const maskAcctNum = (num, _len) => {
    const len = _len || 4;
    const acctNo = num.toString().trim();
    return "****".concat(acctNo.substr(-len));
  };
  /**
   * Form component for route 'ps.expresspay'
   * @module Components/Pypestream/ExpressPay
   *
   * @prop {Classes/StateMachine} fsm
   * @prop {Services/ExpressPayService} EPS expresspay-service
   * @prop {Services/Paymentech} paymentech
   * @prop {String} currentState for StateMachine
   * @prop {Int|String} adtAcctNo
   * @prop {Int|String} billingZipCode
   * @prop {Object[]} acctTypeOpts
   * @prop {Object} bankAcctType
   * @prop {String} bankAcctType.text
   * @prop {String} bankAcctType.value
   * @prop {String} paymentType
   * @prop {String} paymentAmount float, but as a string
   * @prop {String} paymentDate
   * @prop {Object} fieldErrors
   * @prop {String} confirmationNumber
   * @prop {String[]} statusMessages
   * @prop {String[]} formErrorMessage
   * @prop {Object} achDetails
   * @prop {Object} ccDetails
   * @prop {String} uID
   * @prop {Boolean} uIDReceived true after Paymentech returns data (needed if there is another error with the payment)
   * @prop {Boolean} ccDetailsReceived true after MyADT BE returns data
   * @prop {String} maskedAcctNum masked payment account number
   */


  let PaymentForm = (_dec = Ember.inject.service('expresspay-service'), _dec2 = Ember.computed('currentState'), _dec3 = Ember.computed('_statusMessages'), _dec4 = Ember.computed('paymentDate'), (_class = (_temp = class PaymentForm extends _fsmComponent.default {
    get paymentType() {
      return this.isCC ? 'card' : 'bank';
    }

    get isLoading() {
      return /ing/i.test(this.currentState);
    }

    get statusMessages() {
      return this._statusMessages;
    }

    get paymentDateObj() {
      return Date.createDate(this.paymentDate);
    }

    get maskedAcctNum() {
      return this.isCC ? maskAcctNum(this.ccDetails.number) : maskAcctNum(this.achDetails.accountNumber);
    }

    constructor() {
      super(...arguments);
      this.elementId = 'psPaymentForm';

      _initializerDefineProperty(this, "EPS", _descriptor, this);

      _initializerDefineProperty(this, "paymentech", _descriptor2, this);

      _initializerDefineProperty(this, "currentLocation", _descriptor3, this);

      _initializerDefineProperty(this, "apiService", _descriptor4, this);

      this.isPS = true;
      this.isSubmitting = false;
      this.flowId = 'EXP_PAY';
      this.acctTypeOpts = [{
        value: 'C',
        text: 'Checking Account'
      }, {
        value: 'S',
        text: 'Savings Account'
      }];
      this.rejectCount = 0;

      _initializerDefineProperty(this, "currentState", _descriptor5, this);

      _initializerDefineProperty(this, "fieldErrors", _descriptor6, this);

      _initializerDefineProperty(this, "achDetails", _descriptor7, this);

      _initializerDefineProperty(this, "bankAcctType", _descriptor8, this);

      _initializerDefineProperty(this, "ccDetails", _descriptor9, this);

      _initializerDefineProperty(this, "uID", _descriptor10, this);

      _initializerDefineProperty(this, "confirmationNumber", _descriptor11, this);

      this._statusMessages = [];
      this.fsmModel = {
        scrollToTop: true,
        transitions: {
          [IDLE_STATE]: {
            [LOCATE_ACTION]: LOCATING_STATE
          },
          [LOCATING_STATE]: {
            [RESOLVE_LOCATE_ACTION]: READY_STATE,
            [REJECT_LOCATE_ACTION]: IDLE_STATE
          },
          [READY_STATE]: {
            [VALIDATE_ACTION]: VALIDATING_STATE,
            [VALIDATED_ACTION]: CONFIRMATION_STATE,
            [REJECT_PAYMENT_ACTION]: READY_STATE,
            [RESET_ACTION]: READY_STATE
          },
          [VALIDATING_STATE]: {
            [VALIDATED_ACTION]: CONFIRMATION_STATE,
            [INVALIDATED_ACTION]: READY_STATE
          },
          [CONFIRMATION_STATE]: {
            [SUBMIT_ACTION]: SUBMITTING_STATE,
            [RESET_ACTION]: READY_STATE
          },
          [SUBMITTING_STATE]: {
            [RESOLVE_PAYMENT_ACTION]: SUBMITTING_STATE,
            [REJECT_PAYMENT_ACTION]: READY_STATE
          }
        },
        context: this
      };
    }
    /**
     * Resets form to account search
     * Clears errors
     */


    [RESET_ACTION]() {
      this.setProperties({
        _statusMessages: null,
        fieldErrors: (0, _emberCopy.copy)(defaultProps.fieldErrors),
        achDetails: (0, _emberCopy.copy)(defaultProps.achDetails),
        ccDetails: null,
        uID: null,
        uIDReceived: false,
        ccDetailsReceived: false
      });
    }

    [LOCATE_ACTION]() {
      return this.currentLocation.getAccountInfo().then(() => this.fsm.dispatch(RESOLVE_LOCATE_ACTION)).catch(() => this.fsm.dispatch(RESOLVE_LOCATE_ACTION));
    }

    [RESOLVE_LOCATE_ACTION]() {
      if (this.uID) {
        this.CRE_COMPLETE_PAYMENT_EVENT({
          uID: this.uID
        });
      }
    }

    [REJECT_LOCATE_ACTION](data) {
      return this.handleErrors(data);
    }

    [VALIDATE_ACTION]() {
      const validation = this._validatePaymentDetails(this.paymentType);

      this.fieldErrors = validation.fe;

      if (validation.valid) {
        return this.fsm.dispatch(VALIDATED_ACTION);
      }

      return this.fsm.dispatch(INVALIDATED_ACTION, validation.errors);
    }

    [INVALIDATED_ACTION](errors) {
      this._statusMessages = errors.map(e => new _statusMessage.default(e, 'error'));
      return true;
    }

    [INVALIDATED_ACTION](errors) {
      this.handleErrors(errors);
    }
    /**
     * Validates payment details
     * @param {String} type
     * @returns {Object}
     * @example validatePaymentDetails('bank') ->
     * {
     * 	valid: false,
     * 	fe: {
     * 		accountType: false,
     * 		routingNumber: true,
     * 		accountNumber: false,
     * 		accountNumber2: false,
     * 		paymentAmount: false,
     * 		paymentDate: false
     * 	}
     * 	errors: ['Payment.errorRoutingNumberInvalid']
     * }
     */


    _validatePaymentDetails() {
      let valid = true;
      const fe = {
        accountType: false,
        routingNumber: false,
        accountNumber: false,
        accountNumber2: false,
        paymentAmount: false,
        paymentDate: false,
        uuid: false
      };
      const errors = [];
      let missingInfo = false;
      const details = this.isCC ? {
        uID: this.uID
      } : this.achDetails;
      Ember.set(this, '_statusMessages', []);
      Object.keys(details).forEach(key => {
        if (!details[key]) {
          missingInfo = true;
          fe[key] = true;
        }
      });

      if (!this.isCC) {
        if (details.routingNumber && details.routingNumber.length !== 9) {
          errors.push((0, _localization.toExpandedString)('Payment.errorRoutingNumberInvalid'));
          fe.routingNumber = true;
        }

        if (details.accountNumber && details.accountNumber2 && details.accountNumber !== details.accountNumber2) {
          errors.push((0, _localization.toExpandedString)('Payment.errorBankAccountNumbersNoMatch'));
          fe.accountNumber2 = true;
        }
      }

      if (missingInfo) {
        errors.push((0, _localization.toExpandedString)('General.errorAllFieldsRequired'));
      }

      valid = !Object.keys(fe).some(key => fe[key] === true);
      return {
        valid,
        fe,
        errors
      };
    }
    /**
     * Submits payment info, and CC/ACH details through EPS
     * if validation passes, else dispatches `REJECT_PAYMENT_ACTION`
     */


    [SUBMIT_ACTION]() {
      const {
        paymentType,
        paymentAmount,
        paymentDateObj,
        achDetails,
        uID
      } = this;
      const paymentData = {
        paymentType,
        paymentDetails: {
          paymentAmount: parseFloat(paymentAmount).toFixed(2),
          postDate: paymentDateObj.toUSAString('/'),
          deviceType: 'Web'
        },
        achDetails,
        uID
      };
      return this.EPS.payBillPS(paymentData).then(data => this.fsm.dispatch(RESOLVE_PAYMENT_ACTION, data)).catch(results => this.fsm.dispatch(REJECT_PAYMENT_ACTION, results));
    }
    /**
     * Sets success message, and `confirmationNumber`
     * @param {Object} data
     * @param {String} data.confirmationNumber
     */


    [RESOLVE_PAYMENT_ACTION](data) {
      window.top.postMessage({
        epConfirmationNumber: data.confirmationNumber
      }, '*');
      setTimeout(() => {
        if (/www.myadt|\/\/myadt.com/i.test(document.location.href)) {
          window.location.replace('https://webchat.pypestream.com/redirect.html');
        } else {
          window.location.replace('https://webchat-sandbox.pypestream.com/redirect.html');
        }
      }, 5000);
    }
    /**
     * Handles payment rejection
     * Posts message to Pypestream if second rejection
     * @param {Object} results
     * @param {String[]} results.errors error messages or localization keys
     * @param {Boolean} [results.isHpf] indicates if errors came from Paymentech
     */


    [REJECT_PAYMENT_ACTION](results) {
      this.rejectCount += 1;

      if (this.rejectCount > 1) {
        window.top.postMessage({
          psError: 'error-expresspay-ps'
        }, '*');
      }

      this.handleErrors(results);
    }
    /**
     * Handles error messaging
     * @param {Object} results
     * @param {String[]} results.errors error messages or localization keys
     * @param {Boolean} [results.isHpf] indicates if errors came from Paymentech
     */


    handleErrors(results) {
      const errors = (0, _myadtHelpers.genericErrorHandler)(results);
      Ember.set(this, '_statusMessages', errors.map(e => new _statusMessage.default(e, 'error')));
    }

    [CRE_ERROR_EVENT](errors) {
      this.fsm.dispatch(REJECT_PAYMENT_ACTION, {
        errors,
        isHpf: true
      }, true);
    }
    /**
     * @param {Object} transaction
     * @param {String} transaction.uID
     */


    [CRE_COMPLETE_PAYMENT_EVENT](transaction) {
      this.uIDReceived = true;
      this.uID = transaction.uID;
      this.EPS.uID = transaction.uID;
      return this.EPS.getPayConfDetails({
        flowId: this.flowId,
        uID: transaction.uID
      }).then(response => {
        const rData = response.data;
        const cardNum = "".concat(rData.cardType, " ending in ").concat(rData.lastFourOfCC);
        this.ccDetails = {
          type: rData.cardType,
          number: cardNum
        };
        return rData;
      }).then(rData => this.fsm.dispatch(VALIDATED_ACTION, rData));
    }

    willDestroy() {
      this.EPS.clear();
      this.appDispatcher.unsubscribe(CRE_ERROR_EVENT, this, CRE_ERROR_EVENT);
      this.appDispatcher.unsubscribe(CRE_COMPLETE_PAYMENT_EVENT, this, CRE_COMPLETE_PAYMENT_EVENT);
    }

    didReceiveAttrs() {
      this.rejectCount = 0;
      this.appDispatcher.subscribe(CRE_ERROR_EVENT, this, CRE_ERROR_EVENT);
      this.appDispatcher.subscribe(CRE_COMPLETE_PAYMENT_EVENT, this, CRE_COMPLETE_PAYMENT_EVENT);
      this.fsm.dispatch(LOCATE_ACTION);
    }

    /**
     * Passthrough to {@link Classes/StateMachine.dispatch} `RESET_ACTION`
     */
    reset() {
      this.fsm.dispatch(RESET_ACTION, null, true);
    }

    /**
     * Passthrough to {@link Classes/StateMachine.dispatch} `SUBMIT_ACTION`
     */
    validatePaymentDetails() {
      this.achDetails.accountType = this.bankAcctType.value;
      this.fsm.dispatch(VALIDATE_ACTION, null, true);
    }

    /**
     * Passthrough to {@link Classes/StateMachine.dispatch} `SUBMIT_ACTION`
     */
    submitPaymentDetails() {
      if (!this.isSubmitting) {
        this.isSubmitting = true;
        this.fsm.dispatch(SUBMIT_ACTION, null, true);
      }

      setTimeout(() => this.isSubmitting = false, 500);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "EPS", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "paymentech", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentLocation", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "apiService", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentState", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return IDLE_STATE;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isLoading"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fieldErrors", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        acctNum: false,
        zip: false,
        accountType: false,
        routingNumber: false,
        accountNumber: false,
        accountNumber2: false,
        paymentAmount: false,
        paymentDate: false,
        uuid: false
      };
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "achDetails", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return defaultProps.achDetails;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "bankAcctType", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.acctTypeOpts[0];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "ccDetails", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return defaultProps.ccDetails;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "uID", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "confirmationNumber", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "statusMessages", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "statusMessages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "paymentDateObj", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "paymentDateObj"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validatePaymentDetails", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "validatePaymentDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitPaymentDetails", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "submitPaymentDetails"), _class.prototype)), _class));
  _exports.default = PaymentForm;
});