define("adt-wss/pods/components/dashboard/account/payment-amount-entry/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    paymentDate: new Date(),
    paymentAmount: '0.00',
    setAmount: v => v
  };
  /**
   * @module Components/Dashboard/Account/PaymentAmountEntry
   * @prop {Date} paymentDate
   * @prop {String} paymentAmount
   * @prop {Function} setAmount
   * @prop {Date} startDate
   * @prop {Object} errors
   * @example
   * {@lang xml} {{ dashboard/account/payment-amount-entry
  		setAmount=body.updateAmount
  		paymentAmount=body.data.amount
  		paymentDate=body.data.paymentDate
  		startDate=body.currentDateObj
  		errors=body.errors
  }}
   */

  var _default = Ember.Component.extend(defaultProps, {
    tagName: 'dl',
    classNames: ['payment-details-entering']
  });

  _exports.default = _default;
});