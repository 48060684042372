define("adt-wss/pods/ps/easypay/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EasypayRoute extends Ember.Route {}

  _exports.default = EasypayRoute;
});