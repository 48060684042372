define("adt-wss/pods/components/widget/tool-tip/component", ["exports", "adt-wss-common/mixins/with-fsm", "jquery"], function (_exports, _withFsm, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * ### Widget tool-tip
   * > by default font-family: ADTMedium (some icons instead standart font)
   * @module  Widget/ToolTip
   * @property {String} icon:'&#x24D8;' - Supports HTML string as icon use for inline mode
   * @property {String} [iconType] - Css class for icon
   * @property {String} tip - Supports HTML string as tip text use for inline mode
   * @property {String} [tipType] - Css class for tip window style
   * @property {String} [tipPosition] - Css class for tip window position: top, bottom, leftside, rightside
   * @property {Number} [width] - Width of tip window
   * @property {Number} [height] - Height of tip window
   * @property {String} [style] - Style attribute for icon
   * @example
   * {@lang xml} {{ widget/tool-tip tip='"full inline mode"' }}
   *
   *
   * @example
   * {@lang xml}
   * {{#widget/tool-tip
  	icon='x'
  	iconType=args.iconType
  	width='100'
  	height='200'
  	tipType=args.tipType as |tt|}}
  	{{tt.icon}}
  	{{#tt.tip}}
  		inline icon and block tip
  	{{/tt.tip}}
  {{/widget/tool-tip}}
  
  
  @example
  {@lang xml}
  {{#widget/tool-tip
  	iconType='Some css class'
  	width='100'
  	height='200'
  	tipType='also some css class' as |tt|}}
  
  	{{#tt.icon style='font-family: arial'}}
  		icon
  	{{/tt.icon}}
  
  	{{#tt.tip}}
  		block mode
  	{{/tt.tip}}
  {{/widget/tool-tip}}
   */
  var _default = Ember.Component.extend(_withFsm.default, {
    classNameBindings: ['clickOpen:click-open:hover-open', 'open', 'hideMobile:hide-mobile'],
    classNames: ['tool-tip'],
    icon: null,
    tipPosition: 'rightside',
    height: 'auto',
    hideMobile: true,
    stopPropagation: true,
    open: false,

    closeTip() {
      if (!(this.isDestroyed || this.isDestroying)) {
        (0, _jquery.default)('body').not('tool-tip .tip').off('click.close-tooltip');
        return Ember.trySet(this, 'open', false);
      }

      return true;
    },

    click(event) {
      if (this.get('stopPropagation')) {
        event.stopPropagation();
      }

      if (this.get('open')) {
        return this.closeTip();
      }

      if (!(this.isDestroyed || this.isDestroying)) {
        Ember.trySet(this, 'open', true);
        (0, _jquery.default)('body').not('tool-tip .tip').on('click.close-tooltip', () => this.closeTip());
      }

      return true;
    },

    init() {
      this._super(...arguments);

      this.icon = "<img aria-label=\"Information icon tool tip\" src=\"".concat(this.get('rootURL'), "images/v2/icons/i-information.png\" />");
    }

  });

  _exports.default = _default;
});