define("adt-wss/pods/components/stupid/input-counter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "76UQePkC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",\"unexpand-icon\"],[3,\"action\",[[23,0,[]],\"clickHandler\",[24,[\"DECREMENT_TYPE\"]]]],[8],[9],[0,\"\\n\"],[1,[28,\"input\",null,[[\"value\",\"step\",\"min\",\"max\",\"readonly\",\"type\"],[[24,[\"value\"]],[24,[\"step\"]],[24,[\"min\"]],[24,[\"max\"]],true,\"number\"]]],false],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"expand-icon\"],[3,\"action\",[[23,0,[]],\"clickHandler\",[24,[\"INCREMENT_TYPE\"]]]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/stupid/input-counter/template.hbs"
    }
  });

  _exports.default = _default;
});