define("adt-wss/services/auth-info", ["exports", "adt-wss-common/utils/http-resources"], function (_exports, _httpResources) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const _gaq = window._gaq;
  /**
   * Populates authentication info
   * @module Services/AuthInfo
   * @prop {Object} authInfo
   * @prop {String} gaAcct
   */

  var _default = Ember.Service.extend(Ember.Evented, {
    session: Ember.inject.service(),
    apiService: Ember.inject.service(),
    csrInfo: Ember.inject.service(),
    authInfo: null,
    newRegistration: false,
    gaAcct: '',
    psAuthToken: null,

    /**
     * Observes changes to `authInfo.csrUser`, `authInfo.businessReportingUser`,
     * and `authInfo.adtUser` to set GA account
     * @observes `authInfo`
     */
    setGAAcct: Ember.observer('authInfo', function () {
      const authInfo = this.get('authInfo');
      let account = null;

      if (authInfo.csrUser) {
        this.set('gaAcct', 'GA: CSR User');
        account = 'UA-35901340-1';
      } else if (authInfo.businessReportingUser) {
        // Detect Business User
        this.set('gaAcct', 'GA: Business Reporting');
        account = 'UA-42411715-1';
      } else if (authInfo.adtUser) {
        // Detect Normal User
        this.set('gaAcct', 'GA: Normal User');
        account = 'UA-27133841-1';
      }

      if (_gaq && Array.isArray(_gaq)) {
        _gaq.push(['_setAccount', account]);
      }
    }),

    /**
     * Retreives user auth info.
     * Also acts as auth check on reload
     * @instance
     *
     * @type {ServiceCall}
     * @emits isAuthenticated
     * @return {ApiResponse}
     */
    _getAuthInfo() {
      const authInfo = this.get('authInfo');

      if (this.get('session.isAuthenticated') && authInfo) {
        return Ember.RSVP.resolve({
          data: authInfo
        });
      }

      return this.get('apiService').request({
        resource: _httpResources.myADT.getAuthInfo
      });
    },

    _getPsToken() {
      return this.get('apiService').request({
        resource: _httpResources.myADT.getPSAuthToken
      });
    },

    getPsToken() {
      return this._getPsToken().then(({
        data
      }) => {
        const psToken = this.get('apiService.authorizationToken').substr(7);
        const ps_token_evt = new CustomEvent('PS_AUTH_LOGIN_SUCESS');
        const ps_token_detail = {
          myPsToken: psToken
        };
        ps_token_evt.initCustomEvent('PS_AUTH_LOGIN_SUCESS', true, true, ps_token_detail);
        window.postMessage({
          psToken: psToken
        }, '*');
        return window.dispatchEvent(ps_token_evt);
      });
    },

    getAuthInfo() {
      return this._getAuthInfo().then(({
        data
      }) => {
        const token = this.get('apiService.authorizationToken');
        this.set('psAuthToken', token);

        if (window.Pypestream) {
          window.Pypestream('config', {
            passthrough: {
              attribute: 'psAuthToken',
              label: 'psAuthToken',
              value: token,
              visible: 'none'
            }
          });
        }

        this.set('authInfo', data);
        this.setGAAcct();
        this.trigger('isAuthenticated');

        if (data.csrUser) {
          return this.get('csrInfo').getCSRInformation().then(() => data);
        }

        return data;
      });
    },

    /**
     * Sets user auth info.
     * Called from authenticator after login
     */
    setAuthInfo(data) {
      this.set('authInfo', data);
      this.setGAAcct();

      if (data.csrUser) {
        return this.get('csrInfo').getCSRInformation().then(() => data);
      }

      return data;
    }

  });

  _exports.default = _default;
});