define("adt-wss/services/sso-info", ["exports", "adt-wss-common/utils/http-resources"], function (_exports, _httpResources) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Populates SSO info
   * @module Services/SsoInfo
   * @prop {Boolean} isSsoEnabled
   */
  var _default = Ember.Service.extend(Ember.Evented, {
    apiService: Ember.inject.service(),
    isSsoEnabled: false,
    questionsList: null,

    /**
     * Retrieves isSsoEnabled info.
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getIsSsoEnabled() {
      return this.get('apiService').request({
        resource: _httpResources.myADT.getIsSsoEnabled
      }).then(({
        data
      }) => {
        this.set('isSsoEnabled', data);
        return data;
      });
    },

    /**
     * Retrieves security questions for SSO users.
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getSsoSecurityQuestions() {
      return this.get('apiService').request({
        resource: _httpResources.myADT.getSecurityQuestions
      }).then(({
        data
      }) => {
        const questions = [{
          value: null,
          text: 'Choose a Security Question'
        }];
        data.forEach((question, index) => {
          questions.push({
            value: index + 1,
            text: question
          });
        });
        this.set('questionsList', questions);
        return questions;
      });
    }

  });

  _exports.default = _default;
});