define("adt-wss/pods/components/dashboard/alarm/appointments/change-appointment/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FAILURE_ACTION = _exports.SUCCESS_FETCH_ACTION = _exports.SUCCESS_ACTION = _exports.CLOSE_MODAL_ACTION = _exports.FETCH_AVAILABLE_DATES_ACTION = _exports.CONFIRM_RESCHEDULE_APPOINTMENT_ACTION = _exports.RESCHEDULE_APPOINTMENT_ACTION = _exports.CANCEL_APPOINTMENT_ACTION = _exports.START_CANCEL_PROCEDURE_ACTION = _exports.START_RESCHEDULE_PROCEDURE_ACTION = _exports.FAILURE_STATE = _exports.LOADING_STATE = _exports.CANCEL_APPOINTMENT_STATE = _exports.RESCHEDULE_APPOINTMENT_CONFIRM_STATE = _exports.RESCHEDULE_APPOINTMENT_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const RESCHEDULE_APPOINTMENT_STATE = 'RESCHEDULE_APPOINTMENT_STATE';
  _exports.RESCHEDULE_APPOINTMENT_STATE = RESCHEDULE_APPOINTMENT_STATE;
  const RESCHEDULE_APPOINTMENT_CONFIRM_STATE = 'RESCHEDULE_APPOINTMENT_CONFIRM_STATE';
  _exports.RESCHEDULE_APPOINTMENT_CONFIRM_STATE = RESCHEDULE_APPOINTMENT_CONFIRM_STATE;
  const CANCEL_APPOINTMENT_STATE = 'CANCEL_APPOINTMENT_STATE';
  _exports.CANCEL_APPOINTMENT_STATE = CANCEL_APPOINTMENT_STATE;
  const LOADING_STATE = 'LOADING_STATE';
  _exports.LOADING_STATE = LOADING_STATE;
  const FAILURE_STATE = 'FAILURE_STATE';
  _exports.FAILURE_STATE = FAILURE_STATE;
  const START_RESCHEDULE_PROCEDURE_ACTION = 'START_RESCHEDULE_PROCEDURE_ACTION';
  _exports.START_RESCHEDULE_PROCEDURE_ACTION = START_RESCHEDULE_PROCEDURE_ACTION;
  const START_CANCEL_PROCEDURE_ACTION = 'START_CANCEL_PROCEDURE_ACTION';
  _exports.START_CANCEL_PROCEDURE_ACTION = START_CANCEL_PROCEDURE_ACTION;
  const CANCEL_APPOINTMENT_ACTION = 'CANCEL_APPOINTMENT_ACTION';
  _exports.CANCEL_APPOINTMENT_ACTION = CANCEL_APPOINTMENT_ACTION;
  const RESCHEDULE_APPOINTMENT_ACTION = 'RESCHEDULE_APPOINTMENT_ACTION';
  _exports.RESCHEDULE_APPOINTMENT_ACTION = RESCHEDULE_APPOINTMENT_ACTION;
  const CONFIRM_RESCHEDULE_APPOINTMENT_ACTION = 'CONFIRM_RESCHEDULE_APPOINTMENT_ACTION';
  _exports.CONFIRM_RESCHEDULE_APPOINTMENT_ACTION = CONFIRM_RESCHEDULE_APPOINTMENT_ACTION;
  const FETCH_AVAILABLE_DATES_ACTION = 'FETCH_AVAILABLE_DATES_ACTION';
  _exports.FETCH_AVAILABLE_DATES_ACTION = FETCH_AVAILABLE_DATES_ACTION;
  const CLOSE_MODAL_ACTION = 'CLOSE_MODAL_ACTION';
  _exports.CLOSE_MODAL_ACTION = CLOSE_MODAL_ACTION;
  const SUCCESS_ACTION = 'SUCCESS_ACTION';
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
  const SUCCESS_FETCH_ACTION = 'SUCCESS_FETCH_ACTION';
  _exports.SUCCESS_FETCH_ACTION = SUCCESS_FETCH_ACTION;
  const FAILURE_ACTION = 'FAILURE_ACTION';
  _exports.FAILURE_ACTION = FAILURE_ACTION;
});