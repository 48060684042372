define("adt-wss/pods/components/dashboard/account/on-site-assessment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bZYcWHRG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showOnSiteAssessmentCharge\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"hasQSP\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"h3\",true],[10,\"class\",\"widget-title\"],[8],[0,\"On-site Assessment Charges\"],[9],[0,\"\\n\\t\"],[7,\"p\",true],[10,\"class\",\"charge-title\"],[8],[0,\"$\"],[1,[22,\"onSiteCharge\"],false],[0,\" on-site assessment charge.\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/account/on-site-assessment/template.hbs"
    }
  });

  _exports.default = _default;
});