define("adt-wss/pods/components/index/primary-marquee/component", ["exports", "adt-wss-common/utils/ember-helpers"], function (_exports, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Components/Index/PrimaryMarquee
   * @prop {String} premiumRegUuid
   * @prop {String} premiumRegEmail
   * @example
   * {@lang xml} {{ index/primary-marquee
  		premiumRegUuid='uuid'
  		premiumRegEmail='email'
  }}
   */
  var _default = Ember.Component.extend({
    currentLocation: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ['marquee'],
    firstName: (0, _emberHelpers.computedApplyFunction)((isAuthenticated, fName) => {
      const storedName = sessionStorage.getItem('myADT_storedFirstName');
      return fName || storedName;
    }, 'session.isAuthenticated', 'currentLocation.firstName')
  });

  _exports.default = _default;
});