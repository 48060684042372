define("adt-wss/pods/components/index/home-footer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['footer', 'hide-mobile'],
    session: Ember.inject.service(),
    classNameBindings: ['session.isAuthenticated:authenticated-state:unauthenticated-state']
  });

  _exports.default = _default;
});