define("adt-wss/pods/components/widget/tool-tip/icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['icon'],
    classNameBindings: ['iconType'],
    icon: '<img aria-label="Information icon" src="{{rootURL}}images/v2/icons/i-information.png" />'
  });

  _exports.default = _default;
});