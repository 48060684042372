define("adt-wss/templates/modal-windows/system-test-wizard/pre-start", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eWRgWNlx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"test-system-wizard-info-modal\"],[10,\"role\",\"region\"],[11,\"aria-label\",[29,[\"Your system will now be put into Test Mode for \",[24,[\"body\",\"duration\",\"text\"]],\".\\n\\tPlacing system on test mode does not prevent the siren from sounding.\"]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"body\",\"IS_START_PENDING_STATE\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[15,\"loading-spinner\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"test-wizard-info-text system-test-pre-start-info\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"p\",true],[8],[0,\"Your system will now be put into\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"body\",\"duration\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"p\",true],[8],[7,\"strong\",true],[8],[0,\"Test Mode for \"],[1,[24,[\"body\",\"duration\",\"text\"]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"widget/system-test-wizard-note\",null,[[\"text\"],[\"Placing system on test mode does not prevent the siren from sounding\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":true}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/system-test-wizard/pre-start.hbs"
    }
  });

  _exports.default = _default;
});