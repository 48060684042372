define("adt-wss/services/dashboard-status-messages", ["exports", "adt-wss-common/utils/myadt-helpers"], function (_exports, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)({
    statusMessages: null
  }), {
    statusMessages: null,
    router: Ember.inject.service(),
    currentRouteName: Ember.computed.alias('router.currentRouteName'),

    clear() {
      this.set('statusMessages', null);
    }

  });

  _exports.default = _default;
});