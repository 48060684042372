define("adt-wss/pods/components/dashboard/account/manage-easypay/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CANCEL_FIRST_STEP_ACTION = _exports.CHOOSE_PAYMENT_STATE = _exports.CHOOSE_PAYMENT_ACTION = _exports.VIEW_FIRST_STEP_STATE = _exports.VIEW_FIRST_STEP_ACTION = _exports.FAILURE_FETCH_ACTION = _exports.SUCCESS_FETCH_ACTION = void 0;
  const SUCCESS_FETCH_ACTION = 'SUCCESS_FETCH_ACTION';
  _exports.SUCCESS_FETCH_ACTION = SUCCESS_FETCH_ACTION;
  const FAILURE_FETCH_ACTION = 'FAILURE_FETCH_ACTION';
  _exports.FAILURE_FETCH_ACTION = FAILURE_FETCH_ACTION;
  const VIEW_FIRST_STEP_ACTION = 'VIEW_FIRST_STEP_ACTION';
  _exports.VIEW_FIRST_STEP_ACTION = VIEW_FIRST_STEP_ACTION;
  const VIEW_FIRST_STEP_STATE = 'VIEW_FIRST_STEP_STATE';
  _exports.VIEW_FIRST_STEP_STATE = VIEW_FIRST_STEP_STATE;
  const CHOOSE_PAYMENT_ACTION = 'CHOOSE_PAYMENT_ACTION';
  _exports.CHOOSE_PAYMENT_ACTION = CHOOSE_PAYMENT_ACTION;
  const CHOOSE_PAYMENT_STATE = 'CHOOSE_PAYMENT_STATE';
  _exports.CHOOSE_PAYMENT_STATE = CHOOSE_PAYMENT_STATE;
  const CANCEL_FIRST_STEP_ACTION = 'CANCEL_FIRST_STEP_ACTION';
  _exports.CANCEL_FIRST_STEP_ACTION = CANCEL_FIRST_STEP_ACTION;
});