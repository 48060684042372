define("adt-wss/pods/components/dashboard/manage/select-locations/component", ["exports", "ember-copy"], function (_exports, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    /**
     * Select handler
     * @prop {Object[]} selectedLocations - list of selected locations
     * @return {void}
     */

    /* eslint-disable no-unused-vars */
    onSelect: selectedLocations => console.error('Please pass the "onSelect" param to "dashboard/manage/select-locations" component'),

    /* eslint-enable no-unused-vars */
    locations: [],
    selectedLocations: []
  };
  /**
   * @module Components/Dashboard/Manage/SelectLocations
   * @prop {Object[]} locations
   * @prop {Function} onSelect
   * @example
   * {@lang xml} {{dashboard/manage/select-locations
  		locations=locations
  		onSelect=(action "select")
  }}
   */

  var _default = Ember.Component.extend(defaultProps, {
    tagName: 'section',
    classNames: ['select-locations-widget'],

    init(...args) {
      this._super(...args);

      this.set('editableSelectedLocations', (0, _emberCopy.copy)(this.get('selectedLocations'), true) || []);
    },

    actions: {
      /**
       * Handler for selecting all locations
       * Updates `editableSelectedLocations` prop
       * Calls `onSelect` function which is passed via props
       *
       * @function
       */
      selectAllLocations() {
        const editableListKey = 'editableSelectedLocations';
        const {
          editableSelectedLocations,
          locations
        } = this.getProperties(editableListKey, 'locations');
        let list = [];

        if (editableSelectedLocations.length !== locations.length) {
          list = locations.map(l => l.webAddressId);
        }

        this.set(editableListKey, list);
        this.onSelect(list);
      },

      /**
       * Handler for selecting location
       * Updates `editableSelectedLocations` prop
       * Calls `onSelect` function which is passed via props
       *
       * @function
       * @param {Number} id - location id;
       * @param {Boolean} selected - indicates if location is already selected
       */
      selectLocation(id, selected) {
        const key = 'editableSelectedLocations';
        const list = this.get(key);
        const newList = selected ? list.filter(i => i !== id) : list.concat(id);
        this.set(key, newList);
        this.onSelect(newList);
      }

    }
  });

  _exports.default = _default;
});