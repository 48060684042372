define("adt-wss/pods/dashboard/alarm/history/route", ["exports", "adt-wss/services/app-dispatcher"], function (_exports, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    myadtSecurity: Ember.inject.service(),

    afterModel() {
      this.get('appDispatcher').subscribe(_appDispatcher.PIC_VALIDATED_EVENT, this, 'picSubmitted');
      this.get('appDispatcher').subscribe(_appDispatcher.PIC_INVALIDATED_EVENT, this, 'picSubmitted');
    },

    deactivate() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.PIC_VALIDATED_EVENT, this, 'picSubmitted');
      this.get('appDispatcher').unsubscribe(_appDispatcher.PIC_INVALIDATED_EVENT, this, 'picSubmitted');
    },

    picSubmitted() {
      if (!this.get('myadtSecurity.picProvided')) {
        this.transitionTo('dashboard.alarm.appointments');
      }
    }

  });

  _exports.default = _default;
});