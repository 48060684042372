define("adt-wss/pods/dashboard/account/payments/manageeasypay/controller", ["exports", "adt-wss/mixins/dashboard-status-messages", "adt-wss-common/utils/ember-helpers"], function (_exports, _dashboardStatusMessages, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_dashboardStatusMessages.default, {
    showPendingMessage: true,

    setPendingMessageVisibility(visible) {
      this.set('showPendingMessage', visible);
    }

  });

  _exports.default = _default;
});