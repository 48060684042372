define("adt-wss/pods/components/dashboard/sweepstake-form/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/route-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/localization", "adt-wss-common/utils/fp", "adt-wss/services/app-dispatcher", "adt-wss/pods/components/dashboard/sweepstake-form/constants"], function (_exports, _withFsm, _routeHelpers, _emberHelpers, _myadtHelpers, _localization, _fp, _appDispatcher, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    formInfo: null,
    contentData: null,

    /**
     * Notify user if form was submitted
     * @param {Boolean} isSubmitted - if user submit sweepstakes form
     * @return {void}
     */
    onClose: isSubmitted => {
      console.error('Pass the "onClose" param to "dashboard/sweepstake-form" component to close the modal', isSubmitted);
    }
  };
  const fsmModel = {
    transitions: {
      [_constants.IDLE_STATE]: {
        [_constants.FETCH_DATA_ACTION]: _constants.LOADING_STATE
      },
      [_constants.LOADING_STATE]: {
        [_constants.SUCCESS_ACTION]: _constants.ENTRY_FORM_STATE,
        [_constants.FAILURE_ACTION]: _constants.IDLE_STATE
      },
      [_constants.ENTRY_FORM_STATE]: {
        [_constants.SUBMIT_ACTION]: _constants.LOADING_SUBMITTING_STATE
      },
      [_constants.LOADING_SUBMITTING_STATE]: {
        [_constants.SUCCESS_SUBMIT_ACTION]: _constants.IDLE_STATE,
        [_constants.FAILURE_SUBMIT_ACTION]: _constants.ENTRY_FORM_STATE
      }
    }
  };
  /**
   * @module Components/Dashboard/SweepstakeForm
   * @prop {Object} formInfo - data from API for form
   * @prop {?Boolean} formState - implies `ENTRY_FORM_STATE` as initial state
   * @prop {Array} contentData - data from sweepstake-promos component
   * @prop {Function} onClose - function to notify component that submission is completed
   * @example
   * {@lang xml} {{ dashboard/sweepstake-form
  		formInfo=null
  		contentData=null
  		formState=false
  		onClose=onClose
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    fsmModel,
    mwd: Ember.inject.service('modal-window-dispatcher'),
    classNames: ['sweepstake-form', 'sweepstakes-content-component'],
    promotionData: Ember.inject.service(),
    appDispatcher: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    currentState: _constants.IDLE_STATE,
    opened: false,
    acceptedTermsConditions: false,
    acceptedMarketingTexts: false,
    showErrorMsg: false,
    errorMsg: '',
    footerId: 'sweepstakeFormFooter',
    phoneNumber: Ember.computed.oneWay('promotionData.formInfo.phoneNumber'),
    sweepstakeId: (0, _emberHelpers.computedApplyFunction)(content => content.id, 'promotionData.content'),

    [_constants.SUCCESS_ACTION](formInfo) {
      this.set('formInfo', formInfo);
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Sweepstakes', 'Enter Sweepstakes link', 'Sweepstakes 3']);
    },

    [_constants.FAILURE_ACTION](error) {
      console.error(error);
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Sweepstakes', 'Load Failed', 'Sweepstakes 3']);
    },

    [_constants.FETCH_DATA_ACTION]() {
      return this.deliverData('promotionData.formInfo', formInfo => formInfo.getSweepstakeFormInfo()).then((0, _routeHelpers.fsmDispatch)(_constants.SUCCESS_ACTION)(this)).catch((0, _routeHelpers.fsmDispatch)(_constants.FAILURE_ACTION)(this));
    },

    [_constants.SUBMIT_ACTION](data) {
      return this.get('promotionData').submitSweepstakeForm(data);
    },

    [_constants.SUCCESS_SUBMIT_ACTION]() {
      const mwd = this.get('mwd');
      mwd.hide();
      this.onClose(true);
      this.appDispatcher.sendEvent(_appDispatcher.SHOW_POST_SUBMISSION_NOTIFICATION_MASSAGE, {
        drawDate: false
      });
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Sweepstakes', 'Submission Success', 'Sweepstakes 3']);
    },

    [_constants.FAILURE_SUBMIT_ACTION](data) {
      const errors = data.errors;
      const error = errors ? (0, _localization.toExpandedString)(errors[0]) : (0, _localization.toExpandedString)('Sweepstake.Generic.error');
      this.showError(error);
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Sweepstakes', 'Submission Failed', 'Sweepstakes 3']);
    },

    init(...args) {
      this._super(...args);

      this.get('ET').sendEventTag({
        eventTypeId: 'SWEEPSTK',
        subEventTypeId: 'BNRDISPL'
      }, false, '3');
      this.termsAndConditionsModal = this.termsAndConditionsModal.bind(this);

      if (this.formState) {
        this.fsm.dispatch(_constants.FETCH_DATA_ACTION);
      }
    },

    footerLinkClickHandler(e) {
      e.preventDefault();
      this.termsAndConditionsModal(true);
    },

    termsAndConditionsModal(open) {
      this.set('opened', open);

      if (open) {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Sweepstakes', 'Official Rules viewed', 'Sweepstakes 3']);
      }
    },

    showError(msg) {
      this.set('showErrorMsg', true);
      this.set('errorMsg', msg);
    },

    clearMessages() {
      this.set('showErrorMsg', false);
    },

    resetModalWindowState() {
      this.clearMessages();
      this.set('phoneNumber', this.get('promotionData.formInfo.phoneNumber'));
      this.set('acceptedTermsConditions', false);
      this.set('acceptedMarketingTexts', false);
    },

    didInsertElement() {
      const linkElement = this.element.querySelector("#".concat(this.footerId, " a"));

      if (!linkElement) {
        return false;
      }

      return linkElement.addEventListener('click', this.footerLinkClickHandler.bind(this));
    },

    actions: {
      onClose() {
        this.onClose(false);
      },

      generateMobileBannerImage(path) {
        return path.replace(/(\.(jpg|png|gif))(\?.+)?$/, '-mobile$1');
      },

      submitEntry() {
        const {
          fsm,
          formInfo,
          sweepstakeId,
          acceptedTermsConditions,
          acceptedMarketingTexts,
          phoneNumber
        } = this.getProperties('fsm', 'formInfo', 'sweepstakeId', 'acceptedTermsConditions', 'acceptedMarketingTexts', 'phoneNumber');
        const data = (0, _fp.omix)(formInfo, {
          sweepstakeId,
          acceptedTermsConditions,
          acceptedMarketingTexts,
          phoneNumber
        });
        this.clearMessages();

        if (!phoneNumber) {
          return this.showError((0, _localization.toExpandedString)('Sweepstake.incorrectPhoneNumber'));
        }

        if (!acceptedTermsConditions) {
          return this.showError((0, _localization.toExpandedString)('Sweepstake.notAgreeTermsAndConditions'));
        }

        if (formInfo.phoneNumber !== phoneNumber) {
          (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Sweepstakes', 'Phone Number Update', 'Sweepstakes 3']);
        }

        if (acceptedMarketingTexts) {
          (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Sweepstakes', 'EW Consent', 'Sweepstakes 3']);
        }

        return fsm.dispatch(_constants.SUBMIT_ACTION, data).then(response => fsm.dispatch(_constants.SUCCESS_SUBMIT_ACTION, response)).catch(results => fsm.dispatch(_constants.FAILURE_SUBMIT_ACTION, results));
      }

    }
  });

  _exports.default = _default;
});