define("adt-wss/pods/dashboard/alarm/appointments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XSC8DH59",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"dashboard-alarm-appointments\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"widgets-row\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"appointments\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"dashboard/appointments-list\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"troubleshooting-links\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"dashboard/alarm/troubleshooting-links\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/alarm/appointments/template.hbs"
    }
  });

  _exports.default = _default;
});