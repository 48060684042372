define("adt-wss/pods/components/dashboard/manage/enroll-business-reporting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U2QA0/1v",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",\"button button-blue\"],[3,\"action\",[[23,0,[]],\"dispatch\",\"OPEN_MODAL_WINDOW_ACTION\"]],[8],[0,\"Enroll in Business Reporting\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/manage/enroll-business-reporting/template.hbs"
    }
  });

  _exports.default = _default;
});