define("adt-wss/pods/components/dashboard/account/payment-options/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AMOUNT_GREATER_THAN_DUE_MSG = _exports.RE_ENTER_PAYMENT_REQUEST_ERR = _exports.ENCRYPTED_INFORMATION_MSG = _exports.FLEXFI_PAYMENT_ID = _exports.OTHER_AMOUNT_PAYMENT_ID = _exports.BALANCE_DUE_PAYMENT_ID = _exports.PAYMENT_DETAILS_CONFIRMATION_STATE = _exports.FLEXFI_PAYMENT_PAYMENT_STATE = _exports.FLEXFI_PAYMENT_SELECTION_STATE = _exports.PAYMENT_AMOUNT_ENTRY_STATE = _exports.PAYMENT_METHOD_SELECTION_STATE = _exports.TRANSITION_TO_PAYMENT_METHOD_SELECTION_STATE = _exports.TRANSITION_TO_FLEXFI_PAYMENT_SELECTION_STATE = _exports.TRANSITION_TO_PAYMENT_AMOUNT_ENTRY_STATE = _exports.RESET_STATE_ACTION = _exports.CONFIRM_PAYMENT_DETAILS_ACTION = _exports.SELECT_FLEXFI_PAYMENT_ACTION = _exports.ENTER_PAYMENT_AMOUNT_ACTION = _exports.SELECT_PAYMENT_METHOD_ACTION = void 0;
  const SELECT_PAYMENT_METHOD_ACTION = 'SELECT_PAYMENT_METHOD_ACTION';
  _exports.SELECT_PAYMENT_METHOD_ACTION = SELECT_PAYMENT_METHOD_ACTION;
  const ENTER_PAYMENT_AMOUNT_ACTION = 'ENTER_PAYMENT_AMOUNT_ACTION';
  _exports.ENTER_PAYMENT_AMOUNT_ACTION = ENTER_PAYMENT_AMOUNT_ACTION;
  const SELECT_FLEXFI_PAYMENT_ACTION = 'SELECT_FLEXFI_PAYMENT_ACTION';
  _exports.SELECT_FLEXFI_PAYMENT_ACTION = SELECT_FLEXFI_PAYMENT_ACTION;
  const CONFIRM_PAYMENT_DETAILS_ACTION = 'CONFIRM_PAYMENT_DETAILS_ACTION';
  _exports.CONFIRM_PAYMENT_DETAILS_ACTION = CONFIRM_PAYMENT_DETAILS_ACTION;
  const RESET_STATE_ACTION = 'RESET_STATE_ACTION';
  _exports.RESET_STATE_ACTION = RESET_STATE_ACTION;
  const TRANSITION_TO_PAYMENT_AMOUNT_ENTRY_STATE = 'TRANSITION_TO_PAYMENT_AMOUNT_ENTRY_STATE';
  _exports.TRANSITION_TO_PAYMENT_AMOUNT_ENTRY_STATE = TRANSITION_TO_PAYMENT_AMOUNT_ENTRY_STATE;
  const TRANSITION_TO_FLEXFI_PAYMENT_SELECTION_STATE = 'TRANSITION_TO_FLEXFI_PAYMENT_SELECTION_STATE';
  _exports.TRANSITION_TO_FLEXFI_PAYMENT_SELECTION_STATE = TRANSITION_TO_FLEXFI_PAYMENT_SELECTION_STATE;
  const TRANSITION_TO_PAYMENT_METHOD_SELECTION_STATE = 'TRANSITION_TO_PAYMENT_METHOD_SELECTION_STATE';
  _exports.TRANSITION_TO_PAYMENT_METHOD_SELECTION_STATE = TRANSITION_TO_PAYMENT_METHOD_SELECTION_STATE;
  const PAYMENT_METHOD_SELECTION_STATE = 'PAYMENT_METHOD_SELECTION_STATE';
  _exports.PAYMENT_METHOD_SELECTION_STATE = PAYMENT_METHOD_SELECTION_STATE;
  const PAYMENT_AMOUNT_ENTRY_STATE = 'PAYMENT_AMOUNT_ENTRY_STATE';
  _exports.PAYMENT_AMOUNT_ENTRY_STATE = PAYMENT_AMOUNT_ENTRY_STATE;
  const FLEXFI_PAYMENT_SELECTION_STATE = 'FLEXFI_PAYMENT_SELECTION_STATE';
  _exports.FLEXFI_PAYMENT_SELECTION_STATE = FLEXFI_PAYMENT_SELECTION_STATE;
  const FLEXFI_PAYMENT_PAYMENT_STATE = 'FLEXFI_PAYMENT_PAYMENT_STATE';
  _exports.FLEXFI_PAYMENT_PAYMENT_STATE = FLEXFI_PAYMENT_PAYMENT_STATE;
  const PAYMENT_DETAILS_CONFIRMATION_STATE = 'PAYMENT_DETAILS_CONFIRMATION_STATE';
  _exports.PAYMENT_DETAILS_CONFIRMATION_STATE = PAYMENT_DETAILS_CONFIRMATION_STATE;
  const BALANCE_DUE_PAYMENT_ID = 'balanceDuePayment';
  _exports.BALANCE_DUE_PAYMENT_ID = BALANCE_DUE_PAYMENT_ID;
  const OTHER_AMOUNT_PAYMENT_ID = 'otherAmoumnt';
  _exports.OTHER_AMOUNT_PAYMENT_ID = OTHER_AMOUNT_PAYMENT_ID;
  const FLEXFI_PAYMENT_ID = 'flexfi';
  _exports.FLEXFI_PAYMENT_ID = FLEXFI_PAYMENT_ID;
  const ENCRYPTED_INFORMATION_MSG = 'Your payment information is encrypted';
  _exports.ENCRYPTED_INFORMATION_MSG = ENCRYPTED_INFORMATION_MSG;
  const RE_ENTER_PAYMENT_REQUEST_ERR = 'Payment.reEnterPaymentRequest';
  _exports.RE_ENTER_PAYMENT_REQUEST_ERR = RE_ENTER_PAYMENT_REQUEST_ERR;
  const AMOUNT_GREATER_THAN_DUE_MSG = 'This payment will bring the amount due below zero, creating a credit balance.';
  _exports.AMOUNT_GREATER_THAN_DUE_MSG = AMOUNT_GREATER_THAN_DUE_MSG;
});