define("adt-wss/pods/components/dashboard/alarm/system-test-wizard-stopwatch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gyj3sxzB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"countdown\"],[8],[0,\"\\n \"],[7,\"div\",true],[10,\"class\",\"countdown-number\"],[8],[1,[22,\"countdown\"],false],[0,\"s\"],[9],[0,\"\\n \"],[7,\"svg\",true],[11,\"class\",[29,[\"countdown-wrapper \",[28,\"if\",[[24,[\"IS_RUNNING_STATE\"]],\"running\"],null]]]],[8],[0,\"\\n  \"],[7,\"circle\",true],[10,\"r\",\"110\"],[10,\"cx\",\"122\"],[10,\"cy\",\"122\"],[8],[9],[0,\"\\n \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"reset-btn\"],[3,\"action\",[[23,0,[]],\"resetCouter\"]],[8],[0,\"Reset Stopwatch\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/alarm/system-test-wizard-stopwatch/template.hbs"
    }
  });

  _exports.default = _default;
});