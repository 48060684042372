define("adt-wss/pods/components/navbar-header/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SIGN_OUT_ACTION = _exports.CHANGE_LOCATION_ACTION = _exports.FAILURE_CHANGE_LOCATION_ACTION = _exports.SUCCESS_CHANGE_LOCATION_ACTION = _exports.FAILURE_FETCH_DATA_ACTION = _exports.SUCCESS_FETCH_DATA_ACTION = _exports.FETCH_DATA_ACTION = _exports.ERROR_STATE = _exports.IDLE_STATE = _exports.INIT_STATE = _exports.LOADING_STATE = void 0;
  const LOADING_STATE = 'LOADING_STATE';
  _exports.LOADING_STATE = LOADING_STATE;
  const INIT_STATE = 'INIT_STATE';
  _exports.INIT_STATE = INIT_STATE;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const ERROR_STATE = 'ERROR_STATE';
  _exports.ERROR_STATE = ERROR_STATE;
  const FETCH_DATA_ACTION = 'FETCH_DATA_ACTION';
  _exports.FETCH_DATA_ACTION = FETCH_DATA_ACTION;
  const SUCCESS_FETCH_DATA_ACTION = 'SUCCESS_FETCH_DATA_ACTION';
  _exports.SUCCESS_FETCH_DATA_ACTION = SUCCESS_FETCH_DATA_ACTION;
  const FAILURE_FETCH_DATA_ACTION = 'FAILURE_FETCH_DATA_ACTION';
  _exports.FAILURE_FETCH_DATA_ACTION = FAILURE_FETCH_DATA_ACTION;
  const SUCCESS_CHANGE_LOCATION_ACTION = 'SUCCESS_CHANGE_LOCATION_ACTION';
  _exports.SUCCESS_CHANGE_LOCATION_ACTION = SUCCESS_CHANGE_LOCATION_ACTION;
  const FAILURE_CHANGE_LOCATION_ACTION = 'FAILURE_CHANGE_LOCATION_ACTION';
  _exports.FAILURE_CHANGE_LOCATION_ACTION = FAILURE_CHANGE_LOCATION_ACTION;
  const CHANGE_LOCATION_ACTION = 'CHANGE_LOCATION_ACTION';
  _exports.CHANGE_LOCATION_ACTION = CHANGE_LOCATION_ACTION;
  const SIGN_OUT_ACTION = 'SIGN_OUT_ACTION';
  _exports.SIGN_OUT_ACTION = SIGN_OUT_ACTION;
});