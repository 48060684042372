define("adt-wss/pods/dashboard/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SWEEPSTAKES_MODAL_CANCEL_EDIT_ACTION = _exports.SWEEPSTAKES_MODAL_STATE = _exports.SWEEPSTAKES_MODAL_ACTION = _exports.PRIMARY_EMAIL_FAILURE_ACTION = _exports.PRIMARY_EMAIL_SUCCESS_ACTION = _exports.WELCOME_EXPERIENCE_EXIT_ACTION = _exports.WELCOME_EXPERIENCE_SKIP_STEP_ACTION = _exports.WELCOME_EXPERIENCE_NEXT_STEP_ACTION = _exports.START_WELCOME_EXPERIENCE_ACTION = _exports.SIGN_OUT_ACTION = _exports.CANCEL_PIC_ACTION = _exports.REJECT_PIC_ACTION = _exports.RESOLVE_PIC_ACTION = _exports.SUBMIT_PIC_ACTION = _exports.VALIDATE_PIC_ACTION = _exports.CLOSE_SIDEBAR_ACTION = _exports.TOGGLE_SIDEBAR_ACTION = _exports.SUBMITTING_PRIMARY_EMAIL_STATE = _exports.WELCOME_EXPERIENCE_STEP_FIVE = _exports.WELCOME_EXPERIENCE_STEP_FOUR = _exports.WELCOME_EXPERIENCE_STEP_THREE = _exports.WELCOME_EXPERIENCE_STEP_TWO = _exports.WELCOME_EXPERIENCE_STEP_ONE = _exports.SUBMITTING_PIC_STATE = _exports.VALIDATE_PIC_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const VALIDATE_PIC_STATE = 'VALIDATE_PIC_STATE';
  _exports.VALIDATE_PIC_STATE = VALIDATE_PIC_STATE;
  const SUBMITTING_PIC_STATE = 'SUBMITTING_STATE';
  _exports.SUBMITTING_PIC_STATE = SUBMITTING_PIC_STATE;
  const WELCOME_EXPERIENCE_STEP_ONE = 'WELCOME_EXPERIENCE_STEP_ONE';
  _exports.WELCOME_EXPERIENCE_STEP_ONE = WELCOME_EXPERIENCE_STEP_ONE;
  const WELCOME_EXPERIENCE_STEP_TWO = 'WELCOME_EXPERIENCE_STEP_TWO';
  _exports.WELCOME_EXPERIENCE_STEP_TWO = WELCOME_EXPERIENCE_STEP_TWO;
  const WELCOME_EXPERIENCE_STEP_THREE = 'WELCOME_EXPERIENCE_STEP_THREE';
  _exports.WELCOME_EXPERIENCE_STEP_THREE = WELCOME_EXPERIENCE_STEP_THREE;
  const WELCOME_EXPERIENCE_STEP_FOUR = 'WELCOME_EXPERIENCE_STEP_FOUR';
  _exports.WELCOME_EXPERIENCE_STEP_FOUR = WELCOME_EXPERIENCE_STEP_FOUR;
  const WELCOME_EXPERIENCE_STEP_FIVE = 'WELCOME_EXPERIENCE_STEP_FIVE';
  _exports.WELCOME_EXPERIENCE_STEP_FIVE = WELCOME_EXPERIENCE_STEP_FIVE;
  const SUBMITTING_PRIMARY_EMAIL_STATE = 'SUBMITTING_PRIMARY_EMAIL_STATE';
  _exports.SUBMITTING_PRIMARY_EMAIL_STATE = SUBMITTING_PRIMARY_EMAIL_STATE;
  const TOGGLE_SIDEBAR_ACTION = 'TOGGLE_SIDEBAR_ACTION';
  _exports.TOGGLE_SIDEBAR_ACTION = TOGGLE_SIDEBAR_ACTION;
  const CLOSE_SIDEBAR_ACTION = 'CLOSE_SIDEBAR_ACTION';
  _exports.CLOSE_SIDEBAR_ACTION = CLOSE_SIDEBAR_ACTION;
  const VALIDATE_PIC_ACTION = 'VALIDATE_PIC_ACTION';
  _exports.VALIDATE_PIC_ACTION = VALIDATE_PIC_ACTION;
  const SUBMIT_PIC_ACTION = 'SUBMIT_ACTION';
  _exports.SUBMIT_PIC_ACTION = SUBMIT_PIC_ACTION;
  const RESOLVE_PIC_ACTION = 'RESOLVE_ACTION';
  _exports.RESOLVE_PIC_ACTION = RESOLVE_PIC_ACTION;
  const REJECT_PIC_ACTION = 'REJECT_ACTION';
  _exports.REJECT_PIC_ACTION = REJECT_PIC_ACTION;
  const CANCEL_PIC_ACTION = 'CANCEL_ACTION';
  _exports.CANCEL_PIC_ACTION = CANCEL_PIC_ACTION;
  const SIGN_OUT_ACTION = 'SIGN_OUT_ACTION';
  _exports.SIGN_OUT_ACTION = SIGN_OUT_ACTION;
  const START_WELCOME_EXPERIENCE_ACTION = 'START_WELCOME_EXPERIENCE_ACTION';
  _exports.START_WELCOME_EXPERIENCE_ACTION = START_WELCOME_EXPERIENCE_ACTION;
  const WELCOME_EXPERIENCE_NEXT_STEP_ACTION = 'WELCOME_EXPERIENCE_NEXT_STEP_ACTION';
  _exports.WELCOME_EXPERIENCE_NEXT_STEP_ACTION = WELCOME_EXPERIENCE_NEXT_STEP_ACTION;
  const WELCOME_EXPERIENCE_SKIP_STEP_ACTION = 'WELCOME_EXPERIENCE_SKIP_STEP_ACTION';
  _exports.WELCOME_EXPERIENCE_SKIP_STEP_ACTION = WELCOME_EXPERIENCE_SKIP_STEP_ACTION;
  const WELCOME_EXPERIENCE_EXIT_ACTION = 'WELCOME_EXPERIENCE_EXIT_ACTION';
  _exports.WELCOME_EXPERIENCE_EXIT_ACTION = WELCOME_EXPERIENCE_EXIT_ACTION;
  const PRIMARY_EMAIL_SUCCESS_ACTION = 'PRIMARY_EMAIL_SUCCESS_ACTION';
  _exports.PRIMARY_EMAIL_SUCCESS_ACTION = PRIMARY_EMAIL_SUCCESS_ACTION;
  const PRIMARY_EMAIL_FAILURE_ACTION = 'PRIMARY_EMAIL_FAILURE_ACTION';
  _exports.PRIMARY_EMAIL_FAILURE_ACTION = PRIMARY_EMAIL_FAILURE_ACTION;
  const SWEEPSTAKES_MODAL_ACTION = 'SWEEPSTAKES_MODAL_ACTION';
  _exports.SWEEPSTAKES_MODAL_ACTION = SWEEPSTAKES_MODAL_ACTION;
  const SWEEPSTAKES_MODAL_STATE = 'SWEEPSTAKES_MODAL_STATE';
  _exports.SWEEPSTAKES_MODAL_STATE = SWEEPSTAKES_MODAL_STATE;
  const SWEEPSTAKES_MODAL_CANCEL_EDIT_ACTION = 'SWEEPSTAKES_MODAL_CANCEL_EDIT_ACTION';
  _exports.SWEEPSTAKES_MODAL_CANCEL_EDIT_ACTION = SWEEPSTAKES_MODAL_CANCEL_EDIT_ACTION;
});