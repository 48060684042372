define("adt-wss/pods/help/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),

    init() {
      this._super(...arguments);

      const uint = localStorage.myADT_system_names || '';
      return uint ? "index.uint=".concat(uint) : 'index';
    }

  });

  _exports.default = _default;
});