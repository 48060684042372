define("adt-wss/utils/localization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.toExpandedString = _exports.keys = void 0;

  /* eslint no-template-curly-in-string: 0 */

  /**
   * @deprecated
   * @namespace Localization
   * @prop {Object} keys key/value pairs for message expansion.
   * DO NOT UPDATE - NOT IN USE
   * this file is only present because QA got mad when we tried to delete it
   * because PMO didn't authorize any improvements
   * Use the one in `adt-wss-common`
   */
  const keys = {
    'l10.language': 'Language',
    'Generic.error': 'An unexpected error has occurred and we are unable to complete your request at this time.',
    'Generic.enrollmentError': 'An unexpected error has occurred and we are unable to enroll your account in EasyPay. Please call (800) 521-1734 for assistance.',
    'Generic.invalidEmailFormat': 'Please enter a valid email address.',
    'Generic.emailSendError': 'There was an error sending the email',
    'Generic.noSelection': 'Please make a selection.',
    'Generic.invalidDate': 'Please enter a valid date (MM/DD/YYYY)',
    'Generic.noRecordsFound': 'No records found.',
    'Generic.invalidInput': 'Invalid input received.',
    'Generic.NotSupported': 'Feature not supported',
    'Generic.errorADTGoCustomer': 'Please go to ADT Go app to update the context',
    enrolled: 'Enrollment successful. Please log in.',
    emailChanged: 'Your Login Email has been successfully changed. Please log in.',
    logout: 'You have been logged out successfully!',
    'Header.linkTitle': 'ADT LLC',
    'Header.logoAlt': 'ADT Logo',
    'Header.alwaysThere': 'ADT <em>Always There</em>&reg;',
    'Header.broadviewLink': 'We noticed you are also a former Broadview customer. If you need information for that account, please visit <a href="http://myadtonline.com">MyADTOnline</a>.',
    'General.errorAllFieldsRequired': 'All fields are required',
    'General.billingSystemMaintenance': 'The MyADT.com billing system is not available at this time, so please check back with us. We apologize for the inconvenience.',
    'AjaxHandler.waitMessage': 'Please wait while the system processes your request.',
    'Account.myADT': 'MyADT',
    'Account.logout': 'Log Out',
    'Account.welcome': 'Welcome back,',
    'Account.lastLogIn': 'Last Log In:',
    'Navbar.accountSummary': 'Account Summary',
    'Navbar.search': 'Search',
    'Navbar.billing': 'Billing',
    'Navbar.billingDescription': 'Pay your bill, manage your account and set up payments',
    'Navbar.makePayment': 'Make a Payment',
    'Navbar.easyPay': 'EasyPay',
    'Navbar.billingAndPayments': 'Billing & Payments',
    'Navbar.myBill': 'My Bill',
    'Navbar.billHistory': 'Account Statements',
    'Navbar.enrollEasyPay': 'Enroll in ADT<sup>&reg;</sup> EasyPay',
    'Navbar.manageEasyPay': 'Manage ADT<sup>&reg;</sup> EasyPay',
    'Navbar.accountManagement': 'Account Management',
    'Navbar.myContactInfo': 'My Locations',
    'Navbar.addBillingAccount': 'Add a Location',
    'Navbar.userProfile': 'Profile',
    'Navbar.removeBillingAccount': 'Remove a Location',
    'Navbar.upgradePulse': 'Upgrade to ADT Pulse Today',
    'Navbar.pulseDescription': 'From almost anywhere, arm and disarm your home, control climate, lights and video.',
    'Navbar.pulseWorks': 'How ADT Pulse Works',
    'Navbar.manageSecurity': 'Manage Security',
    'Navbar.manageDescription': 'Put your system on test, change your security codes and more!',
    'Navbar.alarmActivity': 'Alarm Activity',
    'Navbar.monitoring': 'Monitoring',
    'Navbar.myAlarm': 'My Alarm',
    'Navbar.addAlarm': 'Add a Location',
    'Navbar.runSystemTest': 'System Test',
    'Navbar.temporaryNoResponse': 'Temporary No Response',
    'Navbar.alarmHistory': 'Alarm Activity',
    'Navbar.securityInfo': 'Security Information',
    'Navbar.securityPasswords': 'Security Passwords',
    'Navbar.alarmContacts': 'Emergency Contacts',
    'Navbar.tipsAndTools': 'Tips & Tools',
    'Navbar.readingYourInvoice': 'Reading Your Invoice',
    'Navbar.crimeMap': 'Crime Map',
    'Navbar.reduceFalseAlarms': 'Reducing False Alarms',
    'Navbar.insuranceDiscounts': 'Insurance Discounts',
    'Navbar.help': 'Help',
    'Navbar.faqs': 'FAQs',
    'Navbar.manuals': 'Manuals',
    'AppAlert.noAlarmActivity': 'We have not detected any system tests or recent activity from your location. ADT recommends testing your system every 30 days.',
    'AppAlert.hardDecline': 'Your last automatic payment was declined and your EasyPay account has been suspended for your security. To clear your balance, please make a one-time payment. Then, please re-enroll in EasyPay with your updated payment information.',
    'AppAlert.returnMail': 'Recent ADT mailings to you have been returned. Please update your billing address at your earliest convenience.',
    'Footer.copyright': '&copy;',
    'Footer.line1': 'ADT LLC. All rights reserved. ADT, the ADT logo, 800.ADT.ASAP and the',
    'Footer.line2': 'product/service names listed in this document are marks and/or registered marks. Unauthorized use is',
    'Footer.line3': 'strictly prohibited.',
    'Footer.legal': 'Legal',
    'Footer.trademark': 'Trademark Copyright',
    'Footer.termsAndConditions': '* Terms & Conditions',
    'Footer.privacy': 'Privacy',
    'Footer.licenses': 'Licenses',
    'Footer.websiteTermsOfUse': 'Website Terms of Use',
    'Footer.customerService': 'Customer Service',
    'Login.title': 'Login',
    'Login.errorInvalidEmail': 'Please enter a valid email address',
    'Login.errorUserOrPwdInvalidB': 'User and/or password is invalid',
    'Login.errorUserOrPwdInvalid': 'Your user name and password did not match our records. Please try again.',
    'UserCredentials.userId': 'Email',
    'UserCredentials.password': 'Password',
    'UserCredentials.rememberMe': 'Remember Me',
    'UserCredentials.login': 'Login',
    'UserCredentials.authError': 'The information you entered was not found.',
    'UserCredentials.forgotPassword': 'Forgot your password?',
    'UserCredentials.passwordRequired': 'Password must be entered.',
    'UserCredentials.userIdRequired': 'Email must be entered.',
    'UserCredentials.broadviewUser.title': 'Redirecting',
    'UserCredentials.broadviewUser.message': 'The login you provided is for a former Broadview Security account. You can go directly to <a href="http://www.myadtonline.com">MyADTOnline</a>, or wait 10 seconds and we will redirect you.',
    'CsrCredentials.userId': 'Email',
    'CsrCredentials.password': 'Password',
    'CsrCredentials.rememberMe': 'Remember Me',
    'CsrCredentials.login': 'Sign in Now',
    'CsrCredentials.forgotPassword': 'Forgot your password?',
    'Dashboard.loading': 'We\'re loading your account information...',
    'Dashboard.securityQuestionNotSet.message': 'You do not have a security question associated with this account. Please visit <a href="userProfile.jsp">Web Profile</a> to set one up.',
    'DashboardNotifications.hardDecline': 'Your last automatic payment was declined and your EasyPay account has been suspended for your security. To clear your balance, please make a <a href="#{$.urlPrefix}auth/mybill.jsp">one-time payment</a>. Then, please <a href="#{$.urlPrefix}auth/easypay.jsp">re-enroll in EasyPay</a> with your updated payment information.',
    'PasswordReset.title': 'Login',
    'PasswordReset.userId': 'Email',
    'PasswordReset.question': 'Secret Question',
    'PasswordReset.answer': 'Answer',
    'PasswordReset.currentPassword': 'Current Password',
    'PasswordReset.newPassword': 'New Password',
    'PasswordReset.retypePassword': 'Retype Password',
    'PasswordReset.submit': 'Submit',
    'PasswordReset.cancel': 'Cancel Reset',
    'PasswordReset.next': 'Next',
    'PasswordReset.emptyUsername': 'Email must be entered.',
    'PasswordReset.usernameNotFound': 'The information that you entered was not found.',
    'PasswordReset.securityQuestionUnavailable': 'Unable to find security question for your account. Please reset password using Reset password by email.',
    'PasswordReset.securityAnswerUnavailable': 'Unable to find original response to security question. Password not changed.',
    'PasswordReset.emailUnavailable': 'Unable to find email for your account. Please reset password using Security Question.',
    'PasswordReset.cannotResetPassword': 'Your password cannot be reset at this time. please contact ADT at 1.800.238.2727.',
    'PasswordReset.blankAnswer': 'Please enter an answer to your secret question.',
    'PasswordReset.blankPassword': 'Please enter a password for your account.',
    'PasswordReset.blankConfirmPassword': 'Please confirm your password.',
    'PasswordReset.incorrectAnswerShort': 'The secret question answer you provided is not correct.',
    'PasswordReset.incorrectAnswer': 'The secret question answer you provided is not correct. Please enter the correct answer to your secret question.',
    'PasswordReset.invalidPassword': 'Invalid Password. Passwords must be 8-71 characters, contain letters, symbols and numbers, with a minimum of one number or symbol, and a minimum of one letter. They cannot match your email.',
    'PasswordReset.passwordNotMatching': 'Passwords entered do not match',
    'PasswordReset.passwordChanged': 'Password changed!  Use your new password to sign in.',
    'PasswordReset.passwordNotChanged': 'An internal error occurred and the password was not changed.',
    'PasswordReset.resetEmailSent': 'We sent you an email to "${email}" to reset your password.',
    'PasswordReset.linkVerified': 'Account verified! Enter your new password below.',
    'PasswordReset.invalidLink': 'Sorry, the password reset link you used is not valid, or has expired. Please click the "Forgot your password?" link if you need another one.',
    'PasswordReset.invalidPasswordProvided': 'Invalid Password.',
    'PasswordReset.securityQuestionAnswerIsRequired': 'Please choose a security question below and include your answer in the answer field.',
    'UserProfile.blankUserName': 'Please provide a first name',
    'UserProfile.invalidPassword': 'Invalid Password. Passwords must be 8-71 characters, contain letters, symbols and numbers, with a minimum of one number or symbol, and a minimum of one letter. They cannot match your email.',
    'UserProfile.passwordNotMatching': 'Passwords do not match',
    'UserProfile.blankPassword': 'Please enter a password for your account',
    'UserProfile.blankConfirmPassword': 'Please confirm your password',
    'UserProfile.errorPasswordCouldNotBeChanged': 'Your password could not be changed at this time.',
    'UserProfile.errorPasswordExpired': 'Your password is expired, Please set new password.',
    'PasswordReset.notLoggedIn': 'Please login before changing password.',
    'PasswordReset.passwordSame': 'Please change. Current and new passwords are same.',
    'PasswordReset.userRegistrationNotCompleted': 'You have not completed registration.',
    'UserProfile.errorBlankEmail': 'Please enter an email address',
    'UserProfile.errorDeletingUser': 'Unable to delete the user. Please try again later.',
    'UserProfile.errorInvalidEmail': 'Please enter a valid email address',
    'UserProfile.errorBlankConfirmEmail': 'Please enter a confirmation email address',
    'UserProfile.errorNonMatchingConfirmEmail': 'Your email addresses do not match.',
    'UserProfile.errorProblemChangingEmail': 'Your email address could not be changed at this time.',
    'UserProfile.errorSameAsCurrentEmail': 'The email you have specified is the same as your current email address.',
    'UserProfile.errorUsernameExists': 'The email you are trying to use already exists in our system.',
    'UserProfile.errorInvalidPasswordProvided': 'Invalid password provided.',
    'UserProfile.errorBlankQuestion1': 'Please select a question',
    'UserProfile.errorBlankAnswer1': 'Please enter an answer',
    'UserProfile.errorQuestionAnswerCannotBeChanged': 'Your security question and answer could not be changed at this time.',
    'UserProfile.successUserNameChanged': 'Your first name has been successfully changed',
    'UserProfile.successLoginChanged': 'Your login email has been successfully changed',
    'UserProfile.successPasswordChanged': 'Your password has been successfully changed',
    'UserProfile.successQuestionAnswerChanged': 'Your Security question and/or answer has been successfully changed.',
    'BillView.myBill': 'My Bill',
    'BillView.downloadLatestPdf': 'Download Latest Bill (PDF - 600k)',
    'BillView.pendingPayment': 'Pending Amount:',
    'BillView.lastPayment': 'Last Payment:',
    'BillView.newCharges': 'New Charges:',
    'BillView.pastDue': 'Past Due:',
    'BillView.totalDue': 'Total Amount Due:',
    'BillView.paymentDueDate': 'Payment Due Date:',
    'BillView.makePayment': 'Make a payment from',
    'BillView.creditCard': 'Credit Card',
    'BillView.bankAccount': 'Bank Account',
    'BillView.viewBillHistory': 'View Account Statements',
    'BillView.payMyBill': 'Pay My Bill',
    'BillView.noBillsAvailable': 'We do not have any bill history for this account. Service must be active for at least thirty days before bill history becomes available. Please try again later.',
    'BillView.on': 'on',
    'BillView.billHistory': 'Account Statements',
    'billingHistory.customerAddress.missing': 'Customer Address Missing.',
    'billingHistory.customerNumber.missing': ' Customer Number Missing.',
    'billingHistory.customerName.missing': 'Customer Name Missing.',
    'ContractView.noContractsAvailable': 'We did not find any contracts associated with this account. Not all contracts are available online. If you do not see the contract you are looking for and have questions or concerns please <a href="http://www.adt.com/contact-adt" target="_blank">contact us.</a>',
    POSTAL_CODE_ERROR: 'Zip Code not recognized. Please check for errors and try again.',
    DEFAULT_ERROR: 'Please make sure that all required fields are filled out completely and try again.',
    EARLY_WARNING_ERROR: 'This address appears to be new and cannot be verified at this time. Please call Customer Care for assistance.',
    SUITE_ERROR: 'Please make sure that the address is complete and accurate and try again.',
    STREET_ERROR: 'Please check that the street address is complete and accurate and try again.',
    PO_BOX_ERROR: 'Please check that the PO, RR, or HC box number is correct and try again.',
    PMB_NUM_ERROR: 'Please check that the private mail box number is complete and try again.',
    ADDRESS_ERROR: 'Address could not be verified at this time. Please try again later.',
    UPDATE_ERROR: 'Address could not be updated at this time. Please try again later.',
    'ReturnMailFlag.GenericError': 'An unexpected error has occurred and we are unable to complete your request at this time.',
    SUITE_OR_APT_NOT_REQ_ERROR: 'A suite number was entered but this address does not have subunits.',
    'EasypayForm.easypayTitle': 'Enroll In ADT<sup>&reg;</sup> EasyPay',
    'EasypayForm.manageEasypayTitle': 'Manage ADT<sup>&reg;</sup> EasyPay',
    'Easypay.errorBlankCCBillingZip': 'Billing Zip code cannot be blank',
    'Easypay.errorInvalidCCBillingZip': 'Please enter a valid ZIP code',
    'Easypay.errorBlankCCNumber': 'Credit card number cannot be blank',
    'Easypay.errorInvalidCreditCard': 'Invalid Credit Card',
    'Easypay.errorExpiredCCDate': 'The credit card provided has expired',
    'Easypay.errorBlankBankAccount': 'Bank account number cannot be blank',
    'Easypay.errorBlankReenterAccount': 'You must re-enter your bank account number',
    'Easypay.errorNonMatchingBankAccount': 'Bank account numbers must match',
    'Easypay.errorBlankRoutingNumber': 'Routing number cannot be blank',
    'Easypay.errorBlankInitials': 'Please enter your first, middle and last name initials in the Authorization Initials box',
    'Easypay.errorOnServer': 'Server Error',
    'Easypay.errorCustomerAlreadyEnrolled': 'ADT<sup>&reg;</sup> EasyPay Customer is already enrolled',
    'Easypay.errorEnrollment': 'Error Enrolling in ADT<sup>&reg;</sup> EasyPay',
    'Easypay.errorBankAccountNumberBlocked': 'Bank account number is blocked.',
    'Easypay.errorInvalidAbaAcctNo': 'Invalid ABA/Account number combination entered. Please verify and re-enter ABA/Account number.',
    'Easypay.errorUnrecognizedAchNo': 'The ACH number provided was not recognized.',
    'Easypay.cancelledAccount': 'This billing account is currently canceled. Canceled accounts are not eligible for ADT<sup>&reg;</sup> Easypay.',
    'Easypay.errorDraftDayUpdatedTwiceIn12Months': 'Please note: Unable to make this change because draft date cannot be updated more than twice in 12 months.',
    'Easypay.errorDraftDayUpdatePendingPriceIncrease': 'Please note:  Unable to make this change as the customer has a pending price increase.',
    'Easypay.errorDraftDayPendingPaymentExists': 'Please note:  There is a pending payment. In order to change the draft day, you must first cancel all pending payments.',
    'Easypay.errorDraftDayPendingpPaymentExists': 'Please note: There is a pending payment. In order to change the draft date, you must first cancel all pending payments.',
    'Easypay.hasPendingChanges': 'This change cannot be made because there are pending EasyPay changes on this account.',
    'Easypay.error.unEnroll': 'An unexpected error has occurred and we are unable to Unenroll you from EasyPay at this time. Please try again later.',
    'Easypay.error.ccExpDateUpdate': 'We had an issue updating your credit card expiration date. Please try again later.',
    'Easypay.error.ccExpDateUpdate.prepay': 'Sorry, we are unable to retrieve the current easypay information to process your update. Please wait a moment and try again.',
    'Registration.blankFirstNameField': 'firstName',
    'Registration.blankLastNameField': 'lastName',
    'Registration.blankBillingZipCodeField': 'billingZipCode',
    'Registration.blankBlankAccountNumberField': 'accountNumber',
    'Registration.blankEmailAddressField': 'emailAddress',
    'Registration.blankConfirmEmailField': 'confirmEmailAddress',
    'Registration.blankSecurityPasswordField': 'securityPassword',
    'Registration.blankPasswordField': 'password',
    'Registration.blankConfirmPasswordField': 'confirmPassword',
    'Registration.blankSecretQuestionField': 'question1',
    'Registration.blankSecretAnswerField': 'secretAnswer1',
    'Registration.blankSitePhoneField': 'sitePhone',
    'Registration.blankCaptchaTextField': 'captchaText',
    'Registration.blankCheckSiteUsage': 'siteAgreementCheck',
    'Registration.accountNumberMustBeNumeric': 'Account number must be numeric and 9 digits or less.',
    'Registration.errorInvalidSitePhone': 'A valid phone number for this location is required',
    'Registration.errorInvalidBillingZipCode': 'Please specify a valid 5-digit US ZIP code.',
    'Registration.errorInvalidEmailAddress': 'An invalid email address was provided.',
    'Registration.errorInvalidConfirmEmailAddress': 'Email addresses must be the same.',
    'Registration.errorNonMatchingConfirmEmail': 'Your email confirmation must match your email.',
    'Registration.errorInvalidPassword': 'Invalid Password. Passwords must be 8-71 characters, contain letters, symbols and numbers, with a minimum of one number or symbol, and a minimum of one letter. They cannot match your email.',
    'Registration.errorNonMatchingConfirmPassword': 'Your password confirmation must match your password.',
    'Registration.errorQuestionsMustBeDifferent': 'Security Question 1 must be different than other questions.',
    'Registration.errorInvalidCaptchaText': 'Captcha text invalid.',
    'Registration.errorSitePhoneAndPicNotRecognized': 'Phone number and Security Password not recognized.',
    'Registration.errorSitePhonePicAndCustNoCombinationNotRecognized': 'Please check your customer number for typos and try again.',
    'Registration.broadviewCustomer': 'The phone number you provided is for a former Broadview Security account. You should go directly to MyADTOnline.com in order to manage this account.',
    'Registration.errorInvalidAccountNumber': 'The account number you entered was invalid.',
    'Registration.errorSitePhoneAndAccountNumberNotRecognized': 'Phone number and Account number not recognized.',
    'Registration.errorCaptchaInvalid': 'CAPTCHA invalid.',
    'Registration.errorAccountLocked': 'For your security, this account has been locked in response to several failed login attempts. Please try back in 30 minutes.',
    'Registration.errorInsertFailed': 'Failed To Create User',
    'Registration.errorUserAlreadyExists': 'The user email you provided is already in use. Please go to https://www.myadt.com/reset-password if you need to retrieve your login information.',
    'Registration.errorAssigningUserToGroup': 'Error assigning user to group.',
    'Registration.errorCreatingNewUser': 'Error creating new user.',
    'Registration.errorNoBillingAccountFound': 'No Billing Account or Site found.',
    'Registration.errorRegistrationError': 'Registration Error.',
    'Registration.errorVerizonCustomersCantRegister': 'Verizon customers cannot register.',
    'Registration.errorMMBCustomersCantRegister': 'The account information you entered cannot be registered at this time.',
    'Registration.errorSiteNotRecognized': 'The account information you entered cannot be found.',
    'Registration.errorMultipleSiteWithSamePhone': 'The account information you entered cannot be registered at this time.',
    'Registration.errorNotEligible': 'The account information you entered cannot be registered.',
    'Registration.accountInfoErrorReported': 'More information is needed.',
    'Registration.errorInvalidAccount': 'Accounts of this type are currently ineligible for registration with myADT.com.',
    'Registration.customerNumberNeededVisitMyAdtcom': 'We are having trouble identifying your location. Please provide your account number to help us resolve this issue. If you do not know your account number, please call Customer Care at 1-800-238-2727 for assistance in adding this location to your MyADT account.',
    'Registration.errorOnSystemInfo': 'Trouble getting system information associated with the Site.',
    'Registration.errorDIYnotSupported': 'This account is not supported on MyADT.',
    'Registration.errorNotEligibleSFBilled': 'The information you entered is not valid. Please click the chat button below for account assistance.',
    'Registration.broadviewUrl': 'http://devmoss01.corp.brinks.com:22221/Pages/Login.aspx',
    'Registration.broadviewLink': 'You also have a Broadview account. If you would like to manage that account please visit <a href="http://myadtonline.com">MyADTOnline</a>.',
    'Registration.invalidLink': 'The link we sent you has expired, but don\'t worry. You can still set up your "MyADT.com account" - the easiest way to manage payments, monitor your alarm history, updates your emergency contacts, and more!',
    'Registration.errorGettingCustomerType': 'Check Emails requires an in service account.',
    'Registration.errorInvalidPic': 'The Verbal Security Password you entered is not valid or eligible to manage this site. ',
    'Registration.errorIneligibleServiceDate': 'This location is currently not in service or is more than 7 days out from installation, and cannot be managed on MyADT yet.',
    'Registration.errorIneligibleSiteType': 'This location is a type that cannot be managed on MyADT. Site type key: A-Small business account serviced by dealer, G-Government account, N-National account, W-AWARE account, Z-ADT internal account.',
    'Registration.accountIsMarkedCancelled': 'The account we identified for this site is marked as canceled.  That can\'t be right can it?... Please enter your account number.',
    'Registration.atLeastOneLocationIsRequired': 'At least one location is required to continue.',
    'Registration.errorPaidCustomer': 'This is a Business Reporting location that has already been registered. Any additional web logins must be created by the admin.',
    'Registration.errorParentCustomerType': 'This kind of account cannot be managed on MyADT. Please call Customer Care for assistance.',
    'Registration.errorEnrollingUDF1OfCustomer': 'This customer failed to get enrolled with UDF1 in customer.',
    'RegisterLocation.primaryPhoneTooltip': 'This is the phone number you provided to ADT as your primary contact number. It is usually the first number ADT will call should an alarm occur. You may have provided your home, business or mobile phone number.',
    'RegisterLocation.picTooltip': 'Sometimes referred to as PIC, Code Word, or PIN. This is the unique word or alphanumeric sequence that you would provide to a Customer Care Representative during an alarm event. This is not necessarily the touchpad code that you enter to arm or disarm the ADT system. This is not the password that you use to login to the MyADT website.',
    'CreateBillingAccountForm.addBillingAccountTitle': 'Add Account',
    'CreateBillingAccountForm.errorBlankPhoneNumber': 'blank',
    'CreateBillingAccountForm.errorBlankSecurityPassword': 'blank',
    'CreateBillingAccountForm.errorInvalidPhoneNumber': 'The phone number you\'ve entered is invalid.',
    'CreateBillingAccountForm.errorSiteUsageNotChecked': 'Please check the site usage agreement',
    'CreateBillingAccountForm.sitePhoneNotFound': 'The site phone number you\'ve entered was not found in our records',
    'CreateBillingAccountForm.errorGettingBillingInfo': 'Error getting billing information',
    'CreateBillingAccountForm.errorCreatingBillingAccount': 'There was an error creating your account',
    'CreateBillingAccountForm.errorCreatingBillingAccountServiceDown': 'There was an error creating your account. A Service MyADT relies on for registration is not responding at this time. Registrations cannot proceed until this service is restored.',
    'RemoveBillingAccount.errorCannotDeleteAllAccounts': 'You must have at least one account enrolled with MyADT.com',
    'RemoveBillingAccount.errorAccountNotFound': 'The account you are trying to remove cannot be found in our systems.',
    'RemoveBillingAccount.errorUnexpectedError': 'Your user account could not be deleted at this time.',
    'OnetimeCC.myBill': 'My Bill',
    'OnetimeACH.myBill': 'My Bill',
    'Payment.allFieldsNeeded': 'All fields are required.',
    'Payment.errorAmountGreaterThanBalance': 'Your payment amount may not exceed the amount due.',
    'Payment.errorPaymentAmountInvalid': 'Payment amount is invalid.',
    'Payment.errorPaymentLessThanFive': 'Please pay balance due if payment is less than $5.00.',
    'Payment.errorPaymentMoreThanHundredThousand': 'Payment amount can not be more than $99,999.99.',
    'Payment.errorPaymentMoreThanTenThousand': 'Payment amount can not be more than $10,000.',
    'Payment.errorPaymentMoreThanFiveThousand': 'Payment amount can not be more than $5,000.',
    'Payment.errorPaymentPending': 'A payment request has already been submitted on this account. We are unable to take a new request at this time.',
    'Payment.errorPostDateRequired': 'Payment Date is required.',
    'Payment.errorPostDateInvalid': 'Requested payment date is invalid.',
    'Payment.errorPostDateRange': 'Payment Date cannot be in the past, or more than 30 days in the future.',
    'Payment.errorZeroBalance': 'You cannot make a payment with a $0 balance due.',
    'Payment.errorCCCardNumberRequired': 'Please enter your credit card number.',
    'Payment.errorCCZipCodeRequired': 'Please enter your credit card zip code.',
    'Payment.errorAgreeSiteTermsAndConditions': 'Please accept the terms and conditions agreement.',
    'Payment.errorCCCardExpired': 'Credit card expiration date indicates the card has expired.',
    'Payment.errorCCCardNumberInvalid': 'Some of the required information is either invalid or missing. Please review and re-submit.',
    'Payment.errorCCInvalidMonth': 'Credit card expiration month is invalid.',
    'Payment.errorCCInvalidYear': 'Credit card expiration year is invalid.',
    'Payment.errorCCZipCodeLength': 'Please enter a 5 digit zip code.',
    'Payment.errorCCLookupFailed': 'Credit Card number was not verified due to technical difficulties.',
    'Payment.errorNameOnCCRequired': 'Please enter the name that appears on your credit card.',
    'Payment.errorCCBillingAddressRequired': 'Please enter your credit card billing address.',
    'Payment.errorAddressTooLong': 'Please limit your address to the first 60 characters',
    'Payment.errorNameOnCardTooLong': 'Please enter only the first 60 letters of the name on your credit card',
    'Payment.errorBankAccountBlocked': 'Bank account number is blocked.',
    'Payment.errorBankAccountLookupFailed': 'Technical difficulties encountered while validating bank account number.',
    'Payment.errorBankAccountNumbersNoMatch': 'Bank account numbers must match.',
    'Payment.errorBankAccountTypeInvalid': 'Bank account type is invalid.',
    'Payment.errorBankAccountNumberRequired': 'Bank account number is required.',
    'Payment.errorBankAccountReEnterRequired': 'You must re-enter your bank account number.',
    'Payment.errorBankAccountTypeRequired': 'Bank account type is required.',
    'Payment.errorRoutingNumberRequired': 'Bank routing number is required.',
    'Payment.errorRoutingNumberBlocked': 'This bank routing number is blocked.',
    'Payment.errorRoutingNumberInvalid': 'Bank routing number must be 9 digits.',
    'Payment.errorRoutingNumberLookupFailed.Old': 'Bank routing number was not verified due to technical difficulties.',
    'Payment.errorRoutingNumberLookupFailed': 'Bank routing number was not verified.',
    'Payment.errorPaymentTypeNotSpecified': 'Valid payment type not provided. Accepted payment types are Credit Card and Bank Account.',
    'Payment.errorOldDate': 'The chosen date is in the past.',
    'Payment.errorPostDateRange2': 'The chosen date cannot be more than 30 days in the future.',
    'Payment.errorPostDateInvalid ': 'Please enter a valid date (MM/DD/YYYY)',
    'Payment.errorAuthInitialsRequired': 'Authorization initials are required',
    'Payment.msgFullyRefunded': 'Payment is already fully refunded',
    'Payment.msgOpenRefund': 'Payment has an open refund',
    'Payment.msgOpenRefundOnPayAcct': 'This payment method has a refund in progress',
    'Payment.msgPaymentReversed': 'Payment has been reversed',
    'Payment.invalidCCNumber': 'Please enter your credit card number',
    'Payment.invalidCardExpiryDate': 'Please verify your credit card expiration date and correct as needed',
    'Payment.invalidPaymentDate': 'Please verify that the payment date entered is within the next 30 days',
    'Payment.invalidPaymentAmount': 'Please verify that the Payment Amount is between $1.00 and $10,000.00 and correct as needed',
    'Payment.paymentRequestAlreadyExists': 'A payment request has already been submitted on this account. We are unable to take a new request at this time.',
    'Payment.reEnterPaymentRequest': 'The system has experienced a problem. Please re-enter your payment request',
    'Payment.invalidBankAcctNumber': 'Please verify your Bank Account Number and correct as needed',
    'Payment.amountExceedsCurrentBalance': 'This payment will bring the amount due below zero, creating a credit balance',
    'Payment.invalidBankAbaNumber': 'Invalid Bank ABA Number. Please verify your Bank Routing Number and correct as needed',
    'Payment.invalidBankRoutingNumber': 'Please verify your Bank Routing Number and correct as needed',
    'Payment.bankAcctTypePOrB': 'We apologize that we are unable to process your payment request at this time',
    "Payment.errorAmountGreaterThanOverpayLimit": "Overpay Amount Cann't be greater than -100",
    'BillingStatement.errorFailedToRetrieve': 'We are experiencing technical difficulties and are unable to retrieve your statement(s) at this time. Please try again later.',
    'BillingStatement.errorResponseNull': 'Response is null',
    'BillingStatement.errorWithErrorCode': 'Error with error code',
    'BillingStatement.failedToRetrieveForEmail': 'There was an issue while attempting to retrieve your statement; email has not been sent.',
    'BillingStatement.errorInvalidEmail': 'An invalid email was provided; email has not been sent.',
    'BillingStatement.invalidUrlProvided': 'An invalid URL was provided; email has not been sent.',
    'COI.certificateOfInstallationLabel': 'Insurance Certificate',
    'COI.edwError': 'ADT Support has received your request for a Security System Insurance Certificate, but is unable to auto-generate your certificate at this time. Your requested certificate will be mailed within the next 72 hours. For immediate assistance, please contact ADT at 1.800.238.2727.',
    'COI.systemsNotFound': 'These systems are currently not monitored or they are inactive. Please call 800.238.2727 to request an insurance certificate.',
    'AlarmSystem.modifyTestButton': 'Save',
    'AlarmSystem.putOnTestButton': 'Place System On Test',
    'AlarmSystem.clearTestLabel': 'Clear Test',
    'AlarmSystem.manageTestButton': 'Manage Test',
    'AlarmSystem.alarmSystem': 'Temporary No Response & System Test ',
    'AlarmSystem.cs': 'CS#',
    'AlarmSystem.endTime': 'End Time',
    'AlarmSystem.secondaryCs': 'Secondary CS #',
    'AlarmSystem.incorrectParameters': 'An error occurred, meaning that the system description could not be changed.',
    'AlarmSystem.systemNotFoundError': 'System could not be found; the system description was not changed.',
    'AlarmSystem.descriptionChanged': 'System description was successfully changed.',
    'AlarmSystem.xld': 'Excel Download',
    'AlarmHistory.loading': 'Loading activity...',
    'AlarmHistory.noAlarmHistoryAvailable': 'There has not been any activity on this account for the last 30 days',
    'AlarmHistory.alarmHistory': 'Last 30 Days of Activity',
    'AlarmHistory.date': 'Date',
    'AlarmHistory.onTest': 'On Test',
    'AlarmHistory.description': 'Description',
    'AlarmHistory.user': 'User',
    'AlarmHistory.zone': 'Zone',
    'AlarmHistory.zoneComments': 'Zone Comments',
    'AlarmHistory.cs': 'CS #',
    'AlarmHistory.xld': 'Excel Download',
    'AlarmHistory.activityReporting': 'Activity Reporting',
    'AlarmHistory.emailOptions': 'Email Options',
    'AlarmHistory.invalidEmail': 'Email address is not in a valid format',
    'AlarmHistory.invalidInterval': 'Incorrect inverval specified',
    'AlarmHistory.invalidReportDates': 'One or more reporting dates is invalid',
    'AlarmHistory.invalidReportName': 'Please provide a name for this report',
    'AlarmHistory.invalidDays': 'Report can only be provided for the past 30, 60, or 90 days',
    'AlarmHistory.legend': 'View Activity Key',
    'AlarmHistory.legend.key': 'Activity Key',
    'AlarmHistory.legend.prefix': 'Prefix',
    'AlarmHistory.legend.description': 'Description',
    'AlarmHistory.legend.explanation': 'Explanation',
    'AlarmHistory.legend.BA': 'Burglar Alarm',
    'AlarmHistory.legend.BA.description': 'Intrusion detected.',
    'AlarmHistory.legend.CA': 'Cancel Alarm',
    'AlarmHistory.legend.CA.description': 'The user entered a valid code on the alarm keypad after an alarm event was activated.',
    'AlarmHistory.legend.CC': 'Critical Condition',
    'AlarmHistory.legend.CC.description': 'A device monitoring a critical environment (chemical storage, blood banks, medical labs, etc.) has detected a change.',
    'AlarmHistory.legend.CF': 'Communications Failure',
    'AlarmHistory.legend.CF.description': 'Potential loss of communication between protected premises and ADT.',
    'AlarmHistory.legend.CL': 'Close',
    'AlarmHistory.legend.CL.description': 'System is armed for the indicated location or zone.',
    'AlarmHistory.legend.CM': 'Carbon Monoxide',
    'AlarmHistory.legend.CM.description': 'Carbon monoxide detected.',
    'AlarmHistory.legend.DE': 'Data Entry',
    'AlarmHistory.legend.DE.description': 'Internal ADT events which display certain types of changes to the account information.',
    'AlarmHistory.legend.EC': 'Early Close',
    'AlarmHistory.legend.EC.description': 'System was armed before the scheduled close time.',
    'AlarmHistory.legend.EO': 'Early/Irregular Open',
    'AlarmHistory.legend.EO.description': 'System was disarmed before the scheduled open time.',
    'AlarmHistory.legend.FA': 'Fire Alarm',
    'AlarmHistory.legend.FA.description': 'Signal from fire detection device ',
    'AlarmHistory.legend.FC': 'Fail to Close',
    'AlarmHistory.legend.FC.description': 'System was not armed before scheduled close time.',
    'AlarmHistory.legend.FO': 'Fail to Open',
    'AlarmHistory.legend.FO.description': 'System was not disarmed before scheduled open time.',
    'AlarmHistory.legend.FR': 'Fail to Restore/Reset',
    'AlarmHistory.legend.FR.description': 'Device (heat detector, hold-up button, etc.) has not reset (or restored) as expected.',
    'AlarmHistory.legend.FT': 'Fail to Test',
    'AlarmHistory.legend.FT.description': 'ADT did not receive an expected test signal.',
    'AlarmHistory.legend.GT': 'Guard Tour',
    'AlarmHistory.legend.GT.description': 'A guard at the premises did not complete a scheduled event.',
    'AlarmHistory.legend.HU': 'Hold-Up/Panic/Duress',
    'AlarmHistory.legend.HU.description': 'Someone at the protected premises has pressed a button indicating that they require police response.',
    'AlarmHistory.legend.IC': 'Communications Failure',
    'AlarmHistory.legend.IC.description': 'Potential loss of communication between protected premises and ADT.',
    'AlarmHistory.legend.IF': 'Communications Failure',
    'AlarmHistory.legend.IF.description': 'Potential loss of communication between protected premises and ADT.',
    'AlarmHistory.legend.IN': 'Information',
    'AlarmHistory.legend.IN.description': 'These signals provide information about your system or account and do not require response from ADT.',
    'AlarmHistory.legend.LB': 'Low Battery',
    'AlarmHistory.legend.LB.description': 'Indicates a system battery or sensor battery is low.',
    'AlarmHistory.legend.LC': 'Late Close',
    'AlarmHistory.legend.LC.description': 'The system was armed after the late close window expired.',
    'AlarmHistory.legend.LO': 'Late Open',
    'AlarmHistory.legend.LO.description': 'The system was disarmed after the late open window expired.',
    'AlarmHistory.legend.MA': 'Medical Alarm',
    'AlarmHistory.legend.MA.description': 'Someone at the protected premises has pressed a button indicating that Emergency Medical Services (EMS) may be required.',
    'AlarmHistory.legend.OA': 'Operator Action',
    'AlarmHistory.legend.OA.description': 'Indicate actions taken by ADT operators.',
    'AlarmHistory.legend.OP': 'Open',
    'AlarmHistory.legend.OP.description': 'System is disarmed for the indicated location or zone.',
    'AlarmHistory.legend.RA': 'Resume Alarm',
    'AlarmHistory.legend.RA.description': 'Internal ADT reminder signal, typically used to mark an account that requires follow-up.',
    'AlarmHistory.legend.RE': 'Restore-Reset',
    'AlarmHistory.legend.RE.description': 'Device or zone has returned to normal status after generating an alarm or trouble signal.',
    'AlarmHistory.legend.SU': 'Supervisory',
    'AlarmHistory.legend.SU.description': 'Signals from devices provided for supervision of a condition at the premises, such as high water and high/low temperature.',
    'AlarmHistory.legend.TR': 'Trouble ',
    'AlarmHistory.legend.TR.description': 'Potential problem with an alarm system or device.',
    'System.OutOfService.myAdtMessage': 'Out of service system ${csNo} will be removed from your account in ${daysLeft} days',
    'AllSystems.OutOfService.myAdtMessage': 'Out of service systems will be removed from your account in few days',
    'PicChallenge.notAuthenticated': 'The Verbal Password you entered is incorrect. As a reminder, this is not your MyADT.com login password. Your Verbal Security Password is the "word" or code that you provide to identify yourself, for example, when canceling a false alarm.',
    'PicChallenge.insufficientAuthority': 'The user level associated with the provided PIC is insufficient to access this area',
    'Contacts.primaryContact': 'Primary Contact Number.',
    'Contacts.secondaryContact': 'Secondary Contact Number.',
    'Contacts.primaryContactRequired': 'A primary contact number is required.',
    'Contacts.phoneTypeRequired': 'Phone type is required.',
    'Contacts.invalidPhoneNumber': 'Oops, you entered an invalid number. Your number must have 10 digits, area code can\'t start with a 1 or 0 and can\'t be 800, 866, 877, 888, 900 or 911, prefix can\'t start with a 1 or 0 and can\'t be 555, 7 of the 10 digits can\'t be the same number, and can\'t be in sequential order (1234… etc).',
    'Contacts.invalidCustomerNumber': 'Account number must not exceed 9 digits in length.',
    'ContactAdd.UnexpectedError': 'An Unexpected error occurred while trying to add a contact.',
    'Contact.errorLastNameRequired': 'Last Name is required.',
    'Contact.errorLastNameLength': 'Last Name may not exceed 20 characters.',
    'Contact.errorFirstNameRequired': 'First Name is required.',
    'Contact.errorFirstNameLength': 'First Name may not exceed 20 characters.',
    'Contact.errorInvalidPhoneNumber': 'Oops, you entered an invalid number. Your number must have 10 digits, area code can\'t start with a 1 or 0 and can\'t be 800, 866, 877, 888, 900 or 911, prefix can\'t start with a 1 or 0 and can\'t be 555, 7 of the 10 digits can\'t be the same number, and can\'t be in sequential order (1234… etc).',
    'Contact.errorInternationalNumber': 'Oops, you entered an invalid number. Your number must have 10 digits, area code can\'t start with a 1 or 0 and can\'t be 800, 866, 877, 888, 900 or 911, prefix can\'t start with a 1 or 0 and can\'t be 555, 7 of the 10 digits can\'t be the same number, and can\'t be in sequential order (1234… etc).',
    'Contact.errorInvalidExtension': 'Extension must not exceed 10 digits in length.',
    'Contact.duplicatePasswordExists': 'Duplicate verbal password exists',
    'Contact.invalidPassword': 'Invalid verbal password provided',
    'Contact.errorCannotDeleteContact': 'Contact can\'t be deleted at this time.',
    'Contact.tooManyPhoneNumbers': 'An emergency contact may have no more than 5 phone numbers.',
    'Contact.successReorderSitePhones': 'Site phone numbers successfully reordered.',
    'Contact.successReorderContacts': 'Contacts successfully reordered.',
    'Contact.cantEditMtmWithEmail': 'To edit this contact, please contact ADT at 1.800.238.2727.',
    'CommonPassword.errorPasswordRequired': 'Password is required.',
    'CommonPassword.errorPasswordLength': 'Password must be between 3 and 10 characters in length.',
    'CommonPassword.cannotDeleteLastAuthorizedPic': 'Cannot delete the last verbal password with the authority to manage the account.',
    'CommonPassword.cannotRemoveLastAuthorizedPic': 'Cannot remove management capability as it would leave this site without a manager.',
    'CommonPassword.unauthorized': 'We are enhancing system functionality and security of our systems. Please call 800-ADT-ASAP to speak with a customer care representative for assistance.',
    'CustomerContactsService.updatePin.service.notAvailable': 'An unexpected error has occurred and we are unable to complete your request at this time.',
    'CustomerContactsService.addContact.service.notAvailable': 'An unexpected error has occurred and we are unable to complete your request at this time.',
    'CustomerContactsService.updateContact.service.notAvailable': 'An unexpected error has occurred and we are unable to complete your request at this time.',
    'CustomerContactsService.deleteContact.service.notAvailable': 'An unexpected error has occurred and we are unable to complete your request at this time.',
    'ManageAccount.allFieldsNeeded': 'Some of the required information is either invalid or missing. Please review and re-submit.',
    'ManageAccount.errorInvalidEmail': 'A valid Email Address is required',
    'ManageAccount.errorPhone1Required': 'A valid primary contact number is required',
    'ManageAccount.errorInvalidPhone1': 'Phone number should be in the format: (123) 456-7890',
    'ManageAccount.errorInvalidPhone2': 'Phone number should be in the format: (123) 456-7890',
    'ManageAccount.errorSiteAlreadyManaged': 'This site is already managed by this MyADT account.',
    'ManageAccount.error420CustomerCannotRegister': 'This location cannot be registered on MyADT until ADT account set up is complete. Please try back later.',
    'ManageAccount.canceledAccount': 'The account you are trying to add is canceled, and cannot be managed.',
    'MyADT.Alert.Account.Deactivate': 'Your MyADT account will be deactivated in ${daysLeft} days',
    'MyADT.Login.Error.Deactivated': 'The alarm system(s) associated with this account has been deactivated due to inactivity. If you think this was done in error please contact us.',
    'MyADT.Login.Error.DeactivatedPartial': 'The alarm system(s) associated with this account has been deactivated due to inactivity. If you think this was done in error please',
    'SecurityPasswords.loading': 'Loading',
    'SecurityPasswords.refreshing': 'Refreshing List',
    'SecurityPasswords.everyone': 'Everyone',
    'SecurityPasswords.assign': 'Assign password to',
    'SecurityPasswords.description': 'Description',
    'SecurityPasswords.password': 'Password',
    'SecurityPasswords.manage': 'manage this account',
    'SecurityPasswords.canBeUsedTo': 'Can be used to',
    'SecurityPasswords.addPassword': 'Add a New Password',
    'SecurityPasswords.edit': 'Edit Password',
    'SecurityPasswords.invalidContact': 'Invalid contact id provided',
    'SecurityPasswords.invalidPassword': 'Invalid password provided',
    'SecurityPasswords.invalidPasswordLength': 'Password cannot be longer than 10 characters',
    'SecurityPasswords.descriptionRequired': 'Description must be provided when assigning password to everyone',
    'SecurityPasswords.passwordRequired': 'Password must be provided',
    'SecurityPasswords.authorizedRequired': 'Please select if this Password can be used to manage this account',
    'SecurityPasswords.authorizedPasswordRequired': 'Cannot delete authorized Password if there are no more authorized passwords remaining',
    'SecurityPasswords.saved': 'Password information saved',
    'SecurityPasswords.deleted': 'Password deleted',
    'SecurityPasswords.save': 'Save',
    'SecurityPasswords.delete': 'Delete',
    'SecurityPasswords.confirmDeleteTitle': 'Delete Password',
    'SecurityPasswords.confirmDelete': 'Are you sure you want to delete this password?',
    'Equipment.datasource': 'Paid Small Business',
    'Equipment.invalidOrderType': 'Invalid order type specified',
    'Equipment.invalidQuantity': 'Invalid quantity provided',
    'Equipment.ineligibleForEnrollment': 'You are not currently eligible to enroll',
    'Equipment.pendingEnrollment': 'There is currently an application for enrollment already pending for this site',
    'QuickPay.accountNumber': 'ADT Account Number',
    'QuickPay.zipCode': 'Zip Code',
    'QuickPay.notFound': 'Sorry! That account information could not be found. Please check your account number and try again.',
    'QuickPay.multipleMatches': 'We\'re having trouble locating your account. Please login to MyADT or call 800.238.2727 to make a payment.',
    'Billing.billingSystem.generalError': 'A billing system error has occurred. Please try back again later or contact us if you need immediate assistance.',
    'Billing.billingSystem.accountDoesNotExist': 'Well that didn\'t work! We may be experiencing temporary system issues. Please check to see that you have provided the correct account information or call us if you need immediate assistance.',
    'Billing.admin.plannedMaintenance': 'Billing will be available again between the following times: <br/> Mon-Fri(7:00am-12:15am), Sat(8:00am-6:00pm), Sun(2:00pm-9:00pm), <BR/>We are closed for maintenance on last saturday of every month. <BR/>*All times are Central Standard Time(CST)',
    'Billing.admin.unplannedMaintenance': 'Our billing system is temporarily unavailable. We are working as quickly as possible to restore access, and apologize for the inconvenience.',
    'Billing.informix.plannedMaintenance': 'Our billing system is temporarily unavailable. We are working as quickly as possible to restore access, and apologize for the inconvenience.',
    'Billing.informix.unplannedMaintenance': 'Our billing system is temporarily unavailable. We are working as quickly as possible to restore access, and apologize for the inconvenience.',
    'Billing.informix.unavailable.myAdt': 'We\'re upgrading your MyADT experience! Unfortunately, MyADT will be offline for a little while. Sorry for the inconvenience. Try again later.',
    'Billing.mmb.plannedMaintenance': 'Our billing system is temporarily unavailable. We are working as quickly as possible to restore access, and apologize for the inconvenience.',
    'Billing.mmb.unplannedMaintenance': 'Our billing system is temporarily unavailable. We are working as quickly as possible to restore access, and apologize for the inconvenience.',
    'Billing.informix.mmbplannedMaintenance': 'Our billing system is temporarily unavailable. We are working as quickly as possible to restore access, and apologize for the inconvenience.',
    'Billing.informix.mmbunplannedMaintenance': 'Our billing system is temporarily unavailable. We are working as quickly as possible to restore access, and apologize for the inconvenience.',
    'loginService.broadviewUserWithoutConversionEnabled': 'Broadview users are not yet eligible to convert to myAdt.com, please go to myadtonline.com to log in.',
    'SiteSurvey.errorComment1000CharsOrLess': 'Your comment must be 1000 characters or less.',
    'SiteSurvey.errorCommentEmpty': 'Please enter a comment.',
    'SiteSurvey.errorRatingOutOfRange': 'You must choose a rating between 1 and 5 (awful - excellent)',
    'SiteSurvey.genericError': 'There was a problem submitting your feedback. Please try again later.',
    'Csr.invalidLoginId': 'Login ID must be entered.',
    'Csr.loginNotFound': 'Login ID not found.',
    'Csr.invalidTelephoneNumber': 'Telephone number must be entered.',
    'Csr.telephoneNumberNotFound': 'Telephone number not found in the system.',
    'Csr.invalidEmailAddress': 'Email Address is blank or Invalid.',
    'Csr.invalidEmailAddresses': 'Please provide a valid email address.',
    'Csr.invalidPrimaryEmailAddress': 'Updates not saved, as there must be at least one email address designated as the primary.',
    'Csr.preferencesNotAvailable': 'This account does not have any email addresses. Please create a MyADT login to manage email preferences.',
    'Csr.registerCheckBoxNotChecked': 'Register for MyADT.com must be checked.',
    'Csr.emailNotProvided': 'Email address must be entered.',
    'Csr.alreadyRegistered': 'Cannot register email with ADT, as it has already been registered.',
    'Csr.impersonationOfAccountRequired': 'An attempt was made to do something that requires impersonation of an account first.',
    'Csr.associatedEmailsFoundMultiplePrimaryEmails': 'This account has multiple Primary Emails associated with it. If the customer would like to make changes to their Email Preferences, please impersonate the customer and select Email Preferences from the left side of the Overview.',
    'Csr.associatedEmailsWrongCustomerNumber': 'We are unable to return the Primary Email information for this account. If the customer would like to make changes to their Email Preferences, please impersonate the customer and select Email Preferences from the left side of the Overview.',
    'Csr.emailNotSupported': 'Currently email id is not supported.',
    'Csr.parentImpersonateNotAllowed': 'This kind of account cannot be impersonated.',
    'Csr.invalidCustomerNumber': 'Customer number must be entered.',
    'Csr.invalidSiteNumber': 'Site number must be entered.',
    'Csr.invalidBillingSystem': 'Billing System must be entered.',
    'Csr.customerTypeNotAllowed': 'Customer type ${custTypeId} : ${custTypeDescr} is not supported in Chudly.',
    'Csr.customerHasNoAllowedSites': 'All customer sites are unsupported in Chudly. ',
    'Csr.siteTypeNotAllowed': 'Site type ${siteTypeId} : ${siteTypeDesc} is not supported in Chudly.',
    'Csr.noAssociatedSiteFound': 'No sites found for this account',
    'Csr.noSitesFound': 'No Sites found for this Customer',
    'Csr.moreThanExpectedSitesFound': 'Unable to display account information - more than 1000 records returned',
    'Csr.alarmActivity.csNumberMissing': 'CS Number was not provided.',
    'Csr.alarmActivity.siteNumberMissing': 'Site Number was not provided.',
    'Csr.alarmActivity.dateRangeMissing': 'Date Range was not provided.',
    'Csr.billing.pendingBillFreq': 'There is a pending frequency change to ${pendingBillFreq} for this account.',
    'Csr.communications.noStatements': 'Unable to retrieve statement at this time.',
    'Csr.impersonation.invalid': 'This is not the active customer session. Please close this tab.',
    'Csr.billing.billFreqChangeNotAllowedOnHealthAccount': 'Home health accounts cannot be updated by Chudly.',
    'Csr.billing.billFreqChangedTwiceIn12Months': 'Unable to make this change because bill frequency cannot be updated more than twice in 12 months.',
    'tnmCalculator.notEligible': 'This system is not eligible for Schedule Appointments discounts.',
    'tnmCalculator.pastDue': 'Customer account is 91+ days past due. Schedule Appointments is not available until the account is brought up to date.',
    'tnmCalculator.incomplete': 'The information you have provided is incomplete. Please ensure all fields are complete.',
    'TnmCalculatorSubmit.incomplete': 'An unexpected error has occurred and we are unable to complete your request at this time.',
    'TnmCalculatorSubmit.errorCommentAvailable': 'We\'re sorry. We are unable to create the job at this time. Please create the job in MMB and include the following information:',
    'tnmCalculator.pendingServiceJobsExist': 'An active service job exists for this system. A new job cannot be created at this time.',
    'tnmCalculator.notEligibleForServiceAppointments': 'Job scheduling is unavailable for systems with the ${servicePlanID} service plan.',
    'tnmCalculator.cancelAppointmentConfirmation': 'Are you sure you want to cancel the appointment?',
    'tnmCalculator.exitAppointmentConfirmation': 'You have not scheduled this job, are you sure?',
    'tnmCalculator.virtualAppointmentMessage': 'You are going to create a virtual appointment.',
    'CreateIncident.errorCreatingIncidentComment': 'We are sorry. We are unable to submit your incident in Chudly at this time. Please create the incident in MMB.',
    'CreateIncident.errorAddingAddtnlComment': 'We are unable to add your comment at this time.',
    'IncidentIssueComments.errorGettingComments': 'We are unable to retrieve comments at this time.',
    'InteractionHistory.errorGettingIncidentsFromMMB': 'There was an issue getting Incidents from MMB.',
    'InteractionHistory.errorGettingDispositionsFromInformix': 'There was an issue getting Dispositions from Informix.',
    'InteractionHistory.errorGettingActionsFromMMB': 'There was an issue getting Actions from MMB.',
    'CMSDocuments.errorEmailing': 'There was an error sending the email.',
    'CMSDocuments.noDocTypes': 'No docTypes.',
    'CMSDocuments.emailDocumentSuccessfuly': 'Email with document successfully sent.',
    'CMSDocuments.availableDocuments': 'Documents are available.',
    'CsrCoi.errorCustNoBlank': 'Customer number is required.',
    'CsrCoi.errorCustNoTooLong': 'Customer number must be no more than 9 digits long.',
    'CsrCoi.errorAccountTypeBlank': 'Account type is required.',
    'CsrCoi.errorSiteNameBlank': 'Site Name is required.',
    'CsrCoi.errorSiteNameTooLong': 'Site name must be no more than 20 characters long.',
    'CsrCoi.errorAddr1Blank': 'Address Line 1 is required.',
    'CsrCoi.errorAddr1TooLong': 'Address 1 must be no more than 200 characters long.',
    'CsrCoi.errorAddr2TooLong': 'Address 2 must be no more than 200 characters long.',
    'CsrCoi.errorCityBlank': 'City is required.',
    'CsrCoi.errorCityTooLong': 'City name must be no more than 35 characters long',
    'CsrCoi.errorStateBlank': 'State is required.',
    'CsrCoi.errorInvalidStateAbbr': 'Invalid state abbreviation.',
    'CsrCoi.errorZipCodeBlank': 'Zip is required.',
    'CsrCoi.errorInvalidZipCodeLength': 'Zip code must be either 5 or 9 digits in length.',
    'CsrCoi.errorPhone1Blank': 'Primary Phone is required.',
    'CsrCoi.errorPhone1Invalid': 'Please enter a valid Phone number.',
    'CsrCoi.errorFullNameBlank': 'Full Name(s) is required',
    'CsrCoi.errorFullNameTooLong': 'Full Name(s) must be less than 70 characters.',
    'CsrCoi.errorFaxCommentTooLong': 'Fax Comments must be less than 256 characters.',
    'CsrCoi.errorFaxNumberBlank': 'Fax Number is required.',
    'CsrCoi.errorFaxNumberInvalid': 'Please enter a valid Fax Number.',
    'CsrCoi.errorEmailBlank': 'Email is required.',
    'CsrCoi.errorEmailInvalid': 'Please enter a valid Email.',
    'CsrCoi.errorCsNumberInvalid': 'Please enter a valid CS Number.',
    'CsrCoi.errorUnableToGetCoiInfo': 'Unable to retrieve the Insurance Certificate for the given CS#.',
    'CsrCoi.errorInstallDateInvalid': 'Valid installation date required to generate COI certificate.',
    'CsrCoi.errorContractDateInvalid': 'Valid contract date required to generate COI certificate.',
    'CsrCoi.errorInstallDatePrecedesContractDate': 'The Contract Date must be earlier than the Install Date.',
    'CsrCoi.errorBAorFARequiredForCIO': 'Either Fire Alarm or Burglar Alarm is required to generate COI certificate.',
    'CsrCoi.msgCoiInfoUpdateSuccessful': 'Update Completed Successfully.',
    'CsrCoi.msgCoiSentSuccessful': 'COI Certificate sent Successfully.',
    'CsrCoi.chooseAtLeastOneDeliveryOption': 'Please choose at least one delivery option.',
    'CsrCoi.certInfoRequired': 'COI Certificate info Required to generate Certificate.',
    'CsrCoi.errorCannotVerifyService': 'We\'re sorry but we are unable to verify service and provide certificates on accounts that are 60 or more days past due.Please go to My Account to make a payment, or call us at 1-800-ADT-ASAP.',
    'CsrCoi.errorContractMonitoredAccount': 'Insurance Certificate unavailable for contract monitored accounts.',
    'CsrCoi.errorNotResiOrSmallBusinessOrCommAcct': 'Insurance Certificate unavailable for this site type.',
    'CsrCoi.errorCanceledAccount': 'Insurance Certificate unavailable for canceled accounts.',
    'CsrCoi.errorOOSAccount': 'Insurance Certificate unavailable for out of service systems.',
    'CsrCoi.errorIsAlreadyRequested': 'Our records indicate that an Insurance Certificate was requested for this location very recently. We try to mail certificates within 72 hours of the request. Please allow 7 to 10 days for your certificate to arrive. If you have any questions, please email to <a href=\'mailto:coi_request@adt.com\'>coi_request@adt.com</a>',
    'CsrCoi.errorMultipleActivePrimarySystems': 'Found multiple active primary systems.',
    'CsrCoi.errorNoPrimarySystem': 'Primary system not found for site.',
    'CsrCoi.errorMissingCoiInfo': 'Please complete all required information to continue.',
    'CsrCoi.CannotValidateCoiInfo': 'Cannot Validate COI Information.',
    'CsrCoi.errorCreateIncident': 'COI request has been submitted successfully, but failed to create COI incident in MMB.',
    'CsrCoi.errorCreateCoiRequest': 'We are unable to send/create COI request at the moment. Please try again later.',
    'CsrCoi.errorCreateFaxRequest': 'We are unable to create COI Fax request at this moment. Please try again later.',
    'CsrCoi.errorCreateEmailRequest': 'We are unable to create COI Email request at this moment. Please try again later.',
    'CsrCoi.errorCreateMailRequest': 'We are unable to create COI Mail request at this moment. Please try again later.',
    'DSWebUsers.errorProblemAddingWebUser': 'An error occurred while attempting to add the new web user.',
    'DSWebUsers.errorBlankEmail': 'The User Login Email is required.',
    'DSWebUsers.errorinvalidEmail': 'The User Login Email provided is not a valid email address.',
    'DSWebUsers.errorBlankTitle': 'The Title must not be blank.',
    'DSWebUsers.errorBlankDept': 'The Department must not be blank.',
    'DSWebUsers.errorBlankPriPhone': 'The Primary Phone must not be blank.',
    'DSWebUsers.errorBlankSecondPhone': 'The Secondary Phone must not be blank.',
    'DSWebUsers.userAlreadyAddedToSite': 'This user has already been added to this site.',
    'DSWebUsers.userHasPendingGrantToSite': 'This user has a pending access grant to this site.',
    'PaymentActivity.unableToRetreivePaymentHistory': 'Payment Activity could not be retrieved at this time.',
    'SavedPayments.invalidExpiryDate': 'Invalid expiration date',
    'SavedPayments.expDateBeforeOldExpDate': 'The new expiration date should be after the expiration date currently associated with this card.',
    'SavedPayments.invalidSavedAccount': 'Saved account not found',
    'SavedPayments.errorDuringSave': 'An error was reported while saving...changes may not have been successful',
    'SavedPayments.errorDuringDelete': 'An error was reported while removing the saved account...account may not have been removed',
    'PasswordReset.CannotResetBroadviewPW': 'Cannot reset passwords on Broadview accounts until Broadview conversion is enabled',
    'quickpay.mtmConversionNotEnabled': 'Cannot accept express payments on Broadview accounts until Broadview conversion is enabled',
    'loginService.mtmConversionNotEnabled': 'Cannot process Broadview conversions until Broadview conversions are enabled',
    'Schedules.invalidTimeInterval': 'Invalid time intervals have been entered.',
    'Schedules.conflictHelp': 'Please contact customer service to assist in resolving existing schedule conflicts.',
    'Schedules.errorUpdateExisting': 'Please contact customer service to assist in resolving existing schedule conflicts.',
    'Schedules.illegalUpdatePermanent': 'Cannot update this permanent schedule. Please contact customer support for assistance',
    'Schedules.temporaryScheduleExists': 'A temporary schedule already exists that overlaps with this date range.',
    'Schedules.invalidScheduleName': 'A schedule name must be provided in order to add, update, or delete schedules.',
    'Login.userAccountLocked': 'For your security this account has been locked due to too many failed login attempts. Please try back in 30 minutes, or call 1-800-ADT-ASAP for assistance.',
    'Promotion.noPromotion': 'No current promotions found. Please visit again soon',
    'Promotion.futurePromotion': 'This promotion has not yet started. Please visit again soon',
    'Promotion.expiredPromotion': 'This promotion is expired. Please visit again soon',
    'Promotion.entryIgnoredPromotion': 'Already Registered for this promotion',
    'PanelCodeAssignments.noAddressesFound': 'No addresses were found.',
    'BatchReports.invalidParameters': 'Invalid parameters provided',
    'BatchReports.invalidId': 'Cannot find parameter settings in database',
    'PMOC.notEnabled': 'This service is not currently enabled.',
    'PMOC.notAvailable': 'This feature is temporarily unavailable; please try again later.',
    'PMOC.notAllowed': 'This location is currently in transition, most likely because the customer is moving. Email preferences cannot be managed at this time.',
    'PMOC.incompleteSave': 'An error occurred while saving; some changes may not have been applied properly.',
    "PMOC.phoneConsentInconsistant": "The Primary Phone and Alternate phone match.  Please update consent to match for both phone numbers or please update/remove the duplicate phone number before proceeding.",
    'Vacation.errorStartDateTooSoon': 'Travel reminders cannot be scheduled for trips that begin in less than 48 hours. Please update contacts now.',
    'Vacation.errorDuplicateVacationInfo': 'These travel dates have already been entered for this location.',
    'Vacation.errorInvalidStartDate': 'Trip start dates cannot be in the past.',
    'Vacation.errorInvalidEndDate': 'Travel end date must be after start date.',
    'Vacation.errorEndDateOverYear': 'Travel end dates cannot be more than 12 months from today.',
    'Vacation.errorInvalidContactEmail': 'Please provide a valid email address.',
    'Vacation.errorMandatoryFields': 'Please complete all fields.',
    'Vacation.errorInvalidDateFormat': 'Please enter dates in MM/DD/YYYY format.',
    'Battery.orderAvailibility.invalidState': 'Currently, ADT cannot ship replacement batteries to customers located in Alaska, Hawaii or Puerto Rico.',
    'Appointment.errorScheduling': 'The appointment was not scheduled. Schedule the appointment for Job# ${jobNo} in MMB.',
    'Appointment.errorUpdatingJobDetails': 'The job update failed. Please review Job# ${jobNo} in MMB and update the following fields if necessary: priority, job request and job comment for escalation.',
    'CancelJob.SuccessWithOutQSP': 'Job #${jobNo} has been canceled. If customer still requires service, a new job must be opened.',
    'CancelJob.SuccessWithQSP': 'Job #${jobNo} has been canceled. Please be sure to cancel the QSP.',
    'CancelJob.error': 'An error occurred. If you are unable to cancel this job it will need to be canceled in MMB.',
    'Appointment.noOffersRetrieved': 'There are currently no appointments scheduled for this location.',
    'Appointment.errorRescheduling': 'We\'re sorry, we were unable to reschedule this appointment. Please try again later.',
    'Appointment.errorCanceling': 'We\'re sorry, we were unable to cancel this appointment. Please try again later.',
    'Appointment.errorRetrievingOffers': 'We\'re having trouble finding available appointment options right now. Please try again later.',
    'Appointment.errorRetrievingJobAppointments': 'We\'re having trouble finding your appointments right now. Please try again later.',
    'AddJobComment.errorUpdatingJobComment': 'The job comment update failed. Please try again.',
    'AddJobComment.successfullyUpdatingJobComment': 'The job comment updated successfully.',
    'Refund.errorRetrievingRefundStatus': 'We\'re having trouble finding your refund status.',
    'Refund.beingProcessed': 'The ${refundAmount} refund requested on ${refundChangeDate} is being reviewed. (EC:${refundNumber}:${refundStatusId})',
    'Refund.posted': 'The ${refundAmount} refund requested on ${refundChangeDate} has been posted to your account or a check has been mailed. (EC:${refundNumber}:${refundStatusId})',
    'Refund.shipped': 'The ${refundAmount} refund requested on ${refundChangeDate} has been approved and will be mailed or posted to your account soon. (EC:${refundNumber}:${refundStatusId})',
    'Refund.havingIssue': 'There appears to be an issue with the  ${refundAmount} refund requested on ${refundChangeDate}. Please call Customer Care for more details. (EC:${refundNumber}:${refundStatusId})',
    'DeclineInfo.errorRetrievingDeclineInfo': 'We\'re having trouble finding payment declines.',
    'LowBattery.deviceNotification': 'One of your peripheral devices is sending a low battery signal.',
    'LowBattery.deviceNotificationPlural': '${numberOfSignals} of your peripheral devices are sending low battery signals.',
    'LowBattery.panelNotification': 'One of your system backup batteries is sending a low battery signal.',
    'LowBattery.panelNotificationPlural': '${numberOfSignals} of your system backup batteries are sending low battery signals.',
    'LowBattery.unknownNotification': 'We are receiving a low battery signal from your location.',
    'LowBattery.unknownNotificationPlural': 'We are receiving ${numberOfSignals} low battery signals from your location.',
    'LowBattery.deviceNotificationSys': 'Low battery: One peripheral device.',
    'LowBattery.deviceNotificationPluralSys': 'Low battery: ${numberOfSignals} peripheral devices.',
    'LowBattery.panelNotificationSys': 'Low battery: One system backup battery.',
    'LowBattery.panelNotificationPluralSys': 'Low battery: ${numberOfSignals} system backup batteries.',
    'LowBattery.unknownNotificationSys': 'Low battery: One signal from your location.',
    'LowBattery.unknownNotificationPluralSys': 'Low battery: ${numberOfSignals} signals from your location.',
    'BatteryOrder.incomplete': 'An error occurred and your battery order cannot be completed at this time. Please contact customer service to order the battery.',
    'BatteryOrder.historybysiteerror': 'An error occurred when retrieving battery order history. Please contact customer service to get your battery order history.',
    'BatteryOrder.sendemailerror': 'Your order has been submitted successfully, but the confirmation email cannot be sent at this time. Please contact customer service regarding any questions with your order.',
    'BatteryOrder.systemQspFree': 'ADT Quality Service Plan: $0.00 when you order online. When you order on MyADT, your QSP provides one free system battery per year. Additional system batteries cost $29.99.',
    'BatteryOrder.systemQspNoFree': 'ADT Quality Service Plan: $29.99. When you order on MyADT, your QSP provides one free system battery per year. Additional system batteries cost $29.99.',
    'BatteryOrder.systemNoQsp': 'Regular price: $29.99.',
    'BatteryOrder.sensorQspFree': 'ADT Quality Service Plan: $0.00 when you order online. When you order on MyADT, your QSP provides up to 5 free device batteries per year. Additional device batteries cost $10.',
    'BatteryOrder.sensorQspNoFree': 'ADT Quality Service Plan: $10. When you order on MyADT, your QSP provides up to 5 free device batteries per year. Additional device batteries cost $10.',
    'BatteryOrder.sensorNoQsp': 'Regular price: $10.',
    'BatteryDropShip.addrStateNotEligible': 'Currently, ADT cannot ship replacement batteries to P.O. boxes or customers located in Alaska, Hawaii or Puerto Rico. Please edit the shipping address.  When finished, select "Continue".',
    'BatteryDropShip.custAccountPastDue': 'Customer account is 91+ days past due. Battery order cannot be placed until the account is brought up to date.',
    'BatteryDropShip.batteryAIOPanel': 'The customer may need a battery for their Command All In One (AIO) Panel or for their Command Wireless Touchscreen. Please confirm with the customer which battery needs to be replaced, the Command AIO Panel Battery or the Command Wireless Touchscreen battery. Then select the correct battery to replace.',
    'BatteryDropShip.battery12volt4amp7amp': 'System may take a 12 Volt 4 Amp battery or a 12 Volt 7 Amp battery, depending on the space available.<br>Please have customer verify space in panel box when changing from a 4 Amp to a 7 Amp battery.<br><br><ul><li>12 Volt 4 Amp (not shippable): 3.5" L x 2.6" W x 3.7" H</li><li>12 Volt 7 Amp (shippable): 6" L x 2.6" W x 3.7" H</li></ul>',
    'BatteryDropShip.batteryNotShippable': 'Battery is not drop-shippable',
    'BatteryDropShip.quickConnectAdemcoLynx': 'Battery may differ based on the panel install date and whether the system has a wireless smoke detector.  Please verify the panel installation date and order the appropriate battery.<br><br><ul><li>Panel installed prior to 2001: Battery is not available for drop ship</li><li>Panel installed 2001-2005: 6 AAA (2 Prong) battery pack</li><li>Panel installed 2006-2009 and has a wireless smoke detector: 6 AA (3 Prong) battery pack</li><li>Panel installed 2006-2009 and no wireless smoke detector: 6 AAA (2 Prong) battery pack</li><li>Panel installed after 2009: 6 AA (3 Prong) battery pack</li></ul>',
    'BatteryDropShip.sixVolt': 'Any approved panel that takes a 6V 1.2 amp battery may also utilize the 6V 1.3 amp battery.',
    'BatteryDropShip.panelGroupNotShippable': 'Battery is not drop-shippable',
    'BatteryDropShip.addressInvalid': 'Please verify the address is correct.',
    'BatteryDropShip.orderQtyInvalid': 'Order Quantity is not correct.',
    'BatteryDropShip.poBoxNotPermitted': 'Batteries cannot be shipped to PO Boxes',
    'ADSOrder.incomplete': 'An error occurred and your adhesive kit order cannot be completed at this time. Please contact customer service to order the kit.',
    'Response.unauthorized': 'unauthorized access.',
    'CustomerInsight.NotUnerollFromEasyPay': 'DO NOT unenroll customer from EasyPay',
    'CustomerInsight.BillAnnually': 'MUST be billed annually',
    'CustomerInsight.BillQuarterly': 'MUST be billed quarterly or annually',
    'CustomerInsight.MutipleContracts': 'Multiple Contracts on File',
    'CustomerInsight.OutOfContract': 'Out of Contract',
    'CustomerInsight.NoContract': 'No Contract Found',
    'CustomerInsight.CancelledWithinChargeBackPeriod': 'Contract Ends: Cancelled in Chargeback',
    'CustomerInsight.NoActiveContract': 'No Active Contract',
    'CustomerInsight.ContractPending': 'Contract Pending',
    'CustomerInsight.PhoneReturnsMoreCustomerSite': 'More than one location matches this phone number. Cannot provide information for more than one location at a time.',
    'CustomerInsight.PhoneReturnsNoCustomerSite': 'No sites are associated with this phone number.',
    'CustomerInsight.NotSupported': 'System not supported',
    'CustomerInsight.Informix': 'Informix',
    'CustomerInsight.DealerOwnedAccount': 'Account is within the charge back period.',
    'UpdateCustomerBillingAddress.emptyFields': 'All fields are required.',
    'UpdateCustomerBillingAddress.moreThanAllowedLength': 'One or more length of this address is lengthier than allowed limit.',
    'UpdateCustomerBillingAddress.genericError': 'An unexpected error has occurred and we are unable to complete your request at this time.',
    'UpdateCustomerBillingAddress.dataError': 'An unexpected error has occurred and we are unable to complete your request at this time.',
    'ContractInfo.referToContractCustOwned': 'Please refer to contract to determine if equipment is customer owned.',
    'csrHud.CustomerInsight.BillAnnually': 'annually',
    'csrHud.CustomerInsight.BillMonthly': 'monthly',
    'csrHud.CustomerInsight.BillQuarterly': 'quarterly/annually',
    'MelissaCheck.service.hasWarnings': 'The Melissa address verification process updated the address, please verify the changes are correct.',
    'MelissaCheck.service.hasErrors': 'The Melissa address verification process does not recognize this as a valid address. Please update the information below.',
    'MelissaCheck.service.hasWarnings.myAdt': 'You changed the Billing address below. Please confirm your changes are correct and click the Update button to complete the changes.',
    'MelissaCheck.service.hasErrors.myAdt': 'The Billing address below is not recognized as a valid address. Please correct the Billing address.',
    'Entitlements.service.notAvailable': 'MyADT is currently unavailable, but our 24/7 monitoring centers are always there protecting what\'s important to you. Please try again later.',
    'Mastermind.service.notAvailable': 'Alarm Contacts are currently unavailable, but our 24/7 monitoring centers are always there protecting what\'s important to you. Please try back later.',
    'MMBPayments.service.notAvailable': 'We\'re sorry. MyADT billing is currently unavailable. Please try back later.',
    'MelissaCheck.service.notAvailable': 'We\'re sorry. MyADT billing is currently unavailable. Please try back later.',
    'InformixPayments.service.notAvailable': 'We\'re sorry. MyADT billing is currently unavailable. Please try back later.',
    'SystemTestMgmt.service.notAvailable': 'System Information is currently unavailable, but our 24/7 monitoring centers are always there protecting what\'s important to you. Please try back later.',
    'SystemTestMgmt.errorPuttingOnTest': 'We\'re sorry. System was not put on test. Please try back later.',
    'Pmoc.service.notAvailable': 'Email Preferences are currently unavailable. Please try back later.',
    'Vertex.service.notAvailable': 'Battery Ordering is currently unavailable. Please try back later, or you can purchase a replacement battery from any battery retailer.',
    'CombinedIVR.service.notAvailable': 'We\'re having trouble finding your appointments right now. Please try again later.',
    'AppointmentStatus.service.notAvailable': 'Appointment rescheduling is currently unavailable. Please try back later.',
    'ServicePower.service.notAvailable': 'Appointment rescheduling is currently unavailable. Please try back later.',
    'CsgStatements.service.notAvailable': 'We\'re sorry. Statements are currently unavailable. Please try back later.',
    'CsgStatements.service.noStatements': 'No statements available.',
    'RefundStatus.service.notAvailable': 'We\'re having trouble finding your refund status right now. Please try again later.',
    'DeclineInfo.service.notAvailable': 'Payment Decline Info is currently unavailable. Please try again later.',
    'ContractInfo.service.notAvailable': 'Contract Information is currently unavailable. Please try again later.',
    'MMBCommonService.service.notAvailable': 'We\'re sorry. MMB Common Service is currently unavailable.',
    'CustomerInsight.service.notAvailable': 'We\'re sorry, Customer Insights is unavailable.',
    'CustomerSiteSearch.service.notAvailable': 'We\'re sorry, Customer Site Search is unavailable.',
    'BatteryOrder.service.notAvailable': 'Battery Order Service is currently unavailable. Please try again later.',
    'BatteryOrderHistoryBySite.service.notAvailable': 'Battery Order History Service is currently unavailable. Please try again later.',
    'BatteryOrderHistoryByDate.service.notAvailable': 'Battery Order History By Date Service is currently unavailable. Please try again later.',
    'UpdateBatteryOrderStatus.service.notAvailable': 'Update Battery Order Status Service is currently unavailable. Please try again later.',
    'RateIncreaseInfoSP.service.notAvailable': 'We\'re sorry, Rate Increase Info SP service is unavailable.',
    'RateIncrease.DeEscalationSuccessful': 'The rate increase has been reversed. The rate should take effect within the next business day and will appear on the customer\'s next statement.',
    'RateIncrease.DeEscalationFailed': 'Something went wrong and  we were not able to reverse the rate increase at this time.',
    'RateIncrease.DeEscalationPending': 'De-escalation is currently pending.',
    'RateIncreaseInfo.MultipleRateIncreases': 'Multiple rate increases associated with the account, please process the rate de-escalation in Informix.',
    'PTP.Successful': 'You have successfully scheduled a promisory payment date.',
    'PTP.Failed': 'Something went wrong! Try again later.',
    'PTP.AlreadyHasPTP': 'Customer already has an open status PTP action.',
    'PTP.PlacedWithCollection': 'Customer with collections, unable to take a promise to pay at this time.',
    'Paymentech.Errors.000': 'Successful request.',
    'Paymentech.Errors.100': 'We experienced an error when processing your transaction. Please try again later.',
    'Paymentech.Errors.110': 'We experienced an error when processing your transaction. Please try again later.',
    'Paymentech.Errors.200': 'Please enter the name on the credit card.',
    'Paymentech.Errors.300': 'Please enter a valid dollar amount.',
    'Paymentech.Errors.310': 'Please enter your credit card number.',
    'Paymentech.Errors.315': 'Please verify your credit card number and correct as needed.',
    'Paymentech.Errors.320': 'Please verify your credit card type and correct as needed.',
    'Paymentech.Errors.330': 'Please enter your credit card expiration date.',
    'Paymentech.Errors.340': 'Please enter your credit card expiration date.',
    'Paymentech.Errors.350': 'Please verify the CVV and correct as needed.',
    'Paymentech.Errors.355': 'Please enter the CVV for the credit card provided.',
    'Paymentech.Errors.357': 'An invalid character was entered, such as a letter in a numeric field.',
    'Paymentech.Errors.360': 'Your payment was declined by your financial institution. Please verify the data entered and try again.',
    'Paymentech.Errors.370': 'Please verify your credit card expiration date and correct as needed.',
    'Paymentech.Errors.400': 'Transaction tracer value does not match',
    'Paymentech.Errors.500': 'Please enter your credit card billing street address.',
    'Paymentech.Errors.510': 'Please enter your credit card billing city.',
    'Paymentech.Errors.520': 'Please enter your credit card billing state.',
    'Paymentech.Errors.530': 'Please enter your credit card billing zip code.',
    'Paymentech.Errors.531': 'Please verify your credit card billing zip code and correct as needed.',
    'Paymentech.Errors.550': 'Country is missing',
    'Paymentech.Errors.600': 'Please enter your bank name.',
    'Paymentech.Errors.610': 'Please enter the routing number for your bank account.',
    'Paymentech.Errors.620': 'Please enter your checking account number.',
    'Paymentech.Errors.630': 'Please verify the routing number and correct as needed.',
    'Paymentech.Errors.640': 'Please verify the routing number and correct as needed.',
    'Paymentech.Errors.630/640': 'Please verify the routing number and correct as needed.',
    'Paymentech.Errors.516': 'We experienced an error when processing your transaction. Please try again later.',
    'Paymentech.Errors.521': 'We experienced an error when processing your transaction.  Please try again later.',
    'Paymentech.Errors.839': 'Please verify your credit card number and correct as needed.',
    'Paymentech.Errors.841': 'Please verify your credit card number and correct as needed.',
    'Paymentech.Errors.20412': 'We experienced an error when processing your transaction. Please try again later.',
    'Paymentech.Errors.9582': 'We experienced an error when processing your transaction. Please try again later.',
    'Paymentech.Errors.PaymentFlowMismatch': 'We experienced an error when processing your transaction. Please try again later.',
    'EmailCommunication.Errors.NoDocument': 'Document doesn\'t exist.',
    'EmailCommunication.Errors.DocumentWriteError': 'Unable to create email attachment.',
    'EmailCommunication.Errors.NoDocumentSelection': 'Please make a selection.',
    'ServiceAppointmentOffers.errorJobNumberIsRequired': 'Job number is required to look for service appointment offers.',
    'ServiceAppointmentOffers.errorRetrievingOffers': 'We\'re having trouble finding available service offers right now. Please try again later.',
    'ServiceAppointmentOffers.spNoTimeAvailable8': 'No appointments are available for the dates and times selected.',
    'ServiceAppointmentOffers.spDateInvalid14': 'The entered start/end date is invalid. Please enter a valid date (MM/DD/YYYY).',
    'ServiceAppointmentOffers.spDaysInvalid15': ' The entered days parameter is invalid. Please select the day of the week appropriately.',
    'ServiceAppointmentOffers.spTimeBandInvalid16': 'The supplied time band is invalid or is not available in Service EPower.',
    'ServiceAppointmentOffers.spIndexInvalid17': 'The supplied Index parameter is invalid.',
    'ServiceAppointmentOffers.spCountInvalid18': 'Requested number of offers count is invalid, can not request for more than 99 offers.',
    'ServiceAppointmentOffers.spEndNotGeStartDate19': 'Start date (#{startDate}) cannot be after end date (#{endDate}).',
    'ServiceAppointmentOffers.spJobIdExists23': 'The given JobID already exists in SERVICEPower and AllowRebook is not SET.',
    'ServiceAppointmentOffers.spPromSetInvalid30': 'The supplied Prom SET is not in the SERVICEPower database or is empty.',
    'ServiceAppointmentOffers.spStartDateInvalid55': 'The entered start date is invalid. Please enter a valid date (MM/DD/YYYY).',
    'ServiceAppointmentOffers.spEndDateInvalid56': 'The entered end date is invalid. Please enter a valid date (MM/DD/YYYY).',
    'ServiceAppointmentOffers.spBothStartAndEndDateInvalid57': 'Both start and end dates are invalid. Please enter a valid date. (MM/DD/YYYY).',
    'ServiceAppointmentOffers.servicePowerDown98': 'Service Power is down.  Please try again later.',
    'ServiceAppointmentOffers.spJobTypeInvalid2': 'The given JobNumber is not in the SERVICEPower database, or is too long, or is empty',
    'ServiceAppointmentOffers.spPromTypeInvalid50': 'The PromType is not in the SERVICEPower database, or isnt the type of at least one of the timebands in the supplied promise SET',
    'ServiceAppointmentOffers.jobImportanceZero476': 'Importance has been used internally for which the associated cost  is 0.',
    'ServiceAppointmentOffers.errorSiteNumberIsRequired': 'Site number is required for site hours look up.',
    'ServiceAppointmentOffers.siteHoursNotFound': 'No site hours defined. Use the Set Site Hours link below to update hours.',
    'ServiceAppointmentOffers.errorCsNoIsRequired': 'CS number is required to look for service appointment offers.',
    'ServiceAppointmentOffers.errorRetrievingSiteHours': 'We\'re having trouble retrieving site hours. Please try again later.',
    'ServiceAppointmentOffers.errorUpdatingSiteHours': 'Site hours cannot be updated at this time.',
    'ServiceAppointmentOffers.jstNoTimeAvailable': 'The date range selected does not show any available technicians. Please adjust your search parameters and search for appointments again.',
    'ServiceAppointmentOffers.jstMissingGeoParameterInMMB': 'Missing geographic parameters in MMB, please reach out to OSC to update MMB Lat and Long parameters.',
    'ServiceAppointmentOffers.jstEmpIdIssue': 'Employee ID issue please reach out to local support for help.',
    'ServiceAppointmentOffers.jstInvalidStartEndTime': 'Invalid Start and or End time.',
    'ServiceAppointmentOffers.jstNoAvailableTechnicians': 'No available technicians to schedule, please reach out to the OSC for support.',
    'ServiceAppointmentOffers.jstTechnicianParameterIssue': 'Technician parameter issue, please reach out to OSC for support',
    'ServiceAppointmentOffers.jstSystemError': 'System Error. Please use a different time frame to schedule',
    'ServiceAppointmentOffers.jstJobTimeFrameScheduled': 'Job time frame is already scheduled, please search again for available appointments.',
    'ServiceAppointmentOffers.jstIncorrectJobStatus': 'Incorrect Job Status. Please reach out to OSC support center',
    'ServiceAppointmentOffers.jstScheduleGenericError': 'System Error. Unable to schedule at this time. Please leave an open status and reach out to OSC Support Center',
    'ServiceAppointmentOffers.jstRescheduleGenericError': 'System Error. Unable to reschedule at this time. Please reach out to OSC Support Center',
    'ServiceAppointmentOffers.jstGenericError': 'Unexpected error. Please reach out to OSC support center',
    'ServiceAppointmentOffers.jstGenericErrorForMyADT': 'An error occurred while trying to ${type} your appointment.<br/>Please call us at 1-800-238-2727 and reference job # ${jobNo}',
    'CustomerService.restServiceDown': 'CustomerService REST Service is down. Please try again later.',
    'CustomerService.customerARInfo.notAvailable': 'We\'re sorry, Customer AR Info is unavailable.',
    'CustomerService.notFound': 'Given accountNumber not found in MMB/informix.',
    'CustomerService.informixCustomerARInfo.contractMonitoredAcctKey': 'Contract Monitored Account',
    'CustomerService.informixCustomerARInfo.dealerAcctKey': 'Dealer Account',
    'CustomerService.informixCustomerARInfo.edisonOnCallAcctKey': 'Edison On Call Account',
    'CustomerService.informixCustomerARInfo.ADTTakeoverAcctKey': 'ADT Takeover Account',
    'CustomerService.informixCustomerARInfo.consolidatedBillingAcctKey': 'Consolidated Billing Account',
    'CsrSearch.streetNameOrNumberIsRequired': 'Zip code search requires either the street number or street name.',
    'CsrSearch.streetNumberAndZipCodeIsRequired': 'Street number and zip code required.',
    'CsrSearch.streetNameAndZipCodeIsRequired': 'Street name and zip code required.',
    'CsrSearch.zipCodeIsRequired': 'Site zip code is always required, along with either the street number or street name.',
    'CsrSearch.invalidSearchCriteria': 'At least one of the search parameters must be entered.',
    'CsrSearch.zipCodeIsInvalid': 'Please enter a valid ZIP code.',
    'CsrSession.csrNotFound': 'Cannot find CSR from Session',
    'Session.customerNumberOrSiteNumberNotFound': 'Cannot find Customer Number or Site Number from Session',
    'ApplyCustomerCreditAmount.unexpectedError': 'Unexpected error occured. Credit not applied successfully.',
    'ApplyCustomerCreditAmount.customerNumberOrSiteNumberDoesntMatch': 'Customer Number or Site Number doesn\'t match',
    'ApplyCustomerCreditAmount.adjustAmount': 'ApplyCustomerCreditAmount.adjustAmount may not be null',
    'ApplyCustomerCreditAmount.myAdtReasonCode': 'ApplyCustomerCreditAmount.myAdtReasonCode may not be null',
    GeneralCredits_66: 'Customer is non MMB billed customer',
    GeneralCredits_77: 'Only credit adjustment allowed',
    GeneralCredits_88: 'Customer/Site Link does not exist',
    GeneralCredits_99: 'Invalid Credit Reason code',
    GeneralCredits_119: 'Employee maximum credit adjustment exceeded',
    GeneralCredits_129: 'Bill Code is not valid for the adjustment',
    GeneralCredits_130: 'Bill code is not for AR Credit Adjustment',
    GeneralCredits_219: 'Error-the data locked by another transaction, please try again later',
    GeneralCredits_299: 'One or more required input parameter(s) is null',
    GeneralCredits_399: 'Error- problem to insert to tmp_payment_header',
    GeneralCredits_499: 'Error - problem to insert to payment_line',
    GeneralCredits_999: 'Some specific Oracle error',
    GeneralCredits_599: 'Number of credits exceeded',
    GeneralCredits_699: 'Daily customer DOA exceeded',
    GeneralCredits_25: 'Daily customer DOA exceeded',
    GeneralCredits_81: 'Number of credits exceeded',
    'csrSystemTestMgmt.clearTest.webService.failure': 'Cancellation request failed. Service unavailable.',
    'csrSystemTestMgmt.getTestInfo.webService.failure': 'System test request failed. Service unavailable.',
    'csrSystemTestMgmt.extendTest.webService.failure': 'System test extension request failed. Service unavailable.',
    'csrSystemTestMgmt.putTest.webService.failure': 'System test request failed. Service unavailable.',
    'putSystemOnTest.genericError': 'System test request failed. Service unavailable.',
    'putSystemOnTest.otherTestExists': 'This system currently has an active test. Please cancel the existing test.',
    'extendSystemOnTest.genericError': 'System test extension request failed. Service unavailable.',
    'cancelSystemOnTest.genericError': 'Cancellation request failed. Service unavailable.',
    'systemOutOfService.genericError': 'System is out of service.',
    'SystemTestMgmt.NullInputs': 'Input values are null (SiteNo/CsNo/SystemNo)',
    'ActionManagement.invalidActionId': 'Action Id from the input request is invalid.',
    'ActionManagement.siteAndCustNumberRequired': 'Both site and customer number are required.',
    'ActionManagement.CaseNbrAndCourtDistrictRequired': 'Both case number and court district are required.',
    'P1Customer.RES': 'No match was found in ADT records. It appears this is a P1 residential customer that may need to be transferred.',
    'P1Customer.COM': 'No match was found in ADT records. It appears this is a P1 commercial customer that may need to be transferred.',
    'P1Customer.NAT': 'No match was found in ADT records. It appears this is a P1 national customer that may need to be transferred.',
    'P1Customer.MULTI': 'No match was found in ADT records. There appears to be multiple P1 account types that may need to be transferred.',
    'LifeShieldCustomer': 'No match was found in ADT or P1 records. It appears this is a LifeShield customer that may be transferred.',
    'EasypayUnenroll.genericError': 'UnenrollEasypay request failed. Service unavailable.',
    'EasypayUnenroll.notenrolled': 'UnenrollEasypay request failed. Service unavailable.',
    'RemoveQSP.invalidSiteType': 'Site type is neither \'R\' nor \'S\',  may not possible to remove QSP.',
    'RemoveQSP.nonMMBCustomer': 'Customer is non MMB billed customer.',
    'RemoveQSP.notAnActiveContract': 'Not an active contract.',
    'RemoveQSP.invalidServicePlanId': 'Service plan ID is null or invalid.',
    'RemoveQSP.success': 'Removed QSP from system.',
    'RemoveQSP.fail': 'Failed to remove QSP.',
    'RemoveQSP.noMaintenancePlan': 'System does not have a QSP maintenance plan.',
    'RemoveQSP.nonQSP': 'Billing line is not QSP. ',
    'adc.issue.connection': 'Issue connecting with alarm.com',
    'adc.issue.generic': 'Unable to process request at this time',
    'adc.user.code.length.failure': 'Code can only be 4 digits in length',
    'UnlockMyADT.success': 'You have successfully unlocked the MyADT account for ',
    'UnlockMyADT.failed': 'Something went wrong! Try again later.',
    'ActionResource.sendFraudPacketEmail.success': 'A fraud packet has been emailed to the specified email address.',
    'ActionResource.sendFraudPacketEmail.failure': 'Something went wrong and we were not able to send the packet. Please try again later.',
    'ActionResource.sendFraudStatusEmail.success': 'An email has been sent to the AMSC requesting that they contact the customer using the preferred method of communication.',
    'ActionResource.sendFraudStatusEmail.failure': 'Something went wrong and we were not able to send the request to the AMSC. Please try again later.',
    'pdfGenerator.emailSystemInfoDetailsDoc.success': 'Email sent successfully',
    'pdfGenerator.emailSystemInfoDetailsDoc.fail': 'Email Failed',
    'AccountManagement.noBillingLineToCreateOffer': 'No recur line available to apply offer',
    'AccountManagement.offerNotAccepted': 'Offer is not in Accepted status',
    'AccountManagement.customerInInitialContract': 'Customer is in their initial term, rate reductions are discouraged.',
    'AccountManagement.cancelNotAllowedForAcctType': 'Cancellation is not supported for this account type in Chudly. Please refer to <a href="http://alpha.adt.com/content/dam/sop/sop/chudly-sidekick/billing/cancellation-not-supported.html" target="sidekick">MyHC</a> for additional information',
    'AccountManagement.cancelMsgIfAcctLessOrEqualTo60Days': 'This may be a <a style="text-decoration: underline;color: #FFFF" href="http://alpha.adt.com/sop/en/index/am-hub/am-inbound-processes/three-day-cancellation.html" target="_blank">3 Day Cancel</a> or <a style="text-decoration: underline;color: #FFF" href="http://alpha.adt.com/sop/index/policies-all/policy-cancellation.html" target="_blank">Never Effective/Never Installed</a> customer. Please refer to MyHC for additional information',
    'AccountManagement.cancelMsgForMultipleContracts': 'This process will cancel all contracts on this account',
    'AccountManagement.cancelMsgForReasonCode': 'Additional documentation is required to waive the balance of contract fees. Please refer to <a style="text-decoration: underline;color: #FFF" href="http://alpha.adt.com/sop/index/policies-all/policy-cancellation.html" target="_blank">MyHC</a> for additional information',
    'AccountManagement.retentionOffersFailed': 'Something went wrong! Try again later.',
    'AccountManagement.unavailable': 'This feature is not available for this account',
    'AccountManagement.ContractExtension.notAllowedType': 'Contract extensions are not supported for this account type.',
    'AccountManagement.ContractExtension.notAllowed': 'Contract extensions are not supported for this customer.',
    'AccountManagement.ContractExtension.noAllowedTerms': 'There is no available contract term for this customer\'s existing contract.',
    'AccountManagement.ContractExtension.notAllowedDueToBillThruDate': 'Contract extensions cannot be processed in Chudly for accounts with bill through dates more than 90 days from today.',
    'Sweepstake.Generic.error': 'An error occured with your submission. Please try again. If error persists please try again later.',
    'Sweepstake.email.exceedsAllowedLength': 'To enter the sweepstakes, email addresses need to be 65 characters or less. If the email address you are trying to use has more than 65 characters, please use a different email address.',
    'Sweepstake.mandatoryFieldEmpty': 'First Name, Email Address and Zip Code cannot be empty',
    'Sweepstake.incorrectPhoneNumber': 'To enter Sweepstakes ADT needs at least one phone number on file. Please go to "Preferences" to update your phone number',
    'Sweepstake.notAgreeTermsAndConditions': 'Please accept Official Rules to submit the Sweepstakes',
    'Sweepstake.notification.postSubmission': 'You\'ve entered the Alexa Dot Sweepstakes on ${submitDate}. Drawing is in ${daysToDraw} days on ${drawDate}. Winners will be notified by email within a week of drawing',
    'PreferenceCenter.phoneConsent.update.failure': 'An error occurred and your saved phone numbers cannot be displayed. Please close the window and try again. If error persists please try again later.',
    'Pmoc.email.update.failure': 'An error occurred and your saved email addresses cannot be displayed. Please close the window and try again. If error persists please try again later.',
    'docuSignDetail.Generic.error': 'Please contact ADT at ${careHelplineNumber} to complete your order.',
    "InstallmentPayment.errorAmountGreaterThanRemainingBalance": "Payment Amount Cann't be greater than the remaining balance of the installment plan."
  };
  /**
   * Matches message key to `keys` object. Returns full message.
   * @memberof Localization
   * @param {String} string message key or full message
   * @return {String} expanded message if available, else returns param
   * @example
   * toExpandedString('Generic.error') ->
   * 	'An unexpected error has occurred and we are unable to complete your request at this time.'
   */

  _exports.keys = keys;

  const toExpandedString = function toExpandedString(string) {
    if (string && typeof string === 'string' && keys[string]) {
      return keys[string];
    }

    return string;
  };

  _exports.toExpandedString = toExpandedString;
  var _default = {
    toExpandedString,
    keys
  };
  _exports.default = _default;
});