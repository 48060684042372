define("adt-wss/pods/dashboard/shop/checkout/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      if (!params.batteryid) {
        this.transitionTo('dashboard.shop.productsservices.battery');
      }
    }

  });

  _exports.default = _default;
});