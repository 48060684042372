define("adt-wss/pods/components/dashboard/account/monitoring-consent/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4jJj77KR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"class\",\"email-opt-in-info\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",false],[12,\"class\",[29,[\"opt-in-icon \",[28,\"if\",[[24,[\"opted\"]],\"enabled\",\"disabled\"],null]]]],[3,\"action\",[[23,0,[]],[28,\"unless\",[[24,[\"opted\"]],\"consentYesClicked\",[24,[\"subscribePrimaryEmails\"]]],null]]],[8],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"td\",true],[10,\"class\",\"email-opt-in-info\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",false],[12,\"class\",[29,[\"opt-in-icon \",[28,\"unless\",[[24,[\"opted\"]],\"enabled\",\"disabled\"],null]]]],[3,\"action\",[[23,0,[]],[28,\"if\",[[24,[\"opted\"]],\"consentNoClicked\",[24,[\"subscribePrimaryEmails\"]]],null]]],[8],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/account/monitoring-consent/template.hbs"
    }
  });

  _exports.default = _default;
});