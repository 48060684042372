define("adt-wss/pods/components/dashboard/account/profile-info/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CONFIRM_PASSWORD_MESSAGE = _exports.PASSWORD_REQUIRED_FOR_CSR = _exports.PASSWORD_INVALID_MESSAGE = _exports.PASSWORD_EQUAL_TO_EMAIL_MESSAGE = _exports.SUCCESS_ACTION = _exports.FAILURE_ACTION = _exports.UPDATE_DATA_ACTION = _exports.CANCEL_EDIT_MODE_ACTION = _exports.SET_EDIT_MODE_ACTION = _exports.UPDATING_STATE = _exports.EDIT_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const EDIT_STATE = 'EDIT_STATE';
  _exports.EDIT_STATE = EDIT_STATE;
  const UPDATING_STATE = 'UPDATING_STATE';
  _exports.UPDATING_STATE = UPDATING_STATE;
  const SET_EDIT_MODE_ACTION = 'SET_EDIT_MODE_ACTION';
  _exports.SET_EDIT_MODE_ACTION = SET_EDIT_MODE_ACTION;
  const CANCEL_EDIT_MODE_ACTION = 'CANCEL_EDIT_MODE_ACTION';
  _exports.CANCEL_EDIT_MODE_ACTION = CANCEL_EDIT_MODE_ACTION;
  const UPDATE_DATA_ACTION = 'UPDATE_DATA_ACTION';
  _exports.UPDATE_DATA_ACTION = UPDATE_DATA_ACTION;
  const FAILURE_ACTION = 'FAILURE_ACTION';
  _exports.FAILURE_ACTION = FAILURE_ACTION;
  const SUCCESS_ACTION = 'SUCCESS_ACTION';
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
  const PASSWORD_EQUAL_TO_EMAIL_MESSAGE = 'Password: Cannot be equal to email. ';
  _exports.PASSWORD_EQUAL_TO_EMAIL_MESSAGE = PASSWORD_EQUAL_TO_EMAIL_MESSAGE;
  const PASSWORD_INVALID_MESSAGE = 'Password: Does not meet requirements. ';
  _exports.PASSWORD_INVALID_MESSAGE = PASSWORD_INVALID_MESSAGE;
  const PASSWORD_REQUIRED_FOR_CSR = 'Login Email: To update the Login Email a new password is required.';
  _exports.PASSWORD_REQUIRED_FOR_CSR = PASSWORD_REQUIRED_FOR_CSR;
  const CONFIRM_PASSWORD_MESSAGE = 'Confirm password: Does not match password. ';
  _exports.CONFIRM_PASSWORD_MESSAGE = CONFIRM_PASSWORD_MESSAGE;
});