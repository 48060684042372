define("adt-wss/pods/dashboard/account/documents/route", ["exports", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/route-helpers"], function (_exports, _appDispatcher, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    myadtSecurity: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    promotionData: Ember.inject.service(),
    accountData: Ember.inject.service,
    systemInformation: Ember.inject.service(),

    afterModel() {
      this.get('appDispatcher').subscribe(_appDispatcher.PIC_VALIDATED_EVENT, this, 'picSubmitted');
      this.get('appDispatcher').subscribe(_appDispatcher.PIC_INVALIDATED_EVENT, this, 'picSubmitted');
    },

    deactivate() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.PIC_VALIDATED_EVENT, this, 'picSubmitted');
      this.get('appDispatcher').unsubscribe(_appDispatcher.PIC_INVALIDATED_EVENT, this, 'picSubmitted');
    },

    picSubmitted() {
      if (!this.get('myadtSecurity.picProvided')) {
        this.transitionTo('dashboard.account.index');
      }
    },

    model() {
      const promotionsDeliverData = (0, _routeHelpers.makeDeliverData)(this, 'promotionData');
      const {
        systemInformation
      } = this.getProperties('systemInformation');
      const renewalOffers = promotionsDeliverData('renewalOffers', promoData => promoData.showRenewalOffers());
      const systemPlanDetails = promotionsDeliverData('systemPlanDetails', promoData => promoData.getSystemPlanDetails(systemInformation.selectedSystem.systemNo));
      return Ember.RSVP.hashSettled({
        renewalOffers,
        systemPlanDetails
      }).then(response => {
        this.isRefresh = false;
        return (0, _routeHelpers.useResolvedOnly)(response);
      }).catch(error => console.error(error));
    }

  });

  _exports.default = _default;
});