define("adt-wss/services/expresspay-service", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/http-resources", "ember-copy", "adt-wss-common/utils/fp", "adt-wss/services/app-dispatcher"], function (_exports, _myadtHelpers, _httpResources, _emberCopy, _fp, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const clearCacheArgs = {
    accountId: '',
    zipCode: '',
    date: null,
    billInfo: null
  };
  /**
   * Express Pay Service Module
   * @module Services/ExpressPay
   *
   * @prop {String} accountId
   * @prop {String} zipCode
   * @prop {Date} date current server date, to eliminate time zone issues
   * @prop {ExpressPayBillInfo} billInfo
   * @prop {Boolean} expressPromo
   */

  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)(clearCacheArgs), {
    apiService: Ember.inject.service(),
    paymentech: Ember.inject.service(),
    csrInfo: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    accountId: Ember.computed.oneWay('currentLocation.profileInfo.accountId'),
    zipCode: Ember.computed.oneWay('currentLocation.profileInfo.address.zip'),
    date: null,
    billInfo: null,
    expressPromo: false,
    uuid: null,
    isMTM: Ember.computed('billInfo', function () {
      return this.get('billInfo.billingSystem') === 'MTM';
    }),

    clear() {
      this.setProperties({
        accountId: '',
        zipCode: '',
        date: null,
        billInfo: null
      });
    },

    /**
     * @param {Object} _ref
     * @param {String} _ref.paymentType
     * @param {Object} _ref.paymentDetails
     * @param {String|Float} _ref.paymentDetails.paymentAmount
     * @param {String} _ref.paymentDetails.postDate MM/DD/YYYY
     * @param {String} _ref.paymentDetails.deviceType 'Web'
     * @param {Object} _ref.achDetails
     * @param {Int} _ref.achDetails.routingNumber
     * @param {Int} _ref.achDetails.accountNumber
     * @param {Int} _ref.achDetails.accountNumber2
     * @param {String} _ref.achDetails.initials def: 'WEB'
     * @param {String} _ref.achDetails.accountType
     * @param {Object} _ref.ccDetails
     * @param {Int|String} _ref.ccDetails.number
     * @param {Int|String} _ref.ccDetails.expirationMonth
     * @param {Int|String} _ref.ccDetails.expirationYear
     * @param {Int|String} _ref.ccDetails.profileId
     * @param {String} _ref.ccDetails.type
     * @param {String} [psResource] resource to use if a Pypestream flow
     */
    payBill({
      paymentType,
      paymentDetails,
      achDetails,
      uID
    }, psResource) {
      const params = psResource == 'psExpressPayPayment' ? null : {
        accountId: this.get('accountId'),
        zipCode: this.get('zipCode')
      };
      const headers = psResource ? {
        client_id: 'pypestream'
      } : null;
      return this.get('apiService').request({
        resource: _httpResources.default.myADT[psResource || 'expressPayPayment'],
        type: 'POST',
        params,
        headers,
        data: {
          paymentDetails,
          paymentType: ['bank', 'card'].indexOf(paymentType) === -1 ? paymentType : null,
          achDetails: paymentType === 'bank' ? achDetails : null,
          ccDetails: paymentType === 'card' ? {
            uID
          } : null
        }
      }).then(response => {
        const code = paymentType === 'bank' ? 'ACH' : paymentType === 'card' ? 'CC' : 'IVR';
        const tagInfo = {
          eventTypeId: 'EXPPAY',
          subEventTypeId: code === 'ACH' ? 'BANKACCT' : paymentType === 'card' ? 'CREDCARD' : 'IVR'
        };
        this.get('ET').sendEventTag(tagInfo);

        _myadtHelpers.default.sendAnalyticEvent(['_trackPageview', "quickPay/made".concat(code, "Payment")]);

        if (this.get('billInfo')) {
          const amountDue = this.get('billInfo.amountDue');
          this.set('billInfo.amountDue', _myadtHelpers.default.precisionRound(amountDue - paymentDetails.paymentAmount, 2));
        }

        if (this.get('csrInfo.isCsr')) {
          const {
            csrTeam,
            csrUsername
          } = this.get('csrInfo.csr');

          _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', "CSR ".concat(code, " EXPRESS PAY"), "CSR Team:".concat(csrTeam), "CSR Username:".concat(csrUsername)]);
        }

        if (response.data.displayEasyPaySignupBanner) {
          this.get('appDispatcher').sendEvent(_appDispatcher.EXPRESS_PAY_EVENT);

          _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'EasyPay Promotion', 'Alert Displayed', 'Express Pay']);

          this.get('ET').sendEventTag({
            eventTypeId: 'EZPAYPRO',
            subEventTypeId: 'ALRTDISP',
            udf2: 'EX_PAY'
          }, false, '1');
          this.set('expressPromo', true);
        }

        return response.data;
      });
    },

    payBillPS(data) {
      const {
        accountId,
        zipCode
      } = this.getProperties('accountId', 'zipCode');
      const httpResource = !accountId || !zipCode ? 'psExpressPayPayment' : 'expressPayPayment';
      return this.payBill(data, httpResource);
    },

    getPayConfDetails({
      flowId,
      uID
    }) {
      return this.get('apiService').myADT.getPayConfDetails({
        flowId,
        uID
      });
    },

    /**
     * Gets payment details from CC or IVR for confirmation screens
     */
    getPayConfDetails_v2(opts) {
      return this.get('apiService').myADT.getPayConfDetails_v2({
        appName: 'web',
        flowId: opts.flowId,
        uID: opts.uID,
        paymentType: opts.paymentType || null
      });
    },

    /**
     * Retrieves user billing info
     * @param {Int} accountId
     * @param {Int} zipCode
     * @param {Boolean} isPS set to true for pypestream form
     * @prop {String} chatId generated by chat window
     */
    getBillInfo(accountId, zipCode, isPS) {
      const endpoint = isPS ? 'psLoginAcc' : 'expressPayInfo';
      return this.get('apiService').myADT[endpoint]({
        accountId,
        zipCode
      }).then(response => {
        const data = (0, _emberCopy.copy)(response.data, true);

        if (isPS) {
          const detail = {
            billInfo: data
          };
          window.top.postMessage(detail, '*');
        }

        data.pendingPayment = parseFloat(data.pendingPayment);
        data.amountDue = parseFloat(data.amountDue);
        data.dueDate = Date.createDate(data.balanceDueDate);
        this.setProperties({
          accountId,
          zipCode,
          date: Date.createDate(data.currentDate).setMidnight(),
          billInfo: data
        });
        this.setAgentOrCustomerId();
        return data;
      });
    },

    /**
     * Retrieves user billing info
     * @param {Int} accountId
     * @param {Int} zipCode
     * @param {Boolean} isPS set to true for pypestream form
     * @prop {String} chatId generated by chat window
     */
    getFlexFiInfo() {
      const accountId = this.get('accountId');
      const billingSystem = 'MMB';
      return this.get('apiService').myADT.expressPayFlexFiInfo({
        accountId,
        billingSystem
      }).then(response => {
        const data = (0, _emberCopy.copy)(response.data, true);
        this.set('flexFiInstallmentInfo', data.flexFiInstallmentInfoList);
        return data;
      });
    },

    /**
     * Validates eligibility for payment authorization
     */
    getOepAuthInfo() {
      return this.get('apiService').myADT.oepAuthInfo({
        remotePayID: this.get('uuid')
      }).then(({
        data
      }) => {
        this.setAgentOrCustomerId();
        return data;
      });
    },

    /**
     * Authorizes Payment Method
     * @param {Object} param
     * @param {String} param.paymentType
     * @param {Object} param.achDetails
     * @param {Int} param.achDetails.routingNumber
     * @param {Int} param.achDetails.accountNumber
     * @param {Int} param.achDetails.accountNumber2
     * @param {String} param.achDetails.initials def: 'WEB'
     * @param {String} param.achDetails.accountType
     * @param {Object} param.ccDetails
     * @param {Int|String} param.ccDetails.number
     * @param {Int|String} param.ccDetails.expirationMonth
     * @param {Int|String} param.ccDetails.expirationYear
     * @param {Int|String} param.ccDetails.profileId
     * @param {String} param.ccDetails.type
     */
    oepAuth({
      paymentType,
      achDetails,
      uID
    }) {
      return this.get('apiService').myADT.oepAuth({
        remotePayID: this.get('uuid'),
        paymentType,
        browserTime: new Date().toISOString(),
        achDetails: paymentType === 'bank' ? (0, _fp.omit)(achDetails, 'initials') : null,
        ccDetails: paymentType === 'card' ? {
          uID
        } : null
      }).then(({
        data
      }) => data);
    },

    setAgentOrCustomerId() {
      const {
        uuid,
        accountId
      } = this.getProperties('uuid', 'accountId');
      window.myADT.agentOrCustomerId = uuid ? "RemotePayID #: ".concat(uuid) : "Customer #: ".concat(accountId);
    }

  });

  _exports.default = _default;
});