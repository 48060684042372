define("adt-wss/authenticators/myadt", ["exports", "ember-simple-auth/authenticators/base", "adt-wss-common/utils/myadt-helpers", "adt-wss/config/environment"], function (_exports, _base, _myadtHelpers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.reasons = void 0;
  const reasons = {
    noAcctInfo: 'Account Info service failure',
    inactivity: 'Your session has ended due to inactivity'
  };
  /**
   * MyADT authenticator
   * uses ember-simple-auth
   * @module Authenticator/MyADT
   */

  _exports.reasons = reasons;

  var _default = _base.default.extend({
    apiService: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    router: Ember.inject.service(),
    impersonationData: Ember.inject.service(),
    accountData: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    windowRedirect: _myadtHelpers.windowRedirect,
    consoleError: _myadtHelpers.consoleError,

    /**
    	Authenticates the session with the specified `identification` and
    	`password`; the credentials are `POST`ed to the server.
    	__If the credentials are valid and authentication succeeds, a promise that
    	resolves with the server's response is returned__, otherwise a promise that
    	rejects with the server error is returned.
    		@instance
    	@param {String} identification The user's identification
    	@param {String} password The user's password
    	@return {Promise} A promise that when it resolves results in the session becoming authenticated
    	@public
    */
    authenticate(identification, password, options) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const credentials = {};

        const timeZone = function getTimeZone() {
          const timeZoneOffset = new Date().getTimezoneOffset();
          let str = 'GMT';
          str += timeZoneOffset > 0 ? '-' : '+';
          const hours = "0".concat(Math.floor(Math.abs(timeZoneOffset) / 60)).slice(-2);
          const minutes = "0".concat(Math.abs(timeZoneOffset) % 60).slice(-2);
          return "".concat(str).concat(hours, ":").concat(minutes);
        }();

        credentials.userId = identification;
        credentials.password = password;
        credentials.timeZone = timeZone;

        if (!identification && !password) {
          const authInfo = this.get('authInfo.authInfo');

          if (authInfo && (authInfo.adtUser || authInfo.csrUser)) {
            Ember.run(null, resolve, authInfo);
          } else {
            Ember.run(null, reject, {
              errors: ['Generic.error']
            });
          }
        } else {
          this.get('apiService').myADT.login(credentials).then(response => {
            if (response.data.adtUser) {
              const tagInfo = {
                eventTypeId: 'LOGIN',
                subEventTypeId: 'SUCCESS'
              };
              this.get('ET').sendEventTag(tagInfo);
              (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Login', 'SuccessBeforeWebSeal']);
              this.get('authInfo').setAuthInfo(response.data); // Get Customer Details before the form finishes submitting!

              this.get('apiService').myADT.getCustomerInfo().then(({
                data
              }) => {
                if (data.defaultAddressHasPulse) {
                  (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CustomerProfile', 'Pulse']);
                  localStorage.setItem('myADT_hasPulse', false);
                }

                if (data.addressListSize) {
                  (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CustomerProfile', 'Locations', data.addressListSize]);
                }

                if (data.isFireUser) {
                  (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CustomerProfile', 'Fire Protection']);
                  localStorage.setItem('myADT_isFireUser', true);
                }

                if (data.isBusinessReportingUser) {
                  (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CustomerProfile', 'Business Reporting']);
                  localStorage.setItem('myADT_isBusinessReportingUser', true);
                }

                if (data.billingSystem) {
                  localStorage.setItem('myADT_billingSystem', data.billingSystem);
                  (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CustomerProfile', 'Billing System', data.billingSystem]);
                }

                if (/smb/i.test(data.customerType)) {
                  localStorage.setItem('myADT_isSmallBusinessUser', true);
                  (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CustomerProfile', 'Small Business User']);
                }

                if (data.activeSystemsForSite && data.activeSystemsForSite.length) {
                  data.activeSystemsForSite.forEach(sys => {
                    localStorage.setItem("myADT_system_".concat(sys.csNo, "_udf3"), sys.udf3 || 'null');
                    (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CustomerProfile', 'System Description', sys.descr || sys.equiptypeId || 'null']);
                    (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CustomerProfile', 'UDF3 Summary System', sys.udf3 || 'null']);
                  });
                } else {
                  (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CustomerProfile', 'UDF3 Summary System', 'No System']);
                }

                if (options && options.newReg) {
                  this.set('authInfo.newRegistration', true);
                }

                this.set('accountData.customerInfo', data);
                return data;
              }).then(data => Ember.run(null, resolve, data)).catch(() => Ember.run(null, resolve, response.data));
            } else {
              if (!response.messages) response.messages = [response.data.message];
              if (!response.errors) response.errors = [response.data.message];
              response.success = false;
              Ember.run(null, reject, response);
            }
          }, results => {
            Ember.run(null, reject, results);
          });
        }
      });
    },

    /**
     * Triggers authentication; by default this method transitions to the
     * `authenticationRoute`. In case the application uses an authentication
     * mechanism that does not use an authentication route, this method can be
     * overridden.
     * @protected
    */
    triggerAuthentication() {
      const authenticationRoute = this.get('authenticationRoute');
      const routeName = this.get('routeName');
      return routeName !== authenticationRoute;
    },

    restore(data) {
      return Ember.RSVP.Promise.resolve(data);
    },

    /**
    	Invalidates user session and redirects to the landing page
    	@return {ApiResponse}
    	@instance
    */
    invalidate(skipRedirect, reason) {
      const apiService = this.get('apiService');

      if (reason) {
        this.consoleError(reason);

        if (reasons[reason]) {
          this.consoleError(reasons[reason]);
          sessionStorage.setItem('myADT_logoutReason', {
            reason: reasons[reason],
            timestamp: Date.now()
          });
        } else {
          this.consoleError(reason);
        }
      }

      sessionStorage.removeItem('contractRenewalClosed');
      sessionStorage.removeItem('chudlySearchId');
      return apiService.myADT.logout().then(() => apiService.myADT.invalidateSession()).finally(() => {
        window.top.postMessage({
          psError: 'session-invalidated'
        }, '*');

        if (document.location.href.indexOf('/ps/') > -1 && (reason != 'ApplicationRoute.noAuth' || document.location.href.indexOf('/ps/login') > -1)) {
          if (/www.myadt|\/\/myadt.com/i.test(document.location.href)) {
            return window.location.replace('https://webchat.pypestream.com/redirect.html');
          }

          return window.location.replace('https://webchat-sandbox.pypestream.com/redirect.html');
        }

        return this.triggerAuthentication();
      });
    },

    endImpersonation() {
      return this.get('impersonationData').endImpersonation().then(response => {
        const {
          emailImpersonatedInCSRCustomerDashboard: impFromEmail
        } = response;

        const rootURL = (0, _myadtHelpers.getOrigin)() + _environment.default.rootURL.substr(1); // remove double slash


        this.windowRedirect("".concat(rootURL, "/auth/csr/#/").concat(impFromEmail ? 'dashboard' : 'search'));
      });
    },

    init() {
      this._super(...arguments);

      this.set('authenticationRoute', _environment.default.authenticationRoute);
    }

  });

  _exports.default = _default;
});