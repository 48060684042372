define("adt-wss/templates/modal-windows/system-test-wizard/silence-alarm-instruction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BTMesHXP",
    "block": "{\"symbols\":[\"pr\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"test-system-wizard-info-modal test-wizard-instruction\"],[10,\"role\",\"region\"],[10,\"aria-label\",\"Silence Alarm.\\n\\tEnter your panel code into the system keypad to stop the siren (if sounding) and end the system test.\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"test-system-wizard-progress\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[8],[0,\"Progress\"],[9],[0,\"\\n\"],[4,\"bs-progress\",null,null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"bar\"]],\"expected `pr.bar` to be a contextual component but found a string. Did you mean `(component pr.bar)`? ('adt-wss/templates/modal-windows/system-test-wizard/silence-alarm-instruction.hbs' @ L6:C5) \"],null]],[[\"value\"],[100]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"test-wizard-info-text system-test-silence-alarm-instruction-info\"],[8],[0,\"\\n\\t\\t\"],[7,\"h4\",true],[10,\"class\",\"test-wizard-info-title\"],[8],[0,\"Silence Alarm\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"\\n\\t\\t\\tEnter your panel code into the system keypad to stop the siren (if sounding) and end the system test.\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/system-test-wizard/silence-alarm-instruction.hbs"
    }
  });

  _exports.default = _default;
});