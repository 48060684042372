define("adt-wss/pods/dashboard/controller", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss-common/mixins/with-fsm", "adt-wss/services/app-dispatcher", "adt-wss/pods/dashboard/constants", "adt-wss/pods/dashboard/route"], function (_exports, _myadtHelpers, _emberHelpers, _withFsm, _appDispatcher, constants, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    IDLE_STATE,
    VALIDATE_PIC_STATE,
    SUBMITTING_PIC_STATE,
    WELCOME_EXPERIENCE_STEP_ONE,
    WELCOME_EXPERIENCE_STEP_TWO,
    WELCOME_EXPERIENCE_STEP_THREE,
    WELCOME_EXPERIENCE_STEP_FOUR,
    WELCOME_EXPERIENCE_STEP_FIVE,
    SUBMITTING_PRIMARY_EMAIL_STATE,
    TOGGLE_SIDEBAR_ACTION,
    CLOSE_SIDEBAR_ACTION,
    VALIDATE_PIC_ACTION,
    SUBMIT_PIC_ACTION,
    RESOLVE_PIC_ACTION,
    REJECT_PIC_ACTION,
    CANCEL_PIC_ACTION,
    SIGN_OUT_ACTION,
    START_WELCOME_EXPERIENCE_ACTION,
    WELCOME_EXPERIENCE_NEXT_STEP_ACTION,
    WELCOME_EXPERIENCE_SKIP_STEP_ACTION,
    WELCOME_EXPERIENCE_EXIT_ACTION,
    PRIMARY_EMAIL_SUCCESS_ACTION,
    PRIMARY_EMAIL_FAILURE_ACTION,
    SWEEPSTAKES_MODAL_ACTION,
    SWEEPSTAKES_MODAL_STATE,
    SWEEPSTAKES_MODAL_CANCEL_EDIT_ACTION
  } = constants;
  const fsmModel = {
    transitions: {
      [IDLE_STATE]: {
        [VALIDATE_PIC_ACTION]: VALIDATE_PIC_STATE,
        [SWEEPSTAKES_MODAL_ACTION]: SWEEPSTAKES_MODAL_STATE,
        [START_WELCOME_EXPERIENCE_ACTION]: WELCOME_EXPERIENCE_STEP_ONE,
        [TOGGLE_SIDEBAR_ACTION]: IDLE_STATE,
        [CLOSE_SIDEBAR_ACTION]: IDLE_STATE,
        [SIGN_OUT_ACTION]: IDLE_STATE
      },
      [SWEEPSTAKES_MODAL_STATE]: {
        [SWEEPSTAKES_MODAL_CANCEL_EDIT_ACTION]: IDLE_STATE
      },
      [VALIDATE_PIC_STATE]: {
        [SUBMIT_PIC_ACTION]: SUBMITTING_PIC_STATE,
        [CANCEL_PIC_ACTION]: IDLE_STATE
      },
      [SUBMITTING_PIC_STATE]: {
        [RESOLVE_PIC_ACTION]: IDLE_STATE,
        [REJECT_PIC_ACTION]: VALIDATE_PIC_STATE
      },
      [WELCOME_EXPERIENCE_STEP_ONE]: {
        [WELCOME_EXPERIENCE_NEXT_STEP_ACTION]: WELCOME_EXPERIENCE_STEP_TWO,
        [WELCOME_EXPERIENCE_EXIT_ACTION]: IDLE_STATE
      },
      [WELCOME_EXPERIENCE_STEP_TWO]: {
        [WELCOME_EXPERIENCE_NEXT_STEP_ACTION]: SUBMITTING_PRIMARY_EMAIL_STATE,
        [WELCOME_EXPERIENCE_SKIP_STEP_ACTION]: WELCOME_EXPERIENCE_STEP_THREE,
        [WELCOME_EXPERIENCE_EXIT_ACTION]: IDLE_STATE
      },
      [SUBMITTING_PRIMARY_EMAIL_STATE]: {
        [PRIMARY_EMAIL_SUCCESS_ACTION]: WELCOME_EXPERIENCE_STEP_THREE,
        [PRIMARY_EMAIL_FAILURE_ACTION]: WELCOME_EXPERIENCE_STEP_TWO
      },
      [WELCOME_EXPERIENCE_STEP_THREE]: {
        [WELCOME_EXPERIENCE_NEXT_STEP_ACTION]: WELCOME_EXPERIENCE_STEP_FOUR,
        [WELCOME_EXPERIENCE_SKIP_STEP_ACTION]: IDLE_STATE,
        [WELCOME_EXPERIENCE_EXIT_ACTION]: IDLE_STATE
      },
      [WELCOME_EXPERIENCE_STEP_FOUR]: {
        [WELCOME_EXPERIENCE_NEXT_STEP_ACTION]: WELCOME_EXPERIENCE_STEP_FIVE,
        [WELCOME_EXPERIENCE_SKIP_STEP_ACTION]: IDLE_STATE,
        [WELCOME_EXPERIENCE_EXIT_ACTION]: IDLE_STATE
      },
      [WELCOME_EXPERIENCE_STEP_FIVE]: {
        [WELCOME_EXPERIENCE_EXIT_ACTION]: IDLE_STATE
      }
    }
  };

  var _default = Ember.Controller.extend(_withFsm.default, {
    mwd: Ember.inject.service('modal-window-dispatcher'),
    myadtSecurity: Ember.inject.service(),
    promotionData: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    contactData: Ember.inject.service(),
    impersonationData: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    userProfile: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    authInfo: Ember.inject.service(),
    sidebarOpened: Ember.computed.bool('media.isNotMobile'),
    fsmModel,
    windowRedirect: _myadtHelpers.windowRedirect,
    currentState: IDLE_STATE,
    isLoading: (0, _emberHelpers.isState)(SUBMITTING_PRIMARY_EMAIL_STATE),
    // for welcome experience
    email: Ember.computed.oneWay('currentLocation.profileInfo.email'),
    sweepStakeInfo: Ember.computed.oneWay('promotionData.content'),
    serviceNotes: true,
    billingNotes: true,
    picValidationPostponed: false,
    sweepstakesModalDrawingTime: false,
    welcomeExpCaption: (0, _emberHelpers.computedApplyFunction)(cs => cs === WELCOME_EXPERIENCE_STEP_FIVE ? 'Done' : 'Next Step', 'currentState'),

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.PIC_INVALIDATED_EVENT, this, _appDispatcher.PIC_INVALIDATED_EVENT);
    },

    [_appDispatcher.PIC_INVALIDATED_EVENT]() {
      const {
        fsm,
        currentState,
        email
      } = this.getProperties('fsm', 'currentState', 'email');

      if ((0, _route.getWelcomeFlag)(email) && currentState === IDLE_STATE) {
        return fsm.dispatch(VALIDATE_PIC_ACTION);
      }

      return this.set('picValidationPostponed', true);
    },

    [VALIDATE_PIC_ACTION]() {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);

      const onClose = () => {
        fsm.dispatch(CANCEL_PIC_ACTION);
        mwd.hide();
      };

      const body = {
        pic: ''
      };

      const onEnter = () => {
        const {
          pic
        } = body;
        mwd.clearMessages();

        if (pic && pic.trim()) {
          return fsm.dispatch(SUBMIT_PIC_ACTION, pic).then(data => {
            if (data.validPic) {
              return fsm.dispatch(RESOLVE_PIC_ACTION);
            }

            return showErrors({
              success: false,
              errors: ['PicChallenge.notAuthenticated']
            }, REJECT_PIC_ACTION);
          }).catch(results => showErrors(results, REJECT_PIC_ACTION));
        }

        this.set('value.error', true);
        return showErrors({
          errors: 'Please enter your password'
        });
      };

      mwd.show({
        name: 'validate-pic',
        title: 'Enter Your Verbal Security Password',
        body,
        onClose,
        onEnter,
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: 'Validate',
          callback: onEnter
        }]
      });
    },

    [SUBMIT_PIC_ACTION](pic) {
      return this.get('myadtSecurity').submit(pic);
    },

    [RESOLVE_PIC_ACTION]() {
      const {
        mwd
      } = this.getProperties('mwd', 'fsm');
      const route = Ember.getOwner(this).lookup('route:dashboard');
      mwd.hide();
      return route.refresh();
    },

    [WELCOME_EXPERIENCE_EXIT_ACTION]() {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      mwd.hide();
      fsm.dispatch(SWEEPSTAKES_MODAL_ACTION);
      return true;
    },

    [SWEEPSTAKES_MODAL_ACTION]() {
      const {
        mwd,
        fsm,
        sweepStakeInfo,
        impersonationData
      } = this.getProperties('mwd', 'fsm', 'sweepStakeInfo', 'impersonationData');

      const onClose = isSubmitted => {
        this.get('ET').sendEventTag({
          eventTypeId: 'SWEEPSTK',
          subEventTypeId: 'BNRCLSD'
        }, false, '3');
        fsm.dispatch(SWEEPSTAKES_MODAL_CANCEL_EDIT_ACTION);

        if (isSubmitted) {
          // trigger StatusMessage in dashboard/index
          this.set('sweepstakesModalDrawingTime', sweepStakeInfo.drawDate);
        } else if (typeof isSubmitted === 'undefined') {
          if (!impersonationData.isCsr) {
            this.get('promotionData').incrementDispositionCounter('SWEEPSTAKE');
          }

          (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Sweepstakes', 'Banner Closed', 'Sweepstakes 3']);
        } else {
          (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Sweepstakes', 'Remind Me Later', 'Sweepstakes 3']);
        }
      };

      if (sweepStakeInfo.showModal) {
        mwd.show({
          name: 'sweepstakes-modal',
          title: 'ADT Sweepstakes',
          body: {
            sweepStakeInfo
          },
          onClose,
          isFooterLine: false
        });
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Sweepstakes', 'Banner Displayed', 'Sweepstakes 3']);
      } else {
        fsm.dispatch(SWEEPSTAKES_MODAL_CANCEL_EDIT_ACTION);
      }
    },

    [SWEEPSTAKES_MODAL_CANCEL_EDIT_ACTION]() {
      const {
        mwd,
        fsm,
        picValidationPostponed
      } = this.getProperties('mwd', 'fsm', 'picValidationPostponed');

      if (picValidationPostponed) {
        return fsm.dispatch(VALIDATE_PIC_ACTION);
      }

      mwd.hide();
      return true;
    },

    [START_WELCOME_EXPERIENCE_ACTION]() {
      const {
        mwd,
        fsm,
        userProfile
      } = this.getProperties('mwd', 'fsm', 'userProfile');
      const tenure = userProfile.userProfileData.firstTimeLogin ? 'new' : 'existing';

      const onClose = () => {
        fsm.dispatch(WELCOME_EXPERIENCE_EXIT_ACTION);
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Login', "".concat(tenure, " customer onboarding skipped")]);
      };

      mwd.show({
        name: 'welcome-experience-intro',
        title: 'Welcome!',
        body: this,
        onClose,
        isFooterLine: false,
        actions: [{
          caption: 'No thanks, I\'ve got this.',
          stl: 'outline',
          callback: onClose
        }, {
          caption: 'Get Started',

          callback() {
            return fsm.dispatch(WELCOME_EXPERIENCE_NEXT_STEP_ACTION);
          }

        }]
      });
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Login', "".concat(tenure, " customer onboarding modal launched")]);
    },

    [WELCOME_EXPERIENCE_SKIP_STEP_ACTION]({
      keepOpen,
      tenure
    }) {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');

      if (!keepOpen) {
        mwd.hide();
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Login', "".concat(tenure, " customer onboarding skipped")]);
        fsm.dispatch(SWEEPSTAKES_MODAL_ACTION);
      }

      return mwd.clearMessages();
    },

    [WELCOME_EXPERIENCE_NEXT_STEP_ACTION]() {
      return new Ember.RSVP.Promise(resolve => {
        const {
          mwd,
          fsm,
          contactData,
          currentState,
          userProfile
        } = this.getProperties('mwd', 'fsm', 'contactData', 'currentState', 'userProfile');
        mwd.clearMessages();
        const tenure = userProfile.userProfileData.firstTimeLogin ? 'new' : 'existing';
        const title = (0, _emberHelpers.computedApplyFunction)(cs => {
          /* eslint-disable no-else-return */
          if (cs === WELCOME_EXPERIENCE_STEP_TWO) {
            return 'Set Up Your Email Preferences';
          } else if (cs === WELCOME_EXPERIENCE_STEP_THREE) {
            return 'Easier Ways to Pay';
          } else if (cs === WELCOME_EXPERIENCE_STEP_FOUR) {
            return 'Features For Your Alarm System';
          } else if (cs === WELCOME_EXPERIENCE_STEP_FIVE) {
            return 'Set Your Preferences';
          }
          /* eslint-enable no-else-return */


          return 'Welcome!';
        }, 'currentState');

        const keepOpen = () => this.get('currentState') === WELCOME_EXPERIENCE_STEP_TWO;

        const skip = () => fsm.dispatch(WELCOME_EXPERIENCE_SKIP_STEP_ACTION, {
          keepOpen: keepOpen(),
          tenure
        });

        const onClose = step => {
          fsm.dispatch(WELCOME_EXPERIENCE_EXIT_ACTION);

          if (step !== WELCOME_EXPERIENCE_STEP_FIVE) {
            (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Login', "".concat(tenure, " customer onboarding skipped")]);
          }
        };

        const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);

        const onEnter = () => {
          const cs = this.get('currentState');

          if (cs === WELCOME_EXPERIENCE_STEP_FIVE) {
            return onClose(cs);
          }

          return fsm.dispatch(WELCOME_EXPERIENCE_NEXT_STEP_ACTION).then(() => {
            if (cs === WELCOME_EXPERIENCE_STEP_TWO) {
              this.set('welcomeExpEmailError', false);
              const {
                email,
                billingNotes,
                serviceNotes
              } = this.getProperties('email', 'billingNotes', 'serviceNotes');

              if (!email || !email.trim().isValidEmail()) {
                this.set('welcomeExpEmailError', false);
                const results = {
                  success: false,
                  errors: ['Generic.invalidEmailFormat']
                };
                showErrors(results, PRIMARY_EMAIL_FAILURE_ACTION);
                throw results;
              } else if (!billingNotes && !serviceNotes) {
                const results = {
                  success: false,
                  errors: ['Please check one or both of the boxes below.']
                };
                showErrors(results, PRIMARY_EMAIL_FAILURE_ACTION);
                throw results;
              }

              return contactData.savePMOC({
                pmoc: {
                  primary: [{
                    email,
                    pending: null
                  }],
                  billing: billingNotes ? [{
                    email,
                    pending: null
                  }] : [],
                  service: serviceNotes ? [{
                    email,
                    pending: null
                  }] : []
                },
                paperlessOption: {
                  pending: false,
                  enrolled: false
                }
              }).then(() => fsm.dispatch(PRIMARY_EMAIL_SUCCESS_ACTION, tenure)).catch(results => showErrors(results, PRIMARY_EMAIL_FAILURE_ACTION));
            }

            return Ember.RSVP.Promise.resolve();
          });
        };

        this.setProperties({
          welcomExpSkip: skip,
          welcomeExpCallback: onEnter
        });

        if (currentState === WELCOME_EXPERIENCE_STEP_TWO) {
          if (!userProfile.userProfileData.firstTimeLogin) {
            skip();
          }

          mwd.show({
            name: 'welcome-experience',
            title,
            body: this,
            isFooterLine: false,
            onClose,
            onEnter
          });
        }

        resolve();
      });
    },

    [PRIMARY_EMAIL_SUCCESS_ACTION](tenure) {
      return (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Login', "".concat(tenure, " customer onboarding preferences completed")]);
    },

    [TOGGLE_SIDEBAR_ACTION]() {
      this.toggleProperty('sidebarOpened');
    },

    [CLOSE_SIDEBAR_ACTION]() {
      this.set('sidebarOpened', false);
    },

    [SIGN_OUT_ACTION]() {
      const {
        session,
        impersonationData
      } = this.getProperties('session', 'impersonationData');
      (0, _route.setSweepstakesFlag)(false);

      if (impersonationData.isCsr) {
        return impersonationData.endImpersonation().then(response => {
          const {
            emailImpersonatedInCSRCustomerDashboard: impFromEmail
          } = response;
          const rootURL = (0, _myadtHelpers.getOrigin)() + this.get('rootURL').substr(1); // remove double slash

          const path = impFromEmail ? 'dashboard' : 'search';
          this.windowRedirect("".concat(rootURL, "/auth/csr/#/").concat(path));
        });
      }

      if (session.isAuthenticated) {
        if (this.get('authInfo.authInfo.isPSEmailLoginAuthenticated')) {
          this.get('appDispatcher').sendEvent(_appDispatcher.PS_SESSION_INVALIDATED_EVENT, null, this);
        } else {
          return session.invalidate(true, {
            message: 'sign out from dashboard header'
          });
        }
      }

      return false;
    }

  });

  _exports.default = _default;
});