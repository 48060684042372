define("adt-wss/pods/components/dashboard/account/payment-options/validator", ["exports", "adt-wss-common/utils/fp", "adt-wss/pods/components/dashboard/account/payment-options/constants"], function (_exports, _fp, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stateUnderValidation = _exports.validate = void 0;

  const validateAmount = (value, due, billingSystem) => {
    const errors = [];
    const amt = parseFloat(value);

    if (amt == null) {
      errors.push('General.errorAllFieldsRequired');
    } else if (amt > 5000 && due < amt) {
      errors.push('Payment.errorPaymentMoreThanFiveThousand');
    } else if (amt > 99999.99) {
      errors.push('Payment.errorPaymentMoreThanHundredThousand');
    } else if (amt > 10000 && billingSystem !== 'MMB') {
      errors.push('Payment.errorPaymentMoreThanTenThousand');
    } else if (amt < 5 && (due <= 0 || due >= 5)) {
      errors.push('Payment.errorPaymentLessThanFive');
    }

    return errors;
  };

  const validateDate = (postDateObj, today) => {
    const errors = [];

    if (!postDateObj) {
      errors.push('General.errorAllFieldsRequired');
    } else if (/invalid/i.test(postDateObj)) {
      errors.push('Payment.errorPostDateInvalid');
    } else if (postDateObj < today || postDateObj.timeSpan(today).days > 30) {
      errors.push('Payment.errorPostDateRange');
    }

    return errors;
  };

  const validateAccountType = v => v ? [] : ['General.errorAllFieldsRequired'];

  const validateRoutingNumber = n => /\d{9}/.test(n) ? [] : ['Payment.errorRoutingNumberInvalid'];

  const validateAccountNumbers = (main, extra) => {
    if (!main) {
      return ['General.errorAllFieldsRequired'];
    }

    if (main && extra && main !== extra) {
      return ['Payment.errorBankAccountNumbersNoMatch'];
    }

    return [];
  };

  const validationStrategies = {
    [_constants.PAYMENT_AMOUNT_ENTRY_STATE]({
      amount,
      date
    }) {
      return {
        amount: validateAmount(...amount),
        date: validateDate(...date)
      };
    },

    [_constants.PAYMENT_METHOD_SELECTION_STATE](options) {
      return {
        accountType: validateAccountType(...options.accountType),
        routingNumber: validateRoutingNumber(...options.routingNumber),
        accountNumber: validateAccountNumbers(...options.accountNumber),
        accountNumber2: validateAccountNumbers(...options.accountNumber2)
      };
    },

    [_constants.FLEXFI_PAYMENT_SELECTION_STATE]({
      amount
    }) {
      return {
        amount: validateAmount(...amount)
      };
    }

  };

  const validate = (state, options = {}) => {
    const validatedFields = validationStrategies[state](options);
    const messages = Array.from(new Set(Object.values(validatedFields).reduce((acc, c) => acc.concat(c), [])));
    return {
      messages,
      fields: Object.entries(validatedFields).reduce((acc, [k, v]) => (0, _fp.omix)(acc, {
        [k]: Boolean(v.length)
      }), {}),
      failed: Boolean(messages.length)
    };
  };

  _exports.validate = validate;

  const stateUnderValidation = (state, {
    type,
    newInstance
  } = {}) => Object.keys(validationStrategies).filter(s => {
    if (s === _constants.PAYMENT_METHOD_SELECTION_STATE) {
      return type === 'bank' && newInstance;
    }

    return true;
  }).includes(state);

  _exports.stateUnderValidation = stateUnderValidation;
});