define("adt-wss/templates/modal-windows/welcome-experience-intro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CVj0F38x",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[11,\"src\",[29,[[22,\"rootURL\"],\"images/v2/welcome-experience/welcome-plane.jpg\"]]],[10,\"alt\",\"Welcome! (image of a plane with 'Welcome!' banner.)\"],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"padded\"],[8],[0,\"\\n\\t\"],[7,\"h2\",true],[8],[0,\"Hello, \"],[1,[24,[\"body\",\"userProfile\",\"userProfileData\",\"firstName\"]],false],[0,\"! Welcome to MyADT.\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"yellow-bar\"],[8],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"\\n\\t\\tLet's take a quick tour of all the tools and features so\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\tyou get the most out of MyADT.\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/welcome-experience-intro.hbs"
    }
  });

  _exports.default = _default;
});