define("adt-wss/pods/dashboard/manage/controller", ["exports", "adt-wss/mixins/dashboard-status-messages", "adt-wss-common/utils/ember-helpers"], function (_exports, _dashboardStatusMessages, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.determinePermissions = _exports.links = void 0;
  const links = [{
    route: 'dashboard.manage.index',
    text: 'Locations',
    determineVisibility: () => true
  }, {
    route: 'dashboard.manage.users',
    text: 'Users',
    determineVisibility: (permissions = {}) => permissions.paidDataServicesAdmin
  }, {
    route: 'dashboard.manage.businessreporting',
    text: 'Business reporting',
    determineVisibility: (permissions = {}, businessReportingStatus = {
      enrollable: true,
      enrolled: false
    }, isCsr = false) => {
      const agentView = !JSON.parse(sessionStorage.getItem('emailImpersonated') || null) && isCsr;
      const {
        businessReportingEnrollable,
        paidDataServicesAdmin
      } = permissions;
      const {
        enrollable,
        enrolled
      } = businessReportingStatus;
      return (businessReportingEnrollable || paidDataServicesAdmin) && (enrollable || enrolled) && !agentView;
    }
  }];
  /**
   * Determines permissions for manage sub-routes
   *
   * @function
   * @param {String} routeName
   * @param {...Object} restArgs - Params which be passed to `determineVisibility` function
   * @return {Boolean}
   */

  _exports.links = links;

  const determinePermissions = (routeName, ...restArgs) => {
    const {
      determineVisibility
    } = links.find(l => l.route === routeName) || {
      determineVisibility: () => true
    };
    return determineVisibility(...restArgs);
  };

  _exports.determinePermissions = determinePermissions;

  const filterLinks = (...args) => links.filter(l => l.determineVisibility(...args));

  var _default = Ember.Controller.extend(_dashboardStatusMessages.default, {
    currentLocation: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    isCsr: Ember.computed.readOnly('authInfo.authInfo.csrUser'),
    businessReportingStatus: Ember.computed.readOnly('currentLocation.profileInfo.businessReportingStatus'),
    links: (0, _emberHelpers.computedApplyFunction)(filterLinks, 'model.permissions', 'businessReportingStatus', 'isCsr')
  });

  _exports.default = _default;
});