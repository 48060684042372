define("adt-wss/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable max-len */
  var _default = {
    smartphone: '(max-width: 768px)',
    tablet: '(min-width: 769px) and (max-width: 1024px)',
    largetTablet: '(min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)',
    desktop: '(min-width: 1025px) and (max-width: 1800px)',
    cinema: '(min-width: 1801px)',
    'not-mobile': '(min-width: 769px)'
  };
  /* eslint-enable max-len */

  _exports.default = _default;
});