define("adt-wss/pods/dashboard/account/payments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NWKln5Kk",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"salesForceBilled\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"stupid/simple-iframe-scrolling\",null,[[\"url\",\"iFrameID\",\"classNames\"],[[28,\"concat\",[\"https://myadt.camp.int.bluebyadt.com/payment?myADT_token=\",[24,[\"model\",\"billingModel\",\"paymentStatus\",\"SalesForceToken\"]]],null],[28,\"concat\",[[24,[\"elementId\"]],\"iframeId-billing-info\"],null],\"search-iframe\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[1,[28,\"dashboard/account/balance-info\",null,[[\"viewStatement\",\"blockedByPending\",\"statusMessages\"],[[28,\"action\",[[23,0,[]],\"dispatch\",\"viewLatestStatement\"],null],[24,[\"blockedByPending\"]],[28,\"mut\",[[24,[\"billingStatusMessages\"]]],null]]]],false],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"section-content\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/account/payments/template.hbs"
    }
  });

  _exports.default = _default;
});