define("adt-wss/pods/components/dashboard/flexfi-identifier-external/component", ["exports", "adt-wss/pods/components/dashboard/flexfi-identifier/component", "adt-wss-common/utils/myadt-helpers"], function (_exports, _component, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable import/order */
  var _default = _component.default.extend({
    initializeGAEvent() {
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Billing', 'FlexFiExternal', this.get('place')]);
    }

  });

  _exports.default = _default;
});