define("adt-wss/pods/components/stupid/usage-agreement/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wvUWnh+A",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"selenium-id\",[22,\"seleniumId\"]],[11,\"class\",[22,\"classes\"]],[8],[0,\"\\n\\t\"],[1,[28,\"input\",null,[[\"id\",\"tabindex\",\"type\",\"name\",\"checked\"],[[24,[\"inputId\"]],\"0\",\"checkbox\",\"remember\",[24,[\"checked\"]]]]],false],[0,\"\\n\\t\"],[7,\"label\",true],[11,\"for\",[22,\"inputId\"]],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"checked-indicator\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"description\"],[8],[14,1],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/stupid/usage-agreement/template.hbs"
    }
  });

  _exports.default = _default;
});