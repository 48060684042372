define("adt-wss/pods/components/dashboard/manage/update-report/component", ["exports", "adt-wss/pods/components/dashboard/manage/add-report/component", "ember-copy"], function (_exports, _component, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Components/Dashboard/Manage/UpdateReport
   * @param {Function} onSubmit - handler for submitting
   * @param {Object[]} locations - list of locations
   * @param {report} report
   * @example
   * {@lang xml} {{dashboard/manage/update-report
  		onSubmit=(action 'submit')
  		locations=locations
  		report=report
  }}
   */
  var _default = _component.default.extend({
    modalName: 'Update Business Report',
    saveBtnName: 'Update Report',

    /**
     * Sets unique `report` prop for each instance of component
     *
     * @function
     */
    prepareInitialData() {
      this.set('report', (0, _emberCopy.copy)(this.get('data'), true));
    },

    /**
     * Resets report to initial state and resets errors
     * Updates `report` and `errors` props
     *
     * @function
     */
    resetDataState() {
      this.set('report', (0, _emberCopy.copy)(this.get('data'), true));
      this.set('errors', null);
    }

  });

  _exports.default = _default;
});