define("adt-wss/pods/components/password-validator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hljSFIhA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h5\",true],[8],[0,\"Password requirements:\"],[9],[0,\"\\n\"],[7,\"ul\",true],[8],[0,\"\\n\\t\"],[7,\"li\",true],[10,\"class\",\"min-length\"],[11,\"class\",[29,[[28,\"if\",[[24,[\"fitsMinLimit\"]],\"passed\",\"failed\"],null]]]],[8],[1,[22,\"MIN_LENGTH\"],false],[0,\" character minimum\"],[9],[0,\"\\n\\t\"],[7,\"li\",true],[10,\"class\",\"max-length\"],[11,\"class\",[29,[[28,\"if\",[[24,[\"fitsMaxLimit\"]],\"passed\",\"failed\"],null]]]],[8],[0,\"A maximum of \"],[1,[22,\"MAX_LENGTH\"],false],[0,\" characters\"],[9],[0,\"\\n\\t\"],[7,\"li\",true],[10,\"class\",\"min-numbers\"],[11,\"class\",[29,[[28,\"if\",[[24,[\"hasLetter\"]],\"passed\",\"failed\"],null]]]],[8],[0,\"At least 1 letter\"],[9],[0,\"\\n\\t\"],[7,\"li\",true],[10,\"class\",\"min-numbers\"],[11,\"class\",[29,[[28,\"if\",[[24,[\"hasNumberOrSymbol\"]],\"passed\",\"failed\"],null]]]],[8],[0,\"\\n\\t\\tAt least 1 number or symbol (! @ # etc.)\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/password-validator/template.hbs"
    }
  });

  _exports.default = _default;
});