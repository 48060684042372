define("adt-wss/pods/components/dashboard/contract-renewal-alert/component", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers", "adt-wss/services/promotion-data"], function (_exports, _emberHelpers, _myadtHelpers, _promotionData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TEMPLATE_1 = 'ocr_template_phase1';
  const TEMPLATE_2 = 'ocr_template_phase2';

  const createEventsMapByTemplates = ({
    addLabelPostfix,
    sendEventTag
  } = {
    addLabelPostfix: v => v
  }) => ({
    [TEMPLATE_1]: {
      display() {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Contract Renewal', 'Alert Displayed', addLabelPostfix('No action taken')]);
      },

      close() {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Contract Renewal', 'Close Alert', addLabelPostfix('Closed alert')]);
      }

    },
    [TEMPLATE_2]: {
      display() {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Contract Renewal 2', 'Alert Displayed']);
        sendEventTag({
          subEventTypeId: _promotionData.eventTagValues.ALERT_DISPLAYED
        });
      },

      close() {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Contract Renewal 2', 'Alert Closed']);
        sendEventTag({
          subEventTypeId: _promotionData.eventTagValues.ALERT_CLOSED
        });
      }

    }
  });
  /**
   * @module Components/Dashboard/ContractRenewalAlert
   * @prop {Object} promotion
   * @prop {Boolean} isCsr
   * @prop {Function} onClose
   * @prop {Function} sendEventTag
   * @example
   * {@lang xml} {{ dashboard/contract-renewal-alert
  		promotion=promotion
  		isCsr=isCsr
  		sendEventTag=(action 'sendContractRenewalEventTag')
  		onClose=(action 'onCloseAlert')
  }}
   */


  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    classNames: ['status-message', 'contract-renewal-alert', 'promo'],
    classNameBindings: ['isTemplate1', 'isTemplate2'],
    content: (0, _emberHelpers.computedApplyFunction)(promotion => promotion.data || {}, 'promotion'),
    currentTemplate: Ember.computed.alias('content.template'),
    isTemplate1: Ember.computed.equal('currentTemplate', TEMPLATE_1),
    isTemplate2: Ember.computed.equal('currentTemplate', TEMPLATE_2),
    gaEventsStrategy: (0, _emberHelpers.computedApplyFunction)((template, strategies) => strategies[template], 'currentTemplate', 'gaEventsMapByTemplates'),

    init(...args) {
      this._super(...args);

      this.gaEventsMapByTemplates = createEventsMapByTemplates({
        addLabelPostfix: (...params) => this.addAdditionalCsrPostfix(...params),
        sendEventTag: this.sendEventTag
      });
    },

    didInsertElement() {
      this.get('gaEventsStrategy').display();
    },

    /**
     * Adds `CSR` postfix to value if a user is an agent
     *
     * @function
     * @param {String} value
     * @returns {String}
     */
    addAdditionalCsrPostfix(value) {
      if (this.get('isCsr')) {
        return value.concat(' (CSR)');
      }

      return value;
    },

    actions: {
      /**
       * Click handler for the `close` button
       * Calls '/api/v2/auth/promotion/incrementDispositionCounter/:promotionType' endpoint
       * Sends GA event
       *
       * @function
       */
      onCloseHandler() {
        const promotionType = this.get('promotion.promotionType');
        this.onClose({
          promotionType,
          requireRequest: true
        });
        this.get('gaEventsStrategy').close();
      },

      /**
       * Click handler for the `Remind me Later` button
       * Sends GA event
       *
       * @function
       */
      remindMeLaterClickHandler() {
        this.onClose({
          requireRequest: false
        });
        this.sendEventTag({
          subEventTypeId: _promotionData.eventTagValues.CLICKED_REMIND_ME_LATER
        });
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Contract Renewal 2', 'Remind Me Later pushed']);
      },

      /**
       * Click handler for the `Learn More` button
       * Sends GA event and moves to documents page
       *
       * @function
       */
      learnMoreClickHandler() {
        this.sendEventTag({
          subEventTypeId: _promotionData.eventTagValues.CLICKED_LEARN_MORE
        });
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Contract Renewal 2', 'Learn More pushed']);
        this.router.transitionTo('dashboard.account.documents');
      }

    }
  });

  _exports.default = _default;
});