define("adt-wss/pods/dashboard/alarm/system/index/route", ["exports", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/route-helpers"], function (_exports, _appDispatcher, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    hashSettled: Ember.RSVP.hashSettled,
    makeDeliverData: _routeHelpers.makeDeliverData,
    useResolvedOnly: _routeHelpers.useResolvedOnly,
    systemInformation: Ember.inject.service(),

    activate() {
      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    deactivate() {
      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      this.isRefresh = true;
      this.refresh();
    },

    model() {
      const deliverDataOptions = {
        forceRequest: this.isRefresh
      };
      const systemInformationDeliverData = this.makeDeliverData(this, 'systemInformation');
      const hasPulse = systemInformationDeliverData('systemInfoList', systemInformation => systemInformation.getSystemInfoList(), deliverDataOptions).then(list => (list || []).some(s => s.pulse));
      return this.hashSettled({
        hasPulse
      }).then(response => {
        this.isRefresh = false;
        return this.useResolvedOnly(response);
      }).catch(error => console.error(error));
    }

  });

  _exports.default = _default;
});