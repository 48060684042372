define("adt-wss/pods/dashboard/shop/productsservices/contactrepairkit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.detectPermissions = void 0;

  /**
   * Checks permissions of the user for `contactrepairkit` route
   * Method is exported due to usage for the showing links in the `side-bar` component and `productsservices`controller
   *
   * @function
   * @param {Boolean} adtGoOrCyberSecurityUser
   * @returns {Promise}
   */
  const detectPermissions = adtGoOrCyberSecurityUser => !adtGoOrCyberSecurityUser;

  _exports.detectPermissions = detectPermissions;

  var _default = Ember.Route.extend({
    detectPermissions,
    currentLocation: Ember.inject.service(),
    parentRoute: 'dashboard.shop',

    beforeModel() {
      if (!this.detectPermissions(this.currentLocation.adtGoOrCyberSecurity)) {
        this.transitionTo(this.parentRoute);
      }
    }

  });

  _exports.default = _default;
});