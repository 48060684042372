define("adt-wss/pods/components/ps/myadt-reset-pic/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss/mixins/on-form-esc-handler", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/route-helpers", "adt-wss-common/utils/fp", "adt-wss-common/classes/validator", "adt-wss-common/utils/myadt-helpers", "adt-wss/services/app-dispatcher", "adt-wss/pods/components/ps/myadt-reset-pic/constants"], function (_exports, _withFsm, _onFormEscHandler, _emberHelpers, _routeHelpers, _fp, _validator, _myadtHelpers, _appDispatcher, constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COMMON_VALIDATION_ERROR = _exports.PASSWORD_INVALID_MESSAGE = _exports.FAILURE_PASSWORDS_ACTION = _exports.SUCCESS_PASSWORDS_ACTION = _exports.SUCCESS_ACTION = _exports.FAILURE_ACTION = _exports.UPDATE_DATA_ACTION = _exports.LOAD_PASSWORDS_ACTION = _exports.LOADING_STATE = _exports.UPDATING_STATE = _exports.IDLE_STATE = _exports.defaultProperties = void 0;
  const validator = new _validator.default({
    password: {
      rules: ['isNotEmpty', 'minLength?3', 'maxLength?10', 'noSpecialChar']
    }
  });
  const defaultProperties = {
    passwords: [],
    alert: v => v,
    setOverlap: () => {},
    submitEdit: () => {
      throw new Error('Please pass submitEdit method to myadt-reset-pic component');
    }
  };
  _exports.defaultProperties = defaultProperties;
  const {
    IDLE_STATE,
    UPDATING_STATE,
    LOADING_STATE,
    LOAD_PASSWORDS_ACTION,
    UPDATE_DATA_ACTION,
    FAILURE_ACTION,
    SUCCESS_ACTION,
    SUCCESS_PASSWORDS_ACTION,
    FAILURE_PASSWORDS_ACTION,
    PASSWORD_INVALID_MESSAGE,
    COMMON_VALIDATION_ERROR
  } = constants;
  _exports.COMMON_VALIDATION_ERROR = COMMON_VALIDATION_ERROR;
  _exports.PASSWORD_INVALID_MESSAGE = PASSWORD_INVALID_MESSAGE;
  _exports.FAILURE_PASSWORDS_ACTION = FAILURE_PASSWORDS_ACTION;
  _exports.SUCCESS_PASSWORDS_ACTION = SUCCESS_PASSWORDS_ACTION;
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
  _exports.FAILURE_ACTION = FAILURE_ACTION;
  _exports.UPDATE_DATA_ACTION = UPDATE_DATA_ACTION;
  _exports.LOAD_PASSWORDS_ACTION = LOAD_PASSWORDS_ACTION;
  _exports.LOADING_STATE = LOADING_STATE;
  _exports.UPDATING_STATE = UPDATING_STATE;
  _exports.IDLE_STATE = IDLE_STATE;
  const fsmModel = {
    scrollToTop: false,
    transitions: {
      [IDLE_STATE]: {
        [LOAD_PASSWORDS_ACTION]: LOADING_STATE,
        [UPDATE_DATA_ACTION]: UPDATING_STATE
      },
      [LOADING_STATE]: {
        [SUCCESS_PASSWORDS_ACTION]: IDLE_STATE,
        [FAILURE_PASSWORDS_ACTION]: IDLE_STATE
      },
      [UPDATING_STATE]: {
        [FAILURE_ACTION]: IDLE_STATE,
        [SUCCESS_ACTION]: IDLE_STATE
      }
    }
  };
  /**
   * @module Components/MyADTResetPic
   * @prop {Array} password - User's existing passwords
   * @prop {Object} data - data from API copy to it for edit mode to avoid two-ways binding
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProperties, _onFormEscHandler.default, {
    classNames: ['account-widget-section'],
    classNameBindings: ['editMode:active'],
    mwd: Ember.inject.service('modal-window-dispatcher'),
    commonPasswords: Ember.inject.service(),
    validator,
    fsmModel,
    currentState: IDLE_STATE,
    initAction: LOAD_PASSWORDS_ACTION,
    IS_IDLE_STATE: Ember.computed.equal('currentState', IDLE_STATE),
    IS_UPDATING: (0, _emberHelpers.computedSome)('currentState', UPDATING_STATE, LOADING_STATE),
    errors: null,
    messages: null,
    verbalSecurityPasswordError: false,
    data: null,
    passwords: Ember.computed.oneWay('commonPasswords.allPasswords'),
    actions: {
      dispatch(action, data) {
        this.get('fsm').dispatch(action, data);
      },

      disableFields(passwordItem) {
        this.disableInactiveFields(passwordItem);
      }

    },

    disableInactiveFields(passwordItem) {
      const data = this.get('data');
      data.forEach(prop => {
        Ember.set(prop, 'inputDisabled', true);
      });
      Ember.set(passwordItem, 'inputDisabled', false);
    },

    [SUCCESS_ACTION]({
      data
    }) {
      const detail = {
        'picUpdate': 'PIC Updated successfully'
      };
      window.top.postMessage(detail, '*');
      this.redirectPage();
    },

    [FAILURE_ACTION](response) {
      if (!response || !response.errors) return true;
      (0, _myadtHelpers.genericErrorHandler)(response).map(error => this.set('messages', error));
      const detail = {
        'picFail': 'An unexpected error has occurred and we are unable to complete your request at this time.'
      };
      window.top.postMessage(detail, '*');
      this.redirectPage();
      this.disableInactiveFields();
    },

    redirectPage() {
      window.setTimeout(function () {
        if (/www.myadt|\/\/myadt.com/i.test(document.location.href)) {
          window.location.replace('https://webchat.pypestream.com/redirect.html');
        } else {
          window.location.replace('https://webchat-sandbox.pypestream.com/redirect.html');
        }
      }, 1000);
    },

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
      this.get('validator').onValidate(({
        messages: errors,
        values
      }) => {
        this.set('errors', errors);

        if (values.length) {
          this.set('messages', COMMON_VALIDATION_ERROR);
        }
      });
      this.keyDown = this.onEscHandler.bind(this);
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      this.get('fsm').dispatch(LOAD_PASSWORDS_ACTION, true);
    },

    [SUCCESS_PASSWORDS_ACTION]() {
      this.resetAlert();
      this.prepareEdit();
    },

    [FAILURE_PASSWORDS_ACTION](error) {
      console.error(error);
    },

    [LOAD_PASSWORDS_ACTION](forceRequest = false) {
      const commonPasswords = this.deliverData('commonPasswords.allPasswords', passwordsData => passwordsData.getData(), {
        forceRequest
      });
      return commonPasswords.then((0, _routeHelpers.fsmDispatch)(SUCCESS_PASSWORDS_ACTION)(this)).catch((0, _routeHelpers.fsmDispatch)(FAILURE_PASSWORDS_ACTION)(this));
    },

    [UPDATE_DATA_ACTION](data) {
      this.resetAlert();
      const {
        validator: v,
        fsm
      } = this.getProperties('validator', 'fsm');
      const validationFailed = v.validate(data);

      if (!validationFailed) {
        return this.get('commonPasswords').updatePassword(data).then(() => fsm.dispatch(SUCCESS_ACTION, {
          data
        })).catch(errors => fsm.dispatch(FAILURE_ACTION, {
          errors,
          data
        }));
      }

      return fsm.dispatch(FAILURE_ACTION);
    },

    resetAlert() {
      this.set('errors', null);
      this.set('messages', null);
      this.alert(null);
    },

    prepareEdit() {
      const passwords = this.get('passwords');
      const props = (passwords || []).map(value => Ember.Object.create((0, _fp.omix)(value, {
        existingPassword: value.password
      })));
      this.set('data', props);
    }

  });

  _exports.default = _default;
});