define("adt-wss/pods/dashboard/account/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentLocation: Ember.inject.service(),

    redirect() {
      if (this.get('currentLocation.permissions.billing')) {
        this.transitionTo('dashboard.account.payments');
      } else {
        this.transitionTo('dashboard.account.profile');
      }
    }

  });

  _exports.default = _default;
});