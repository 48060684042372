define("adt-wss/pods/components/dashboard/emergency-contacts/component", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss-common/mixins/with-fsm"], function (_exports, _emberHelpers, _withFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.processContacts = void 0;
  const defaultProps = {
    elementId: 'emergencyContacts',
    icon: 'dashboard',
    contacts: [],
    listLimit: 3,
    primaryPhone: '',
    style: ''
  };
  /**
   * Transform contacts to "flat" view and cut list by a limit
   * @param {Object[]} contacts
   * @param {Number} limit
   *
   * @returns {Object}
   */

  const processContacts = (contacts, limit) => contacts.reduce((phoneNumbers, current) => {
    /**
     * Step 1:
     * Input:
     * [{ firstName: 'foo', lastName: 'bar', phoneNumbers: [{ number: '123' }] }]
     * Output:
     * [{ name: 'foobar', number: '123' }]
     */
    const {
      phoneNumbers: numbers,
      firstName,
      lastName
    } = current;
    const currentNumbers = numbers.map(i => ({
      number: i.number,
      name: "".concat(firstName, " ").concat(lastName).toLowerCase()
    }));
    return phoneNumbers.concat(currentNumbers);
  }, [])
  /**
   * Step 2: Slice array
   */
  .slice(0, limit);
  /**
   * @module Components/Dashboard/EmergencyContacts
   * @prop {String} elementId - ID attribute for div component wrapper
   * @prop {String} icon - Icon of widget
   * @prop {Array} contacts - List of contacts
   * @prop {String} primaryPhone - Primary phone number
   * @prop {String} style - HTML Atribute
   * @prop {Number} listLimit - Value of limit
   * @example
   * {@lang xml} {{ dashboard/emergency-contacts
  		contacts=[{ firstName: 'John', lastName: 'Doe', phoneNumbers: ['804-829-1423'] }]
  		primaryPhone='924-284-2349'
  		icon='dashboard'
  		listLimit=3
  		elementId: 'safetyTips'
  		style='"width: 600px"'
  }}
   */


  _exports.processContacts = processContacts;

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    tagName: 'section',
    classNames: ['emergency-contacts', 'widget-section'],
    classNameBindings: ['hidden'],
    hidden: (0, _emberHelpers.computedApplyFunction)(({
      paidDataServicesAdmin,
      paidDataServices
    }) => {
      const permissions = [paidDataServicesAdmin, paidDataServices];
      return !(permissions.every(Boolean) || !permissions.some(Boolean));
    }, 'currentLocation.permissions'),
    currentLocation: Ember.inject.service(),
    myadtSecurity: Ember.inject.service(),
    showManageLink: (0, _emberHelpers.computedApplyFunction)((picProvided, adtGoOnly) => picProvided && !adtGoOnly, 'myadtSecurity.picProvided', 'currentLocation.adtGoOnly'),
    list: Ember.computed('contacts', 'listLimit', function () {
      const {
        contacts,
        listLimit
      } = this.getProperties(['contacts', 'listLimit']);
      return processContacts(contacts || [], listLimit);
    })
  });

  _exports.default = _default;
});