define("adt-wss/pods/dashboard/shop/productsservices/controller", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss/pods/dashboard/shop/productsservices/contactrepairkit/route"], function (_exports, _emberHelpers, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CONTACT_REPAIR_KIT_ROUTE = 'dashboard.shop.productsservices.contactrepairkit';
  const links = [{
    route: 'dashboard.shop.productsservices.products',
    text: 'New',
    determineVisibility: ({
      newSectionVisible
    }) => newSectionVisible
  }, {
    route: 'dashboard.shop.productsservices.products',
    text: 'Products',
    determineVisibility: ({
      newSectionVisible
    }) => !newSectionVisible
  }, {
    route: 'dashboard.shop.productsservices.battery',
    text: 'Batteries',
    determineVisibility: () => true
  }, {
    route: 'dashboard.shop.productsservices.decals',
    text: 'Yard Signs/Decals',
    determineVisibility: () => true
  }, {
    route: CONTACT_REPAIR_KIT_ROUTE,
    text: 'Contact Repair Kit',
    determineVisibility: ({
      adtGoOrCyberSecurity
    }) => (0, _route.detectPermissions)(adtGoOrCyberSecurity)
  }];

  var _default = Ember.Controller.extend({
    links,
    orderData: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    contactRepairKitVisible: (0, _emberHelpers.computedApplyFunction)(list => list.some(l => l.route === CONTACT_REPAIR_KIT_ROUTE), 'visibleLinks'),
    visibleLinks: (0, _emberHelpers.computedApplyFunction)((newSectionVisible, adtGoOrCyberSecurity) => links.filter(l => l.determineVisibility({
      newSectionVisible,
      adtGoOrCyberSecurity
    })), 'orderData.newSectionVisible', 'currentLocation.adtGoOrCyberSecurity')
  });

  _exports.default = _default;
});