define("adt-wss/pods/components/dashboard/shop/yard-signs-decals/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FAILURE_POST_ACTION = _exports.SUCCESS_POST_ACTION = _exports.POST_YARD_SIGNS_ACTION = _exports.POST_DECALS_ACTION = _exports.SUCCESS_ACTION = _exports.LOAD_ORDER_INFO_ACTION = _exports.LOADING_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const LOADING_STATE = 'LOADING_STATE';
  _exports.LOADING_STATE = LOADING_STATE;
  const LOAD_ORDER_INFO_ACTION = 'LOAD_ORDER_INFO_ACTION';
  _exports.LOAD_ORDER_INFO_ACTION = LOAD_ORDER_INFO_ACTION;
  const SUCCESS_ACTION = 'SUCCESS_ACTION';
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
  const POST_DECALS_ACTION = 'POST_DECALS_ACTION';
  _exports.POST_DECALS_ACTION = POST_DECALS_ACTION;
  const POST_YARD_SIGNS_ACTION = 'POST_YARD_SIGNS_ACTION';
  _exports.POST_YARD_SIGNS_ACTION = POST_YARD_SIGNS_ACTION;
  const SUCCESS_POST_ACTION = 'SUCCESS_POST_ACTION';
  _exports.SUCCESS_POST_ACTION = SUCCESS_POST_ACTION;
  const FAILURE_POST_ACTION = 'FAILURE_POST_ACTION';
  _exports.FAILURE_POST_ACTION = FAILURE_POST_ACTION;
});