define("adt-wss/pods/components/stupid/input-counter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    value: 0,
    step: 1,
    min: 0,
    max: Infinity,
    onChange: v => v
  };
  /**
   * @module Components/Stupid/InputCounter
   * @prop {Number} value
   * @prop {Number} step
   * @prop {Number} min
   * @prop {Number} max
   * @prop {Function} onChange
   * @example
   * {@lang xml} {{ stupid/input-counter
  		value=value
  		step=1
  		min=0
  		max=5
  		onChange=(action 'onChange')
  }}
   */

  var _default = Ember.Component.extend(defaultProps, {
    classNames: ['stupid-widget-input-counter'],
    INCREMENT_TYPE: 'incrementProperty',
    DECREMENT_TYPE: 'decrementProperty',
    actions: {
      clickHandler(operation) {
        const {
          INCREMENT_TYPE: INC,
          DECREMENT_TYPE: DEC,
          min,
          max,
          step,
          value
        } = this;

        if (operation === INC && value >= max || operation === DEC && value <= min) {
          return false;
        }

        return this.onChange(this[operation]('value', step));
      }

    }
  });

  _exports.default = _default;
});