define("adt-wss/services/app-logger", ["exports", "adt-wss/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.logToMyadtInspector = _exports.getEntities = _exports.checkDebug = void 0;

  /**
   * @memberof Services/AppLogger
   */

  /**
   * return true if debug mode enabled
   * @return {Boolean}
   */
  const checkDebug = () => _environment.default.environment != 'production';
  /**
   * @memberof Services/AppLogger
   * Run each function from array
   * @param {Array}  arr - Array of functions
   */


  _exports.checkDebug = checkDebug;

  const call = arr => (arr || []).map(f => f());
  /**
   * returns log time in format `HH:MM:SS::MS`
   * @return {String} - 15:26:46::136
   */


  const getTime = () => {
    const now = new Date();
    return [now.getFixedHour(), ':', now.getFixedMinute(), ':', now.getSeconds(), '::', now.getMilliseconds()].join('');
  };

  const getEntities = () => {
    const entitiesList = Object.keys(window.Ember.Application.byName('adt-wss').__container__ ? window.Ember.Application.byName('adt-wss').__container__.cache : []).filter(key => /service|controller|route/.test(key));
    window.postMessage({
      from: 'myadt-app',
      type: 'entitiesList',
      payload: entitiesList
    }, '*');
  };
  /**
   * @memberof Services/AppLogger
   * @deprecated
   * @param {Object} data
   */


  _exports.getEntities = getEntities;

  const logToMyadtInspector = data => {
    if (!window.myadtlogs) {
      window.myadtlogs = [];
    }

    try {
      JSON.stringify({
        from: 'myadt-app',
        payload: window.myadtlogs
      });
      window.myadtlogs.push(data);
      window.postMessage({
        from: 'myadt-app',
        type: 'logs',
        payload: window.myadtlogs
      }, '*');
      window.postMessage({
        from: 'myadt-app',
        type: 'components',
        payload: window.myadtcomponents || []
      }, '*');
    } catch (e) {
      return console.error('Uh oh, an error occurred when trying to serialize your apollo data:', e); // ideally we could make this message show up to the user so they know what is going wrong when serializing and can fix that error?
    }
  };
  /**
   * @memberof Services/AppLogger
   * Log different messages with color
   * @param  {String} type - message type
   * @param  {Object} data - detaild for message
   */


  _exports.logToMyadtInspector = logToMyadtInspector;

  const colorLog = (type, data = {}) => {
    const {
      action,
      state,
      initiator,
      details,
      path,
      serviceName,
      method
    } = data;
    const time = getTime();
    const green = 'color: green';
    const black = 'color: black';
    const blue = 'color: blue';
    const gray = 'color: #ccc';
    const orangered = 'color: orangered';
    const bold = 'font-weight: bold';
    const [, methodName] = (method || '').toString().match(/function (.*?) \(/) || [];

    switch (type) {
      case 'fsmStart':
        console.log('\n%c==================== FSM ACTION ====================', green);
        console.log("\tFSM Action: %c".concat(action, "%c from state: %c").concat(state, "%c called by %c").concat(initiator, "%c - %c").concat(time), green, black, blue, black, orangered, black, gray);
        console.log('\t', details);
        console.log('%c^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^', green);
        break;

      case 'deliveryFromCache':
        console.log('\n%c==================== FROM CACHE ====================', orangered);
        console.log("\t%cDELIVERY: from \"Cache\" %c\"".concat(initiator, "\"%c got %c\"").concat(path, "\"%c data from %c\"").concat(serviceName, "\"%c service - %c").concat(time), bold, orangered, black, green, black, blue, black, gray);
        console.log('%c^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^', orangered);
        break;

      case 'deliveryFromServer':
        console.log('\n%c==================== SERVER CALL ====================', orangered);
        console.log("\t%cDELIVERY: from \"Server\" %c\"".concat(initiator, "\"%c called method from %c\"").concat(serviceName, "\"%c service - %c").concat(time, ":"), bold, orangered, black, blue, black, gray);
        console.log("\tPath %c\"".concat(path, "\"%c"), green);
        console.log("\t".concat(method));
        console.log('%c^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^', orangered);
        break;

      case 'AppEventSubscribe':
        console.log('\n%c==================== SUBSCRIBE ====================', blue);
        console.log("\t%cApp Event: %c".concat(methodName || 'anonymous', "%c was %csubscribed%c for %c").concat(action, "%c by %c\"").concat(initiator, "\"%c - %c").concat(time, "%c:"), bold, orangered, black, green, black, orangered, black, blue, black, gray);

        if (!methodName) {
          console.log("\t".concat(method));
        }

        console.log('%c^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^', blue);
        break;

      case 'AppEventUnsubscribe':
        console.log('\n%c==================== UNSUBSCRIBE ====================', blue);
        console.log("\t%cApp Event: %c".concat(methodName || 'anonymous', "%c was %cunsubscribed%c %c").concat(action, "%c  by %c\"").concat(initiator, "\"%c - %c").concat(time, "%c:"), bold, orangered, black, green, black, orangered, black, blue, black, gray);

        if (!methodName) {
          console.log("\t".concat(method));
        }

        console.log('%c^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^', blue);
        break;

      case 'AppEventFire':
        console.log('\n%c==================== EVENT ====================', blue);
        console.log("\t%cApp Event: %c".concat(action, "%c was %cfired%c by %c\"").concat(initiator, "\"%c - %c").concat(time, "%c:"), bold, orangered, black, green, black, blue, black, gray);
        console.log('\t', data);
        console.log('%c^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^', blue);
        break;

      default:
        console.log('-');
    }
  };
  /**
   * @memberof Services/AppLogger
   */


  const makeAppLogSubscriberMethod = name => function ({
    action,
    initiator,
    method,
    data
  }) {
    const debug = checkDebug();
    const time = getTime();

    if (debug) {
      const log = () => {
        colorLog(name, {
          action,
          initiator,
          time,
          method,
          data
        });
      };

      call([log]);
      this.addLog('appEventsLogs', log);
    }
  };
  /**
   * @module Services/AppLogger
   */


  var _default = Ember.Service.extend(Ember.Evented, {
    init() {
      this._super(...arguments);

      this.actionsFSMLogs = [];
      this.actionsFSMLogBuffer = [];
      this.deliveryDataLogs = [];
      this.appEventsLogs = [];

      if (checkDebug) {
        window.readFSMLogs = this.get('readFSMLogs').bind(this);
        window.readDeliveryLogs = this.get('readDeliveryLogs').bind(this);
        window.readAppEventLogs = this.get('readAppEventLogs').bind(this);
      }

      window.postMessage({
        from: 'myadt-app',
        type: 'init',
        payload: {}
      }, '*');
      setTimeout(getEntities, 1000);
    },

    appLogSubscriber: () => true,
    //makeAppLogSubscriberMethod('AppEventSubscribe'),
    appLogUnsubscriber: () => true,
    //makeAppLogSubscriberMethod('AppEventUnsubscribe'),
    appLogEvent: makeAppLogSubscriberMethod('AppEventFire'),

    /**
     * Show all log messages of Finiti State Machine in console
     */
    readFSMLogs() {
      call(this.get('actionsFSMLogs') || []);
    },

    /**
     * Show all log messages of Finiti State Machine in console
     */
    readAppEventLogs() {
      call(this.get('appEventsLogs') || []);
    },

    /**
     * Show all log messages of Delivery Transactions in console
     */
    readDeliveryLogs() {
      call(this.get('deliveryDataLogs') || []);
    },

    /**
     * Add log to stack by logger type
     * @param {String} loggerName - FSM action or Delivery
     */
    addLog(loggerName, log) {
      const logger = this.get(loggerName);
      if (!logger) return console.error("Logger ".concat(logger, " is not exist"));
      if (!(log instanceof Function)) return console.error('Log is not a function', log);
      return logger.push(log);
    },

    /**
     * Makes grouped log for FSM actions log
     * @param  {String} options.action - fired action
     * @param  {String} options.state
     * @param  {Object} options.transitions - available transactions for current state
     * @param  {*} options.data
     * @param  {String} options.initiator -  component name or other service name
     */
    logFSM({
      action,
      state,
      transitions,
      data,
      initiator,
      callStack
    }) {
      const debug = checkDebug();
      const time = getTime();
      const payload = {
        type: 'fsm',
        action,
        state,
        initiator,
        time,
        details: {
          transitions,
          data,
          callStack
        }
      };

      if (debug) {
        const log = () => {
          colorLog('fsmStart', payload);
        };

        call([log]);
        this.addLog('actionsFSMLogBuffer', log);
      }
    },

    /**
     * Save and show all logs related to data transactions in Application
     * @param  {String} options.initiator   component name or other service name
     * @param  {String} options.path        path of requested data
     * @param  {String} options.serviceName name if used service
     * @param  {String} options.method      body of method called in case if service does not have needed data
     */
    logDeliveryData({
      initiator,
      path,
      serviceName,
      method
    }) {
      const debug = checkDebug();
      const time = getTime();

      if (debug) {
        const data = {
          initiator,
          path,
          serviceName,
          method
        };

        const log = () => method === 'undefined' ? colorLog('deliveryFromServer', data) : colorLog('deliveryFromCache', data);

        call([log]);
        this.addLog('deliveryDataLogs', log);
      }
    }

  });

  _exports.default = _default;
});