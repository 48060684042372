define("adt-wss/pods/index/resetpassword/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    model(params) {
      let uuid = params.uuid;

      if (/[&#?]/.test(params.uuid)) {
        uuid = params.uuid.split(/[&#?]/)[0];
      }

      uuid = /^[-0-9a-z]+$/i.test(uuid) ? uuid : null;
      return uuid;
    },

    actions: {
      invalidate() {
        if (this.get('session.isAuthenticated')) {
          this.get('session').invalidate();
        }
      }

    }
  });

  _exports.default = _default;
});