define("adt-wss/pods/components/dashboard/shop/order-summary/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers"], function (_exports, _withFsm, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.calcShippingCost = void 0;

  const calcShippingCost = (selectedShipping, standardShippingCost, expeditedShippingCost) => {
    if (selectedShipping === 'standard') {
      if (standardShippingCost !== '$0.00') {
        return standardShippingCost;
      }

      return null;
    }

    if (selectedShipping === 'expedited') {
      return expeditedShippingCost;
    }

    return null;
  };

  _exports.calcShippingCost = calcShippingCost;

  var _default = Ember.Component.extend(_withFsm.default, {
    shippingCost: (0, _emberHelpers.computedApplyFunction)(calcShippingCost, 'selectedShipping', 'standardShippingCost', 'expeditedShippingCost')
  });

  _exports.default = _default;
});