define("adt-wss/templates/modal-windows/delete-verbal-security-password-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tduiXtrE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"verbal-security-pass-body\"],[8],[1,[24,[\"body\",\"name\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/delete-verbal-security-password-confirm.hbs"
    }
  });

  _exports.default = _default;
});