define("adt-wss/services/myadt-security", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss/services/app-dispatcher"], function (_exports, _myadtHelpers, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const clearCacheArgs = {
    picProvided: false,
    picMessage: ''
  };
  /**
   * Verbal security password service
   * @module Services/MyADTSecurity
   * @prop {Boolean} picProvided
   */

  var _default = Ember.Service.extend(Ember.Evented, (0, _myadtHelpers.clearCache)(clearCacheArgs), {
    apiService: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    session: Ember.inject.service(),
    picProvided: false,
    picMessage: '',

    /**
     * Sets `picProvided` to false and initiates a PIC challenge
     * @return {Promise}
     */
    start() {
      this.set('picProvided', false);
      return this.challengePic();
    },

    /**
     * Sets `picProvided` to true
     * @emits picValidated
     */
    onValidPicProvided(data) {
      this.set('picProvided', true);
      this.get('appDispatcher').sendEvent(_appDispatcher.PIC_VALIDATED_EVENT, data, this);
      return data;
    },

    /** Sets `picProvided` to false */
    showPicChallenge(data) {
      this.set('picProvided', false);
      return data;
    },

    /**
     * PIC Challenge
     * Submits empty PIC to server; if user PIC has changed
     * since last login, it will fail;
     * Initiating component should show PIC challenge form
     * @instance
     *
     * @return {Promise}
     */
    challengePic() {
      if (this.get('picProvided')) {
        this.onValidPicProvided();
        return Ember.RSVP.Promise.resolve();
      }

      return this.submitPic('', true);
    },

    /**
     * Submits passed password as PIC challenge
     * @instance
     * @param {String} password
     *
     * @return {Promise}
     */
    submit(password) {
      return this.submitPic(password, false);
    },

    /**
     * PIC submission
     * Service to submit PIC to server. Resolves automatically if
     * {@link module:Services/AuthInfo Services/AuthInfo.authInfo.csrUser} is true
     * @type {ServiceCall}
     * @param {String} [password] if not passed, challenges user's last submitted PIC
     * @param {*} sessionCheck ???
     *
     * @emits picSubmitted on resolve
     * @return {ApiResponse}
     */
    submitPic(password, sessionCheck) {
      if (!this.get('authInfo.authInfo.csrUser')) {
        return this.get('apiService').myADT.submitPicChallenge({
          sessionCheck,
          password
        }).then(({
          data,
          message
        }) => {
          this.get('appDispatcher').sendEvent(_appDispatcher.PIC_SUBMITTED_EVENT, data, this);

          if (data.validPic && !data.message) {
            this.onValidPicProvided();
          } else {
            this.get('appDispatcher').sendEvent(_appDispatcher.PIC_INVALIDATED_EVENT, data, this);
            this.showPicChallenge();
          }

          this.set('picMessage', message);
          return data;
        });
      }

      this.get('appDispatcher').sendEvent(_appDispatcher.PIC_SUBMITTED_EVENT, null, this);
      this.onValidPicProvided();
      return Ember.RSVP.Promise.resolve({
        validPic: true,
        message: null
      });
    },

    init() {
      this._super(...arguments);

      this.get('session').on('invalidationSucceeded', () => this.showPicChallenge());
      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'start');
    }

  });

  _exports.default = _default;
});