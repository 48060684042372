define("adt-wss/templates/modal-windows/enroll-paperless-statements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jahTUdiG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"paperless-statements-modal-wrap\"],[8],[0,\"\\n\\t\"],[7,\"h5\",true],[10,\"class\",\"paperless-statements-modal-title\"],[8],[0,\"Enroll Account #\"],[1,[24,[\"body\",\"accountId\"]],false],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"All statements for account \"],[1,[24,[\"body\",\"accountId\"]],false],[0,\" will be emailed to \"],[1,[24,[\"body\",\"emailsList\"]],false],[0,\" and can be viewed on MyADT.com\"],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"You can update this selection at anytime. Your email can be updated under Preferences.\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/enroll-paperless-statements.hbs"
    }
  });

  _exports.default = _default;
});