define("adt-wss/services/user-profile", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/fp"], function (_exports, _myadtHelpers, _fp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const generateCommentForUpdatePremiumProfileInfo = function (data, action, uiCopy) {
    switch (action) {
      case 'UPDATE_TITLE':
        if (data.title !== uiCopy.title) {
          if (uiCopy.title && data.title) return 'Updated MyADT user\'s title';
          if (uiCopy.title && !data.title) return 'Deleted MyADT user\'s title';
          if (!uiCopy.title && data.title) return 'Added MyADT user\'s title';
        }

        break;

      case 'UPDATE_DEPT':
        if (data.department !== uiCopy.department) {
          if (uiCopy.department && data.department) return 'Updated MyADT user\'s department';
          if (uiCopy.department && !data.department) return 'Deleted MyADT user\'s department';
          if (!uiCopy.department && data.department) return 'Added MyADT user\'s department';
        }

        break;

      case 'UPDATE_PRIMARY_PHONE':
        if (data.primaryPhone !== uiCopy.primaryPhone) {
          if (uiCopy.primaryPhone && data.primaryPhone) return 'Updated MyADT user\'s primary phone number';
          if (uiCopy.primaryPhone && !data.primaryPhone) return 'Deleted MyADT user\'s primary phone number';
          if (!uiCopy.primaryPhone && data.primaryPhone) return 'Added MyADT user\'s primary phone number';
        }

        break;

      case 'UPDATE_SECONDARY_PHONE':
        if (data.secondaryPhone !== uiCopy.secondaryPhone) {
          if (uiCopy.secondaryPhone && data.secondaryPhone) return 'Updated MyADT user\'s secondary phone number';
          if (uiCopy.secondaryPhone && !data.secondaryPhone) return 'Deleted MyADT user\'s secondary phone number';
          if (!uiCopy.secondaryPhone && data.secondaryPhone) return 'Added MyADT user\'s secondary phone number';
        }

        break;

      default:
        return '';
    }
  };

  const clearCacheArgs = {
    userProfileData: null,
    webUsersProfileData: null,
    startTime: null
  };
  /**
   * API service and data store for User Profile Info
   * @module Services/UserProfile
   * @prop {Object} userProfileData
   * @prop {Object} webUsersProfileData
   */

  var _default = Ember.Service.extend(Ember.Evented, (0, _myadtHelpers.clearCache)(clearCacheArgs), {
    apiService: Ember.inject.service(),
    mmbIncidents: Ember.inject.service(),
    impersonationData: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    userProfileData: null,
    webUsersProfileData: null,
    startTime: null,
    generateComment: generateCommentForUpdatePremiumProfileInfo,
    custNo: Ember.computed.oneWay('impersonationData.headerInfo.customer.custNo'),
    siteNo: Ember.computed.oneWay('impersonationData.headerInfo.customer.siteNo'),

    /**
     * @instance
     * @type {ServiceCall}
     * @param {Object} data
     * @param {String} data.name new username
     * @return {ApiResponse}
     */
    updateUserName(data) {
      return this.get('apiService').myADT.updateUserName(data).then(() => _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'My Web Profile', 'Change Username'])).finally(() => {
        const {
          custNo,
          siteNo
        } = this.getProperties('custNo', 'siteNo');
        this.get('mmbIncidents').createIncidentOrIssue({
          customerNo: custNo || 0,
          siteNo: siteNo || 0,
          duration: Date.now() - this.get('startTime'),
          transactionType: 'MAPU',
          jobComments: 'Update MyADT registered username'
        }, true);
      });
    },

    /**
     * @instance
     * @type {ServiceCall}
     * @param {Object} data
     * @param {String} data.firstName new user first name
     * @param {String} data.lastName new user last name
     * @return {ApiResponse}
     */
    updateName(data) {
      return this.get('apiService').myADT.updateName(data).then(() => {
        const eventTagInfo = {
          eventTypeId: 'ECUSTPRO',
          subEventTypeId: 'CHNGNAME'
        };
        this.get('ET').sendEventTag(eventTagInfo);

        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'My Web Profile', 'Change Name']);

        const {
          custNo,
          siteNo
        } = this.getProperties('custNo', 'siteNo');
        const {
          firstName,
          lastName
        } = data;
        const {
          firstName: ofn,
          lastName: oln
        } = this.getProperties('firstName', 'lastName');
        let comments = 'Updated MyADT registered user ';

        if (firstName !== ofn) {
          if (lastName !== oln) {
            comments += 'first name and last name';
          } else {
            comments += 'first name';
          }
        } else if (lastName !== oln) {
          comments += 'last name';
        }

        this.get('mmbIncidents').createIncidentOrIssue({
          customerNo: custNo || 0,
          siteNo: siteNo || 0,
          duration: Date.now() - this.get('startTime'),
          transactionType: 'MAPU',
          jobComments: comments
        }, true);
      });
    },

    /**
     * @instance
     * @type {ServiceCall}
     * @param {Object} data
     * @param {String} data.email
     * @param {String} data.confirmEmail must match `data.email`
     * @param {String} data.currentPassword
     * @return {ApiResponse}
     */
    updateEmail(data) {
      return this.get('apiService').myADT.updateEmail(data).then(() => {
        const eventTagInfo = {
          eventTypeId: 'ECUSTPRO',
          subEventTypeId: 'CHGEMAIL'
        };
        this.get('ET').sendEventTag(eventTagInfo);

        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'My Web Profile', 'Change Email']);

        const {
          custNo,
          siteNo
        } = this.getProperties('custNo', 'siteNo');
        this.get('mmbIncidents').createIncidentOrIssue({
          customerNo: custNo || 0,
          siteNo: siteNo || 0,
          duration: Date.now() - this.get('startTime'),
          transactionType: 'MAPU',
          jobComments: 'Updated MyADT login email'
        }, true);
      });
    },

    /**
     * @instance
     * @type {ServiceCall}
     * @param {Object} data
     * @param {String} data.password
     * @param {String} data.confirmPassword must match `data.password`
     * @param {String} data.currentPassword not required in impersonation
     * @return {ApiResponse}
     */
    updatePassword(data) {
      if (this.get('impersonationData.isCsr')) delete data.currentPassword;
      return this.get('apiService').myADT.updatePassword(data).then(() => {
        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'My Web Profile', 'Change Web Password']);

        const {
          custNo,
          siteNo
        } = this.getProperties('custNo', 'siteNo');
        this.get('mmbIncidents').createIncidentOrIssue({
          customerNo: custNo || 0,
          siteNo: siteNo || 0,
          duration: Date.now() - this.get('startTime'),
          transactionType: 'MAPU',
          jobComments: 'Updated MyADT password'
        }, true);
      });
    },

    /**
     * @instance
     * @type {ServiceCall}
     * @param {data}
     * @return {Object}
     */
    transformUpdateQuestionAnswerRequestData(data) {
      if (data.questions && data.answers) {
        return (0, _fp.convertArrayToNumericProps)((0, _fp.convertArrayToNumericProps)({}, 'answer', data.answers), 'question', data.questions);
      }
    },

    /**
     * @instance
     * @type {ServiceCall}
     * @param {Object} data
     * @param {String} data.question1
     * @param {String} data.answer1
     * @return {ApiResponse}
     */
    updateQuestionAnswer(data) {
      const payload = this.transformUpdateQuestionAnswerRequestData(data);
      return this.get('apiService').myADT.updateQuestionAnswer(payload).then(() => {
        const eventTagInfo = {
          eventTypeId: 'ECUSTPRO',
          subEventTypeId: 'CHGSECQN'
        };
        this.get('ET').sendEventTag(eventTagInfo);

        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'My Web Profile', 'Change Security Question']);

        return this.getProfileData();
      }).finally(() => {
        const {
          custNo,
          siteNo
        } = this.getProperties('custNo', 'siteNo');
        this.get('mmbIncidents').createIncidentOrIssue({
          customerNo: custNo || 0,
          siteNo: siteNo || 0,
          duration: Date.now() - this.get('startTime'),
          transactionType: 'MAPU',
          jobComments: 'Updated MyADT security question/answer'
        }, true);
      });
    },

    /**
     * @instance
     * @type {ServiceCall}
     * @param {Object} data
     * @param {String} data.title
     * @param {String} data.department
     * @param {String} data.primaryPhone
     * @param {String} data.secondaryPhone
     * @return {ApiResponse}
     */
    updatePremiumProfileInfo(data, action) {
      return this.get('apiService').myADT.updatePremiumProfileInfo(data).then(() => {
        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'My Web Profile', 'Updated Small Business Profile Information']);

        return this.getProfileData();
      }).finally(() => {
        const {
          custNo,
          siteNo
        } = this.getProperties('custNo', 'siteNo');
        this.get('mmbIncidents').createIncidentOrIssue({
          customerNo: custNo || 0,
          siteNo: siteNo || 0,
          duration: Date.now() - this.get('startTime'),
          transactionType: 'MAPU',
          jobComments: this.generateComment(data, action, this.get('webUsersProfileData'))
        }, true);
      });
    },

    /**
     * @instance
     * @return {hash}
     */
    getProfiles() {
      return Ember.RSVP.hash({
        profileData: this.getProfileData(),
        webUsersProfileData: this.getWebUsersProfileData()
      });
    },

    /**
     * @instance
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getProfileData() {
      this.set('userProfileData', null);
      return this.get('apiService').myADT.getUserProfileData().then(({
        data
      }) => this.set('userProfileData', (0, _fp.omix)(data, {
        questions: [data.question1, data.question2].filter(Boolean),
        answers: [data.answer1, data.answer2].filter(Boolean)
      })));
    },

    /**
     * @instance
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getWebUsersProfileData() {
      this.set('webUsersProfileData', null);
      return this.get('apiService').myADT.getWebUsersProfileData().then(({
        data
      }) => this.set('webUsersProfileData', data));
    }

  });

  _exports.default = _default;
});