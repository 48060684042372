define("adt-wss/pods/components/dashboard/account/contacts/contact-dispatch/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ERROR_STATUS = _exports.SUCCESS_STATUS = _exports.SUCCESSFUL_ADD_MESSAGE = _exports.SUCCESSFUL_DELETE_MESSAGE = _exports.SUCCESSFUL_SAVE_MESSAGE = void 0;
  const SUCCESSFUL_SAVE_MESSAGE = 'Emergency Contact Saved';
  _exports.SUCCESSFUL_SAVE_MESSAGE = SUCCESSFUL_SAVE_MESSAGE;
  const SUCCESSFUL_DELETE_MESSAGE = 'Emergency Contact Deleted';
  _exports.SUCCESSFUL_DELETE_MESSAGE = SUCCESSFUL_DELETE_MESSAGE;
  const SUCCESSFUL_ADD_MESSAGE = 'Emergency Contact Added';
  _exports.SUCCESSFUL_ADD_MESSAGE = SUCCESSFUL_ADD_MESSAGE;
  const SUCCESS_STATUS = 'success';
  _exports.SUCCESS_STATUS = SUCCESS_STATUS;
  const ERROR_STATUS = 'error';
  _exports.ERROR_STATUS = ERROR_STATUS;
});