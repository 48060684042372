define("adt-wss/pods/dashboard/account/contacts/controller", ["exports", "adt-wss-common/classes/status-message"], function (_exports, _statusMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    account: Ember.inject.controller('dashboard/account'),
    dashboardStatusMessages: Ember.computed.alias('account.dashboardStatusMessages'),
    contactData: Ember.inject.service(),
    phoneNumbsDuplication: false,

    init(...args) {
      this._super(...args);

      this.duplicatePhoneNumbersMessage = new _statusMessage.default({
        head: 'Duplicate Phone Numbers May Slow Emergency Response Time.',
        text: 'Please update or remove highlighted contacts below.'
      }, {
        type: 'warn',
        static: true
      });
      this.duplicationsCorrectedMessage = new _statusMessage.default('All duplicate phone numbers have been corrected.', 'success');
      this.setDashboardStatusMessages = this.setDashboardStatusMessages.bind(this);
    },

    duplicatePhoneNumbsMessageHandler(extraMessages = []) {
      const duplication = this.get('contactData.phonesDuplication');
      const duplicationKey = 'phoneNumbsDuplication';

      if (duplication) {
        this.set(duplicationKey, true);
        return [this.duplicatePhoneNumbersMessage].concat(extraMessages);
      } else if (!duplication && this.get(duplicationKey)) {
        this.set(duplicationKey, false);
        return [this.duplicationsCorrectedMessage];
      }

      return extraMessages;
    },

    setDashboardStatusMessages(messages = []) {
      this.set('dashboardStatusMessages', this.duplicatePhoneNumbsMessageHandler(messages));
    },

    actions: {
      determinePhoneDuplication(...params) {
        return this.contactData.determinePhoneDuplication(...params);
      },

      determinePhoneListDuplication(...params) {
        return this.contactData.determinePhoneListDuplication(...params);
      }

    }
  });

  _exports.default = _default;
});