define("adt-wss/pods/components/dashboard/sweepstake-form/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CANCEL_ACTION = _exports.FAILURE_SUBMIT_ACTION = _exports.SUCCESS_SUBMIT_ACTION = _exports.SUBMIT_ACTION = _exports.FAILURE_ACTION = _exports.SUCCESS_ACTION = _exports.FETCH_DATA_ACTION = _exports.LOADING_SUBMITTING_STATE = _exports.ENTRY_FORM_STATE = _exports.LOADING_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const LOADING_STATE = 'LOADING_STATE';
  _exports.LOADING_STATE = LOADING_STATE;
  const ENTRY_FORM_STATE = 'ENTRY_FORM_STATE';
  _exports.ENTRY_FORM_STATE = ENTRY_FORM_STATE;
  const LOADING_SUBMITTING_STATE = 'LOADING_SUBMITTING_STATE';
  _exports.LOADING_SUBMITTING_STATE = LOADING_SUBMITTING_STATE;
  const FETCH_DATA_ACTION = 'FETCH_DATA_ACTION';
  _exports.FETCH_DATA_ACTION = FETCH_DATA_ACTION;
  const SUCCESS_ACTION = 'SUCCESS_ACTION';
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
  const FAILURE_ACTION = 'FAILURE_ACTION';
  _exports.FAILURE_ACTION = FAILURE_ACTION;
  const SUBMIT_ACTION = 'SUBMIT_ACTION';
  _exports.SUBMIT_ACTION = SUBMIT_ACTION;
  const SUCCESS_SUBMIT_ACTION = 'SUCCESS_SUBMIT_ACTION';
  _exports.SUCCESS_SUBMIT_ACTION = SUCCESS_SUBMIT_ACTION;
  const FAILURE_SUBMIT_ACTION = 'FAILURE_SUBMIT_ACTION';
  _exports.FAILURE_SUBMIT_ACTION = FAILURE_SUBMIT_ACTION;
  const CANCEL_ACTION = 'CANCEL_ACTION';
  _exports.CANCEL_ACTION = CANCEL_ACTION;
});