define("adt-wss/pods/components/dashboard/account/contract-sign-process/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SMARTPHONE_IFRAME_HEIGHT = _exports.TABLET_IFRAME_HEIGHT = _exports.ERROR_MESSAGE_TITLE = _exports.WARN_MESSAGE = _exports.USER_CLOSED_DOCUSIGN_MODAL_EVENT = _exports.DOCUMENT_EXPIRED_DOCUSIGN_EVENT = _exports.DOCUSIGN_SESSION_EXPIRED_EVENT = _exports.DOCUMENT_DECLINED_DOCUSIGN_EVENT = _exports.DOCUMENT_CANCELED_DOCUSIGN_EVENT = _exports.DOCUMENT_SIGNED_DOCUSIGN_EVENT = void 0;
  const DOCUMENT_SIGNED_DOCUSIGN_EVENT = 'signing_complete';
  _exports.DOCUMENT_SIGNED_DOCUSIGN_EVENT = DOCUMENT_SIGNED_DOCUSIGN_EVENT;
  const DOCUMENT_CANCELED_DOCUSIGN_EVENT = 'cancel';
  _exports.DOCUMENT_CANCELED_DOCUSIGN_EVENT = DOCUMENT_CANCELED_DOCUSIGN_EVENT;
  const DOCUMENT_DECLINED_DOCUSIGN_EVENT = 'decline';
  _exports.DOCUMENT_DECLINED_DOCUSIGN_EVENT = DOCUMENT_DECLINED_DOCUSIGN_EVENT;
  const DOCUSIGN_SESSION_EXPIRED_EVENT = 'session_timeout';
  _exports.DOCUSIGN_SESSION_EXPIRED_EVENT = DOCUSIGN_SESSION_EXPIRED_EVENT;
  const DOCUMENT_EXPIRED_DOCUSIGN_EVENT = 'ttl_expired';
  _exports.DOCUMENT_EXPIRED_DOCUSIGN_EVENT = DOCUMENT_EXPIRED_DOCUSIGN_EVENT;
  const USER_CLOSED_DOCUSIGN_MODAL_EVENT = 'userClosedModal';
  _exports.USER_CLOSED_DOCUSIGN_MODAL_EVENT = USER_CLOSED_DOCUSIGN_MODAL_EVENT;
  const WARN_MESSAGE = {
    head: 'Your Contract Renewal Is Not Complete',
    text: 'Select an option below to continue. If you leave this page your previous selections will not be saved.'
  };
  _exports.WARN_MESSAGE = WARN_MESSAGE;
  const ERROR_MESSAGE_TITLE = 'We apologize, an error accured while processing your renewal.';
  _exports.ERROR_MESSAGE_TITLE = ERROR_MESSAGE_TITLE;
  const TABLET_IFRAME_HEIGHT = 580;
  _exports.TABLET_IFRAME_HEIGHT = TABLET_IFRAME_HEIGHT;
  const SMARTPHONE_IFRAME_HEIGHT = 480;
  _exports.SMARTPHONE_IFRAME_HEIGHT = SMARTPHONE_IFRAME_HEIGHT;
});