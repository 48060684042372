define("adt-wss/pods/components/dashboard/preinstall-widget/component", ["exports", "adt-wss/pods/components/dashboard/appointment-widget/component", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/route-helpers", "adt-wss-common/utils/common-fsm-state"], function (_exports, _component, _emberHelpers, _routeHelpers, _commonFsmState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.dateComputed = void 0;
  const defaultProps = {
    appointment: {}
  };
  /**
   * Make date string for scheduled appointment message
   * @param  {Date} date - Scheduled appointment date
   * @return {String}
   */

  const dateComputed = date => date.toAltDayString('abbr', true, 'full');
  /**
   * @module Components/Dashboard/Preinstall-widget
   * @prop {Object} appointment - data from API for pre-install appointment
   * @example
   * {@lang xml} {{ dashboard/preinstall-widget
  		appointment={}
  }}
   */


  _exports.dateComputed = dateComputed;

  var _default = _component.default.extend(defaultProps, _commonFsmState.commonMixin, {
    appointmentData: Ember.inject.service(),
    date: (0, _emberHelpers.computedApplyFunction)(dateComputed, 'appointment.apptStartDateObj'),

    [_commonFsmState.FETCH_DATA_ACTION]() {
      return this.deliverData('appointmentData.appointmentList', appointmentList => appointmentList.getAppointmentList().then(() => appointmentList.get('activeAppointments'))).then((0, _routeHelpers.fsmDispatch)(_commonFsmState.SUCCESS_ACTION)(this)).catch((0, _routeHelpers.fsmDispatch)(_commonFsmState.FAILURE_ACTION)(this));
    },

    [_commonFsmState.SUCCESS_ACTION](appointmentList) {
      this.set('appointment', appointmentList.find(el => el.preInstallJob));
    },

    [_commonFsmState.FAILURE_ACTION](error) {
      console.error(error);
    }

  });

  _exports.default = _default;
});