define("adt-wss/templates/modal-windows/system-test-wizard/trigger-sensors-instruction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hDztPKYd",
    "block": "{\"symbols\":[\"pr\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"test-system-wizard-info-modal test-wizard-instruction\"],[10,\"role\",\"region\"],[10,\"aria-label\",\"Trigger Your Sensors.\\n\\tNow, you'll need to trigger one or multiple sensors you wish to test to set off the alarm such as opening a window, a door or walking past a motion detector.\\n\\tAlarm will sound.\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"test-system-wizard-progress\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[8],[0,\"Progress\"],[9],[0,\"\\n\"],[4,\"bs-progress\",null,null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"bar\"]],\"expected `pr.bar` to be a contextual component but found a string. Did you mean `(component pr.bar)`? ('adt-wss/templates/modal-windows/system-test-wizard/trigger-sensors-instruction.hbs' @ L7:C5) \"],null]],[[\"value\"],[40]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"test-wizard-info-text system-test-trigger-sensors-instruction-info\"],[8],[0,\"\\n\\t\\t\"],[7,\"h4\",true],[10,\"class\",\"test-wizard-info-title\"],[8],[0,\"Trigger Your Sensors\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"\\n\\t\\t\\tNow, you'll need to trigger one or multiple sensors you wish to test to set off the alarm such as opening a window, a door or walking past a motion detector.\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"widget/system-test-wizard-note\",null,[[\"text\"],[\"Alarm will sound\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/system-test-wizard/trigger-sensors-instruction.hbs"
    }
  });

  _exports.default = _default;
});