define("adt-wss/pods/components/dashboard/account/one-time-payment/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.REJECT_ACTION = _exports.RESOLVE_ACTION = _exports.LOADED_ACTION = _exports.SET_PAYMENT_TYPE_ACTION = _exports.REJECT_IVR_ACTION = _exports.RESOLVE_IVR_ACTION = _exports.INITIATE_IVR_ACTION = _exports.EXIT_ACTION = _exports.SUBMIT_ACTION = _exports.SELECT_ACCOUNT_ACTION = _exports.COMPLETED_STATE = _exports.SUBMITTING_STATE = _exports.INTITIATING_IVR_STATE = _exports.SETTING_PAYMENT_TYPE_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const SETTING_PAYMENT_TYPE_STATE = 'SETTING_PAYMENT_TYPE_STATE';
  _exports.SETTING_PAYMENT_TYPE_STATE = SETTING_PAYMENT_TYPE_STATE;
  const INTITIATING_IVR_STATE = 'INTITIATING_IVR_STATE';
  _exports.INTITIATING_IVR_STATE = INTITIATING_IVR_STATE;
  const SUBMITTING_STATE = 'SUBMITTING_STATE';
  _exports.SUBMITTING_STATE = SUBMITTING_STATE;
  const COMPLETED_STATE = 'COMPLETED_STATE';
  _exports.COMPLETED_STATE = COMPLETED_STATE;
  const SELECT_ACCOUNT_ACTION = 'SELECT_ACCOUNT_ACTION';
  _exports.SELECT_ACCOUNT_ACTION = SELECT_ACCOUNT_ACTION;
  const SUBMIT_ACTION = 'SUBMIT_ACTION';
  _exports.SUBMIT_ACTION = SUBMIT_ACTION;
  const EXIT_ACTION = 'EXIT_ACTION';
  _exports.EXIT_ACTION = EXIT_ACTION;
  const INITIATE_IVR_ACTION = 'INITIATE_IVR_ACTION';
  _exports.INITIATE_IVR_ACTION = INITIATE_IVR_ACTION;
  const RESOLVE_IVR_ACTION = 'RESOLVE_IVR_ACTION';
  _exports.RESOLVE_IVR_ACTION = RESOLVE_IVR_ACTION;
  const REJECT_IVR_ACTION = 'REJECT_IVR_ACTION';
  _exports.REJECT_IVR_ACTION = REJECT_IVR_ACTION;
  const SET_PAYMENT_TYPE_ACTION = 'SET_PAYMENT_TYPE_ACTION';
  _exports.SET_PAYMENT_TYPE_ACTION = SET_PAYMENT_TYPE_ACTION;
  const LOADED_ACTION = 'LOADED_ACTION';
  _exports.LOADED_ACTION = LOADED_ACTION;
  const RESOLVE_ACTION = 'RESOLVE_ACTION';
  _exports.RESOLVE_ACTION = RESOLVE_ACTION;
  const REJECT_ACTION = 'REJECT_ACTION';
  _exports.REJECT_ACTION = REJECT_ACTION;
});