define("adt-wss/pods/components/dashboard/account/balance-info/component", ["exports", "adt-wss-common/utils/ember-helpers"], function (_exports, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const computeButton = (afterPaymentState, positiveBalance) => "Make ".concat(afterPaymentState ? 'Another' : positiveBalance ? 'a' : 'an Additional', " Payment");
  /**
   * @module Components/Dashboard/Account/BalanceInfo
   * @prop {Function} startMakePayment shows one time payment form
   * @prop {Function} startModifyEasyPay shows EasyPay form
   * @prop {Function} viewStatement pops up latest statement PDF
   * @prop {BillingModel.BillInfo} billInfo from `billingData` service
   * @prop {String} cardNumber
   * @prop {Boolean} afterPaymentState
   * @prop {String} cardType
   */


  var _default = Ember.Component.extend({
    classNames: ['section-content'],
    billingData: Ember.inject.service(),
    manageEasypay: Ember.inject.service(),
    flexfiData: Ember.inject.service(),
    router: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    dsm: Ember.inject.service('dashboard-status-messages'),
    easyPayDetails: Ember.computed.readOnly('manageEasypay.details'),
    savedAccounts: Ember.computed.readOnly('billingData.savedAccounts'),
    billInfo: Ember.computed.readOnly('billingData.billingModel.billInfo'),
    flexfiDetails: Ember.computed.readOnly('flexfiData.data'),
    enrolledInEasyPay: Ember.computed.readOnly('billingData.billingModel.billingOptions.enrolledInEasyPay'),
    isFlexFi: (0, _emberHelpers.computedApplyFunction)((installmentData = []) => installmentData.length > 0, 'flexfiDetails.installmentData'),
    isExternalFlexFi: Ember.computed.bool('flexfiDetails.financierData.flexFiSystem'),
    isFlexFiMissingEasyPay: Ember.computed.readOnly('flexfiData.missingEasyPay'),
    isFlexFiNoEasyPay: Ember.computed.readOnly('flexfiData.noEasyPay'),
    positiveBalance: (0, _emberHelpers.computedApplyFunction)(balance => parseFloat(balance) > 0, 'billInfo.balance'),
    paymentButtonText: (0, _emberHelpers.computedApplyFunction)(computeButton, 'afterPaymentState', 'positiveBalance'),
    afterPaymentState: Ember.computed.readOnly('billingData.afterPaymentState'),
    hasPendingPayment: Ember.computed.oneWay('billInfo.hasPendingPayment'),
    easyPayPending: Ember.computed.oneWay('billingData.easyPayPending'),
    showOneTimePayment: (0, _emberHelpers.computedApplyFunction)(r => r !== 'dashboard.account.payments.onetimepayment', 'router.currentRouteName'),
    isSmartphone: Ember.computed.oneWay('media.isSmartphone'),
    paymentSelectionProcess: false,

    didReceiveAttrs() {
      this.get('billingData').getBillingStatements();
    },

    actions: {
      dispatch(action, data) {
        this.get(action)(data);
      },

      startVideoAction() {
        const {
          mwd
        } = this.getProperties('mwd');

        const onClose = () => {
          mwd.hide();
        };

        mwd.show({
          name: 'video-billing-statement',
          title: 'Understand Your Bill',
          onClose
        });
      }

    }
  });

  _exports.default = _default;
});