define("adt-wss/templates/modal-windows/enroll-business-reporting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5MKIbWlg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"loading-spinner\",[[24,[\"body\",\"IS_UPDATING_STATE\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"section\",true],[10,\"class\",\"enroll-business-reporting-modal-description\"],[8],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"After enrolling you can create your reports by location, create a location group and include event notifications for open, close and exceptions as well as manager users.\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"You'll be able to schedule reports by frequency and select on which days you wish to receive the reports.\"],[9],[0,\"\\n\\t\\t\"],[7,\"section\",true],[10,\"class\",\"enroll-business-reporting-modal-services-list-wrap\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h6\",true],[10,\"class\",\"list-title\"],[8],[0,\"Services Added\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"dl\",true],[10,\"class\",\"enroll-business-reporting-modal-services-list\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"dt\",true],[8],[0,\"ADT Business Reporting\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"dd\",true],[8],[0,\"$10 /month\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"],[4,\"stupid/usage-agreement\",null,[[\"checked\",\"extraClasses\"],[[24,[\"body\",\"agreement\"]],[28,\"array\",[\"enroll-business-report-agreement\"],null]]],{\"statements\":[[0,\"\\t\\t\\tI accept the ADT Business Reporting\\n\\t\\t\\t\"],[7,\"a\",true],[10,\"href\",\"https://alpha.adt.com/content/dam/MyADTAssets/static/ADT-LLC-Business-Reporting-Service-User-Agreement.pdf\"],[10,\"class\",\"blue-link\"],[10,\"target\",\"blank\"],[8],[0,\"terms\"],[9],[0,\".\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/enroll-business-reporting.hbs"
    }
  });

  _exports.default = _default;
});