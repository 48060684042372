define("adt-wss/pods/help/topics/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const topicId = /^[0-9a-z]+$/i.test(params.topic_id) ? params.topic_id : null;
      const uint = localStorage.myADT_system_names || '';
      return {
        full: uint ? "".concat(topicId, ".uint=").concat(uint) : topicId,
        topicId
      };
    }

  });

  _exports.default = _default;
});