define("adt-wss/templates/modal-windows/close-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a7B8aMV/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[0,\"\\n\\tAre you sure you want to close the form?\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/close-form.hbs"
    }
  });

  _exports.default = _default;
});