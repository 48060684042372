define("adt-wss/pods/components/help/help-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ChZAnZRM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"navbarPosition\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"help-search-wrapper \",[28,\"if\",[[24,[\"searchIsOpen\"]],\"open\"],null]]]],[8],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"class\",\"id\",\"focusOut\",\"enter\",\"bubbles\",\"value\"],[\"search-field\",\"navbarHeaderSearchInput\",\"toggleSearch\",[28,\"action\",[[23,0,[]],\"submitSearch\"],null],\"false\",[24,[\"query\"]]]]],false],[0,\"\\n\\t\\t\"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",[29,[\"clear-search-icon\",[28,\"unless\",[[24,[\"query\"]],\" hidden\"],null]]]],[3,\"action\",[[23,0,[]],\"clearSearch\"]],[8],[9],[0,\"\\n\\t\\t\"],[7,\"div\",false],[12,\"class\",[29,[\"icon search-icon\",[28,\"if\",[[24,[\"media\",\"isSmartphone\"]],\" white\"],null]]]],[3,\"action\",[[23,0,[]],\"toggleSearch\"]],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"input-wrapper search-input-wrapper flex\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"id\",\"aria-label\",\"name\",\"enter\",\"value\"],[\"mobile-search-input\",\"mobile-search-input\",\"mobile-search-input\",[28,\"action\",[[23,0,[]],\"submitSearch\"],null],[24,[\"query\"]]]]],false],[0,\"\\n\\t\\t\"],[7,\"span\",false],[12,\"class\",\"search-icon\"],[3,\"action\",[[23,0,[]],\"submitSearch\"]],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/help/help-search/template.hbs"
    }
  });

  _exports.default = _default;
});