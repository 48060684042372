define("adt-wss/pods/components/dashboard/alarm/system-test-item/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/fp", "adt-wss/pods/components/dashboard/alarm/system-test-item/constants"], function (_exports, _withFsm, _emberHelpers, _myadtHelpers, _fp, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.defaultProperties = void 0;
  const defaultProperties = {
    system: null,
    refreshSystemTestList: null,
    testTimeOptions: ['Select', '1 Hour', '2 Hours', '4 Hours', '8 Hours', '12 Hours', '1 day', '2 days', '3 days', '4 days', '5 days', '6 days', '7 days']
  };
  _exports.defaultProperties = defaultProperties;
  const fsmModel = {
    transitions: {
      [_constants.IDLE_STATE]: {
        [_constants.SYSTEM_IS_ON_TEST_ACTION]: _constants.TEST_IN_PROGRESS_STATE,
        [_constants.LOADING_START_TEST_ACTION]: _constants.LOADING_START_TEST_STATE
      },
      [_constants.TEST_IN_PROGRESS_STATE]: {
        [_constants.LOADING_EDIT_TEST_ACTION]: _constants.LOADING_EDIT_TEST_STATE,
        [_constants.LOADING_STOP_TEST_ACTION]: _constants.LOADING_STOP_TEST_STATE
      },
      [_constants.LOADING_START_TEST_STATE]: {
        [_constants.SUCCESS_START_ACTION]: _constants.TEST_IN_PROGRESS_STATE,
        [_constants.FAILURE_START_ACTION]: _constants.IDLE_STATE
      },
      [_constants.LOADING_STOP_TEST_STATE]: {
        [_constants.SUCCESS_ACTION]: _constants.IDLE_STATE,
        [_constants.FAILURE_ACTION]: _constants.TEST_IN_PROGRESS_STATE
      },
      [_constants.LOADING_EDIT_TEST_STATE]: {
        [_constants.SUCCESS_EDIT_ACTION]: _constants.TEST_IN_PROGRESS_STATE,
        [_constants.FAILURE_ACTION]: _constants.TEST_IN_PROGRESS_STATE
      }
    }
  };
  /**
   * @module Components/Dashboard/Alarm/SystemTestItem
   * @prop {Array} system - user system
   * @example
   * {@lang xml} {{ dashboard/alarm/system-test-item
  		system=null
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProperties, {
    systemTest: Ember.inject.service(),
    csrInfo: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    fsmModel,
    extendTimeValue: 'Select',
    countDownPrefix: 'Your system is on Test',
    countDown: '00:00:00',
    countDownInterval: null,
    inService: false,
    timeIsUpdated: (0, _emberHelpers.computedApplyFunction)(extendTimeValue => extendTimeValue !== 'Select', 'extendTimeValue'),
    currentState: _constants.IDLE_STATE,
    IS_UPDATING: (0, _emberHelpers.computedSome)('currentState', _constants.LOADING_STOP_TEST_STATE, _constants.LOADING_EDIT_TEST_STATE),
    isSplitIntegrationSystem: false,

    [_constants.SUCCESS_START_ACTION]() {
      this.get('refreshSystemTestList')();
    },

    [_constants.FAILURE_START_ACTION](results) {
      const error = (0, _myadtHelpers.genericErrorHandler)(results);
      return this.showStatusMessage((error || [])[0], 'error');
    },

    [_constants.SUCCESS_EDIT_ACTION]() {
      this.get('refreshSystemTestList')();
    },

    [_constants.SUCCESS_ACTION]() {
      this.get('refreshSystemTestList')();
    },

    [_constants.FAILURE_ACTION](results) {
      const error = (0, _myadtHelpers.genericErrorHandler)(results);
      return this.showStatusMessage((error || [])[0], 'error');
    },

    [_constants.SYSTEM_IS_ON_TEST_ACTION](system) {
      console.log(system);
    },

    [_constants.LOADING_START_TEST_ACTION](system) {
      const {
        extendTimeValue,
        systemTest,
        inService,
        system: {
          systemNo
        }
      } = this.getProperties('extendTimeValue', 'systemTest', 'inService', 'system');

      if (inService) {
        const time = parseInt(extendTimeValue, 10);
        const extendHrs = extendTimeValue.includes('Hour') ? time : time * 24;
        const eventTagInfo = {
          eventTypeId: 'SYSTTEST',
          subEventTypeId: 'STRTTSTM'
        };
        this.get('ET').sendEventTag(eventTagInfo);

        const sendAnalytics = () => {
          (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'System Test', 'PutSystemOnTest', "SystemNo: ".concat(systemNo)]);
        };

        return this.commonDispatcher(() => {
          const data = {
            extendHrs,
            systemNo: system.systemNo,
            siteNo: system.siteNo,
            seqNo: system.seqNo,
            secondarySystemNo: system.secondarySystemNo,
            secondarySeqNo: system.secondarySeqNo
          };
          return systemTest.putSystemOnTest(data).then(() => sendAnalytics());
        }, _constants.SUCCESS_START_ACTION, _constants.FAILURE_START_ACTION);
      }

      return this.get('fsm').dispatch(_constants.FAILURE_START_ACTION, {
        errors: ['systemOutOfService.genericError']
      });
    },

    [_constants.LOADING_EDIT_TEST_ACTION](system) {
      const {
        extendTimeValue,
        systemTest,
        timeIsUpdated
      } = this.getProperties('extendTimeValue', 'systemTest', 'timeIsUpdated');

      if (timeIsUpdated) {
        const time = parseInt(extendTimeValue, 10);
        const extendHrs = extendTimeValue.includes('Hour') ? time : time * 24;
        return this.commonDispatcher(() => systemTest.updateSystemTest((0, _fp.omix)(system, {
          extendHrs
        })), _constants.SUCCESS_EDIT_ACTION, _constants.FAILURE_ACTION);
      }

      return this.get('fsm').dispatch(_constants.FAILURE_ACTION, 'Time is not updated.');
    },

    [_constants.LOADING_STOP_TEST_ACTION](system) {
      const {
        systemTest,
        system: {
          systemNo
        }
      } = this.getProperties('systemTest', 'system');
      const eventTagInfo = {
        eventTypeId: 'SYSTTEST',
        subEventTypeId: 'MANSTPTM'
      };
      this.get('ET').sendEventTag(eventTagInfo);

      const sendAnalytics = () => {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'System Test', 'Manually Clear Test', "SystemNo: ".concat(systemNo)]);
      };

      return this.commonDispatcher(() => systemTest.clearSystemTest(system).then(() => sendAnalytics()), _constants.SUCCESS_ACTION, _constants.FAILURE_ACTION);
    },

    formatCountdown(value) {
      return value > 9 ? value : "0".concat(value);
    },

    setCountdownPrefix(system) {
      const endTime = Date.createDate(system.testExpiresAt);
      this.set('countDownPrefix', endTime.toAltString() + ' ' + endTime.toTimeAmPm(false, true));
    },

    setIsSplitIntSystem(system, splitIntSystemList) {
      if (splitIntSystemList.includes(system.systemNo)) {
        this.set('isSplitIntegrationSystem', true);
      }
    },

    setCountdown(system) {
      const {
        effectiveDate
      } = system;
      const endTime = effectiveDate + system.expirationTimeInMillis;
      this.set('countDownInterval', setInterval(() => {
        if (endTime >= Date.now()) {
          const {
            days,
            hours,
            minutes,
            seconds
          } = Date.span(endTime, Date.now()).relative || {};
          const D = days ? "".concat(days, " day(s), ") : '';
          const HH = this.formatCountdown(hours);
          const MM = this.formatCountdown(minutes);
          const ss = this.formatCountdown(seconds);
          const countDownValue = "".concat(D).concat(HH, ":").concat(MM, ":").concat(ss);
          this.set('countDown', countDownValue);
        } else {
          clearInterval(this.get('countDownInterval'));
        }
      }, 1000));
    },

    willDestroyElement() {
      const countDownInterval = this.get('countDownInterval');

      this._super(...arguments);

      if (countDownInterval) {
        clearInterval(countDownInterval);
      }
    },

    didReceiveAttrs() {
      this._super(...arguments);

      const systemAttr = this.get('system');
      const splitIntSystemListAttr = this.get('splitIntsystemNoList');

      if (systemAttr && splitIntSystemListAttr) {
        this.setIsSplitIntSystem(systemAttr, splitIntSystemListAttr);
      }

      if (systemAttr && systemAttr.onTest) {
        this.setCountdownPrefix(systemAttr);
        this.setCountdown(systemAttr);
        this.get('fsm').dispatch(_constants.SYSTEM_IS_ON_TEST_ACTION);
      }
    }

  });

  _exports.default = _default;
});