define("adt-wss/pods/components/dashboard/account/flexfi-table/component", ["exports", "adt-wss-common/utils/myadt-helpers"], function (_exports, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['flexfi-table'],
    flexfiData: Ember.inject.service(),
    installmentData: Ember.computed.oneWay('flexfiData.installmentData'),
    financierData: Ember.computed.oneWay('flexfiData.financierData'),
    missingEasyPay: Ember.computed.oneWay('flexfiData.missingEasyPay'),

    init() {
      this._super(...arguments);

      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'MyADT Billing', 'MyADT FlexFi Details', 'Active']);
    },

    actions: {
      dispatch(action, data) {
        this.get(action)(data);
      }

    }
  });

  _exports.default = _default;
});