define("adt-wss/pods/dashboard/manage/index/controller", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss/services/app-dispatcher", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/common-fsm-state"], function (_exports, _emberHelpers, _appDispatcher, _withFsm, _commonFsmState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  	 * Returns locations filtered by search query and sorted
  	 * by location name.
  	 * Searches all string properties.
  	 * @param {String} query search query
  	 * @param {Object[]} locations
  	 */
  const filteredLocations = (query, locations) => {
    if (!locations || !locations.filter) return [];
    if (!query) return locations;
    const regex = new RegExp(query, 'i');
    const filtered = locations.filter(location => Object.keys(location).some(key => typeof location[key] === 'string' && regex.test(location[key])));
    return filtered.sort((l, r) => {
      if (!l.locationName) return 1;
      if (!r.locationName) return -1;
      return l.locationName.toLowerCase() < r.locationName.toLowerCase() ? -1 : 1;
    });
  };

  const calcCurrentLocationOrIndex = isIndex => locations => {
    let index = 0;
    const currentAccount = (locations || []).find((x, i) => {
      if (x.currentAccount) {
        index = i;
        return true;
      }

      return false;
    });
    return isIndex ? index : currentAccount;
  };

  const calcCurrentLocationIndex = calcCurrentLocationOrIndex(true);
  const calcCurrentLocation = calcCurrentLocationOrIndex();
  /**
   * @module Controllers/Dashboard/Manage/Index
   * @prop {Location[]} model list of locations from {@link Services/ManageAccountService.locations}
   * @prop {Classes/StatusMessage[]} statusMessages reference to parent controller `dashboardStatusMessages`
   * @prop {Object} currentAddress reference to {@link Services/CurrentLocation.profileInfo}.address
   * @prop {String} searchQuery for filtering locations when > 3
   * @prop {Int} currentIndex for separating currently viewed site when > 3
   * @prop {Computed<Location[]>} visibleLocations model filtered by `searchQuery`
   * @prop {Computed<Location>} currentAccount currently viewed location; side effect: sets `currentIndex`
   */

  var _default = Ember.Controller.extend(_withFsm.default, _commonFsmState.commonMixin, {
    manage: Ember.inject.controller('dashboard/manage'),
    currentLocation: Ember.inject.service(),
    mas: Ember.inject.service('manage-account-service'),
    isLoading: (0, _emberHelpers.isState)(_commonFsmState.LOADING_STATE),
    statusMessages: Ember.computed.alias('manage.dashboardStatusMessages'),
    currentAddress: Ember.computed.oneWay('currentLocation.profileInfo.address'),
    searchQuery: '',
    currentIndex: (0, _emberHelpers.computedApplyFunction)(calcCurrentLocationIndex, 'locations'),
    locations: Ember.computed.oneWay('mas.locations'),
    visibleLocations: (0, _emberHelpers.computedApplyFunction)(filteredLocations, 'searchQuery', 'locations'),
    showCurrent: (0, _emberHelpers.computedApplyFunction)((vl, ca) => (vl || []).includes(ca), 'visibleLocations', 'currentAccount'),
    currentAccount: (0, _emberHelpers.computedApplyFunction)(calcCurrentLocation, 'locations'),

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      this.set('mas.locations', null);
      this.set('mas.locationGroups', null);
      this.get('fsm').dispatch(_commonFsmState.FETCH_DATA_ACTION);
    },

    [_commonFsmState.FETCH_DATA_ACTION]() {
      return this.deliverDataAndDispatch('manage-account-service.locations', m => m.getLocationsInfo());
    },

    [_commonFsmState.SUCCESS_ACTION]() {
      this.send('refreshLocations');
    },

    /**
     * Makes sure search is empty when 3 or less locations
     */
    clearSearchUnderFour: Ember.observer('locations', function () {
      const locations = this.get('locations');

      if (locations && locations.length < 4) {
        this.set('searchQuery', '');
      }
    }),
    actions: {
      /**
       * Clears search and removes focus from search field
       * @param {Event} e
       */
      checkEscapeInSearch(e) {
        if (e.keyCode === 27) {
          e.target.blur();
          this.set('searchQuery', '');
        }
      },

      refreshLocations(webAddressId) {
        const route = Ember.getOwner(this).lookup('route:dashboard.manage.index');

        if (webAddressId) {
          return this.get('currentLocation').changeAddress({
            webAddressId
          }).then(() => route.refresh());
        }

        return route.refresh();
      }

    }
  });

  _exports.default = _default;
});