define("adt-wss/pods/dashboard/account/contacts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TTZhB9m8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"section-content\"],[8],[0,\"\\n\\t\"],[1,[28,\"dashboard/account/contacts/location-number\",null,[[\"statusMessages\",\"determinePhoneDuplication\",\"setMessages\",\"systemPhoneNumbersDuplication\"],[[24,[\"dashboardStatusMessages\"]],[28,\"action\",[[23,0,[]],\"determinePhoneDuplication\"],null],[24,[\"setDashboardStatusMessages\"]],[24,[\"phoneNumbsDuplication\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"section-content\"],[8],[0,\"\\n\\t\"],[1,[28,\"dashboard/account/contacts/contact-dispatch\",null,[[\"type\",\"setMessages\",\"systemPhoneNumbersDuplication\",\"determinePhoneDuplication\",\"determinePhoneListDuplication\"],[\"predispatch\",[24,[\"setDashboardStatusMessages\"]],[24,[\"phoneNumbsDuplication\"]],[28,\"action\",[[23,0,[]],\"determinePhoneDuplication\"],null],[28,\"action\",[[23,0,[]],\"determinePhoneListDuplication\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"section-content\"],[8],[0,\"\\n\\t\"],[1,[28,\"dashboard/account/contacts/contact-dispatch\",null,[[\"type\",\"setMessages\",\"systemPhoneNumbersDuplication\",\"determinePhoneDuplication\",\"determinePhoneListDuplication\"],[\"postdispatch\",[24,[\"setDashboardStatusMessages\"]],[24,[\"phoneNumbsDuplication\"]],[28,\"action\",[[23,0,[]],\"determinePhoneDuplication\"],null],[28,\"action\",[[23,0,[]],\"determinePhoneListDuplication\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/account/contacts/template.hbs"
    }
  });

  _exports.default = _default;
});