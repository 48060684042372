define("adt-wss/pods/components/dashboard/account/communication-preferences-item/component", ["exports", "adt-wss-common/mixins/with-fsm"], function (_exports, _withFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.defaultProps = void 0;
  const defaultProps = {
    title: '',
    subTitle: false,
    explanation: '',
    opted: false,
    paperlessVisible: false,
    billingRemindersEmails: [],

    updateBillingReminders() {
      console.error('Please pass updateBillingReminders method to dashboard/account/communication-preferences-item component');
    },

    travelReminders: [],

    updateTravelReminders() {
      console.error('Please pass updateTravelReminders method to dashboard/account/communication-preferences-item component');
    },

    monitoringReminders: [],

    updateMonitoringReminders() {
      console.error('Please pass updateMonitoringReminders method to dashboard/account/communication-preferences-item component');
    },

    updateServiceAppointmentsPrefs() {
      console.error('Please pass updateServiceAppointmentsPrefs method to dashboard/account/communication-preferences-item component');
    }

  };
  /**
   * @module Components/Dashboard/Account/CommunicationPreferencesItem
   * @prop {Function} title - function will be used for computing
   * @prop {Boolean|Function} subTitle - function will be used for computing
   * @prop {String} explanation
   * @prop {Boolean} opted
   * @prop {Boolean} paperlessVisible
   * @prop {Array.<{email: String, pending: ?String}>} primaryEmails
   * @prop {Function} updateBillingReminders
   * @prop {Array.<{email: String, pending: ?String}>} billingRemindersEmails
   * @prop {Function} updateTravelReminders
   * @prop {Array.<{email: String, pending: ?String}>} travelReminders
   * @prop {Function} updateMonitoringReminders
   * @prop {Array.<{email: String, pending: ?String}>} monitoringReminders
   * @prop {Function} updateServiceAppointmentsPrefs
   * @example
   * {@lang xml} {{ dashboard/account/communication-preferences-item
  		title=title
  		subTitle=subTitle
  		explanation='Explanation...'
  		opted=false
  		billingRemindersEmails=[{ email: 'test@test.com', pending: true }]
  		paperlessVisible=true
  		updateBillingReminders=(action 'updateBillingReminders')
  		travelReminders=[{ email: 'test@test.com', pending: true }]
  		updateTravelReminders=(action 'updateBillingReminders')
  		monitoringReminders=[{ email: 'test@test.com', pending: true }]
  		updateMonitoringReminders=(action 'updateMonitoringReminders')
  		updateServiceAppointmentsPrefs=(action 'updateMonitoringReminders')
  		primaryEmails=[{ email: 'test@test.com', pending: null }]
  }}
   */

  _exports.defaultProps = defaultProps;

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    tagName: 'tr',
    classNames: ['communication-preferences-item'],
    classNameBindings: ['even'],
    collapsed: true
  });

  _exports.default = _default;
});