define("adt-wss/templates/modal-windows/video-billing-statement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sGiKgT+u",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"video-billing-statement-wrapper\"],[8],[0,\"\\n\\t\"],[7,\"iframe\",true],[10,\"src\",\"https://www.youtube.com/embed/AeM1qm23CRo\"],[10,\"frameborder\",\"0\"],[10,\"allow\",\"autoplay; encrypted-media\"],[10,\"allowfullscreen\",\"\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/video-billing-statement.hbs"
    }
  });

  _exports.default = _default;
});