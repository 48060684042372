define("adt-wss/pods/help/systemmanuals/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g51Za8Qd",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"stupid/simple-iframe\",null,[[\"url\",\"iFrameID\",\"classNames\"],[[28,\"concat\",[[24,[\"aemHostUrl\"]],\"help/\",[24,[\"model\"]],\".html\"],null],[28,\"concat\",[\"helpSystemManuals\"],null],\"search-iframe\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/help/systemmanuals/template.hbs"
    }
  });

  _exports.default = _default;
});