define("adt-wss/pods/components/widget/system-test-wizard-note/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pl1ViHzb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"strong\",true],[8],[0,\"Note: \"],[1,[22,\"text\"],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/widget/system-test-wizard-note/template.hbs"
    }
  });

  _exports.default = _default;
});