define("adt-wss/pods/components/dashboard/shop/battery-order-summary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['product-order-summary'],
    classNameBindings: ['open']
  });

  _exports.default = _default;
});