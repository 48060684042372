define("adt-wss/pods/components/dashboard/safety-tips/component", ["exports", "adt-wss-common/utils/ember-helpers"], function (_exports, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.determUrl = _exports.determTypeOfAccount = void 0;
  const defaultProps = {
    elementId: 'safetyTips'
  };
  /**
   * Detects type of user account
   * @param accounts  - array of types of the systems
   * @returns {String} - return value of account based on systems
   */

  const determTypeOfAccount = accounts => {
    const types = {
      adtSecurity: accounts.includes('adtSecurity'),
      adtGo: accounts.includes('adtgo') && !accounts.includes('adtSecurity'),
      cyberSecurity: accounts.every(t => t === 'cyberSecurity')
    };
    return Object.keys(types).find(el => types[el]) || 'adtSecurity';
  };
  /**
   * Detects url which to get url of iframe
   * @param account  - type of account (adtSecurity, adtGo or Digital Security)
   * @returns {Number} - return url ending for specific account
   */


  _exports.determTypeOfAccount = determTypeOfAccount;

  const determUrl = account => {
    const urls = {
      adtSecurity: 326,
      adtGo: 327,
      cyberSecurity: 328
    };
    return urls[account];
  };
  /**
   * @module Components/Dashboard/SafetyTips
   * @prop {String} elementId - ID attribute for div component wrapper
   * @example
   * {@lang xml} {{ dashboard/safety-tips
  		elementId: 'safetyTips'
  }}
   */


  _exports.determUrl = determUrl;

  var _default = Ember.Component.extend(defaultProps, {
    classNames: ['safety-tips-widget', 'widget-section'],
    systemInformation: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    accounts: (0, _emberHelpers.computedApplyFunction)((filteredResults, test) => {
      const results = filteredResults || [{
        adtgo: test.adtgo,
        cyberSecuritySystem: test.cyberSecurity
      }];
      return results.map(el => ({
        adtSecurity: ![el.adtgo, el.cyberSecuritySystem].some(Boolean),
        adtgo: el.adtgo,
        cyberSecurity: el.cyberSecuritySystem
      })).map(key => Object.keys(key).filter(o => key[o])).map(el => el[0]);
    }, 'systemInformation.systemInfoList', 'currentLocation.profileInfo'),
    typeOfAccount: (0, _emberHelpers.computedApplyFunction)(determTypeOfAccount, 'accounts'),
    url: (0, _emberHelpers.computedApplyFunction)(determUrl, 'typeOfAccount')
  });

  _exports.default = _default;
});