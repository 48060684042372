define("adt-wss/services/battery-data", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers"], function (_exports, _myadtHelpers, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.setBatterySupportKey = void 0;

  const setBatterySupportKey = (b = {}) => {
    const help = (b || {}).batteryHelpPageUrl ? b.batteryHelpPageUrl.replace(/(help\/|.html)/gi, '') : 'batteryReplacementGuides';
    Ember.set(b, 'supportKey', help);
    return b;
  };

  _exports.setBatterySupportKey = setBatterySupportKey;
  const clearCacheArgs = {
    canOrderBattery: true,
    canOrderBatteryMessage: null,
    batteryList: null,
    orderHistory: null,
    orderPricing: null,
    lastOrder: null
  };
  /**
   * API container and data store for user battery info
   * @module Services/BatteryData
   * @prop {Boolean} canOrderBattery
   * @prop {String} canOrderBatteryMessage
   * @prop {OrderBattery[]} batteryList
   * @prop {OrderBattery[]} systemBatteryList Computed from `batteryList`
   * @prop {OrderBattery[]} peripheralBatteryList Computed from `batteryList`
   */

  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)(clearCacheArgs), {
    apiService: Ember.inject.service(),
    orderData: Ember.inject.service(),
    canOrderBattery: true,
    canOrderBatteryMessage: null,
    batteryList: null,
    orderHistory: null,
    orderPricing: null,
    lastOrder: null,
    systemBatteryList: (0, _emberHelpers.computedApplyFunction)(batteryList => (batteryList || []).filter(b => !b.sensor), 'batteryList'),
    peripheralBatteryList: (0, _emberHelpers.computedApplyFunction)(batteryList => (batteryList || []).filter(b => b.sensor), 'batteryList'),

    /**
     * Retrieves list of all batteries from API.
     * Populates `batteryList`, `canOrderBattery`, and `canOrderBatteryMessage`
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getBatteryList() {
      this.setProperties({
        canOrderBattery: false,
        canOrderBatteryMessage: null
      });
      return this.get('apiService').myADT.getBatteryList().then(({
        data
      }) => {
        const list = (data.batteryList || []).map(b => setBatterySupportKey(b));
        this.set('batteryList', list.length ? list : null); // check for order eligibility

        if (!data.canOrderEligibility && data.reason) {
          this.setProperties({
            canOrderBattery: false,
            canOrderBatteryMessage: data.reason
          });
        } else {
          this.setProperties({
            canOrderBattery: true,
            canOrderBatteryMessage: null
          });
        }

        return data;
      });
    },

    /**
     * Gets pricing info for shopping cart
     * @instance
     *
     * @param {Object} orderData
     * @param {Number} orderData.batteryId
     * @param {String} orderData.csNo
     * @param {String} orderData.shippingOptionSelected
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getBatteryOrderPricing(orderData) {
      return this.get('apiService').myADT.getBatteryOrderPricing(orderData).then(({
        data
      }) => this.set('orderPricing', data));
    },

    /**
     * Gets tax info for shopping cart
     * @instance
     *
     * @param {Object} orderData
     * @param {Number} orderData.batteryId
     * @param {String} orderData.csNo
     * @param {String} orderData.shippingOptionSelected
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getBatteryOrderTaxes(orderData) {
      return this.get('apiService').myADT.getBatteryOrderTaxes(orderData).then(({
        data
      }) => data);
    },

    /**
     * Submits order
     * @instance
     *
     * @param {Object} orderData
     * @param {Object} fullOrder
     * @param {Number} orderData.batteryId
     * @param {String} orderData.csNo
     * @param {String} orderData.shippingOptionSelected
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    postBatteryOrderPlacement(orderData, fullOrder) {
      return this.get('apiService').myADT.postBatteryOrderPlacement(orderData).then(({
        data
      }) => {
        if (fullOrder) {
          fullOrder.jobConfirmationNo = data.jobConfirmationNo;
          this.set('lastOrder', fullOrder);
        }

        this.orderData.getOrderHistory();
        return data;
      });
    }

  });

  _exports.default = _default;
});