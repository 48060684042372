define("adt-wss/services/alarm-data", ["exports", "adt-wss/config/environment", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/localization", "adt-wss-common/utils/http-resources", "adt-wss-common/utils/fp"], function (_exports, _environment, _myadtHelpers, _localization, _httpResources, _fp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const clearCacheArgs = {
    reportEmailSettings: null,
    inactivityAlert: null,
    alarmHistoryData: null,
    systemAvailable: true,
    alarmHistory: null
  };
  /**
   * Data store and API service handler for alarm specific data
   *
   * @module Services/AlarmData
   * @prop {Object[]} activityKeyData.{prefix} Key for the text expansion
   * @prop {Object} activityKeyData.{desc} short description
   * @prop {Object} activityKeyData.{explanation} full description
   * @prop {LocationGroup[]} locationGroups account location groups
   * @prop {ActivityReport[]} reportEmailSettings settings for activity reports
   * @prop {Object} inactivityAlert settings for inactivity alert
   * @prop {Int} inactivityAlert.intervalInMonths
   * @prop {String} inactivityAlert.email
   * @prop {Boolean} systemAvailable is API service available
   */

  var _default = Ember.Service.extend(Ember.Evented, (0, _myadtHelpers.clearCache)(clearCacheArgs), {
    apiService: Ember.inject.service(),
    mas: Ember.inject.service('manage-account-service'),
    ET: Ember.inject.service('event-tagging'),
    myadtSecurity: Ember.inject.service(),
    sendAnalyticEvent: _myadtHelpers.sendAnalyticEvent,
    locationGroups: Ember.computed.readOnly('mas.locationGroups'),
    reportEmailSettings: null,
    inactivityAlert: null,
    alarmHistoryData: null,
    systemAvailable: true,
    alarmHistory: null,

    /**
     * Runs {@link alarmData.getAlarmHistory} and {@link Services/ManageAccountService.getLocationGroups}
     * @instance
     */
    getAlarmData() {
      return Ember.RSVP.hashSettled({
        alarmHistory: this.getAlarmHistory(),
        locationGroups: this.get('mas').getLocationGroups()
      });
    },

    /**
     * Retrieves alarm history, populates `alarmHistory` and `systemAvailable`
     * @instance
     * @type {ServiceCall}
     * @param {Object} [settings]
     * @param {String} [settings.eventTypes] comma-separated string or string[]?
     * @param {String} [settings.interval]
     * @param {String} [settings.lbDate] // TODO: get definition
     * @param {String} [settings.ubDate] // TODO: get definition
     * @emits alarmHistoryAPIComplete
     * @return {ApiResponse}
     */
    getAlarmHistory(settings) {
      if (!this.get('myadtSecurity.picProvided')) {
        return Promise.reject({
          success: false,
          errors: ['PicChallenge.notAuthenticated']
        });
      }

      const opts = {
        eventTypes: '',
        interval: 'NINETY_DAYS',
        lbDate: '',
        ubDate: ''
      };
      (0, _fp.omix)(opts, settings);
      /* eslint-disable newline-per-chained-call */

      return this.get('apiService').request({
        resource: _httpResources.myADT.getAlarmHistory,
        data: opts
      }).then(({
        data
      }) => {
        let history = null;

        if ((data.rows || []).length) {
          history = data.rows.map(row => {
            row.date = Date.createDate(row.eventDate);
            return row;
          });
        }

        this.set('alarmHistory', history);
        this.set('alarmHistoryData', this.parseAlarmList(data));
        this.set('systemAvailable', true);
        return data;
      }).catch(results => {
        if (results.errors) {
          const errors = results.errors;

          _myadtHelpers.default.serviceUnavailableMessage(errors);
        }

        this.set('systemAvailable', false);
        return results;
      }).finally(data => {
        this.trigger('alarmHistoryAPIComplete');
        return data;
      });
      /* eslint-enable newline-per-chained-call */
    },

    /**
     * Unminified response to prepare data for rendering. Sorted by date descending.
     * @param alarmList
     * @returns {*}
     */
    parseAlarmList(alarmList) {
      const stringTable = alarmList.stringTable; // response comes in minified way. Need to parse by keys.

      const parsedAlarmList = alarmList.rows.sort((a, b) => b.eventDate - a.eventDate).map(r => {
        const dateFull = Date.createDate(r.eventDate).toAltDayString('full');
        const row = r;
        row.date = dateFull.substr(dateFull.indexOf(' ') + 1).toUpperCase();
        row.amPm = row.a;
        row.month = row.m;
        row.day = row.d;
        row.zone = row.z;
        row.zoneComment = row.zc;
        row.year = stringTable[row.y];
        row.event = stringTable[row.e];
        row.longDate = "".concat(row.month, " ").concat(row.day, ", ").concat(row.year, " ").concat(row.time + row.amPm);
        row.random = Math.floor(Math.random() * Date.now());
        return (0, _fp.omix)({
          time: "".concat(row.t.substring(0, 5), " ").concat(row.amPm),
          description: stringTable[row.s],
          csNumber: stringTable[row.c],
          userName: row.un || ''
        }, row);
      });
      return parsedAlarmList;
    },

    /**
     * Opens window displaying alarm history in .xlsx
     * @instance
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    exportAlarmHistory(criteria) {
      return this.get('apiService').request({
        resource: _httpResources.myADT.exportAlarmHistory,
        data: criteria
      }).then(({
        data
      }) => {
        const eventTagInfo = {
          eventTypeId: 'ALRMHIST',
          subEventTypeId: 'VIEW'
        };
        this.get('ET').sendEventTag(eventTagInfo);
        this.sendAnalyticEvent(['_trackEvent', 'Alarm History', 'Download History', "fileType=xls, siteNo=".concat(data.siteNo)]); // TODO: move this to component

        const windowUrl = "".concat(_environment.default.rootURL, "ReportServlet");
        const windowId = "ExcelWindow_".concat(Date.now());
        const windowFeatures = 'channelmode=no,directories=no,fullscreen=no,' + 'location=no,dependent=yes,menubar=no,resizable=no,scrollbars=yes,' + 'status=no,toolbar=no,titlebar=no,left=0,top=0,width=700px,height=700px';
        const windowReference = window.open(windowUrl, windowId, windowFeatures);
        window.myADT.hasPopupBlocker(windowReference);
        windowReference.focus();
        return data;
      });
    },

    /**
     * Get's info about activity report emails,
     * NOT the activity itself.
     * Pouplates `reportEmailSettings`
     * @instance
     * @type {ServiceCall}
     * @emits activityReportsComplete
     * @return {ApiResponse}
     */
    getActivityReports() {
      this.set('reportEmailSettings', null);
      return this.get('apiService').request({
        resource: _httpResources.myADT.getActivityReports
      }).then(({
        data
      }) => this.set('reportEmailSettings', data)).finally(data => {
        this.trigger('activityReportsComplete');
        return data;
      });
    },

    /**
     * Get's info about inactivity alert emails,
     * NOT the activity itself.
     * Pouplates `inactivityAlert`
     * @instance
     * @type {ServiceCall}
     * @emits inactivityAlertsComplete
     * @return {ApiResponse}
     */
    getInactivityAlerts() {
      return this.get('apiService').myADT.getInactivityAlerts().then(({
        data
      }) => {
        data.intervalInMonths = data.intervalInMonths.toString();
        return this.set('inactivityAlert', data);
      }).finally(data => {
        this.trigger('inactivityAlertsComplete');
        return data;
      });
    },

    /**
     * Saves settings for new activity report email;
     * calls {@linkcode Services/AlarmData~getActivityReports getActivityReports} before resolve
     * @instance
     * @type {ServiceCall}
     * @param {Object} report TODO: get structure
     * @param {Int} report.step // TODO: define
     * @param {Int[]} report.addressIds
     * @param {String[]} report.sendEmailOnDays
     * @param {String[]} report.email
     * @param {String} report.name
     * @param {String|Int} report.days string or Int-like string
     * @param {String[]} report.eventTypes notifications | exceptions | openClose | other
     * @param {String} report.searchBy locations; TODO: get other types
     * @param {Int[]} report.locationsSelected addressIds
     * @param {Int[]} report.locationGroupsSelected group IDs
     * @param {Boolean} report.acceptSiteAgreement
     * @param {Int} report.issueDuration milliseconds
     * @return {ApiResponse}
     */
    saveActivityReport(payload = {}) {
      const report = (0, _fp.omix)({
        acceptSiteAgreement: true,
        locationGroupsSelected: [],
        locationsSelected: payload.addressIds,
        searchBy: 'locations',
        step: 3
      }, payload);
      return this.get('apiService').myADT.saveActivityReport(report).then(({
        data
      }) => {
        this.sendAnalyticEvent(['_trackEvent', 'Business Reporting', 'Add Report Click', 'Business Report Added Success']);
        return data;
      }).then(() => this.getActivityReports()).catch(() => this.sendAnalyticEvent(['_trackEvent', 'Business Reporting', 'Add Report Click', 'Business Report Added Failed']));
    },

    /**
     * Deletes settings for activity report email
     * calls {@linkcode Services/AlarmData~getActivityReports getActivityReports} before resolve
     * @instance
     * @type {ServiceCall}
     * @param {Object} report
     * @param {String} report.id
     * @return {ApiResponse}
     */
    removeActivityReport(report) {
      return this.get('apiService').myADT.removeActivityReport({
        reportId: report.id
      }).then(() => this.getActivityReports());
    },

    updateActivityReport(report) {
      return this.get('apiService').myADT.updateActivityReport(report).then(() => this.getActivityReports()).then(() => this.sendAnalyticEvent(['_trackEvent', 'Business Reporting', 'Update Report Click', 'Business Report Update Success'])).catch(() => this.sendAnalyticEvent(['_trackEvent', 'Business Reporting', 'Update Report Click', 'Business Report Update Failed']));
    },

    /**
     * Creates inactivity alert email
     * calls {@linkcode Services/AlarmData~getActivityReports getInactivityAlerts} on resolve
     * @instance
     * @type {ServiceCall}
     * @param {Object} settings TODO: get structure
     * @return {ApiResponse}
     */
    addInactivityAlert(settings) {
      return this.get('apiService').myADT.addInactivityAlert(settings).then(() => this.getInactivityAlerts()).catch(errors => {
        const messages = errors.slice(0);

        if (typeof messages[0] === 'string' && messages[0] === 'The response was empty.') {
          console.error('Server return empty response for updating "Monitoring reports", it should be success action');
          this.getInactivityAlerts();
          return Promise.resolve();
        }

        throw errors;
      });
    },

    init() {
      this._super(...arguments);

      const legendKeyList = ['BA', 'CA', 'CC', 'CF', 'CL', 'CM', 'DE', 'EC', 'EO', 'FA', 'FC', 'FO', 'FR', 'FT', 'GT', 'HU', 'IC', 'IF', 'IN', 'LB', 'LC', 'LO', 'MA', 'OA', 'OP', 'RA', 'RE', 'SU', 'TR'];
      const legendKeyJSON = [];
      legendKeyList.forEach(legendKey => {
        legendKeyJSON.push({
          prefix: legendKey,
          desc: (0, _localization.toExpandedString)("AlarmHistory.legend.".concat(legendKey)),
          explanation: (0, _localization.toExpandedString)("AlarmHistory.legend.".concat(legendKey, ".description"))
        });
      });
      this.activityKeyData = legendKeyJSON;
    }

  });

  _exports.default = _default;
});