define("adt-wss/pods/dashboard/index/controller", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss-common/classes/status-message", "adt-wss/services/app-dispatcher"], function (_exports, _emberHelpers, _statusMessage, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ONLINE_CONTRACT_RENEWAL = 'ONLINE_CONTRACT_RENEWAL';

  var _default = Ember.Controller.extend({
    appointmentData: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    myadtSecurity: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    promotionData: Ember.inject.service(),
    systemInformation: Ember.inject.service(),
    contactData: Ember.inject.service(),
    dashboard: Ember.inject.controller('dashboard'),
    appDispatcher: Ember.inject.service(),
    appAlerts: Ember.inject.service(),
    flexfiData: Ember.inject.service(),
    billingData: Ember.inject.service(),
    canViewContacts: Ember.computed.not('currentLocation.permissions.hideContacts'),
    showAppointmentsTab: Ember.computed.readOnly('appointmentData.showAppointmentsTab'),
    isPreInstallCustomer: (0, _emberHelpers.computedApplyFunction)((appointmentData, isPreInstallCustomer) => (appointmentData || []).some(el => el.preInstallJob) && isPreInstallCustomer, 'appointmentData.appointmentList', 'currentLocation.profileInfo.isPreInstallCustomer'),
    showSweepstakeTab: (0, _emberHelpers.computedApplyFunction)((content, promotions) => content && content.length && (promotions || []).some(el => el.promotionType === 'SWEEPSTAKE'), 'promotionData.content', 'promotionData.promotions'),
    sweepStakeSubmitted: Ember.computed.readOnly('promotionData.sweepStakeSubmitted'),
    isCsr: Ember.computed.readOnly('authInfo.authInfo.csrUser'),
    hasPulse: Ember.computed('systemInformation', function () {
      const systemList = this.get('systemInformation.systemInfoList') || [];
      return systemList.some(s => s.pulse);
    }),
    emergencyContactsDisabled: (0, _emberHelpers.computedApplyFunction)((canViewContacts, picProvided, cyberOnly, adtGoOnly) => [!canViewContacts, !picProvided, cyberOnly, adtGoOnly].some(Boolean), 'canViewContacts', 'myadtSecurity.picProvided', 'currentLocation.cyberOnly', 'currentLocation.adtGoOnly'),
    contractRenewalPromotion: (0, _emberHelpers.computedApplyFunction)(promotions => (promotions || []).find(p => p.promotionType.includes(ONLINE_CONTRACT_RENEWAL)), 'promotionData.promotions'),
    contractRenewalAlertVisible: (0, _emberHelpers.computedApplyFunction)(promotions => {
      const contractRenewalClosed = sessionStorage.getItem('contractRenewalClosed');
      return (promotions || []).some(el => el.promotionType.includes(ONLINE_CONTRACT_RENEWAL) && el.data && !contractRenewalClosed);
    }, 'promotionData.promotions'),
    statusMessages: (0, _emberHelpers.computedApplyFunction)((newRegistration, phonesDuplication, emergencyContactsDisabled, sweepstakesModalDrawingTime) => {
      const entries = [[newRegistration, new _statusMessage.default('Registration Successful!', 'success', true)], [phonesDuplication && !emergencyContactsDisabled, new _statusMessage.default({
        head: 'We Found Duplicate Phone Numbers',
        text: 'Please remove duplicates within your emergency contacts.'
      }, {
        type: 'warn',
        static: true
      }).addButton({
        text: 'Remove Now',
        route: 'dashboard.account.contacts'
      })], [!!sweepstakesModalDrawingTime, new _statusMessage.default({
        head: "You're entered.",
        text: "Official drawing on ".concat(sweepstakesModalDrawingTime)
      }, {
        type: 'success'
      })]];
      return entries.filter(e => e[0]).map(e => e[1]);
    }, 'authInfo.newRegistration', 'contactData.phonesDuplication', 'emergencyContactsDisabled', 'dashboard.sweepstakesModalDrawingTime'),

    showStatusMessage(...params) {
      const statusMessages = this.get('statusMessages') || [];
      statusMessages.push(new _statusMessage.default(...params));
      this.set('statusMessages', statusMessages);
    },

    closePromoMessage({
      requireRequest,
      promotionType
    } = {
      requireRequest: true
    }) {
      sessionStorage.setItem('contractRenewalClosed', true);
      this.set('contractRenewalAlertVisible', false);

      if (requireRequest) {
        this.get('promotionData').incrementDispositionCounter(promotionType);
      }
    },

    updateSweepstakesDrawingTime({
      drawDate
    } = {
      drawDate: false
    }) {
      this.set('dashboard.sweepstakesModalDrawingTime', drawDate);
    },

    showNotificationMessage() {
      this.appAlerts.getPromotionData();
    },

    salesForceBilled: (0, _emberHelpers.computedApplyFunction)(function (bs) {
      if (bs === 'SalesForce') {
        return true;
      }

      return false;
    }, 'billingData.billingSystem'),
    salesForceToken: Ember.computed.readOnly('billingData.billingModel.paymentStatus.SalesForceToken'),

    init(...args) {
      this._super(...args);

      this.appDispatcher.subscribe(_appDispatcher.HIDE_CONTRACT_RENEWAL_ALERT, this, 'closePromoMessage');
      this.appDispatcher.subscribe(_appDispatcher.SET_SWEEPSTAKES_MODAL_DRAWING_TIME, this, 'updateSweepstakesDrawingTime');
      this.appDispatcher.subscribe(_appDispatcher.SHOW_POST_SUBMISSION_NOTIFICATION_MASSAGE, this, 'showNotificationMessage');
    },

    deactivate() {
      this.appDispatcher.unsubscribe(_appDispatcher.HIDE_CONTRACT_RENEWAL_ALERT, this, 'closePromoMessage');
      this.appDispatcher.unsubscribe(_appDispatcher.SET_SWEEPSTAKES_MODAL_DRAWING_TIME, this, 'updateSweepstakesDrawingTime');
      this.appDispatcher.subscribe(_appDispatcher.SHOW_POST_SUBMISSION_NOTIFICATION_MASSAGE, this, 'showNotificationMessage');
    },

    actions: {
      showStatusMessage(message, type) {
        this.showStatusMessage(message, type);
      },

      closePromoMessage(...args) {
        this.closePromoMessage(...args);
      },

      sendContractRenewalEventTag(...params) {
        return this.promotionData.sendEventTag(...params);
      }

    }
  });

  _exports.default = _default;
});