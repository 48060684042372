define("adt-wss/templates/modal-windows/validate-pic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cz8NrcDd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[0,\"\\n\\tThis is the unique word that you would provide to a Customer Care\\n\\tRepresentative during an alarm event. This isn't necessarily\\n\\tthe touchpad code that you enter to arm or disarm the ADT system.\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form myadt-form\"],[8],[0,\"\\n\\t\"],[1,[28,\"widget/input-wrapper\",null,[[\"label\",\"inputId\",\"inputClass\",\"inputVal\",\"onEnter\",\"error\"],[\"Verbal Security Password\",\"picValidation\",\"pic-validation\",[24,[\"body\",\"pic\"]],[28,\"action\",[[23,0,[]],[24,[\"onEnter\"]]],null],[24,[\"body\",\"error\"]]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/validate-pic.hbs"
    }
  });

  _exports.default = _default;
});