define("adt-wss/pods/components/dashboard/account/enroll-paperless-statement/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/classes/status-message", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/common-fsm-state", "adt-wss/pods/components/dashboard/account/enroll-paperless-statement/constants"], function (_exports, _withFsm, _statusMessage, _emberHelpers, _commonFsmState, constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CURRENT_STATE = 'currentState';
  const {
    IDLE_STATE,
    ENROLLED_STATE,
    PRE_UNENROLL_STATE,
    PRE_ENROLL_STATE,
    SYSTEM_PENDING_ENROLLING_STATE,
    UNENROLLING_STATE,
    TRANSITION_TO_PRE_ENROLL_ACTION,
    TRANSITION_TO_PRE_UNENROLL_ACTION,
    START_ENROLLING_ACTION,
    SUCCESSFUL_ENROLL_ACTION,
    FAILURE_ENROLL_ACTION,
    BACK_TO_ENROLLED_STATE_ACTION,
    START_UNENROLLING_ACTION,
    SUCCESSFUL_UNENROLL_ACTION,
    FAILURE_UNENROLL_ACTION,
    ENROLLING_STATE,
    SUCCESSFUL_LOADING_ACTION,
    FAILURE_LOADING_ACTION,
    CANCEL_PRE_ENROLL_ACTION
  } = constants;
  const fsmModel = {
    transitions: {
      [IDLE_STATE]: {
        [TRANSITION_TO_PRE_ENROLL_ACTION]: PRE_ENROLL_STATE,
        [_commonFsmState.FETCH_DATA_ACTION]: _commonFsmState.LOADING_STATE
      },
      [_commonFsmState.LOADING_STATE]: {
        [SUCCESSFUL_LOADING_ACTION]: IDLE_STATE,
        [FAILURE_LOADING_ACTION]: IDLE_STATE
      },
      [PRE_ENROLL_STATE]: {
        [START_ENROLLING_ACTION]: ENROLLING_STATE,
        [CANCEL_PRE_ENROLL_ACTION]: IDLE_STATE
      },
      [ENROLLING_STATE]: {
        [SUCCESSFUL_ENROLL_ACTION]: IDLE_STATE,
        [FAILURE_ENROLL_ACTION]: IDLE_STATE
      },
      [ENROLLED_STATE]: {
        [TRANSITION_TO_PRE_UNENROLL_ACTION]: PRE_UNENROLL_STATE
      },
      [PRE_UNENROLL_STATE]: {
        [START_UNENROLLING_ACTION]: UNENROLLING_STATE,
        [BACK_TO_ENROLLED_STATE_ACTION]: ENROLLED_STATE
      },
      [UNENROLLING_STATE]: {
        [SUCCESSFUL_UNENROLL_ACTION]: IDLE_STATE,
        [FAILURE_UNENROLL_ACTION]: ENROLLED_STATE
      }
    }
  };

  const detectInitialState = (enrolled, pending) => Object.entries({
    [ENROLLED_STATE]: enrolled && !pending,
    [SYSTEM_PENDING_ENROLLING_STATE]: pending,
    [IDLE_STATE]: true
  }).find(i => i[1])[0];

  const defaultProps = {
    updatePaperlessOption() {
      console.warn('Please pass updatePaperlessOption method to dashboard/account/enroll-paperless-statement component');
    }

  };
  /**
   * @module Components/Dashboard/Account/EnrollPaperlessStatement
   * @prop {Boolean} enrolled
   * @prop {Function} updatePaperlessOption
   * @prop {String} systemPendingStatus
   * @prop {String} email
   * @example
   * {@lang xml} {{ dashboard/account/enroll-paperless-statement
  		updatePaperlessOption=(action 'update')
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    constants,
    fsmModel,
    contactData: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    accountId: Ember.computed.readOnly('currentLocation.profileInfo.accountId'),
    classNames: ['enroll-paperless-statement'],
    mwd: Ember.inject.service('modal-window-dispatcher'),
    emailsList: (0, _emberHelpers.computedApplyFunction)((emails = []) => emails.join(', '), 'emails'),
    initAction: _commonFsmState.FETCH_DATA_ACTION,
    updating: (0, _emberHelpers.computedSome)(CURRENT_STATE, UNENROLLING_STATE, ENROLLING_STATE),
    IS_IDLE_STATE: Ember.computed.equal(CURRENT_STATE, IDLE_STATE),
    IS_ENROLLED_STATE: Ember.computed.equal(CURRENT_STATE, ENROLLED_STATE),
    IS_PRE_UNENROLL_STATE: Ember.computed.equal(CURRENT_STATE, PRE_UNENROLL_STATE),
    IS_PENDING_ENROLLING_STATE: Ember.computed.equal(CURRENT_STATE, SYSTEM_PENDING_ENROLLING_STATE),
    IS_UNENROLLING_STATE: Ember.computed.equal(CURRENT_STATE, UNENROLLING_STATE),
    pendingStatusMessages: (0, _emberHelpers.computedApplyFunction)((emails, s) => {
      const pendingDelete = s === 'D';
      return [new _statusMessage.default("".concat(emails, " has been ").concat(pendingDelete ? 'deleted' : 'added', " (Pending)"), {
        type: 'success',
        static: true
      })];
    }, 'emailsList', 'systemPendingStatus'),

    [TRANSITION_TO_PRE_ENROLL_ACTION]() {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      mwd.show({
        title: 'Enroll in Paperless Billing?',
        name: 'enroll-paperless-statements',
        body: this,
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: () => this.closeModal(CANCEL_PRE_ENROLL_ACTION)
        }, {
          caption: 'Yes, enroll',
          callback: () => fsm.dispatch(START_ENROLLING_ACTION)
        }]
      });
    },

    [_commonFsmState.FETCH_DATA_ACTION]() {
      const fsm = this.get('fsm');
      return this.deliverData('contactData.pmocData', contactData => contactData.getPMOC()).then(({
        primary
      }) => {
        const {
          enrolled,
          pending
        } = this.get('contactData.paperlessOption');
        return {
          enrolled,
          emails: primary.filter(el => el.pending !== 'D').map(el => el.email),
          systemPendingStatus: pending
        };
      }).then(data => fsm.dispatch(SUCCESSFUL_LOADING_ACTION, data)).catch(e => fsm.dispatch(FAILURE_LOADING_ACTION, e));
    },

    [SUCCESSFUL_LOADING_ACTION](data) {
      const {
        enrolled,
        systemPendingStatus
      } = data;
      this.setProperties(data);
      this.set(CURRENT_STATE, detectInitialState(enrolled, systemPendingStatus));
    },

    [FAILURE_LOADING_ACTION](err) {
      console.log('Loading failed', err);
    },

    [START_ENROLLING_ACTION]() {
      const fsm = this.get('fsm');
      this.closeModal();
      return this.updatePaperlessOption({
        enrolled: true
      }).then(() => fsm.dispatch(SUCCESSFUL_ENROLL_ACTION)).then(() => fsm.dispatch(_commonFsmState.FETCH_DATA_ACTION)).catch(err => fsm.dispatch(FAILURE_UNENROLL_ACTION, err));
    },

    [START_UNENROLLING_ACTION]() {
      const fsm = this.get('fsm');
      this.closeModal();
      return this.updatePaperlessOption({
        enrolled: false
      }).then(() => fsm.dispatch(SUCCESSFUL_UNENROLL_ACTION)).then(() => fsm.dispatch(_commonFsmState.FETCH_DATA_ACTION)).catch(() => fsm.dispatch(FAILURE_ENROLL_ACTION));
    },

    [TRANSITION_TO_PRE_UNENROLL_ACTION]() {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      mwd.show({
        title: 'Opt Out of Paperless Billing?',
        name: 'unenroll-paperless-statements',
        body: this,
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: () => this.closeModal(BACK_TO_ENROLLED_STATE_ACTION)
        }, {
          caption: 'Yes, Receive Paper Statements',
          callback: () => fsm.dispatch(START_UNENROLLING_ACTION)
        }]
      });
    },

    closeModal(actionType) {
      const {
        fsm,
        mwd
      } = this.getProperties('fsm', 'mwd');
      mwd.hide();

      if (actionType) {
        fsm.dispatch(actionType);
      }
    }

  });

  _exports.default = _default;
});