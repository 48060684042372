define("adt-wss/pods/dashboard/shop/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentLocation: Ember.inject.service(),
    csrInfo: Ember.inject.service(),

    afterModel() {
      const orderEquipment = this.get('currentLocation.permissions.orderEquipment');

      if (!orderEquipment && !this.get('csrInfo.isCsr') || this.get('currentLocation.noSystems')) {
        this.transitionTo('dashboard');
      }
    }

  });

  _exports.default = _default;
});