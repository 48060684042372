define("adt-wss/pods/dashboard/account/payments/onetimepayment/controller", ["exports", "adt-wss/pods/dashboard/account/payments/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    queryParams: ['flexfi'],
    flexfi: false
  });

  _exports.default = _default;
});