define("adt-wss/services/paymentech", ["exports", "adt-wss/config/environment", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/fp", "adt-wss-common/utils/localization", "adt-wss/services/app-dispatcher"], function (_exports, _environment, _myadtHelpers, _fp, _localization, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getOrigin = () => _myadtHelpers.default.getOrigin('https://dev2.myadt.com');

  const paymentFlows = {
    EASYPAY_NEW: 'EASYPAY_NEW',
    EASYPAY_SWITCH: 'EASYPAY_SWITCH',
    ONETIME_PAY: 'ONETIME_PAY',
    EXP_PAY: 'EXP_PAY',
    EXP_PAY_OEP: 'EXP_PAY_OEP'
  };
  const config = {
    origin: getOrigin(),
    domNodeSelector: '#hpfIframeContainer',
    callback_url: "".concat(getOrigin(), "/hpfcallback.html"),
    callbackHTML: "".concat(getOrigin(), "/hpfcallback.html"),
    hpfHostUrl: _environment.default.hpfHostUrl,
    iFrameOnload: ''
  };
  /**
   * Chase Paymentech Service
   * @module Services/Paymentech
   *
   * @prop {String} origin 'http://www.myadt.com', uses window.location.origin
   * @prop {Object} config Paymentech config object
   * @prop {String} config.domNodeSelector  Where in the DOM will the HPF iFrame be inserted? I.e. what will be the HPF's container?
   * @prop {String} config.apiUrl The URL to the API call that will provide several variables necessary to construct the iFrame URL (e.x. hostedSecureID) so that these values can be configurable on the server as a JNDI variable.
   * @prop {String} config.contractUrl JSON Contract File to provide the structure of the apiUrl configuration variable.
   * @prop {String} config.hpfHostUrl The Host for the HPF iFrame URL
   * @prop {String} config.iFrameOnload Code to execute when the HPF iFrame has finished loading (successfully or not). This allows for a spinner to be put in place until loading is complete.
   * @prop {String} origin adt-wss rootURL
   */

  var _default = Ember.Service.extend(Ember.Evented, {
    apiService: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    config,
    paymentFlows,
    flowId: null,
    tokenId: null,

    /**
     * Initializes Paymentech service.
     * Success of {@link module:app/services/paymentech~getHpfPaymentConfig getHpfPaymentConfig} will update
     * `config` from Paymentech servers. However, this method always resolves,
     * regardless of the result and emits the `paymentechReady` event
     * @instance
     * @param {Object} options will extend `config`
     * @return {Promise}
     */
    start(opts) {
      let conf = this.get('config');
      const options = opts || {};
      conf = (0, _fp.omix)(conf, options);
      return this.getHpfPaymentConfig(options.flowId, {
        isPS: opts.isPS
      }).then(({
        data
      }) => {
        if (_environment.default.useProxyUrlForPaymentIframe) {
          conf.hpfHostUrl = "".concat(_environment.default.proxyUrlForPaymentIframe, "?frameURL=").concat(data.hpfHostUrl);
        } else {
          conf.hpfHostUrl = data.hpfHostUrl;
        }

        conf.uID = data.uID;
        this.set('config', conf);
        this.trigger('paymentechReady');
        return conf;
      });
    },

    getOrigin,

    /**
     * Retrieves Paymentech UID from MyADT back end
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getHpfPaymentConfig(flowId, {
      isPS
    }) {
      this.set('flowId', flowId);
      const endpoint = isPS ? 'getPSHpfPaymentConfig' : 'getHpfPaymentConfig';
      return this.get('apiService').myADT[endpoint]({
        flowId
      });
    },

    /**
     * Invert an object's property keys and values
     * This method only works for shallow objects (1 level).
     * @param {Object} obj
     * @example { "key1": "value1" } => { "value1": "key1" }
     */
    invertObject(obj) {
      const res = {};
      Object.keys(obj).forEach(k => {
        res[obj[k]] = k;
      });
      return res;
    },

    /**
     * Takes an error code array from paymentech (or MyADT) and converts it to an
     * array of error codes that are (hopefully) defined in our localization.json file :)
     * This method will likely be used in the application/s, not inside this "class".
     * @param {String[]} errorCode
     * @return {String[]}
     */
    preparePaymentechErrorMessages(errorCode) {
      const messageList = [];
      const genericError = 'Generic.error';
      let genErrEncountered = false; // if the errorCode parameter is an array and is not an empty array, then...

      if (Array.isArray(errorCode) && errorCode.length > 0) {
        errorCode.forEach(err => {
          // don't generic error codes with the Paymentech namespace
          // if we encounter a generic error for the first time...
          if (genericError === err && !genErrEncountered) {
            // Push the generic error message (not error code) to the messageList array.
            messageList.push((0, _localization.toExpandedString)(err));
            genErrEncountered = true;
            return;
          }

          const item = (0, _localization.toExpandedString)("Paymentech.Errors.".concat(err.toString())); // If the message does not already exist in our messageList array, then push it to the messageList

          if (messageList.indexOf(item) === -1) {
            messageList.push(item);
          }
        }); // else, return the generic error message
      } else {
        messageList.push((0, _localization.toExpandedString)(genericError));
      }

      return messageList;
    },

    /**
     * Sets config defaults to avoid leaking state, and also sets
     * a number of globals for Paymentech callback availability
     */
    init() {
      this._super(...arguments); // === Global Variables "Useful" for Paymentech ( helps to set context for the global functions below ) === //
      //   The hpfContext variable is useful when there may be two Paymentech HPF forms on the page.
      //   Take EasyPay for example. One page dynamically allows the customer to sign up a new card for
      //   EasyPay and also Manage Credit Card Info for EasyPay. This means that when a signal is
      //   dispatched, it may be handled by two HPF instances, instead of only the instance that it was
      //   intended for. The application should set the context when the iFrame is injected, and also
      //   reset it when "" is called at the application level.
      //   Note: Use of the HPF Context is not necessary (or necessarily helpful) when dealing with only
      //   one Payment HPF form on a page. If, to get to another HPF form, you need to navigate to a
      //   completely separate page (full HTTP/S request/reload/refresh), then you likely do not need
      //   to use this, or it's associated global functions.


      window.hpfContext = ''; // === Global Functions Necessary for Paymentech ( unavoidable due to Paymentech implementation :) ) === //
      // HPF will invoke these callbacks, which will trigger an event to be handled by the component/controller

      /**
       * HPF callback will invoke this function when a transaction error occurs.
       * @param {String} errorCode pipe-delimited
       */

      window.creHandleErrors = (errorCode, isValidation) => {
        // convert pipe-delimited string to array
        if (errorCode && errorCode.length > 0) {
          errorCode = errorCode.replace(/\|$/, '').split('|');
        }

        window.top.postMessage({
          'hpfError': errorCode.join()
        }, '*');

        try {
          // Dispatch the error data to be handled by the application/s
          if (isValidation) this.get('appDispatcher').sendEvent(_appDispatcher.CRE_VALIDATION_ERROR_EVENT, errorCode, this);else this.get('appDispatcher').sendEvent(_appDispatcher.CRE_ERROR_EVENT, errorCode, this);
        } catch (e) {
          console.error(e);
        }

        console.log(errorCode, isValidation ? 'isValidation' : 'isNotValidation');
      };
      /**
       * HPF callback will invoke this function (optionally) for transaction errors when more detailed information is required for debugging.
       * See the Integration Guide for more information.
       * @param {String} errorCode pipe-delimited
       * @param {String} gatewayCode
       * @param {String} gatewayMessage
       */


      window.creHandleDetailErrors = (errorCode, gatewayCode, gatewayMessage, isValidation) => {
        // convert pipe-delimited string to array
        if (errorCode && errorCode.length > 0) {
          errorCode = errorCode.replace(/\|$/, '').split('|');
        }

        window.top.postMessage({
          'hpfError': errorCode.join()
        }, '*');

        try {
          // Dispatch the error data to be handled by the application/s.
          if (isValidation) this.get('appDispatcher').sendEvent(_appDispatcher.CRE_VALIDATION_ERROR_EVENT, errorCode, this);else this.get('appDispatcher').sendEvent(_appDispatcher.CRE_ERROR_EVENT, errorCode, this);
        } catch (e) {
          console.error(e);
        }

        console.log(errorCode, gatewayCode, gatewayMessage, isValidation ? 'isValidation' : 'isNotValidation');
      };
      /**
       * HPF callback will invoke this function on successful transaction.
       * See the Integration Guide for more information.
       * @param {Object} transaction
       * @param {String} transaction.code
       * @param {String} transaction.message
       * @param {String} transaction.uID
       * @param {String} transaction.rurl
       */


      window.completeCREPayment = transaction => {
        const out = {}; // Log the transaction object response from Paymentech to the console, if defined.

        if (transaction) {
          Object.keys(transaction).forEach(key => {
            out[key] = decodeURI(transaction[key]);
          });
        }

        window.top.postMessage({
          'hpfData': JSON.stringify(out)
        }, '*'); // Dispatch the transaction data to be handled by the application/s.

        this.get('appDispatcher').sendEvent(_appDispatcher.CRE_COMPLETE_PAYMENT_EVENT, out, this);
      };
      /**
       * HPF callback will invoke this function on successful EasyPay validation.
       * See the Integration Guide for more information.
       * @param {Object} transaction
       * @param {String} transaction.code
       * @param {String} transaction.message
       * @param {String} transaction.uID
       * @param {String} transaction.rurl
       */


      window.completeCREValidation = transaction => {
        const out = {}; // Log the transaction object response from Paymentech to the console, if defined.

        if (transaction) {
          Object.keys(transaction).forEach(key => {
            out[key] = decodeURI(transaction[key]);
          });
        }

        window.top.postMessage({
          'hpfData': JSON.stringify(out)
        }, '*'); // Dispatch the transaction data to be handled by the application/s.

        this.get('appDispatcher').sendEvent(_appDispatcher.CRE_COMPLETE_VALIDATION_EVENT, out, this);
      };

      window.setHpfContext = value => {
        window.hpfContext = value;
        this.set('currentHpfContext', value);
      };

      window.getHpfContext = () => this.get('currentHpfContext'); // Reset the Paymentech HPF Context to an empty string


      window.resetHpfContext = () => {
        window.setHpfContext('');
        this.set('currentHpfContext', '');
      };
    }

  });

  _exports.default = _default;
});