define("adt-wss/pods/dashboard/account/payments/savedpayments/route", ["exports", "adt-wss/pods/dashboard/account/payments/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    scrollToModule: _route.scrollToModule,

    afterModel() {
      this.scrollToModule();
    }

  });

  _exports.default = _default;
});