define("adt-wss/pods/components/dashboard/manage/enroll-business-reporting/component", ["exports", "adt-wss-common/utils/common-fsm-state", "adt-wss-common/utils/ember-helpers", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/myadt-helpers", "adt-wss/pods/components/dashboard/manage/enroll-business-reporting/constants"], function (_exports, _commonFsmState, _emberHelpers, _withFsm, _myadtHelpers, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    /**
     * Notify status of operation
     * @prop {String} message
     * @prop {String} type
     * @return {void}
     */

    /* eslint-disable no-unused-vars */
    notify: (message, type) => console.error('Please pass the "notify" param to "dashboard/manage/enroll-business-reporting" component')
    /* eslint-enable no-unused-vars */

  };
  const fsmModel = {
    transitions: {
      [_commonFsmState.IDLE_STATE]: {
        [_constants.OPEN_MODAL_WINDOW_ACTION]: _constants.MODAL_WINDOW_STATE
      },
      [_constants.MODAL_WINDOW_STATE]: {
        [_constants.UPDATE_DATA_ACTION]: _constants.UPDATING_STATE,
        [_constants.CLOSE_MODAL_WINDOW_ACTION]: _commonFsmState.IDLE_STATE
      },
      [_constants.UPDATING_STATE]: {
        [_commonFsmState.SUCCESS_ACTION]: _constants.ENROLLED_STATE,
        [_commonFsmState.FAILURE_ACTION]: _commonFsmState.IDLE_STATE
      }
    }
  };
  /**
   * @module Components/Dashboard/Manage/EnrollBusinessReporting
   * @prop {Function} notify - Method which is used for showing status messages.
   * @example
   * {@lang xml} {{dashboard/manage/enroll-business-reporting
  		notify=(action 'setMessages')
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    orderData: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    fsmModel,
    agreement: false,
    currentState: _commonFsmState.IDLE_STATE,
    IS_MODAL_WINDOW_STATE: (0, _emberHelpers.isState)(_constants.MODAL_WINDOW_STATE),
    IS_UPDATING_STATE: (0, _emberHelpers.isState)(_constants.UPDATING_STATE),

    /**
     * Business reporting enroll
     * Calls '/api/order/dsEnroll'
     *
     * @function
     * @returns {Promise}
     */
    [_constants.UPDATE_DATA_ACTION]() {
      return this.commonDispatcher(() => this.get('orderData').businessReportingEnroll());
    },

    /**
     * Handler for successful updating.
     * Calls `CLOSE_MODAL_WINDOW` method in order to close the modal window
     * Calls `sendAnalyticEvent` function in order to send GA event
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     */
    [_commonFsmState.SUCCESS_ACTION]() {
      this.notify(_constants.SUCCESSFUL_MESSAGE, 'success');

      this[_constants.CLOSE_MODAL_WINDOW_ACTION]();

      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Business Reporting', 'Enroll Click', 'Business Reporting Enroll Success']);
    },

    /**
     * Handler for failed updating.
     * Calls `this.notify` method in order to show a message
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     * @param {Classes/AjaxResponse} results - response from BE
     * @param {Classes/AjaxResponse}.errors - object with errors
     */
    [_commonFsmState.FAILURE_ACTION](results = {}) {
      const [error] = (0, _myadtHelpers.genericErrorHandler)(results);
      this.notify(error, 'error');

      this[_constants.CLOSE_MODAL_WINDOW_ACTION]();

      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Business Reporting', 'Add Report Click', 'Business Reporting Enroll Failed']);
    },

    /**
     * Shows modal window
     * Calls `mwd.show` method in order to show a modal window
     * Triggered on template
     *
     * @function
     */
    [_constants.OPEN_MODAL_WINDOW_ACTION]() {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');

      const closeModal = () => fsm.dispatch(_constants.CLOSE_MODAL_WINDOW_ACTION);

      mwd.show({
        title: 'Enroll in Business Reporting',
        name: 'enroll-business-reporting',
        body: this,
        onClose: closeModal,
        isFooterLine: false,
        actions: [{
          caption: 'Go back',
          stl: 'outline',
          callback: closeModal
        }, {
          caption: 'Enroll',
          name: 'enroll',
          disabled: (0, _emberHelpers.computedApplyFunction)((a, b) => !a || b, 'agreement', 'IS_UPDATING_STATE'),
          callback: () => fsm.dispatch(_constants.UPDATE_DATA_ACTION)
        }]
      });
    },

    /**
     * Closes modal window
     * Calls `mwd.hide` method in order to close a modal window
     * Sets `agreement` to `false`
     * Triggered on `OPEN_MODAL_WINDOW_ACTION`, `FAILURE_ACTION`, `SUCCESS_ACTION`.
     *
     * @function
     */
    [_constants.CLOSE_MODAL_WINDOW_ACTION]() {
      this.get('mwd').hide();
      this.set('agreement', false);
    }

  });

  _exports.default = _default;
});