define("adt-wss/pods/components/dashboard/account/one-time-payment/payment-messages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b7Zz35fA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"payment-messages\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"payment-info message\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"indicator\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"payment-box\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"payment-box-title\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\",true],[8],[0,\"Avoid being late. Set up automatic payments with EasyPay.\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"payment-box-links\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"a\",false],[12,\"href\",\"\"],[3,\"action\",[[23,0,[]],\"showEasyPayEnrollModal\"]],[8],[7,\"span\",true],[8],[0,\"Enroll in EasyPay\"],[9],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"divider\"],[8],[0,\"Ι\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"a\",false],[12,\"href\",\"\"],[3,\"action\",[[23,0,[]],\"remindMeLater\"]],[8],[7,\"span\",true],[8],[0,\"Remind me later\"],[9],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/account/one-time-payment/payment-messages/template.hbs"
    }
  });

  _exports.default = _default;
});