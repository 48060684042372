define("adt-wss/pods/components/pypestream-layout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lSK0hq3P",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showPSChat\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",false],[12,\"id\",\"launch-chat\"],[12,\"class\",[29,[\"chatbotButton\",[28,\"if\",[[24,[\"showChatWindow\"]],\" hidden\"],null]]]],[3,\"on\",[\"click\",[23,0,[\"launchButton\"]]]],[8],[0,\"\\n\\t\\t\"],[7,\"img\",true],[10,\"alt\",\"Chat button\"],[11,\"src\",[29,[[22,\"rootURL\"],\"images/v2/logos/live-chat-face.png\"]]],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"id\",\"chat-container\"],[11,\"class\",[29,[[28,\"if\",[[28,\"and\",[[24,[\"showPSChat\"]],[24,[\"showChatWindow\"]]],null],\"open\"],null]]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/pypestream-layout/template.hbs"
    }
  });

  _exports.default = _default;
});