define("adt-wss/services/system-test", ["exports", "adt-wss-common/utils/fp", "adt-wss-common/utils/myadt-helpers"], function (_exports, _fp, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.makeSystemData = void 0;

  const makeSystemData = (system, full) => Ember.getProperties(system, ['systemNo', 'siteNo', 'seqNo', 'secondarySystemNo', 'secondarySeqNo', 'secondaryCsNo'].concat(full ? ['extendHrs', 'localOffset', 'expiration', 'localTimezoneOffsetFromSite'] : []));

  _exports.makeSystemData = makeSystemData;

  var _default = Ember.Service.extend({
    apiService: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    systemInformation: Ember.inject.service(),
    mmbIncidents: Ember.inject.service(),

    /**
     * Creates MMB Issue
     * @param {String} code transaction type
     * @param {String} comment comment text
     * @return {Promise}
     */
    createIncident(code, comment) {
      return this.get('mmbIncidents').createIncidentOrIssue({
        customerNo: this.get('currentLocation.profileInfo.accountId'),
        siteNo: 0,
        duration: Date.now() - this.get('pageStartTime'),
        transactionType: code,
        jobComments: comment
      }, true);
    },

    /**
     * @instance
     * @type {ServiceCall}
     * @param {Object} sys
     * @return {ApiResponse}
     */
    updateSystemTest(sys) {
      const system = makeSystemData(sys, true);
      return this.get('apiService').myADT.updateSystemTest((0, _fp.omix)(system, {
        onlyModify: true,
        expirationString: sys.testExpiresAtStr
      })).then(({
        data
      }) => {
        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'ExtendSystemTest', "SystemNo: ".concat(sys.systemNo)]);

        this.createIncident('UTST', "Updated system on test for ".concat(sys.csNo));
        return data;
      }).finally(() => this.get('systemInformation').getSystemInfoList());
    },

    /**
     * @instance
     * @type {ServiceCall}
     * @param {Object} data
     * @return {ApiResponse}
     */
    clearSystemTest(sys) {
      const system = makeSystemData(sys, true);
      return this.get('apiService').myADT.clearSystemTest(system).then(({
        data
      }) => {
        this.createIncident('ETST', "Ended system test for ".concat(sys.csNo));
        return data;
      }).finally(() => this.get('systemInformation').getSystemInfoList());
    },

    /**
     * @instance
     * @type {ServiceCall}
     * @param {Object} data
     * @param {String} data.extendHrs
     * @param {String} data.systemNo
     * @param {String} data.siteNo
     * @param {String} data.seqNo
     * @param {String} data.secondarySystemNo
     * @param {String} data.secondarySeqNo
     * @return {ApiResponse}
     */
    putSystemOnTest(sys) {
      const dataAPI = makeSystemData(sys, true);
      return this.get('apiService').myADT.putSystemOnTest((0, _fp.omix)(dataAPI, {
        extendHrs: sys.extendHrs
      })).then(({
        data
      }) => {
        this.createIncident('PTST', "Placed system on full test for ".concat(sys.csNo));
        return data;
      }).finally(() => this.get('systemInformation').getSystemInfoList());
    }

  });

  _exports.default = _default;
});