define("adt-wss/pods/components/stupid/inline-errors-messages/component", ["exports", "adt-wss-common/mixins/with-fsm"], function (_exports, _withFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    messages: []
  };
  /**
   * @module Components/Stupid/InlineErrorsMessages
   * @prop {String[]} messages
   * @example
   * {@lang xml} {{stupid/inline-errors-messages messages=['Foo', 'Bar']}}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    classNames: ['inline-errors-messages']
  });

  _exports.default = _default;
});