define("adt-wss/pods/components/dashboard/account/flexfi-payment-selection/component", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/fp", "adt-wss/pods/components/stupid/amount-input/component"], function (_exports, _emberHelpers, _fp, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    list: [],
    setFlexFiPlan: () => true
  };
  /**
   * @module Components/Dashboard/Account/FlexFiPaymentSelection
   * @prop {Object[]} list - list of options
   * @prop {Date} paymentDate
   * @prop {String} paymentAmount
   * @prop {Boolean} visible
   * @prop {Object} errors
   * @prop {Function} onError
   * @prop {Function} setFlexFiPlan
   * @example
   * {@lang xml} {{ dashboard/account/flexfi-payment-selection
  		paymentAmount=body.data.amount
  		paymentDate=body.data.paymentDate
  		list=body.installmentPlanInfo
  		setFlexFiPlan=body.setFlexFiPlan
  		visible=body.isFlexFiPaymentSelectionState
  		errors=body.errors
  		onError=body.disableNextButton
  }}
   */

  var _default = Ember.Component.extend(defaultProps, {
    classNames: ['flexfi-payment-selection'],
    classNameBindings: ['visible'],
    selectionAbility: (0, _emberHelpers.computedApplyFunction)(list => list.length > 1, 'list'),
    defaultAmount: '0.00',
    dynamicError: null,
    minAmount: 5,
    options: (0, _emberHelpers.computedApplyFunction)((list, amount) => list.map(o => (0, _fp.omix)(o, {
      amount
    })), 'list', 'defaultAmount', 'selectedOptionId' // It's not redundant dep., the list should be recomputed if a selected option has been changed
    ),
    selectedOptionId: (0, _emberHelpers.computedApplyFunction)((selectionAbility, list) => !selectionAbility && list.length ? list[0].noteNo : null, 'selectionAbility', 'list'),

    /**
     * Validates `amount` based on minimal amount and remaining balance
     * Sets errors
     *
     * @param {String|Number} amount
     * @param {String|Number} remainingBalance
     * @param {Object} autoPayDetails
     *
     * @function
     */
    validateAmount(amount, remainingBalance, autoPayDetails) {
      const parsedAmount = parseFloat(amount);
      const parsedRemainingBalance = parseFloat(remainingBalance);
      let dynamicError = null;

      if (!autoPayDetails) {
        dynamicError = "Save your payment information for your next Monthly Loan Installment";
      } else if (parsedAmount < this.minAmount) {
        dynamicError = "Payment amount cannot be less than $".concat(this.minAmount.toFixed(2));
      } else if (parsedAmount > parsedRemainingBalance) {
        dynamicError = "Payment amount cannot be greater than $".concat(remainingBalance);
      }

      this.onError(Boolean(dynamicError));
      this.set('dynamicError', dynamicError);
    },

    actions: {
      /**
       * Selects an option
       *
       * @param {String} noteNo
       *
       * @function
       * @returns {String} selected note number
       */
      selectOption(noteNo) {
        if (!this.get('selectionAbility')) {
          return false;
        }

        this.set('dynamicError', null);
        return this.set('selectedOptionId', noteNo);
      },

      /**
       * Sets FlexFi payment amount
       *
       * @param {String|Number} amount
       * @param {String} noteNo
       * @param {String|Number} remainingBalance
       * @param {Object} autoPayDetails
       *
       * @function
       */
      setFlexfiPaymentOption(amount, noteNo, remainingBalance, autoPayDetails) {
        if (noteNo !== this.get('selectedOptionId')) {
          this.actions.selectOption.call(this, noteNo);
        }

        this.validateAmount(amount, remainingBalance, autoPayDetails);
        this.setFlexFiPlan({
          amount: (0, _component.parseDollarToFloat)(amount),
          noteNo
        });
      }

    }
  });

  _exports.default = _default;
});