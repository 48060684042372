define("adt-wss/pods/components/dashboard/account/additional-certificates/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/fp"], function (_exports, _withFsm, _emberHelpers, _myadtHelpers, _fp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.links = void 0;
  const links = {
    certLinks: [{
      external: true,
      title: 'Theft Protection Guarantee',
      url: 'https://help.adt.com/s/article/Theft-Protection-Guarantee',
      type: 'theftProtection',
      visibility: false,
      action: () => (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Welcome Certificates', 'Theft Protection', 'Acct'])
    }, {
      external: true,
      title: '6-Month Service Guarantee',
      url: 'https://help.adt.com/s/article/6-Month-Service-Guarantee',
      type: 'monthService',
      visibility: true,
      action: () => (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Welcome Certificates', '6-month', 'Acct'])
    }, {
      external: true,
      title: 'W-9 Form: Taxpayer ID',
      type: 'w9Form',
      visibility: true,
      url: 'https://alpha.adt.com/content/dam/MyADTAssets/static/ADT_US_Holdings_Inc_W-9.pdf',
      action: () => (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'W-9 Form', 'W-9 Form Downloaded', 'Acct'])
    }, {
      external: true,
      title: 'W-9 Form Taxpayer ID',
      type: 'w9FormCommercial',
      visibility: false,
      url: 'https://alpha.adt.com/content/dam/MyADTAssets/static/ADT_Commercial_LLC_W-9.pdf',
      action: () => (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'W-9 Form Downloaded', 'Form Download', 'Commercial'])
    }]
  };
  _exports.links = links;
  const defaultProps = {
    links
  };
  /**
   * @module Components/Dashboard/Account/AdditionalCertificates
   * @prop {Object[]} links - List of links
   * @prop {Boolean} isResidentialCustomer
   * @example
   * {@lang xml} {{ dashboard/account/additional-certificates
  		isResidentialCustomer=true
  		links=[{ title: 'Movers', url: 'http://url' }]
  	}}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    currentLocation: Ember.inject.service(),
    classNames: ['additional-certificates-widget', 'widget-section'],
    isCommercialCustomer: Ember.computed.equal('currentLocation.profileInfo.address.siteType', 'C'),

    didReceiveAttrs() {
      const isResidentialCustomer = this.get('currentLocation.profileInfo.address.siteType') === 'R';
      const isCommercialCustomer = this.get('currentLocation.profileInfo.address.siteType') === 'C';

      if (isResidentialCustomer) {
        this.set('links.certLinks', links.certLinks.map(link => {
          if (link.type === 'theftProtection') {
            return (0, _fp.omix)(link, {
              visibility: true
            });
          }

          return link;
        }));
      }

      if (isCommercialCustomer) {
        this.set('links.certLinks', links.certLinks.map(link => {
          if (link.type === 'w9FormCommercial') {
            return (0, _fp.omix)(link, {
              visibility: true
            });
          } else if (link.type !== 'w9FormCommercial') {
            return (0, _fp.omix)(link, {
              visibility: false
            });
          }

          return link;
        }));
      }
    }

  });

  _exports.default = _default;
});