define("adt-wss/pods/index/registeruuid/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      email: ''
    },
    templateName: 'index/home',

    model(params) {
      let {
        uuid
      } = params;

      if (/[&#?]/.test(uuid)) {
        uuid = uuid.split(/[&#?]/)[0];
      }

      if (!this.get('queryParams.email').isValidEmail()) {
        this.set('queryParams.email', '');
      }

      return /^[-0-9a-z]+$/i.test(uuid) ? uuid : null;
    }

  });

  _exports.default = _default;
});