define("adt-wss/pods/components/stupid/simple-iframe/component", ["exports", "adt-wss-common/mixins/with-fsm", "jquery", "adt-wss/utils/iframeResizer"], function (_exports, _withFsm, _jquery, _iframeResizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Components/Stupid/SimpleIframe
   * @property {String} iFrameID - id of iframe - should be uniq for page
   * @property {String} url - url address of Iframe
   * @property {String} width - width of Iframe
   * @property {String} height - height of Iframe
   * @property {String} [classNames] - class for iframe
   * @property {Boolean} debug - shows debug info
   * @example
   * {@lang xml} {{stupid/simple-iframe url="https://google.com" iFrameID="myGoogle" classNames="a b c"}}
   */
  var _default = Ember.Component.extend(_withFsm.default, {
    classNames: ['simple-iframe'],
    width: '100%',

    didReceiveAttrs() {
      this._super(...arguments);

      const {
        width,
        height
      } = this.getProperties('width', 'height');
      let iFrameID = this.get('iFrameID');

      if (!iFrameID) {
        iFrameID = Math.random();
      }

      window["".concat(iFrameID, "IframeOnload")] = function () {
        const iFrame = document.getElementById(iFrameID);
        iFrame.width = width;
        iFrame.height = height || document.body.clientHeight;
      };
    },

    didRender() {
      this._super(...arguments);

      const {
        iFrameID,
        debug
      } = this.getProperties('iFrameID', 'debug');
      document.getElementById(iFrameID).onload = window["".concat(iFrameID, "IframeOnload")];
      (0, _iframeResizer.default)({
        log: debug,
        // Enable console logging
        enablePublicMethods: true,
        // Enable methods within iframe hosted page
        scrolling: false,

        resizedCallback({
          iframe,
          height,
          width,
          type
        }) {
          // Callback fn when resize is received
          (0, _jquery.default)('p#callback').html("\n\t\t\t\t\t<b>Frame ID:</b> ".concat(iframe.id, "\n\t\t\t\t\t<b>Height:</b> ").concat(height, "\n\t\t\t\t\t<b>Width:</b> ").concat(width, "\n\t\t\t\t\t<b>Event type:</b>").concat(type, "\n\t\t\t\t"));
        },

        messageCallback({
          iframe,
          message
        }) {
          // Callback fn when message is received
          (0, _jquery.default)('p#callback').html("\n\t\t\t\t\t<b>Frame ID:</b> ".concat(iframe.id, "\n\t\t\t\t\t<b>Message:</b> ").concat(message, "\n\t\t\t\t"));
          window.alert(message);
        },

        closedCallback(id) {
          // Callback fn when iFrame is closed
          (0, _jquery.default)('p#callback').html("\n\t\t\t\t\t<b>IFrame (</b>".concat(id, "<b>) removed from page.</b>\n\t\t\t\t"));
        }

      }, "#".concat(iFrameID));
    }

  });

  _exports.default = _default;
});