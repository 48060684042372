define("adt-wss/pods/components/navbar-header/component", ["exports", "adt-wss-common/utils/fp", "adt-wss-common/utils/route-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/mixins/with-fsm", "adt-wss/pods/components/navbar-header/constants", "adt-wss/services/app-dispatcher"], function (_exports, _fp, _routeHelpers, _emberHelpers, _myadtHelpers, _withFsm, _constants, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.computeTokenClass = _exports.buildLocations = _exports.makeLabel = _exports.makeClickListener = _exports.defaultProperties = void 0;
  const FORCE_CLOSE_TIMEOUT = 7200000;
  const defaultProperties = {
    locations: [],
    alerts: [],
    logout: () => console.error('Please pass "logout" to navbar-header component!'),
    toggleSidebar: () => console.error('Please pass "toggleSidebar" to navbar-header component!')
  };
  _exports.defaultProperties = defaultProperties;
  const fsmModel = {
    transitions: {
      [_constants.LOADING_STATE]: {
        [_constants.SIGN_OUT_ACTION]: _constants.LOADING_STATE,
        [_constants.SUCCESS_FETCH_DATA_ACTION]: _constants.IDLE_STATE,
        [_constants.FAILURE_FETCH_DATA_ACTION]: _constants.ERROR_STATE,
        [_constants.SUCCESS_CHANGE_LOCATION_ACTION]: _constants.IDLE_STATE,
        [_constants.FAILURE_CHANGE_LOCATION_ACTION]: _constants.ERROR_STATE
      },
      [_constants.ERROR_STATE]: {
        [_constants.FETCH_DATA_ACTION]: _constants.LOADING_STATE,
        [_constants.CHANGE_LOCATION_ACTION]: _constants.LOADING_STATE,
        [_constants.SIGN_OUT_ACTION]: _constants.LOADING_STATE
      },
      [_constants.IDLE_STATE]: {
        [_constants.CHANGE_LOCATION_ACTION]: _constants.LOADING_STATE,
        [_constants.FETCH_DATA_ACTION]: _constants.LOADING_STATE,
        // [SUCCESS_FETCH_DATA_ACTION]: IDLE_STATE,
        // [FAILURE_FETCH_DATA_ACTION]: ERROR_STATE,
        [_constants.SIGN_OUT_ACTION]: _constants.LOADING_STATE
      }
    }
  };

  const makeClickListener = context => () => context.clickOnBody();

  _exports.makeClickListener = makeClickListener;
  const makeLabel = (0, _fp.pipe)(_fp.compact, (0, _fp.join)(' '));
  _exports.makeLabel = makeLabel;

  const buildLocations = (locations, accountNumber) => (locations || []).map((loc = {}) => {
    const {
      locationName,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      zip,
      state
    } = loc;
    const addressLabel = makeLabel([addressLine1, addressLine2, addressLine3, city, state, zip]);
    const label = locationName || addressLabel;
    return (0, _fp.omix)(loc, {
      addressLabel,
      label,
      accountNumber
    });
  });

  _exports.buildLocations = buildLocations;

  const computeTokenClass = alerts => {
    if (alerts.length > 9 && alerts.length < 99) {
      return ' great-than9';
    }

    if (alerts.length > 99) {
      return ' great-than99';
    }

    return '';
  };
  /**
   * @module Components/NavbarHeader
   */


  _exports.computeTokenClass = computeTokenClass;

  var _default = Ember.Component.extend(_withFsm.default, defaultProperties, {
    elementId: 'navbarHeader',
    currentLocationService: Ember.inject.service('currentLocation'),
    media: Ember.inject.service(),
    appAlerts: Ember.inject.service(),
    appDispatcher: Ember.inject.service(),
    myadtSecurity: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    currentState: _constants.IDLE_STATE,
    fsmModel,
    locationsIsOpen: false,
    alertsIsOpen: false,
    forceClosed: false,
    isCsrUser: Ember.computed.readOnly('authInfo.authInfo.isCsrUser'),
    accountNumber: Ember.computed.readOnly('currentLocationService.profileInfo.accountId'),
    noSystems: Ember.computed.readOnly('currentLocationService.noSystems'),
    locations: Ember.computed.readOnly('currentLocationService.addresses'),
    currentLocation: (0, _emberHelpers.computedFindBy)('availableLocations', 'current', true),
    availableLocations: (0, _emberHelpers.computedApplyFunction)(buildLocations, 'locations', 'accountNumber'),
    tokenClass: (0, _emberHelpers.computedApplyFunction)(computeTokenClass, 'alerts'),

    init() {
      this._super(...arguments);

      this.set('isContractMode', Number(window.sessionStorage.getItem('contractMode')));
      this.bodyClickListener = makeClickListener(this);
      const accountId = this.get('currentLocationService.profileInfo.accountId');
      const forceClosed = sessionStorage.getItem("myadtAlertsForceClosed_".concat(accountId));
      if (forceClosed && forceClosed - Date.now() < FORCE_CLOSE_TIMEOUT) this.set('forceClosed', true);
      document.addEventListener('click', this.bodyClickListener);
      if (this.get('myadtSecurity.picProvided')) this.PIC_SUBMITTED_EVENT();
      this.get('appDispatcher').subscribe(_appDispatcher.PIC_SUBMITTED_EVENT, this, _appDispatcher.PIC_SUBMITTED_EVENT);
      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_NOTIFICATION_MASSAGES, this, _appDispatcher.PIC_SUBMITTED_EVENT); // this.get('appDispatcher').subscribe(UPDATE_APP_STATE_EVENT, this, UPDATE_APP_STATE_EVENT);
    },

    willDestroyElement() {
      document.removeEventListener('click', this.bodyClickListener);
      this.get('appDispatcher').unsubscribe(_appDispatcher.PIC_SUBMITTED_EVENT, this, _appDispatcher.PIC_SUBMITTED_EVENT);
      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_NOTIFICATION_MASSAGES, this, _appDispatcher.PIC_SUBMITTED_EVENT); // this.get('appDispatcher').unsubscribe(UPDATE_APP_STATE_EVENT, this, UPDATE_APP_STATE_EVENT);
    },

    clickOnBody() {
      const locationsIsOpen = this.get('locationsIsOpen');

      if (locationsIsOpen) {
        this.set('locationsIsOpen', false);
      }
    },

    [_appDispatcher.PIC_SUBMITTED_EVENT]() {
      if (this.get('currentState') === _constants.IDLE_STATE) {
        this.get('fsm').dispatch(_constants.FETCH_DATA_ACTION);
      }
    },

    [_constants.FETCH_DATA_ACTION]() {
      const {
        appAlerts,
        fsm,
        currentLocationService
      } = this.getProperties('appAlerts', 'currentLocationService', 'fsm');
      currentLocationService.getAddresses().then(() => appAlerts.getAlerts().then(alerts => fsm.dispatch(_constants.SUCCESS_FETCH_DATA_ACTION, {
        alerts
      })).catch((0, _routeHelpers.fsmDispatch)(_constants.FAILURE_FETCH_DATA_ACTION)(this)));
    },

    [_constants.SUCCESS_FETCH_DATA_ACTION]({
      alerts
    }) {
      if (!this.get('media.isSmartphone') && !this.get('forceClosed')) {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Proactive Notifications', 'Notifications Pop Up Opened']);
        this.set('alertsIsOpen', alerts.length);
      }

      this.setProperties({
        alerts
      });
    },

    [_constants.FAILURE_FETCH_DATA_ACTION](error) {
      console.error(_constants.FAILURE_FETCH_DATA_ACTION, error);
    },

    [_constants.CHANGE_LOCATION_ACTION](location) {
      const currentLocationService = this.get('currentLocationService');
      return this.commonDispatcher(() => currentLocationService.changeAddress(location), _constants.SUCCESS_CHANGE_LOCATION_ACTION, _constants.FAILURE_CHANGE_LOCATION_ACTION);
    },

    [_constants.FAILURE_CHANGE_LOCATION_ACTION](error) {
      console.error(_constants.FAILURE_CHANGE_LOCATION_ACTION, error);
    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      if (this.get('currentState') === _constants.IDLE_STATE) {
        this.get('fsm').dispatch(_constants.FETCH_DATA_ACTION);
      }
    },

    [_constants.SIGN_OUT_ACTION]() {
      this.getMethod('logout')();
    },

    actions: {
      closeAlerts() {
        this.set('alertsIsOpen', false);
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Proactive Notifications', 'Notifications Pop Up Closed']);
        const accountId = this.get('currentLocationService.profileInfo.accountId');
        sessionStorage.setItem("myadtAlertsForceClosed_".concat(accountId), Date.now());
      },

      /**
       * Calls app-alert service's method;
       * Remove clicked alert from the array of alerts;
       *
       * @param {Function} alertAction method to be called on action clicked
       * @param {Array} actionParams [context, params] - context of app-alerts service and parameters for alertAction call
       */
      triggerAlertAction(alert) {
        const alerts = this.get('alerts');
        const newAlerts = alerts.filter(el => el.action != alert.action);

        if (typeof alert.action === 'function') {
          if (alert.actionParams.length > 1) {
            alert.action.call(alert.actionParams[0], alert.actionParams[1]);
          } else {
            alert.action();
          }
        }

        this.set('alerts', newAlerts);
      }

    }
  });

  _exports.default = _default;
});