define("adt-wss/pods/dashboard/alarm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B1LT8PJG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"section-title\"],[8],[7,\"span\",true],[8],[0,\"Alarm System\"],[9],[7,\"span\",true],[10,\"class\",\"orange-line\"],[8],[9],[9],[0,\"\\n\"],[1,[28,\"widget/status-messages\",null,[[\"messages\"],[[24,[\"dashboardStatusMessages\"]]]]],false],[0,\"\\n\"],[7,\"section\",true],[10,\"id\",\"dashboardAlarmSystem\"],[10,\"class\",\"inner-container\"],[8],[0,\"\\n\\t\"],[1,[28,\"dashboard-tabs\",null,[[\"links\"],[[24,[\"links\"]]]]],false],[0,\"\\n\\t\"],[7,\"section\",true],[10,\"class\",\"section-content\"],[8],[1,[22,\"outlet\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/alarm/template.hbs"
    }
  });

  _exports.default = _default;
});