define("adt-wss/services/appointment-data", ["exports", "adt-wss-common/services/appointment-data"], function (_exports, _appointmentData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _appointmentData.default.extend({
    ET: Ember.inject.service('event-tagging'),

    sendEventTag(...args) {
      return this.ET.sendEventTag(...args);
    }

  });

  _exports.default = _default;
});