define("adt-wss/services/billing-data", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/localization", "adt-wss-common/utils/http-resources", "adt-wss-common/utils/fp", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/ember-helpers"], function (_exports, _myadtHelpers, _localization, _httpResources, _fp, _appDispatcher, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EASYPAY_SWITCH_TO_ACH = void 0;

  /**
   * Returns account number masked, exposing only last 4 digits
   * @param {Int|String} num
   * @param {Int} len how many digits after mask
   * @return {String}
   * @example this.maskAcctNum(324987324, 4) -> '***7324';
   */
  const maskAcctNum = (num, len) => {
    const acctNo = num.toString().trim();
    return "******".concat(acctNo.substr(-len));
  };

  const clearCacheArgs = {
    easyPayInfo: null,
    canViewBillingLink: true,
    canShowBillingAddress: false,
    billingModel: {},
    billingStatements: null,
    billingHistory: null,
    billingSystem: null,
    billingAddress: null,
    billingPhoneDetails: null,
    billingSystemDownStatus: null,
    savedAccounts: null,
    hardDeclined: null,
    easyPayPending: false,
    afterPaymentState: false,
    installationData: null
  };
  const EASYPAY_SWITCH_TO_ACH = 'EASYPAY_SWITCH_TO_ACH_PHASE1';
  /**
   * API container and data store for user battery info
   * @module Services/BillingData
   * @prop {Boolean} billingSystemDown checks `billingModel.paymentStatus`
   * @prop {Boolean} billingSystemDownStatus separate from `billingSystemDown`; part of an API response
   * @prop {Boolean} canViewBillingLink
   * @prop {Boolean} canShowBillingAddress
   * @prop {BillingModel} billingModel
   * @prop {BillingStatement[]} billingStatements
   * @prop {ARHistoryLine[]} billingHistory
   * @prop {String} billingSystem
   * @prop {Object[]} savedAccounts
   * @prop {Object} hardDeclined object so we know when it's been retrieved from API
   * @prop {Object} hardDeclined.hardDeclined
   * @prop {Boolean} easyPayPending `billingModel.easypayDetails.changesMadeRecently`
   * @prop {BillingAddress} billingAddress
   * @prop {Object} billingPhoneDetails
   */

  _exports.EASYPAY_SWITCH_TO_ACH = EASYPAY_SWITCH_TO_ACH;

  var _default = Ember.Service.extend(Ember.Evented, (0, _myadtHelpers.clearCache)(clearCacheArgs), {
    apiService: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    csrInfo: Ember.inject.service(),
    paymentech: Ember.inject.service(),
    flexfiData: Ember.inject.service(),
    impersonationData: Ember.inject.service(),
    promotionData: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    billingSystemDown: Ember.computed.bool('billingModel.paymentStatus.status'),
    easyPayInfo: null,
    canViewBillingLink: true,
    canShowBillingAddress: false,
    billingStatements: null,
    billingHistory: null,
    installationData: null,
    billingSystem: null,
    billingAddress: null,
    billingPhoneDetails: null,
    billingSystemDownStatus: null,
    savedAccounts: null,
    hardDeclined: null,
    easyPayPending: false,
    afterPaymentState: false,
    easyPayPromotion: (0, _emberHelpers.computedApplyFunction)(promotions => (promotions || []).some(el => el.promotionType.includes(EASYPAY_SWITCH_TO_ACH)), 'promotionData.promotions'),

    /**
     * Populates `billingModel` and `billingSystem`.
     * @param {Object} data raw `billingModel` from {@link module:Services/BillingData~getBillingInfo getBillingInfo}
     *
     * @emits billingModelAPIComplete
     * @return {Promise}
     */
    prepBillingData(data) {
      // NEVER MUTATE DATA IT SPAWNs A LOT OF DEFECTS
      const billingData = (0, _fp.omix)(data);
      const billInfo = (0, _fp.omix)(billingData.billInfo);
      const easypayDetails = (0, _fp.omix)(billingData.easypayDetails);

      if (this.get('currentLocation.permissions.billing')) {
        const ccDetails = {
          expired: false,
          expirationDate: null,
          expirationDateObj: null,
          daysUntilExpiration: null
        };

        if (billingData.isEnrolled) {
          billingData.isEnrolled = JSON.parse(billingData.isEnrolled).isEnrolled;
        }

        if (billInfo.pendingPayment === '$0.00' || billInfo.pendingPayment === '0.00') {
          billInfo.hasPendingPayment = false;
        }

        if (!billingData.errEasypayDetails && !billingData.errCcExpirationDate) {
          if (billingData.isEnrolled && easypayDetails.ccExpDate) {
            const sd = easypayDetails.ccExpDate.split('/');
            const dateObj = new Date(sd[1], sd[0], 1);
            ccDetails.expirationDate = easypayDetails.ccExpDate;
            ccDetails.expirationDateObj = dateObj.dateAfterNDays(-1);
            ccDetails.expired = ccDetails.expirationDateObj < new Date().setMidnight();
            ccDetails.expiresSoon = ccDetails.expirationDateObj.timeSpan().days < 30;
          }
        }

        this.set('easyPayPending', !!easypayDetails.changesMadeRecently);
        easypayDetails.ccDetails = ccDetails;

        if (billingData.paymentStatus) {
          billingData.paymentStatus = JSON.parse(billingData.paymentStatus);
        }

        if (!billingData.errBillInfo && billingData.billInfo) {
          this.set('billingSystem', billInfo.billingSystem);
          billInfo.balanceDueDateObj = Date.createDate(billInfo.balanceDueDate);
          billInfo.lastPaymentDateObj = Date.createDate(billInfo.lastPaymentDate);
          billInfo.balance = billInfo.amountDue.toString().replace('$', '').trim();
        }
      }

      this.set('billingModel', (0, _fp.omix)(billingData, {
        billInfo,
        easypayDetails
      }));
      this.trigger('billingModelAPIComplete');
      return billingData;
    },

    /**
     * Retrieves `billingModel` from API
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getBillingInfo() {
      let getBillingInfoData;
      return this.get('apiService').request({
        resource: _httpResources.myADT.getBillingInfo
      }).then(({
        data
      }) => {
        getBillingInfoData = data;
        this.get('flexfiData').getFlexFiDetails();
      }).then(() => this.prepBillingData(getBillingInfoData));
    },

    /**
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getBillingOnPreinstallData(params) {
      return this.get('apiService').myADT.getBillingOnPreinstallData(params).then(({
        data
      }) => this.set('installationData', data));
    },

    /**
     * Retrieves current bill from API.
     * Sets `billingModel.bill`.
     * Rejects if {@link module:CurrentLocation.permissions.billing} is `false`
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getBill() {
      if (this.get('currentLocation.permissions.billing')) {
        return this.get('apiService').myADT.getBill().then(({
          data
        }) => this.set('billingModel.bill', data));
      }

      return Ember.RSVP.Promise.reject({
        success: false,
        errors: [(0, _localization.toExpandedString)('Billing.noPermissions')]
      });
    },

    /**
     * Checks for EasyPay hard decline.
     * Sets `hardDeclined`
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    checkHardDecline() {
      if (this.get('currentLocation.permissions.billing')) {
        return this.get('apiService').myADT.checkHardDecline().then(({
          data
        }) => this.set('hardDeclined', data));
      }

      return Ember.RSVP.Promise.reject({
        success: false,
        errors: [(0, _localization.toExpandedString)('Billing.noPermissions')]
      });
    },

    /**
     * Retrieves `billingModel.billingOptions` from API
     * Rejects if {@link module:CurrentLocation.permissions.billing} is `false`
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getBillingOptions() {
      if (this.get('currentLocation.permissions.billing')) {
        return this.get('apiService').myADT.getBillingOptions().then(({
          data
        }) => this.set('billingModel.billingOptions', data));
      }

      return Ember.RSVP.Promise.reject({
        success: false,
        errors: [(0, _localization.toExpandedString)('Billing.noPermissions')]
      });
    },

    /**
     * Retrieves URL of passed statement
     * @instance
     * @deprecated
     *
     * @type {ServiceCall}
     * @param {Object} statement
     * @param {String} statement.date
     * @param {String|Int} statement.id
     * @param {String} [format] Def='PDF' or 'XLS'
     * @return {ApiResponse}
     */
    getBillingStatementURL(statement, format) {
      return this.get('apiService').request({
        resource: _httpResources.myADT.getBillingStatementURL,
        data: {
          date: statement.date,
          id: statement.id,
          format: format || 'PDF'
        }
      }).then(({
        data
      }) => data);
    },

    /**
     * Retrieves `billingStatements` from API
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getBillingStatements() {
      this.set('billingStatements', null);
      return this.get('apiService').request({
        resource: _httpResources.myADT.getBillingStatements
      }).then(({
        data
      }) => {
        let list = (data.statementsList || []).map(statement => (0, _fp.omix)(statement, {
          docDateObj: Date.createDate(statement.docDate)
        }));
        if (!list.length) list = null;
        return this.set('billingStatements', list);
      });
    },

    /**
     * Send statement by email
     * @param {String} email
     * @param {String} docId
     */
    emailBillingStatement(email, docId) {
      return this.get('apiService').myADT.emailBillingStatement({
        email,
        docId
      });
    },

    getBillingHistory() {
      this.set('billingHistory', null);
      return this.get('apiService').request({
        resource: _httpResources.myADT.getBillingHistory
      }).then(({
        data
      }) => {
        let history = (data.billingHistory || []).map(el => {
          el.dateObj = Date.createDate(el.date);
          el.date = el.dateObj.toAltString();
          el.invoiceNumberStr = el.invoiceNumber === 0 || el.invoiceNumber === '0' ? '' : el.invoiceNumber;
          el.balanceStr = (el.balance < 0 ? '-$' : '$') + Math.abs(el.balance).toFixed(2);
          el.amountStr = (el.amount < 0 ? '-$' : '$') + Math.abs(el.amount).toFixed(2);
          return el;
        });

        if (!history.length) {
          history = null;
        }

        this.set('billingHistory', history);
        return history;
      });
    },

    /**
     * Initializes process for using IVR to get payment details
     * when agent is work-from-home (in `pods/components/dashboard/account/one-time-payment`)
     */
    initIvrPayment({
      agentIVRContactNo,
      paymentType
    }) {
      if (!agentIVRContactNo) {
        return Ember.RSVP.Promise.reject({
          success: false,
          errors: ['Customer phone number required.']
        });
      }

      if (!/^\d{10}$/.test(agentIVRContactNo)) {
        return Ember.RSVP.Promise.reject({
          success: false,
          errors: ['Digits 0-9 only. Format: ##########']
        });
      }

      return this.get('apiService').myADT.initIvrPayment({
        agentIVRContactNo,
        paymentType
      }).finally(() => this.get('csrInfo').getCurrentCSR());
    },

    /**
     * Gets payment details from CC or IVR for confirmation screens
     */
    getPayConfDetails_v2(opts) {
      return this.get('apiService').myADT.getPayConfDetails_v2({
        appName: 'web',
        flowId: opts.flowId,
        uID: opts.uID,
        paymentType: opts.paymentType || null
      }).then(({
        data
      }) => data);
    },

    /**
     * Retrieves `savedAccounts` from API
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getSavedAccounts() {
      this.set('savedAccounts', null);
      return this.get('apiService').request({
        resource: _httpResources.myADT.getSavedAccounts
      }).then(({
        data
      }) => {
        let list = (data || []).map(obj => {
          obj.accountNumberMasked = maskAcctNum(obj.accountNumberMasked, 4);
          obj.uniqueId = [obj.id, obj.accountNumberMasked.substr(-4)].join('_');
          return obj;
        });
        if (!list.length) list = null;
        return this.set('savedAccounts', list);
      });
    },

    /**
     * Updates card expiration date
     * Refreshes saved account data on complete
     * @instance
     * @param {SavedAccount} account
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    updateSavedAccount(account) {
      const data = {
        id: account.id,
        expiryDate: account.expiryDate
      };
      return this.get('apiService').myADT.updateSavedAccount(data).then(() => {
        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Billing', 'Update saved payment account']);

        return this.get('impersonationData').createIncidentOrIssue('SAEP', 'Updated expiration date of saved payment method');
      }).finally(() => this.getSavedAccounts());
    },

    /**
     * Deletes saved account
     * Refreshes saved account data on complete
     * @instance
     * @param {SavedAccount} account
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    deleteSavedAccount(account) {
      const data = {
        id: account.id
      };
      return this.get('apiService').myADT.deleteSavedAccount(data).then(() => {
        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Billing', 'Delete saved payment account']);

        return this.get('impersonationData').createIncidentOrIssue('SAD', 'Deleted saved payment method');
      }).finally(() => this.getSavedAccounts());
    },

    /**
     * Retrieves `billingAddress` from API
     * Sets `canViewBillingLink` and `canShowBillingAddress`
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getBillingAddress() {
      this.set('canShowBillingAddress', false);
      this.set('canViewBillingLink', false);

      if (this.get('currentLocation.permissions.billing')) {
        return this.get('apiService').myADT.getBillingAddress().then(({
          data
        }) => {
          this.setProperties({
            canViewBillingLink: data.canShowBillingAddress && data.billingAddress,
            canShowBillingAddress: data.canShowBillingAddress,
            billingAddress: data.billingAddress,
            billingPhoneDetails: data.billingPhoneDetails
          });
          return data;
        });
      }

      return Ember.RSVP.Promise.reject({
        success: false,
        errors: [(0, _localization.toExpandedString)('Billing.noPermissions')]
      });
    },

    /**
     * Updates `billingAddress` to API.
     * Sets new value on success
     * @instance
     *
     * @type {ServiceCall}
     * @prop {BillingAddress} address
     * @return {ApiResponse}
     */
    updateBillingAddress(address) {
      const apiData = (0, _fp.omix)({
        country: null,
        addressType: 'permanent',
        returnMailFlag: false
      }, address);
      return this.get('apiService').myADT.updateBillingAddress(apiData).then(({
        data
      }) => {
        if (data.hasErrors) {
          return Ember.RSVP.Promise.reject({
            success: false,
            errors: [data.messageCode]
          });
        }

        const eventTagInfo = {
          eventTypeId: 'ECUSTPRO',
          subEventTypeId: 'CHGBILAD'
        };
        this.get('ET').sendEventTag(eventTagInfo);

        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Billing', 'Billing address updated']);

        this.get('impersonationData').createIncidentOrIssue('BAUP', 'Updated billing address');
        return this.set('billingAddress', data);
      });
    },

    /**
     * Submits Payment
     * @instance
     *
     * @type {ServiceCall}
     * @param {Object} obj
     * @param {Object} obj.paymentDetails
     * @param {String} [obj.paymentDetails.noteNo]
     * @param {String} obj.paymentDetails.paymentAmount
     * @param {String} obj.paymentDetails.postDate
     * @param {String} obj.paymentDetails.selectedSavedAccountId
     * @param {String} obj.paymentDetails.deviceType
     * @param {String} obj.paymentDetails.paymentType
     * @param {Object} [obj.achDetails] only one of `achDetails` or `ccDetails` permitted
     * @param {Object} [obj.ccDetails] only one of `achDetails` or `ccDetails` permitted
     * @return {ApiResponse}
     */
    payBill(obj) {
      const {
        paymentDetails,
        achDetails,
        ccDetails
      } = obj;
      let paymentData = obj;
      let {
        paymentType
      } = obj;

      if (!achDetails || paymentType == 'ivrCreditCard') {
        paymentData = (0, _fp.omit)(paymentData, 'achDetails');
        paymentType = paymentType || 'card';
      }

      if (!ccDetails || paymentType == 'ivrBankAccount') {
        paymentData = (0, _fp.omit)(paymentData, 'ccDetails');
        paymentType = paymentType || 'bank';
      }

      return this.get('apiService').myADT.payBill(paymentData).then(({
        data
      }) => {
        const details = achDetails || ccDetails;
        const pt = paymentType.toUpperCase();

        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Billing', 'One Time Payment', "Amount ".concat(paymentDetails.paymentAmount)]);

        _myadtHelpers.default.sendAnalyticEvent(['_trackPageview', 'One time payment', "/auth/onetime.jsp/oneTimePayment/completed".concat(pt, "Payment")]);

        if (this.get('csrInfo.isCsr')) {
          const csr = this.get('csrInfo.csr');

          _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', "CSR ".concat(pt, " BILL PAY"), "CSR Team:".concat(csr.team), "CSR Username:".concat(csr.username)]);
        }

        if (details.savePayment) {
          _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Billing', 'Saved new payment account info']);
        }

        if (paymentDetails.selectedSavedAccountId) {
          _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Billing', 'Used saved payment account']);
        }

        if (this.get('billingModel.billingOptions.enrolledInEasyPay')) {
          _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Billing', 'Additional Payment by EasyPay Customer', "Payment Type: ".concat(pt)]);
        }

        return data;
      }).then(data => {
        this.resetBillingInfo().then(() => this.getSavedAccounts());
        return data;
      });
    },

    /**
     * Gets account details
     * @instance
     *
     * @param {Object} account
     * @param {String} [account.routingNumber]
     * @param {String} [account.accountNumber]
     * @param {String} [account.number]
     * @param {String} [account.accountType]
     * @param {String} [account.type]
     * @param {String} [account.tokenId]
     * @param {String} [account.id]
     * @param {String} [account.profileId]
     * @param {String} [account.expiryDate]
     * @param {String} [account.expirationMonth]
     * @param {String} [account.expirationYear]
     * @param {String} [account.savedCardType]
     * @param {String} [account.accountType]
     * @param {String} [account.uID]
     * @param {String} [account.billingZipCode]
     * @param {String} [account.zip]
     * @param {Boolean} account.savePayment
     * @return {Object} - computed details
     */
    getPaymentDetails(account) {
      const {
        accountType
      } = account;
      const key = /bank/i.test(accountType) || accountType === 'C' || accountType === 'S' ? 'achDetails' : 'ccDetails';
      const details = {
        achDetails: {
          routingNumber: account.routingNumber,
          accountNumber: account.accountNumber,
          accountNumber2: account.accountNumber,
          accountType: account.accountType,
          initials: 'WEB',
          savePayment: Boolean(account.savePayment)
        },
        ccDetails: {
          profileId: [account.profileId, account.tokenId, account.id].find(Boolean) || null,
          uID: account.uID,
          savePayment: Boolean(account.savePayment)
        }
      };
      return {
        [key]: details[key]
      };
    },

    getPayConfDetails({
      flowId,
      uID
    }) {
      return this.get('apiService').myADT.getPayConfDetails({
        flowId,
        uID
      });
    },

    // =========== EasyPay =========== //

    /**
     * Validates account details before submission
     *
     * @param {Object} opts
     * @param {String} opts.paymentType
     * @param {Object} opts.ccDetails
     * @param {Object} opts.achDetails
     */
    validateEasypay(opts) {
      const easypay = this.prepareEasypayData(opts);
      return this.get('apiService').myADT.validateEasypay(easypay).then(({
        data
      }) => data);
    },

    /**
     * Validates ACH details before submission
     * for account switch
     *
     * @param {Object} opts
     * @param {String} opts.paymentType
     * @param {Object} opts.ccDetails
     * @param {Boolean} isPS Indicates Pypestream flow, so response actions can be skipped
     */
    validateSwitchEasypayBank(opts, isPS) {
      const easypay = this.prepareEasypayUpdate(opts);
      return this.get('apiService').myADT.validateSwitchEasypayBank(easypay).then(({
        data
      }) => {
        _myadtHelpers.default.sendAnalyticEvent(['_trackPageview', "".concat(window.location.pathname, "/manageEasyPay/confirmACHInfo")]);

        if (!isPS && this.get('csrInfo.isCsr')) {
          const csr = this.get('csrInfo.csr');

          _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'CSR Manage Easypay switchACH', "CSR Team:".concat(csr.team, ", CSR Username:").concat(csr.username)]);
        }

        return data;
      });
    },

    /**
     * Enrolls a user in EasyPay
     *
     * @param {Object} opts
     * @param {String} opts.paymentType
     * @param {Object} opts.ccDetails
     * @param {Object} opts.achDetails
     * @param {Boolean} isPS Indicates Pypestream flow, so response actions can be skipped
     */
    enrollEasypay(opts, isPS) {
      const easypay = this.prepareEasypayData(opts);
      return this.get('apiService').myADT.enrollEasypay(easypay).then(({
        data
      }) => {
        const eventTagInfo = {
          eventTypeId: 'EPAYENRL',
          subEventTypeId: 'ENROLL'
        };
        this.get('ET').sendEventTag(eventTagInfo);

        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Easypay Enrollment', 'Enrollment Complete']);

        if (!isPS) {
          if (this.get('csrInfo.isCsr')) {
            const csr = this.get('csrInfo.csr');

            _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'CSR Manage Easypay switchCC', "CSR Team:".concat(csr.team, ", CSR Username:").concat(csr.username)]);
          }

          this.resetBillingInfo();
        }

        return data;
      });
    },

    /**
     * Switches the card in a user's EasyPay enrollment
     *
     * @param {Object} opts
     * @param {String} opts.paymentType
     * @param {Object} opts.ccDetails
     * @param {Boolean} isPS Indicates Pypestream flow, so response actions can be skipped
     */
    switchEasypayCard(opts, isPS) {
      const easypay = this.prepareEasypayUpdate(opts);
      return this.get('apiService').myADT.switchEasypayCard(easypay).then(({
        data
      }) => {
        _myadtHelpers.default.sendAnalyticEvent(['_trackPageview', "".concat(window.location.pathname, "/manageEasyPay/thankYou")]);

        if (!isPS) {
          if (this.get('csrInfo.isCsr')) {
            const csr = this.get('csrInfo.csr');

            _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'CSR Manage Easypay switchCC', "CSR Team:".concat(csr.team, ", CSR Username:").concat(csr.username)]);
          }

          this.resetBillingInfo();
        }

        return data;
      });
    },

    /**
     * Updates EasyPay CC expiration date
     * @param {Object} opts
     * @param {String} opts.profileId null
     * @param {String} opts.number
     * @param {String} opts.expirationMonth
     * @param {String} opts.expirationYear
     * @param {String} opts.billingZipCode
     * @param {String} opts.type
     * @param {String} opts.nameOnCard
     * @param {String} opts.streetAddress
     * @param {String} opts.initials 'WEB'
     */
    updateEasypayExpDate(opts) {
      const paymentDetails = {
        deviceType: 'Web',
        noteNo: opts.easypayDetails && opts.easypayDetails.noteNo ? opts.easypayDetails.noteNo : null
      };
      let ccDetails = {
        profileId: null,
        number: '',
        expirationMonth: '',
        expirationYear: '',
        billingZipCode: '',
        type: '',
        nameOnCard: '',
        streetAddress: '',
        initials: 'WEB'
      };
      ccDetails = (0, _fp.omix)(ccDetails, opts.ccDetails);
      return this.get('apiService').myADT.updateEasypayExpDate({
        ccDetails,
        paymentDetails
      }).then(data => {
        this.resetBillingInfo();
        return data;
      });
    },

    /**
     * Switches the account in a user's EasyPay enrollment
     *
     * @param {Object} opts
     * @param {String} opts.paymentType
     * @param {Object} opts.achDetails only include if ACH is selected
     * @param {Boolean} isPS Indicates Pypestream flow, so response actions can be skipped
     */
    switchEasypayBank(opts, isPS) {
      const easypay = this.prepareEasypayUpdate(opts);
      return this.get('apiService').myADT.switchEasypayBank(easypay).then(({
        data
      }) => {
        _myadtHelpers.default.sendAnalyticEvent(['_trackPageview', "".concat(window.location.pathname, "/manageEasyPay/thankYou")]);

        if (!isPS) {
          this.resetBillingInfo();
        }

        return data;
      });
    },

    /**
     * Preps data for validation and submission
     * @param {Object} opts
     * @param {String} opts.paymentType
     * @param {Object} opts.ccDetails
     * @param {Object} opts.achDetails
     *
     * @return {Object}
     * @example {
     * 	paymentType: 'bankAccount',
     * 	initial: 'WEB',
     * 	deviceType: 'Web',
     * 	[bankAccountForm: {...}],
     * 	[creditCardForm: {...}]
     * }
     */
    prepareEasypayData(opts) {
      const easypay = {
        initials: 'WEB',
        deviceType: 'Web',
        noteNo: opts.easypayDetails && opts.easypayDetails.noteNo ? opts.easypayDetails.noteNo : null,
        paymentType: opts.paymentType
      };

      if (opts.paymentType === 'card') {
        easypay.paymentType = 'creditCard';
        easypay.creditCardForm = {
          uID: opts.uID || '',
          profileId: opts.profileId || ''
        };
      } else if (opts.paymentType === 'bank') {
        const {
          achDetails
        } = opts;
        easypay.paymentType = 'bankAccount';
        easypay.bankAccountForm = {
          bankAccountType: achDetails.accountType === 'C' ? 'Checking' : 'Savings',
          bankRoutingNumber: achDetails.routingNumber,
          bankAccountNumber: achDetails.accountNumber,
          validateBankAccountNumber: achDetails.accountNumber2
        };
      }

      return easypay;
    },

    prepareEasypayUpdate(opts) {
      const easypay = {
        paymentDetails: {
          deviceType: 'Web',
          noteNo: opts.easypayDetails && opts.easypayDetails.noteNo ? opts.easypayDetails.noteNo : null
        },
        paymentType: opts.paymentType
      };

      if (opts.paymentType === 'card') {
        easypay.paymentType = 'creditCard';
        easypay.ccDetails = {
          profileId: opts.profileId || '',
          uID: opts.uID || '',
          initials: 'WEB'
        };
      } else if (opts.paymentType === 'bank') {
        const {
          achDetails
        } = opts;
        easypay.paymentType = 'bankAccount';
        easypay.achDetails = {
          accountType: achDetails.accountType,
          applySwitchACHPromo: this.easyPayPromotion,
          routingNumber: achDetails.routingNumber,
          accountNumber: achDetails.accountNumber,
          accountNumber2: achDetails.accountNumber2,
          initials: 'WEB',
          prepayNumber: achDetails.prepayNumber
        };
      }

      return easypay;
    },

    /**
     * Seems self explanatory.
     * Sets `billingSystemDownStatus`
     * @deprecated
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    checkBillingSystemDown() {
      this.set('billingSystemDownStatus', null);
      return this.get('apiService').myADT.checkBillingSystemDown().then(({
        data
      }) => this.set('billingSystemDownStatus', data.status));
    },

    resetBillingInfo() {
      this.clearAll();
      return this.getBillingInfo();
    },

    promotionEvent(params) {
      return this.get('apiService').myADT.promotionEvent(params);
    },

    clearAll() {
      this.setProperties({
        easyPayInfo: null,
        canViewBillingLink: true,
        canShowBillingAddress: false,
        billingAddress: null,
        billingModel: {},
        billingStatements: null,
        billingHistory: null,
        billingSystem: null,
        billingSystemDownStatus: null,
        savedAccounts: null,
        hardDeclined: null,
        installationData: null
      });
    },

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.EASYPAY_ENROLL_EVENT, this, 'resetBillingInfo');
      this.billingModel = {};
    }

  });

  _exports.default = _default;
});