define("adt-wss/pods/components/billing/expresspay-form/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.states = _exports.actions = void 0;
  const actions = {
    RESET: 'RESET',
    LOCATE: 'LOCATE',
    LOCATE_AUTH: 'LOCATE_AUTH',
    LOCATE_FLEXFI: 'LOCATE_FLEXFI',
    SHOW_ACCOUNTS: 'SHOW_ACCOUNTS',
    SELECT_ACCOUNT: 'SELECT_ACCOUNT',
    INITIATE_IVR: 'INITIATE_IVR',
    RESOLVE_IVR: 'RESOLVE_IVR',
    REJECT_IVR: 'REJECT_IVR',
    SET_PENDING_PAYMENT: 'SET_PENDING_PAYMENT',
    SHOW_PENDING_PAYMENT_ERROR: 'SHOW_PENDING_PAYMENT_ERROR',
    SET_PAYMENT_TYPE: 'SET_PAYMENT_TYPE',
    SUBMIT_PAYMENT_DETAILS: 'SUBMIT_PAYMENT_DETAILS',
    REJECT_ACCT_DATA: 'REJECT_ACCT_DATA',
    RESOLVE_ACCT_DATA: 'RESOLVE_ACCT_DATA',
    RESOLVE_FLEXFI: 'RESOLVE_FLEXFI',
    REJECT_FLEXFI: 'REJECT_FLEXFI',
    RESOLVE_PAYMENT: 'RESOLVE_PAYMENT',
    REJECT_PAYMENT: 'REJECT_PAYMENT',
    PAYMENT_LOADED: 'PAYMENT_LOADED'
  };
  _exports.actions = actions;
  const states = {
    IDLE: 'IDLE',
    INITIATING_IVR: 'INITIATING_IVR',
    FETCHING_ACCT_DATA: 'FETCHING_ACCT_DATA',
    FETCHING_FLEXFI: 'FETCHING_FLEXFI',
    ACCOUNT_LIST: 'ACCOUNT_LIST',
    PAYMENT_DETAILS: 'PAYMENT_DETAILS',
    HAS_PAYMENT: 'HAS_PAYMENT',
    SETTING_PAYMENT_TYPE: 'SETTING_PAYMENT_TYPE',
    SUBMITTING_PAYMENT: 'SUBMITTING_PAYMENT',
    COMPLETED_PAYMENT: 'COMPLETED_PAYMENT'
  };
  _exports.states = states;
});