define("adt-wss/pods/components/billing/expresspay-form/payment-messages/component", ["exports", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/myadt-helpers"], function (_exports, _appDispatcher, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Universal display for warning and API messages
   * @module Components/Billing/ExpressPayForm/PaymentMessages
   * @example {{components/billing/expresspay-form/payment-messages}}
   */
  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    actions: {
      sendEasyPayEvent() {
        const {
          appDispatcher,
          router,
          ET
        } = this.getProperties('appDispatcher', 'router', 'ET');
        const promise = new Ember.RSVP.Promise(async resolve => {
          appDispatcher.sendEvent(_appDispatcher.HIDE_EXPRESS_PAY_EVENT);
          await router.transitionTo('dashboard.account.payments');
          return resolve();
        });
        promise.then(() => {
          (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'EasyPay Promotion', 'Enroll in EasyPay', 'Express Pay']);
          ET.sendEventTag({
            eventTypeId: 'EZPAYPRO',
            subEventTypeId: 'ENREZPY',
            udf2: 'EX_PAY'
          }, false, '1');
          appDispatcher.sendEvent(_appDispatcher.SHOW_EASYPAY_ENROLL_EVENT);
        });
      }

    }
  });

  _exports.default = _default;
});