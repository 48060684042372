define("adt-wss/pods/dashboard/account/payments/manageeasypay/route", ["exports", "adt-wss/pods/dashboard/account/payments/savedpayments/route", "adt-wss-common/utils/route-helpers"], function (_exports, _route, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    billingData: Ember.inject.service(),
    flexfiData: Ember.inject.service(),
    makeDeliverData: _routeHelpers.makeDeliverData,
    hashSettled: Ember.RSVP.hashSettled,
    useResolvedOnly: _routeHelpers.useResolvedOnly,

    model() {
      const flexfiDeliverData = this.makeDeliverData(this, 'flexfiData');
      const flexfiDetails = flexfiDeliverData('data', flexfiData => flexfiData.getFlexFiDetails());
      const enrolledInEasyPay = this.get('billingData.billingModel.billingOptions.enrolledInEasyPay');
      return this.hashSettled({
        enrolledInEasyPay,
        flexfiDetails
      }).then(this.useResolvedOnly);
    }

  });

  _exports.default = _default;
});