define("adt-wss/pods/components/dashboard/shop/shop-battery/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/fp", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/common-fsm-state"], function (_exports, _withFsm, _emberHelpers, _myadtHelpers, _fp, _appDispatcher, _commonFsmState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.filterResultsBySystemName = _exports.defaultProperties = void 0;
  const defaultProperties = {
    systemList: null,
    batteryList: null,
    userSystemInfo: null,
    batteryTypes: ['System', 'Sensor']
  };
  /**
   * Shows only relevant batteries for selected system
   * @param systemList - API response with all systems that user has
   * @param userSystemValue - value of drop-down with results depending system's CsNo
   * @returns {Array} - returns the new array of relevant batteries for selected system
   */

  _exports.defaultProperties = defaultProperties;

  const filterResultsBySystemName = (systemList, userSystemValue) => {
    const csNo = userSystemValue.csNo.split('-')[0].trim();
    return (systemList || []).filter(el => el.csNo === csNo);
  };
  /**
   * @module Components/Dashboard/Shop/YardSignsDecals
   * @prop {Array} systemList - user systems
   * @prop {OrderBattery[]} batteryList - batteries list
   * @prop {Array} orderInfo - user systems
   * @prop {String} batteryValue - 'System' or 'Sensor'
   * @prop {String} userSystemValue - default: 'Select system' | from `userSystemInfo`
   * @prop {Boolean} batteryValueBoolean - `batteryValue === 'Sensor'`
   * @prop {Boolean} isLoading
   * @prop {String[]} userSystemCombo - '[csNo] - Primary' | populates CS no drop down
   * @prop {String[]} batteryTypes - 'System', 'Sensor'
   *
   * @example
   * {@lang xml} {{ dashboard/Shop/yard-signs-decals
  		systemList=null
  	}}
   */


  _exports.filterResultsBySystemName = filterResultsBySystemName;

  var _default = Ember.Component.extend(defaultProperties, _withFsm.default, _commonFsmState.commonMixin, {
    classNames: ['battery-list'],
    mwd: Ember.inject.service('modal-window-dispatcher'),
    systemInformation: Ember.inject.service(),
    batteryData: Ember.inject.service(),
    router: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    batteryValue: 'System',
    showAllBatteriesList: false,
    filteredResults: (0, _emberHelpers.computedApplyFunction)(filterResultsBySystemName, 'systemList', 'userSystemValue'),
    userSystemValue: 'Select system',
    batteryValueBoolean: Ember.computed.equal('batteryValue', 'Sensor'),
    isLoading: (0, _emberHelpers.isState)(_commonFsmState.LOADING_STATE),
    systemName: (0, _emberHelpers.computedApplyFunction)(filteredResults => filteredResults.map(el => el.csNoAlias)[0], 'filteredResults'),
    batteryValueChanged: Ember.observer('userSystemValue', function () {
      this.set('showAllBatteriesList', false);
    }),
    actions: {
      openRetailersModal(batteryName) {
        this.openRetailersModal(batteryName);
      },

      /**
      * Shows all batteries list
      * @returns {*}
      */
      showAllBatteries() {
        this.set('showAllBatteriesList', true);
      },

      /**
      * Gets whole and fractional part from full price
      * @param fullPrice - full price
      * @param priceValue - dollars or cents
      * @returns {String} - returns whole value depending on priceValue
      */
      splitPrice(fullPrice, priceValue) {
        const pSplit = fullPrice.toString().split('.');
        return priceValue === 'dollars' ? pSplit[0] : pSplit[1] || '00';
      },

      /**
       * Opens intermediary modal before order page
       * @param {OrderBattery} battery
       */
      openSystemOrderModal(battery) {
        const mwd = this.get('mwd');

        const onClose = () => mwd.hide();

        const body = (0, _fp.omix)(battery, {
          showIframe: false
        });
        const orderBattery = [{
          caption: 'Order Battery',
          callback: () => this.get('router').transitionTo('dashboard.shop.checkout.batteryid', battery.batteryID)
        }];
        const retailers = [{
          caption: 'Retailers',
          stl: 'outline',
          callback: () => {
            mwd.hide();
            this.openRetailersModal();
          }
        }];
        const actions = (battery.availableInRetailStore ? retailers : []).concat(orderBattery);

        body.toggleShowIframe = () => Ember.set(body, 'showIframe', true);

        mwd.show({
          name: 'system-battery-confirm-modal',
          title: 'Important System Battery Information',
          body,
          onClose,
          actions
        });
      },

      openSensorOrderModal(battery) {
        const mwd = this.get('mwd');

        const onClose = () => mwd.hide();

        const body = (0, _fp.omix)(battery, {
          showIframe: false
        });
        const orderBattery = [{
          caption: 'Order Battery',
          callback: () => this.get('router').transitionTo('dashboard.shop.checkout.batteryid', battery.batteryID)
        }];
        const retailers = [{
          caption: 'Retailers',
          stl: 'outline',
          callback: () => {
            mwd.hide();
            this.openRetailersModal();
          }
        }];
        const actions = (battery.availableInRetailStore ? retailers : []).concat(orderBattery);

        body.toggleShowIframe = () => Ember.set(body, 'showIframe', true);

        mwd.show({
          name: 'sensor-battery-confirm-modal',
          title: 'Important Sensor Battery Information',
          body,
          onClose,
          actions
        });
      }

    },

    /**
     * Opens modal with links to retailers
     * @param {String} batteryName
     */
    openRetailersModal(batteryName) {
      const mwd = this.get('mwd');

      const close = () => mwd.hide();

      mwd.show({
        title: 'Shop Other Retailers',
        name: 'other-retailers',
        body: this,
        onClose: close,
        actions: [{
          caption: 'Close',
          stl: 'outline',
          callback: close
        }]
      });
      const eventTagInfo = {
        eventTypeId: 'BATTMODL',
        subEventTypeId: 'RETCLICK'
      };
      this.get('ET').sendEventTag(eventTagInfo);
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Products', 'Battery Retailers Modal', "Interested in: ".concat(this.get('batteryValue'), ": ").concat(batteryName)]);
    },

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    changeLocationHandler() {
      this.get('fsm').dispatch(_commonFsmState.FETCH_DATA_ACTION, true);
    },

    /**
     * Populates relevant data on success of
     * `FETCH_DATA_ACTION`
     * @param {Object} data
     * @param {Object[]} systemList
     * @param {OrderBattery[]} batteryList
     */
    [_commonFsmState.SUCCESS_ACTION]({
      systemList,
      batteryList
    }) {
      this.set('userSystemInfo', systemList.map(el => ({
        csNo: "".concat(el.csNo, " - Primary"),
        csNoAlias: el.csNoAlias
      })));
      this.set('userSystemValue', this.get('userSystemInfo.0'));
      this.setProperties({
        systemList,
        batteryList
      });
    },

    [_commonFsmState.FETCH_DATA_ACTION](forceRequest) {
      return this.deliverData('systemInformation.systemInfoList', systemInformation => systemInformation.getSystemInfoList(), {
        forceRequest
      }).then(systemList => this.deliverData('batteryData.batteryList', batteryData => batteryData.getBatteryList(), {
        forceRequest
      }).then(batteryList => {
        const results = {
          systemList,
          batteryList
        };
        return this.get('fsm').dispatch('SUCCESS_ACTION', results);
      }));
    }

  });

  _exports.default = _default;
});