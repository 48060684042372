define("adt-wss/pods/help/search/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const searchQuery = /^[~]?[0-9a-z\s]+$/i.test(params.search_query) ? params.search_query : null;
      return {
        encoded: window.btoa(searchQuery),
        raw: searchQuery
      };
    }

  });

  _exports.default = _default;
});