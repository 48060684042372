define("adt-wss/pods/dashboard/account/controller", ["exports", "adt-wss/mixins/dashboard-status-messages", "adt-wss-common/utils/ember-helpers", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/myadt-helpers", "adt-wss/services/billing-data"], function (_exports, _dashboardStatusMessages, _emberHelpers, _appDispatcher, _myadtHelpers, _billingData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const links = [{
    route: 'dashboard.account.payments',
    text: 'Payments',
    hideIfNotBilling: true
  }, {
    route: 'dashboard.account.profile',
    text: 'Profile'
  }, {
    route: 'dashboard.account.preferences',
    text: 'Preferences'
  }, {
    route: 'dashboard.account.contacts',
    text: 'Emergency Contacts',
    hideIfNotContacts: true,
    hideIfNoPic: true,
    hideIfCyberOnly: true,
    hideIfAdtGoOnly: true
  }, {
    route: 'dashboard.account.documents',
    text: 'Account Documents'
  }];

  var _default = Ember.Controller.extend(_dashboardStatusMessages.default, {
    myadtSecurity: Ember.inject.service(),
    billingData: Ember.inject.service(),
    promotionData: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    alertShow: false,
    showEasyPayPromo: true,
    ET: Ember.inject.service('event-tagging'),
    links: (0, _emberHelpers.computedApplyFunction)((permissions, picProvided, cyberOnly, adtGoOnly) => links.filter(l => {
      let out = true;
      out = !(l.hideIfNotBilling && !permissions.billing);

      if (out) {
        out = !(l.hideIfNotContacts && permissions.hideContacts);
      }

      if (out) {
        out = !(l.hideIfNoPic && !picProvided);
      }

      if (out) {
        out = !(l.hideIfCyberOnly && cyberOnly);
      }

      if (out) {
        out = !(l.hideIfAdtGoOnly && adtGoOnly);
      }

      return out;
    }), 'model.permissions', 'myadtSecurity.picProvided', 'currentLocation.cyberOnly', 'currentLocation.adtGoOnly'),
    easyPayPromotion: (0, _emberHelpers.computedApplyFunction)(promotions => (promotions || []).find(p => p.promotionType.includes(_billingData.EASYPAY_SWITCH_TO_ACH)), 'promotionData.promotions'),
    showEasyPayPromoAlert: (0, _emberHelpers.computedApplyFunction)((promotions, showEasyPayPromo) => (promotions || []).some(el => el.promotionType.includes(_billingData.EASYPAY_SWITCH_TO_ACH) && showEasyPayPromo), 'promotionData.promotions', 'showEasyPayPromo'),

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.HIDE_EASYPAY_PROMO_ALERT, this, _appDispatcher.HIDE_EASYPAY_PROMO_ALERT);
      this.get('appDispatcher').subscribe(_appDispatcher.ONE_TIME_PAYMENT_EVENT, this, _appDispatcher.ONE_TIME_PAYMENT_EVENT);
      this.get('appDispatcher').subscribe(_appDispatcher.HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT, this, _appDispatcher.HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT);
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.HIDE_EASYPAY_PROMO_ALERT, this, _appDispatcher.HIDE_EASYPAY_PROMO_ALERT);
      this.get('appDispatcher').unsubscribe(_appDispatcher.ONE_TIME_PAYMENT_EVENT, this, _appDispatcher.ONE_TIME_PAYMENT_EVENT);
      this.get('appDispatcher').unsubscribe(_appDispatcher.HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT, this, _appDispatcher.HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT);
    },

    [_appDispatcher.ONE_TIME_PAYMENT_EVENT]() {
      this.set('alertShow', true);
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'EasyPay Promotion', 'Alert Display', '1 time payment #2']);
      this.get('ET').sendEventTag({
        eventTypeId: 'EZPAYPRO',
        subEventTypeId: 'ALRTDISP',
        udf2: '1 x PAYMENT'
      }, false, '1');
    },

    [_appDispatcher.HIDE_EASYPAY_PROMO_ALERT]() {
      this.set('showEasyPayPromo', false);
    },

    [_appDispatcher.HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT]() {
      this.set('alertShow', false);
    },

    actions: {
      closoEasyPayPromo({
        promotionType
      }) {
        this.get('promotionData').incrementDispositionCounter(promotionType);
      }

    }
  });

  _exports.default = _default;
});