define("adt-wss/pods/dashboard/manage/users/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QPGTAy4l",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"webUsers\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"section-content\"],[8],[0,\"\\n\\t\\t\"],[7,\"h4\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n\\t\\t\\tAccount Users\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[10,\"class\",\"sub-title\"],[8],[0,\"Add and manage users who have access to this account and location.\"],[9],[0,\"\\n\\t\\t\"],[7,\"a\",false],[12,\"class\",\"add-user right\"],[12,\"href\",\"\"],[3,\"action\",[[23,0,[]],\"startAddUser\"]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"img\",true],[10,\"alt\",\"plus\"],[11,\"src\",[29,[[22,\"rootURL\"],\"images/v2/icons/plus-slim.svg\"]]],[10,\"height\",\"16\"],[8],[9],[0,\"\\n\\t\\t\\tAdd User\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[1,[28,\"dashboard/manage/users/user-list\",null,[[\"users\",\"accountEmail\",\"addAdmin\",\"removeAdmin\",\"deleteUser\"],[[24,[\"model\"]],[24,[\"profileInfo\",\"email\"]],[28,\"action\",[[23,0,[]],\"startAddUserAdmin\"],null],[28,\"action\",[[23,0,[]],\"startRemoveUserAdmin\"],null],[28,\"action\",[[23,0,[]],\"startDeleteUser\"],null]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/manage/users/template.hbs"
    }
  });

  _exports.default = _default;
});