define("adt-wss/pods/components/ps/myadt-login-ps/component", ["exports", "adt-wss-common/utils/localization", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/mixins/with-fsm"], function (_exports, _localization, _myadtHelpers, _withFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fsmModel = {
    transitions: {
      idle: {
        authenticate: 'fetchingLogin',
        passwordReset: 'idle'
      },
      fetchingLogin: {
        resolveAuth: 'fetchingCustData',
        rejectAuth: 'idle'
      },
      fetchingCustData: {
        resolveCustData: 'loggedIn',
        rejectCustData: 'loggedIn'
      },
      loggedIn: null,
      loginFailure: {
        authenticate: 'fetchingLogin'
      }
    },
    context: void 0
  };
  /**
   * MyADT Login Component
   * @module Components/MyADTLoginPs
   * @prop {String} currentState
   * @prop {Computed<Boolean>} isFetching
   * @prop {String} loginUserId
   * @prop {String} loginPassword
   * @prop {Boolean} loginUserIdError
   * @prop {Boolean} deactivatedAccountError
   * @prop {Boolean} loginPasswordError
   * @prop {String} loginUser
   * @prop {String} email
   * @prop {String} chatId
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    session: Ember.inject.service(),
    apiService: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    billingData: Ember.inject.service(),
    router: Ember.inject.service(),
    myadtSecurity: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    contactData: Ember.inject.service(),
    alarmData: Ember.inject.service(),
    fsmModel,
    currentState: 'idle',
    loginUserId: '',
    loginPassword: '',
    loginUserIdError: false,
    deactivatedAccountError: false,
    loginPasswordError: false,
    errorMessages: null,
    loginUser: null,
    rendered: false,
    rejectCount: 0,
    chatId: null,
    psAuthToken: Ember.computed.readOnly('authInfo.psAuthToken'),
    isFetching: Ember.computed('currentState', function () {
      return /ing/i.test(this.get('currentState'));
    }),

    /**
     * Fires event to Pypestream
     */
    passwordReset() {
      window.top.postMessage({
        psForgotPwd: 'true'
      }, '*');
      window.setTimeout(function () {
        if (/www.myadt|\/\/myadt.com/i.test(document.location.href)) {
          window.location.replace('https://webchat.pypestream.com/redirect.html');
        } else {
          window.location.replace('https://webchat-sandbox.pypestream.com/redirect.html');
        }
      }, 1000);
    },

    /**
     * Runs `session.authenticate`.
     * Resolves with `resolveAuth`
     */
    authenticate() {
      const {
        fsm,
        loginPassword
      } = this.getProperties('fsm', 'loginPassword');
      const ERROR_DEACTIVATED = 'MyADT.Login.Error.Deactivated';
      const ERROR_DEACTIVATED_PARTIAL = 'MyADT.Login.Error.DeactivatedPartial';
      let loginUserId = this.get('loginUserId');
      sessionStorage.removeItem('myADT_logoutReason');
      loginUserId = loginUserId.trim();
      const isPS = this.set('isPS', true);
      let chatID = window.sessionStorage.getItem("pypestream-webchat-chat-id");

      if (loginUserId && loginUserId.isValidEmail() && loginPassword) {
        const credentials = {};
        credentials.userId = loginUserId;
        credentials.password = loginPassword;
        const chatParam = new URLSearchParams(window.location.search);
        const chatId = chatParam.get('chatId');
        credentials.chatId = chatId;
        return this.get('apiService').myADT.psLogin(credentials).then(response => {
          if (response.data.adtUser) {
            this.set('errorMessages', null);
            const authToken = this.get('apiService.authorizationToken');
            const trimToken = authToken.substr(7);
            window.top.postMessage({
              authToken: trimToken
            }, '*');
            return fsm.dispatch('resolveAuth');
          }

          if (!response.messages) response.messages = [response.data.message];
          if (!response.errors) response.errors = [response.data.message];
          response.success = false;
          return fsm.dispatch('rejectAuth', response);
        }).catch(results => {
          this.set('deactivatedAccountError', results.errors.includes(ERROR_DEACTIVATED));
          this.set('errorMessages', (results.errors || []).map(e => (0, _localization.toExpandedString)(e === ERROR_DEACTIVATED ? ERROR_DEACTIVATED_PARTIAL : e)));
          this.incrementProperty('rejectCount');

          if (this.get('rejectCount') >= 4) {
            const detail = {
              psError: 'error-login-ps'
            };
            window.top.postMessage(detail, '*');
            window.setTimeout(function () {
              if (/www.myadt|\/\/myadt.com/i.test(document.location.href)) {
                window.location.replace('https://webchat.pypestream.com/redirect.html');
              } else {
                window.location.replace('https://webchat-sandbox.pypestream.com/redirect.html');
              }
            }, 1000);
          }

          return fsm.dispatch('rejectAuth', results);
        });
      }

      const errors = [];

      if (loginUserId && loginUserId.isValidEmail()) {
        this.set('loginUserIdError', false);
      } else {
        this.set('loginUserIdError', true);

        if (!loginUserId) {
          errors.push((0, _localization.toExpandedString)('UserCredentials.userIdRequired'));
        } else if (!loginUserId.isValidEmail()) {
          errors.push((0, _localization.toExpandedString)('Login.errorInvalidEmail'));
        }
      }

      if (!loginPassword) {
        this.set('loginPasswordError', true);
        errors.push((0, _localization.toExpandedString)('UserCredentials.passwordRequired'));
      }

      this.set('errorMessages', errors);
      return fsm.dispatch('rejectAuth', {
        data: null
      });
    },

    resolveAuth() {
      this.set('isFetching', true);
      window.setTimeout(function () {
        if (/www.myadt|\/\/myadt.com/i.test(document.location.href)) {
          window.location.replace('https://webchat.pypestream.com/redirect.html');
        } else {
          window.location.replace('https://webchat-sandbox.pypestream.com/redirect.html');
        }
      }, 1000);
    },

    rejectAuth(results) {
      return results.data;
    },

    resolveCustData() {// this.send('getUserData');
    },

    startAuth() {
      return Ember.RSVP.hash({
        security: this.get('myadtSecurity').start(),
        profileInfo: this.get('currentLocation').getAccountInfo(true)
      });
    },

    actions: {
      dispatch() {
        this.get('fsm').dispatch(...arguments);
      },

      authenticate() {
        this.authenticate(...arguments);
      },

      returnToLogin() {
        this.set('currentState ', 'idle');
      },

      validateUserId(email) {
        const errors = [];
        const emailTrimmed = email.trim();

        if (emailTrimmed && !emailTrimmed.isValidEmail()) {
          this.set('loginUserIdError', true);
          errors.push((0, _localization.toExpandedString)('Login.errorInvalidEmail'));
        } else {
          this.set('loginUserIdError', false);
        }

        this.set('errorMessages', errors);
      }

    },

    clearErrors() {
      this.set('errorMessages', null);
    },

    handleErrors(results) {
      const errors = (0, _myadtHelpers.genericErrorHandler)(results);
      this.set('errorMessages', errors);
    },

    returnToLogin() {
      this.set('currentState ', 'idle');
    },

    init() {
      this._super(...arguments);

      if (this.get('session.isAuthenticated')) {
        this.set('isFetching', true);
      }

      const authInfo = this.get('authInfo.authInfo');

      if (authInfo) {
        const tokenVal = this.get('psAuthToken');

        if (tokenVal) {
          const trimToken = tokenVal.substr(7);
          window.top.postMessage({
            authToken: trimToken
          }, '*');
          window.setTimeout(function () {
            if (/www.myadt|\/\/myadt.com/i.test(document.location.href)) {
              window.location.replace('https://webchat.pypestream.com/redirect.html');
            } else {
              window.location.replace('https://webchat-sandbox.pypestream.com/redirect.html');
            }
          }, 1000);
        }
      }
    },

    didReceiveAttrs() {
      this._super(...arguments);

      const attemptedTransition = this.get('session.attemptedTransition');
      if (attemptedTransition) this.set('redirect', attemptedTransition);
    },

    didRender() {
      const loginUserIdEl = document.getElementById('loginUserId');

      if (!this.get('rendered')) {
        this.set('rendered', true);

        if (!loginUserIdEl) {
          return false;
        }

        loginUserIdEl.focus();
      }
    }

  });

  _exports.default = _default;
});