define("adt-wss/pods/components/dashboard/account/communication-preferences/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DISCLAIMER_MESSAGE = _exports.PRODUCTS_AND_OFFERS_SUCCESS_MESSAGE = _exports.MONITORING_CHANGED_MESSAGE = _exports.TRAVEL_REMINDER_DELETED_MESSAGE = _exports.TRAVEL_REMINDER_ADDED_MESSAGE = _exports.PRIMARY_EMAILS_SUCCESSFULLY_SUBSCR_MESSAGE = _exports.SUCCESSFULLY_UPDATED_MESSAGE = _exports.GENERIC_ERROR_MESSAGE = _exports.FAILED_LOADING_ACTION = _exports.SUCCESSFUL_LOADING_ACTION = _exports.LOAD_DATA_ACTION = _exports.LOADING_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const LOADING_STATE = 'LOADING_STATE';
  _exports.LOADING_STATE = LOADING_STATE;
  const LOAD_DATA_ACTION = 'LOAD_DATA_ACTION';
  _exports.LOAD_DATA_ACTION = LOAD_DATA_ACTION;
  const SUCCESSFUL_LOADING_ACTION = 'SUCCESSFUL_LOADING_ACTION';
  _exports.SUCCESSFUL_LOADING_ACTION = SUCCESSFUL_LOADING_ACTION;
  const FAILED_LOADING_ACTION = 'FAILED_LOADING_ACTION';
  _exports.FAILED_LOADING_ACTION = FAILED_LOADING_ACTION;
  const GENERIC_ERROR_MESSAGE = 'Generic.error';
  _exports.GENERIC_ERROR_MESSAGE = GENERIC_ERROR_MESSAGE;
  const SUCCESSFULLY_UPDATED_MESSAGE = 'Preferences successfully saved';
  _exports.SUCCESSFULLY_UPDATED_MESSAGE = SUCCESSFULLY_UPDATED_MESSAGE;
  const PRIMARY_EMAILS_SUCCESSFULLY_SUBSCR_MESSAGE = 'Primary email opt-in successful.';
  _exports.PRIMARY_EMAILS_SUCCESSFULLY_SUBSCR_MESSAGE = PRIMARY_EMAILS_SUCCESSFULLY_SUBSCR_MESSAGE;
  const TRAVEL_REMINDER_ADDED_MESSAGE = 'Travel Reminder was successfully added';
  _exports.TRAVEL_REMINDER_ADDED_MESSAGE = TRAVEL_REMINDER_ADDED_MESSAGE;
  const TRAVEL_REMINDER_DELETED_MESSAGE = 'Travel Reminder was successfully deleted';
  _exports.TRAVEL_REMINDER_DELETED_MESSAGE = TRAVEL_REMINDER_DELETED_MESSAGE;
  const MONITORING_CHANGED_MESSAGE = 'Monitoring Reports was successfully updated';
  _exports.MONITORING_CHANGED_MESSAGE = MONITORING_CHANGED_MESSAGE;
  const PRODUCTS_AND_OFFERS_SUCCESS_MESSAGE = 'Products & Offers Preference Saved Successfully';
  _exports.PRODUCTS_AND_OFFERS_SUCCESS_MESSAGE = PRODUCTS_AND_OFFERS_SUCCESS_MESSAGE;
  const DISCLAIMER_MESSAGE = 'You can stop receiveing text alerts by texting <strong>STOP</strong> from your mobile phone.' + ' Text <strong>Help</strong> for Help. Message and data rates may apply.';
  _exports.DISCLAIMER_MESSAGE = DISCLAIMER_MESSAGE;
});