define("adt-wss/pods/index/expresspay/remote/uuid/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    EPS: Ember.inject.service('expresspay-service'),

    model(params) {
      let {
        uuid
      } = params;

      if (/[&#?]/.test(params.uuid)) {
        uuid = params.uuid.split(/[&#?]/)[0];
      }

      uuid = /^[-0-9a-z]+$/i.test(uuid) ? uuid : null;
      return this.set('EPS.uuid', uuid);
    }

  });

  _exports.default = _default;
});