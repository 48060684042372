define("adt-wss/pods/components/ps/credit-enquiryform/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SUBMIT_SUCCESS_ACTION = _exports.COMPLETED_STATE = _exports.REJECT_SUBMIT_ACTION = _exports.RESOLVE_SUBMIT_ACTION = _exports.SUBMIT_ACTION = _exports.FETCHING_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const FETCHING_STATE = 'FETCHING_STATE';
  _exports.FETCHING_STATE = FETCHING_STATE;
  const SUBMIT_ACTION = 'SUBMIT_ACTION';
  _exports.SUBMIT_ACTION = SUBMIT_ACTION;
  const RESOLVE_SUBMIT_ACTION = 'RESOLVE_SUBMIT_ACTION';
  _exports.RESOLVE_SUBMIT_ACTION = RESOLVE_SUBMIT_ACTION;
  const REJECT_SUBMIT_ACTION = 'REJECT_SUBMIT_ACTION';
  _exports.REJECT_SUBMIT_ACTION = REJECT_SUBMIT_ACTION;
  const COMPLETED_STATE = 'COMPLETED_STATE';
  _exports.COMPLETED_STATE = COMPLETED_STATE;
  const SUBMIT_SUCCESS_ACTION = 'SUBMIT_SUCCESS_ACTION';
  _exports.SUBMIT_SUCCESS_ACTION = SUBMIT_SUCCESS_ACTION;
});