define("adt-wss/pods/components/stupid/amount-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "afOIp5C8",
    "block": "{\"symbols\":[],\"statements\":[[0,\"$\"],[1,[28,\"input\",null,[[\"value\",\"type\",\"keyUp\",\"focusOut\"],[[24,[\"value\"]],\"number\",[24,[\"onKeyUp\"]],[28,\"action\",[[23,0,[]],\"parseDollarToFloat\",[24,[\"value\"]]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/stupid/amount-input/template.hbs"
    }
  });

  _exports.default = _default;
});