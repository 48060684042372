define("adt-wss/pods/billinghelp/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BoBi5Q08",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"pageWrap\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"limit-wrapper\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"id\",\"indexContent\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"index/meta-header\"],false],[0,\"\\n\\t\\t\\t\"],[1,[22,\"index/mobile-header\"],false],[0,\"\\n\\t\\t\\t\"],[1,[22,\"index/main-nav\"],false],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"help-iframe-container flex flex-column\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"stupid/simple-iframe\",null,[[\"url\",\"iFrameID\",\"classNames\"],[[28,\"concat\",[[24,[\"aemHostUrl\"]],\"help/\",[24,[\"model\",\"full\"]],\".html\"],null],[28,\"concat\",[\"help\",[24,[\"model\",\"helpId\"]]],null],\"search-iframe\"]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/billinghelp/template.hbs"
    }
  });

  _exports.default = _default;
});