define("adt-wss/services/coi-info", ["exports", "adt-wss-common/utils/myadt-helpers"], function (_exports, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const clearCacheArgs = {
    certificateInfo: null,
    allSystemsForSite: null,
    primarySystemForSite: null
  };
  /**
   * COI Info Service Module
   * @module Services/CoiInfo
   *
   * @prop {Object} certificateInfo
   * @prop {Object[]} allSystemsForSite
   * @prop {Object} primarySystemForSite
   */

  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)(clearCacheArgs), {
    apiService: Ember.inject.service(),
    certificateInfo: null,
    allSystemsForSite: null,
    primarySystemForSite: null,

    /**
     * Populate certificate from API and sets `certificateInfo`
     * @param {Object} params - Parameters for http request
     * @return {Promise}
     */
    populateCertificate(params) {
      return this.get('apiService').myADT.populateCertificate(params).then(({
        data
      }) => this.set('certificateInfo', data));
    }

  });

  _exports.default = _default;
});