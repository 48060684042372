define("adt-wss/pods/components/three-g/forced-self-schedule/component", ["exports", "adt-wss-common/classes/fsm-component", "adt-wss-common/classes/status-message", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/fp", "ember-power-calendar-utils", "adt-wss/pods/components/three-g/forced-self-schedule/constants", "adt-wss/services/app-dispatcher"], function (_exports, _fsmComponent, _statusMessage, _myadtHelpers, _emberHelpers, _fp, _emberPowerCalendarUtils, _constants, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  let _actions$GET_CUSTOMER, _actions$RESOLVE_GET_, _actions$VOID_ELIGIBI, _actions$CANCEL_WITHO, _actions$CHAT, _actions$CREATE_JOB, _actions$GET_TIME_BLO, _actions$RESOLVE_GET_2, _actions$SCHEDULE_APP, _actions$RESOLVE_SCHE, _actions$REJECT, _actions$CLOSE;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  const fsmModel = {
    transitions: {
      [_constants.states.IDLE]: {
        [_constants.actions.GET_CUSTOMER_DATA]: _constants.states.GETTING_CUSTOMER_DATA
      },
      [_constants.states.GETTING_CUSTOMER_DATA]: {
        [_constants.actions.RESOLVE_GET_CUSTOMER_DATA]: _constants.states.WELCOME,
        [_constants.actions.REJECT]: _constants.states.IDLE
      },
      [_constants.states.WELCOME]: {
        [_constants.actions.VOID_ELIGIBILITY]: _constants.states.INELIGIBLE_CHAT,
        [_constants.actions.CONFIRM_ELIGIBILITY]: _constants.states.COVID_Q
      },
      [_constants.states.INELIGIBLE]: {
        [_constants.actions.CLOSE]: _constants.states.IDLE
      },
      [_constants.states.INELIGIBLE_CHAT]: {
        [_constants.actions.CLOSE]: _constants.states.IDLE,
        [_constants.actions.CHAT]: _constants.states.INELIGIBLE_CHAT
      },
      [_constants.states.COVID_Q]: {
        [_constants.actions.VOID_ELIGIBILITY]: _constants.states.INELIGIBLE_CHAT,
        [_constants.actions.CONFIRM_ELIGIBILITY]: _constants.states.CALENDAR
      },
      [_constants.states.CALENDAR]: {
        [_constants.actions.CREATE_JOB]: _constants.states.GETTING_TIME_BLOCKS,
        [_constants.actions.GET_TIME_BLOCKS]: _constants.states.GETTING_TIME_BLOCKS
      },
      [_constants.states.GETTING_TIME_BLOCKS]: {
        [_constants.actions.RESOLVE_GET_TIME_BLOCKS]: _constants.states.TIME_SLOTS,
        [_constants.actions.REJECT]: _constants.states.INELIGIBLE_CHAT
      },
      [_constants.states.TIME_SLOTS]: {
        [_constants.actions.SCHEDULE_APPT]: _constants.states.SCHEDULING_APPT,
        [_constants.actions.RETURN_TO_CALENDAR]: _constants.states.CALENDAR,
        [_constants.actions.CANCEL_WITHOUT_APPT]: _constants.states.INELIGIBLE_CHAT
      },
      [_constants.states.SCHEDULING_APPT]: {
        [_constants.actions.RESOLVE_SCHEDULE]: _constants.states.SUCCESS,
        [_constants.actions.REJECT]: _constants.states.INELIGIBLE_CHAT
      },
      [_constants.states.SUCCESS]: {
        [_constants.actions.CLOSE]: _constants.states.IDLE
      }
    }
  };
  const ineligibiltyMsgs = {
    otherProblems: Ember.String.htmlSafe('I\'m sorry to hear that you are having additional troubles with your system. We have plenty of support resources on <a class="blue-link" target="_blank" href="/help/alpha/116">MyADT.com</a> and you can always dial <strong>800-ADT-ASAP</strong> to further assist your needs. If necessary, we will schedule a technician and your radio replacement at the same time. If your problems have been resolved and you\'d like to schedule your radio replacement, you can do it <a class="blue-link" target="_blank" href="/3gconversion">here</a>.'),
    haveTheRona: Ember.String.htmlSafe('We understand this may not be the right time to address your conversion. Please return to schedule your service visit when no one in the home is impacted by COVID-19 or chat with one of our agents now to schedule a future visit.'),
    overdidIt: Ember.String.htmlSafe('For additional options for appointments, please call (800) 724-9118 or chat with an agent.'),
    apiFail: Ember.String.htmlSafe('To schedule your conversation appointment, please give us a call at your convenience at (800)-724-9118 or Chat with an agent.')
  };
  const psIneligibilityKeys = {
    otherProblems: 'otherSystemProblems',
    haveTheRona: 'covid19Exposure',
    overdidIt: 'noSuitableAppointment',
    apiFail: 'webServiceFailure'
  };
  /**
   * Calculating dates to show arrow forward
   * @param {Date} maxDate
   * @param {Date} month
   */

  const showArrowForward = (maxDate, month) => (0, _emberPowerCalendarUtils.isAfter)(maxDate, month);
  /**
   * Calculating first day of month or current day
   * @param {Date} month
   */


  const firstDayOfMonth = month => {
    const firstDay = (0, _emberPowerCalendarUtils.isSame)(month, new Date(), 'day') ? month : (0, _emberPowerCalendarUtils.startOf)(month, 'month');
    return (0, _emberPowerCalendarUtils.formatDate)(firstDay, 'YYYY-MM-DDTHH:mm:ss.SSS');
  };
  /**
   * Calculating last day of month or last available day
   * @param {Date} month
   * @param {Date} maxDate
   */


  const lastDayOfMonth = (month, maxDate) => {
    const lastDay = (0, _emberPowerCalendarUtils.isSame)(maxDate, month, 'month') ? maxDate : (0, _emberPowerCalendarUtils.endOf)(month, 'month');
    return (0, _emberPowerCalendarUtils.formatDate)(lastDay || (0, _emberPowerCalendarUtils.endOf)(month, 'month'), 'YYYY-MM-DDTHH:mm:ss.SSS');
  };
  /**
   * Calculating dates to show arrow back
   * @param {Date} month
   */


  const showArrowBack = (month, minDate) => month.getMonth() > minDate.getMonth();

  let ForcedSelfScheduleComponent = (_dec = Ember.inject.service('modal-window-dispatcher'), _dec2 = Ember.computed('_statusMessages'), _dec3 = Ember.computed('_appointments'), (_class = (_temp = (_actions$GET_CUSTOMER = _constants.actions.GET_CUSTOMER_DATA, _actions$RESOLVE_GET_ = _constants.actions.RESOLVE_GET_CUSTOMER_DATA, _actions$VOID_ELIGIBI = _constants.actions.VOID_ELIGIBILITY, _actions$CANCEL_WITHO = _constants.actions.CANCEL_WITHOUT_APPT, _actions$CHAT = _constants.actions.CHAT, _actions$CREATE_JOB = _constants.actions.CREATE_JOB, _actions$GET_TIME_BLO = _constants.actions.GET_TIME_BLOCKS, _actions$RESOLVE_GET_2 = _constants.actions.RESOLVE_GET_TIME_BLOCKS, _actions$SCHEDULE_APP = _constants.actions.SCHEDULE_APPT, _actions$RESOLVE_SCHE = _constants.actions.RESOLVE_SCHEDULE, _actions$REJECT = _constants.actions.REJECT, _actions$CLOSE = _constants.actions.CLOSE, class ForcedSelfScheduleComponent extends _fsmComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appointmentData", _descriptor, this);

      _initializerDefineProperty(this, "systemInformation", _descriptor2, this);

      _initializerDefineProperty(this, "currentLocation", _descriptor3, this);

      _initializerDefineProperty(this, "mwd", _descriptor4, this);

      _initializerDefineProperty(this, "csrInfo", _descriptor5, this);

      this.fsmModel = fsmModel;

      _initializerDefineProperty(this, "currentState", _descriptor6, this);

      this._statusMessages = null;
      this._appointments = null;

      _initializerDefineProperty(this, "otherProblems", _descriptor7, this);

      _initializerDefineProperty(this, "haveTheRona", _descriptor8, this);

      _initializerDefineProperty(this, "psDetail", _descriptor9, this);

      _initializerDefineProperty(this, "resetCount", _descriptor10, this);

      _initializerDefineProperty(this, "customerData", _descriptor11, this);

      _initializerDefineProperty(this, "selectedDate", _descriptor12, this);

      _initializerDefineProperty(this, "selectedTimeBlock", _descriptor13, this);

      _initializerDefineProperty(this, "ineligibiltyMsg", _descriptor14, this);

      _initializerDefineProperty(this, "somethingWrong", _descriptor15, this);

      this.showedSelfScheduleModalList = [];

      _initializerDefineProperty(this, "center", _descriptor16, this);

      _initializerDefineProperty(this, "maxDate", _descriptor17, this);

      _initializerDefineProperty(this, "minDate", _descriptor18, this);
    }

    get statusMessages() {
      return this._statusMessages;
    }

    get appointments() {
      return this._appointments;
    }

    get startDate() {
      return firstDayOfMonth(this.center);
    }

    get endDate() {
      return lastDayOfMonth(this.center, this.maxDate);
    }

    get availableTimes() {
      const {
        scheduleTimeblocks
      } = this.appointmentData;
      return scheduleTimeblocks ? (scheduleTimeblocks.serviceOffers || []).map(d => (0, _fp.omix)(d, {
        date: Date.createDate(d.date)
      })) : null;
    }

    get showedSelfScheduleModalAtLocation() {
      const list = this.showedSelfScheduleModalList;
      const id = this.currentLocation.currentWebAddressId;
      return !!list.filter(a => a == id).length;
    }

    showedSelfScheduleModal() {
      this.showedSelfScheduleModalList.push(this.currentLocation.currentWebAddressId);
    }

    init() {
      super.init(...arguments);

      if (!this.csrInfo.isCsr && !this.showedSelfScheduleModalAtLocation) {
        this.fsm.dispatch(_constants.actions.GET_CUSTOMER_DATA);
      }

      this.appDispatcher.subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    }

    changeLocationHandler() {
      this._statusMessages = null;
      this.ineligibiltyMsg = null;
      this.resetCount = 0;
      this.selectedDate = null;
      this.selectedTimeBlock = null;
      this.somethingWrong = false;
      this.currentState = _constants.states.IDLE;

      if (!this.csrInfo.isCsr && !this.showedSelfScheduleModalAtLocation) {
        this.fsm.dispatch(_constants.actions.GET_CUSTOMER_DATA);
      }
    }

    [_actions$GET_CUSTOMER]() {
      const {
        fsm,
        systemInformation
      } = this;
      this.psDetail.value = null;
      return systemInformation.getCellUpgradeEligibilityDetails().then(data => {
        if (data.cellTechUpgradeEligible) {
          return fsm.dispatch(_constants.actions.RESOLVE_GET_CUSTOMER_DATA, data);
        }

        return fsm.dispatch(_constants.actions.REJECT, {});
      }).catch(results => fsm.dispatch(_constants.actions.REJECT, results));
    }

    [_actions$RESOLVE_GET_](customerData) {
      const {
        mwd,
        fsm,
        isLoading
      } = this;

      const onClose = success => fsm.dispatch(_constants.actions.CLOSE, success);

      const body = this;
      this.customerData = customerData;
      this.showedSelfScheduleModal();
      body.showForwardArrow = (0, _emberHelpers.computedApplyFunction)((max, c) => showArrowForward(max, c), 'maxDate', 'center');
      body.showBackArrow = (0, _emberHelpers.computedApplyFunction)((c, min) => showArrowBack(c, min), 'center', 'minDate');
      body.isLoading = (0, _emberHelpers.computedApplyFunction)(cs => /ing/i.test(cs), 'currentState');
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackPageview', 'myadt3g/bannerDisplay']);
      return mwd.show({
        title: (0, _emberHelpers.computedApplyFunction)(cs => cs == 'SUCCESS' ? 'Success!' : 'Mandatory Update', 'currentState'),
        name: 'three-g/self-scheduling',
        body,
        isLoading: body.isLoading,
        isFooterLine: false,
        actions: [{
          caption: 'Close',
          hidden: (0, _emberHelpers.computedApplyFunction)(cs => ['INELIGIBLE', 'INELIGIBLE_CHAT'].indexOf(cs) == -1, 'currentState'),
          callback: () => onClose(false)
        }, {
          caption: 'Back to MyADT',
          hidden: (0, _emberHelpers.computedApplyFunction)(cs => cs != 'SUCCESS', 'currentState'),
          callback: () => onClose(true)
        }, {
          caption: 'Submit',
          hidden: (0, _emberHelpers.computedApplyFunction)(cs => cs != 'CALENDAR', 'currentState'),
          callback: this.getOffers
        }, {
          caption: 'Submit',
          hidden: (0, _emberHelpers.computedApplyFunction)(cs => cs != 'TIME_SLOTS', 'currentState'),
          disabled: (0, _emberHelpers.computedApplyFunction)(stb => !stb, 'selectedTimeBlock'),
          callback: this.submitTimeSlot
        }]
      });
    }

    [_actions$VOID_ELIGIBI](msgKey) {
      const keys = {
        otherProblems: ['System Related Question', 'Yes'],
        haveTheRona: ['Covid Related Question', 'Yes'],
        overdidIt: ['Appt Selection', '3 times'],
        apiFail: ['Web Service', 'Error']
      };
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', '3G Self Schedule', keys[msgKey][0], keys[msgKey][1]]);
      this.psDetail.value = psIneligibilityKeys[msgKey];
      this.ineligibiltyMsg = ineligibiltyMsgs[msgKey];
    }

    [_actions$CANCEL_WITHO]() {
      this.psDetail.value = psIneligibilityKeys.overdidIt;
      this.ineligibiltyMsg = ineligibiltyMsgs.overdidIt;
      const {
        appointmentData,
        selectedTimeBlock
      } = this;
      const {
        scheduleTimeblocks
      } = appointmentData;
      const options = {
        jobNo: parseInt(scheduleTimeblocks.jobNo, 10),
        requestThruChudly: false
      };
      this.appointmentData.cancelJobWithoutAppointment(options); // sendAnalyticEvent(['_trackEvent', '3G Self Schedule', 'Appt Selection', '3 times']);
    }

    [_actions$CHAT]() {
      //window.top.postMessage(this.psDetail,'*');

      /*window.Pypestream('config', {
      	passthrough: [this.psDetail],
      });*/
      this[_constants.actions.CLOSE](false);

      this.appDispatcher.sendEvent(_appDispatcher.DISABLE_PS_CHAT_EVENT);
      $("button.uiButton").click();
      this.appDispatcher.sendEvent(_appDispatcher.ENABLE_SF_3G_CHAT_EVENT);
    }

    [_actions$CREATE_JOB]() {
      const {
        selectedDate,
        customerData,
        appointmentData,
        fsm
      } = this;
      this.psDetail.value = null;
      const params = {
        apptStartDate: selectedDate.setMidnight().toZonedISOString(),
        apptEndDate: selectedDate.setMidnight().dateAfterNDays(30).toZonedISOString(),
        systemNo: customerData.systemNo,
        csNo: customerData.csNo
      };
      Ember.set(this, '_statusMessages', null);
      return appointmentData.createProjectJobAndGetApptOffers(params).then(data => fsm.dispatch(_constants.actions.RESOLVE_GET_TIME_BLOCKS, data)).catch(results => {
        this.somethingWrong = true;
        this.ineligibiltyMsg = ineligibiltyMsgs.apiFail;
        return fsm.dispatch(_constants.actions.REJECT, results);
      });
    }

    [_actions$GET_TIME_BLO]() {
      const {
        selectedDate,
        customerData,
        appointmentData,
        fsm
      } = this;
      const {
        scheduleTimeblocks
      } = appointmentData;
      this.psDetail.value = null;
      const params = {
        apptStartDate: selectedDate.setMidnight().toZonedISOString(),
        apptEndDate: selectedDate.setMidnight().dateAfterNDays(30).toZonedISOString(),
        days: 'YYYYYYY',
        customerSiteType: customerData.siteType,
        jobNo: scheduleTimeblocks.jobNo,
        siteNo: customerData.siteNo || 0,
        csNo: customerData.csNo,
        timeBand: scheduleTimeblocks.timeBand
      };
      Ember.set(this, '_statusMessages', null);
      return appointmentData.getScheduleTimeBlocks(params).then(() => fsm.dispatch(_constants.actions.RESOLVE_GET_TIME_BLOCKS)).catch(results => {
        this.somethingWrong = true;
        this.ineligibiltyMsg = ineligibiltyMsgs.apiFail;
        return fsm.dispatch(_constants.actions.REJECT, results);
      });
    }

    [_actions$RESOLVE_GET_2]() {
      this.selectedTimeBlock = null;
      this._statusMessages = null;
    }

    [_actions$SCHEDULE_APP]() {
      const {
        fsm,
        appointmentData,
        selectedTimeBlock
      } = this;
      const {
        scheduleTimeblocks
      } = appointmentData;
      this.psDetail.value = null;
      const startTime = selectedTimeBlock ? /pm/i.test(selectedTimeBlock.startTime) && !/^12/.test(selectedTimeBlock.startTime) ? '' + (parseInt(selectedTimeBlock.startTime.substr(0, 2), 10) + 12) + selectedTimeBlock.startTime.substr(3, 2) : selectedTimeBlock.startTime.split(' ')[0].replace(':', '') : null;
      const endTime = selectedTimeBlock ? /pm/i.test(selectedTimeBlock.endTime) && !/^12/.test(selectedTimeBlock.endTime) ? '' + (parseInt(selectedTimeBlock.endTime.substr(0, 2), 10) + 12) + selectedTimeBlock.endTime.substr(3, 2) : selectedTimeBlock.endTime.split(' ')[0].replace(':', '') : null;
      const options = {
        jobNo: parseInt(scheduleTimeblocks.jobNo, 10),
        siteNo: 0,
        apptDate: selectedTimeBlock ? selectedTimeBlock.date : null,
        optimizerPromBand: selectedTimeBlock ? selectedTimeBlock.promBand : null,
        optimizerOfferToken: selectedTimeBlock ? selectedTimeBlock.offerToken : null,
        optimizerOfferSeqno: selectedTimeBlock ? parseInt(selectedTimeBlock.index, 10) : null,
        optimizerPromStartTime: selectedTimeBlock ? startTime : null,
        optimizerPromEndTime: selectedTimeBlock ? endTime : null,
        requestThruChudly: false,
        siteAddressLine1: null,
        email: null,
        optOutOfSoonerAppt: false,
        escalatedWithAppt: false,
        escalationComments: '',
        allowEarlyArrival: false
      };
      return appointmentData.saveScheduledAppointment(options).then(appointments => fsm.dispatch(_constants.actions.RESOLVE_SCHEDULE, appointments)).catch(results => {
        this.somethingWrong = true;
        this.ineligibiltyMsg = ineligibiltyMsgs.apiFail;
        return fsm.dispatch(_constants.actions.REJECT, results);
      });
    }

    [_actions$RESOLVE_SCHE](appointments) {
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', '3G Self Schedule', 'Flow Complete', 'Success']);
      this._appointments = appointments;
      return true;
    }

    [_actions$REJECT](results) {
      const msgs = this.handleErrors(results);
      this.psDetail.value = msgs[0];
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', '3G Self Schedule', 'Flow Error', msgs[0]]);
      return msgs;
    }

    [_actions$CLOSE](success) {
      if (success) (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', '3G Self Schedule', 'Flow Complete', 'Back to MyADT']);
      this.mwd.hide();
      this._statusMessages = null;
      this.ineligibiltyMsg = null;
      this.resetCount = 0;
      this.selectedDate = null;
      this.selectedTimeBlock = null;
      this.somethingWrong = false;
    }
    /**
     * Implements {@link MyADTHelpers.genericErrorHandler} and sets `statusMessage`
     * @param {ApiResponse} results
     */


    handleErrors(results) {
      const errors = (0, _myadtHelpers.genericErrorHandler)(results);
      Ember.set(this, '_statusMessages', errors.map(m => new _statusMessage.default(m, 'error', true)));
      return errors;
    }

    checkElig() {
      if (this.otherProblems == 'yes') return this.voidElig('otherProblems');
      if (this.haveTheRona == 'yes') return this.voidElig('haveTheRona');
      return this.confirmElig();
    }

    confirmElig(msgKey) {
      const keys = {
        otherProblems: ['System Related Question', 'No'],
        haveTheRona: ['Covid Related Question', 'No']
      };
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', '3G Self Schedule', keys[msgKey][0], keys[msgKey][1]]);
      if (msgKey == 'otherProblems') (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', '3G Self Schedule', 'Covid Related Question', 'Banner Display']);
      return this.fsm.dispatch(_constants.actions.CONFIRM_ELIGIBILITY);
    }

    voidElig(msgKey) {
      return this.fsm.dispatch(_constants.actions.VOID_ELIGIBILITY, msgKey);
    }

    setDate(moment) {
      this.center = moment.date;
      this.selectedDate = moment.date;
    }

    getOffers() {
      const act = this.appointmentData.scheduleTimeblocks ? _constants.actions.GET_TIME_BLOCKS : _constants.actions.CREATE_JOB;
      return this.fsm.dispatch(act);
    }

    submitTimeSlot() {
      if (this.selectedTimeBlock == null) return;
      const act = this.selectedTimeBlock == 'none' ? _constants.actions.RETURN_TO_CALENDAR : _constants.actions.SCHEDULE_APPT;

      if (act == _constants.actions.RETURN_TO_CALENDAR) {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', '3G Self Schedule', 'Appt Selection', 'None of the Above']);
        this.selectedTimeBlock = null;
        if (this.resetCount < 2) this.resetCount++;else return this.fsm.dispatch(_constants.actions.CANCEL_WITHOUT_APPT, 'overdidIt');
      } else {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', '3G Self Schedule', 'Appt Selection', 'Time Slot']);
      }

      return this.fsm.dispatch(act);
    }

    initChat() {
      return this.fsm.dispatch(_constants.actions.CHAT);
    }

    closeModal(success) {
      return this.fsm.dispatch(_constants.actions.CLOSE, success);
    }

    willDestroy() {
      super.willDestroy(...arguments);
      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    }

  }), _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appointmentData", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "systemInformation", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentLocation", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "mwd", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "csrInfo", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currentState", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _constants.states.IDLE;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "statusMessages", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "statusMessages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "appointments", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "appointments"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "otherProblems", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "haveTheRona", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "psDetail", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        attribute: 'error-3gselfschedule-ps',
        label: 'error-3gselfschedule-ps',
        value: null,
        visible: 'wpm'
      };
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "resetCount", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "customerData", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "selectedDate", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Date();
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "selectedTimeBlock", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "ineligibiltyMsg", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "somethingWrong", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "center", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Date();
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "maxDate", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _emberPowerCalendarUtils.add)(new Date(), 30, 'days');
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "minDate", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Date();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "checkElig", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "checkElig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmElig", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "confirmElig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "voidElig", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "voidElig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDate", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "setDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getOffers", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "getOffers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitTimeSlot", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "submitTimeSlot"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initChat", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "initChat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = ForcedSelfScheduleComponent;
});