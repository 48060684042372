define("adt-wss/pods/components/dashboard/manage/delete-report/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CLOSE_MODAL_WINDOW_ACTION = _exports.DELETING_STATE = _exports.OPEN_MODAL_ACTION = _exports.MODAL_WINDOW_STATE = _exports.DELETE_ACTION = void 0;
  const DELETE_ACTION = 'DELETE_ACTION';
  _exports.DELETE_ACTION = DELETE_ACTION;
  const MODAL_WINDOW_STATE = 'MODAL_WINDOW_STATE';
  _exports.MODAL_WINDOW_STATE = MODAL_WINDOW_STATE;
  const OPEN_MODAL_ACTION = 'OPEN_MODAL_ACTION';
  _exports.OPEN_MODAL_ACTION = OPEN_MODAL_ACTION;
  const DELETING_STATE = 'DELETING_STATE';
  _exports.DELETING_STATE = DELETING_STATE;
  const CLOSE_MODAL_WINDOW_ACTION = 'CLOSE_MODAL_WINDOW_ACTION';
  _exports.CLOSE_MODAL_WINDOW_ACTION = CLOSE_MODAL_WINDOW_ACTION;
});