define("adt-wss/pods/components/dashboard/account/travel-reminders/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers", "adt-wss-common/classes/validator", "adt-wss-common/utils/myadt-helpers", "adt-wss/pods/components/dashboard/account/travel-reminders/constants"], function (_exports, _withFsm, _emberHelpers, _validator, _myadtHelpers, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.defaultProperties = void 0;
  const validator = new _validator.default({
    email: {
      label: 'Recipient Email',
      rules: ['isEmail']
    },
    startDate: {
      label: 'Start Date',
      rules: ['isNotEmpty']
    },
    endDate: {
      reference: 'startDate',
      label: 'End Date',
      rules: ['isNotEmpty', 'afterStartDate']
    }
  }, {
    types: {
      afterStartDate: {
        validate: (endDateValue, params, startDateValue) => startDateValue < endDateValue,
        instruction: () => 'End Date cannot be less than Start Date'
      }
    }
  });
  const fsmModel = {
    scrollToTop: false,
    transitions: {
      [_constants.IDLE_STATE]: {
        [_constants.ADD_TRAVEL_REMINDER_ACTION]: _constants.UPDATING_STATE,
        [_constants.DELETE_TRAVEL_REMINDER_ACTION]: _constants.UPDATING_STATE
      },
      [_constants.UPDATING_STATE]: {
        [_constants.REJECT_ACTION]: _constants.IDLE_STATE,
        [_constants.RESOLVE_ACTION]: _constants.IDLE_STATE
      }
    }
  };
  const defaultReminder = {
    contactEmail: '',
    vacationStartDt: null,
    vacationEndDt: null,
    startDate: null,
    endDate: null,
    reminderCreatedDt: null,
    createdDate: null
  };
  const defaultProperties = {
    reminders: [],
    opted: false,
    addTravelReminder: () => {
      throw new Error('Please pass "addTravelReminder" into component');
    },
    deleteTravelReminder: () => {
      throw new Error('Please pass "deleteTravelReminder" into component');
    }
  };
  /**
   * @module Components/Dashboard/Account/TravelReminders
   * @prop {Object[]} reminders
   * @prop {Function} addTravelReminder
   * @prop {Function} deleteTravelReminder
   * @example
   * {@lang xml} {{ dashboard/account/travel-reminders
  		reminders=[{ contactEmail: 'test@test.com', reminderCreatedDt: '"Jan-14-2019"', ... }]
  		opted=true
  		addTravelReminder=(action 'addTravelReminder')
  		deleteTravelReminder=(action 'deleteTravelReminder')
  }}
   */

  _exports.defaultProperties = defaultProperties;

  var _default = Ember.Component.extend(_withFsm.default, defaultProperties, {
    mwd: Ember.inject.service('modal-window-dispatcher'),
    currentState: _constants.IDLE_STATE,
    IS_UPDATING_STATE: (0, _emberHelpers.isState)(_constants.UPDATING_STATE),
    fsmModel,
    validator,
    tagName: '',
    isNewReminder: false,
    newReminder: Ember.Object.create(defaultReminder),
    greatThan48hours: null,

    init(...args) {
      this._super(...args);

      const mwd = this.get('mwd');
      this.set('greatThan48hours', new Date().dateAfterNDays(3));
      this.get('validator').onValidate(({
        messages,
        values
      }) => {
        mwd.setProperty('errors', messages);
        mwd.setProperty('messages', values);
      });
    },

    optstatusChanged: Ember.observer('opted', function () {
      this.sendOptTypeTrackingEvents(this.get('opted'));
    }),

    /**
     * Reset new reminder
     * Updates `isNewReminder` and `newReminder` properties
     * Triggered on `closeWindowHandler`
     *
     * @function
     */
    resetNewReminder() {
      this.set('isNewReminder', false);
      this.set('newReminder', Ember.Object.create(defaultReminder));
    },

    /**
     * Resets state (checkboxes, selects, etc.) of the modal window
     * Used before closing of the modal window
     * Triggered on `closeWindowHandler`
     *
     * @function
     */
    resetModalWindowState() {
      const mwd = this.get('mwd');
      mwd.setProperty('errors', null);
      mwd.setProperty('messages', null);
    },

    /**
     * Handler for closening of the window
     * Triggered on `RESOLVE_ACTION`, `openModalWindow` action
     *
     * @function
     */
    closeWindowHandler() {
      this.get('mwd').hide();
      this.resetNewReminder();
      this.resetModalWindowState();
    },

    /**
     * High-Order function
     * Returns function which updates some property for reminder
     * Triggered on `RESOLVE_ACTION`, `openModalWindow` action
     *
     * @function
     * @returns {Function}
     */
    changeReminder(name, reminder) {
      return value => {
        const val = name === 'contactEmail' ? value.target.value : value;
        reminder.set(name, val);
      };
    },

    /**
     * Opens confimation modal window for deletion of reminder
     * Triggered on `removeReminder`
     *
     * @function
     * @param {Object} reminder
     * @returns {Promise}
     */
    deleteConfirmation(reminder) {
      const mwd = this.get('mwd');
      mwd.setProperty('errors', null);
      mwd.setProperty('messages', null);
      return new Ember.RSVP.Promise((resolve, reject) => {
        mwd.show({
          title: 'Travel Reminder',
          name: 'preferences/travel-reminders-confirm',
          body: {
            reminder
          },
          onClose: reject,
          onEnter: resolve,
          actions: [{
            caption: 'Cancel',
            stl: 'outline',
            callback: reject
          }, {
            caption: 'Delete',
            callback: resolve
          }]
        });
      });
    },

    /**
     * Removes reminder
     *
     * @function
     * @param {Object} reminder
     * @param {Object} scope
     * @returns {Promise}
     */
    removeReminder(reminder, scope) {
      return scope.deleteConfirmation(reminder).then(() => scope.get('fsm').dispatch(_constants.DELETE_TRAVEL_REMINDER_ACTION, reminder)).catch(() => scope.actions.showModalWindow.call(scope));
    },

    /**
     * Adds travel reminder if payload has passed the validation
     * Calls '/api/v2/auth/vacation/saveVacation' endpoint
     *
     * @function
     * @param {Object} reminder
     * @returns {Promise}
     */
    [_constants.ADD_TRAVEL_REMINDER_ACTION](reminder) {
      const {
        fsm,
        addTravelReminder,
        validator: val
      } = this.getProperties('fsm', 'addTravelReminder', 'validator');
      const validationFailed = val.validate({
        startDate: reminder.startDate,
        endDate: reminder.endDate,
        email: reminder.contactEmail
      });

      if (validationFailed) {
        return Ember.RSVP.Promise.resolve(fsm.dispatch(_constants.REJECT_ACTION));
      }

      return this.commonDispatcher(() => addTravelReminder(reminder), _constants.RESOLVE_ACTION, _constants.REJECT_ACTION, true);
    },

    /**
     * Removes travel reminder
     * Calls '/api/v2/auth/vacation/deleteVacation' endpoint
     *
     * @function
     * @param {Object} reminder
     * @returns {Promise}
     */
    [_constants.DELETE_TRAVEL_REMINDER_ACTION](reminder) {
      const deleteTravelReminder = this.get('deleteTravelReminder');
      return this.commonDispatcher(() => deleteTravelReminder(reminder), _constants.RESOLVE_ACTION, _constants.REJECT_ACTION, true);
    },

    /**
     * Handler for successful updating.
     * Calls `closeWindowHandler` method in order to close the modal window
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     */
    [_constants.RESOLVE_ACTION]() {
      this.closeWindowHandler();
    },

    /**
     * Handler for failed updating.
     * Calls `mwd.showError` method in order to show a success message
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     * @param {Classes/AjaxResponse} results - object with errors from BE response
     * @returns {Promise} If results are `null` it means that requested data hasn't passed the FE validation,
     * 	in another case data hasn't passed BE validation.
     */
    [_constants.REJECT_ACTION](results) {
      if (!results) return Ember.RSVP.Promise.reject();
      const mwd = this.get('mwd');
      const {
        errors = [_constants.GENERIC_ERROR_MESSAGE]
      } = results || {};
      mwd.showError(errors[0]);
      return Ember.RSVP.Promise.reject();
    },

    /**
     * Sends GA tracking
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     * @returns {undefined}
     */
    sendOptTypeTrackingEvents(adding) {
      if (adding) {
        return (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Preferences', 'Opt-in Travel Reminders Click', 'Travel Reminders Opt-in']);
      }

      return (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Preferences', 'Opt-out Travel Reminders Click', 'Travel Reminders Opt-out']);
    },

    actions: {
      /**
       * Opens modal window
       *
       * @function
       */
      showModalWindow() {
        const {
          fsm,
          mwd
        } = this.getProperties('fsm', 'mwd');

        const closeModal = () => this.closeWindowHandler();

        const onEnter = () => fsm.dispatch(_constants.ADD_TRAVEL_REMINDER_ACTION, this.get('newReminder'));

        mwd.show({
          title: 'Travel Reminder',
          name: 'preferences/travel-reminders',
          body: this,
          isFooterLine: false,
          onClose: closeModal,
          onEnter,
          actions: [{
            caption: 'Cancel',
            stl: 'outline',
            callback: closeModal
          }, {
            caption: 'Save',
            callback: onEnter
          }]
        });
      }

    }
  });

  _exports.default = _default;
});