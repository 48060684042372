define("adt-wss/pods/dashboard/account/profile/route", ["exports", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/route-helpers"], function (_exports, _appDispatcher, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentLocation: Ember.inject.service(),
    userProfile: Ember.inject.service(),
    billingData: Ember.inject.service(),
    commonPasswords: Ember.inject.service(),
    isRefresh: false,
    makeDeliverData: _routeHelpers.makeDeliverData,
    useResolvedOnly: _routeHelpers.useResolvedOnly,
    hashSettled: Ember.RSVP.hashSettled,

    activate() {
      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    deactivate() {
      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      this.isRefresh = true;
      this.refresh();
    },

    model() {
      const deliverDataOptions = {
        forceRequest: this.isRefresh
      };
      const profileInfo = Ember.RSVP.Promise.resolve(this.get('currentLocation.profileInfo'));
      const billingDeliverData = this.makeDeliverData(this, 'billingData');
      const userProfileDeliverData = (0, _routeHelpers.makeDeliverData)(this, 'userProfile');
      const billData = billingDeliverData('billingAddress', billingData => billingData.getBillingAddress(), deliverDataOptions).then(r => ({
        address: r,
        phone: this.get('billingData.billingPhoneDetails.customerPhone1'),
        canShowBillingAddress: this.get('billingData.canShowBillingAddress')
      }));
      const userProfileData = userProfileDeliverData('userProfileData', userProfile => userProfile.getProfileData(), deliverDataOptions);
      const accountName = this.get('currentLocation.profileInfo.name');
      return this.hashSettled({
        billData,
        profileInfo,
        userProfileData,
        accountName
      }).then(response => {
        this.isRefresh = false;
        this.set('userProfile.startTime', Date.now());
        return this.useResolvedOnly(response);
      }).catch(error => console.error(error));
    },

    actions: {
      willTransition() {
        const context = this.get('controller');
        const {
          setOverlap
        } = context.actions;
        setOverlap.call(context, false);
      }

    }
  });

  _exports.default = _default;
});