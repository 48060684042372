define("adt-wss/pods/components/dashboard/account/easypay-enroll/payment-messages/component", ["exports", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers"], function (_exports, _appDispatcher, _myadtHelpers, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Universal display for warning and API messages
   * @module Components/Dashboard/Account/EasypayEnroll/PaymentMessages
   * @prop {Object} promotion
   * @prop {Function} onClose
   * @example
   * {@lang xml} {{ dashboard/account/easypay-enroll/payment-messages
  		promotion=promotion
  		onClose=(action 'closoEasyPayPromo')
  }}
   */
  var _default = Ember.Component.extend({
    ET: Ember.inject.service('event-tagging'),
    authInfo: Ember.inject.service(),
    router: Ember.inject.service(),
    isCsr: Ember.computed.readOnly('authInfo.authInfo.isCsrUser'),
    csrStr: (0, _emberHelpers.computedApplyFunction)(isCsr => "".concat(isCsr ? 'CSR ' : '', "EasyPay Promotion"), 'isCsr'),

    init() {
      this._super(...arguments);

      this.get('ET').sendEventTag({
        eventTypeId: 'EZPAYPRO',
        subEventTypeId: 'ALRTDISP',
        udf2: 'CREDIT_PROMO'
      }, false, '1');
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', this.get('csrStr'), '$5 Credit banner displayed']);
    },

    actions: {
      updatePaymentMethod() {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', this.get('csrStr'), 'Update Payment Method', '$5 Credit Promotion']);
        this.get('appDispatcher').sendEvent(_appDispatcher.HIDE_EASYPAY_PROMO_ALERT);
        this.get('router').transitionTo('dashboard.account.payments.manageeasypay');
      },

      remindMeLater() {
        const promotionType = this.get('promotion.promotionType');
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', this.get('csrStr'), 'Remind me later', '$5 Credit Promotion']);
        this.get('ET').sendEventTag({
          eventTypeId: 'EZPAYPRO',
          subEventTypeId: 'CLKDRLTR',
          udf2: 'CREDIT_PROMO'
        }, false, '1');
        this.get('appDispatcher').sendEvent(_appDispatcher.HIDE_EASYPAY_PROMO_ALERT);
        this.onClose({
          promotionType
        });
      }

    }
  });

  _exports.default = _default;
});