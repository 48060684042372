define("adt-wss/services/password-service", ["exports", "adt-wss-common/utils/http-resources", "adt-wss-common/utils/myadt-helpers"], function (_exports, _httpResources, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * API service and data story for password management
   * @module Services/PasswordService
   * @prop {String} securityQuestion user's password security question
   */
  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)({
    securityQuestion: ''
  }), {
    apiService: Ember.inject.service(),
    securityQuestion: '',

    /**
     * Checks email against Broadview listing before sending reset email.
     * Should this be deprecated?
     * @param {String} loginId
     */
    verifyEmail(loginId) {
      return this.get('apiService').request({
        resource: _httpResources.default.myADT.passwordResetVerifyEmail,
        data: {
          userId: loginId
        }
      });
    },

    /**
     * Retrieves secuirty question from API
     * @param {String} loginId
     */
    getSecurityQuestion(loginId) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('apiService').request({
          resource: _httpResources.default.myADT.passwordResetGetSecurityQuestion,
          data: {
            loginId
          }
        }).then(response => {
          this.set('securityQuestion', response.data.securityQuestion);
          Ember.run(null, resolve, response);
        }, results => {
          Ember.run(null, reject, results);
        });
      });
    },

    /**
     * Sends the reset link via email.
     * Is selected email necessary? It appears required by API.
     * @param {String} loginId
     * @param {String} selectedEmail
     */
    sendResetLink(loginId, selectedEmail) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('apiService').request({
          resource: _httpResources.default.myADT.passwordResetSendEmailLink,
          data: {
            loginId,
            selectedEmail
          }
        }).then(response => {
          if (response.data.emailSent) {
            _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Forgot Password', 'Email sent']);
          }

          Ember.run(null, resolve, response);
        }, results => {
          Ember.run(null, reject, results);
        });
      });
    },

    /**
     * Submits security answer with new password. Resets password if successful.
     * @param {Object} data
     * @param {String} data.loginId
     * @param {String} data.password1
     * @param {String} data.password2
     * @param {String} data.securityAnswer
     */
    resetWithAnswer(data) {
      return this.get('apiService').request({
        resource: _httpResources.default.myADT.passwordResetWithSecurityQuestion,
        data
      }).then(response => {
        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Forgot Password', 'Security Questions Answered']);

        return response;
      });
    },

    /**
     * Submits new and current password, when password is expired.
     * @param {Object} data
     * @param {String} data.loginId
     * @param {String} data.password1
     * @param {String} data.password2
     * @param {String} data.currentPassword
     */
    resetExpiredPassword(data) {
      return this.get('apiService').request({
        resource: _httpResources.default.myADT.passwordResetExpiredPassword,
        data
      }).then(response => {
        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Reset Password', 'Change Expired Password']);

        return response;
      });
    },

    /**
     * Submits password and link code
     * @param {Object} data
     * @param {String} data.uuid Code from reset email
     * @param {String} data.password1
     * @param {String} data.password2
     */
    resetWithLink(data) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('apiService').request({
          resource: _httpResources.default.myADT.passwordResetWithLink,
          data
        }).then(response => {
          _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Reset Password', 'By Email', 'Change Password Click']);

          Ember.run(null, resolve, response);
        }, results => {
          Ember.run(null, reject, results);
        });
      });
    },

    /**
     * Validate security answer for forgot password flow.
     *
     * @param {Object} data
     * @param {String} data.username
     * @param {Array} data.questions List of security question and answer
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    validateSecurityAnswer(data) {
      return this.get('apiService').request({
        resource: _httpResources.default.myADT.validateSecurityAnswer,
        data
      }).then(response => response);
    }

  });

  _exports.default = _default;
});