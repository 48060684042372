define("adt-wss/pods/dashboard/manage/users/route", ["exports", "adt-wss/pods/dashboard/manage/route", "adt-wss-common/utils/route-helpers"], function (_exports, _route, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    webUsersService: Ember.inject.service('web-users'),
    router: Ember.inject.service(),
    makeDeliverData: _routeHelpers.makeDeliverData,

    model() {
      const webUsersData = this.makeDeliverData(this, 'webUsersService');
      return webUsersData('webUsers', webUsersService => webUsersService.getWebUsers());
    }

  });

  _exports.default = _default;
});