define("adt-wss/pods/components/stupid/inline-errors-messages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QyQIFXfl",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"error-messages-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"messages\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"li\",true],[10,\"class\",\"error-messages-item\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/stupid/inline-errors-messages/template.hbs"
    }
  });

  _exports.default = _default;
});