define("adt-wss/pods/dashboard/alarm/appointments/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Controllers/Dashboard/Account/Profile
   * @prop {Object} router - injected service
   * @prop {Object} contactData - injected service
   * @prop {Object} userProfile - injected service
   * @prop {Object} billingData - injected service
   * @prop {Object} commonPasswords - injected service
   */
  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    statusMessages: null,
    messageTarget: 'dashboard/alarm-system'
  });

  _exports.default = _default;
});