define("adt-wss/pods/components/dashboard/account/billing-statements/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.REJECT_EMAIL_ACTION = _exports.RESOLVE_EMAIL_ACTION = _exports.REJECT_SINGLE_ACTION = _exports.RESOLVE_SINGLE_ACTION = _exports.REJECT_MANY_ACTION = _exports.RESOLVE_MANY_ACTION = _exports.SEND_EMAIL_ACTION = _exports.CANCEL_ACTION = _exports.START_EMAIL_ACTION = _exports.VIDEO_MODAL_STATE = _exports.START_VIDEO_ACTION = _exports.DOWNLOAD_ACTION = _exports.VIEW_ACTION = _exports.GET_MANY_ACTION = _exports.SENDING_EMAIL_STATE = _exports.FETCHING_SINGLE_STATE = _exports.FETCHING_MANY_STATE = _exports.EMAIL_MODAL_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const EMAIL_MODAL_STATE = 'EMAIL_MODAL_STATE';
  _exports.EMAIL_MODAL_STATE = EMAIL_MODAL_STATE;
  const FETCHING_MANY_STATE = 'FETCHING_MANY_STATE';
  _exports.FETCHING_MANY_STATE = FETCHING_MANY_STATE;
  const FETCHING_SINGLE_STATE = 'FETCHING_SINGLE_STATE';
  _exports.FETCHING_SINGLE_STATE = FETCHING_SINGLE_STATE;
  const SENDING_EMAIL_STATE = 'SENDING_EMAIL_STATE';
  _exports.SENDING_EMAIL_STATE = SENDING_EMAIL_STATE;
  const GET_MANY_ACTION = 'GET_MANY_ACTION';
  _exports.GET_MANY_ACTION = GET_MANY_ACTION;
  const VIEW_ACTION = 'VIEW_ACTION';
  _exports.VIEW_ACTION = VIEW_ACTION;
  const DOWNLOAD_ACTION = 'DOWNLOAD_ACTION';
  _exports.DOWNLOAD_ACTION = DOWNLOAD_ACTION;
  const START_VIDEO_ACTION = 'START_VIDEO_ACTION';
  _exports.START_VIDEO_ACTION = START_VIDEO_ACTION;
  const VIDEO_MODAL_STATE = 'VIDEO_MODAL_STATE';
  _exports.VIDEO_MODAL_STATE = VIDEO_MODAL_STATE;
  const START_EMAIL_ACTION = 'START_EMAIL_ACTION';
  _exports.START_EMAIL_ACTION = START_EMAIL_ACTION;
  const CANCEL_ACTION = 'CANCEL_ACTION';
  _exports.CANCEL_ACTION = CANCEL_ACTION;
  const SEND_EMAIL_ACTION = 'SEND_EMAIL_ACTION';
  _exports.SEND_EMAIL_ACTION = SEND_EMAIL_ACTION;
  const RESOLVE_MANY_ACTION = 'RESOLVE_MANY_ACTION';
  _exports.RESOLVE_MANY_ACTION = RESOLVE_MANY_ACTION;
  const REJECT_MANY_ACTION = 'REJECT_MANY_ACTION';
  _exports.REJECT_MANY_ACTION = REJECT_MANY_ACTION;
  const RESOLVE_SINGLE_ACTION = 'RESOLVE_SINGLE_ACTION';
  _exports.RESOLVE_SINGLE_ACTION = RESOLVE_SINGLE_ACTION;
  const REJECT_SINGLE_ACTION = 'REJECT_SINGLE_ACTION';
  _exports.REJECT_SINGLE_ACTION = REJECT_SINGLE_ACTION;
  const RESOLVE_EMAIL_ACTION = 'RESOLVE_EMAIL_ACTION';
  _exports.RESOLVE_EMAIL_ACTION = RESOLVE_EMAIL_ACTION;
  const REJECT_EMAIL_ACTION = 'REJECT_EMAIL_ACTION';
  _exports.REJECT_EMAIL_ACTION = REJECT_EMAIL_ACTION;
});