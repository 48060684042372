define("adt-wss/templates/modal-windows/delete-saved-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EQYngR4m",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"inner padded\"],[8],[0,\"\\n\\t\"],[7,\"h4\",true],[8],[0,\"Please confirm you'd like to delete the following payment method from your account:\"],[9],[0,\"\\n\\t\"],[7,\"h6\",true],[8],[1,[28,\"if\",[[24,[\"body\",\"account\",\"name\"]],[24,[\"body\",\"account\",\"name\"]],[24,[\"body\",\"account\",\"accountNumber\"]]],null],false],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[1,[24,[\"body\",\"account\",\"accountNumberMasked\"]],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/delete-saved-account.hbs"
    }
  });

  _exports.default = _default;
});