define("adt-wss/pods/components/widget/calendar/days-component/component", ["exports", "ember-power-calendar/components/power-calendar/days", "ember-power-calendar-utils", "adt-wss-common/utils/fp"], function (_exports, _days, _emberPowerCalendarUtils, _fp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const daysToSchedule = (days, availableDates) => (days || []).map(day => {
    const isAvailable = (availableDates || []).some(avaDay => (0, _emberPowerCalendarUtils.isSame)(avaDay.start, day.date, 'date'));
    return (0, _fp.omix)(day, {
      isAvailable
    });
  });

  var _default = _days.default.extend({
    actions: {
      daysToSchedule,

      selectDate(select, day, calendar) {
        if (!day.isCurrentMonth) {
          return;
        }

        select(day, calendar);
      }

    }
  });

  _exports.default = _default;
});