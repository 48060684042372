define("adt-wss/pods/components/dashboard/account/billing-history/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.REJECT_GET_HISTORY_ACTION = _exports.RESOLVE_GET_HISTORY_ACTION = _exports.VIEW_HISTORY_ACTION = _exports.GET_HISTORY_ACTION = _exports.FETCHING_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'idle';
  _exports.IDLE_STATE = IDLE_STATE;
  const FETCHING_STATE = 'fetchingBillingHistory';
  _exports.FETCHING_STATE = FETCHING_STATE;
  const GET_HISTORY_ACTION = 'getBillingHistory';
  _exports.GET_HISTORY_ACTION = GET_HISTORY_ACTION;
  const VIEW_HISTORY_ACTION = 'viewHistory';
  _exports.VIEW_HISTORY_ACTION = VIEW_HISTORY_ACTION;
  const RESOLVE_GET_HISTORY_ACTION = 'resolveGetHistory';
  _exports.RESOLVE_GET_HISTORY_ACTION = RESOLVE_GET_HISTORY_ACTION;
  const REJECT_GET_HISTORY_ACTION = 'rejectGetHistory';
  _exports.REJECT_GET_HISTORY_ACTION = REJECT_GET_HISTORY_ACTION;
});