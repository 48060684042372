define("adt-wss/pods/components/salesforce-chat-include/component", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss/services/app-dispatcher"], function (_exports, _myadtHelpers, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let SalesforceChatComponent = (_class = (_temp = class SalesforceChatComponent extends Ember.Component {
    get siteNo() {
      if (this.systemInformation.selectedSystem) {
        return this.systemInformation.selectedSystem.siteNo || '';
      }

      return null;
    }

    get accountInfo() {
      const out = this.accountData.accountInfo || {};
      const names = out.name && out.name.name ? out.name.name.split(' ') : [];
      out.firstName = names[0] || null;
      out.lastName = names[1] || null;
      return out;
    }

    get initValues() {
      if (/www.myadt|\/\/myadt.com/i.test(document.location.href)) {
        return this.liveChat.sfProdInit;
      }

      if (/\/test4./i.test(document.location.href)) {
        return this.liveChat.sfTrnInit;
      }

      return this.liveChat.sfDevInit;
    }

    openChat() {
      this.showChatWindow = true;
    }

    closeChat() {
      this.showChatWindow = false;
    }

    enableChat() {
      document.body.classList.remove('hide-salesforce-chat');
    }

    disableChat() {
      document.body.classList.add('hide-salesforce-chat');
    }

    closeModal() {
      this.modalOpened = false;
    }

    updateFor3G() {
      if (!window.embedded_svc.isButtonDisabled) {
        let int3GUpdateCount = 0;
        const int3GButtonVal = setInterval(() => {
          var sortBySelect = document.querySelector("select.What_brings_you_here_today__c");

          if (sortBySelect != null) {
            clearInterval(int3GButtonVal);
          } else if (int3GUpdateCount > 5) {
            return clearInterval(int3GButtonVal);
          } else {
            return int3GUpdateCount++;
          }

          sortBySelect.value = "3G Conversion";
          sortBySelect.dispatchEvent(new Event("change"));
        }, 1000);
      }
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "apiService", _descriptor, this);

      _initializerDefineProperty(this, "liveChat", _descriptor2, this);

      _initializerDefineProperty(this, "ssoInfo", _descriptor3, this);

      _initializerDefineProperty(this, "authInfo", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      _initializerDefineProperty(this, "accountData", _descriptor6, this);

      _initializerDefineProperty(this, "systemInformation", _descriptor7, this);

      _initializerDefineProperty(this, "showSFChat", _descriptor8, this);

      _initializerDefineProperty(this, "showChatButton", _descriptor9, this);

      _initializerDefineProperty(this, "showChatWindow", _descriptor10, this);

      _initializerDefineProperty(this, "env", _descriptor11, this);

      _initializerDefineProperty(this, "flag3gConversion", _descriptor12, this);

      _initializerDefineProperty(this, "modalOpened", _descriptor13, this);

      this.liveChat.getLiveChatData().then(() => {
        this.showSFChat = this.liveChat.showSFChat;

        if (document.location.pathname.indexOf('/ps/') > -1) {
          this.showSFChat = false;
        }

        if (document.location.pathname.indexOf('/3gconversion') > -1) {
          this.flag3gConversion = true;
        }

        const sfdc = document.createElement('script');
        sfdc.type = 'text/javascript';
        sfdc.src = 'https://service.force.com/embeddedservice/5.0/esw.min.js';
        const s = document.getElementsByTagName('script')[0];
        return s.parentNode.insertBefore(sfdc, s);
      });
    }

    showOfflineModal() {
      if (window.embedded_svc.isButtonDisabled) {
        this.modalOpened = true;
      }
    }

    init() {
      super.init(...arguments);
      let intValCount = 0;
      const intVal = setInterval(() => {
        if (window.embedded_svc) {
          clearInterval(intVal);
        } else if (intValCount > 10) {
          return clearInterval(intVal);
        } else {
          return intValCount++;
        }

        const initESW = function (context, gslbBaseURL) {
          window.embedded_svc.settings.displayHelpButton = true; //Or false

          window.embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'

          window.embedded_svc.settings.defaultMinimizedText = 'Chat with an Agent'; //(Defaults to Chat with an Expert)

          window.embedded_svc.settings.disabledMinimizedText = 'Contact Us'; //(Defaults to Agent Offline)
          // window.embedded_svc.settings.loadingText = ''; //(Defaults to Loading)

          window.embedded_svc.settings.storageDomain = 'myadt.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)
          // Settings for Chat
          // window.embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
          // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
          // Returns a valid button ID.
          // };
          //	Below is the configuration for Salesforce chat button in Non-Prod environment
          // 	window.embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
          //	if (prechatFormData[5].value === "3G Conversion"){
          //		return "5731h000000CjRG";
          //	}
          //	};
          //Below is the configuration for Salesforce chat button in Prod environment

          window.embedded_svc.settings.directToButtonRouting = function (prechatFormData) {
            if (prechatFormData[5].value === "3G Conversion") {
              return "5734P00000095pa";
            }
          };

          const accountInfo = context.accountData.getAccountInfo();
          const prepopulatedPrechatFields = {
            //Sets the auto-population of pre-chat form fields
            FirstName: accountInfo.firstName,
            LastName: accountInfo.lastName,
            ContactName: accountInfo.name,
            Email: accountInfo.email,
            WebEmail: accountInfo.email,
            Phone: accountInfo.phone,
            WebPhone: accountInfo.phone
          };
          const extraPrechatFormDetails = [{
            label: 'MMBCN',
            value: accountInfo.accountId,
            transcriptFields: [],
            displayToAgent: false
          }, {
            label: 'MMBSN',
            value: context.siteNo,
            transcriptFields: [],
            displayToAgent: false
          }, {
            label: 'CustSiteAffliation',
            value: context.accountData.customerInfo ? context.accountData.customerInfo.customerType : null,
            transcriptFields: [],
            displayToAgent: false
          }, {
            label: 'Origin',
            value: 'Web - Chat',
            transcriptFields: [],
            displayToAgent: false
          }];
          const extraPrechatInfo = [{
            entityName: 'Case',
            showOnCreate: true,
            saveToTranscript: 'Case',
            entityFieldMaps: [{
              isExactMatch: false,
              fieldName: 'MMB_Customer__c',
              doCreate: true,
              doFind: false,
              label: 'MMBCN'
            }, {
              isExactMatch: false,
              fieldName: 'MMB_Site__c',
              doCreate: true,
              doFind: false,
              label: 'MMBSN'
            }, {
              isExactMatch: false,
              fieldName: 'Customer_Site_Affiliation',
              doCreate: true,
              doFind: false,
              label: 'CustSiteAffliation'
            }, {
              isExactMatch: false,
              fieldName: 'Origin',
              doCreate: true,
              doFind: false,
              label: 'Origin'
            }]
          }, {
            entityName: 'Contact',
            saveToTranscript: 'Contact',
            showOnCreate: true,
            entityFieldMaps: [{
              doCreate: false,
              doFind: true,
              fieldName: 'LastName',
              isExactMatch: true,
              label: 'Last Name'
            }, {
              doCreate: false,
              doFind: true,
              fieldName: 'FirstName',
              isExactMatch: true,
              label: 'First Name'
            }, {
              doCreate: false,
              doFind: true,
              fieldName: 'Email',
              isExactMatch: true,
              label: 'Email'
            }]
          }];
          window.embedded_svc.settings.prepopulatedPrechatFields = prepopulatedPrechatFields;
          window.embedded_svc.settings.extraPrechatFormDetails = extraPrechatFormDetails;
          window.embedded_svc.settings.extraPrechatInfo = extraPrechatInfo; // window.embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
          // window.embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)
          // window.embedded_svc.settings.prechatBackgroundImgURL = '...';
          // window.embedded_svc.settings.waitingStateBackgroundImgURL = '...';

          window.embedded_svc.settings.avatarImgURL = "".concat(_myadtHelpers.default.getOrigin(), "/images/v2/logos/adt-logo.svg");
          window.embedded_svc.settings.chatbotAvatarImgURL = "".concat(_myadtHelpers.default.getOrigin(), "/images/v2/logos/adt-logo.svg");
          window.embedded_svc.settings.smallCompanyLogoImgURL = "".concat(_myadtHelpers.default.getOrigin(), "/images/v2/logos/adt-logo.svg");
          window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
          window.embedded_svc.settings.entryFeature = 'LiveAgent';

          if (!window.embedded_svc.settings.extraPrechatFormDetails) {
            window.embedded_svc.settings.extraPrechatFormDetails = [];
          }

          window.embedded_svc.settings.extraPrechatFormDetails.push({
            "label": "LaunchSite",
            "value": window.location.href,
            "transcriptFields": ["LaunchSite__c"],
            "displayToAgent": false
          });
          window.embedded_svc.init(...context.initValues.urls, gslbBaseURL, ...context.initValues.params);
          let intUiButtonValCount = 0;
          const intUiButtonVal = setInterval(() => {
            const el = document.getElementsByClassName('uiButton');

            if (el.length) {
              clearInterval(intUiButtonVal);
            } else if (intUiButtonValCount > 10) {
              return clearInterval(intUiButtonVal);
            } else {
              return intUiButtonValCount++;
            }

            let i;

            for (i = 0; i < el.length; i++) {
              el[i].addEventListener('click', () => context.showOfflineModal());
            }

            if (context.flag3gConversion) {
              el[0].click();
              context.updateFor3G();
            }
          }, 2000);
        };

        if (this.showSFChat) {
          if (!window.embedded_svc) {
            const s = document.createElement('script');
            s.setAttribute('src', this.initValues.esw);

            s.onload = function () {
              initESW(this, null).call(this);
            };

            document.body.appendChild(s);
          } else {
            initESW(this, 'https://service.force.com');
          }
        }

        "";
        this.appDispatcher.subscribe(_appDispatcher.DISABLE_PS_CHAT_EVENT, this, 'enableChat');
        this.appDispatcher.subscribe(_appDispatcher.ENABLE_PS_CHAT_EVENT, this, 'disableChat');
        this.appDispatcher.subscribe(_appDispatcher.ENABLE_SF_3G_CHAT_EVENT, this, 'updateFor3G');

        if (!this.flag3gConversion) {//if (!this.session.isAuthenticated) this.disableChat();
        }

        if (!this.showSFChat) this.disableChat();
      }, 1000);
    }

    willDestroy() {
      super.willDestroy(...arguments);
      this.get('appDispatcher').unsubscribe(_appDispatcher.DISABLE_PS_CHAT_EVENT, this, 'enableChat');
      this.get('appDispatcher').unsubscribe(_appDispatcher.ENABLE_PS_CHAT_EVENT, this, 'disableChat');
      this.get('appDispatcher').unsubscribe(_appDispatcher.ENABLE_SF_3G_CHAT_EVENT, this, 'updateFor3G');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiService", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "liveChat", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ssoInfo", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "authInfo", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "accountData", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "systemInformation", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showSFChat", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showChatButton", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showChatWindow", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "env", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'dev';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "flag3gConversion", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "modalOpened", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openChat", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "openChat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeChat", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "closeChat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "enableChat", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "enableChat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableChat", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "disableChat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFor3G", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "updateFor3G"), _class.prototype)), _class);
  _exports.default = SalesforceChatComponent;
});