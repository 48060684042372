define("adt-wss/templates/modal-windows/default-delete-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2RI50vcV",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"loading-spinner\",[[24,[\"body\",\"isFetching\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"p\",true],[10,\"class\",\"confirmation-text\"],[8],[1,[24,[\"body\",\"confirmationText\"]],false],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"inner padded\"],[8],[0,\"\\n\\t\\t\"],[7,\"h6\",true],[8],[1,[28,\"if\",[[24,[\"body\",\"location\",\"locationName\"]],[24,[\"body\",\"location\",\"locationName\"]],[28,\"concat\",[\"Location \",[24,[\"body\",\"ordinal\"]]],null]],null],false],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"address-line address-line-1\"],[8],[1,[24,[\"body\",\"location\",\"siteLine1\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"body\",\"location\",\"siteLine2\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"address-line address-line-2\"],[8],[1,[24,[\"body\",\"location\",\"siteLine2\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"body\",\"location\",\"siteLine3\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"address-line address-line-3\"],[8],[1,[24,[\"body\",\"location\",\"siteLine3\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"city\"],[8],[1,[24,[\"body\",\"location\",\"city\"]],false],[0,\", \"],[1,[24,[\"body\",\"location\",\"state\"]],false],[0,\" \"],[1,[24,[\"body\",\"location\",\"zip\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/default-delete-location.hbs"
    }
  });

  _exports.default = _default;
});