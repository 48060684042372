define("adt-wss/pods/dashboard/manage/users/controller", ["exports", "jquery", "adt-wss-common/classes/status-message", "adt-wss/pods/dashboard/manage/users/constants", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/mixins/with-fsm"], function (_exports, _jquery, _statusMessage, constants, _myadtHelpers, _withFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const addUserDefault = {
    email: '',
    admin: null
  };
  const {
    IDLE_STATE,
    CANCEL_ACTION,
    ADD_USER_STEP1_STATE,
    ADD_USER_STEP2_STATE,
    ADD_USER_STEP3_STATE,
    ADD_USER_ADMIN_STATE,
    REMOVE_USER_ADMIN_STATE,
    DELETE_USER_STATE,
    SUBMITTING_NEW_USER_STATE,
    SUBMITTING_ADD_USER_ADMIN_STATE,
    SUBMITTING_REMOVE_USER_ADMIN_STATE,
    SUBMITTING_DELETE_USER_STATE,
    START_ADD_USER_ACTION,
    START_ADD_USER_ADMIN_ACTION,
    START_REMOVE_USER_ADMIN_ACTION,
    START_DELETE_USER_ACTION,
    ADD_USER_NEXT_STEP_ACTION,
    SUBMIT_ADD_USER_ACTION,
    RESOLVE_ADD_USER_ACTION,
    REJECT_ADD_USER_ACTION,
    SUBMIT_REMOVE_USER_ADMIN_ACTION,
    RESOLVE_REMOVE_USER_ADMIN_ACTION,
    REJECT_REMOVE_USER_ADMIN_ACTION,
    SUBMIT_ADD_USER_ADMIN_ACTION,
    RESOLVE_ADD_USER_ADMIN_ACTION,
    REJECT_ADD_USER_ADMIN_ACTION,
    SUBMIT_DELETE_USER_ACTION,
    RESOLVE_DELETE_USER_ACTION,
    REJECT_DELETE_USER_ACTION
  } = constants;
  const fsmModel = {
    transitions: {
      IDLE_STATE: {
        [START_ADD_USER_ACTION]: ADD_USER_STEP1_STATE,
        [START_ADD_USER_ADMIN_ACTION]: ADD_USER_ADMIN_STATE,
        [START_REMOVE_USER_ADMIN_ACTION]: REMOVE_USER_ADMIN_STATE,
        [START_DELETE_USER_ACTION]: DELETE_USER_STATE
      },
      [ADD_USER_STEP1_STATE]: {
        [ADD_USER_NEXT_STEP_ACTION]: ADD_USER_STEP2_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      },
      [ADD_USER_STEP2_STATE]: {
        [ADD_USER_NEXT_STEP_ACTION]: ADD_USER_STEP3_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      },
      [ADD_USER_STEP3_STATE]: {
        [SUBMIT_ADD_USER_ACTION]: SUBMITTING_NEW_USER_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      },
      [SUBMITTING_NEW_USER_STATE]: {
        [RESOLVE_ADD_USER_ACTION]: IDLE_STATE,
        [REJECT_ADD_USER_ACTION]: ADD_USER_STEP3_STATE
      },
      [ADD_USER_ADMIN_STATE]: {
        [SUBMIT_ADD_USER_ADMIN_ACTION]: SUBMITTING_ADD_USER_ADMIN_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      },
      [SUBMITTING_ADD_USER_ADMIN_STATE]: {
        [RESOLVE_ADD_USER_ADMIN_ACTION]: IDLE_STATE,
        [REJECT_ADD_USER_ADMIN_ACTION]: ADD_USER_ADMIN_STATE
      },
      [REMOVE_USER_ADMIN_STATE]: {
        [SUBMIT_REMOVE_USER_ADMIN_ACTION]: SUBMITTING_REMOVE_USER_ADMIN_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      },
      [SUBMITTING_REMOVE_USER_ADMIN_STATE]: {
        [RESOLVE_REMOVE_USER_ADMIN_ACTION]: IDLE_STATE,
        [REJECT_REMOVE_USER_ADMIN_ACTION]: REMOVE_USER_ADMIN_STATE
      },
      [DELETE_USER_STATE]: {
        [SUBMIT_DELETE_USER_ACTION]: SUBMITTING_DELETE_USER_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      },
      [SUBMITTING_DELETE_USER_STATE]: {
        [RESOLVE_DELETE_USER_ACTION]: IDLE_STATE,
        [REJECT_DELETE_USER_ACTION]: DELETE_USER_STATE
      }
    }
  };
  /**
   * @module Controller/Dashboard/Manage/Users
   * @prop {Classes/StatusMessage[]} statusMessages `computed.alias` from parent controller
   * @prop {Computed<Object>} profileInfo `currentLocation.profileInfo`
   * @prop {String} currentState
   * @prop {Object} newUser data structure for new user form in modal
   * @prop {String} newUser.email
   * @prop {Boolean} newUser.admin
   */

  var _default = Ember.Controller.extend(_withFsm.default, {
    manage: Ember.inject.controller('dashboard/manage'),
    webUsersService: Ember.inject.service('web-users'),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    currentLocation: Ember.inject.service(),
    myadtSecurity: Ember.inject.service(),
    statusMessages: Ember.computed.alias('manage.dashboardStatusMessages'),
    profileInfo: Ember.computed.oneWay('currentLocation.profileInfo'),
    fsmModel,
    genericErrorHandler: _myadtHelpers.genericErrorHandler,
    showModalErrors: _myadtHelpers.showModalErrors,
    currentState: IDLE_STATE,
    newUser: addUserDefault,

    init() {
      this._super(...arguments);
    },

    // Controllers are singletons, so setting idle after the model resolves
    afterModel() {
      this.set('currentState', IDLE_STATE);
    },

    // ========== ADD USER ========== //

    /**
     * Begins process of adding a new web user
     * by opening modal.
     * `mwd` passes `this` as `body` to modal.
     * The modal interacts with controller data directly
     * because I couldn't get the scope to work.
     * TODO: fix scoping
     */
    [START_ADD_USER_ACTION]() {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const showErrors = this.showModalErrors(fsm, mwd);

      const onClose = () => {
        this.get('fsm').dispatch(CANCEL_ACTION);
        mwd.hide();
      };

      const onEnter = () => {
        const email = this.get('newUser.email');
        const admin = this.get('newUser.admin');
        mwd.clearMessages();
        (0, _jquery.default)('.input-wrapper.add-user-email-wrapper, .add-web-user .is-admin-header').removeClass('error');

        if (typeof email !== 'string' || !email.isValidEmail()) {
          mwd.showError('Please enter a valid email address.');
          (0, _jquery.default)('.input-wrapper.add-user-email-wrapper').addClass('error');
          return false;
        }

        if (this.get('currentState') === ADD_USER_STEP2_STATE && !admin) {
          (0, _jquery.default)('.add-web-user .is-admin-header').addClass('error');
          return false;
        }

        if (this.get('currentState') === ADD_USER_STEP3_STATE) {
          const data = {
            email,
            admin: admin === 'yes',
            comments: null,
            department: null,
            title: null,
            siteType: this.get('profileInfo.address.siteType')
          };
          return fsm.dispatch(SUBMIT_ADD_USER_ACTION, data).then(d => {
            this.get('fsm').dispatch(RESOLVE_ADD_USER_ACTION, d);
            mwd.hide();
          }).catch(results => showErrors(results, REJECT_ADD_USER_ACTION));
        }

        return fsm.dispatch(ADD_USER_NEXT_STEP_ACTION);
      };

      mwd.show({
        name: 'add-web-user',
        title: 'Add Account User to This Location',
        body: this,
        isFooterLine: false,
        onClose,
        onEnter,
        statusMessages: [],
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: this.get('currentState') === ADD_USER_STEP3_STATE ? 'Add User' : 'Next',
          callback: onEnter
        }]
      });
    },

    /**
     * Runs the API call through `webUsersService` to add
     * a new web user
     * @param {Object} user
     * @param {String} user.email
     * @param {Boolean} user.admin
     * @param {String} user.comments
     * @param {String} user.department
     * @param {String} user.title
     * @param {String} user.siteType
     */
    [SUBMIT_ADD_USER_ACTION](user) {
      return this.get('webUsersService').addWebUser(user);
    },

    [RESOLVE_ADD_USER_ACTION]() {
      this.refreshRoute();
      const msg = new _statusMessage.default('You\'ve successfully added a new user.', 'success', 3000);
      this.set('statusMessages', [msg]);
      this.set('newUser', {
        email: '',
        admin: null
      });
    },

    // ============ ADD ADMIN ============ //

    /**
     * Begins process of adding admin privileges
     * by opening modal.
     * @param {Classes/WebUser} user
     */
    [START_ADD_USER_ADMIN_ACTION](user) {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const showErrors = this.showModalErrors(fsm, mwd);
      const title = 'Make User an Admin';

      const onClose = () => {
        this.get('fsm').dispatch(CANCEL_ACTION);
        mwd.hide();
      };

      const body = {
        user
      };
      mwd.show({
        name: 'add-web-user-admin-privilege',
        title,
        body,
        onClose,
        statusMessages: [],
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: 'Make Admin',
          callback: () => fsm.dispatch(SUBMIT_ADD_USER_ADMIN_ACTION, user).then(data => {
            fsm.dispatch(RESOLVE_ADD_USER_ADMIN_ACTION, data);
            mwd.hide();
          }).catch(results => showErrors(results, REJECT_ADD_USER_ADMIN_ACTION))
        }]
      });
    },

    /**
     * Submits admin addition to API
     * @param {Object} data
     * @param {Int} data.id
     * @param {Boolean} data.pending
     * @param {String} data.title
     * @param {String} data.department
     * @param {String} data.userComments
     */
    [SUBMIT_ADD_USER_ADMIN_ACTION](data) {
      const user = {
        id: data.id,
        pending: data.pending,
        admin: true,
        title: data.title,
        department: data.department,
        userComments: data.userComments
      };
      return this.get('webUsersService').updateUser(user);
    },

    [RESOLVE_ADD_USER_ADMIN_ACTION]() {
      this.refreshRoute();
      return this.set('statusMessages', new _statusMessage.default('Success! Your web user has been updated.', 'success'));
    },

    // ============ REMOVE ADMIN ============ //

    /**
     * Begins process of removing admin privileges
     * by opening modal.
     * @param {Classes/WebUser} user
     */
    [START_REMOVE_USER_ADMIN_ACTION](user) {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const showErrors = this.showModalErrors(fsm, mwd);
      const title = 'Remove Admin Privileges';

      const onClose = () => {
        this.get('fsm').dispatch(CANCEL_ACTION);
        mwd.hide();
      };

      mwd.show({
        name: 'remove-web-user-admin-privilege',
        title,
        body: user,
        onClose,
        statusMessages: [],
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: title,
          callback: () => fsm.dispatch(SUBMIT_REMOVE_USER_ADMIN_ACTION, user).then(data => {
            fsm.dispatch(RESOLVE_REMOVE_USER_ADMIN_ACTION, data);
            mwd.hide();
          }).catch(results => showErrors(results, REJECT_REMOVE_USER_ADMIN_ACTION))
        }]
      });
    },

    /**
     * Submits admin removal to API
     * @param {Object} data
     * @param {Int} data.id
     * @param {Boolean} data.pending
     * @param {String} data.title
     * @param {String} data.department
     * @param {String} data.userComments
     */
    [SUBMIT_REMOVE_USER_ADMIN_ACTION](data) {
      const user = {
        id: data.id,
        pending: data.pending,
        admin: false,
        title: data.title,
        department: data.department,
        userComments: data.userComments
      };
      return this.get('webUsersService').updateUser(user);
    },

    [RESOLVE_REMOVE_USER_ADMIN_ACTION]() {
      this.refreshRoute();
      return this.set('statusMessages', new _statusMessage.default('Success! Your web user has been updated.', 'success'));
    },

    // ============ DELETE USER ============ //

    /**
     * Begins process of removing admin privileges
     * by opening modal.
     * @param {Classes/WebUser} user
     */
    [START_DELETE_USER_ACTION](user) {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const showErrors = this.showModalErrors(fsm, mwd);
      const title = 'Delete Web User';

      const onClose = () => {
        this.get('fsm').dispatch(CANCEL_ACTION);
        mwd.hide();
      };

      mwd.show({
        name: 'delete-web-user',
        title,
        body: user,
        onClose,
        statusMessages: [],
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: title,
          callback: () => fsm.dispatch(SUBMIT_DELETE_USER_ACTION, user).then(data => {
            fsm.dispatch(RESOLVE_DELETE_USER_ACTION, data);
            mwd.hide();
          }).catch(results => showErrors(results, REJECT_DELETE_USER_ACTION))
        }]
      });
    },

    /**
     * Submits user deletion to API
     * @param {Object} data
     * @param {Int} data.id
     * @param {Boolean} data.pending
     */
    [SUBMIT_DELETE_USER_ACTION](data) {
      const user = {
        id: data.id,
        pending: data.pending
      };
      return this.get('webUsersService').deleteUser(user);
    },

    [RESOLVE_DELETE_USER_ACTION]() {
      this.refreshRoute();
      return this.set('statusMessages', new _statusMessage.default('Success! Your web user has been deleted.', 'success'));
    },

    refreshRoute() {
      const route = Ember.getOwner(this).lookup('route:dashboard.manage');
      return route.refresh();
    },

    /**
     * Generic error handler
     * TODO: make into helper or mixin
     * @param {Classes/ApiResponse} results
     * @param {String|String[]} [result.errors] error keys or messages
     * @return {Classes/StatusMessage[]}
     */
    handleErrors(results) {
      const errors = this.genericErrorHandler(results);
      const statusMessages = errors.map(err => new _statusMessage.default(err, 'error', 5000));
      return this.set('statusMessages', statusMessages);
    },

    /**
     * Used by buttons in modal to set the value of
     * `admin` for a user being added or updated
     * @param {Object} el
     * @param {Boolean} value
     */
    setAdmin(el, value) {
      Ember.set(el, 'admin', value);
    },

    actions: {
      startAddUser() {
        this.get('fsm').dispatch(START_ADD_USER_ACTION);
      },

      startAddUserAdmin(user) {
        this.get('fsm').dispatch(START_ADD_USER_ADMIN_ACTION, user);
      },

      startRemoveUserAdmin(user) {
        this.get('fsm').dispatch(START_REMOVE_USER_ADMIN_ACTION, user);
      },

      startDeleteUser(user) {
        this.get('fsm').dispatch(START_DELETE_USER_ACTION, user);
      }

    }
  });

  _exports.default = _default;
});