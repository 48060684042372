define("adt-wss/pods/components/dashboard/alarm/appointments/change-appointment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MrQEj+lR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"type\"]],\"cancel\"],null]],null,{\"statements\":[[0,\"\\t\"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"blue-link\"],[3,\"action\",[[23,0,[]],\"dispatch\",\"START_CANCEL_PROCEDURE_ACTION\"]],[8],[0,\"Cancel Appointment\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"type\"]],\"reschedule\"],null]],null,{\"statements\":[[0,\"\\t\"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"blue-link\"],[3,\"action\",[[23,0,[]],\"dispatch\",\"FETCH_AVAILABLE_DATES_ACTION\"]],[8],[0,\"Reschedule\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/alarm/appointments/change-appointment/template.hbs"
    }
  });

  _exports.default = _default;
});