define("adt-wss/pods/components/dashboard/flexfi-identifier-external/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jZYY3AK9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h5\",true],[10,\"class\",\"flexfi-title\"],[8],[0,\"You have scheduled payments through \"],[1,[24,[\"financierData\",\"financierCustName\"]],false],[0,\".\"],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"flexfi-description\"],[8],[0,\"\\n\\tPlease visit \"],[7,\"a\",true],[10,\"class\",\"blue-link financier-cust-website\"],[11,\"href\",[29,[\"http://\",[24,[\"financierData\",\"financierCustWebsite\"]]]]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[1,[24,[\"financierData\",\"financierCustWebsite\"]],false],[0,\" for billing details\"],[9],[0,\" or call \"],[1,[28,\"format-phone\",[[24,[\"financierData\",\"financierCustPhone\"]],\"-\"],null],false],[0,\".\\n\"],[4,\"if\",[[28,\"gte\",[[24,[\"financierData\",\"csNo\",\"length\"]],1],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[8],[0,\"(Includes system with CS# \"],[1,[28,\"join\",[\", \",[24,[\"financierData\",\"csNo\"]]],null],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/flexfi-identifier-external/template.hbs"
    }
  });

  _exports.default = _default;
});