define("adt-wss/pods/dashboard/shop/productsservices/products/route", ["exports", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/route-helpers"], function (_exports, _appDispatcher, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    orderData: Ember.inject.service(),
    hashSettled: Ember.RSVP.hashSettled,
    useResolvedOnly: _routeHelpers.useResolvedOnly,
    makeDeliverData: _routeHelpers.makeDeliverData,

    activate() {
      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    deactivate() {
      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      this.isRefresh = true;
      this.refresh();
    },

    model() {
      const options = {
        forceRequest: this.isRefresh
      };
      const orderDataDeliverData = this.makeDeliverData(this, 'orderData');
      return this.hashSettled({
        newSectionVisible: orderDataDeliverData('newSectionVisible', s => s.determNewsVisibility(), options)
      }).then(response => {
        this.isRefresh = false;
        return this.useResolvedOnly(response);
      });
    }

  });

  _exports.default = _default;
});