define("adt-wss/pods/components/dashboard/account/billing-statements/component", ["exports", "adt-wss/pods/dashboard/account/payments/controller", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/localization", "adt-wss-common/classes/status-message", "adt-wss-common/mixins/with-fsm", "adt-wss/pods/components/dashboard/account/billing-statements/constants"], function (_exports, _controller, _myadtHelpers, _emberHelpers, _localization, _statusMessage, _withFsm, constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    IDLE_STATE,
    EMAIL_MODAL_STATE,
    FETCHING_MANY_STATE,
    SENDING_EMAIL_STATE,
    GET_MANY_ACTION,
    VIEW_ACTION,
    DOWNLOAD_ACTION,
    START_VIDEO_ACTION,
    VIDEO_MODAL_STATE,
    START_EMAIL_ACTION,
    CANCEL_ACTION,
    SEND_EMAIL_ACTION,
    RESOLVE_MANY_ACTION,
    REJECT_MANY_ACTION,
    RESOLVE_EMAIL_ACTION,
    REJECT_EMAIL_ACTION
  } = constants;
  const fsmModel = {
    transitions: {
      [IDLE_STATE]: {
        [GET_MANY_ACTION]: FETCHING_MANY_STATE,
        [VIEW_ACTION]: IDLE_STATE,
        [DOWNLOAD_ACTION]: IDLE_STATE,
        [START_EMAIL_ACTION]: EMAIL_MODAL_STATE,
        [START_VIDEO_ACTION]: VIDEO_MODAL_STATE
      },
      [VIDEO_MODAL_STATE]: {
        [CANCEL_ACTION]: IDLE_STATE
      },
      [EMAIL_MODAL_STATE]: {
        [CANCEL_ACTION]: IDLE_STATE,
        [SEND_EMAIL_ACTION]: SENDING_EMAIL_STATE
      },
      [FETCHING_MANY_STATE]: {
        [RESOLVE_MANY_ACTION]: IDLE_STATE,
        [REJECT_MANY_ACTION]: IDLE_STATE
      },
      [SENDING_EMAIL_STATE]: {
        [RESOLVE_EMAIL_ACTION]: IDLE_STATE,
        [REJECT_EMAIL_ACTION]: EMAIL_MODAL_STATE
      }
    }
  };
  const dateRanges = [{
    value: 12,
    text: 'Past 12 Months'
  }, {
    value: 3,
    text: 'Past 3 Months'
  }, {
    value: 6,
    text: 'Past 6 Months'
  }, {
    value: 9,
    text: 'Past 9 Months'
  }];
  /**
   * @module Components/Dashboard/Account/BillingStatements
   * @prop {String} currentState
   * @prop {StateMachine} fsm
   * @prop {Object} dateRange date range for visible statements in table
   * @prop {Int} dateRange.value
   * @prop {String} dateRange.text
   * @prop {Object[]} dateRanges
   * @prop {String} email address to send statement to
   * @prop {Boolean} emailError
   * @prop {Boolean} paperlessVisible
   * @prop {StatusMessage[]} statusMessages
   * @prop {Computed<BillingStatement[]>} billingStatements
   * @prop {Computed<BillingStatement[]>} visibleStatements statements within the date range
   * @example {{dashboard/account/billing-statements paperless=model.paperlessOption}}
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    billingData: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    impersonationData: Ember.inject.service(),
    mmbIncidents: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    classNames: ['billing-statements'],
    fsmModel,
    currentState: IDLE_STATE,
    dateRange: dateRanges[0],
    dateRanges,
    email: Ember.computed.oneWay('userId'),
    emailError: false,
    statusMessages: null,
    billingStatements: Ember.computed.readOnly('billingData.billingStatements'),
    visibleStatements: (0, _emberHelpers.computedApplyFunction)((bs, dr) => {
      const range = dr.value || 12;
      const start = Date.getDateAfterNDays(range * -30.5).setMidnight();
      return (bs || []).filter(s => s.docDateObj < new Date() && s.docDateObj > start);
    }, 'billingStatements', 'dateRange'),
    isFetching: (0, _emberHelpers.computedApplyFunction)(cs => /ing/.test(cs), 'currentState'),

    /**
     * Runs API call then dispatches resolve/reject method
     */
    [GET_MANY_ACTION]() {
      this.get('billingData').getBillingStatements().then(() => this.get('fsm').dispatch(RESOLVE_MANY_ACTION)).catch(() => this.get('fsm').dispatch(REJECT_MANY_ACTION));
    },

    openStatement(statement, isDownload) {
      const startTime = Date.now();
      const eventTagInfo = {
        eventTypeId: 'VBILSTMT',
        subEventTypeId: 'VIEW'
      };
      const searchId = typeof sessionStorage !== 'undefined' ? sessionStorage.chudlySearchId || null : null;
      let url = _controller.docLinkTmpl + statement.docId;
      if (searchId) url += "?searchId=".concat(searchId);
      this.get('ET').sendEventTag(eventTagInfo);
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'View Bill', 'ViewBillStatementRequested', "#Statement Date: ".concat(statement.docDateObj.toUSAString())]);
      window.open(url);
      this.createIncidentOrIssue('WCVSTM', "".concat(isDownload ? 'Downloaded' : 'Viewed', " Statement"), startTime);
    },

    /**
     * Opens statement PDF in new window
     * @param {BillingStatement} statement
     */
    [VIEW_ACTION](statement) {
      this.openStatement(statement, false);
    },

    /**
     * Opens statement PDF in new window,
     * Records 'download' action in MMB
     * @param {BillingStatement} statement
     */
    [DOWNLOAD_ACTION](statement) {
      this.openStatement(statement, true);
    },

    /**
     * Opens 'Understand You Bill' modal
     */
    [START_VIDEO_ACTION]() {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');

      const onClose = () => {
        fsm.dispatch(CANCEL_ACTION, {
          errors: []
        });
        mwd.hide();
      };

      mwd.show({
        name: 'video-billing-statement',
        title: 'Understand Your Bill',
        onClose
      });
    },

    /**
     * Opens 'Email Statement' modal
     * @param {BillingStatement} statement
     */
    [START_EMAIL_ACTION](statement) {
      const {
        mwd,
        email,
        emailError,
        fsm
      } = this.getProperties('mwd', 'email', 'emailError', 'fsm');
      const format = 'PDF';
      const scope = this;
      const body = {
        isFetching: this.isFetching,
        statement,
        email,
        emailError,
        format
      };

      const showErrors = () => {
        Ember.set(body, 'isFetching', false);
        return (0, _myadtHelpers.showModalErrors)(fsm, mwd);
      };

      const onClose = () => {
        fsm.dispatch(CANCEL_ACTION, {
          errors: []
        });
        mwd.hide();
      };

      const submitAction = function (e) {
        const submittedEmail = typeof e === 'string' ? e : body.email;

        if (submittedEmail && submittedEmail.isValidEmail()) {
          scope.set('emailError', false);
          Ember.set(body, 'isFetching', true);
          fsm.dispatch(SEND_EMAIL_ACTION, {
            statement,
            submittedEmail,
            format
          }).then(() => fsm.dispatch(RESOLVE_EMAIL_ACTION, {
            submittedEmail,
            statement
          }, true)).catch(results => showErrors(results, REJECT_EMAIL_ACTION));
        } else {
          scope.set('emailError', true);
          mwd.showError((0, _localization.toExpandedString)('Login.errorInvalidEmail'));
        }
      };

      Ember.set(body, 'submitAction', submitAction);
      mwd.show({
        name: 'email-billing-statement',
        title: "Email Billing Statement for ".concat(statement.docDateObj.toAltString()),
        body,
        onClose,
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: 'Send',
          callback: submitAction
        }]
      });
    },

    /**
     * Retrieves statement URL, then sends to provided email
     * @param {Object} options
     * @param {BillingStatement} options.statement
     * @param {String} options.submittedEmail
     * @param {String} options.format defaults to 'PDF'
     */
    [SEND_EMAIL_ACTION]({
      submittedEmail,
      statement: {
        docId
      }
    }) {
      return this.get('billingData').emailBillingStatement(submittedEmail, docId);
    },

    [RESOLVE_EMAIL_ACTION]({
      submittedEmail,
      statement
    }) {
      const msg = "Billing document for ".concat(statement.docDateObj.toAltString(), " has been sent to ").concat(submittedEmail, ".");
      this.set('statusMessages', [new _statusMessage.default(msg, 'success')]);
      this.get('mwd').hide();
      if (!this.get('email')) this.set('email', submittedEmail);
      return (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Billing', 'Emailed statement']);
    },

    [REJECT_EMAIL_ACTION]() {
      return (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'View Billing Statement', 'Email Billing Statement clicked', 'Failed']);
    },

    /**
     * Creates MMB Incident
     * calls {@link Services/ImpersonationData.createIncidentOrIssue}
     * @param {String} transactionType
     * @param {String} jobComments
     * @param {Timestamp} startTime
     */
    createIncidentOrIssue(transactionType, jobComments, startTime = new Date()) {
      const headerInfo = this.get('impersonationData.headerInfo');
      const issueData = {
        customerNo: headerInfo ? headerInfo.customer.custNo : 0,
        siteNo: headerInfo ? headerInfo.site.siteNo : 0,
        duration: Date.now() - startTime,
        transactionType,
        jobComments
      };
      return this.get('mmbIncidents').createIncidentOrIssue(issueData);
    },

    /**
     * Handles errors. requires object parameter to have
     * 'errors' property
     * @param {AjaxResponse|Object} param0
     */
    handleErrors({
      errors
    }, event) {
      let e;

      if (Array.isArray(errors)) {
        e = errors.map(error => new _statusMessage.default(error, 'error'));
      } else {
        e = [new _statusMessage.default(errors, 'error')];
      }

      this.set('statusMessages', e);
      if (event) (0, _myadtHelpers.sendAnalyticEvent)(event);
      return true;
    },

    /**
     * Auto hides status message after timer expires
     * @param {Int} duration defaults to 5000
     */
    statusMessageTimeout(duration) {
      setTimeout(() => {
        this.set('statusMessages', null);
      }, duration || 5000);
    },

    init() {
      this._super(...arguments);

      if (!this.get('billingStatements')) {
        this.get('fsm').dispatch(GET_MANY_ACTION);
      }
    }

  });

  _exports.default = _default;
});