define("adt-wss/pods/components/dashboard/shop/contact-repair-kit/component", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers"], function (_exports, _emberHelpers, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Components/Dashboard/Shop/ContactRepairKit
   * @example
   * {@lang xml} {{ dashboard/shop/contact-repair-kit}}
   */
  var _default = Ember.Component.extend({
    classNames: ['contact-repair-kit'],
    MIN_QUANTITY_VALUE: 1,
    MAX_QUANTITY_VALUE: 5,
    systemInformation: Ember.inject.service(),
    footerIsHidden: Ember.computed.readOnly('systemInformation.outOfService'),
    quantityValue: (0, _emberHelpers.computedApplyFunction)(v => v, 'MIN_QUANTITY_VALUE'),

    sendAnalyticEvent(event, label) {
      return (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Products', event, label]);
    },

    didInsertElement() {
      this.sendAnalyticEvent('Page View', 'Adhesive Kit');
    }

  });

  _exports.default = _default;
});