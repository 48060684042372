define("adt-wss/pods/components/dashboard/alarm/appointments/change-appointment/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers", "ember-power-calendar-utils", "adt-wss-common/utils/fp", "adt-wss-common/utils/myadt-helpers", "adt-wss/pods/components/dashboard/alarm/appointments/change-appointment/constants"], function (_exports, _withFsm, _emberHelpers, _emberPowerCalendarUtils, _fp, _myadtHelpers, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProperties = {
    month: new Date(),
    selectedTimeIndex: 0,
    selectedDate: new Date()
  };
  const idleState = {
    [_constants.START_CANCEL_PROCEDURE_ACTION]: _constants.CANCEL_APPOINTMENT_STATE,
    [_constants.START_RESCHEDULE_PROCEDURE_ACTION]: _constants.RESCHEDULE_APPOINTMENT_STATE,
    [_constants.CLOSE_MODAL_ACTION]: _constants.IDLE_STATE,
    [_constants.FETCH_AVAILABLE_DATES_ACTION]: _constants.LOADING_STATE
  };
  const fsmModel = {
    transitions: {
      [_constants.IDLE_STATE]: idleState,
      [_constants.FAILURE_STATE]: idleState,
      [_constants.CANCEL_APPOINTMENT_STATE]: {
        [_constants.CLOSE_MODAL_ACTION]: _constants.IDLE_STATE,
        [_constants.CANCEL_APPOINTMENT_ACTION]: _constants.LOADING_STATE
      },
      [_constants.RESCHEDULE_APPOINTMENT_STATE]: {
        [_constants.CONFIRM_RESCHEDULE_APPOINTMENT_ACTION]: _constants.RESCHEDULE_APPOINTMENT_CONFIRM_STATE,
        [_constants.CLOSE_MODAL_ACTION]: _constants.IDLE_STATE,
        [_constants.FETCH_AVAILABLE_DATES_ACTION]: _constants.LOADING_STATE
      },
      [_constants.RESCHEDULE_APPOINTMENT_CONFIRM_STATE]: {
        [_constants.START_RESCHEDULE_PROCEDURE_ACTION]: _constants.RESCHEDULE_APPOINTMENT_STATE,
        [_constants.RESCHEDULE_APPOINTMENT_ACTION]: _constants.LOADING_STATE,
        [_constants.CLOSE_MODAL_ACTION]: _constants.IDLE_STATE
      },
      [_constants.LOADING_STATE]: {
        [_constants.SUCCESS_FETCH_ACTION]: _constants.IDLE_STATE,
        [_constants.SUCCESS_ACTION]: _constants.IDLE_STATE,
        [_constants.FAILURE_ACTION]: _constants.FAILURE_STATE
      }
    }
  };
  /**
   * Calculating dates to show arrow forward
   * @param {Date} maxDate
   * @param {Date} month
   */

  const showArrowForward = (maxDate, month) => (0, _emberPowerCalendarUtils.isAfter)(maxDate, month);
  /**
   * Calculating first day of month or current day
   * @param {Date} month
   */


  const firstDayOfMonth = month => {
    const firstDay = (0, _emberPowerCalendarUtils.isSame)(month, new Date(), 'day') ? month : (0, _emberPowerCalendarUtils.startOf)(month, 'month');
    return (0, _emberPowerCalendarUtils.formatDate)(firstDay, 'YYYY-MM-DDTHH:mm:ss.SSS');
  };
  /**
   * Calculating last day of month or last available day
   * @param {Date} month
   * @param {Date} maxDate
   */


  const lastDayOfMonth = (month, maxDate) => {
    const lastDay = (0, _emberPowerCalendarUtils.isSame)(maxDate, month, 'month') ? maxDate : (0, _emberPowerCalendarUtils.endOf)(month, 'month');
    return (0, _emberPowerCalendarUtils.formatDate)(lastDay || (0, _emberPowerCalendarUtils.endOf)(month, 'month'), 'YYYY-MM-DDTHH:mm:ss.SSS');
  };
  /**
   * Calculating dates to show arrow back
   * @param {Date} month
   */


  const showArrowBack = month => !(0, _emberPowerCalendarUtils.isSame)(month, new Date(), 'month');
  /**
   * Filtering dates for selected day
   * @param {Date} date
   * @param {Object[]} availableDates - available dates for reschedule appointment
   */


  const computeAvailableTime = (date, availableDates) => (availableDates || []).filter(d => (0, _emberPowerCalendarUtils.isSame)(d.start, date, 'date'));
  /**
   * Select on option of time
   * @param {Number} index
   * @param {Object[]} availableTimes - available dates for reschedule appointment
   */


  const selectTime = (index, availableTimes) => availableTimes[index];

  const isFailedState = (currentState, expectedType, type) => _constants.FAILURE_STATE === currentState && expectedType === type;

  const computeState = (state, expectedType) => (0, _emberHelpers.computedApplyFunction)((type, currentState) => state === currentState || isFailedState(currentState, expectedType, type), 'type', 'currentState');
  /**
   * **Change Appointment Component** - can **cancel** or **reschedule** scheduled appointment
  
   * --------------------------
   * @example template usage
   * {@lang xml}
   * {{dashboard/alarm/appointments/change-appointment type='cancel'
   *   appointment=this
   *   callback=(action 'myAction')
   * }}
   *
   * @module Components/Dashboard/Alarm/Appointments/ChangeAppointment
   * @prop {ApptObj} appointment
   * @prop {String} appointment.date
   * @prop {String} appointment.timeRange
   * @prop {Function} callback - display component or not. Also add class 'fade'
   * @prop {String} type - Title of modal window. Default: 'Modal window'.
  
   */


  var _default = Ember.Component.extend(_withFsm.default, defaultProperties, {
    fsmModel,
    appointmentData: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    currentState: _constants.IDLE_STATE,
    IS_LOADING: (0, _emberHelpers.isState)(_constants.LOADING_STATE),
    IS_CANCEL_VIEW: computeState(_constants.CANCEL_APPOINTMENT_STATE, 'cancel'),
    IS_RESCHEDULE_VIEW: (0, _emberHelpers.isState)(_constants.RESCHEDULE_APPOINTMENT_STATE),
    IS_RESCHEDULE_CONFIRM_VIEW: computeState(_constants.RESCHEDULE_APPOINTMENT_CONFIRM_STATE, 'reschedule'),
    startDate: (0, _emberHelpers.computedApplyFunction)(firstDayOfMonth, 'month'),
    endDate: (0, _emberHelpers.computedApplyFunction)(lastDayOfMonth, 'month', 'maxDate'),
    showForwardArrow: (0, _emberHelpers.computedApplyFunction)(showArrowForward, 'maxDate', 'month'),
    showBackArrow: (0, _emberHelpers.computedApplyFunction)(showArrowBack, 'minDate', 'month'),
    availableTimes: (0, _emberHelpers.computedApplyFunction)(computeAvailableTime, 'selectedDate', 'availableDates'),
    selectedTime: (0, _emberHelpers.computedApplyFunction)(selectTime, 'selectedTimeIndex', 'availableTimes'),

    /**
     * Open modal window of cancelation or reschuduling
     * @method OPEN_MODAL_ACTION
     * @arg {String} type - link type (cancel | reschedule)
     */
    [_constants.START_CANCEL_PROCEDURE_ACTION]() {
      const fsm = this.get('fsm');

      const save = () => fsm.dispatch(_constants.CANCEL_APPOINTMENT_ACTION, this.get('appointmentObj'));

      this.openModal('Cancel appointment', 'Cancel', null, 'Cancel appointment', save);
    },

    [_constants.FETCH_AVAILABLE_DATES_ACTION]() {
      const {
        siteNo,
        jobNo
      } = this.get('appointmentObj.appointment');
      const {
        startDate,
        endDate,
        month,
        fsm
      } = this.getProperties('startDate', 'endDate', 'month', 'fsm');
      const skippableErrors = ['ServiceAppointmentOffers.jstNoTimeAvailable', 'ServiceAppointmentOffers.jstNoAvailableTechnicians'];
      const monthAbbr = month.getMonthString();
      this.openModal('Reschedule appointment', 'Cancel', null, 'Next', null, () => true);
      return this.deliverData("appointmentData.rescheduleTimeblocks.".concat(monthAbbr), appointmentData => appointmentData.getRescheduleTimeBlocks({
        siteNo,
        jobNo,
        startDate,
        endDate,
        monthAbbr
      })).then(data => fsm.dispatch(_constants.SUCCESS_FETCH_ACTION, data)).catch(error => {
        if (error.errors && skippableErrors.indexOf(error.errors[0]) > -1) {
          return fsm.dispatch(_constants.SUCCESS_FETCH_ACTION, {
            jobNo,
            startDate,
            endDate,
            days: 'YYYYYYY',
            timeBand: 'RS',
            numberOfOffers: 0,
            serviceOffers: null
          });
        }

        return fsm.dispatch(_constants.FAILURE_ACTION, {
          error,
          type: 'reschedule',
          jobNo
        });
      });
    },

    [_constants.START_RESCHEDULE_PROCEDURE_ACTION]() {
      const fsm = this.get('fsm');

      const next = () => fsm.dispatch(_constants.CONFIRM_RESCHEDULE_APPOINTMENT_ACTION);

      const disableButton = () => Ember.computed.not('selectedTime');

      this.openModal('Reschedule appointment', 'Cancel', null, 'Next', next, disableButton);
    },

    [_constants.CONFIRM_RESCHEDULE_APPOINTMENT_ACTION]() {
      const {
        fsm,
        selectedDate,
        selectedTime,
        appointmentObj
      } = this.getProperties('fsm', 'selectedDate', 'selectedTime', 'appointmentObj');

      const back = () => fsm.dispatch(_constants.START_RESCHEDULE_PROCEDURE_ACTION);

      const save = () => fsm.dispatch(_constants.RESCHEDULE_APPOINTMENT_ACTION, {
        selectedDate,
        selectedTime,
        appointmentObj
      });

      this.openModal('Reschedule appointment', 'Back', back, 'Save', save);
    },

    resetModalWindowState() {
      this.set('selectedDate', new Date());
    },

    dispatchAction(comp, action, params) {
      comp.get('fsm').dispatch(action, params);
    },

    openModal(title, prevTitle, prev, nextTitle, next, disableButton = () => null) {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');

      const onClose = () => {
        fsm.dispatch(_constants.CLOSE_MODAL_ACTION);
        this.resetModalWindowState();
      };

      mwd.show({
        title,
        name: 'change-appointment',
        body: this,
        onClose,
        actions: [{
          caption: prevTitle || 'Cancel',
          stl: 'outline',
          callback: prev || onClose
        }, {
          caption: nextTitle || 'Next',
          disabled: disableButton(),
          callback: next || (() => 1)
        }]
      });
    },

    /**
     * Close modal window of cancelation or reschuduling
     * @method CLOSE_MODAL_ACTION
     */
    [_constants.CLOSE_MODAL_ACTION]() {
      this.get('mwd').hide();
      this.set('month', new Date());
    },

    /**
     * Send request to server to cancel scheduled appointment
     * @method CANCEL_APPOINTMENT_ACTION
     * @arg {Object} appointment
     * @arg {Date} appointment.startDate
     * @arg {Date} appointment.endDate
     * @arg {String} appointment.customerEmail
     * @arg {String|Number} appointment.jobNo
     * @return {Promise} Promise
     */
    [_constants.CANCEL_APPOINTMENT_ACTION](appointmentObj) {
      const {
        fsm,
        appointmentData
      } = this.getProperties('fsm', 'appointmentData');
      const {
        customerEmail,
        appointment: {
          jobNo,
          apptStartDateObj: startDate,
          apptEndDateObj: endDate
        }
      } = appointmentObj.getProperties('appointment', 'customerEmail', 'jobNo');
      const optimizerPromStartTime = "".concat(startDate.getFixedHour()).concat(startDate.getFixedMinute(), ":00"); // "0800:00"

      const optimizerPromEndTime = "".concat(endDate.getFixedHour()).concat(endDate.getFixedMinute(), ":00"); // "0800:00"

      const dataToServer = {
        jobNo,
        apptDate: startDate.toIntlString(),
        email: customerEmail,
        optimizerPromEndTime,
        optimizerPromStartTime
      };
      return appointmentData.cancelScheduledAppointment(dataToServer).then(data => fsm.dispatch(_constants.SUCCESS_ACTION, {
        data,
        type: 'cancel'
      })).catch(error => fsm.dispatch(_constants.FAILURE_ACTION, {
        error,
        type: 'cancel',
        jobNo
      }));
    },

    [_constants.RESCHEDULE_APPOINTMENT_ACTION]({
      selectedDate,
      selectedTime,
      appointmentObj
    }) {
      const {
        fsm,
        appointmentData
      } = this.getProperties('fsm', 'appointmentData');
      const {
        jobNo
      } = this.get('appointmentObj.appointment');
      return appointmentData.saveRescheduledAppointment({
        appointmentObj,
        selectedDate,
        selectedTime
      }).then(data => fsm.dispatch(_constants.SUCCESS_ACTION, {
        data,
        type: 'reschedule'
      })).catch(error => fsm.dispatch(_constants.FAILURE_ACTION, {
        error,
        type: 'reschedule',
        jobNo
      }));
    },

    /**
     * Close modal window and call callback function with success parameters
     * @method SUCCESS_ACTION
     * @arg {Object} data
     * @arg {Date} data.data - data to server
     * @arg {Date} data.type - change appointment type (cancel | reschedule)
     */
    [_constants.SUCCESS_ACTION]({
      data,
      type
    }) {
      this.get('mwd').hide();
      this.get('callback')({
        data,
        type,
        status: 'success'
      });
    },

    // CREATE FUNCTION TO TRANSFORM SELECTED DATE AND AVAILABLE DATES TO HOURS MAYBE COMPUTED PROPERTY
    [_constants.SUCCESS_FETCH_ACTION](timeblocks) {
      const availableDates = (timeblocks.offers || []).map(d => (0, _fp.omix)(d, {
        start: Date.createDate(d.start),
        end: Date.createDate(d.end)
      }));
      this.set('availableDates', availableDates);
      this.set('timeblocks', timeblocks);
      this.set('minDate', Date.createDate(timeblocks.startDate.replace(/-/g, '/')));
      this.set('maxDate', (0, _emberPowerCalendarUtils.add)(new Date(), 120, 'days'));

      if (!availableDates.length) {
        this.set('selectedDate', null);
      }

      this.get('fsm').dispatch(_constants.START_RESCHEDULE_PROCEDURE_ACTION);
    },

    /**
     * Close modal window and call callback function with failure parameters
     * @method FAILURE_ACTION
     * @arg {Object} data
     * @arg {Object} data.error - Error object
     * @arg {String} data.type - change appointment type (cancel | reschedule)
     * @arg {String} data.jobNo - job number
     */
    [_constants.FAILURE_ACTION]({
      error,
      type,
      jobNo
    }) {
      this.get('mwd').showError(_myadtHelpers.default.localizeStringWithParams([{
        fieldName: 'type',
        fieldValue: type
      }, {
        fieldName: 'jobNo',
        fieldValue: jobNo
      }], error.message || error.errors[0]));
    }

  });

  _exports.default = _default;
});