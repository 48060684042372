define("adt-wss/pods/dashboard/suggestedfeatures/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/tAU/0Z7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"section-title\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[8],[0,\"Suggested Features\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"orange-line\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"suggested-features\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/suggestedfeatures/template.hbs"
    }
  });

  _exports.default = _default;
});