define("adt-wss/pods/components/dashboard/alarm/system-identification/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/fp", "adt-wss-common/classes/status-message", "adt-wss/pods/components/dashboard/alarm/system-identification/constants"], function (_exports, _withFsm, _emberHelpers, _myadtHelpers, _appDispatcher, _fp, _statusMessage, constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.systemListToShowFilter = _exports.hasDisplayFunc = _exports.canTalkFunc = _exports.defaultProperties = _exports.fsmModel = void 0;
  const {
    UPDATE_SYSTEM_ACTION,
    SUCCESS_SYSTEM_ACTION,
    FAILURE_SYSTEM_ACTION,
    IDLE_STATE,
    LOADING_STATE,
    ERROR_STATE,
    FETCH_DATA_ACTION,
    SUCCESS_ACTION,
    FAILURE_ACTION
  } = constants;
  const fsmModel = {
    scrollToTop: true,
    transitions: {
      [IDLE_STATE]: {
        [FETCH_DATA_ACTION]: LOADING_STATE,
        [UPDATE_SYSTEM_ACTION]: LOADING_STATE
      },
      [ERROR_STATE]: {
        [FETCH_DATA_ACTION]: LOADING_STATE
      },
      [LOADING_STATE]: {
        [SUCCESS_ACTION]: IDLE_STATE,
        [FAILURE_ACTION]: ERROR_STATE,
        [SUCCESS_SYSTEM_ACTION]: IDLE_STATE,
        [FAILURE_SYSTEM_ACTION]: IDLE_STATE
      }
    }
  };
  _exports.fsmModel = fsmModel;
  const defaultProperties = {
    systemListInitial: null,
    systemListToShow: null,
    systemTalkOptions: ['Does your system talk?', 'Yes, my system talks', 'No, my system does not talk'],
    haveScreenOptions: ['Does it have a screen?', 'Yes, my system has a screen', 'No, my system not have a screen'],
    howArmOptions: ['How do you arm your system?'],
    howDisarmOptions: ['How do you disarm your system?']
  };
  _exports.defaultProperties = defaultProperties;

  const canTalkFunc = (systemTalkValue, systemTalkOptions) => {
    let res = null;

    if (systemTalkValue === systemTalkOptions[1]) {
      res = 'Yes';
    } else if (systemTalkValue === systemTalkOptions[2]) {
      res = 'No';
    }

    return res;
  };

  _exports.canTalkFunc = canTalkFunc;

  const hasDisplayFunc = (haveScreenValue, haveScreenOptions) => {
    let res = null;

    if (haveScreenValue === haveScreenOptions[1]) {
      res = 'Yes';
    } else if (haveScreenValue === haveScreenOptions[2]) {
      res = 'No';
    }

    return res;
  };

  _exports.hasDisplayFunc = hasDisplayFunc;

  const systemListToShowFilter = (armingSequence, disarmingSequence, canTalk, hasDisplay, systemListInitial) => {
    const conditions = {
      armingSequence: armingSequence !== 'How do you arm your system?',
      disarmingSequence: disarmingSequence !== 'How do you disarm your system?',
      canTalk: canTalk !== null,
      hasDisplay: hasDisplay !== null
    };
    const args = {
      armingSequence,
      disarmingSequence,
      canTalk,
      hasDisplay,
      systemListInitial
    };
    const systemListToShow = systemListInitial.filter(sys => !Object.keys(conditions).some(key => conditions[key] && sys[key] !== args[key])).filter(sys => sys.showInFilter);
    return systemListToShow.sort((x, y) => x.isCurrent ? -1 : y.isCurrent ? 1 : 0);
  };
  /**
   * @module Components/Dashboard/Alarm/SystemIdentification
   * @prop {Array} systemListInitial - user systems
   * @prop {Array} systemListToShow - user systems
   * @example
   * {@lang xml} {{ dashboard/alarm/system-identification
  		csNo=model
  }}
   */


  _exports.systemListToShowFilter = systemListToShowFilter;

  var _default = Ember.Component.extend(defaultProperties, _withFsm.default, {
    mwd: Ember.inject.service('modal-window-dispatcher'),
    fsmModel,
    dsm: Ember.inject.service('dashboard-status-messages'),
    statusMessages: Ember.computed.alias('dsm.statusMessages'),
    systemInformation: Ember.inject.service(),
    media: Ember.inject.service(),
    initAction: FETCH_DATA_ACTION,
    IS_LOADING: (0, _emberHelpers.isState)(LOADING_STATE),
    systemTalkValue: 'Does your system talk?',
    haveScreenValue: 'Does it have a screen?',
    armingSequence: 'How do you arm your system?',
    disarmingSequence: 'How do you disarm your system?',
    canTalk: (0, _emberHelpers.computedApplyFunction)(canTalkFunc, 'systemTalkValue', 'systemTalkOptions'),
    hasDisplay: (0, _emberHelpers.computedApplyFunction)(hasDisplayFunc, 'haveScreenValue', 'haveScreenOptions'),
    systemListToShow: (0, _emberHelpers.computedApplyFunction)(systemListToShowFilter, 'armingSequence', 'disarmingSequence', 'canTalk', 'hasDisplay', 'systemListInitial'),
    tooltipTriggerEvent: (0, _emberHelpers.computedApplyFunction)(isSmartphone => isSmartphone ? 'click' : 'hover', 'media.isSmartphone'),
    actions: {
      openSystemDetailsModal(system) {
        this.openSystemDetailsModal(system);
      },

      clearAllFilters() {
        this.clearAllFilters();
      }

    },

    systemSetRequestDone(successMsg, msgType) {
      this.get('router').transitionTo('dashboard.alarm.system');
      this.set('statusMessages', [new _statusMessage.default(successMsg, msgType)]);
    },

    [UPDATE_SYSTEM_ACTION](system) {
      const systemInformation = this.get('systemInformation');
      const csNumber = this.get('csNo');
      const data = {
        csNo: csNumber,
        systemDetailId: system.systemId,
        systemStandardizedName: system.systemStandardizedName
      };
      return this.commonDispatcher(() => systemInformation.setSystem(data, {
        systemId: system.systemId
      }), SUCCESS_SYSTEM_ACTION, FAILURE_SYSTEM_ACTION);
    },

    [SUCCESS_SYSTEM_ACTION]() {
      const successMsg = 'Thank you for confirming your system.';
      this.systemSetRequestDone(successMsg, 'success');
    },

    [FAILURE_SYSTEM_ACTION]() {
      const errorMsg = 'Sorry, we seem to be having technical issues assigning this system to your account. Please try again later.';
      this.systemSetRequestDone(errorMsg, 'error');
    },

    clearAllFilters() {
      this.set('systemTalkValue', this.get('systemTalkOptions')[0]);
      this.set('haveScreenValue', this.get('haveScreenOptions')[0]);
      this.set('armingSequence', this.get('howArmOptions')[0]);
      this.set('disarmingSequence', this.get('howDisarmOptions')[0]);
    },

    openSystemDetailsModal(system) {
      const mwd = this.get('mwd');

      const close = () => mwd.hide();

      system.isDetails = true;
      system.csNo = this.get('csNo');
      mwd.show({
        title: "System Information: ".concat(system.systemStandardizedName),
        name: 'system-details',
        body: [system],
        onClose: close,
        actions: [{
          caption: 'Close',
          stl: 'outline',
          callback: close
        }]
      });
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', "/systemIdentification.html#!system=".concat(system.systemId, "&state=details")]);
    },

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    changeLocationHandler() {
      this.get('fsm').dispatch(FETCH_DATA_ACTION, true);
    },

    getNotYourSystemId() {
      const {
        systemId
      } = this.get('systemInformation').getSelectedSystem(false, true);
      return systemId;
    },

    populateCombo(systemListFull) {
      const howArmOptions = ['How do you arm your system?'];
      const howDisarmOptions = ['How do you disarm your system?'];
      systemListFull.forEach(sys => {
        howArmOptions.push(sys.armingSequence);
        howDisarmOptions.push(sys.disarmingSequence);
      });
      this.set('howArmOptions', (0, _fp.uniq)(howArmOptions));
      this.set('howDisarmOptions', (0, _fp.uniq)(howDisarmOptions));
    },

    [SUCCESS_ACTION](standardSystemList) {
      const currentSystemId = this.getNotYourSystemId();
      const systemListFull = standardSystemList.map(system => (0, _fp.omix)(system, {
        isCurrent: system.systemId === currentSystemId
      }));
      this.set('systemListInitial', systemListFull);
      this.populateCombo(systemListFull);
    },

    [FETCH_DATA_ACTION](forceRequest) {
      return this.deliverData('systemInformation.standardSystemList', standardSystemList => standardSystemList.getStandardAndSystemInfoLists(), {
        forceRequest
      }).then(standardSystemList => this.get('fsm').dispatch(SUCCESS_ACTION, standardSystemList));
    }

  });

  _exports.default = _default;
});