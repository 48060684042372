define("adt-wss/pods/ps/expresspay/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aE0f1TqV",
    "block": "{\"symbols\":[],\"statements\":[[5,\"ps/payment-form\",[],[[\"@paymentAmount\",\"@paymentDate\",\"@isCC\",\"@uID\"],[[22,\"pmtAmt\"],[22,\"pmtDate\"],[22,\"isCC\"],[22,\"uID\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/ps/expresspay/template.hbs"
    }
  });

  _exports.default = _default;
});