define("adt-wss/pods/dashboard/account/contacts/route", ["exports", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/route-helpers"], function (_exports, _appDispatcher, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    myadtSecurity: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    contactData: Ember.inject.service(),
    makeDeliverData: _routeHelpers.makeDeliverData,

    afterModel() {
      const controller = this.controllerFor(this.routeName);
      controller.setDashboardStatusMessages();
      this.get('appDispatcher').subscribe(_appDispatcher.PIC_VALIDATED_EVENT, this, 'picSubmitted');
      this.get('appDispatcher').subscribe(_appDispatcher.PIC_INVALIDATED_EVENT, this, 'picSubmitted');

      if (this.get('currentLocation.noSystems')) {
        this.transitionTo('dashboard.account.index');
      }
    },

    model() {
      const contactDataDeliverData = this.makeDeliverData(this, 'contactData');
      return contactDataDeliverData('sitePhoneNumbers', s => s.getSiteNumbers()).then(() => contactDataDeliverData('addlContacts', s => s.getContacts())).then(_routeHelpers.useResolvedOnly);
    },

    deactivate() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.PIC_VALIDATED_EVENT, this, 'picSubmitted');
      this.get('appDispatcher').unsubscribe(_appDispatcher.PIC_INVALIDATED_EVENT, this, 'picSubmitted');
    },

    picSubmitted() {
      const {
        permissions
      } = this.get('currentLocation');

      if (permissions.hideContacts || !this.get('myadtSecurity.picProvided')) {
        this.transitionTo('dashboard.account.index');
      }
    }

  });

  _exports.default = _default;
});