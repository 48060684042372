define("adt-wss/pods/dashboard/account/preferences/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Controllers/Dashboard/Account/Preferences
   * @prop {Object} account - Parent controller
   */
  var _default = Ember.Controller.extend({
    contactData: Ember.inject.service(),
    account: Ember.inject.controller('dashboard/account'),
    csrInfo: Ember.inject.service(),
    actions: {
      setMessages(value) {
        this.set('account.dashboardStatusMessages', value);
      }

    }
  });

  _exports.default = _default;
});