define("adt-wss/pods/components/dashboard/account/shared-preferences/component", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss-common/mixins/with-fsm", "adt-wss/services/app-dispatcher", "adt-wss/pods/components/dashboard/account/shared-preferences/constants"], function (_exports, _emberHelpers, _withFsm, _appDispatcher, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.defaultProps = void 0;
  const defaultProps = {
    opted: true
  };
  _exports.defaultProps = defaultProps;
  const defSharingPreferenceData = {
    monitoringInfoConsent: false
  };

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    classNames: ['communication-preferences-widget'],
    contactData: Ember.inject.service(),
    sharingPreferenceData: (0, _emberHelpers.computedApplyFunction)(pc => pc || defSharingPreferenceData, 'contactData.sharingPreferenceData'),
    currentState: _constants.IDLE_STATE,
    initAction: _constants.LOAD_DATA_ACTION,
    SUCCESSFULLY_UPDATED_MESSAGE: _constants.SUCCESSFULLY_UPDATED_MESSAGE,
    GENERIC_ERROR_MESSAGE: _constants.GENERIC_ERROR_MESSAGE,
    IS_LOADING_STATE: Ember.computed.equal('currentState', _constants.LOADING_STATE),
    types: null,

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    /**
     * Handles failed loading
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     */
    [_constants.FAILED_LOADING_ACTION](e) {
      console.log('Loading failed', e);
    },

    /**
     * Handles changing of location
     * Triggered on `appDispatcher` service
     *
     * @function
     */
    actions: {
      saveMonitoringInfoConsent(consent) {
        const fsm = this.get('fsm');
        return this.contactData.updateMonitoringInfoConsent({
          consent
        }).then(() => {// notify([new StatusMessage(message, 'success')]);
          // fsm.dispatch(LOAD_DATA_ACTION);
        }).catch(e => fsm.dispatch(FAILURE_ACTION, e));
        ;
      },

      /**
       * Calls `saveMonitoringInfoConsent` method when changing the consent.
       *
       * @function
       * @param {Boolean} consent
       * @returns {Promise}
       */
      updateMonitoringInfoConsent(consent) {
        // const emails = this.get('emails');
        return this.saveMonitoringInfoConsent({
          consent
        });
      }

    }
  });

  _exports.default = _default;
});