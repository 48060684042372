define("adt-wss/pods/index/route", ["exports", "adt-wss/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    rootURL: _environment.default.rootURL,
    actions: {
      invalidate() {
        if (this.get('session.isAuthenticated')) {
          this.get('session').invalidate();
        }
      }

    }
  });

  _exports.default = _default;
});