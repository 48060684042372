define("adt-wss/pods/dashboard/account/profile/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Controllers/Dashboard/Account/Profile
   * @prop {Object} contactData - injected service
   * @prop {Object} userProfile - injected service
   * @prop {Object} billingData - injected service
   * @prop {Object} commonPasswords - injected service
   * @prop {Object} messages - use by statusMessages
   */
  var _default = Ember.Controller.extend({
    account: Ember.inject.controller('dashboard/account'),
    contactData: Ember.inject.service(),
    userProfile: Ember.inject.service(),
    billingData: Ember.inject.service(),
    commonPasswords: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    csrInfo: Ember.inject.service(),
    messages: null,
    overlaped: false,
    actions: {
      setStatusMessages(messages) {
        this.set('account.dashboardStatusMessages', messages);
      },

      setOverlap(value) {
        this.set('overlaped', value);
      },

      updateQuestionAnswer(data) {
        const payload = Ember.getProperties(data, ['questions', 'answers']);
        return this.get('userProfile').updateQuestionAnswer(payload).then(d => this.set('model.userProfileData', d));
      },

      submitBillingAddress(value) {
        const data = Ember.getProperties(value, ['addressLine1', 'addressLine2', 'zipCode', 'city', 'state']);
        return this.get('billingData').updateBillingAddress(data).then(address => this.set('model.billData.address', address));
      },

      submitProfileInfo(value) {
        return this.submitProfileInfo(value);
      },

      submitProfilePassword(value) {
        const data = Ember.getProperties(value, ['password', 'existingPassword', 'passwordId', 'manage', 'irregularOpen']);
        return this.get('commonPasswords').updatePassword(data);
      },

      deleteProfilePassword(value) {
        const data = Ember.getProperties(value, ['password', 'existingPassword', 'passwordId', 'manage', 'irregularOpen']);
        return this.get('commonPasswords').removePassword(data);
      }

    },

    /**
     * Updates profile info, email and new password
     * - API updateEmail - send email, confirmEmail, currentPassword
     * - API updatePassword - send password, confirmPassword
     * - API updateAddlContact - firstName, lastName
     * - Unnecessary properties are deleted to avoid Error 500
     *
     * @param {Object} value - data from form
     * @param {String} value.firstName
     * @param {String} value.lastName
     * @param {String} value.email
     * @param {String} value.currentPassword
     * @param {String} value.phoneNumber
     * @param {String} value.password
     * @param {String} value.confirmPassword
     * @returns {*}
     */
    submitProfileInfo(value) {
      const currentEmail = this.get('model.profileInfo.email');
      const currentFirstName = this.get('model.profileInfo.firstName');
      const currentLastName = this.get('model.profileInfo.lastName');
      const userProfileService = this.get('userProfile');
      const {
        firstName,
        lastName,
        email,
        currentPassword,
        password,
        confirmPassword
      } = value;
      const updateEmailData = this.get('csrInfo.isCsr') ? {
        confirmEmail: email,
        currentPassword,
        email,
        password,
        confirmPassword
      } : {
        confirmEmail: email,
        currentPassword,
        email
      };
      const isPassword = password.length;
      return (currentEmail !== email ? userProfileService.updateEmail(updateEmailData) : Ember.RSVP.Promise.resolve()).then(() => currentFirstName !== firstName || currentLastName !== lastName ? userProfileService.updateName({
        firstName,
        lastName,
        currentPassword
      }) : null).then(() => !isPassword ? null : userProfileService.updatePassword({
        password,
        confirmPassword,
        currentPassword
      })).then(() => userProfileService.getProfileData());
    }

  });

  _exports.default = _default;
});