define("adt-wss/pods/dashboard/shop/productsservices/battery/route", ["exports", "jquery", "adt-wss/utils/iframeResizer", "adt-wss-common/mixins/scroll-route-to-top"], function (_exports, _jquery, _iframeResizer, _scrollRouteToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollRouteToTop.default, {
    afterModel() {
      this.iFrameResize({
        log: false,
        // Enable console logging
        enablePublicMethods: true,
        // Enable methods within iframe hosted page
        scrolling: false,
        resizedCallback: messageData => {
          // Callback fn when resize is received
          (0, _jquery.default)('p#callback').html("<b>Frame ID:</b> ".concat(messageData.iframe.id, " <b>Height:</b> ").concat(messageData.height, " <b>Width:</b> ").concat(messageData.width, " <b>Event type:</b> ").concat(messageData.type));
        },
        messageCallback: messageData => {
          // Callback fn when message is received
          (0, _jquery.default)('p#callback').html("<b>Frame ID:</b> ".concat(messageData.iframe.id, " <b>Message:</b> ").concat(messageData.message));
          console.log(messageData.message);
        },
        closedCallback: id => {
          // Callback fn when iFrame is closed
          (0, _jquery.default)('p#callback').html("<b>IFrame (</b>".concat(id, "<b>) removed from page.</b>"));
        }
      }, '#batteryIframe');
    },

    init() {
      this._super(...arguments);

      window.helpIframeOnload = function helpIframeOnload() {
        const iFrame = document.getElementById('batteryIframe');
        iFrame.height = document.body.height;
      };

      this.iFrameResize = _iframeResizer.default;
    }

  });

  _exports.default = _default;
});