define("adt-wss/instance-initializers/hot-loader-livereload-plugin", ["exports", "ember-cli-hot-loader/instance-initializers/hot-loader-livereload-plugin"], function (_exports, _hotLoaderLivereloadPlugin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _hotLoaderLivereloadPlugin.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _hotLoaderLivereloadPlugin.initialize;
    }
  });
});