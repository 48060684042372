define("adt-wss/mixins/dashboard-status-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Inserts `dashboardStatusMessages` in the controller
   * for display of messages above dashboard tabs
   * @mixin DashboardStatusMessages
   */
  var _default = Ember.Mixin.create({
    dsm: Ember.inject.service('dashboard-status-messages'),
    dashboardStatusMessages: Ember.computed.alias('dsm.statusMessages'),

    clear() {
      this.get('dsm').clear();
    }

  });

  _exports.default = _default;
});