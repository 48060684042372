define("adt-wss/templates/modal-windows/easypay-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+s++vsPv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"easypay\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"easypay-blue-bar\"],[8],[9],[0,\"\\n\\t\"],[7,\"img\",true],[10,\"class\",\"easypay-welcome-image\"],[11,\"src\",[29,[[24,[\"body\",\"genericImage\"]]]]],[10,\"alt\",\"Welcome\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"easypay-content\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"easypay-content-title\"],[8],[0,\"\\n\\t\\tNever miss a payment, enroll in ADT EasyPay.\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"easypay-content-text\"],[8],[0,\"\\n\\t\\tThe free, automated way to pay for your security monitoring service.\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"easypay-content-message\"],[8],[0,\"\\n\\t\\tThere is no additional cost - just one less thing to worry about.\\n\\t\\tWith your one-time enrollment, your security service fees will be automatically charged to your bank account or\\n\\t\\tcredit card. \"],[7,\"span\",true],[10,\"class\",\"hide-mobile\"],[8],[5,\"link-to\",[[12,\"target\",\"_blank\"]],[[\"@route\"],[\"index.usageagreement\"]],{\"statements\":[[0,\"Terms and Conditions\"]],\"parameters\":[]}],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/easypay-notification.hbs"
    }
  });

  _exports.default = _default;
});