define("adt-wss/pods/dashboard/account/preferences/route", ["exports", "adt-wss-common/utils/route-helpers"], function (_exports, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    hashSettled: Ember.RSVP.hashSettled,
    useResolvedOnly: _routeHelpers.useResolvedOnly,

    model() {
      const {
        paperlessVisible
      } = this.modelFor('dashboard.account');
      return this.hashSettled({
        paperlessVisible
      }).then(this.useResolvedOnly);
    }

  });

  _exports.default = _default;
});