define("adt-wss/pods/components/show-mobile/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Components/ShowMobile
   * @example
   * {@lang xml}
   * {{#show-mobile}}
   *   <section>Visible only for mobiles</section>
   * {{/show-mobile}}
   */
  var _default = Ember.Component.extend({
    tagName: ''
  });

  _exports.default = _default;
});