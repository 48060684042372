define("adt-wss/pods/dashboard/alarm/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    myadtSecurity: Ember.inject.service(),

    redirect() {
      if (!this.get('myadtSecurity.picProvided')) {
        this.transitionTo('dashboard.alarm.appointments');
      } else {
        this.transitionTo('dashboard.alarm.system');
      }
    }

  });

  _exports.default = _default;
});