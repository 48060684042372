define("adt-wss/pods/components/pypestream-layout/component", ["exports", "adt-wss/services/app-dispatcher"], function (_exports, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let PypestreamLayoutComponent = (_class = (_temp = class PypestreamLayoutComponent extends Ember.Component {
    get siteNo() {
      return this.systemInformation.selectedSystem ? this.systemInformation.selectedSystem.siteNo : "";
    }

    openChat() {
      this.showChatWindow = true;
    }

    closeChat() {
      this.showPSChat = this.liveChat.showPSChat;

      if (!this.showPSChat) {
        this.appDispatcher.sendEvent(_appDispatcher.DISABLE_PS_CHAT_EVENT);
      }

      this.showChatWindow = false;
    }

    hideChat() {
      this.showChatWindow = false;
    }

    enableChat() {
      this.showPSChat = true;
      this.launchButton();
    }

    boot() {
      const chatContainer = document.getElementById('chat-container');
      window.Pypestream('boot', {
        APP_ID: this.pypeAppID
      }, chatContainer);
      this.openChat();
    }

    launch() {
      if (window.Pypestream('isReady')) {
        // If wcw is already initialized, show it
        setTimeout(() => {
          window.Pypestream('show');
        }, 0);
      } else {
        //else boot it and show
        this.boot();
      }
    }

    launchButton() {
      this.ssoInfo.getIsSsoEnabled().then(() => {
        if (this.session.isAuthenticated) {
          return this.authInfo.getPsToken();
        }
      }).finally(() => {
        window.Pypestream('toggle');
        return setTimeout(this.launch, 250);
      });
    }

    toggleButtonClick() {
      window.Pypestream('toggle');
      return this.toggle();
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "apiService", _descriptor, this);

      _initializerDefineProperty(this, "liveChat", _descriptor2, this);

      _initializerDefineProperty(this, "ssoInfo", _descriptor3, this);

      _initializerDefineProperty(this, "authInfo", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      _initializerDefineProperty(this, "systemInformation", _descriptor6, this);

      _initializerDefineProperty(this, "showPSChat", _descriptor7, this);

      _initializerDefineProperty(this, "showChatButton", _descriptor8, this);

      _initializerDefineProperty(this, "showChatWindow", _descriptor9, this);

      _initializerDefineProperty(this, "pypeDomain", _descriptor10, this);

      _initializerDefineProperty(this, "pypeEnv", _descriptor11, this);

      this.liveChat.getLiveChatData().then(() => {
        const {
          pypeAppProdId,
          pypeAppDevId,
          showPSChat
        } = this.liveChat;
        this.showPSChat = showPSChat;

        if (document.location.pathname.indexOf('/ps/') > -1) {
          this.showPSChat = false;
        }

        if (document.location.pathname.indexOf('/ps/') < 0) {
          const pype = document.createElement('script');
          pype.type = 'text/javascript';
          pype.src = (/www.myadt|\/\/myadt.com/i.test(document.location.href) ? 'https://webchat.pypestream.com' : 'https://webchat-sandbox.pypestream.com') + '/webchat-public.js';
          this.pypeAppID = pypeAppDevId;

          if (/www.myadt|\/\/myadt.com/i.test(document.location.href)) {
            this.pypeDomain = 'prod';
            this.pypeEnv = 'prod';
            this.pypeAppID = pypeAppProdId;
          }

          const s = document.getElementsByTagName('script')[0];
          return s.parentNode.insertBefore(pype, s);
        }

        return;
      });
    }

    init() {
      super.init(...arguments);
      let intValCount = 0;
      const intVal = setInterval(() => {
        if (window.Pypestream) {
          clearInterval(intVal);
        } else if (intValCount > 10) {
          return clearInterval(intVal);
        } else {
          return intValCount++;
        } //setting up pypestream config


        window.Pypestream('config', {
          domain: this.pypeDomain,
          env: this.pypeEnv,
          passthrough: '',
          beta: true
        }); //Pypestream message event listener

        const eventHandles = ['SESSION_INVALIDATED', 'PS_LOGIN_SUCESS', 'PS_LOGIN_ERROR', 'PS_EXPRESSPAY_SUCCESS', 'PS_EXPRESSPAY_ERROR', 'PS_EASYPAY_SUCCESS', 'PS_EASYPAY_ERROR', 'PS_PIC_LOGIN_SUCCESS', 'PS_PIC_LOGIN_ERROR', 'PS_ACC_LOGIN_SUCCESS', 'PS_ACC_LOGIN_ERROR', 'PS_PIC_SUCCESS', 'PS_PIC_ERROR', 'PS_FORGOT_PWD', 'PS_AUTH_LOGIN_SUCESS'];
        const eventTarget = window;
        window.Pypestream('registerForEvents', eventHandles, eventTarget);
        window.addEventListener('message', e => {
          //Success events
          const token = e.data.authToken;
          let ps_evt;
          let detail;

          if (token) {
            ps_evt = new CustomEvent('PS_LOGIN_SUCESS');
            detail = {
              myadtToken: token
            };
            ps_evt.initCustomEvent('PS_LOGIN_SUCESS', true, true, detail);
            window.dispatchEvent(ps_evt);
          }

          const epConfirmationNumber = e.data.epConfirmationNumber;

          if (epConfirmationNumber) {
            ps_evt = new CustomEvent('PS_EXPRESSPAY_SUCCESS');
            detail = {
              myadtMessage: 'Your payment was successful.',
              confirmationNumber: epConfirmationNumber
            };
            ps_evt.initCustomEvent('PS_EXPRESSPAY_SUCCESS', true, true, detail);
            window.dispatchEvent(ps_evt);
          }

          const ezMessage = e.data.ezMessage;

          if (ezMessage) {
            ps_evt = new CustomEvent('PS_EASYPAY_SUCCESS');
            detail = {
              myadtMessage: ezMessage
            };
            ps_evt.initCustomEvent('PS_EASYPAY_SUCCESS', true, true, detail);
            window.dispatchEvent(ps_evt);
          }

          const picLogin = e.data.picData;

          if (picLogin) {
            ps_evt = new CustomEvent('PS_PIC_LOGIN_SUCCESS');
            detail = {
              myadtToken: picLogin.myadtToken,
              siteList: picLogin.siteList
            };
            ps_evt.initCustomEvent('PS_PIC_LOGIN_SUCCESS', true, true, detail);
            window.dispatchEvent(ps_evt);
          }

          const accData = e.data.billInfo;

          if (accData) {
            ps_evt = new CustomEvent('PS_ACC_LOGIN_SUCCESS');
            detail = {
              billInfo: accData
            };
            ps_evt.initCustomEvent('PS_ACC_LOGIN_SUCCESS', true, true, detail);
            window.dispatchEvent(ps_evt);
          }

          const picUpdate = e.data.picUpdate;

          if (picUpdate) {
            ps_evt = new CustomEvent('PS_PIC_SUCCESS');
            detail = {
              picSuccess: picUpdate
            };
            ps_evt.initCustomEvent('PS_PIC_SUCCESS', true, true, detail);
            window.dispatchEvent(ps_evt);
          }

          const hpfData = e.data.hpfData;

          if (hpfData) {
            ps_evt = new CustomEvent('HPF_SUCCESS');
            detail = {
              myadtMessage: hpfData.message,
              uID: hpfData.uID
            };
            ps_evt.initCustomEvent('HPF_SUCCESS', true, true, detail);
            window.dispatchEvent(ps_evt);
          }

          const psForgotPwd = e.data.psForgotPwd;

          if (psForgotPwd) {
            ps_evt = new CustomEvent('PS_FORGOT_PWD');
            detail = {
              forgotPwd: psForgotPwd
            };
            ps_evt.initCustomEvent('PS_FORGOT_PWD', true, true, detail);
            window.dispatchEvent(ps_evt);
          } // Error events


          const error = e.data.psError;

          if (error === 'session-invalidated') {
            ps_evt = new CustomEvent('SESSION_INVALIDATED');
            detail = {
              myadtError: 'Your session has ended.'
            };
            ps_evt.initCustomEvent('SESSION_INVALIDATED', true, true, detail);
            window.dispatchEvent(ps_evt);
          } else if (error === 'error-login-ps') {
            ps_evt = new CustomEvent('PS_LOGIN_ERROR');
            detail = {
              myadtError: 'Your user name and password did not match our records. Please try again.'
            };
            ps_evt.initCustomEvent('PS_LOGIN_ERROR', true, true, detail);
            window.dispatchEvent(ps_evt);
          } else if (error === 'error-expresspay-ps') {
            ps_evt = new CustomEvent('PS_EXPRESSPAY_ERROR');
            detail = {
              myadtError: 'Your payment was unsuccessful. Please try again.'
            };
            ps_evt.initCustomEvent('PS_EXPRESSPAY_ERROR', true, true, detail);
            window.dispatchEvent(ps_evt);
          } else if (error === 'error-easypay-ps') {
            ps_evt = new CustomEvent('PS_EASYPAY_ERROR');
            detail = {
              myadtError: 'This change cannot be made because there are pending EasyPay changes on this account.'
            };
            ps_evt.initCustomEvent('PS_EASYPAY_ERROR', true, true, detail);
            window.dispatchEvent(ps_evt);
          }

          const picError = e.data.picError;

          if (picError) {
            ps_evt = new CustomEvent('PS_PIC_LOGIN_ERROR');
            detail = {
              myadtError: picError
            };
            ps_evt.initCustomEvent('PS_PIC_LOGIN_ERROR', true, true, detail);
            window.dispatchEvent(ps_evt);
          }

          const accError = e.data.accError;

          if (accError) {
            ps_evt = new CustomEvent('PS_ACC_LOGIN_ERROR');
            detail = {
              myadtError: accError
            };
            ps_evt.initCustomEvent('PS_ACC_LOGIN_ERROR', true, true, detail);
            window.dispatchEvent(ps_evt);
          }

          const picFail = e.data.picFail;

          if (picFail) {
            ps_evt = new CustomEvent('PS_PIC_ERROR');
            detail = {
              picFail
            };
            ps_evt.initCustomEvent('PS_PIC_ERROR', true, true, detail);
            window.dispatchEvent(ps_evt);
          }

          const hpfError = e.data.hpfError;

          if (hpfError) {
            ps_evt = new CustomEvent('HPF_ERROR');
            detail = {
              myadtMessage: hpfError.message,
              uID: hpfError.errorCode
            };
            ps_evt.initCustomEvent('HPF_ERROR', true, true, detail);
            window.dispatchEvent(ps_evt);
          }
        });
        this.appDispatcher.subscribe(_appDispatcher.SHOW_PS_CHAT_EVENT, this, 'launchButton');
        this.appDispatcher.subscribe(_appDispatcher.HIDE_PS_CHAT_EVENT, this, 'hideChat');
        this.appDispatcher.subscribe(_appDispatcher.ENABLE_PS_CHAT_EVENT, this, 'enableChat'); // open chat onload from sms link

        if (document.location.pathname.indexOf('/ich/') > -1 || document.location.pathname.indexOf('/billinghelp') > -1) {
          setTimeout(this.launchButton, 50);
        }

        if (window.Pypestream('isVisible')) {
          setTimeout(this.boot, 50);
        } //Event listeners for on show and on hide of chat container


        window.Pypestream('onShow', () => setTimeout(this.openChat, 0));
        window.Pypestream('onHide', () => setTimeout(this.hideChat, 0));
        window.Pypestream('onChatEnd', () => setTimeout(this.closeChat, 0));
      }, 1000);
    }

    willDestroy() {
      super.willDestroy(...arguments);
      this.get('appDispatcher').unsubscribe(_appDispatcher.SHOW_PS_CHAT_EVENT, this, 'launchButton');
      this.get('appDispatcher').unsubscribe(_appDispatcher.HIDE_PS_CHAT_EVENT, this, 'hideChat');
      this.get('appDispatcher').unsubscribe(_appDispatcher.ENABLE_PS_CHAT_EVENT, this, 'enableChat');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiService", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "liveChat", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ssoInfo", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "authInfo", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "systemInformation", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showPSChat", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showChatButton", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showChatWindow", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "pypeDomain", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'dev';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "pypeEnv", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'sandbox';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openChat", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "openChat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeChat", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "closeChat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideChat", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "hideChat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "enableChat", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "enableChat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "boot", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "boot"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "launch", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "launch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "launchButton", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "launchButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleButtonClick", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleButtonClick"), _class.prototype)), _class);
  _exports.default = PypestreamLayoutComponent;
});