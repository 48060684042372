define("adt-wss/services/csr-info", ["exports", "adt-wss-common/utils/http-resources"], function (_exports, _httpResources) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * CSR User data
   * @module Services/CsrInfo
   *
   * @prop {Boolean} isCsr @observes {@link module:Services/AuthInfo Services/AuthInfo.authInfo.csrUser}
   * @prop {Object} csr
   * @prop {String} csr.customerName
   * @prop {Int} csr.employeeNumber
   * @prop {String} csr.employeeRoleId
   * @prop {Boolean} csr.impersonatingCustomer
   * @prop {String} csr.impersonationType
   * @prop {Boolean} csr.manager
   * @prop {Int} csr.memberId
   * @prop {Boolean} csr.nonregisteredUser
   * @prop {String} csr.team
   * @prop {String} csr.username
   */
  var _default = Ember.Service.extend({
    authInfo: Ember.inject.service(),
    impersonationData: Ember.inject.service(),
    apiService: Ember.inject.service(),
    isCsr: Ember.computed.readOnly('authInfo.authInfo.csrUser'),
    csr: null,

    getCSRInformation() {
      if (!this.get('csr')) {
        return this.getCurrentCSR().then(() => this.get('impersonationData').populateAll());
      }

      return this.get('impersonationData').populateAll();
    },

    /**
     * Retrieves CSR info from API.
     * @type {ServiceCall}
     * @listens module:Services/authInfo.isAuthenticated
     */
    getCurrentCSR() {
      this.set('csr', null);
      return this.get('apiService').request({
        showLoading: false,
        resource: _httpResources.combined.getCurrentCSR
      }).then(({
        data
      }) => this.set('csr', data));
    }

  });

  _exports.default = _default;
});