define("adt-wss/pods/components/dashboard/account/monitoring-reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D9yut5dk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"class\",\"email-opt-in-info\"],[8],[0,\"\\n\"],[4,\"loading-spinner\",[[24,[\"IS_UPDATING_STATE\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"i\",false],[12,\"class\",[29,[\"opt-in-icon \",[28,\"if\",[[24,[\"opted\"]],\"enabled\",\"disabled\"],null]]]],[3,\"action\",[[23,0,[]],\"showModalWindow\"]],[8],[0,\"\\n\"],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showPhoneFields\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"td\",true],[10,\"class\",\"phone-opt-in-info\"],[8],[0,\" \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"td\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"IS_UPDATING_STATE\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"button\",false],[12,\"class\",\"btn communication-preference-action-btn\"],[3,\"action\",[[23,0,[]],\"showModalWindow\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"media\",\"isSmartphone\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"settings-gear-icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\tEdit\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/account/monitoring-reports/template.hbs"
    }
  });

  _exports.default = _default;
});