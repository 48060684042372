define("adt-wss/pods/components/dashboard/account/enroll-paperless-statement/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CANCEL_PRE_ENROLL_ACTION = _exports.FAILURE_LOADING_ACTION = _exports.SUCCESSFUL_LOADING_ACTION = _exports.FAILURE_UNENROLL_ACTION = _exports.SUCCESSFUL_UNENROLL_ACTION = _exports.START_UNENROLLING_ACTION = _exports.BACK_TO_ENROLLED_STATE_ACTION = _exports.FAILURE_ENROLL_ACTION = _exports.SUCCESSFUL_ENROLL_ACTION = _exports.START_ENROLLING_ACTION = _exports.TRANSITION_TO_PRE_UNENROLL_ACTION = _exports.TRANSITION_TO_PRE_ENROLL_ACTION = _exports.ENROLLING_STATE = _exports.UNENROLLING_STATE = _exports.UNENROLLED_STATE = _exports.SYSTEM_PENDING_ENROLLING_STATE = _exports.PRE_ENROLL_STATE = _exports.PRE_UNENROLL_STATE = _exports.ENROLLED_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const ENROLLED_STATE = 'ENROLLED_STATE';
  _exports.ENROLLED_STATE = ENROLLED_STATE;
  const PRE_UNENROLL_STATE = 'PRE_UNENROLL_STATE';
  _exports.PRE_UNENROLL_STATE = PRE_UNENROLL_STATE;
  const PRE_ENROLL_STATE = 'PRE_ENROLL_STATE';
  _exports.PRE_ENROLL_STATE = PRE_ENROLL_STATE;
  const SYSTEM_PENDING_ENROLLING_STATE = 'SYSTEM_PENDING_ENROLLING_STATE';
  _exports.SYSTEM_PENDING_ENROLLING_STATE = SYSTEM_PENDING_ENROLLING_STATE;
  const UNENROLLED_STATE = 'UNENROLLED_STATE';
  _exports.UNENROLLED_STATE = UNENROLLED_STATE;
  const UNENROLLING_STATE = 'UNENROLLING_STATE';
  _exports.UNENROLLING_STATE = UNENROLLING_STATE;
  const ENROLLING_STATE = 'ENROLLING_STATE';
  _exports.ENROLLING_STATE = ENROLLING_STATE;
  const TRANSITION_TO_PRE_ENROLL_ACTION = 'TRANSITION_TO_PRE_ENROLL_ACTION';
  _exports.TRANSITION_TO_PRE_ENROLL_ACTION = TRANSITION_TO_PRE_ENROLL_ACTION;
  const TRANSITION_TO_PRE_UNENROLL_ACTION = 'TRANSITION_TO_PRE_UNENROLL_ACTION';
  _exports.TRANSITION_TO_PRE_UNENROLL_ACTION = TRANSITION_TO_PRE_UNENROLL_ACTION;
  const START_ENROLLING_ACTION = 'START_ENROLLING_ACTION';
  _exports.START_ENROLLING_ACTION = START_ENROLLING_ACTION;
  const SUCCESSFUL_ENROLL_ACTION = 'SUCCESSFUL_ENROLL_ACTION';
  _exports.SUCCESSFUL_ENROLL_ACTION = SUCCESSFUL_ENROLL_ACTION;
  const FAILURE_ENROLL_ACTION = 'FAILURE_ENROLL_ACTION';
  _exports.FAILURE_ENROLL_ACTION = FAILURE_ENROLL_ACTION;
  const BACK_TO_ENROLLED_STATE_ACTION = 'BACK_TO_ENROLLED_STATE_ACTION';
  _exports.BACK_TO_ENROLLED_STATE_ACTION = BACK_TO_ENROLLED_STATE_ACTION;
  const START_UNENROLLING_ACTION = 'START_UNENROLLING_ACTION';
  _exports.START_UNENROLLING_ACTION = START_UNENROLLING_ACTION;
  const SUCCESSFUL_UNENROLL_ACTION = 'SUCCESSFUL_UNENROLL_ACTION';
  _exports.SUCCESSFUL_UNENROLL_ACTION = SUCCESSFUL_UNENROLL_ACTION;
  const FAILURE_UNENROLL_ACTION = 'FAILURE_UNENROLL_ACTION';
  _exports.FAILURE_UNENROLL_ACTION = FAILURE_UNENROLL_ACTION;
  const SUCCESSFUL_LOADING_ACTION = 'SUCCESSFUL_LOADING_ACTION';
  _exports.SUCCESSFUL_LOADING_ACTION = SUCCESSFUL_LOADING_ACTION;
  const FAILURE_LOADING_ACTION = 'FAILURE_LOADING_ACTION';
  _exports.FAILURE_LOADING_ACTION = FAILURE_LOADING_ACTION;
  const CANCEL_PRE_ENROLL_ACTION = 'CANCEL_PRE_ENROLL_ACTION';
  _exports.CANCEL_PRE_ENROLL_ACTION = CANCEL_PRE_ENROLL_ACTION;
});