define("adt-wss/pods/components/dashboard/crime-risk/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "55bBylr9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"crime-risk\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"crime-risk-container\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"crime-risk-body\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"crime-risk-left\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\",true],[10,\"class\",\"crime-risk-header\"],[8],[0,\"Interactive Crime Map from ADT\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\",true],[10,\"class\",\"crime-risk-content\"],[8],[0,\"\\n\\t\\t\\t\\t\\tTo help make sure you understand as much as possible about potential crime in your city or neighborhood, we've\\n\\t\\t\\t\\t\\tcreated this interactive crime map.\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"crime-risk-image\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/images/v2/logos/crime-map-graphic.jpg\"],[10,\"alt\",\"missing page icon\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"crime-risk-button\"],[8],[0,\"\\n\\t\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"button button-blue\",\"crime\"]],{\"statements\":[[0,\"View Crime Map\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/crime-risk/template.hbs"
    }
  });

  _exports.default = _default;
});