define("adt-wss/pods/dashboard/manage/route", ["exports", "adt-wss/pods/dashboard/manage/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentLocation: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    determinePermissions: _controller.determinePermissions,
    isCsr: Ember.computed.readOnly('authInfo.authInfo.csrUser'),
    businessReportingStatusChanged: Ember.observer('currentLocation.profileInfo.', function () {
      const status = this.get('currentLocation.profileInfo.businessReportingStatus');

      if (status) {
        this.checkPermissions();
      }
    }),

    model() {
      return {
        permissions: this.get('currentLocation.permissions')
      };
    },

    checkPermissions() {
      const permissions = this.get('currentLocation.permissions');
      const businessReportingStatus = this.get('currentLocation.profileInfo.businessReportingStatus');
      const isCsr = this.get('isCsr');

      if (!this.determinePermissions(this.routeName, permissions, businessReportingStatus, isCsr)) {
        this.transitionTo('dashboard.manage.index');
      }
    },

    beforeModel() {
      this.checkPermissions();
    }

  });

  _exports.default = _default;
});