define("adt-wss/pods/components/dashboard/shop/order-item-details/component", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss-common/helpers/currency", "adt-wss-common/utils/fp"], function (_exports, _emberHelpers, _currency, _fp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ALL_TYPES = _exports.DS_ORDER_TYPE = _exports.CK_ORDER_TYPE = _exports.BR_ORDER_TYPE = void 0;
  const BR_ORDER_TYPE = 'BR';
  _exports.BR_ORDER_TYPE = BR_ORDER_TYPE;
  const CK_ORDER_TYPE = 'CK';
  _exports.CK_ORDER_TYPE = CK_ORDER_TYPE;
  const DS_ORDER_TYPE = 'DS';
  _exports.DS_ORDER_TYPE = DS_ORDER_TYPE;
  const ALL_TYPES = 'all';
  _exports.ALL_TYPES = ALL_TYPES;
  const defaultProps = {
    item: {
      itemDetails: {}
    }
  };
  const details = [{
    label: 'Item type',
    allowedTypes: ALL_TYPES,
    computeValue: ({
      orderType,
      itemDetails
    }) => ({
      [BR_ORDER_TYPE]: itemDetails.batteryType,
      [CK_ORDER_TYPE]: 'DIY Contact Repair Kit',
      [DS_ORDER_TYPE]: 'Direct Ship'
    })[orderType]
  }, {
    label: 'Modal',
    allowedTypes: [BR_ORDER_TYPE],
    computeValue: ({
      itemType
    }) => itemType
  }, {
    label: 'ADT Quality Service Plan',
    allowedTypes: [BR_ORDER_TYPE],
    computeValue: ({
      itemDetails
    }) => (0, _currency.currency)([itemDetails.batteryPrice], {})
  }, {
    label: 'Quantity',
    allowedTypes: [CK_ORDER_TYPE],
    computeValue: ({
      quantity
    }) => quantity
  }];
  /**
   * @module Components/Dashboard/Shop/OrderItemDetails
   * @prop {Object} item
   * @prop {Function} toggleShippingExpanse
   * @prop {Function} toggleTotalExpanse
   * @prop {Function} togglePurchaseExpanse
   * @example
   * {@lang xml} {{ dashboard/shop/order-item-details
  		item=item
  		toggleShippingExpanse=(action 'toggleShippingExpanse')
  		toggleTotalExpanse=(action 'toggleShippingExpanse')
  		togglePurchaseExpanse=(action 'toggleShippingExpanse')
  }}
   */

  var _default = Ember.Component.extend(defaultProps, {
    detailsList: (0, _emberHelpers.computedApplyFunction)(item => details.filter(d => d.allowedTypes === ALL_TYPES || d.allowedTypes.some(t => t === item.orderType)).map(d => (0, _fp.omix)(d, {
      value: d.computeValue(item)
    })), 'item'),
    classNames: ['order-line-details', 'flex', 'flex-column'],
    DEFAULT_ITEM_PRICE: 0,
    itemPrice: Ember.computed.or('item.itemPrice', 'DEFAULT_ITEM_PRICE'),
    productInfoAreaLabel: (0, _emberHelpers.computedApplyFunction)(list => list.map(i => "".concat(i.label, ": ").concat(i.value)).join(', '), 'detailsList')
  });

  _exports.default = _default;
});