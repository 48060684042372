define("adt-wss/pods/components/dashboard/manage/users/user-list/component", ["exports", "adt-wss/classes/web-user", "adt-wss-common/mixins/with-fsm"], function (_exports, _webUser, _withFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.defaultProps = void 0;
  const demoUsers = [{
    id: 0,
    dateCreated: 1138197510,
    firstName: 'Jason',
    lastName: 'Cypret',
    userName: 'jcyprez@yahoo.com',
    title: 'Principal UX Consultant',
    department: 'marketing',
    lastLogin: null,
    moreInfo: 'Jason is a hipster sent from space here to collect your apples.',
    primaryPhone: null,
    secondaryPhone: null,
    pending: 1,
    admin: 1,
    createdByName: 'Shao Kahn'
  }, {
    id: 4,
    dateCreated: 12224324234,
    firstName: 'Michael',
    lastName: 'Munsie',
    userName: 'mike@mikemunsie.com',
    title: 'UX Consultant',
    department: 'sales',
    lastLogin: null,
    moreInfo: 'Munsie loves oreos.',
    primaryPhone: null,
    secondaryPhone: null,
    pending: 1,
    admin: 0,
    createdByName: 'Bruce Lee'
  }];
  const defaultProps = {
    users: demoUsers.map(u => _webUser.default.create(u))
  };
  /**
   * Displays a table of web users, indicating admin status,
   * and providing links to update and delete
   * @module Components/Dashboard/Manage/Users/UserList
   * @prop {Classes/WebUser[]} users
   * @prop {Function} updateUser action passed from parent
   * @prop {Function} deleteUser action passed from parent
   * @prop {Function} addAdmin
   * @prop {Function} removeAdmin
   */

  _exports.defaultProps = defaultProps;

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    classNames: ['web-users-list']
  });

  _exports.default = _default;
});