define("adt-wss/services/user-activity", ["exports", "ember-user-activity/services/user-activity"], function (_exports, _userActivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userActivity.default.extend({
    EVENT_THROTTLE: 30000 // 30s

  });

  _exports.default = _default;
});