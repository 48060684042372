define("adt-wss/pods/components/stupid/email-updater/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "043zxXLB",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"stupid/inline-errors-messages\",null,[[\"messages\"],[[24,[\"messages\"]]]]],false],[0,\"\\n\"],[4,\"loading-spinner\",[[24,[\"IS_UPDATING_STATE\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"email-updater-inner-wrap\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"email-actions-wrap\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"email-updater-label\"],[8],[1,[22,\"label\"],false],[0,\":\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"strong\",true],[8],[1,[22,\"email\"],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"edit-email-btn\"],[3,\"action\",[[23,0,[]],\"dispatch\",[28,\"unless\",[[24,[\"IS_EDIT_STATE\"]],\"SET_EDIT_MODE_ACTION\",\"CANCEL_EDIT_MODE_ACTION\"],null]]],[8],[0,\"\\n\\t\\t\\t\\tEdit\\n\\t\\t\\t\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"allowDelete\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"delete-email-btn\"],[3,\"action\",[[23,0,[]],\"dispatch\",\"DELETE_DATA_ACTION\"]],[8],[0,\"\\n\\t\\t\\t\\t\\tDelete\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/stupid/email-updater/template.hbs"
    }
  });

  _exports.default = _default;
});