define("adt-wss/pods/components/widget/system-test-wizard-note/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Components/Widget/SystemTestWizardNote
   * @prop {String} text
   * @example
   * {@lang xml} {{ widget/system-test-wizard-note
  		text="Description..."
  }}
   */
  var _default = Ember.Component.extend({
    classNames: ['system-test-wizard-note'],
    tagName: 'section'
  });

  _exports.default = _default;
});