define("adt-wss/pods/components/dashboard/account/products-offers-notifications/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/common-fsm-state", "adt-wss/pods/components/dashboard/account/communication-preferences/constants"], function (_exports, _withFsm, _emberHelpers, _myadtHelpers, _commonFsmState, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fsmModel = {
    transitions: {
      [_commonFsmState.IDLE_STATE]: {
        [_commonFsmState.UPDATE_DATA_ACTION]: _commonFsmState.UPDATING_STATE
      },
      [_commonFsmState.UPDATING_STATE]: {
        [_commonFsmState.FAILURE_ACTION]: _commonFsmState.IDLE_STATE,
        [_commonFsmState.SUCCESS_ACTION]: _commonFsmState.IDLE_STATE
      }
    }
  };
  /**
   * @module Components/Dashboard/Account/ProductsOffersNotifications
   * @prop {String} primaryPhone
   * @prop {Function} updateBillingReminders
   * @prop {Boolean} consent
   * @example
   * {@lang xml} {{ dashboard/account/products-offers-notifications
  		primaryPhone=phoneConsents.phone1
  		updateProductsAndOffersConsents=updateProductsAndOffersConsents
  		consent=phoneConsents.phone1PrdAndOffrTextConsent
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    fsmModel,
    mwd: Ember.inject.service('modal-window-dispatcher'),
    authInfo: Ember.inject.service(),
    tagName: '',
    IS_UPDATING_STATE: (0, _emberHelpers.isState)(_commonFsmState.UPDATING_STATE),

    /**
     * Sends a request for updating of text updates for products and offers.
     * Calls `auth/pmoc/updateProductsAndOffersConsents` endpoint
     *
     * @function
     * @returns {Promise}
     */
    [_commonFsmState.UPDATE_DATA_ACTION]() {
      this.mwd.clearMessages();
      return this.commonDispatcher(() => this.updateProductsAndOffersConsents(this.data));
    },

    /**
     * Sends GA event of the `Preferences` category
     *
     * @function
     */
    sendAnalyticEvent(optIn) {
      const type = optIn ? 'in' : 'out';
      const action = 'Opt '.concat(type);
      const label = 'Products and Offers Opt '.concat(type);
      const csrUser = this.get('authInfo.authInfo.csrUser');
      const category = (csrUser ? 'CSR ' : '').concat('Preferences');
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', category, action, label]);
    },

    /**
     * Handler for closing the modal window
     * Clears messages
     *
     * @function
     */
    closeModalHandler() {
      this.mwd.clearMessages();
      this.mwd.hide();
    },

    /**
     * Creates `data` property for further editing
     *
     * @function
     */
    prepareData() {
      this.set('data', {
        consent: this.consent,
        phone1: this.primaryPhone
      });
    },

    /**
     * Handler for successful updating.
     * Calls `closeWindowHandler` method in order to close the modal window
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     */
    [_commonFsmState.SUCCESS_ACTION]() {
      this.sendAnalyticEvent(this.data.consent);
      this.closeModalHandler();
    },

    /**
     * Handler for failed updating.
     * Calls `mwd.showError` method in order to show a success message
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     * @param {Classes/AjaxResponse} results - object with errors from BE response
     */
    [_commonFsmState.FAILURE_ACTION]({
      errors
    }) {
      this.mwd.showError(errors[0]);
    },

    actions: {
      openModalWindow() {
        const onEnter = () => this.fsm.dispatch(_commonFsmState.UPDATE_DATA_ACTION);

        const onClose = () => this.closeModalHandler();

        this.prepareData();
        this.mwd.show({
          title: 'Products and Offers',
          name: 'preferences/products-and-offers',
          body: this,
          onClose,
          onEnter,
          bigCallback: true,
          disclaimer: _constants.DISCLAIMER_MESSAGE,
          actions: [{
            caption: 'Cancel',
            stl: 'outline',
            callback: onClose
          }, {
            caption: 'Save',
            name: 'save',
            disabled: (0, _emberHelpers.eq)('consent', 'data.consent'),
            callback: onEnter
          }]
        });
      }

    }
  });

  _exports.default = _default;
});