define("adt-wss/pods/components/dashboard/account/inspection-reports/component", ["exports", "adt-wss-common/utils/common-fsm-state", "adt-wss/pods/components/dashboard/account/contract-documents/component"], function (_exports, _commonFsmState, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    myadtSecurity: Ember.inject.service(),

    [_commonFsmState.FETCH_DATA_ACTION]() {
      const {
        accountData,
        fsm
      } = this.getProperties('accountData', 'fsm');

      if (!accountData.customerDocList) {
        return accountData.getAccountDocumentsByType('inspections').then(data => fsm.dispatch(_commonFsmState.SUCCESS_ACTION, data)).catch(results => fsm.dispatch(_commonFsmState.FAILURE_ACTION, results));
      }

      return fsm.dispatch(_commonFsmState.SUCCESS_ACTION, accountData.inspections);
    },

    [_commonFsmState.SUCCESS_ACTION](contracts) {
      this.set('documents', contracts);
    }

  });

  _exports.default = _default;
});