define("adt-wss/pods/dashboard/alarm/systemtest/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+GwqpCsO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"dashboard-system-test\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"widgets-row\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"dashboard-alarm-system-info-wrapper\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"dashboard/alarm/system-test\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"widgets-row bottom-widgets-row\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/alarm/systemtest/template.hbs"
    }
  });

  _exports.default = _default;
});