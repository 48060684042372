define("adt-wss/pods/components/dashboard/side-bar/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers", "adt-wss/pods/dashboard/manage/controller", "adt-wss/pods/dashboard/shop/productsservices/contactrepairkit/route"], function (_exports, _withFsm, _emberHelpers, _controller, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.routes = _exports.logout = _exports.crime = _exports.support = _exports.order = _exports.locations = _exports.alarm = _exports.account = _exports.dashboard = void 0;
  const dashboard = {
    title: 'Dashboard',
    tooltipTitle: 'Dashboard Includes:',
    path: 'dashboard.index',
    icon: 'dashboard',
    cyberOnly: true,
    adtGoOnly: true,
    hasLinks: false,
    collapsed: true,
    detectPermissions: () => true,
    includes: [{
      path: 'dashboard.index',
      name: 'Account Overview'
    }, {
      path: 'dashboard.index',
      name: 'Safety Tips'
    }, {
      path: 'dashboard.index',
      name: 'Quick Support'
    }]
  };
  _exports.dashboard = dashboard;
  const account = {
    title: 'Account',
    tooltipTitle: 'Account Includes:',
    path: 'dashboard.account',
    icon: 'account',
    cyberOnly: true,
    adtGoOnly: true,
    hasLinks: true,
    collapsed: true,
    detectPermissions: () => true,
    includes: [{
      path: 'dashboard.account.payments',
      name: 'Payments',
      hideIfNotBilling: true
    }, {
      path: 'dashboard.account.profile',
      name: 'Manage Profile'
    }, {
      path: 'dashboard.account.security',
      name: 'Account Security',
      hide: true
    }, {
      path: 'dashboard.account.preferences',
      name: 'Communication Preferences'
    }, {
      path: 'dashboard.account.contacts',
      name: 'Manage Emergency Contacts',
      hideIfNotContacts: true,
      hideIfNoPic: true,
      hideIfAdtGoOnly: true,
      hideIfCyberOnly: true
    }, {
      path: 'dashboard.account.documents',
      name: 'Account Documents'
    }]
  };
  _exports.account = account;
  const alarm = {
    title: 'Alarm System',
    tooltipTitle: 'Alarm System Includes:',
    path: 'dashboard.alarm',
    icon: 'alarm',
    cyberOnly: false,
    adtGoOnly: false,
    hasLinks: true,
    collapsed: true,
    detectPermissions: () => true,
    includes: [{
      path: 'dashboard.alarm.system',
      name: 'System Overview',
      hideIfNoPic: true
    }, {
      path: 'dashboard.alarm.trobleshootdevices',
      name: 'Troubleshoot Devices',
      hide: true
    }, {
      path: 'dashboard.alarm.systemtest',
      name: 'System Test',
      hideIfNoPic: true
    }, {
      path: 'dashboard.alarm.history',
      name: 'Alarm History',
      hideIfNoPic: true
    }, {
      path: 'dashboard.alarm.appointments',
      name: 'Service Appointments'
    }]
  };
  _exports.alarm = alarm;
  const locations = {
    title: 'Locations',
    tooltipTitle: 'Locations Includes:',
    path: 'dashboard.manage',
    icon: 'locations',
    cyberOnly: true,
    adtGoOnly: true,
    hasLinks: true,
    collapsed: true,
    detectPermissions: () => true,
    includes: [{
      path: 'dashboard.manage.index',
      name: 'Switch Location'
    }, {
      path: 'dashboard.manage.users',
      name: 'Manage Users',
      hideIfAdtGoOnly: true,
      hideIfCyberOnly: true,

      determineVisibility({
        permissions,
        businessReportingStatus,
        isCsr
      }) {
        return (0, _controller.determinePermissions)(this.path, permissions, businessReportingStatus, isCsr);
      }

    }, {
      path: 'dashboard.manage.businessreporting',
      name: 'Business reporting',

      determineVisibility({
        permissions,
        businessReportingStatus,
        isCsr
      }) {
        return (0, _controller.determinePermissions)(this.path, permissions, businessReportingStatus, isCsr);
      }

    }]
  };
  _exports.locations = locations;
  const order = {
    title: 'Order',
    tooltipTitle: 'Orders Includes:',
    path: 'dashboard.shop',
    icon: 'order',
    cyberOnly: false,
    adtGoOnly: false,
    hasLinks: false,
    collapsed: true,
    detectPermissions: ({
      orderEquipment
    }) => orderEquipment,
    includes: [{
      path: 'dashboard.index',
      name: 'Order Yard Signs, decals and batteries',
      hideIfNoPic: true,
      determineVisibility: ({
        adtGoOrCyberSecurity
      }) => !(0, _route.detectPermissions)(adtGoOrCyberSecurity)
    }, {
      path: 'dashboard.index',
      name: 'Order Yard Signs, decals, batteries and DIY contact repair kits',
      hideIfNoPic: true,
      determineVisibility: ({
        adtGoOrCyberSecurity
      }) => (0, _route.detectPermissions)(adtGoOrCyberSecurity)
    }]
  };
  _exports.order = order;
  const support = {
    title: 'Help',
    tooltipTitle: 'ADT Support Center',
    path: 'help',
    icon: 'support',
    cyberOnly: true,
    adtGoOnly: true,
    hasLinks: false,
    collapsed: true,
    detectPermissions: () => true,
    includes: [],
    supportCenter: true
  };
  _exports.support = support;
  const crime = {
    title: 'Crime Maps',
    path: 'crime',
    icon: 'crime',
    cyberOnly: true,
    adtGoOnly: true,
    hasLinks: false,
    collapsed: false,
    detectPermissions: () => true,
    includes: []
  };
  _exports.crime = crime;
  const logout = {
    title: 'Logout',
    tooltipTitle: 'Logout',
    path: 'index',
    cyberOnly: true,
    adtGoOnly: true,
    hasLinks: false,
    collapsed: true,
    showMobile: true,
    actionName: 'logout',
    detectPermissions: () => true,
    includes: []
  };
  _exports.logout = logout;
  const routes = [dashboard, account, alarm, locations, order, crime, support, logout].map(el => Ember.Object.create(el));
  /**
   * Provides navigation for authorized user.
   * @module Components/Dashboard/SideBar
   * @property {Boolean} opened - Indicator of openness
   * @property {Object[]} routes - Builds sidebar navigation
   * @property {String} routes.title - Label of sidebar link
   * @property {String} routes.icon - Class name for icon placed on sidebar link
   * @property {String} routes.tooltipTitle - Header of tooltip hint
   * @property {String} routes.path - Path for some route for {{link-to}} helper
   icon: '',
   * @property {String[]} routes.includes - List of pages described in tolltip hint window
   */

  _exports.routes = routes;

  var _default = Ember.Component.extend(_withFsm.default, {
    tagName: 'div',
    elementId: 'sidebar-wrapper',
    opened: false,
    router: Ember.inject.service(),
    media: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    myadtSecurity: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    isCsr: Ember.computed.readOnly('authInfo.authInfo.csrUser'),
    businessReportingStatus: Ember.computed.readOnly('currentLocation.profileInfo.businessReportingStatus'),
    permissions: Ember.computed.oneWay('currentLocation.permissions'),
    cyberOnly: Ember.computed.oneWay('currentLocation.cyberOnly'),
    adtGoOnly: Ember.computed.oneWay('currentLocation.adtGoOnly'),
    picProvided: Ember.computed.oneWay('myadtSecurity.picProvided'),
    classNameBindings: ['opened'],
    routes: (0, _emberHelpers.computedApplyFunction)(function (picProvided, permissions, cyberOnly, adtGoOnly, currentRouteName, businessReportingStatus, isCsr, adtGoOrCyberSecurity) {
      return routes.map(route => {
        const isActive = this.calcIsContainRoutePath(route);
        const newRoute = Ember.Object.create(route);
        newRoute.set('collapsed', !isActive);
        newRoute.set('includes', route.includes.filter(l => {
          let out = true;
          out = !(l.hideIfNotBilling && !permissions.billing);

          if (out) {
            out = !(l.hideIfNotContacts && permissions.hideContacts);
          }

          if (out) {
            out = !(l.hideIfNoPic && !picProvided);
          }

          if (out) {
            out = !(l.hideIfAdtGoOnly && adtGoOnly);
          }

          if (out) {
            out = !(l.hideIfCyberOnly && cyberOnly);
          }

          if (l.determineVisibility) {
            return l.determineVisibility({
              permissions,
              businessReportingStatus,
              isCsr,
              adtGoOrCyberSecurity
            });
          }

          return out;
        }));
        newRoute.set('noSystemsHide', () => {
          if (cyberOnly || adtGoOnly) {
            if (cyberOnly && !route.cyberOnly) return true;
            if (adtGoOnly && !route.adtGoOnly) return true;
          }

          return false;
        });
        return newRoute;
      });
    }, 'picProvided', 'permissions', 'cyberOnly', 'adtGoOnly', 'router.currentRouteName', 'businessReportingStatus', 'isCsr', 'currentLocation.adtGoOrCyberSecurity'),
    routeNameChanged: Ember.observer('router.currentRouteName', function () {
      if (this.get('media.isNotMobile') || !this.get('opened')) return false;
      return this.close();
    }),

    calcIsContainRoutePath(route) {
      const currentRoutePath = this.get('router.currentRouteName');
      const crpa = (currentRoutePath || '').split('.');
      const rpa = route.path.split('.'); // dashboard(0).account(1).payment === dashboard(0).account(1)

      return crpa[0] === rpa[0] && crpa[1] === rpa[1];
    },

    actions: {
      toggle(route) {
        route.toggleProperty('collapsed');
      },

      logout() {
        this.getMethod('logout')();
      },

      stopPropagationFunc() {},

      // Mock function to stop bubbling an event to close the sidebar
      supportCenterRedirectFunc() {
        window.open("https://help.adt.com/s/", '_blank' // Opens the link in a new window
        );
      }

    }
  });

  _exports.default = _default;
});