define("adt-wss/pods/components/dashboard/shop/order-history/component", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/fp", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/common-fsm-state", "adt-wss/pods/components/dashboard/shop/order-item-details/component"], function (_exports, _emberHelpers, _withFsm, _fp, _appDispatcher, _commonFsmState, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.filterResultsBySearch = _exports.defaultProperties = void 0;
  const defaultProperties = {
    orderList: []
  };
  _exports.defaultProperties = defaultProperties;
  const PROCESSING_STATUS = 'processing';
  /**
   * Constrict list of events by filter by searchText
   * @param orderList - API response with all order history items
   * @param searchText - value of search field
   * @returns {*}
   */

  const filterResultsBySearch = (orderList, searchText) => (orderList || []).filter(row => {
    let searchResult;

    try {
      searchResult = (row.itemType + row.jobID + row.status + row.totalPriceAfterTax + row.shippingInformation.locationName + row.shippingInformation.addressLine1 + row.shippingInformation.addressLine2 + row.shippingInformation.primaryPhoneNumber + (row.itemDetails.batteryType || '')).toUpperCase().indexOf(searchText.toUpperCase()) > -1;
    } catch (e) {
      console.error('filterResultsBySearch', e);
    }

    return searchResult;
  });
  /**
   * @module Components/Dashboard/Shop/OrderHistory
   * @prop {String} elementId - ID attribute for div component wrapper
   * @prop {Array} orderList - user orders
   * @example
   * {@lang xml} {{ dashboard/shop/order-history
  		orderList=null
  }}
   */


  _exports.filterResultsBySearch = filterResultsBySearch;

  var _default = Ember.Component.extend(_withFsm.default, defaultProperties, _commonFsmState.commonMixin, {
    orderData: Ember.inject.service(),
    locationsIsOpen: false,
    searchText: '',
    filteredResults: (0, _emberHelpers.computedApplyFunction)(filterResultsBySearch, 'orderList', 'searchText'),
    batteryDescriptionVisible: (0, _emberHelpers.computedApplyFunction)(list => (list || []).some(i => i.orderType === _component.BR_ORDER_TYPE && i.status === PROCESSING_STATUS), 'orderList'),
    elementId: 'dashboardOrderHistory',

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    changeLocationHandler() {
      this.get('fsm').dispatch(_commonFsmState.FETCH_DATA_ACTION, true);
    },

    [_commonFsmState.SUCCESS_ACTION](orderList) {
      const list = orderList.map(el => (0, _fp.omix)({
        date: Date.createDate(el.orderDate).toAltString(),
        orderIsExpanded: false,
        purchaseExpanded: false,
        totalExpanded: false,
        shippingExpanded: false
      }, el));
      this.set('orderList', list);
    },

    [_commonFsmState.FETCH_DATA_ACTION](forceRequest) {
      return this.deliverDataAndDispatch('orderData.orderHistory', orderData => orderData.getOrderHistory(), _commonFsmState.SUCCESS_ACTION, _commonFsmState.FAILURE_ACTION, false, false, {
        forceRequest
      });
    }

  });

  _exports.default = _default;
});