define("adt-wss/pods/components/dashboard/flexfi-identifier/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VpS/Nc3B",
    "block": "{\"symbols\":[\"plan\",\"index\"],\"statements\":[[4,\"each\",[[24,[\"installmentData\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"flexfi-line-entry plan-\",[23,1,[\"noteNo\"]]]]],[8],[0,\"\\n\\t\\tFlexFi \"],[1,[28,\"if\",[[28,\"gt\",[[24,[\"installmentData\",\"length\"]],1],null],[28,\"plus-one\",[[23,2,[]]],null]],null],false],[0,\" installment: \"],[1,[28,\"currency\",[[23,1,[\"installmentAmt\"]]],null],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/flexfi-identifier/template.hbs"
    }
  });

  _exports.default = _default;
});