define("adt-wss/pods/components/not-found/component", ["exports", "adt-wss-common/utils/myadt-helpers"], function (_exports, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const links = [{
    title: 'Dashboard',
    url: 'dashboard.index'
  }, {
    title: 'Locations & Users',
    url: 'dashboard.manage'
  }, {
    title: 'Account',
    url: 'dashboard.account'
  }, {
    title: 'Order',
    url: 'dashboard.shop'
  }, {
    title: 'Alarm System',
    url: 'dashboard.alarm'
  }, {
    title: 'Help',
    url: 'help.index'
  }];

  var _default = Ember.Component.extend({
    links,

    init() {
      this._super(...arguments);

      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', '404', '/userProfile.jsp']);
    }

  });

  _exports.default = _default;
});