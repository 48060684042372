define("adt-wss/pods/components/dashboard/whats-new/component", ["exports", "adt-wss-common/mixins/with-fsm"], function (_exports, _withFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.whatsNewLink = _exports.whatsNewImage = void 0;
  const whatsNewImage = 'https://alpha.adt.com/content/dam/MyADTAssets/images/whats-new.jpg';
  _exports.whatsNewImage = whatsNewImage;
  const whatsNewLink = 'https://www.adt.com/products';
  /**
   * Renders news
   * @module  Components/Dashboard/WhatsNew
   * @property {String} whatsNewLink - link to products
   * @property {String} whatsNewImage - image of product
   */

  _exports.whatsNewLink = whatsNewLink;

  var _default = Ember.Component.extend(_withFsm.default, {
    classNames: ['whats-new'],
    whatsNewImage,
    whatsNewLink
  });

  _exports.default = _default;
});