define("adt-wss/pods/components/dashboard/manage/reports-list/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/fp", "adt-wss-common/utils/common-fsm-state", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/helpers/number-ordinal", "adt-wss-common/utils/ember-helpers", "adt-wss/services/app-dispatcher", "adt-wss/pods/components/dashboard/manage/add-report/schematics"], function (_exports, _withFsm, _fp, _commonFsmState, _myadtHelpers, _numberOrdinal, _emberHelpers, _appDispatcher, _schematics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    eventTypes,
    frequencies
  } = _schematics.reportAdjustmentFormSchema;
  const scheduleTable = (0, _fp.omix)({
    365: {
      label: 'Annually'
    }
  }, frequencies);
  const defaultProps = {
    /**
     * Notify status of operation
     * @prop {String} message
     * @prop {String} type
     * @return {void}
     */

    /* eslint-disable no-unused-vars */
    notify: (message, type) => console.error('Please pass the "notify" param to "dashboard/manage/reports-list" component')
    /* eslint-enable no-unused-vars */

  };
  /**
   * @module Components/Dashboard/Manage/ReportsList
   * @prop {Function} notify - Method which is used for showing status messages.
   * @prop {Function} numberOrdinal import from helper
   * @prop {String} searchQuery
   * @prop {Location[]} locations
   * @prop {FullActivityReport[]} reports
   * @prop {FullActivityReport[]} filteredReports filtered by `searchQuery`
   * @example
   * {@lang xml} {{dashboard/manage/reports-list
  		notify=(action 'setMessages')
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, _commonFsmState.commonMixin, defaultProps, {
    alarmData: Ember.inject.service(),
    manageAccountService: Ember.inject.service(),
    tagName: 'section',
    classNames: ['business-scheduled-reports-list'],
    searchQuery: null,
    reports: null,
    locations: null,
    numberOrdinal: _numberOrdinal.numberOrdinal,
    IS_LOADING_STATE: (0, _emberHelpers.isState)(_commonFsmState.LOADING_STATE),
    filteredReports: (0, _emberHelpers.computedApplyFunction)((reports, searchQuery = '') => searchQuery ? reports.filter(i => i.entireDataSet.includes(searchQuery.toLowerCase())) : reports, 'reports', 'searchQuery'),

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    /**
     * Adds addition `view` property for the report.
     * This additional property contains transformed data from the BE response.
     * Used for showing and for `report.entireDataSet` prop's formation.
     * Triggered on `processActivityReportsResponse`
     *
     * @instance
     * @param {ActivityReport} report no `id`
     * @return {Object}
     */
    addViewData(report) {
      const {
        name,
        email,
        eventTypes: evTypes,
        days: d,
        sendEmailOnDays
      } = report;
      return (0, _fp.omix)(report, {
        view: {
          name,
          email,
          eventTypes: evTypes.map(t => (eventTypes.find(e => e.value === t) || {
            label: t
          }).label),
          schedule: !Object.keys(scheduleTable).includes(d) ? d : scheduleTable[d].label,
          day: (() => {
            const separator = ', ';

            if (sendEmailOnDays.every(day => /^\d+$/.test(day))) {
              return sendEmailOnDays.map(day => this.numberOrdinal([day])).join(separator);
            }

            return sendEmailOnDays.map(day => Ember.String.capitalize(day.slice(0, -1))).join(separator);
          })()
        }
      });
    },

    /**
     * Adds additional `entireDataSet` property for each report.
     * This property is needed for searching
     * Triggered on `SUCCESS_ACTION`
     *
     * @instance
     * @param {ActivityReport[]} reports
     * @return {FullActivityReport[]}
     */
    processActivityReportsResponse(reports) {
      return reports.map(r => this.addViewData(r)).map(r => (0, _fp.omix)(r, {
        entireDataSet: Object.values(r.view).join('').replace(/,/g, '').toLowerCase()
      }));
    },

    /**
     * Gets sheculed reports list and locations
     * Retrieves '/api/v2/auth/history/report/email/all'
     * Retrieves '/api/v2/auth/manageAccount/list'
     * Triggered as initial action
     *
     * @function
     * @param {Boolean} forceRequest - option for cache skipping
     * @returns {Promise}
     */
    [_commonFsmState.FETCH_DATA_ACTION]({
      forceRequest,
      fetchReports
    } = {
      forceRequest: false,
      fetchReports: false
    }) {
      const fsm = this.get('fsm');
      const deliverOptions = {
        forceRequest
      };

      if (fetchReports) {
        return this.deliverData('alarmData.reportEmailSettings', s => s.getActivityReports()).then(result => fsm.dispatch(_commonFsmState.SUCCESS_ACTION, [result, this.get('locations')])).catch(e => fsm.dispatch(_commonFsmState.FAILURE_ACTION, e));
      }

      return Promise.all([this.deliverData('alarmData.reportEmailSettings', s => s.getActivityReports(), deliverOptions), this.deliverData('manageAccountService.locations', s => s.getLocations(), deliverOptions)]).then(results => fsm.dispatch(_commonFsmState.SUCCESS_ACTION, results)).catch(e => fsm.dispatch(_commonFsmState.FAILURE_ACTION, e));
    },

    /**
     * Updates `reports` and `locations` properties
     * Triggered on `FETCH_DATA_ACTION`
     *
     * @function
     * @param {Object[]} [reports, locations]
     * @param {ActivityReport[]} reports
     * @param {Location[]} locations
     */
    [_commonFsmState.SUCCESS_ACTION]([reports = [], locations] = []) {
      this.set('reports', this.processActivityReportsResponse(reports));
      this.set('locations', locations);
    },

    /**
     * Handles failed loading
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @prop {Classes/AjaxResponse} results
     * @function
     */
    [_commonFsmState.FAILURE_ACTION](results) {
      const [error] = (0, _myadtHelpers.genericErrorHandler)(results);
      this.notify(error, 'error');
    },

    actions: {
      /**
       * Updates report by using `alarmData.updateActivityReport` method.
       * Additional props (`view` and `entireDataSet`) are removed before sending the request.
       * Dispatches `FETCH_DATA_ACTION` action if promise is resolved
       *
       * Calls '/api/v2/auth/history/report/email/update' endpoint
       *
       * @function
       * @param {FullActivityReport} data - report
       * @returns {Promise}
       */
      updateReport(data = {}) {
        const {
          fsm,
          alarmData
        } = this.getProperties('fsm', 'alarmData');
        return alarmData.updateActivityReport((0, _fp.omit)(data, ['view', 'entireDataSet'])).then(() => {
          this.notify({
            text: 'Please add noreply@adt.com to your address book to prevent these messages from being filtered into your spam or junk email folders.',
            head: "Your report \"".concat(data.name, "\" has been successfully updated.")
          }, 'success');
        }).then(() => fsm.dispatch(_commonFsmState.FETCH_DATA_ACTION, {
          fetchReports: true
        }));
      },

      /**
       * Removes report by using `alarmData.removeActivityReport` method.
       * Dispatches `FETCH_DATA_ACTION` action if promise is resolved
       *
       * Calls '/api/v2/auth/history/report/email/delete/:id' endpoint
       *
       * @function
       * @param {{ id: Number, name: String }} - Report's id and name
       * @returns {Promise}
       */
      deleteReport({
        id,
        name
      }) {
        const {
          fsm,
          alarmData
        } = this.getProperties('fsm', 'alarmData');
        return alarmData.removeActivityReport({
          id
        }).then(() => {
          this.notify({
            text: 'New invoice may take one billing cycle to appear.',
            head: "Report \"".concat(name, "\" has been successfully deleted.")
          }, 'success');
        }).then(() => fsm.dispatch(_commonFsmState.FETCH_DATA_ACTION, {
          fetchReports: true
        }));
      },

      /**
       * Creates report by using `alarmData.saveActivityReport` method.
       * Dispatches `FETCH_DATA_ACTION` action if promise is resolved
       *
       * Calls 'api/v2/auth/history/report/email/add' endpoint
       *
       * @function
       * @param {ActivityReport} data
       * @returns {Promise}
       */
      createReport(data = {}) {
        const {
          fsm,
          alarmData
        } = this.getProperties('fsm', 'alarmData');
        return alarmData.saveActivityReport(data).then(() => {
          this.notify({
            text: 'Please add noreply@adt.com to your address book to prevent these messages from being filtered into your spam or junk email folders.',
            head: "Your report \"".concat(data.name, "\" has been successfully scheduled.")
          }, 'success');
        }).then(() => fsm.dispatch(_commonFsmState.FETCH_DATA_ACTION, {
          fetchReports: true
        }));
      }

    }
  });

  _exports.default = _default;
});