define("adt-wss/pods/components/dashboard/account/security-question/component", ["exports", "ember-copy", "adt-wss-common/mixins/with-fsm", "adt-wss/mixins/on-form-esc-handler", "adt-wss/pods/components/myadt-login/register/component", "adt-wss-common/classes/status-message", "adt-wss-common/classes/validator", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/fp", "adt-wss/pods/components/dashboard/account/security-question/constants"], function (_exports, _emberCopy, _withFsm, _onFormEscHandler, _component, _statusMessage, _validator, _emberHelpers, _fp, constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.defaultProps = void 0;
  const validator = new _validator.default({
    answers: {
      isArray: true,
      label: index => "Answer ".concat(index),
      rules: ['isNotEmpty']
    }
  }, {
    messageCustomizer: (value, label) => "".concat(label, ": ").concat(value)
  });
  const {
    IDLE_STATE,
    EDIT_STATE,
    UPDATING_STATE,
    SET_EDIT_MODE_ACTION,
    CANCEL_EDIT_MODE_ACTION,
    UPDATE_DATA_ACTION,
    RESOLVE_ACTION,
    REJECT_ACTION,
    SUCCESSFULLY_UPDATED_MESSAGE,
    GENERIC_ERROR_MESSAGE
  } = constants;
  const fsmModel = {
    scrollToTop: false,
    transitions: {
      [IDLE_STATE]: {
        [SET_EDIT_MODE_ACTION]: EDIT_STATE
      },
      [EDIT_STATE]: {
        [CANCEL_EDIT_MODE_ACTION]: IDLE_STATE,
        [UPDATE_DATA_ACTION]: UPDATING_STATE
      },
      [UPDATING_STATE]: {
        [REJECT_ACTION]: EDIT_STATE,
        [RESOLVE_ACTION]: IDLE_STATE
      }
    }
  };
  const defaultProps = {
    questionsList: _component.questions,
    questions: [],
    answers: [],
    alert: v => v,
    setOverlap: () => {},
    submitEdit: () => {
      console.error('Please pass submitEdit method to dashboard/account/security-question component');
    }
  };
  /**
   * @module Components/Dashboard/Account/SecurityQuestion
   * @prop {String} elementId - ID attribute for div component wrapper
   * @prop {String} style - HTML Atribute
   * @prop {Object} questionsList - List of questions
   * @prop {String[]} questions - List of users' questions
   * @prop {String[]} answers - List of users' answers
   * @prop {Function} submitEdit - Function which save changes
   * @prop {Function} alert - Function which called after every update attempt
   * @prop {Object} data - Data which used in edit mode and will be sent on the request
   * @prop {Function} setOverlap - Set value for visibility background overlap
   * @example
   * {@lang xml} {{ dashboard/account/security-question
  		elementId='billingAddress'
  		style='"width: 600px"'
  		questions=['20']
  		answers=['test']
  		submitEdit=(action 'submit')
  		alert=(action 'setMessages')
  		setOverlap=(action 'setOverlap')
  }}
   */

  _exports.defaultProps = defaultProps;

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, _onFormEscHandler.default, {
    validator,
    fsmModel,
    ssoInfo: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    isSsoEnabled: Ember.computed.readOnly('ssoInfo.isSsoEnabled'),
    securityQuestion: (0, _emberHelpers.computedApplyFunction)((id, questions) => questions.filter(el => el.value == id[0]).map(el1 => el1.text)[0], 'questions', 'questionsList'),
    classNames: ['security-question', 'account-widget-section'],
    classNameBindings: ['IS_EDIT_STATE:active'],
    tagName: 'section',
    errors: null,
    currentState: IDLE_STATE,
    activeESCState: EDIT_STATE,
    cancelESCState: CANCEL_EDIT_MODE_ACTION,
    IS_EDIT_STATE: Ember.computed.equal('currentState', EDIT_STATE),
    IS_UPDATING_STATE: Ember.computed.equal('currentState', UPDATING_STATE),
    IS_IDLE_STATE: Ember.computed.equal('currentState', IDLE_STATE),
    isEditStateChanged: Ember.observer('IS_EDIT_STATE', function () {
      this.setOverlap(this.get('IS_EDIT_STATE'));
    }),

    init() {
      this._super(...arguments);

      this.get('validator').onValidate(({
        messages,
        values
      }) => {
        this.set('errors', messages);
        this.set('messages', values);
      });
      this.keyDown = this.onEscHandler.bind(this);
    },

    prepareEdit() {
      const props = this.getProperties(['answers', 'questions']);
      const {
        questions
      } = props;
      const listOfQuestions = this.get('questionsList').map(el => el.text);
      const isQuestionPresentInList = questions.filter(el => listOfQuestions.includes(el));
      this.set('data', (0, _emberCopy.copy)((0, _fp.omix)(props, {
        questions: questions.length && isQuestionPresentInList.length ? questions : [listOfQuestions[1]]
      }), true));
    },

    resetAlert() {
      this.set('errors', null);
      this.set('messages', null);
      this.alert(null);
    },

    [SET_EDIT_MODE_ACTION]() {
      if (this.get('isSsoEnabled')) {
        this.get('ssoInfo').getSsoSecurityQuestions().then(securityQuestions => {
          this.set('questionsList', securityQuestions);
        });
      }

      this.resetAlert();
      this.prepareEdit();
    },

    [CANCEL_EDIT_MODE_ACTION]() {
      this.resetAlert();
    },

    [UPDATE_DATA_ACTION](data) {
      const {
        validator: v,
        fsm
      } = this.getProperties('validator', 'fsm');
      const validationFailed = v.validate(data);

      if (validationFailed) {
        return Ember.RSVP.Promise.resolve(fsm.dispatch(REJECT_ACTION));
      }

      return this.submitEdit(data).then(() => fsm.dispatch(RESOLVE_ACTION)).catch(r => fsm.dispatch(REJECT_ACTION, r));
    },

    [RESOLVE_ACTION]() {
      this.alert([new _statusMessage.default(SUCCESSFULLY_UPDATED_MESSAGE, 'success', true)]);
    },

    [REJECT_ACTION](results) {
      if (!results) {
        return;
      }

      const {
        errors = [GENERIC_ERROR_MESSAGE]
      } = results || {};
      return this.alert(errors.map(e => new _statusMessage.default(e, 'error')));
    },

    actions: {
      dispatch(...args) {
        this.get('fsm').dispatch(...args);
      },

      selectQuestion(index = 0, question) {
        const valueToSet = this.get('isSsoEnabled') ? this.get('questionsList')[question].text : question;
        Ember.set(this.get('data.questions'), index, valueToSet);
      }

    }
  });

  _exports.default = _default;
});