define("adt-wss/pods/ps/easypay/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BVXz3jX2",
    "block": "{\"symbols\":[],\"statements\":[[5,\"ps/easy-pay\",[],[[\"@isEnrolled\",\"@isCC\",\"@isEdit\",\"@uID\"],[[22,\"isEnrolled\"],[22,\"isCC\"],[22,\"isEdit\"],[22,\"uID\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/ps/easypay/template.hbs"
    }
  });

  _exports.default = _default;
});