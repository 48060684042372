define("adt-wss/templates/modal-windows/system-test-wizard/final", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A0ONuYog",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"test-system-wizard-info-modal\"],[10,\"role\",\"region\"],[10,\"aria-label\",\"We're Here to Help\\n\\tIf missing a device or no signal was received, please call use at 1-800-238-2727 so that we can get your devices set up properly.\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"test-wizard-info-text system-test-final-info\"],[8],[0,\"\\n\\t\\t\"],[7,\"h4\",true],[10,\"class\",\"test-wizard-info-title\"],[8],[0,\"We're Here to Help\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"\\n\\t\\t\\tIf missing a device or no signal was received, please call use at 1-800-238-2727 so that we can get your devices set up properly.\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/system-test-wizard/final.hbs"
    }
  });

  _exports.default = _default;
});