define("adt-wss/services/app-dispatcher", ["exports", "adt-wss-common/services/app-dispatcher"], function (_exports, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ENABLE_SF_3G_CHAT_EVENT = _exports.UPDATE_NOTIFICATION_MASSAGES = _exports.SHOW_POST_SUBMISSION_NOTIFICATION_MASSAGE = _exports.SET_SWEEPSTAKES_MODAL_DRAWING_TIME = _exports.HIDE_EASYPAY_PROMO_ALERT = _exports.HIDE_CONTRACT_RENEWAL_ALERT = _exports.SHOW_TIMEOUT_MODAL_WINDOW = _exports.PIC_INVALIDATED_EVENT = _exports.PIC_VALIDATED_EVENT = _exports.PIC_SUBMITTED_EVENT = _exports.CRE_COMPLETE_VALIDATION_EVENT = _exports.CRE_COMPLETE_PAYMENT_EVENT = _exports.CRE_ERROR_EVENT = _exports.CRE_VALIDATION_ERROR_EVENT = _exports.EASYPAY_UPDATE_EVENT = _exports.SHOW_EASYPAY_ENROLL_EVENT = _exports.HIDE_EXPRESS_PAY_EVENT = _exports.EXPRESS_PAY_EVENT = _exports.HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT = _exports.ONE_TIME_PAYMENT_SUCCESSFUL = _exports.ONE_TIME_PAYMENT_EVENT = _exports.EASYPAY_ENROLL_EVENT = _exports.SHOW_STATUS_MESSAGE_EVENT = _exports.SUCCESS_PAYMENT_EVENT = _exports.UPDATE_APP_STATE_EVENT = _exports.SHOW_STATUS_MESSAGES_EVENT = _exports.HIDE_SF_CHAT_EVENT = _exports.SHOW_SF_CHAT_EVENT = _exports.DISABLE_SF_CHAT_EVENT = _exports.ENABLE_SF_CHAT_EVENT = _exports.DISABLE_PS_CHAT_EVENT = _exports.ENABLE_PS_CHAT_EVENT = _exports.HIDE_PS_CHAT_EVENT = _exports.SHOW_PS_CHAT_EVENT = _exports.PS_SESSION_INVALIDATED_EVENT = void 0;
  const PS_SESSION_INVALIDATED_EVENT = 'PS_SESSION_INVALIDATED_EVENT';
  _exports.PS_SESSION_INVALIDATED_EVENT = PS_SESSION_INVALIDATED_EVENT;
  const SHOW_PS_CHAT_EVENT = 'SHOW_PS_CHAT_EVENT';
  _exports.SHOW_PS_CHAT_EVENT = SHOW_PS_CHAT_EVENT;
  const HIDE_PS_CHAT_EVENT = 'HIDE_PS_CHAT_EVENT';
  _exports.HIDE_PS_CHAT_EVENT = HIDE_PS_CHAT_EVENT;
  const ENABLE_PS_CHAT_EVENT = 'ENABLE_PS_CHAT_EVENT';
  _exports.ENABLE_PS_CHAT_EVENT = ENABLE_PS_CHAT_EVENT;
  const DISABLE_PS_CHAT_EVENT = 'DISABLE_PS_CHAT_EVENT';
  _exports.DISABLE_PS_CHAT_EVENT = DISABLE_PS_CHAT_EVENT;
  const ENABLE_SF_CHAT_EVENT = 'ENABLE_SF_CHAT_EVENT';
  _exports.ENABLE_SF_CHAT_EVENT = ENABLE_SF_CHAT_EVENT;
  const DISABLE_SF_CHAT_EVENT = 'DISABLE_SF_CHAT_EVENT';
  _exports.DISABLE_SF_CHAT_EVENT = DISABLE_SF_CHAT_EVENT;
  const SHOW_SF_CHAT_EVENT = 'SHOW_SF_CHAT_EVENT';
  _exports.SHOW_SF_CHAT_EVENT = SHOW_SF_CHAT_EVENT;
  const HIDE_SF_CHAT_EVENT = 'HIDE_SF_CHAT_EVENT';
  _exports.HIDE_SF_CHAT_EVENT = HIDE_SF_CHAT_EVENT;
  const SHOW_STATUS_MESSAGES_EVENT = 'SHOW_STATUS_MESSAGES_EVENT';
  _exports.SHOW_STATUS_MESSAGES_EVENT = SHOW_STATUS_MESSAGES_EVENT;
  const UPDATE_APP_STATE_EVENT = 'UPDATE_APP_STATE_EVENT';
  _exports.UPDATE_APP_STATE_EVENT = UPDATE_APP_STATE_EVENT;
  const SUCCESS_PAYMENT_EVENT = 'SUCCESS_PAYMENT_EVENT';
  _exports.SUCCESS_PAYMENT_EVENT = SUCCESS_PAYMENT_EVENT;
  const SHOW_STATUS_MESSAGE_EVENT = 'SHOW_STATUS_MESSAGE_EVENT';
  _exports.SHOW_STATUS_MESSAGE_EVENT = SHOW_STATUS_MESSAGE_EVENT;
  const EASYPAY_ENROLL_EVENT = 'EASYPAY_ENROLL_EVENT';
  _exports.EASYPAY_ENROLL_EVENT = EASYPAY_ENROLL_EVENT;
  const ONE_TIME_PAYMENT_EVENT = 'ONE_TIME_PAYMENT_EVENT';
  _exports.ONE_TIME_PAYMENT_EVENT = ONE_TIME_PAYMENT_EVENT;
  const ONE_TIME_PAYMENT_SUCCESSFUL = 'ONE_TIME_PAYMENT_SUCCESSFUL';
  _exports.ONE_TIME_PAYMENT_SUCCESSFUL = ONE_TIME_PAYMENT_SUCCESSFUL;
  const HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT = 'HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT';
  _exports.HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT = HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT;
  const EXPRESS_PAY_EVENT = 'EXPRESS_PAY_EVENT';
  _exports.EXPRESS_PAY_EVENT = EXPRESS_PAY_EVENT;
  const HIDE_EXPRESS_PAY_EVENT = 'HIDE_EXPRESS_PAY_EVENT';
  _exports.HIDE_EXPRESS_PAY_EVENT = HIDE_EXPRESS_PAY_EVENT;
  const SHOW_EASYPAY_ENROLL_EVENT = 'SHOW_EASYPAY_ENROLL_EVENT';
  _exports.SHOW_EASYPAY_ENROLL_EVENT = SHOW_EASYPAY_ENROLL_EVENT;
  const EASYPAY_UPDATE_EVENT = 'EASYPAY_UPDATE_EVENT';
  _exports.EASYPAY_UPDATE_EVENT = EASYPAY_UPDATE_EVENT;
  const CRE_VALIDATION_ERROR_EVENT = 'CRE_VALIDATION_ERROR_EVENT';
  _exports.CRE_VALIDATION_ERROR_EVENT = CRE_VALIDATION_ERROR_EVENT;
  const CRE_ERROR_EVENT = 'CRE_ERROR_EVENT';
  _exports.CRE_ERROR_EVENT = CRE_ERROR_EVENT;
  const CRE_COMPLETE_PAYMENT_EVENT = 'CRE_COMPLETE_PAYMENT_EVENT';
  _exports.CRE_COMPLETE_PAYMENT_EVENT = CRE_COMPLETE_PAYMENT_EVENT;
  const CRE_COMPLETE_VALIDATION_EVENT = 'CRE_COMPLETE_VALIDATION_EVENT';
  _exports.CRE_COMPLETE_VALIDATION_EVENT = CRE_COMPLETE_VALIDATION_EVENT;
  const PIC_SUBMITTED_EVENT = 'PIC_SUBMITTED_EVENT';
  _exports.PIC_SUBMITTED_EVENT = PIC_SUBMITTED_EVENT;
  const PIC_VALIDATED_EVENT = 'PIC_VALIDATED_EVENT';
  _exports.PIC_VALIDATED_EVENT = PIC_VALIDATED_EVENT;
  const PIC_INVALIDATED_EVENT = 'PIC_INVALIDATED_EVENT';
  _exports.PIC_INVALIDATED_EVENT = PIC_INVALIDATED_EVENT;
  const SHOW_TIMEOUT_MODAL_WINDOW = 'SHOW_TIMEOUT_MODAL_WINDOW';
  _exports.SHOW_TIMEOUT_MODAL_WINDOW = SHOW_TIMEOUT_MODAL_WINDOW;
  const HIDE_CONTRACT_RENEWAL_ALERT = 'HIDE_CONTRACT_RENEWAL_ALERT';
  _exports.HIDE_CONTRACT_RENEWAL_ALERT = HIDE_CONTRACT_RENEWAL_ALERT;
  const HIDE_EASYPAY_PROMO_ALERT = 'HIDE_EASYPAY_PROMO_ALERT';
  _exports.HIDE_EASYPAY_PROMO_ALERT = HIDE_EASYPAY_PROMO_ALERT;
  const SET_SWEEPSTAKES_MODAL_DRAWING_TIME = 'SET_SWEEPSTAKES_MODAL_DRAWING_TIME';
  _exports.SET_SWEEPSTAKES_MODAL_DRAWING_TIME = SET_SWEEPSTAKES_MODAL_DRAWING_TIME;
  const SHOW_POST_SUBMISSION_NOTIFICATION_MASSAGE = 'SHOW_POST_SUBMISSION_NOTIFICATION_MASSAGE';
  _exports.SHOW_POST_SUBMISSION_NOTIFICATION_MASSAGE = SHOW_POST_SUBMISSION_NOTIFICATION_MASSAGE;
  const UPDATE_NOTIFICATION_MASSAGES = 'UPDATE_NOTIFICATION_MASSAGES';
  _exports.UPDATE_NOTIFICATION_MASSAGES = UPDATE_NOTIFICATION_MASSAGES;
  const ENABLE_SF_3G_CHAT_EVENT = 'ENABLE_SF_3G_CHAT_EVENT';
  _exports.ENABLE_SF_3G_CHAT_EVENT = ENABLE_SF_3G_CHAT_EVENT;

  var _default = _appDispatcher.default.extend({});

  _exports.default = _default;
});