define("adt-wss/instance-initializers/authorize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    const session = appInstance.lookup('service:session');

    if (localStorage && localStorage.getItem('ember_simple_auth-session')) {
      session.session.restore(JSON.parse(localStorage.getItem('ember_simple_auth-session')));
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});