define("adt-wss/services/manage-easypay", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss/services/app-dispatcher"], function (_exports, _myadtHelpers, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Manage EasyPay Service Module
   * @module Services/ManageEasyPay
   *
   * @prop {Object} details
   * @prop {String} details.ccNumber
   * @prop {String} details.ccType
   * @prop {String} details.ccExpDate
   * @prop {String} details.enrollmentType
   * @prop {String} details.nameOnAccount
   * @prop {String} details.ccBillingZip
   * @prop {Boolean} details.changesMadeRecently
   * @prop {?String} details.achAccountType
   * @prop {?String} details.achRoutingNumber
   * @prop {?String} details.achAccountNumber
   * @prop {?String} details.nameOnCard
   * @prop {?String} details.streetAddress
   */
  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)({
    details: null
  }), {
    apiService: Ember.inject.service(),
    details: null,

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.EASYPAY_ENROLL_EVENT, this, 'getEasyPayDetails');
    },

    /**
     * Retrieves manage EasyPay from API and sets `details`
     * @return {Promise}
     */
    getEasyPayDetails() {
      return this.get('apiService').myADT.getEasyPayDetails().then(response => {
        if (response && response.data) {
          this.set('details', response.data);
          return response.data;
        }

        return null;
      });
    }

  });

  _exports.default = _default;
});