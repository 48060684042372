define("adt-wss/pods/components/dashboard/account/profile-password/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss/mixins/on-form-esc-handler", "adt-wss-common/classes/status-message", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/route-helpers", "adt-wss-common/utils/fp", "adt-wss-common/classes/validator", "adt-wss-common/utils/myadt-helpers", "adt-wss/services/app-dispatcher", "adt-wss/pods/components/dashboard/account/profile-password/constants"], function (_exports, _withFsm, _onFormEscHandler, _statusMessage, _emberHelpers, _routeHelpers, _fp, _validator, _myadtHelpers, _appDispatcher, constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COMMON_VALIDATION_ERROR = _exports.SET_NO_EDIT_MODE_ACTION = _exports.SET_EDIT_MODE_ACTION = _exports.PASSWORD_INVALID_MESSAGE = _exports.FAILURE_PASSWORDS_ACTION = _exports.SUCCESS_PASSWORDS_ACTION = _exports.SUCCESS_ACTION = _exports.FAILURE_ACTION = _exports.DELETE_DATA_ACTION = _exports.UPDATE_DATA_ACTION = _exports.CANCEL_EDIT_MODE_ACTION = _exports.LOAD_PASSWORDS_ACTION = _exports.LOADING_STATE = _exports.DELETING_STATE = _exports.UPDATING_STATE = _exports.NO_EDIT_STATE = _exports.EDIT_STATE = _exports.IDLE_STATE = _exports.defaultProperties = void 0;
  const validator = new _validator.default({
    password: {
      rules: ['isNotEmpty', 'minLength?3', 'maxLength?10', 'noSpecialChar']
    }
  });
  const defaultProperties = {
    passwords: [],
    isSplitIntegrationSystems: false,
    editModeAvailable: true,
    alert: v => v,
    setOverlap: () => {},
    submitEdit: () => {
      throw new Error('Please pass submitEdit method to dashboard/account/profile-password component');
    },
    submitDelete: () => {
      throw new Error('Please pass submitDelete method to dashboard/account/profile-password component');
    }
  };
  _exports.defaultProperties = defaultProperties;
  const {
    IDLE_STATE,
    EDIT_STATE,
    NO_EDIT_STATE,
    UPDATING_STATE,
    DELETING_STATE,
    LOADING_STATE,
    LOAD_PASSWORDS_ACTION,
    CANCEL_EDIT_MODE_ACTION,
    UPDATE_DATA_ACTION,
    DELETE_DATA_ACTION,
    FAILURE_ACTION,
    SUCCESS_ACTION,
    SUCCESS_PASSWORDS_ACTION,
    FAILURE_PASSWORDS_ACTION,
    PASSWORD_INVALID_MESSAGE,
    SET_EDIT_MODE_ACTION,
    SET_NO_EDIT_MODE_ACTION,
    COMMON_VALIDATION_ERROR
  } = constants;
  _exports.COMMON_VALIDATION_ERROR = COMMON_VALIDATION_ERROR;
  _exports.SET_NO_EDIT_MODE_ACTION = SET_NO_EDIT_MODE_ACTION;
  _exports.SET_EDIT_MODE_ACTION = SET_EDIT_MODE_ACTION;
  _exports.PASSWORD_INVALID_MESSAGE = PASSWORD_INVALID_MESSAGE;
  _exports.FAILURE_PASSWORDS_ACTION = FAILURE_PASSWORDS_ACTION;
  _exports.SUCCESS_PASSWORDS_ACTION = SUCCESS_PASSWORDS_ACTION;
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
  _exports.FAILURE_ACTION = FAILURE_ACTION;
  _exports.DELETE_DATA_ACTION = DELETE_DATA_ACTION;
  _exports.UPDATE_DATA_ACTION = UPDATE_DATA_ACTION;
  _exports.CANCEL_EDIT_MODE_ACTION = CANCEL_EDIT_MODE_ACTION;
  _exports.LOAD_PASSWORDS_ACTION = LOAD_PASSWORDS_ACTION;
  _exports.LOADING_STATE = LOADING_STATE;
  _exports.DELETING_STATE = DELETING_STATE;
  _exports.UPDATING_STATE = UPDATING_STATE;
  _exports.NO_EDIT_STATE = NO_EDIT_STATE;
  _exports.EDIT_STATE = EDIT_STATE;
  _exports.IDLE_STATE = IDLE_STATE;
  const fsmModel = {
    scrollToTop: false,
    transitions: {
      [IDLE_STATE]: {
        [LOAD_PASSWORDS_ACTION]: LOADING_STATE,
        [SET_EDIT_MODE_ACTION]: EDIT_STATE,
        [SET_NO_EDIT_MODE_ACTION]: NO_EDIT_STATE
      },
      [LOADING_STATE]: {
        [SUCCESS_PASSWORDS_ACTION]: IDLE_STATE,
        [FAILURE_PASSWORDS_ACTION]: IDLE_STATE
      },
      [EDIT_STATE]: {
        [CANCEL_EDIT_MODE_ACTION]: IDLE_STATE,
        [UPDATE_DATA_ACTION]: UPDATING_STATE,
        [DELETE_DATA_ACTION]: DELETING_STATE
      },
      [UPDATING_STATE]: {
        [FAILURE_ACTION]: EDIT_STATE,
        [SUCCESS_ACTION]: IDLE_STATE
      },
      [DELETING_STATE]: {
        [FAILURE_ACTION]: EDIT_STATE,
        [SUCCESS_ACTION]: EDIT_STATE
      }
    }
  };
  /**
   * @module Components/Dashboard/Account/ProfilePassword
   * @prop {String} elementId - ID attribute for div component wrapper
   * @prop {Array} password - User's existing passwords
   * @prop {Boolean} editModeAvailable - Availability of edit mode
   * @prop {Object} data - data from API copy to it for edit mode to avoid two-ways binding
   * @prop {Function} submitEdit - Function which save changes
   * @prop {Function} submitDelete - Function which delete changes
   * @prop {Function} setOverlap - Set value for visibility background overlap
   * @example
   * {@lang xml} {{ dashboard/account/profile-password
  		elementId='dashboardProfilePassword'
  		passwords: [
  			{"existingPassword":null,"password":"760","picLevel":3,"passwordId":36520722,"manage":true,"irregularOpen":true},
  			{"existingPassword":null,"password":"27760","picLevel":3,"passwordId":36520723,"manage":true,"irregularOpen":true}
  		],
  		editModeAvailable=true
  		submitEdit=(action 'submit')
  		submitDelete=(action 'submitDelete')
  		setOverlap=(action 'setOverlap')
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProperties, _onFormEscHandler.default, {
    classNames: ['account-widget-section'],
    classNameBindings: ['editMode:active'],
    elementId: 'dashboardProfilePassword',
    mwd: Ember.inject.service('modal-window-dispatcher'),
    commonPasswords: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    csrInfo: Ember.inject.service(),
    validator,
    fsmModel,
    currentState: IDLE_STATE,
    activeESCState: EDIT_STATE,
    cancelESCState: CANCEL_EDIT_MODE_ACTION,
    initAction: LOAD_PASSWORDS_ACTION,
    IS_IDLE_STATE: Ember.computed.equal('currentState', IDLE_STATE),
    IS_UPDATING: (0, _emberHelpers.computedSome)('currentState', UPDATING_STATE, DELETING_STATE, LOADING_STATE),
    errors: null,
    messages: null,
    verbalSecurityPasswordError: false,
    data: null,
    passwords: Ember.computed.oneWay('commonPasswords.allPasswords'),
    isSplitIntegrationSystems: Ember.computed.oneWay('currentLocation.splitIntSystem'),
    editMode: Ember.computed.equal('currentState', EDIT_STATE),
    noEditMode: Ember.computed.equal('currentState', NO_EDIT_STATE),
    isEditModeChanged: Ember.observer('editMode', function () {
      this.setOverlap(this.get('editMode'));
    }),
    actions: {
      dispatch(action, data) {
        this.get('fsm').dispatch(action, data);
      },

      disableFields(passwordItem) {
        this.disableInactiveFields(passwordItem);
      }

    },

    disableInactiveFields(passwordItem) {
      const data = this.get('data');
      data.forEach(prop => {
        prop.set('inputDisabled', true);
      });
      passwordItem.set('buttonsEnabled', true);
      passwordItem.set('inputDisabled', false);
    },

    [SET_EDIT_MODE_ACTION]() {
      this.prepareEdit();
    },

    [SET_NO_EDIT_MODE_ACTION]() {// split integration, no edit available
    },

    [SUCCESS_ACTION]({
      action
    }) {
      const msg = 'Verbal security password successfully ' + action;

      if (action === 'deleted') {
        this.prepareEdit();
      }

      this.alert([new _statusMessage.default(msg, 'success')]);
      this.statusMessageTimeout();
    },

    [FAILURE_ACTION](response) {
      if (!response || !response.errors) return true;
      const e = (0, _myadtHelpers.genericErrorHandler)(response).map(error => new _statusMessage.default(error, 'error'));
      this.alert(e);
      this.disableInactiveFields();
      response.data.buttonsEnabled = false;
      return true;
    },

    /**
     * Auto hides status message after timer expires
     * @param {Int} duration defaults to 5000
     */
    statusMessageTimeout(duration) {
      duration = duration || 5000;
      setTimeout(() => {
        this.alert(null);
      }, duration);
    },

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
      this.get('validator').onValidate(({
        messages: errors,
        values
      }) => {
        this.set('errors', errors);

        if (values.length) {
          this.set('messages', COMMON_VALIDATION_ERROR);
        }
      });
      this.keyDown = this.onEscHandler.bind(this);
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      this.get('fsm').dispatch(LOAD_PASSWORDS_ACTION, true);
    },

    [SUCCESS_PASSWORDS_ACTION]() {
      this.resetAlert();
      this.prepareEdit();
    },

    [FAILURE_PASSWORDS_ACTION](error) {
      console.error(error);
    },

    [LOAD_PASSWORDS_ACTION](forceRequest = false) {
      const commonPasswords = this.deliverData('commonPasswords.allPasswords', passwordsData => passwordsData.getData(), {
        forceRequest
      });
      return commonPasswords.then((0, _routeHelpers.fsmDispatch)(SUCCESS_PASSWORDS_ACTION)(this)).catch((0, _routeHelpers.fsmDispatch)(FAILURE_PASSWORDS_ACTION)(this));
    },

    [CANCEL_EDIT_MODE_ACTION]() {
      const errorProps = this.getProperties(['verbalSecurityPasswordError']);
      this.resetAlert();
      this.statusMessageTimeout(1);
      Object.keys(errorProps).forEach(k => this.set(k, false));
      this.set('errors', '');
    },

    [UPDATE_DATA_ACTION](data) {
      this.resetAlert();
      const {
        validator: v,
        fsm
      } = this.getProperties('validator', 'fsm');
      const validationFailed = v.validate(data);

      if (!validationFailed) {
        return this.submitEdit(data).then(() => fsm.dispatch(SUCCESS_ACTION, {
          action: 'updated'
        })).catch(({
          errors
        }) => fsm.dispatch(FAILURE_ACTION, {
          errors,
          data
        }));
      }

      return fsm.dispatch(FAILURE_ACTION);
    },

    [DELETE_DATA_ACTION](data) {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const {
        passwordId
      } = data;
      mwd.show({
        name: 'delete-verbal-security-password-confirm',
        title: 'Delete Verbal Security Password',
        body: {
          name: 'Are you sure you want to delete this Verbal Security Password?'
        },
        isFooterLine: false,
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          color: 'blue',
          callback: () => {
            mwd.hide();
            fsm.dispatch(FAILURE_ACTION);
          }
        }, {
          caption: 'Delete',
          callback: () => {
            mwd.hide();
            return this.submitDelete(data).then(() => fsm.dispatch(SUCCESS_ACTION, {
              action: 'deleted',
              passwordId
            })).catch(response => fsm.dispatch(FAILURE_ACTION, response));
          }
        }]
      });
    },

    resetAlert() {
      this.set('errors', null);
      this.set('messages', null);
      this.alert(null);
    },

    prepareEdit() {
      const passwords = this.get('passwords');
      const props = (passwords || []).map(value => Ember.Object.create((0, _fp.omix)(value, {
        existingPassword: value.password
      })));
      this.set('data', props);
    }

  });

  _exports.default = _default;
});