define("adt-wss/pods/components/dashboard/account/additional-certificates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N0a2soxP",
    "block": "{\"symbols\":[\"route\"],\"statements\":[[7,\"h3\",true],[10,\"class\",\"widget-title\"],[8],[0,\"Certificates & Forms (PDF)\"],[9],[0,\"\\n\"],[7,\"ul\",true],[10,\"class\",\"additional-certificates-links-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"links\",\"certLinks\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"visibility\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"li\",true],[10,\"class\",\"additional-certificates-links-list-item\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"a\",true],[11,\"href\",[29,[[28,\"if\",[[23,1,[\"external\"]],[23,1,[\"url\"]],[28,\"concat\",[[24,[\"rootURL\"]],[23,1,[\"url\"]]],null]],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,1,[\"action\"]]],null]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[1,[23,1,[\"title\"]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[4,\"unless\",[[24,[\"isCommercialCustomer\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"currentLocation\",\"permissions\",\"coi\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"li\",true],[10,\"class\",\"additional-certificates-links-list-item\"],[8],[1,[28,\"dashboard/account/insurance-discount-widget\",null,[[\"isLinkView\"],[true]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/account/additional-certificates/template.hbs"
    }
  });

  _exports.default = _default;
});