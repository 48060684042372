define("adt-wss/pods/components/billing/paymentech-iframe/component", ["exports", "jquery", "adt-wss/config/environment", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers"], function (_exports, _jquery, _environment, _myadtHelpers, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const testUrl = isEXP => isEXP ? 'static/hpfform7-ep.html' : '/static/hpfform7-otp.html';
  /**
   * Template component for setting up and rendering Chase Paymentech
   * iFrame for new credit card tokenization
   * @module Components/PaymentechIframe
   *
   * @prop {PermissionsObject} permissions
   * @prop {Boolean} isExpressPay the iframe has different settings if true
   * @prop {String} selectedAcctId default: 'new'
   * @prop {Boolean} saveAccountInfo default: false
   * @prop {String} callbackHtml place for form to callback to
   */


  var _default = Ember.Component.extend({
    elementId: 'hpfIframeContainer',
    iframeDomId: 'hpfIframe',
    currentLocation: Ember.inject.service(),
    paymentech: Ember.inject.service(),
    EPS: Ember.inject.service('expresspay-service'),
    permissions: Ember.computed.readOnly('currentLocation.permissions'),
    isExpressPay: (0, _emberHelpers.computedApplyFunction)(() => /express-pay/i.test(window.location.href), 'window.location'),
    flowId: null,
    selectedAcctId: 'new',
    saveAccountInfo: false,
    iframeWidth: 'calc(100% - 20px)',

    /**
     * Creates the HPF iFrame, based upon the configuration settings.
     * Returns a jQuery object.
     * @param {String} iframeDomId
     * @param {String} iframeDomClass
     * @param {String|Int} iframeWidth
     * @param {String|Int} iframeHeight
     *
     * @return {$}
     */
    createIframeHtmlDom({
      uID,
      iframeDomId,
      iframeDomClass,
      iframeWidth,
      iframeHeight
    }) {
      // domId is required
      if (!iframeDomId) return -1; // set the attributes for the iframe

      const attrs = {
        id: iframeDomId,
        class: iframeDomClass || iframeDomId,
        src: _environment.default.environment == 'development' ? testUrl(this.get('isExpressPay')) : "".concat(this.get('paymentech.config.hpfHostUrl'), "uID=").concat(uID),
        border: 0,
        scrolling: 'no',
        frameborder: 0
      }; // if an onload event for the iframe was provided, add it to the attributes.

      const iFrameOnload = this.get('paymentech.config.iFrameOnload');
      if (iFrameOnload && iFrameOnload.length > 0) attrs.onload = iFrameOnload;else attrs.onload = 'window.hpfIframeOnload()';
      if (iframeWidth) attrs.width = iframeWidth;
      if (iframeHeight) attrs.height = iframeHeight;
      const iframe = (0, _jquery.default)('<iframe />', attrs);
      return iframe;
    },

    /**
     * Injects the iFrame into a DOM element
     * @param {String} domNodeSelector
     * @param {String} iframeDomId
     * @param {String} iframeDomClass
     * @param {String|Int} iframeWidth
     * @param {String|Int} iframeHeight
     */
    injectHpfIframe({
      domNodeSelector,
      iframeDomId,
      uID,
      iframeWidth,
      iframeHeight
    }) {
      (0, _jquery.default)("".concat(this.get('elementId'), " .loading")).show(0);
      const iframe = this.createIframeHtmlDom({
        uID,
        iframeDomId,
        iframeWidth,
        iframeHeight
      });
      (0, _jquery.default)(domNodeSelector).html(iframe);
    },

    /**
     * Remove the iframe from the iframe container. This only removes the
     * iframe from the container - not any of the iframe's siblings.
     * @param {String} domNodeSelector
     * @param {String} iframeDomId
     */
    removeIframe(domNodeSelector, iframeDomId) {
      if (!domNodeSelector || (0, _jquery.default)(domNodeSelector).length <= 0) {
        domNodeSelector = this.get('paymentech.config.domNodeSelector');
      }

      (0, _jquery.default)(domNodeSelector).find("#".concat(iframeDomId)).remove();
    },

    /**
     * Empty the iframe container. This removes the iframe and any siblings.
     * @param {String} domNodeSelector
     */
    emptyIframeContainer(domNodeSelector) {
      if (!domNodeSelector || (0, _jquery.default)(domNodeSelector).length <= 0) {
        domNodeSelector = this.get('paymentech.config.domNodeSelector');
      }

      (0, _jquery.default)(domNodeSelector).empty();
    },

    /**
     * Reload the iframe container.
     * @returns {Promise}
     */
    refreshIframeContainer() {
      this.emptyIframeContainer();
      return Ember.RSVP.Promise.resolve();
    },

    didReceiveAttrs() {
      this._super(...arguments);

      const {
        iframeDomId,
        elementId
      } = this.getProperties('iframeDomId', 'elementId');
      const opts = {
        domNodeSelector: "#".concat(elementId),
        flowId: this.get('flowId'),
        isPS: this.get('isPS')
      };

      const injectHpfIframe = config => this.injectHpfIframe({
        domNodeSelector: config.domNodeSelector,
        uID: config.uID,
        iframeDomId,
        iframeWidth: this.get('iframeWidth'),
        iframeHeight: this.get('iframeHeight')
      });

      this.get('paymentech').start(opts).then(injectHpfIframe).catch(() => {// need error messaging
      });

      _myadtHelpers.default.sendAnalyticEvent(['_trackPageview', "".concat(window.location.pathname, "/quickPay/selectedCC")]);

      window.hpfIframeOnload = function () {
        (0, _jquery.default)("".concat(elementId, " .loading")).hide(0);
        (0, _jquery.default)("".concat(elementId, " iframe")).show(0);
      };
    }

  });

  _exports.default = _default;
});