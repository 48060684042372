define("adt-wss/pods/components/dashboard/alarm/system-test/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FAILURE_ACTION = _exports.SUCCESS_ACTION = _exports.LOAD_TESTS_ACTION = _exports.LOADING_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const LOADING_STATE = 'LOADING_STATE';
  _exports.LOADING_STATE = LOADING_STATE;
  const LOAD_TESTS_ACTION = 'LOAD_TESTS_ACTION';
  _exports.LOAD_TESTS_ACTION = LOAD_TESTS_ACTION;
  const SUCCESS_ACTION = 'SUCCESS_ACTION';
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
  const FAILURE_ACTION = 'FAILURE_ACTION';
  _exports.FAILURE_ACTION = FAILURE_ACTION;
});