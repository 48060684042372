define("adt-wss/pods/dashboard/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "adt-wss-common/utils/route-helpers", "adt-wss/services/app-dispatcher", "adt-wss/pods/dashboard/constants"], function (_exports, _authenticatedRouteMixin, _routeHelpers, _appDispatcher, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.setSweepstakesFlag = _exports.getSweepstakesFlag = _exports.setWelcomeFlag = _exports.getWelcomeFlag = void 0;

  const getWelcomeFlag = (email = '') => localStorage.getItem("myADT_showedWelcomeExp_".concat(email)) === 'true';

  _exports.getWelcomeFlag = getWelcomeFlag;

  const setWelcomeFlag = (email = '') => localStorage.setItem("myADT_showedWelcomeExp_".concat(email), 'true');

  _exports.setWelcomeFlag = setWelcomeFlag;

  const getSweepstakesFlag = () => localStorage.getItem('myADT_showedSweepstakes') === 'true';

  _exports.getSweepstakesFlag = getSweepstakesFlag;

  const setSweepstakesFlag = value => localStorage.setItem('myADT_showedSweepstakes', value);
  /**
   * Handles authentication checks and front loading required
   * API data for dashboard pages
   * @module Routes/Dashboard
   * @prop {String} authenticationRoute
   * @prop {Boolean} dataInitialized
   */


  _exports.setSweepstakesFlag = setSweepstakesFlag;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    myadtSecurity: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    systemInformation: Ember.inject.service(),
    billingData: Ember.inject.service(),
    contactData: Ember.inject.service(),
    alarmData: Ember.inject.service(),
    userProfile: Ember.inject.service(),
    promotionData: Ember.inject.service(),
    flexfiData: Ember.inject.service(),
    authenticationRoute: 'index.home',
    dataInitialized: false,

    /**
     * Validates PIC and gets data necessary for the
     * dashboard to run.
     * Uses `call` and `apply` to deal with scoping
     * issues that appeared at runtime during testing
     */
    beforeModel() {
      this._super(...arguments);

      this.startup();
    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      this.set('dataInitialized', false);
      return this.refresh();
    },

    startup() {
      const {
        session,
        myadtSecurity,
        authInfo,
        currentLocation,
        dataInitialized,
        systemInformation,
        billingData,
        promotionData,
        flexfiData
      } = this.getProperties('session', 'myadtSecurity', 'authInfo', 'currentLocation', 'dataInitialized', 'systemInformation', 'billingData', 'promotionData', 'flexfiData');

      const startSecurity = () => myadtSecurity.start.apply(myadtSecurity);

      const getAccountInfo = () => currentLocation.getAccountInfo.call(currentLocation, true);

      const getEquipmentList = () => systemInformation.getEquipmentList.apply(systemInformation);

      const getStandardAndSystemInfoLists = () => {
        systemInformation.getStandardAndSystemInfoLists.apply(systemInformation).then(() => systemInformation.getSelectedSystem());
      };

      const contractRenewalInfo = () => promotionData.showContractRenewal.call(promotionData);

      const getBillingData = () => billingData.getBillingInfo.apply(billingData);

      const getPromotionData = () => promotionData.getPromoInfo.apply(promotionData);

      const getFlexFiData = () => flexfiData.getFlexFiDetails.apply(flexfiData);

      const getUnprotectedData = Ember.RSVP.hashSettled({
        standardSystemList: getStandardAndSystemInfoLists(),
        equipmentList: getEquipmentList(),
        billingData: getBillingData(),
        promotionData: getPromotionData(),
        promotionDataContent: promotionData.getSweepstakeContent.apply(promotionData),
        contractRenewalInfo: contractRenewalInfo(),
        flexfiData: getFlexFiData()
      });

      const getProtectedData = () => this.getProtectedData();

      const dataIsReady = () => this.set('dataInitialized', true);

      const logout = logoutInfo => {
        console.warn('logoutInfo', logoutInfo);
        console.trace();

        if (session.isAuthenticated) {
          session.invalidate(false, 'noAcctInfo');
          setSweepstakesFlag(false);
        }
      };

      const resolveReceivedData = (0, _routeHelpers.promiseResolver)(resolved => {
        const controller = this.controllerFor('dashboard');
        const {
          currentLocation,
          systemInformation
        } = this.getProperties('currentLocation', 'systemInformation');
        const profileInfo = currentLocation.profileInfo || {};
        const cellUpgradeEligibilityDetails = systemInformation.cellUpgradeEligibilityDetails || {};
        const {
          email
        } = profileInfo;
        const {
          promotionData: {
            content
          }
        } = this.getProperties('promotionData');
        const longTermUser = Date.createDate(profileInfo.lastLogin || 1) < Date.createDate('2018-11-18');

        if (!cellUpgradeEligibilityDetails.cellTechUpgradeEligNonProactive) {
          if (longTermUser && !getWelcomeFlag(email)) {
            controller.get('fsm').dispatch(_constants.START_WELCOME_EXPERIENCE_ACTION);
          } else if (content && content.showModal && !getSweepstakesFlag()) {
            controller.get('fsm').dispatch(_constants.SWEEPSTAKES_MODAL_ACTION);
            setSweepstakesFlag(true);
          }
        }

        setWelcomeFlag(email);
        dataIsReady();
        return resolved;
      });

      if (!myadtSecurity.picProvided) {
        return authInfo.getAuthInfo().then(getAccountInfo).then(startSecurity).then(data => data.validPic ? getProtectedData() : true).then(getUnprotectedData).then(resolveReceivedData).catch(logout);
      }

      if (!dataInitialized) {
        return getProtectedData().then(resolveReceivedData).then(dataIsReady);
      }

      return Ember.RSVP.Promise.resolve();
    },

    /**
     * Get's data that requires PIC validation.
     * Uses `call` and `apply` to deal with scoping
     * issues that appeared at runtime during testing
     *
     * _THIS IS CRAZY - THIS SHOULD WORKS WITHOUT APPLY OR CALL BECAUSE WE RUN EACH METHOD BY DOT - a.b()
     * WE SHOULD INVESTIGATE IT - MY MIND TELL THAT THIS IS WRONG CODE_ (osudavnyi)
     */
    getProtectedData() {
      const {
        contactData,
        alarmData,
        userProfile,
        promotionData
      } = this.getProperties('contactData', 'alarmData', 'userProfile', 'promotionData');
      return Ember.RSVP.hashSettled({
        contactData: contactData.getSiteNumbers.apply(contactData),
        alarmData: alarmData.getAlarmHistory.apply(alarmData),
        userProfile: userProfile.getProfileData.apply(userProfile),
        promotionDataContent: promotionData.getSweepstakeContent.apply(promotionData)
      });
    },

    activate() {
      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    deactivate() {
      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    }

  });

  _exports.default = _default;
});