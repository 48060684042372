define("adt-wss/pods/components/dashboard/shop/adhesive-kit-checkout/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SUCCESS_ACTION = _exports.SUCCESS_PRICE_DATA_ACTION = _exports.SUBMIT_ORDER_ACTION = _exports.FETCH_PRICE_DATA_ACTION = void 0;
  const FETCH_PRICE_DATA_ACTION = 'FETCH_PRICE_DATA_ACTION';
  _exports.FETCH_PRICE_DATA_ACTION = FETCH_PRICE_DATA_ACTION;
  const SUBMIT_ORDER_ACTION = 'SUBMIT_ORDER_ACTION';
  _exports.SUBMIT_ORDER_ACTION = SUBMIT_ORDER_ACTION;
  const SUCCESS_PRICE_DATA_ACTION = 'SUCCESS_PRICE_DATA_ACTION';
  _exports.SUCCESS_PRICE_DATA_ACTION = SUCCESS_PRICE_DATA_ACTION;
  const SUCCESS_ACTION = 'SUCCESS_ACTION';
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
});