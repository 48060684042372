define("adt-wss/pods/components/dashboard/balance-due/component", ["exports", "adt-wss-common/mixins/with-fsm"], function (_exports, _withFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Components/Dashboard/BalanceDue
   * @prop {String} elementId - ID attribute for div component wrapper
   * @prop {Number} balanceValue - Balance amount value
   * @prop {String|Number} accountNumber - Personal account number
   * @prop {Date} nextPaymentDate - Date of next payment
   * @prop {String} Style - HTML Atribute
   * @prop {Boolean} enrolledInEasypay - Enrollment status of a user in easypay system
   * @example
   * {@lang xml} {{ dashboard/balance-due
  		balanceValue='58.19'
  		accountNumber='"0123456789"'
  		nextPaymentDate='"2018-04-11T17:21:34.326Z"'
  		enrolledInEasypay=false
  }}
   */
  var _default = Ember.Component.extend(_withFsm.default, {
    elementId: 'dashboardBalanceDue',
    currentLocation: Ember.inject.service(),
    billingData: Ember.inject.service(),
    billingModel: Ember.computed.oneWay('billingData.billingModel'),
    balanceValue: Ember.computed.oneWay('billingModel.billInfo.balance'),
    accountNumber: Ember.computed.oneWay('currentLocation.profileInfo.accountId'),
    nextPaymentDate: Ember.computed.oneWay('billingModel.billInfo.balanceDueDateObj'),
    enrolledInEasypay: Ember.computed.oneWay('billingModel.billingOptions.enrolledInEasyPay'),
    hasPermission: Ember.computed.oneWay('currentLocation.permissions.billing'),
    flexfiDetails: Ember.computed.oneWay('flexfiData.data'),
    blockedByPending: Ember.computed.oneWay('billingModel.billInfo.oneTimePendingPaymentExist')
  });

  _exports.default = _default;
});