define("adt-wss/templates/modal-windows/system-test-wizard/started", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TOjRZMlv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"test-system-wizard-info-modal\"],[10,\"role\",\"region\"],[11,\"aria-label\",[29,[\"System is now in Test Mode for \",[24,[\"body\",\"duration\",\"text\"]],\".\\n\\tPlacing system on test mode does not prevent the siren from sounding\"]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"test-wizard-info-text system-test-started-info\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"body\",\"duration\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"p\",true],[8],[7,\"strong\",true],[8],[0,\"System is now in Test Mode for \"],[1,[24,[\"body\",\"duration\",\"text\"]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\\t\"],[1,[28,\"widget/system-test-wizard-note\",null,[[\"text\"],[\"Placing system on test mode does not prevent the siren from sounding\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/system-test-wizard/started.hbs"
    }
  });

  _exports.default = _default;
});