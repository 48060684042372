define("adt-wss/pods/components/dashboard/appointment-widget/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers"], function (_exports, _withFsm, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.techStatusComputed = _exports.estimatedTimeComputed = _exports.timeRangeComputed = _exports.dateComputed = _exports.sheduledForToday = _exports.forNearestDate = _exports.defaultProps = void 0;
  const defaultProps = {
    avatar: '/images/v2/temp/avatar.png',
    icon: 'dashboard',
    technicianCoordinates: {
      lat: 32.9296988,
      lng: -97.0113566
    }
  };
  /**
   * Make date string for scheduled appointment message (Monday, July 8th)
   * @param  {Date} apptStartDateObj - Scheduled appointment date
   * @return {String} Monday, July 8th)
   */

  _exports.defaultProps = defaultProps;

  const forNearestDate = apptStartDateObj => {
    const dayName = apptStartDateObj.getDayString();
    const monthName = apptStartDateObj.getMonthString();
    const dateNum = apptStartDateObj.getDate();
    return "".concat(dayName, ", ").concat(monthName, " ").concat(dateNum);
  };
  /**
   * Calculating if appointment scheduled for today
   * @param  {Date} apptStartDateObj - Scheduled appointment date
   * @return {Boolean} Monday, July 8th)
   */


  _exports.forNearestDate = forNearestDate;

  const sheduledForToday = (apptStartDateObj, jobClassId) => {
    if ('VSC' === jobClassId) {
      return false;
    }

    return new Date().toAltDayString() === Date.createDate(apptStartDateObj).toAltDayString();
  };
  /**
  * Make date string for scheduled appointment message
  * @param  {Date} date - Scheduled appointment date
  * @param  {Boolean} isSmartphone
  * @return {String}
  */


  _exports.sheduledForToday = sheduledForToday;

  const dateComputed = (date, isSmartphone) => {
    if (isSmartphone) {
      return "".concat(date.getMonthString('full'), " ").concat(date.getFixedDate());
    }

    return date.toAltDayString('abbr', true, 'full');
  };
  /**
   * Calculating time range when technician will come
   * @param  {Date} apptStartDateObj
   * @param  {Date} apptEndDateObj
   * @return {String} - 11AM - 3PM
   */


  _exports.dateComputed = dateComputed;

  const timeRangeComputed = (apptStartDateObj, apptEndDateObj) => {
    const startTime = apptStartDateObj.toTimeAmPm(false, false);
    const endTime = apptEndDateObj.toTimeAmPm(false, false);
    return "".concat(startTime, " - ").concat(endTime);
  };
  /**
   * Calculating time when techinician will come
   * @param  {Date} technicianEstArrivaleDate
   * @return {Number}
   */


  _exports.timeRangeComputed = timeRangeComputed;

  const estimatedTimeComputed = technicianEstArrivaleDate => Date.span(technicianEstArrivaleDate).minutes;
  /**
   * Calculating technician status
   * @param  {String} technicianStatus - technician status
   * @return {Object}
   */


  _exports.estimatedTimeComputed = estimatedTimeComputed;

  const techStatusComputed = (technicianStatus, jobClassId) => ({
    label: technicianStatus || 'Scheduled',
    style: technicianStatus ? 'assigned' : 'processing',
    icon: technicianStatus ? 'truck-green.png' : 'truck-blue.png',
    virtual: jobClassId === 'VSC' ? true : false
  });
  /**
   * Renders appointment windget of client
   * @module Components/Dashboard/AppointmentWidget
   * @prop {Object} appointment - Appointment object
   * @prop {Number} appointment.jobNo - Status details
   * @prop {Number} appointment.technicianEmpNo - technician id
   * @prop {Date} appointment.apptStartDateObj - Start time when technician will come
   * @prop {Date} appointment.apptEndDateObj - End time when technician will come
   * @prop {Date} appointment.technicianEstArrivaleDate - Time in minutes when technician will come
   * @prop {String} appointment.technicianName - Name of technician
   * @prop {Object<ComputedProperty>} appointment.jobStatus - Status details
   * @prop {String<ComputedProperty>} appointment.date - Appointment date
   * @prop {String<ComputedProperty>} appointment.estimatedTime - Time in minutes when technician will come
   * @prop {String<ComputedProperty>} appointment.timeRange - Time range when technician will come
   * @prop {String} appointment.status - Status code of job status
   * @prop {String} appointment.avatar - Path to image
   * @prop {Object} appointment.technicianCoordinates - Coordinates for render marker on map
   * @prop {String} techStatus - Status of technician
   * @prop {Boolean} isWidget - transform view for windget on dashboard page
   * @prop {Boolean} hasToday - indicate that exist appointment for today
   * @prop {String} nearestDate - Date of nearest appointment
   * @prop {String} date - Date of appointment
   * @prop {Number} estimatedTime - Estimated time when technician will come - minutes
   * @prop {String} timeRange - Time range when technician will come
   * @prop {Object} technicianCoordinates - Coordinates for render marker on map
   * @prop {Function} refreshData - Callback function to refresh data on reshedule or cancel appointment
   */


  _exports.techStatusComputed = techStatusComputed;

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    classNames: ['appointment-widget'],
    classNameBindings: ['isWidget::page-view'],
    techStatus: (0, _emberHelpers.computedApplyFunction)(techStatusComputed, 'appointment.technicianStatus', 'appointment.jobClassId'),
    hasToday: (0, _emberHelpers.computedApplyFunction)(sheduledForToday, 'appointment.apptStartDateObj', 'appointment.jobClassId'),
    nearestDate: (0, _emberHelpers.computedApplyFunction)(forNearestDate, 'appointment.apptStartDateObj'),
    date: (0, _emberHelpers.computedApplyFunction)(dateComputed, 'appointment.apptStartDateObj', 'media.isSmartphone'),
    estimatedTime: (0, _emberHelpers.computedApplyFunction)(estimatedTimeComputed, 'appointment.technicianEstArrivaleDate'),
    timeRange: (0, _emberHelpers.computedApplyFunction)(timeRangeComputed, 'appointment.apptStartDateObj', 'appointment.apptEndDateObj'),

    /**
     * `Render map with marker`
     * @param  {Number} lat - Latitude
     * @param  {Number} lng - Longitude
     */
    createMap(lat, lng) {
      const map = new window.google.maps.Map(document.getElementById('appointmentWidgetMap'), {
        center: {
          lat,
          lng
        },
        zoom: 5,
        disableDefaultUI: true
      });
      const marker = new window.google.maps.Marker({
        position: {
          lat,
          lng
        },
        map,
        title: 'Hello World!'
      });
      this.set('map', map);
      this.set('technicianMarker', marker);
    },

    actions: {
      handleChangeAppointment({
        status,
        type
      }) {
        const message = status === 'success' ? "Appointment successfully ".concat(type === 'cancel' ? 'canceled' : 'rescheduled', ".") : 'Oops, something went wrong!';
        this.showStatusMessage(message, status);
        this.getMethod('refreshData')();
      }

    }
  });

  _exports.default = _default;
});