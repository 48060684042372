define("adt-wss/pods/components/dashboard/alarm/system-test-wizard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dlqHxL6s",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",\"start-test-btn button button-blue-outline\"],[3,\"action\",[[23,0,[]],\"dispatch\",\"INITIALIZE_ACTION\"]],[8],[0,\"Select\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/alarm/system-test-wizard/template.hbs"
    }
  });

  _exports.default = _default;
});