define("adt-wss/pods/components/ps/myadt-reset-pic/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.COMMON_VALIDATION_ERROR = _exports.PASSWORD_INVALID_MESSAGE = _exports.SUCCESS_PASSWORDS_ACTION = _exports.FAILURE_PASSWORDS_ACTION = _exports.SUCCESS_ACTION = _exports.FAILURE_ACTION = _exports.UPDATE_DATA_ACTION = _exports.LOAD_PASSWORDS_ACTION = _exports.REFRESH_PASSWORDS_ACTION = _exports.LOADING_STATE = _exports.UPDATING_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const UPDATING_STATE = 'UPDATING_STATE';
  _exports.UPDATING_STATE = UPDATING_STATE;
  const LOADING_STATE = 'LOADING_STATE';
  _exports.LOADING_STATE = LOADING_STATE;
  const REFRESH_PASSWORDS_ACTION = 'REFRESH_PASSWORDS_ACTION';
  _exports.REFRESH_PASSWORDS_ACTION = REFRESH_PASSWORDS_ACTION;
  const LOAD_PASSWORDS_ACTION = 'LOAD_PASSWORDS_ACTION';
  _exports.LOAD_PASSWORDS_ACTION = LOAD_PASSWORDS_ACTION;
  const UPDATE_DATA_ACTION = 'UPDATE_DATA_ACTION';
  _exports.UPDATE_DATA_ACTION = UPDATE_DATA_ACTION;
  const FAILURE_ACTION = 'FAILURE_ACTION';
  _exports.FAILURE_ACTION = FAILURE_ACTION;
  const SUCCESS_ACTION = 'SUCCESS_ACTION';
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
  const FAILURE_PASSWORDS_ACTION = 'FAILURE_PASSWORDS_ACTION';
  _exports.FAILURE_PASSWORDS_ACTION = FAILURE_PASSWORDS_ACTION;
  const SUCCESS_PASSWORDS_ACTION = 'SUCCESS_PASSWORDS_ACTION';
  _exports.SUCCESS_PASSWORDS_ACTION = SUCCESS_PASSWORDS_ACTION;
  const PASSWORD_INVALID_MESSAGE = 'Password does not meet requirements. ';
  _exports.PASSWORD_INVALID_MESSAGE = PASSWORD_INVALID_MESSAGE;
  const COMMON_VALIDATION_ERROR = 'Password must be a minimum of 3 and no more than 10 characters and can contain both number and letters.';
  _exports.COMMON_VALIDATION_ERROR = COMMON_VALIDATION_ERROR;
});