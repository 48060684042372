define("adt-wss/pods/components/dashboard/account/billing-reminders/component", ["exports", "adt-wss-common/classes/status-message", "adt-wss-common/mixins/with-fsm", "adt-wss-common/classes/validator", "ember-copy", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/state-comparator", "adt-wss/pods/components/dashboard/account/billing-reminders/constants", "adt-wss/pods/components/dashboard/account/communication-preferences/constants"], function (_exports, _statusMessage, _withFsm, _validator, _emberCopy, _emberHelpers, _myadtHelpers, _stateComparator, _constants, _constants2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.determOptTypeEvent = void 0;
  const validator = new _validator.default({
    emails: {
      isArray: true,
      label: index => "Email ".concat(index),
      rules: ['isEmail']
    }
  }, {
    messageCustomizer: (value, label) => "".concat(label, ": ").concat(value)
  });
  const fsmModel = {
    scrollToTop: false,
    transitions: {
      [_constants.IDLE_STATE]: {
        [_constants.UPDATE_DATA_ACTION]: _constants.UPDATING_STATE,
        [_constants.UPDATE_EMAIL_ACTION]: _constants.UPDATING_STATE,
        [_constants.UPDATE_PHONE_ACTION]: _constants.UPDATING_STATE
      },
      [_constants.UPDATING_STATE]: {
        [_constants.REJECT_ACTION]: _constants.IDLE_STATE,
        [_constants.RESOLVE_ACTION]: _constants.IDLE_STATE
      }
    }
  };
  const defaultProps = {
    emails: [],

    updateBillingReminders() {
      console.error('Please pass updatebillingReminders method to dashboard/account/billing-reminders component');
    },

    updateBillingConsents() {
      console.error('Please pass updateBillingConsents method to dashboard/account/billing-reminders component');
    }

  };
  /**
   * Determines the type of the event of changing opt status and calls a corresponding callback
   *
   * @function
   * @param {Boolean} optedIn - previous status of opt
   * @param {Object[]} emails
   * @param {String} emails[].pending
   * @param {String} emails[].email
   * @returns {Boolean}
   */

  const determOptTypeEvent = (optedIn, emails, {
    optInEventHandler = () => {},
    optOutEventHandler = () => {}
  }) => {
    const filteredEmails = emails.filter(e => !e.deleted);

    if (filteredEmails.some(e => e.pending !== null)) {
      return false;
    }

    if (optedIn && filteredEmails.length === 0) {
      return optOutEventHandler();
    }

    if (!optedIn && filteredEmails.length > 0) {
      return optInEventHandler();
    }

    return false;
  };
  /**
   * @module Components/Dashboard/Account/BillingReminders
   * @prop {Object[]} emails
   * @prop {PmocPhoneConsentsObject} phoneConsents
   * @prop {Boolean} opted - indicates if user is opted-in
   * @prop {Array.<{email: String, pending: ?String}>} primaryEmails
   * @prop {Function} updateBillingReminders
   * @example
   * {@lang xml} {{ dashboard/account/billing-reminders
  		emails=[{ email: 'test@test.com', pending: null }]
  		opted=true
  		updateBillingReminders=(action 'update')
  		primaryEmails=[{ email: 'test@test.com', pending: null }]
  }}
   */


  _exports.determOptTypeEvent = determOptTypeEvent;

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, (0, _emberHelpers.initializeObjects)({
    emailsForEditing: []
  }), {
    fsmModel,
    validator,
    tagName: '',
    // no default div around the view of component
    currentState: _constants.IDLE_STATE,
    mwd: Ember.inject.service('modal-window-dispatcher'),
    IS_UPDATING_STATE: (0, _emberHelpers.isState)(_constants.UPDATING_STATE),
    errors: null,
    primaryEmail: Ember.computed.alias('primaryEmails.0'),
    consented: (0, _emberHelpers.computedApplyFunction)(pc => pc ? pc.phone1BillingTextConsent || pc.phone2BillingTextConsent : false, 'phoneConsents'),

    init(...args) {
      this._super(...args);

      const {
        mwd,
        primaryEmail,
        emails
      } = this.getProperties('mwd', 'primaryEmail', 'emails');

      if (emails.length) {
        const otherEmails = emails.filter(e => e.email !== primaryEmail.email);
        this.set('emails', otherEmails);
      }

      this.addContact = this.addContact.bind(this);
      this.get('validator').onValidate(({
        messages,
        values
      }) => {
        mwd.setProperty('errors', messages);
        mwd.setProperty('messages', values);
      });
    },

    /**
     * Updates data if data has passed the validation
     * Calls `/api/v2/auth/pmoc` endpoint
     *
     * @function
     * @param {Object} data
     * @param {Object[]} data.emails
     * @param {String} data.emails[].pending
     * @param {String} data.emails[].email
     * @returns {Promise}
     */
    [_constants.UPDATE_DATA_ACTION]({
      emails: originalEmails,
      primaryEmailsSubscription,
      phoneConsents,
      emailsSchema
    } = {
      emails: [],
      primaryEmailsSubscription: false,
      phoneConsents: {}
    }) {
      const {
        fsm,
        opted,
        primaryEmail
      } = this.getProperties('fsm', 'opted', 'primaryEmail');
      let emails = originalEmails;

      if (primaryEmail) {
        const subEmails = emails.filter(e => e.email !== primaryEmail.email);
        emails = [primaryEmail].concat(subEmails);
      }

      return (0, _stateComparator.compareStatesEquality)(originalEmails, () => this.updateBillingReminders(emails, {
        primaryEmailsSubscription
      }), emailsSchema).then((0, _stateComparator.compareStatesEquality)(phoneConsents, data => this.updateBillingConsents(data))).then(({
        stub
      } = {
        stub: false
      }) => {
        this.closeWindowHandler();
        return fsm.dispatch(_constants.RESOLVE_ACTION, stub, true);
      }).then(() => determOptTypeEvent(opted, emails, {
        optInEventHandler: () => this.sendOptTypeTrackingEvents(true),
        optOutEventHandler: () => this.sendOptTypeTrackingEvents(false)
      }));
    },

    /**
     * Shows successful message of updating
     * Triggered on `UPDATE_DATA_ACTION`, `UPDATE_EMAIL_ACTION`, `UPDATE_PHONE_ACTION`
     *
     * @function
     */
    [_constants.RESOLVE_ACTION](stub) {
      if (stub) {
        this.alert([new _statusMessage.default(_constants2.SUCCESSFULLY_UPDATED_MESSAGE, 'success')]);
      }
    },

    /**
     * Updates data if data has passed the validation
     * Calls `/api/v2/auth/pmoc` endpoint
     *
     * @function
     * @param {Object} data
     * @param {Object[]} data.emails
     * @param {String} data.emails[].pending
     * @param {String} data.emails[].email
     * @returns {Promise}
     */
    [_constants.UPDATE_EMAIL_ACTION]({
      emails,
      primaryEmailsSubscription
    } = {
      emails: [],
      primaryEmailsSubscription: false
    }) {
      const {
        fsm,
        opted,
        primaryEmail
      } = this.getProperties('fsm', 'opted', 'primaryEmail');

      if (primaryEmail) {
        const subEmails = emails.filter(e => e.email !== primaryEmail.email);
        emails = [primaryEmail].concat(subEmails);
      }

      return this.updateBillingReminders(emails, {
        primaryEmailsSubscription
      }).then(() => fsm.dispatch(_constants.RESOLVE_ACTION, null, true)).then(() => determOptTypeEvent(opted, emails, {
        optInEventHandler: () => this.sendOptTypeTrackingEvents(true),
        optOutEventHandler: () => this.sendOptTypeTrackingEvents(false)
      }));
    },

    /**
     * Updates data if data has passed the validation
     * Calls `/api/v2/auth/pmoc` endpoint
     *
     * @function
     * @param {Object} data
     * @param {Object[]} data.emails
     * @param {String} data.emails[].pending
     * @param {String} data.emails[].email
     * @returns {Promise}
     */
    [_constants.UPDATE_PHONE_ACTION](phoneConsents) {
      return this.updateBillingConsents(phoneConsents).then(() => this.get('fsm').dispatch(_constants.RESOLVE_ACTION, null, true));
    },

    /**
     * Resets state (checkboxes, selects, etc.) of the modal window
     * Used before closing of the modal window
     * Triggered on `closeWindowHandler`
     *
     * @function
     */
    resetModalWindowState() {
      const mwd = this.get('mwd');
      mwd.setProperty('errors', null);
      mwd.setProperty('messages', null);
    },

    /**
     * Handler for closening of the window
     * Triggered on `RESOLVE_ACTION`, `openModalWindow` action
     *
     * @function
     */
    closeWindowHandler() {
      this.get('mwd').hide();
      this.resetModalWindowState();
    },

    /**
     * Addes an empty element (email) for `emailsForEditing` field
     * Updates `body.emails` prop
     *
     * @function
     */
    addContact() {
      this.get('emailsForEditing').pushObject({
        email: '',
        pending: null
      });
    },

    /**
     * Subscribes only primary emails
     * Calls `UPDATE_EMAIL_ACTION` with primary emails argument.
     *
     * @function
     * @returns {Promise}
     */
    subscribePrimaryEmails() {
      const {
        primaryEmails,
        emails,
        fsm
      } = this.getProperties('primaryEmails', 'emails', 'fsm');

      if (Array.isArray(primaryEmails) && primaryEmails.filter(obj => obj.email !== '').length > 0) {
        return fsm.dispatch(_constants.UPDATE_EMAIL_ACTION, {
          emails,
          primaryEmailsSubscription: true
        });
      }

      return Promise.resolve(this.actions.openModalWindow.call(this));
    },

    /**
     * Subscribes only primary emails
     * Calls `UPDATE_DATA_ACTION` with primary emails argument.
     *
     * @function
     * @returns {Promise}
     */
    subscribePrimaryPhone() {
      const {
        phoneConsents,
        fsm
      } = this.getProperties('phoneConsents', 'fsm');

      if (phoneConsents.phone1 && phoneConsents.phone1Type === 'Mobile') {
        Ember.set(phoneConsents, 'phone1BillingTextConsent', true);
        return fsm.dispatch(_constants.UPDATE_PHONE_ACTION, phoneConsents).catch(results => {
          this.alert((0, _myadtHelpers.genericErrorHandler)(results).map(a => new _statusMessage.default(a, 'error')));
          return fsm.dispatch(_constants.REJECT_ACTION);
        });
      }

      return Promise.resolve(this.actions.openModalWindow.call(this));
    },

    /**
     * Sends GA tracking
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     * @returns {undefined}
     */
    sendOptTypeTrackingEvents(adding) {
      if (adding) {
        return (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Preferences', 'Opt-in Billing Click', 'Billing Opt-in']);
      }

      return (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Preferences', 'Opt-out Billing Click', 'Billing Opt-out']);
    },

    actions: {
      /**
       * Opens modal window
       *
       * @function
       */
      openModalWindow() {
        const {
          emails: origEmails,
          primaryEmail
        } = this.getProperties('emails', 'primaryEmail');

        if (origEmails.length) {
          const em = origEmails.filter(e => e.email !== primaryEmail.email);
          this.set('emails', em);
        }

        const emailsForEditing = (0, _emberCopy.copy)(this.get('emails'), true) || [];
        const {
          mwd,
          fsm
        } = this.getProperties('mwd', 'fsm');
        const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);

        const closeModal = () => this.closeWindowHandler();

        const onEnter = () => {
          const emails = this.get('emailsForEditing');
          const {
            validator: val,
            newPhoneConsents: phoneConsents
          } = this.getProperties('validator', 'newPhoneConsents');
          const validationFailed = val.validate({
            emails: (emails || []).filter(i => !i.deleted).map(i => i.email)
          });

          if (validationFailed) {
            return false;
          }

          return fsm.dispatch(_constants.UPDATE_DATA_ACTION, {
            emails,
            phoneConsents,
            emailsSchema: (0, _stateComparator.getSchema)(emails)
          }).catch(results => showErrors(results, _constants.REJECT_ACTION));
        };

        this.set('emailsForEditing', (0, _stateComparator.generateStateSchemaMiddleware)(emailsForEditing));
        this.set('newPhoneConsents', (0, _stateComparator.generateStateSchemaMiddleware)((0, _emberCopy.copy)(this.get('phoneConsents'), true)));
        mwd.show({
          title: 'Billing',
          name: 'preferences/billing-reminders',
          body: this,
          onClose: closeModal,
          onEnter,
          isFooterLine: false,
          disclaimer: this.get('showPhoneFields') ? _constants2.DISCLAIMER_MESSAGE : null,
          bigCallback: true,
          actions: [{
            caption: 'Cancel',
            stl: 'outline',
            callback: closeModal
          }, {
            caption: 'Save',
            name: 'save',
            callback: onEnter
          }]
        });
      }

    }
  });

  _exports.default = _default;
});