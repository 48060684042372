define("adt-wss/pods/components/dashboard/account/security-question/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SUCCESSFULLY_UPDATED_MESSAGE = _exports.GENERIC_ERROR_MESSAGE = _exports.REJECT_ACTION = _exports.RESOLVE_ACTION = _exports.UPDATE_DATA_ACTION = _exports.CANCEL_EDIT_MODE_ACTION = _exports.SET_EDIT_MODE_ACTION = _exports.UPDATING_STATE = _exports.EDIT_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const EDIT_STATE = 'EDIT_STATE';
  _exports.EDIT_STATE = EDIT_STATE;
  const UPDATING_STATE = 'UPDATING_STATE';
  _exports.UPDATING_STATE = UPDATING_STATE;
  const SET_EDIT_MODE_ACTION = 'SET_EDIT_MODE_ACTION';
  _exports.SET_EDIT_MODE_ACTION = SET_EDIT_MODE_ACTION;
  const CANCEL_EDIT_MODE_ACTION = 'CANCEL_EDIT_MODE_ACTION';
  _exports.CANCEL_EDIT_MODE_ACTION = CANCEL_EDIT_MODE_ACTION;
  const UPDATE_DATA_ACTION = 'UPDATE_DATA_ACTION';
  _exports.UPDATE_DATA_ACTION = UPDATE_DATA_ACTION;
  const RESOLVE_ACTION = 'RESOLVE_ACTION';
  _exports.RESOLVE_ACTION = RESOLVE_ACTION;
  const REJECT_ACTION = 'REJECT_ACTION';
  _exports.REJECT_ACTION = REJECT_ACTION;
  const GENERIC_ERROR_MESSAGE = 'Generic.error';
  _exports.GENERIC_ERROR_MESSAGE = GENERIC_ERROR_MESSAGE;
  const SUCCESSFULLY_UPDATED_MESSAGE = 'Success! Your information has been updated.';
  _exports.SUCCESSFULLY_UPDATED_MESSAGE = SUCCESSFULLY_UPDATED_MESSAGE;
});