define("adt-wss/pods/components/dashboard/account/monitoring-consent/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers", "adt-wss/pods/components/dashboard/account/monitoring-consent/constants"], function (_exports, _withFsm, _emberHelpers, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    updateMonitoringInfoConsent() {
      console.error('Please pass updateMonitoringInfoConsent method to dashboard/account/billing-reminders component');
    }

  };
  const defSharingPreferenceData = {
    monitoringInfoConsent: false
  };
  /**
   * @module Components/Dashboard/Account/MinoringConsent
   * @prop {Boolean} opted - indicates if user is opted-in
   * @prop {Function} updateMonitoringInfoConsent
   * @example
   * {@lang xml} {{ dashboard/account/billing-reminders
  		emails=[{ email: 'test@test.com', pending: null }]
  		opted=true
  		updateBillingReminders=(action 'update')
  		primaryEmails=[{ email: 'test@test.com', pending: null }]
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, (0, _emberHelpers.initializeObjects)({
    emailsForEditing: []
  }), {
    tagName: '',
    // no default div around the view of component
    currentState: _constants.IDLE_STATE,
    contactData: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    IS_UPDATING_STATE: (0, _emberHelpers.isState)(_constants.UPDATING_STATE),
    errors: null,
    opted: false,

    init(...args) {
      this._super(...args);

      this.opted = this.contactData.sharingPreferenceData ? this.contactData.sharingPreferenceData.monitoringInfoConsent : defSharingPreferenceData.monitoringInfoConsent;
    },

    /**
     * Subscribes only primary emails
     * Calls `UPDATE_EMAIL_ACTION` with primary emails argument.
     *
     * @function
     * @returns {Promise}
     */
    subscribePrimaryEmails() {
      return {};
    },

    actions: {
      consentYesClicked() {
        this.updateMonitoringInfoConsent(true);
        this.set('opted', true);
      },

      consentNoClicked() {
        this.updateMonitoringInfoConsent(false);
        this.set('opted', false);
      }

    }
  });

  _exports.default = _default;
});