define("adt-wss/pods/components/dashboard/manage/add-report/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.QUARTERLY_PERIOD = _exports.MONTHLY_PERIOD = _exports.WEEKLY_PERIOD = _exports.CLOSE_MODAL_WINDOW_ACTION = _exports.UPDATE_DATA_ACTION = _exports.GO_BACK_ACTION = _exports.NEXT_ACTION = _exports.START_ADDING_REPORT_ACTION = _exports.UPDATING_STATE = _exports.FINAL_STATE = _exports.REPORT_ADJUSTMENT_STATE = _exports.LOCATIONS_LIST_STATE = void 0;
  const LOCATIONS_LIST_STATE = 'LOCATIONS_LIST_STATE';
  _exports.LOCATIONS_LIST_STATE = LOCATIONS_LIST_STATE;
  const REPORT_ADJUSTMENT_STATE = 'REPORT_ADJUSTMENT_STATE';
  _exports.REPORT_ADJUSTMENT_STATE = REPORT_ADJUSTMENT_STATE;
  const FINAL_STATE = 'FINAL_STATE';
  _exports.FINAL_STATE = FINAL_STATE;
  const UPDATING_STATE = 'UPDATING_STATE';
  _exports.UPDATING_STATE = UPDATING_STATE;
  const START_ADDING_REPORT_ACTION = 'START_ADDING_REPORT_ACTION';
  _exports.START_ADDING_REPORT_ACTION = START_ADDING_REPORT_ACTION;
  const NEXT_ACTION = 'NEXT_ACTION';
  _exports.NEXT_ACTION = NEXT_ACTION;
  const GO_BACK_ACTION = 'GO_BACK_ACTION';
  _exports.GO_BACK_ACTION = GO_BACK_ACTION;
  const UPDATE_DATA_ACTION = 'UPDATE_DATA_ACTION';
  _exports.UPDATE_DATA_ACTION = UPDATE_DATA_ACTION;
  const CLOSE_MODAL_WINDOW_ACTION = 'CLOSE_MODAL_WINDOW_ACTION';
  _exports.CLOSE_MODAL_WINDOW_ACTION = CLOSE_MODAL_WINDOW_ACTION;
  const WEEKLY_PERIOD = 7;
  _exports.WEEKLY_PERIOD = WEEKLY_PERIOD;
  const MONTHLY_PERIOD = 30;
  _exports.MONTHLY_PERIOD = MONTHLY_PERIOD;
  const QUARTERLY_PERIOD = 90;
  _exports.QUARTERLY_PERIOD = QUARTERLY_PERIOD;
});