define("adt-wss/pods/dashboard/alarm/system/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/9C0BPjx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"widgets-row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"dashboard-alarm-system-info-wrapper\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"dashboard/alarm/alarm-system-info\",null,[[\"hasPulse\"],[[24,[\"model\",\"hasPulse\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"widgets-row bottom-widgets-row\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/alarm/system/index/template.hbs"
    }
  });

  _exports.default = _default;
});