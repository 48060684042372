define("adt-wss/helpers/plus-one", ["exports", "adt-wss-common/helpers/plus-one"], function (_exports, _plusOne) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _plusOne.default;
    }
  });
});