define("adt-wss/pods/components/stupid/amount-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.parseDollarToFloat = void 0;

  /**
   * Formats a value using fixed-point notation
   *
   * @param {Number|String} amount
   * @return {Number}
   */
  const parseDollarToFloat = amount => parseFloat(amount || 0).toFixed(2);
  /**
   * @module Components/Dashboard/Account/BillingAddress
   * @prop {String} value
   * @prop {Boolean} error
   * @prop {Boolean} focus
   * @prop {Function} onKeyUp
   * @example
   * {@lang xml} {{ stupid/amount-input
  		onKeyUp=(action 'onKeyUp')
  		value=item.amount
  		error=error
  		focus=focus
  }}
   */


  _exports.parseDollarToFloat = parseDollarToFloat;

  var _default = Ember.Component.extend({
    classNames: ['amount-input'],
    classNameBindings: ['error:error'],

    didRender() {
      if (this.focus) {
        document.querySelector("#".concat(this.elementId, " input")).focus();
      }
    },

    actions: {
      parseDollarToFloat(amount) {
        this.set('value', parseDollarToFloat(amount));
      }

    }
  });

  _exports.default = _default;
});