define("adt-wss/templates/modal-windows/loyalty-offer-contract-renewal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yBjFNzPd",
    "block": "{\"symbols\":[\"instruction\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"contract-renewal-modal\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"title\"],[8],[0,\"Customer Loyalty Discount\"],[9],[0,\"\\n\\t\"],[7,\"p\",true],[10,\"class\",\"details\"],[8],[0,\"\\n\\t\\tYou can renew your contract online for either \"],[1,[28,\"compute\",[[28,\"action\",[[23,0,[]],[24,[\"body\",\"concatYearDuration\"]]],null],[24,[\"body\",\"offers\"]],\"stringDurationInYears\"],null],false],[0,\" years\\n\\t\\tto receive a \"],[7,\"span\",true],[8],[1,[28,\"compute\",[[28,\"action\",[[23,0,[]],[24,[\"body\",\"concatParams\"]]],null],[24,[\"body\",\"offers\"]],\"creditAmount\"],null],false],[9],[0,\" credit to your ADT account number \"],[1,[24,[\"body\",\"accountNumber\"]],false],[0,\".\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"title\"],[8],[0,\"Renewal Steps\"],[9],[0,\"\\n\\t\"],[7,\"ol\",true],[10,\"class\",\"instructions-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"body\",\"CONTRACT_RENEWAL_INSTRUCTIONS\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"li\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[23,1,[\"text\"]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/loyalty-offer-contract-renewal.hbs"
    }
  });

  _exports.default = _default;
});