define("adt-wss/pods/components/dashboard/manage/add-report/schematics", ["exports", "adt-wss/pods/components/dashboard/manage/add-report/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reportSchema = _exports.reportAdjustmentFormSchema = void 0;
  const reportAdjustmentFormSchema = {
    frequencies: {
      [_constants.WEEKLY_PERIOD]: {
        label: 'Weekly',
        days: [{
          value: 'mondays',
          label: 'Monday'
        }, {
          value: 'tuesdays',
          label: 'Tuesday'
        }, {
          value: 'wednesdays',
          label: 'Wednesday'
        }, {
          value: 'thursdays',
          label: 'Thursday'
        }, {
          value: 'fridays',
          label: 'Friday'
        }]
      },
      [_constants.MONTHLY_PERIOD]: {
        label: 'Monthly',
        days: [{
          value: '1',
          label: '1st of the month'
        }, {
          value: '15',
          label: '15th of the month'
        }]
      },
      [_constants.QUARTERLY_PERIOD]: {
        label: 'Quarterly',
        days: [{
          value: '1',
          label: '1st of the month'
        }, {
          value: '15',
          label: '15th of the month'
        }]
      }
    },
    eventTypes: [{
      value: 'notifications',
      label: 'Notifications',
      description: ''
    }, {
      value: 'openClose',
      label: 'Open Close',
      description: ''
    }, {
      value: 'exceptions',
      label: 'Exceptions',
      description: ''
    }, {
      value: 'other',
      label: 'Other',
      description: ''
    }]
  };
  _exports.reportAdjustmentFormSchema = reportAdjustmentFormSchema;
  const reportSchema = {
    addressIds: [],
    sendEmailOnDays: [],
    eventTypes: [],
    name: '',
    email: [''],
    days: String(_constants.QUARTERLY_PERIOD)
  };
  _exports.reportSchema = reportSchema;
});