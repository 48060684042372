define("adt-wss/pods/components/dashboard/account/insurance-discount-widget/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/classes/status-message", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/http-resources", "adt-wss-common/utils/common-fsm-state"], function (_exports, _withFsm, _myadtHelpers, _statusMessage, _emberHelpers, _httpResources, _commonFsmState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Components/Dashboard/Account/InsuranceDiscountWidget
   * @prop {String|Number} customerNumber - Primary system customer number
   * @example
   * {@lang xml} {{ dashboard/account/insurance-discount-widget}}
   */
  var _default = Ember.Component.extend(_withFsm.default, _commonFsmState.commonMixin, {
    coiInfo: Ember.inject.service(),
    myadtSecurity: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    classNames: ['insurance-discount-widget', 'widget-section'],
    customerNumber: 0,
    errors: null,
    isResidentialCustomer: (0, _emberHelpers.computedApplyFunction)(siteType => ['R', 'Q'].includes(siteType), 'currentLocation.profileInfo.address.siteType'),
    isCommercialCustomer: Ember.computed.equal('currentLocation.profileInfo.address.siteType', 'C'),

    [_commonFsmState.FETCH_DATA_ACTION]() {
      this.resetStatusMessage();
      return this.deliverData('systemInformation.allSystemsForSite', systemInformation => systemInformation.getAllSystemsForSite()).then(() => this.deliverDataAndDispatch('systemInformation.selectedSystem', sysInfo => sysInfo.getSelectedSystem()));
    },

    [_commonFsmState.SUCCESS_ACTION](system) {
      if (system && system.csNo && system.inService) {
        this.set('csNo', system.csNo);
      } else if (this.get('currentLocation.permissions.coi')) {
        this.showStatusMessage('COI.systemsNotFound', 'error', 'insurance-discount-widget');
        console.error("".concat(this._namespace, " - does not have \"csNo\""));
      }
    },

    showStatusMessage(message, type) {
      return this.set('errors', [new _statusMessage.default(message, type)]);
    },

    resetStatusMessage() {
      this.set('errors', null);
    },

    actions: {
      generateCertificate() {
        const id = this.get('csNo');
        const csrUser = this.get('authInfo.authInfo.csrUser');

        if (!id) {
          return;
        }

        const searchId = sessionStorage.getItem('chudlySearchId');

        const url = _httpResources.myADT.getCOICertificate.url.concat(csrUser ? "?searchId=".concat(searchId) : '');

        const eventTagInfo = {
          eventTypeId: 'CERTOINS',
          subEventTypeId: 'VIEW'
        };
        this.get('ET').sendEventTag(eventTagInfo);
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CertificateOfInstallation', 'RequestCertificate']);
        this.coiInfo.populateCertificate({
          id
        }).then(() => {
          window.open(url);
          (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CertificateOfInstallation', 'GenerateCertificate']);
        }).catch(error => {
          console.error('Certificate generation error', error);
        });
      }

    }
  });

  _exports.default = _default;
});