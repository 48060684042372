define("adt-wss/pods/components/index/meta-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    classNames: ['meta-header', 'hide-mobile'],
    actions: {
      invalidate() {
        if (this.get('session.isAuthenticated')) {
          this.get('session').invalidate(true, {
            message: 'Logout from meta-header'
          });
        }
      }

    }
  });

  _exports.default = _default;
});