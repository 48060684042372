define("adt-wss/pods/index/3gconversion/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ThreeGConversionRoute extends Ember.Route {
    model() {
      const uint = localStorage.myADT_system_names || '';
      return {
        uint: uint ? ".uint=".concat(uint) : ''
      };
    }

  }

  _exports.default = ThreeGConversionRoute;
});