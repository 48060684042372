define("adt-wss/pods/components/dashboard/account/contract-documents/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/common-fsm-state", "adt-wss/services/app-dispatcher", "adt-wss-common/classes/status-message"], function (_exports, _withFsm, _myadtHelpers, _commonFsmState, _appDispatcher, _statusMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.detectAvailableDateView = _exports.defaultProps = void 0;
  const defaultProps = {
    documents: [],
    showAlert: v => v
  };
  _exports.defaultProps = defaultProps;
  const OPEN_DOCUMENT_ACTION = 'OPEN_DOCUMENT_ACTION';
  const fsmModel = (0, _commonFsmState.fsmExtend)(_commonFsmState.IDLE_STATE, {
    [OPEN_DOCUMENT_ACTION]: _commonFsmState.IDLE_STATE
  });
  /**
   * Determines available value for date of document item
   *
   * @param {String} value - Date of document
   * @returns {String}
   */

  const detectAvailableDateView = value => {
    const date = Date.createDate(value);
    const UNAVAILABLE_DATE = new Date(2016, 0);

    if (value == null || date <= UNAVAILABLE_DATE) {
      return 'Date not available';
    }

    return "".concat(date.getMonthString(), " ").concat(date.getDate(), ", ").concat(date.getFullYear());
  };
  /**
   * @module Components/Dashboard/Account/ContractDocuments
   * @prop {String} elementId - ID attribute for div component wrapper
   * @prop {String} style - HTML Atribute
   * @prop {Object[]} documents - List of documents
   * @example
   * {@lang xml} {{ dashboard/account/contract-documents
  		documents=[]
  		showAlert=(action 'setMessages')
  }}
   */


  _exports.detectAvailableDateView = detectAvailableDateView;

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, _commonFsmState.commonMixin, {
    accountData: Ember.inject.service(),
    userProfile: Ember.inject.service(),
    appDispatcher: Ember.inject.service(),
    myadtSecurity: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    endDate: null,
    showEndDate: false,
    hideContractDocuments: false,
    classNames: ['contract-documents-widget', 'widget-section'],
    fsmModel,

    init(...args) {
      this._super(...args);

      this.appDispatcher.subscribe(_appDispatcher.HIDE_CONTRACT_RENEWAL_ALERT, this, _appDispatcher.HIDE_CONTRACT_RENEWAL_ALERT);
    },

    willDestroyElement() {
      this.appDispatcher.unsubscribe(_appDispatcher.HIDE_CONTRACT_RENEWAL_ALERT, this, _appDispatcher.HIDE_CONTRACT_RENEWAL_ALERT);
    },

    parseDate(date) {
      const newDate = Date.createDate(date);
      return newDate.toAltString('abbr', true, 'full');
    },

    [_appDispatcher.HIDE_CONTRACT_RENEWAL_ALERT]({
      forceDocumentsRequest: forceRequest
    } = {
      forceDocumentsRequest: false
    }) {
      this.fsm.dispatch(_commonFsmState.FETCH_DATA_ACTION, forceRequest);
    },

    [_commonFsmState.FETCH_DATA_ACTION](forceRequest) {
      const {
        accountData,
        fsm
      } = this.getProperties('accountData', 'fsm');

      const renewalCheck = () => {
        if (this.accountData.renewalDocumentPendingAlert && !forceRequest) {
          const successMsg = {
            head: "You renewed your contract on ".concat(this.accountData.renewalDocumentPendingAlert.createdDate, "."),
            text: "Contract has been emailed to ".concat(this.accountData.renewalDocumentPendingAlert.email, ".\n\t\t\t\t\t\t\t\tPlease allow 24 hours for your new contract to display below.")
          };
          this.showAlert([new _statusMessage.default(successMsg, 'success')]);
        }
      };

      if (!accountData.customerDocList) {
        accountData.getAccountDocumentsByType('contracts').then(data => fsm.dispatch(_commonFsmState.SUCCESS_ACTION, data)).then(renewalCheck).catch(results => fsm.dispatch(_commonFsmState.FAILURE_ACTION, results));
        return;
      }

      return fsm.dispatch(_commonFsmState.SUCCESS_ACTION, accountData.contracts).then(renewalCheck);
    },

    [_commonFsmState.SUCCESS_ACTION](contracts) {
      const {
        accountData
      } = this.getProperties('accountData');
      this.set('documents', contracts.contracts);
      this.set('endDate', contracts && contracts.contractMsg && Boolean(parseInt(contracts.contractMsg, 10)) ? this.parseDate(contracts.contractMsg) : false);

      if (accountData.hideContractDocuments) {
        this.set('hideContractDocuments', true);
      }

      return contracts;
    },

    [OPEN_DOCUMENT_ACTION]({
      url,
      docType
    }) {
      window.open(url);
      this.get('accountData').sendDocumentAnalyticEvent(docType);
    },

    actions: {
      detectAvailableDateView,

      endDateLinkClicked() {
        this.set('showEndDate', true);
        const eventTagInfo = {
          eventTypeId: 'VCONTEDT',
          subEventTypeId: 'VIEW'
        };
        this.get('ET').sendEventTag(eventTagInfo);
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'My Web Profile', 'Contract End Date Viewed']);
        const pypemsg = [{
          attribute: 'message',
          label: 'message',
          value: 'Contract End Date',
          visible: 'wpm'
        }];
        Pypestream('config', {
          passthrough: JSON.stringify(pypemsg)
        });
        console.log('Pypestream msg sent: contract end date');
      }

    }
  });

  _exports.default = _default;
});