define("adt-wss/pods/components/dashboard/shop/adhesive-kit-checkout/component", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/fp", "adt-wss/pods/components/dashboard/shop/battery-checkout/component", "adt-wss/pods/components/dashboard/shop/adhesive-kit-checkout/constants"], function (_exports, _emberHelpers, _withFsm, _myadtHelpers, _fp, _component, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Components/Dashboard/Shop/AdhesiveKitCheckout
   * Checkout form, processes user's order of adhesive kit
   * @example {{dashboard/shop/adhesive-kit-checkout orderDetails=model	shipping=model.shippingOptionSelected	currentState='CONFIRMATION_STATE'}}
   * @prop {orderDetails} product order details
   */
  var _default = _component.default.extend(_withFsm.default, {
    orderData: Ember.inject.service(),
    profileInfo: Ember.computed.oneWay('adhesiveKitOrderData.profileInfo'),

    [_constants.FETCH_PRICE_DATA_ACTION]() {
      const shipping = this.get('shipping');
      return this.get('orderData').getAdhesiveKitsOrderTaxes({
        shippingOption: shipping
      });
    },

    [_constants.SUBMIT_ORDER_ACTION]() {
      const {
        fsm,
        adhesiveKitOrderData,
        shipping
      } = this.getProperties('fsm', 'adhesiveKitOrderData', 'shipping');
      const {
        quantityValue
      } = adhesiveKitOrderData.orderDetails;
      const systemInfoList = adhesiveKitOrderData.systemInfoList[0];
      return this.get('orderData').postAdhesiveKitsOrderPlacement({
        csNo: systemInfoList.csNo,
        shippingOptionSelected: shipping,
        orderQuantity: +quantityValue,
        systemId: systemInfoList.systemId
      }, adhesiveKitOrderData.orderDetails).then(data => {
        fsm.dispatch(_constants.SUCCESS_ACTION, data, true);
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Products', 'Adhesive Kit Order', quantityValue, shipping]);
      }).catch(results => fsm.dispatch(FAILURE_ACTION, results, true));
    },

    [_constants.SUCCESS_ACTION]() {
      this.get('router').transitionTo('dashboard.shop.checkout.kitthankyou');
    },

    [_constants.SUCCESS_PRICE_DATA_ACTION](data) {
      const orderDetails = this.get('adhesiveKitOrderData.orderDetails');
      this.set('adhesiveKitOrderData.orderDetails', (0, _fp.omix)(data, {
        quantityValue: orderDetails.quantityValue
      }));
    }

  });

  _exports.default = _default;
});