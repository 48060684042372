define("adt-wss/services/api-service", ["exports", "adt-wss-common/services/api-service", "adt-wss/config/environment"], function (_exports, _apiService, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _apiService.default.extend({
    csrInfo: Ember.inject.service(),
    router: Ember.inject.service(),

    init() {
      this.config = _environment.default;

      if (/\/ps\//.test(document.location.href)) {
        this.config.apiURL += 'ps/';
      }

      this._super(...arguments);
    },

    isPS: false,
    httpSessionId: null,
    clientId: null,

    /**
     * Redirects to index route if response is 401, 403, or 407
     */
    unauthRedirect() {
      return this.combined.redirectToLandingPage().then(results => {
        const url = results.data;
        const isCsr = this.get('csrInfo.isCsr');

        if (isCsr) {
          // Scenario 1: CSR Agent
          this.windowRedirect("".concat(_environment.default.rootURL, "auth/csr/dashboard.jsp"));
        } else if (results.success && url) {
          // Scenario 2: Normal Customer and Redirect URL returned from Backend.
          const route = url.replace(_environment.default.rootURL, '').replace('/', '.');
          this.get('router').transitionTo(route, 'unauthorized');
        } else {
          // Scenario 3: Fallback - All the above has failed...go to Home/Login page.
          this.get('router').transitionTo('index', 'unauthorized');
        }
      });
    }

  });

  _exports.default = _default;
});