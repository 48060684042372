define("adt-wss/pods/dashboard/alarm/system/systemidentification/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      system: ''
    },
    systemInformation: Ember.inject.service(),

    model(params) {
      let {
        csno
      } = params;

      if (/[&#?]/.test(params.csno)) {
        csno = params.csno.split(/[&#?]/)[0];
      }

      if (!/^[\d]+$/.test(this.get('queryParams.system'))) {
        this.set('queryParams.system', '');
      }

      this.get('systemInformation').setSelectedSystemByCsNo(csno);
      return /^~?[0-9a-z]+$/i.test(csno) ? csno : null;
    }

  });

  _exports.default = _default;
});