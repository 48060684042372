define("adt-wss/pods/components/csr-header/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CANCEL_ACTION = _exports.REJECT_ACTION = _exports.RESOLVE_END_IMPERSONATION_ACTION = _exports.END_IMPERSONATION_ACTION = _exports.RESOLVE_DELETE_ACCOUNT_ACTION = _exports.SUBMIT_DELETE_ACCOUNT_ACTION = _exports.START_DELETE_ACCOUNT_ACTION = _exports.RESOLVE_REGISTRATION_ACTION = _exports.SUBMIT_REGISTRATION_ACTION = _exports.START_REGISTRATION_ACTION = _exports.RESOLVE_EXISITING_REGISTRATION_ACTION = _exports.SUBMIT_EXISTING_REGISTRATION_ACTION = _exports.RESOLVE_IMPERSONATION_ACTION = _exports.SUBMIT_IMPERSONATION_ACTION = _exports.SHOW_ENROLLMENTS_ACTION = _exports.SUBMITTING_DELETE_ACCOUNT_STATE = _exports.DELETE_ACCOUNT_STATE = _exports.SUBMITTING_END_IMPERSONATION_STATE = _exports.SUBMITTING_REGISTRATION_STATE = _exports.SUBMITTING_EXISTING_REGISTRATION_STATE = _exports.REGISTRATION_STATE = _exports.SUBMITTING_IMPERSONATION_STATE = _exports.SHOW_ENROLLMENTS_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const SHOW_ENROLLMENTS_STATE = 'SHOW_ENROLLMENTS_STATE';
  _exports.SHOW_ENROLLMENTS_STATE = SHOW_ENROLLMENTS_STATE;
  const SUBMITTING_IMPERSONATION_STATE = 'SUBMITTING_IMPERSONATION_STATE';
  _exports.SUBMITTING_IMPERSONATION_STATE = SUBMITTING_IMPERSONATION_STATE;
  const REGISTRATION_STATE = 'REGISTRATION_STATE';
  _exports.REGISTRATION_STATE = REGISTRATION_STATE;
  const SUBMITTING_EXISTING_REGISTRATION_STATE = 'SUBMITTING_EXISTING_REGISTRATION_STATE';
  _exports.SUBMITTING_EXISTING_REGISTRATION_STATE = SUBMITTING_EXISTING_REGISTRATION_STATE;
  const SUBMITTING_REGISTRATION_STATE = 'SUBMITTING_REGISTRATION_STATE';
  _exports.SUBMITTING_REGISTRATION_STATE = SUBMITTING_REGISTRATION_STATE;
  const SUBMITTING_END_IMPERSONATION_STATE = 'SUBMITTING_END_IMPERSONATION_STATE';
  _exports.SUBMITTING_END_IMPERSONATION_STATE = SUBMITTING_END_IMPERSONATION_STATE;
  const DELETE_ACCOUNT_STATE = 'DELETE_ACCOUNT_STATE';
  _exports.DELETE_ACCOUNT_STATE = DELETE_ACCOUNT_STATE;
  const SUBMITTING_DELETE_ACCOUNT_STATE = 'SUBMITTING_DELETE_ACCOUNT_STATE';
  _exports.SUBMITTING_DELETE_ACCOUNT_STATE = SUBMITTING_DELETE_ACCOUNT_STATE;
  const SHOW_ENROLLMENTS_ACTION = 'SHOW_ENROLLMENTS_ACTION';
  _exports.SHOW_ENROLLMENTS_ACTION = SHOW_ENROLLMENTS_ACTION;
  const SUBMIT_IMPERSONATION_ACTION = 'SUBMIT_IMPERSONATION_ACTION';
  _exports.SUBMIT_IMPERSONATION_ACTION = SUBMIT_IMPERSONATION_ACTION;
  const RESOLVE_IMPERSONATION_ACTION = 'RESOLVE_IMPERSONATION_ACTION';
  _exports.RESOLVE_IMPERSONATION_ACTION = RESOLVE_IMPERSONATION_ACTION;
  const SUBMIT_EXISTING_REGISTRATION_ACTION = 'SUBMIT_EXISTING_REGISTRATION_ACTION';
  _exports.SUBMIT_EXISTING_REGISTRATION_ACTION = SUBMIT_EXISTING_REGISTRATION_ACTION;
  const RESOLVE_EXISITING_REGISTRATION_ACTION = 'RESOLVE_EXISITING_REGISTRATION_ACTION';
  _exports.RESOLVE_EXISITING_REGISTRATION_ACTION = RESOLVE_EXISITING_REGISTRATION_ACTION;
  const START_REGISTRATION_ACTION = 'START_REGISTRATION_ACTION';
  _exports.START_REGISTRATION_ACTION = START_REGISTRATION_ACTION;
  const SUBMIT_REGISTRATION_ACTION = 'SUBMIT_REGISTRATION_ACTION';
  _exports.SUBMIT_REGISTRATION_ACTION = SUBMIT_REGISTRATION_ACTION;
  const RESOLVE_REGISTRATION_ACTION = 'RESOLVE_REGISTRATION_ACTION';
  _exports.RESOLVE_REGISTRATION_ACTION = RESOLVE_REGISTRATION_ACTION;
  const START_DELETE_ACCOUNT_ACTION = 'START_DELETE_ACCOUNT_ACTION';
  _exports.START_DELETE_ACCOUNT_ACTION = START_DELETE_ACCOUNT_ACTION;
  const SUBMIT_DELETE_ACCOUNT_ACTION = 'SUBMIT_DELETE_ACCOUNT_ACTION';
  _exports.SUBMIT_DELETE_ACCOUNT_ACTION = SUBMIT_DELETE_ACCOUNT_ACTION;
  const RESOLVE_DELETE_ACCOUNT_ACTION = 'RESOLVE_DELETE_ACCOUNT_ACTION';
  _exports.RESOLVE_DELETE_ACCOUNT_ACTION = RESOLVE_DELETE_ACCOUNT_ACTION;
  const END_IMPERSONATION_ACTION = 'END_IMPERSONATION_ACTION';
  _exports.END_IMPERSONATION_ACTION = END_IMPERSONATION_ACTION;
  const RESOLVE_END_IMPERSONATION_ACTION = 'RESOLVE_END_IMPERSONATION_ACTION';
  _exports.RESOLVE_END_IMPERSONATION_ACTION = RESOLVE_END_IMPERSONATION_ACTION;
  const REJECT_ACTION = 'REJECT_ACTION';
  _exports.REJECT_ACTION = REJECT_ACTION;
  const CANCEL_ACTION = 'CANCEL_ACTION';
  _exports.CANCEL_ACTION = CANCEL_ACTION;
});