define("adt-wss/pods/components/ps/myadt-login-pic/component", ["exports", "adt-wss-common/utils/localization", "adt-wss-common/utils/myadt-helpers", "adt-wss/pods/components/ps/myadt-login-pic/constants", "adt-wss-common/mixins/with-fsm"], function (_exports, _localization, _myadtHelpers, constants, _withFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    IDLE_STATE,
    FETCHING_ACCOUNT_STATE,
    LOCATE_ACTION,
    RESOLVE_LOCATE_ACTION,
    REJECT_LOCATE_ACTION
  } = constants;
  const fsmModel = {
    transitions: {
      [IDLE_STATE]: {
        [LOCATE_ACTION]: FETCHING_ACCOUNT_STATE
      },
      [FETCHING_ACCOUNT_STATE]: {
        [RESOLVE_LOCATE_ACTION]: IDLE_STATE,
        [REJECT_LOCATE_ACTION]: IDLE_STATE
      }
    }
  };
  /**
   * Account Registration Component
   * @module Components/MyADTLoginPic
   * @prop {String} sitePhone primary phone number, used for location lookup
   * @prop {String} pic Verbal Security Password / Personal Identification Code
   *
   * @prop {String} currentState for StateMachine
   * @prop {Boolean} isFetching
   * @prop {String} chatID generated by chat window
   * @prop {String[]} errorMessages
   * @prop {Boolean} sitePhoneError toggles error state on form field
   * @prop {Boolean} picError toggles error state on form field
   *
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    classNames: ['register-form'],
    registrationService: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    fsmModel,
    currentState: IDLE_STATE,
    sitePhone: '',
    pic: '',
    chatID: '',
    errorMessages: null,
    sitePhoneError: false,
    picError: false,
    rejectCount: 0,
    authToken: Ember.computed.readOnly('registrationService.authToken'),
    isFetching: Ember.computed('currentState', function () {
      const state = this.get('currentState');
      return /ing/i.test(state);
    }),

    /**
     * Invokes `registrationService.verifyLocation`.
     * Validation included
     * dispatches `RESOLVE_LOCATE_ACTION` or `REJECT_LOCATE_ACTION`
     * TODO: separate validation and implement like {@link Components/AddLocation.validateLocateAction}
     */
    [LOCATE_ACTION]() {
      // eslint-disable-next-line prefer-const
      let {
        sitePhone,
        pic,
        chatID
      } = this.getProperties('sitePhone', 'pic', 'chatID');
      let errors = [];
      this.setProperties({
        sitePhoneError: false,
        picError: false,
        errorMessages: null
      });
      sitePhone = sitePhone.trim();
      pic = pic.trim();
      chatID = window.sessionStorage.getItem("pypestream-webchat-chat-id");

      if (!sitePhone || !pic) {
        if (!sitePhone) this.set('sitePhoneError', true);
        if (!pic) this.set('picError', true);
        return this.get('fsm').dispatch(REJECT_LOCATE_ACTION, {
          errors: ['General.errorAllFieldsRequired']
        });
      }

      if (sitePhone && !this.validateRegisterPhoneNum(sitePhone)) {
        errors.push('Contacts.invalidPhoneNumber');
        this.set('sitePhoneError', true);
      }

      if (pic && (pic.length > 10 || pic.length < 3)) {
        errors.push('CommonPassword.errorPasswordLength');
        this.set('picError', true);
      }

      if (errors.length) {
        return this.get('fsm').dispatch(REJECT_LOCATE_ACTION, {
          errors
        });
      }

      return this.get('registrationService').verifyLocation({
        phoneNo: sitePhone,
        pic,
        chatID
      }).then(data => {
        if (!data || !data.siteList.length) {
          if (!errors.length) errors = ['Site not eligible to authenticate'];
          this.set('rejectCount', 4);
          return this.get('fsm').dispatch(REJECT_LOCATE_ACTION, {
            errors
          });
        }

        if (data.siteList.length) {
          const tokenVal = this.get('authToken');
          const trimToken = tokenVal.substr(7);
          const detail = {
            picData: {
              'myadtToken': trimToken,
              'siteList': data.siteList
            }
          };
          window.top.postMessage(detail, '*');
          return this.get('fsm').dispatch(RESOLVE_LOCATE_ACTION, data);
        }
      }).catch(results => {
        return this.get('fsm').dispatch(REJECT_LOCATE_ACTION, results);
      });
    },

    /**
     * Advances after locations are retrieved.
     * If only one location, dispatches `SELECT_LOCATION_ACTION`.
     * @param {Object} data
     */
    [RESOLVE_LOCATE_ACTION](data) {
      this.set('isFetching', true);
      window.setTimeout(function () {
        if (/www.myadt|\/\/myadt.com/i.test(document.location.href)) {
          window.location.replace('https://webchat.pypestream.com/redirect.html');
        } else {
          window.location.replace('https://webchat-sandbox.pypestream.com/redirect.html');
        }
      }, 1000);
    },

    /**
     * Pass-through, riggers `handleErrors`
     * @param {ApiResponse} results
     */
    [REJECT_LOCATE_ACTION](results) {
      this.incrementProperty('rejectCount');
      this.handleErrors(results);
    },

    /**
     * Displays error messages and error states
     * @param {Object} results
     * @param {String[]|String} errors
     */
    handleErrors(results) {
      this.set('errorMessages', (0, _myadtHelpers.genericErrorHandler)(results));

      if (this.get('rejectCount') >= 4) {
        const detail = {
          picError: {
            'myadtError': (0, _myadtHelpers.genericErrorHandler)(results)
          }
        };
        window.top.postMessage(detail, '*');
        window.setTimeout(function () {
          if (/www.myadt|\/\/myadt.com/i.test(document.location.href)) {
            window.location.replace('https://webchat.pypestream.com/redirect.html');
          } else {
            window.location.replace('https://webchat-sandbox.pypestream.com/redirect.html');
          }
        }, 1000);
      }
    },

    validateRegisterPhoneNum(phone) {
      const updatedPhone = (0, _myadtHelpers.removePhoneMask)(phone);
      return !!updatedPhone && !/^[01]/.test(updatedPhone) && updatedPhone.length === 10;
    },

    actions: {
      /**
       * locating the accounts
       */
      locate() {
        this.get('fsm').dispatch(LOCATE_ACTION);
      },

      /**
       * Pass-through to {@link Classes/StateMachine.dispatch}
       * @param {String} action
       * @param {*} params
       */
      dispatch(action, params, scroll) {
        this.get('fsm').dispatch(action, params, scroll);
      },

      /**
       * Validates phone numbers using `validateRegisterPhoneNumber` with less strict criteria
       * @param {Int|String} phone
       * @return {Boolean}
       */
      validatePhone(phone) {
        let ret = true;
        if (!phone) ret = false;

        if (phone && !this.validateRegisterPhoneNum(phone)) {
          this.set('errorMessages', [(0, _localization.toExpandedString)('Contacts.invalidPhoneNumber')]);
          ret = false;
        } else {
          this.set('errorMessages', null);
        }

        this.set('sitePhoneError', !ret);
        return ret;
      },

      /**
       * Makes sure pic is 3-10 characters
       * @param {Int|String} phone
       * @return {Boolean}
       */
      validatePic(pic) {
        let ret = true;

        if (!pic || pic.length > 10 || pic.length < 3) {
          ret = false;
        }

        this.set('picError', !ret);
        return ret;
      }

    },

    init() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});