define("adt-wss/instance-initializers/clearDsm", ["exports", "adt-wss/services/app-dispatcher"], function (_exports, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.Route.reopen({
      dsm: Ember.inject.service('dashboard-status-messages'),
      mwd: Ember.inject.service('modal-window-dispatcher'),
      actions: {
        willTransition(transition) {
          if (this.get('routeName') !== transition.targetName) {
            this.clearDSMandHideModal();
          }
        }

      },

      clearDSMandHideModal() {
        this.get('dsm').clear();
        this.get('mwd').hide();
      },

      activate() {
        this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'clearDSMandHideModal');
      },

      deactivate() {
        this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'clearDSMandHideModal');
      }

    });
  }

  var _default = {
    name: 'clearDsm',
    initialize
  };
  _exports.default = _default;
});