define("adt-wss/templates/modal-windows/thanks-for-feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5FOan/9V",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"class\",\"thanks-for-feedback-title\"],[8],[0,\"Thanks for your feedback\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/thanks-for-feedback.hbs"
    }
  });

  _exports.default = _default;
});