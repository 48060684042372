define("adt-wss/services/registration-service", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/http-resources", "adt-wss-common/utils/fp"], function (_exports, _myadtHelpers, _httpResources, _fp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Account Registration Service
   * @module Services/RegistrationService
   * @prop {RegistrationSite[]} siteList
   */
  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)({
    siteList: null
  }), {
    apiService: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    siteList: null,
    authToken: null,
    // sites that don't have any `unrecoverableRegistrationErrors`
    eligibleSites: Ember.computed('siteList', function () {
      const out = (this.get('siteList') || []).filter(s => !s.unrecoverableRegistrationErrors.length);
      return out;
    }),

    /**
     * Checks for 'unrecoverable errors'
     * TODO remove side effects; move GA events to separate method
     */
    hasUnrecoverableErrors: Ember.computed('siteList', function () {
      let hasErrors = false;
      let has420Error = false;
      const errorSites = (this.get('siteList') || []).filter(s => s.unrecoverableRegistrationErrors.length);

      if (errorSites.length) {
        hasErrors = true;
        errorSites.forEach(site => {
          site.unrecoverableRegistrationErrors.forEach(regError => {
            has420Error = regError.includes('ManageAccount.error420CustomerCannotRegister');

            _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Error Messages', '/registration.jsp', regError]);
          });
        });
      }

      if (has420Error) {
        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Registration', 'Registration error', '420 customer error message shown']);
      }

      return hasErrors;
    }),

    /**
     * Location lookup, returns siteList and other data
     * @param {Object} params
     * @param {String} params.sitePhone
     * @param {String} params.securityPassword
     * @param {Boolean} params.siteUsageChecked
     * @param {Boolean} params.chatID
     * @param {String|Int} [params.customerNumber]
     */
    checkAffiliations(params) {
      return this.get('apiService').request({
        resource: _httpResources.myADT.registrationCheckAffiliations,
        data: params
      }).then(({
        data
      }) => {
        if (data) {
          data.siteRegistrations = (data.siteRegistrations || []).map(site => {
            site.isEligible = true;
            site.canView = true;

            if (site.unrecoverableRegistrationErrors && site.unrecoverableRegistrationErrors.length) {
              site.isEligible = false;

              if (site.unrecoverableRegistrationErrors.find(e => e === 'Registration.errorInvalidPic')) {
                site.canView = false;
              }
            }

            return site;
          });
          this.set('siteList', data.siteRegistrations);
        }

        return data;
      });
    },

    /**
     * PIC and phone number login
     * @param {Object} params
     * @param {String} params.phoneNo
     * @param {String} params.pic
     * @param {Boolean} params.chatID
     */
    verifyLocation(params) {
      return this.get('apiService').request({
        resource: _httpResources.myADT.psLoginPic,
        data: params
      }).then(({
        data
      }) => {
        if (data) {
          const token = this.get('apiService.authorizationToken');
          this.set('authToken', token);

          if (window.Pypestream) {
            window.Pypestream('config', {
              passthrough: {
                attribute: 'psAuthToken',
                label: 'psAuthToken',
                value: token,
                visible: 'none'
              }
            });
          }
        }

        return data;
      });
    },

    /**
     * Registers new user account
     * @param {Object} data
     * @param {String} data.firstName
     * @param {String} data.lastName
     * @param {String} data.sitePhone
     * @param {String} data.emailAddress
     * @param {String} data.confirmEmailAddress
     * @param {String} data.securityPassword
     * @param {Int|String} data.customerNumber
     * @param {String} data.password
     * @param {String} data.confirmPassword
     * @param {Int} data.question1
     * @param {String} data.secretAnswer1
     * @param {Boolean} data.siteUsageChecked
     * @param {RegistrationSite[]} data.siteRegistrations
     * @param {String} data.uuid
     */
    registerUser(data) {
      return this.get('apiService').request({
        resource: _httpResources.myADT.registerUser,
        data
      }).then(resp => this.handleRegSuccess(resp));
    },

    /**
     * Adds new user to existing account
     * @param {Object} data
     * @param {String} data.firstName
     * @param {String} data.lastName
     * @param {String} data.sitePhone
     * @param {String} data.emailAddress
     * @param {String} data.confirmEmailAddress
     * @param {String} data.securityPassword
     * @param {Int|String} data.customerNumber
     * @param {String} data.password
     * @param {String} data.confirmPassword
     * @param {Int} data.question1
     * @param {String} data.secretAnswer1
     * @param {Boolean} data.siteUsageChecked
     * @param {RegistrationSite[]} data.siteRegistrations
     * @param {String} data.uuid
     */
    registerAddUser(data) {
      return this.get('apiService').request({
        resource: _httpResources.myADT.registerAddUser,
        data
      }).then(resp => this.handleRegSuccess(resp));
    },

    addLocation(data) {
      const omissions = ['isEligible', 'canView'];
      data.siteRegistrations = data.siteRegistrations.map(sr => (0, _fp.omit)(sr, omissions));
      return this.get('apiService').request({
        resource: _httpResources.myADT.addLocation,
        data
      }).then(response => response.data);
    },

    handleRegSuccess({
      data
    }) {
      const siteRegistrations = data.registrationDetail.siteRegistrations;

      _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Registration', 'Registration Complete']);

      if (siteRegistrations.length) {
        const firstSite = siteRegistrations[0];

        if (firstSite.firstTimeRegistrationForCustNo) {
          _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Registration', 'Customer Since Date', "Customer Since: ".concat(_myadtHelpers.default.formatDate(firstSite.customerSince))]);
        }
      }

      return data;
    }

  });

  _exports.default = _default;
});