define("adt-wss/resolver", ["exports", "ember-resolver", "ember-cli-hot-loader/mixins/hot-reload-resolver", "adt-wss/config/environment"], function (_exports, _emberResolver, _hotReloadResolver, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let CustomHotReloadMixin = {};

  if (_environment.default.environment === 'fastdev' || _environment.default.environment === 'fastwithcssmap') {
    CustomHotReloadMixin = Ember.Mixin.create(_hotReloadResolver.default, {
      shouldExcludeComponent({
        name
      }) {
        const excludedFromConfig = this._super(...arguments);

        const isBootstrapComponent = name.startsWith('bs-');
        return excludedFromConfig || isBootstrapComponent;
      }

    });
  }

  var _default = _emberResolver.default.extend(CustomHotReloadMixin);

  _exports.default = _default;
});