define("adt-wss/pods/components/stupid/time-of-day-greeting/component", ["exports", "adt-wss-common/utils/ember-helpers"], function (_exports, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const setGreeting = d => {
    const date = d instanceof Date ? d : new Date();
    const timeOfDay = date.getHours();

    if (timeOfDay >= 20 && timeOfDay < 24) {
      return 'Hello';
    } else if (timeOfDay >= 17 && timeOfDay < 20) {
      return 'Good Evening';
    } else if (timeOfDay >= 12 && timeOfDay < 17) {
      return 'Good Afternoon';
    }

    return 'Good Morning';
  };

  var _default = Ember.Component.extend({
    date: null,
    greeting: (0, _emberHelpers.computedApplyFunction)(d => setGreeting(d), 'date'),

    init() {
      this._super(...arguments);

      if (!this.get('date')) this.set('date', new Date());
    }

  });

  _exports.default = _default;
});