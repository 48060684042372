define("adt-wss/pods/components/dashboard/account/payment-details-confirmation/component", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss/pods/components/dashboard/account/one-time-payment/component"], function (_exports, _emberHelpers, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    paymentDate: new Date()
  };
  const achTypes = {
    C: 'Checking',
    S: 'Savings'
  };
  /**
   * @module Components/Dashboard/Account/PaymentDetailsConfirmation
   * @prop {Date} paymentDate
   * @prop {String} paymentAmount
   * @prop {String} accountNumber
   * @prop {String} accountType
   * @prop {Boolean} updating
   * @example
   * {@lang xml} {{ dashboard/account/payment-details-confirmation
  		paymentAmount=body.data.amount
  		accountNumber=(or body.data.account.accountNumber body.data.account.number)
  		accountType=(or body.data.account.accountType body.data.account.type)
  		paymentDate=body.data.paymentDate
  		updating=body.isUpdatingState
  }}
   */

  var _default = Ember.Component.extend(defaultProps, {
    classNames: ['payment-details-confirmation'],
    processingType: (0, _emberHelpers.computedApplyFunction)(t => Boolean(achTypes[t]), 'accountType'),
    maskedAccountNumber: (0, _emberHelpers.computedApplyFunction)((number, type, processing) => {
      if (number) {
        return (processing ? "".concat(achTypes[type], " Account Number ") : '').concat((0, _component.maskAcctNum)(number, 4));
      }

      return null;
    }, 'accountNumber', 'accountType', 'processingType'),
    isEditState: false,

    setAmount() {
      this.set('isEditState', false);
      return this.updateAmount(...arguments);
    },

    actions: {
      renderPaymentIcon: _component.renderPaymentIcon,

      toggleAmountEditState() {
        this.toggleProperty('isEditState');
      }

    }
  });

  _exports.default = _default;
});