define("adt-wss/pods/dashboard/index/route", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/route-helpers"], function (_exports, _myadtHelpers, _appDispatcher, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    myadtSecurity: Ember.inject.service(),
    contactData: Ember.inject.service(),
    billingData: Ember.inject.service(),
    promotionData: Ember.inject.service(),
    appointmentData: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    systemInformation: Ember.inject.service(),
    userProfile: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),

    activate() {
      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    deactivate() {
      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      this.isRefresh = true;
      this.refresh();
    },

    model() {
      const deliverDataOptions = {
        forceRequest: this.isRefresh
      };
      const contactDeliverData = (0, _routeHelpers.makeDeliverData)(this, 'contactData');
      const promotionsDeliverData = (0, _routeHelpers.makeDeliverData)(this, 'promotionData');
      const systemInformationDeliverData = (0, _routeHelpers.makeDeliverData)(this, 'systemInformation');
      const userProfileDeliverData = (0, _routeHelpers.makeDeliverData)(this, 'userProfile');
      const billInfo = Ember.RSVP.Promise.resolve(this.get('billingData.billingModel.billInfo'));
      const billingOptions = Ember.RSVP.Promise.resolve(this.get('billingData.billingModel.billingOptions'));
      const wn = Ember.RSVP.Promise.resolve({
        title: 'ADT GO Peace of Mind Everywhere Life Takes You',
        description: 'ADT Go is the app for families and friends on the go. It lets everyone enjoy reliable ADT monitoring and stay connected as they go about theit busy day.',
        link: 'https://adt.com',
        poster: '../images/v2/temp/smartphones.png',
        iframelink: 'https://emberjs.com'
      });
      const permissions = Ember.RSVP.Promise.resolve(this.get('contactData.currentLocation.permissions'));
      const profileInfo = Ember.RSVP.Promise.resolve(this.get('contactData.currentLocation.profileInfo'));
      const emergencyContacts = contactDeliverData('addlContacts', contactData => contactData.getContacts());
      const siteNumbers = contactDeliverData('sitePhoneNumbers', contactData => contactData.getSiteNumbers());
      const systemInfoData = systemInformationDeliverData('systemInfoListFull', systemInformation => systemInformation.getStandardAndSystemInfoLists(), deliverDataOptions).then(list => (list || [])[0] || {});
      const sweepstakeSubmitted = Ember.RSVP.Promise.resolve(this.get('promotionData.sweepStakeSubmitted'));
      const userProfileData = userProfileDeliverData('userProfileData', userProfile => userProfile.getProfileData(), deliverDataOptions);
      return Ember.RSVP.hashSettled({
        billInfo,
        billingOptions,
        wn,
        siteNumbers,
        emergencyContacts,
        sweepstakeSubmitted,
        systemInfoData,
        profileInfo,
        userProfileData,
        permissions
      }).then(response => {
        this.isRefresh = false;
        return (0, _routeHelpers.useResolvedOnly)(response);
      }).catch(error => console.error(error));
    },

    actions: {
      willTransition() {
        this.set('authInfo.newRegistration', false);
      },

      fireTag(params) {
        _myadtHelpers.default.sendAnalyticEvent(params.split(','));
      }

    },
    showWidgets: Ember.computed('myadtSecurity.picChallengeVisible', function () {
      return !this.get('myadtSecurity.picChallengeVisible');
    })
  });

  _exports.default = _default;
});