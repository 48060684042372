define("adt-wss/pods/dashboard/account/payments/controller", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss-common/classes/status-message"], function (_exports, _myadtHelpers, _emberHelpers, _statusMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.docLinkTmpl = void 0;
  const docLinkTmpl = '/api/v2/auth/bill/statement_v2/';
  _exports.docLinkTmpl = docLinkTmpl;

  var _default = Ember.Controller.extend({
    dsm: Ember.inject.service('dashboard-status-messages'),
    billingData: Ember.inject.service(),
    router: Ember.inject.service(),
    impersonationData: Ember.inject.service(),
    mmbIncidents: Ember.inject.service(),
    billingStatusMessages: Ember.computed.alias('dsm.statusMessages'),
    sendAnalyticEvent: _myadtHelpers.sendAnalyticEvent,
    flexfiData: Ember.inject.service(),
    blockedByPending: (0, _emberHelpers.computedApplyFunction)(function (blocked, sm) {
      if (blocked) {
        const msg = new _statusMessage.default('Payment.paymentRequestAlreadyExists', {
          type: 'error',
          static: true
        });
        const arr = sm || [];
        arr.push(msg);
        this.set('billingStatusMessages', arr);
      }

      return blocked;
    }, 'billingData.billingModel.billInfo.oneTimePendingPaymentExist', 'billingStatusMessages'),
    salesForceBilled: (0, _emberHelpers.computedApplyFunction)(function (bs) {
      if (bs === 'SalesForce') {
        return true;
      }

      return false;
    }, 'billingData.billingSystem'),

    viewLatestStatement() {
      const startTime = Date.now();
      const latestStatement = this.get('billingData.billingStatements.0');
      const searchId = typeof sessionStorage !== 'undefined' ? sessionStorage.chudlySearchId || null : null;
      let url = docLinkTmpl + latestStatement.docId;
      if (searchId) url += "?searchId=".concat(searchId);
      this.sendAnalyticEvent(['_trackEvent', 'View Bill', 'ViewBillStatementRequested', "#Statement Date: ".concat(latestStatement.docDateObj.toUSAString())]);
      this.createIncidentOrIssue('WCVSTM', 'Viewed Statement', startTime);
      window.open(url);
    },

    /**
     * Creates MMB Incident
     * calls {@link Services/ImpersonationData.createIncidentOrIssue}
     * @param {String} transactionType
     * @param {String} jobComments
     * @param {Timestamp} startTime
     */
    createIncidentOrIssue(transactionType, jobComments, startTime = new Date()) {
      const headerInfo = this.get('impersonationData.headerInfo');
      const issueData = {
        customerNo: headerInfo ? headerInfo.customer.custNo : 0,
        siteNo: headerInfo ? headerInfo.site.siteNo : 0,
        duration: Date.now() - startTime,
        transactionType,
        jobComments
      };
      return this.get('mmbIncidents').createIncidentOrIssue(issueData);
    },

    actions: {
      dispatch(action, ...params) {
        this[action](...params);
      },

      routeRefreshBilling() {
        this.send('routeRefresh');
      }

    }
  });

  _exports.default = _default;
});