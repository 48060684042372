define("adt-wss/pods/components/csr-header/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/classes/status-message", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss/pods/components/csr-header/constants"], function (_exports, _withFsm, _statusMessage, _myadtHelpers, _emberHelpers, constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    IDLE_STATE,
    SHOW_ENROLLMENTS_STATE,
    SUBMITTING_IMPERSONATION_STATE,
    REGISTRATION_STATE,
    SUBMITTING_REGISTRATION_STATE,
    SUBMITTING_EXISTING_REGISTRATION_STATE,
    SUBMITTING_END_IMPERSONATION_STATE,
    SUBMITTING_DELETE_ACCOUNT_STATE,
    SHOW_ENROLLMENTS_ACTION,
    SUBMIT_IMPERSONATION_ACTION,
    RESOLVE_IMPERSONATION_ACTION,
    SUBMIT_EXISTING_REGISTRATION_ACTION,
    RESOLVE_EXISITING_REGISTRATION_ACTION,
    START_REGISTRATION_ACTION,
    SUBMIT_REGISTRATION_ACTION,
    RESOLVE_REGISTRATION_ACTION,
    SUBMIT_DELETE_ACCOUNT_ACTION,
    RESOLVE_DELETE_ACCOUNT_ACTION,
    END_IMPERSONATION_ACTION,
    RESOLVE_END_IMPERSONATION_ACTION,
    REJECT_ACTION,
    CANCEL_ACTION
  } = constants;
  const fsmModel = {
    transitions: {
      [IDLE_STATE]: {
        [SHOW_ENROLLMENTS_ACTION]: SHOW_ENROLLMENTS_STATE,
        [START_REGISTRATION_ACTION]: REGISTRATION_STATE,
        [END_IMPERSONATION_ACTION]: SUBMITTING_END_IMPERSONATION_STATE,
        [SUBMIT_DELETE_ACCOUNT_ACTION]: SUBMITTING_DELETE_ACCOUNT_STATE
      },
      [SHOW_ENROLLMENTS_STATE]: {
        [SUBMIT_IMPERSONATION_ACTION]: SUBMITTING_IMPERSONATION_STATE,
        [SUBMIT_EXISTING_REGISTRATION_ACTION]: SUBMITTING_EXISTING_REGISTRATION_STATE,
        [START_REGISTRATION_ACTION]: REGISTRATION_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      },
      [SUBMITTING_IMPERSONATION_STATE]: {
        [RESOLVE_IMPERSONATION_ACTION]: IDLE_STATE,
        [REJECT_ACTION]: SHOW_ENROLLMENTS_STATE
      },
      [SUBMITTING_EXISTING_REGISTRATION_STATE]: {
        [RESOLVE_EXISITING_REGISTRATION_ACTION]: IDLE_STATE,
        [REJECT_ACTION]: SHOW_ENROLLMENTS_STATE
      },
      [SUBMITTING_END_IMPERSONATION_STATE]: {
        [RESOLVE_END_IMPERSONATION_ACTION]: IDLE_STATE,
        [REJECT_ACTION]: IDLE_STATE
      },
      [REGISTRATION_STATE]: {
        [SUBMIT_REGISTRATION_ACTION]: SUBMITTING_REGISTRATION_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      },
      [SUBMITTING_REGISTRATION_STATE]: {
        [RESOLVE_REGISTRATION_ACTION]: IDLE_STATE,
        [REJECT_ACTION]: REGISTRATION_STATE
      },
      [SUBMITTING_DELETE_ACCOUNT_STATE]: {
        [RESOLVE_DELETE_ACCOUNT_ACTION]: IDLE_STATE,
        [REJECT_ACTION]: IDLE_STATE
      }
    }
  };
  /**
   * CSR Header Component
   * @module Components/CSRHeader
   * @prop {Object[]} registeredUsers associated email addresses and registration status
   * @prop {String[]} picList
   * @prop {Object} profileInfo
   * @prop {Object} csrHeader
   * @prop {Boolean} showImpersonationActions visibility of drop down at right
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    elementId: 'csrHeader',
    csrInfo: Ember.inject.service(),
    impersonationData: Ember.inject.service(),
    myadtSecurity: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    currentLocation: Ember.inject.service(),
    billingData: Ember.inject.service(),
    expresspayService: Ember.inject.service(),
    currentState: IDLE_STATE,
    isFetching: (0, _emberHelpers.computedApplyFunction)(x => /ing/i.test(x), 'currentState'),
    fsmModel,
    registeredUsers: Ember.computed.oneWay('impersonationData.registeredUsers'),
    isRegisteredUser: Ember.computed.not('currentLocation.permissions.unregisteredUser'),
    picList: (0, _emberHelpers.computedApplyFunction)(l => (l || []).map(y => y.pin).join(', '), 'impersonationData.picList'),
    profileInfo: Ember.computed.alias('currentLocation.profileInfo'),
    csrHeader: Ember.computed.alias('impersonationData.csrHeader'),
    showImpersonationActions: false,
    windowRedirect: _myadtHelpers.windowRedirect,

    /**
     * Shows modal with list of associated emails and registration status.
     * Allows registration or impersonation for each.
     */
    [SHOW_ENROLLMENTS_ACTION]() {
      const {
        mwd,
        fsm,
        registeredUsers
      } = this.getProperties('mwd', 'fsm', 'registeredUsers');
      const {
        address: {
          addressLine1,
          city,
          state
        }
      } = this.get('currentLocation.profileInfo');

      const onClose = () => {
        fsm.dispatch(CANCEL_ACTION);
        mwd.hide();
      };

      mwd.show({
        name: 'csr-enrollments-list',
        title: "Emails for ".concat(addressLine1, ", ").concat(city, ", ").concat(state),
        body: {
          registeredUsers,
          keyList: ['email', 'contactPreferences'],
          pageSizes: [4, 8, 'All'],
          dispatch: (...args) => fsm.dispatch(...args)
        },
        onClose,
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: 'Register New Email',
          callback: () => {
            mwd.hide();
            fsm.dispatch(START_REGISTRATION_ACTION);
          }
        }]
      });
    },

    /**
     * Opens registration modal
     * @prop {Object} [user]
     * @prop {String} [user.email]
     * @prop {String[]} [user.contactPreferences]
     */
    [START_REGISTRATION_ACTION](user) {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);

      const onClose = () => {
        this.get('fsm').dispatch(CANCEL_ACTION);
        mwd.hide();
      };

      const body = {
        email: user ? Ember.get(user, 'email') || null : null,
        emailError: false,
        alarm: user ? Ember.get(user, 'contactPreferences').some(x => /service/i.test(x)) : true,
        billing: user ? Ember.get(user, 'contactPreferences').some(x => /billing/i.test(x)) : true,
        register: true,
        dispatch: (...args) => fsm.dispatch(...args)
      };

      const onEnter = () => {
        const email = body.email ? body.email.trim() : null;
        mwd.clearMessages();

        if (email && email.isValidEmail()) {
          return fsm.dispatch(SUBMIT_REGISTRATION_ACTION, body);
        }

        this.set('value.emailError', true);
        return showErrors({
          errors: 'Please enter a valid email address'
        });
      };

      mwd.show({
        name: 'impersonation-new-registration',
        title: 'Add New Email Address',
        body,
        onClose,
        onEnter,
        isFooterLine: false,
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: 'Save & Continue',
          callback: onEnter
        }]
      });
    },

    /**
     * Starts impersonation of selected user
     * @prop {Object} user
     * @prop {String} user.email
     */
    [SUBMIT_IMPERSONATION_ACTION](user) {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);
      const reqData = {
        credential: user.email
      };
      return this.get('impersonationData').impersonateRegisteredUser(reqData).then(() => fsm.dispatch(RESOLVE_IMPERSONATION_ACTION, user.email)).catch(results => showErrors(results, REJECT_ACTION));
    },

    /**
     * @prop {Object} user
     * @prop {String} user.email
     * @prop {Boolean} user.register
     * @prop {String[]} user.contactPreferences
     */
    [SUBMIT_REGISTRATION_ACTION](user) {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);
      const contactPreferences = [];
      if (user.register) contactPreferences.concat(['Service', 'Billing']);else {
        if (user.alarm) contactPreferences.push('Service');
        if (user.billing) contactPreferences.push('Billing');
      }
      const reqData = {
        isRegistered: false,
        hasEmail: true,
        requestingRegistration: user.register,
        registerMyADT: user.register,
        email: user.email,
        contactPreferences
      };
      return this.get('impersonationData').registerAndEnroll(reqData).then(() => fsm.dispatch(RESOLVE_REGISTRATION_ACTION, user.email)).catch(results => showErrors(results, REJECT_ACTION));
    },

    [RESOLVE_IMPERSONATION_ACTION](email) {
      this.get('mwd').hide();
      return this.set('statusMessages', [new _statusMessage.default("You are now impersonating ".concat(email, "."), 'success', true)]);
    },

    [RESOLVE_REGISTRATION_ACTION](email) {
      const {
        csr
      } = this.get('csrInfo');
      this.get('mwd').hide();
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CSR REGISTRATION', 'CSR Team: ' + csr.team, 'CSR Username: ' + csr.username]);
      return this.set('statusMessages', [new _statusMessage.default("Successfully added new email address: ".concat(email, "."), 'success', true)]);
    },

    /**
     * Ends impersonation of current user
     */
    [END_IMPERSONATION_ACTION]() {
      const fsm = this.get('fsm');
      return this.get('impersonationData').endImpersonation().then(data => fsm.dispatch(RESOLVE_END_IMPERSONATION_ACTION, data)).catch(results => {
        this.handleErrors(results);
        return fsm.dispatch(REJECT_ACTION);
      });
    },

    /**
     * Returns to Chudly dashboard or search after impersonation is ended
     */
    [RESOLVE_END_IMPERSONATION_ACTION](response) {
      const {
        emailImpersonatedInCSRCustomerDashboard
      } = response;
      const rootURL = (0, _myadtHelpers.getOrigin)() + this.get('rootURL').substr(1); // remove double slash

      localStorage.setItem('myADT_showedSweepstakes', false); // allows to show Sweepstakes offering again for another user

      const enrolledInEasyPay = this.get('billingData.billingModel.billingOptions.enrolledInEasyPay');
      const expressPromo = this.get('expresspayService.expressPromo');

      if (enrolledInEasyPay && expressPromo) {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'EasyPay Promotion', 'Return to Chudly', 'Express Pay']);
      }

      if (emailImpersonatedInCSRCustomerDashboard) {
        this.windowRedirect("".concat(rootURL, "/auth/csr/#/dashboard"));
      } else {
        this.windowRedirect("".concat(rootURL, "/auth/csr/#/search"));
      }
    },

    /**
     * Deletes currently impersonated user account
     */
    [SUBMIT_DELETE_ACCOUNT_ACTION]() {
      const {
        fsm,
        profileInfo
      } = this.getProperties('fsm', 'profileInfo');
      return this.get('impersonationData').removeWebUser().then(() => fsm.dispatch(RESOLVE_DELETE_ACCOUNT_ACTION, profileInfo.userName || profileInfo.name)).catch(results => {
        this.handleErrors(results);
        fsm.dispatch(REJECT_ACTION);
      });
    },

    [RESOLVE_DELETE_ACCOUNT_ACTION](username) {
      this.get('mwd').hide();
      return this.set('statusMessages', [new _statusMessage.default("You have removed ".concat(username, " from MyADT"), 'success', true)]);
    },

    /**
     * Implements {@link MyADTHelpers.genericErrorHandler} and sets `statusMessage`
     * @param {ApiResponse} results
     */
    handleErrors(results) {
      const errors = (0, _myadtHelpers.genericErrorHandler)(results);
      this.set('statusMessages', errors.map(m => new _statusMessage.default(m, 'error', true)));
    },

    actions: {
      /**
       * Toggles visibility of drop down
       */
      toggleShowImpersonationActions() {
        this.toggleProperty('showImpersonationActions');
      },

      dispatch(action, data, scroll) {
        this.get('fsm').dispatch(action, data, scroll);
      },

      /**
       * closes drop down after dispatching the action
       * @param {String} action
       * @param {String} [data]
       * @param {Boolean} [scroll]
       */
      dispatchAndClose(action, data, scroll) {
        this.set('showImpersonationActions', false);
        this.get('fsm').dispatch(action, data, scroll);
      }

    }
  });

  _exports.default = _default;
});