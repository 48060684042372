define("adt-wss/pods/components/dashboard/manage/report-item/component", ["exports", "adt-wss-common/utils/ember-helpers"], function (_exports, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    report: {
      view: {}
    }
  };
  /**
   * @module Components/Dashboard/Manage/ReportItem
   * @prop {Object} report
   * @example
   * {@lang xml} {{dashboard/manage/report-item
  		report=report
  }}
   */

  var _default = Ember.Component.extend(defaultProps, {
    tagName: 'section',
    classNames: ['business-report-item'],
    VISIBLE_EMAILS_LIMIT: 2,
    hiddenEmailsNumber: (0, _emberHelpers.computedApplyFunction)((l, e) => e.length - (l - 1), 'VISIBLE_EMAILS_LIMIT', 'report.email')
  });

  _exports.default = _default;
});