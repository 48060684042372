define("adt-wss/pods/components/dashboard/account/profile-info/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss/mixins/on-form-esc-handler", "adt-wss-common/classes/status-message", "adt-wss-common/utils/fp", "adt-wss-common/classes/validator", "adt-wss-common/utils/ember-helpers", "adt-wss/pods/components/dashboard/account/profile-info/constants"], function (_exports, _withFsm, _onFormEscHandler, _statusMessage, _fp, _validator, _emberHelpers, constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CONFIRM_PASSWORD_MESSAGE = _exports.PASSWORD_INVALID_MESSAGE = _exports.PASSWORD_REQUIRED_FOR_CSR = _exports.PASSWORD_EQUAL_TO_EMAIL_MESSAGE = _exports.SUCCESS_ACTION = _exports.FAILURE_ACTION = _exports.UPDATE_DATA_ACTION = _exports.CANCEL_EDIT_MODE_ACTION = _exports.SET_EDIT_MODE_ACTION = _exports.UPDATING_STATE = _exports.EDIT_STATE = _exports.IDLE_STATE = _exports.defaultProperties = void 0;

  const createValidatorInstance = isCsr => new _validator.default({
    email: {
      label: 'Login Email',
      rules: ['isNotEmpty', 'isEmail']
    },
    firstName: {
      label: 'Name',
      rules: ['isNotEmpty']
    },
    lastName: {
      label: 'Last Name',
      rules: ['isNotEmpty']
    },
    currentPassword: {
      label: 'Existing password',
      rules: isCsr ? [] : ['isNotEmpty']
    }
  }, {
    messageCustomizer: (value, label) => "".concat(label, ": ").concat(value)
  });

  const defaultProperties = {
    accountNumber: '012345678',
    primaryLocationNumber: '111-222-3333',
    email: 'youremail@gmail.com',
    firstName: 'Name',
    lastName: 'Lastname',
    password: '',
    confirmPassword: '',
    currentPassword: '',
    editModeAvailable: true,
    alert: v => v,
    setOverlap: () => {},
    submitEdit: () => {
      throw new Error('Please pass submitEdit method to dashboard/account/profile-info component');
    }
  };
  _exports.defaultProperties = defaultProperties;
  const {
    IDLE_STATE,
    EDIT_STATE,
    UPDATING_STATE,
    SET_EDIT_MODE_ACTION,
    CANCEL_EDIT_MODE_ACTION,
    UPDATE_DATA_ACTION,
    FAILURE_ACTION,
    SUCCESS_ACTION,
    PASSWORD_EQUAL_TO_EMAIL_MESSAGE,
    PASSWORD_REQUIRED_FOR_CSR,
    PASSWORD_INVALID_MESSAGE,
    CONFIRM_PASSWORD_MESSAGE
  } = constants;
  _exports.CONFIRM_PASSWORD_MESSAGE = CONFIRM_PASSWORD_MESSAGE;
  _exports.PASSWORD_INVALID_MESSAGE = PASSWORD_INVALID_MESSAGE;
  _exports.PASSWORD_REQUIRED_FOR_CSR = PASSWORD_REQUIRED_FOR_CSR;
  _exports.PASSWORD_EQUAL_TO_EMAIL_MESSAGE = PASSWORD_EQUAL_TO_EMAIL_MESSAGE;
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
  _exports.FAILURE_ACTION = FAILURE_ACTION;
  _exports.UPDATE_DATA_ACTION = UPDATE_DATA_ACTION;
  _exports.CANCEL_EDIT_MODE_ACTION = CANCEL_EDIT_MODE_ACTION;
  _exports.SET_EDIT_MODE_ACTION = SET_EDIT_MODE_ACTION;
  _exports.UPDATING_STATE = UPDATING_STATE;
  _exports.EDIT_STATE = EDIT_STATE;
  _exports.IDLE_STATE = IDLE_STATE;
  const fsmModel = {
    scrollToTop: false,
    transitions: {
      [IDLE_STATE]: {
        [SET_EDIT_MODE_ACTION]: EDIT_STATE
      },
      [EDIT_STATE]: {
        [CANCEL_EDIT_MODE_ACTION]: IDLE_STATE,
        [UPDATE_DATA_ACTION]: UPDATING_STATE
      },
      [UPDATING_STATE]: {
        [FAILURE_ACTION]: EDIT_STATE,
        [SUCCESS_ACTION]: IDLE_STATE
      }
    }
  };
  /**
   * @module Components/Dashboard/Account/ProfileInfo
   * @prop {String} elementId - ID attribute for div component wrapper
   * @prop {String} accountNumber - Account number
   * @prop {String} primaryLocationNumber - Site phone number
   * @prop {String} email - User's login email
   * @prop {String} firstName - User's first name
   * @prop {String} lastName - User's last name
   * @prop {String} password - User's new password
   * @prop {String} confirmPassword - User's new password confirm
   * @prop {String} currentPassword - User's existing password
   * @prop {Boolean} editModeAvailable - Availability of edit mode
   * @prop {Function} submitEdit - Function which save changes
   * @prop {Function} setOverlap - Set value for visibility background overlap
   * @example
   * {@lang xml} {{ dashboard/account/profile-info
  		elementId='dashboardProfileInfo'
  		accountNumber: '012345678 (Pulse)',
  		primaryLocationNumber: '408-505-0269(Emergency Contact)',
  		email: 'CarolSpencer123@gmail.com',
  		firstName: 'Carol',
  		lastName: 'Spencer',
  		password: '',
  		confirmPassword: '',
  		currentPassword: '',
  		editModeAvailable=true
  		submitEdit=(action 'submit')
  		setOverlap=(action 'setOverlap')
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProperties, _onFormEscHandler.default, {
    validator: (0, _emberHelpers.computedApplyFunction)(createValidatorInstance, 'isCsr'),
    ssoInfo: Ember.inject.service(),
    isSsoEnabled: Ember.computed.readOnly('ssoInfo.isSsoEnabled'),
    firstNameError: false,
    elementId: 'dashboardProfileInfo',
    classNames: ['account-widget-section'],
    classNameBindings: ['editMode:active'],
    mwd: Ember.inject.service('modal-window-dispatcher'),
    errors: null,
    messages: null,
    passErrors: null,
    passwordError: false,
    confirmPasswordError: false,
    invalidPassword: false,
    data: null,
    isValid: true,
    fsmModel,
    currentState: IDLE_STATE,
    activeESCState: EDIT_STATE,
    cancelESCState: CANCEL_EDIT_MODE_ACTION,
    editMode: Ember.computed.equal('currentState', EDIT_STATE),
    updating: Ember.computed.equal('currentState', UPDATING_STATE),
    IS_IDLE_STATE: Ember.computed.equal('currentState', IDLE_STATE),
    isEditStateChanged: Ember.observer('editMode', function () {
      this.setOverlap(this.get('editMode'));
    }),
    actions: {
      dispatch(action, data) {
        this.get('fsm').dispatch(action, data);
      },

      validatePassword() {
        this.validatePassword();
      },

      validateEmailCSR() {
        this.validateEmailCSR();
      }

    },

    validateEmailCSR() {
      if (this.get('isCsr')) {
        this.validatePassword();
      }
    },

    validatePassword() {
      const {
        data: {
          password,
          confirmPassword,
          email
        },
        invalidPassword
      } = this.getProperties('data', 'invalidPassword');
      const e = [];
      let isValid = true;
      this.set('passwordError', false);
      this.set('confirmPasswordError', false);
      this.set('passErrors', []);

      if (!password.length && !confirmPassword.length) {
        this.set('isValid', true);

        if (this.get('isCsr') && !this.get('isSsoEnabled')) {
          this.set('passwordError', true);
          this.set('isValid', false);
          this.set('passErrors', [PASSWORD_REQUIRED_FOR_CSR]);
        }
      } else {
        if (invalidPassword) {
          this.set('passwordError', true);
          isValid = false;
          e.push(PASSWORD_INVALID_MESSAGE);
        }

        if (password === email) {
          this.set('passwordError', true);
          isValid = false;
          e.push(PASSWORD_EQUAL_TO_EMAIL_MESSAGE);
        }

        if (password !== confirmPassword) {
          this.set('confirmPasswordError', true);
          isValid = false;
          e.push(CONFIRM_PASSWORD_MESSAGE);
        }

        this.set('isValid', isValid ? true : isValid);
        this.set('passErrors', e);
      }
    },

    [SUCCESS_ACTION](data) {
      const msg = 'Profile successfully updated';
      this.alert([new _statusMessage.default(msg, 'success')]);
      this.statusMessageTimeout();
      this.setProperties({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName
      });
    },

    [FAILURE_ACTION](error) {
      let e;
      if (!error) return true;
      const {
        errors
      } = error;

      if (Array.isArray(errors)) {
        e = errors.map(errorItem => new _statusMessage.default(errorItem, 'error'));
      } else {
        e = [new _statusMessage.default(errors, 'error')];
      }

      this.alert(e);
      return true;
    },

    /**
     * Auto hides status message after timer expires
     * @param {Int} duration defaults to 5000
     */
    statusMessageTimeout(duration) {
      duration = duration || 5000;
      setTimeout(() => {
        this.alert(null);
      }, duration);
    },

    init() {
      this._super(...arguments);

      this.get('validator').onValidate(({
        messages: errors,
        values
      }) => {
        this.set('errors', errors);
        this.set('messages', values);
      });
      this.keyDown = this.onEscHandler.bind(this);
    },

    [SET_EDIT_MODE_ACTION]() {
      this.prepareEdit();
    },

    [CANCEL_EDIT_MODE_ACTION]() {
      const errorProps = this.getProperties(['passwordError', 'confirmPasswordError']);
      this.statusMessageTimeout(1);
      Object.keys(errorProps).forEach(k => this.set(k, false));
      this.set('passErrors', null);
      this.resetAlert();
    },

    [UPDATE_DATA_ACTION](fieldType) {
      if (fieldType === 'password') {
        this.validatePassword();
      } else if (fieldType === 'loginEmail') {
        this.validateEmailCSR();
      }

      const {
        validator: val,
        fsm,
        isValid,
        data
      } = this.getProperties('validator', 'fsm', 'isValid', 'data');
      const validationFailed = val.validate(data);

      if (!validationFailed && isValid) {
        this.submitEdit(data).then(() => fsm.dispatch(SUCCESS_ACTION, data)).catch(errors => fsm.dispatch(FAILURE_ACTION, errors));
      } else {
        fsm.dispatch(FAILURE_ACTION);
      }
    },

    resetAlert() {
      this.set('errors', null);
      this.set('messages', null);
      this.alert(null);
    },

    prepareEdit() {
      const props = this.getProperties(['firstName', 'lastName', 'email', 'confirmPassword', 'currentPassword', 'password']);
      this.set('data', (0, _fp.omix)(props));
    }

  });

  _exports.default = _default;
});