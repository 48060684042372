define("adt-wss/pods/billinghelp/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      const helpId = '308';
      const uint = localStorage.myADT_system_names || '';
      return {
        full: uint ? "".concat(helpId, ".uint=").concat(uint) : helpId,
        helpId
      };
    }

  });

  _exports.default = _default;
});