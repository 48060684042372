define("adt-wss/pods/dashboard/shop/productsservices/products/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    orderData: Ember.inject.service(),
    productsServicesCtrl: Ember.inject.controller('dashboard/shop/productsservices')
  });

  _exports.default = _default;
});