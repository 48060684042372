define("adt-wss/pods/components/dashboard/account/communication-preferences/component", ["exports", "adt-wss-common/utils/route-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss-common/mixins/with-fsm", "adt-wss-common/classes/status-message", "adt-wss-common/utils/fp", "adt-wss/services/app-dispatcher", "adt-wss/pods/components/dashboard/account/monitoring-reports/component", "adt-wss/pods/components/dashboard/account/communication-preferences/constants"], function (_exports, _routeHelpers, _emberHelpers, _withFsm, _statusMessage, _fp, _appDispatcher, _component, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.defaultProps = void 0;
  const {
    timeRangeOptions
  } = _component.defaultProperties;
  const fsmModel = {
    transitions: {
      [_constants.IDLE_STATE]: {
        [_constants.LOAD_DATA_ACTION]: _constants.LOADING_STATE
      },
      [_constants.LOADING_STATE]: {
        [_constants.SUCCESSFUL_LOADING_ACTION]: _constants.IDLE_STATE,
        [_constants.FAILED_LOADING_ACTION]: _constants.IDLE_STATE
      }
    }
  };
  const defaultProps = {
    list: [{
      title: () => 'Billing',
      explanation: 'Receive payment reminders, billing statements, scheduled autopay and payment postings.',
      type: 'billing'
    }, {
      title: () => 'Appointment Reminders',
      explanation: 'Receive scheduled sales, installation and service confirmation, and appointment reminders.',
      type: 'service',
      even: true
    }, {
      title: () => 'Products and Offers',
      explanation: "\n\t\t\t\tBy enabling products and offers text alerts,\n\t\t\t\tI agree that ADT may contact me via text messages, from time to time,\n\t\t\t\tat the primary phone provided using automated technology\n\t\t\t\tand consent is not required to make a purchase.",
      type: 'productsAndOffers'
    }, {
      title: () => 'Travel Reminders',
      explanation: 'Receive reminders to update your emergency contacts.',
      type: 'travel',
      even: true
    }, {
      title: () => 'Monitoring Reports',
      subTitle: ({
        monitoringReports: {
          intervalInMonths: i = 0
        }
      }) => "(".concat(timeRangeOptions.find(o => o.value === parseInt(i, 10)).text, ")"),
      explanation: 'Report emails will inform you whether or not we are seeing activity from your system.',
      type: 'monitoring'
    }],
    email: '',
    paperlessVisible: false,
    alert: v => v
  };
  _exports.defaultProps = defaultProps;
  const defPhoneConsents = {
    phone1: null,
    phone1Type: null,
    phone1BillingTextConsent: false,
    phone1SrvcApptTextConsent: false,
    phone2: null,
    phone2Type: null,
    phone2BillingTextConsent: false,
    phone2SrvcApptTextConsent: false,
    phone1PrdAndOffrTextConsent: false
  };
  /**
   * @module Components/Dashboard/Account/CommunicationPreferences
   * @prop {Object[]} list - List of preferences
   * @prop {String} email - Email address
   * @prop {Boolean} paperlessVisible
   * @prop {Object} types - Object which contains key as a name of type and value as a list of contacts
   * @prop {Function} updateEmail - Function which update email address
   * @prop {Function} alert - Function which called after every update attempt
   * @example
   * {@lang xml} {{ dashboard/account/communication-preferences
  		list=[]
  		email='test@example.com'
  		updateEmail=(action 'updateEmailAction')
  		paperlessVisible=true
  		alert=(action 'setMessages')
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    classNames: ['communication-preferences-widget'],
    contactData: Ember.inject.service(),
    alarmData: Ember.inject.service(),
    billingData: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    fsmModel,
    emails: Ember.computed.readOnly('types.primary'),
    phoneConsents: (0, _emberHelpers.computedApplyFunction)(pc => pc || defPhoneConsents, 'contactData.phoneConsents'),
    billingReminders: Ember.computed.readOnly('types.billing'),
    currentState: _constants.IDLE_STATE,
    initAction: _constants.LOAD_DATA_ACTION,
    SUCCESSFULLY_UPDATED_MESSAGE: _constants.SUCCESSFULLY_UPDATED_MESSAGE,
    GENERIC_ERROR_MESSAGE: _constants.GENERIC_ERROR_MESSAGE,
    IS_LOADING_STATE: Ember.computed.equal('currentState', _constants.LOADING_STATE),
    types: null,
    primaryEmail: Ember.computed('emails', function () {
      const emails = this.get('emails');
      return emails && emails.length ? emails[0] : {
        email: '',
        pending: null
      };
    }),

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    /**
     * Get data for communication preferences.
     * Retrieves '/api/v2/auth/pmoc'
     * Retrieves '/api/v2/auth/history/inactivity'
     * Retrieves '/api/v2/auth/vacation/getAllVacations'
     * Triggered as initial action
     *
     * @function
     * @param {Boolean} forceRequest - option for cache skipping
     * @returns {Promise}
     */
    [_constants.LOAD_DATA_ACTION](forceRequest) {
      const fsm = this.get('fsm');
      const pmocData = this.deliverData('contactData.pmocData', contactData => contactData.getPMOC(), {
        forceRequest
      }).then(data => (0, _fp.omix)(data, {
        paperlessOption: this.get('contactData.paperlessOption')
      }));
      const travelRemindersData = this.deliverData('contactData.travelReminders', contactData => contactData.getTravelReminders(), {
        forceRequest
      });
      const monitoringReportsData = this.deliverData('alarmData.inactivityAlert', alarmData => alarmData.getInactivityAlerts(), {
        forceRequest
      });
      return Ember.RSVP.hashSettled({
        pmocData,
        travelRemindersData,
        monitoringReportsData
      }).then(_routeHelpers.useResolvedOnly).then(data => fsm.dispatch(_constants.SUCCESSFUL_LOADING_ACTION, data)).catch(e => fsm.dispatch(_constants.FAILED_LOADING_ACTION, e));
    },

    /**
     * Converts retrieved data to the proper format and updates `types` property
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     * @param {Object} param - combined data
     * @param {Object} param.pmocData
     * @param {Object} param.travelRemindersData
     * @param {Object} param.monitoringReportsData
     */
    [_constants.SUCCESSFUL_LOADING_ACTION]({
      pmocData,
      travelRemindersData,
      monitoringReportsData
    }) {
      const types = (0, _fp.omix)(pmocData, {
        monitoringReportsData,
        travelRemindersData,
        billingOpted: Boolean(pmocData.billing && pmocData.billing.length),
        serviceOpted: Boolean(pmocData.service && pmocData.service.length),
        monitoringOpted: Boolean(monitoringReportsData && parseInt(monitoringReportsData.intervalInMonths, 10)),
        travelOpted: (travelRemindersData || []).length > 0
      });
      this.set('types', types);
    },

    /**
     * Handles failed loading
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     */
    [_constants.FAILED_LOADING_ACTION](e) {
      console.log('Loading failed', e);
    },

    /**
     * Handles changing of location
     * Triggered on `appDispatcher` service
     *
     * @function
     */
    changeLocationHandler() {
      this.get('fsm').dispatch(_constants.LOAD_DATA_ACTION, true);
    },

    /**
     * Converts payload for PMOC and sends a request via `contactData` service
     * Shows successful message if the promise is resolved and changes state to `LOAD_DATA_ACTION` for data updating.
     * Calls '/api/v2/auth/pmoc' endpoint
     * Triggered on `updatePrimaryEmail`, `deletePrimaryEmail` and `updatePMOCEntity` actions.
     *
     * @function
     * @param {Object} payload
     * @param {Object} options
     * @param {Boolean} options.primaryEmailsSubscription
     * @returns {Promise}
     */
    savePMOCData(payload, options = {
      primaryEmailsSubscription: false
    }) {
      const {
        primaryEmailsSubscription: prmEmailsSubscr
      } = options;
      const {
        fsm,
        contactData,
        types,
        alert: notify
      } = this.getProperties('contactData', 'types', 'alert', 'fsm');
      const message = prmEmailsSubscr ? _constants.PRIMARY_EMAILS_SUCCESSFULLY_SUBSCR_MESSAGE : _constants.SUCCESSFULLY_UPDATED_MESSAGE;
      return contactData.savePMOC({
        pmoc: (0, _fp.omit)((0, _fp.omix)(types, payload), ['travel', 'travelRemindersData', 'monitoring', 'travelOpted', 'serviceOpted', 'monitoringOpted', 'billingOpted', 'monitoringReportsData', 'paperlessOption'])
      }).then(() => {
        notify([new _statusMessage.default(message, 'success')]);
        fsm.dispatch(_constants.LOAD_DATA_ACTION);
      });
    },

    actions: {
      /**
       * Sends a request for changing of `paperless options` via `contactData` service.
       * Calls `/api/v2/auth/pmoc` endpoint
       * Shows successful message if the promise is resolved and changes state to `LOAD_DATA_ACTION` for data updating.
       *
       * @function
       * @param {Object} payload
       * @returns {Promise}
       */
      savePaperlessOption(payload = {}) {
        const {
          contactData,
          alert,
          fsm
        } = this.getProperties('contactData', 'alert', 'fsm');
        const {
          paperlessOption
        } = contactData;
        return contactData.savePMOC({
          paperlessOption: (0, _fp.omix)(paperlessOption, payload)
        }).then(() => {
          alert([new _statusMessage.default(_constants.SUCCESSFULLY_UPDATED_MESSAGE, 'success')]);
          fsm.dispatch(_constants.LOAD_DATA_ACTION);
        });
      },

      /**
       * Calls `contactData.updatePhoneNumbers`
       * method for changing of needed primary phone number.
       *
       * @function
       * @param {Object} phone - reduced `phoneConsents` object
       * @param {String} phone.phone1 - reduced `phoneConsents` object
       * @param {String} phone.phone2 - reduced `phoneConsents` object
       * @param {Boolean} phone.phone1Type - reduced `phoneConsents` object
       * @param {Boolean} phone.phone2Type - reduced `phoneConsents` object
       * @returns {Promise}
       */
      updatePrimaryPhone(phone) {
        return this.contactData.updatePhoneNumbers(phone);
      },

      /**
       * Calls `savePMOCData` method for changing of needed primary email.
       *
       * @function
       * @param {String} email
       * @returns {Promise}
       */
      updatePrimaryEmail(email) {
        const emails = this.get('emails');
        return this.savePMOCData({
          primary: [email].concat(emails.slice(1))
        });
      },

      /**
       * Calls `savePMOCData` method for removing of needed primary email.
       *
       * @function
       * @param {Object} value - object of needed email
       * @returns {Promise}
       */
      deletePrimaryEmail(value) {
        const emails = this.get('emails');
        return this.savePMOCData({
          primary: emails.filter(e => e !== value)
        });
      },

      /**
       * High-Order function
       * Returns function which calls `savePMOCData` method for updating of some PMOC entity
       *
       * @function
       * @param {String} key - key of PMOC entity
       * @returns {Function}
       */
      updatePMOCEntity(key) {
        return (v, options) => this.savePMOCData({
          [key]: v
        }, options);
      },

      /**
       * Calls `contactData.updateBillingConsents`
       * method for changing of text updates for billing information.
       *
       * @function
       * @param {PmocPhoneConsentsObject} phone - reduced `phoneConsents` object
       * @returns {Promise}
       */
      updateBillingConsents(phone) {
        return this.contactData.updateBillingConsents(phone);
      },

      /**
       * Calls `contactData.updateServiceAppointmentConsents`
       * method for changing of text updates for service appointments.
       *
       * @function
       * @param {PmocPhoneConsentsObject} phone - reduced `phoneConsents` object
       * @returns {Promise}
       */
      updateServiceAppointmentConsents(phone) {
        return this.contactData.updateServiceAppointmentConsents(phone);
      },

      /**
       * Calls `contactData.updateProductsAndOffersConsents`
       * method for changing of text updates for products and offers.
       *
       * @function
       * @param {PmocPhoneConsentsObject} phone - reduced `phoneConsents` object
       * @returns {Promise}
       */
      updateProductsAndOffersConsents(data = {}) {
        return this.contactData.updateProductsAndOffersConsents(data).then(() => {
          this.alert([new _statusMessage.default(_constants.PRODUCTS_AND_OFFERS_SUCCESS_MESSAGE, 'success')]);
          this.fsm.dispatch(_constants.LOAD_DATA_ACTION);
        });
      },

      /**
       * Sends a request via `contactData` service for adding a new travel reminder.
       * Calls '/api/v2/auth/vacation/saveVacation' endpoint
       *
       * @function
       * @param {Object} travelReminder
       * @returns {Promise}
       */
      addTravelReminder(travelReminder) {
        const {
          fsm,
          contactData,
          alert: notify
        } = this.getProperties('contactData', 'alert', 'fsm');
        return contactData.addTravelReminder(travelReminder).then(() => {
          notify([new _statusMessage.default(_constants.TRAVEL_REMINDER_ADDED_MESSAGE, 'success')]);
          fsm.dispatch(_constants.LOAD_DATA_ACTION);
        });
      },

      /**
       * Sends a request via `contactData` service for removing a travel reminder.
       * Calls '/api/v2/auth/vacation/deleteVacation' endpoint
       *
       * @function
       * @param {Object} travelReminder
       * @returns {Promise}
       */
      deleteTravelReminder(travelReminder) {
        const {
          fsm,
          contactData,
          alert: notify
        } = this.getProperties('contactData', 'alert', 'fsm');
        return contactData.deleteTravelReminder(travelReminder).then(() => {
          notify([new _statusMessage.default(_constants.TRAVEL_REMINDER_DELETED_MESSAGE, 'success')]);
          fsm.dispatch(_constants.LOAD_DATA_ACTION);
        });
      },

      /**
       * Sends a request via `alrmData` service for updating a monitoring reports.
       * Calls '/api/v2/auth/history/inactivity' endpoint
       *
       * @function
       * @param {Object} monitoringReportsReminder
       * @returns {Promise}
       */
      updateMonitoringReports(monitoringReportsReminder) {
        const {
          alarmData,
          alert: notify,
          fsm
        } = this.getProperties('alarmData', 'alert', 'fsm');
        return alarmData.addInactivityAlert(monitoringReportsReminder).then(() => {
          notify([new _statusMessage.default(_constants.MONITORING_CHANGED_MESSAGE, 'success')]);
          fsm.dispatch(_constants.LOAD_DATA_ACTION);
        });
      }

    }
  });

  _exports.default = _default;
});