define("adt-wss/pods/components/dashboard/emergency-contacts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bLoG7v7v",
    "block": "{\"symbols\":[\"contact\"],\"statements\":[[15,\"widget-section-icon\",[]],[0,\"\\n\"],[7,\"h3\",true],[10,\"class\",\"widget-title\"],[8],[0,\"Emergency contacts\"],[9],[0,\"\\n\"],[7,\"address\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"currentLocation\",\"adtGoOnly\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"h6\",true],[8],[7,\"br\",true],[8],[9],[0,\"To update your contacts use your mobile ADTGo app.\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"primaryPhone\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"strong\",true],[10,\"class\",\"emerg-primary-phone\"],[8],[0,\"Location: \"],[1,[22,\"primaryPhone\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[7,\"dl\",true],[10,\"class\",\"emerg-contacts-phones-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"list\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"dt\",true],[11,\"title\",[29,[[23,1,[\"name\"]]]]],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"dd\",true],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"emerg-contact-phone-item\"],[8],[1,[23,1,[\"number\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showManageLink\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"emerg-contacts-more widget-bottom-btn angle-bracket-link\",\"dashboard.account.contacts\"]],{\"statements\":[[0,\"\\t\\tManage contacts\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/emergency-contacts/template.hbs"
    }
  });

  _exports.default = _default;
});