define("adt-wss/pods/dashboard/manage/businessreporting/controller", ["exports", "adt-wss-common/classes/status-message"], function (_exports, _statusMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Controllers/Dashboard/Manage/BusinessReporting
   * @prop {Object} manage - injected controller
   * @prop {Object} actions
   * @prop {Function} actions.setStatusMessage - Set status message for parent route
   */
  var _default = Ember.Controller.extend({
    currentLocation: Ember.inject.service(),
    manage: Ember.inject.controller('dashboard/manage'),
    businessReportingStatus: Ember.computed.readOnly('currentLocation.profileInfo.businessReportingStatus'),
    actions: {
      /**
       * Set status messages
       *
       * @function
       * @param {String} message
       * @param {String} type
       */
      setStatusMessage(message, type) {
        this.set('manage.dashboardStatusMessages', [new _statusMessage.default(message, type)]);
      }

    }
  });

  _exports.default = _default;
});