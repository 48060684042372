define("adt-wss/pods/index/usageagreement/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KGXj7/ZM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"model\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[15,\"loading-spinner\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"id\",\"usageAgreement\"],[8],[1,[22,\"model\"],true],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "adt-wss/pods/index/usageagreement/template.hbs"
    }
  });

  _exports.default = _default;
});