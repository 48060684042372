define("adt-wss/pods/components/dashboard/alarm/alarm-history/component", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss-common/mixins/with-fsm", "adt-wss/services/app-dispatcher", "jquery", "adt-wss-common/utils/common-fsm-state"], function (_exports, _emberHelpers, _withFsm, _appDispatcher, _jquery, _commonFsmState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.filterResultsBySearch = _exports.defaultProperties = void 0;
  const defaultProperties = {
    systemList: null,
    alarmList: [],
    resultsPerDateOptions: ['Last 24 Hours', 'Last 48 Hours', 'Last 7 Days', 'Last 30 Days', 'Last 90 Days', 'Last 6 Months', 'Last 13 Months']
  };
  /**
   * Get timestamp based on date number
   * @param resultsPerDate - List of available days
   * @param dateNumber - Number of the selected day
   */

  _exports.defaultProperties = defaultProperties;

  const determDateRange = (resultsPerDate, dateNumber) => {
    const date = new Date();
    return Object.entries({
      Hours: () => date.setHours(date.getHours() - dateNumber),
      Days: () => date.setHours(date.getHours() - dateNumber * 24),
      Months: () => date.setMonth(date.getMonth() - dateNumber)
    }).find(([key]) => resultsPerDate.includes(key))[1]();
  };
  /**
   * Constrict list of events by resultsPerDate and filter by searchText
   * @param alarmList - API response with all alarm history events
   * @param searchText - value of search field
   * @param resultsPerDate - value of drop-down with results depending on date
   * @returns {*}
   */


  const filterResultsBySearch = (alarmList, searchText, resultsPerDate) => {
    const dateNumber = parseInt(resultsPerDate.match(/\d+/)[0], 0);
    const amountOfHours = determDateRange(resultsPerDate, dateNumber);
    return (alarmList || []).filter(row => row.eventDate >= amountOfHours).filter(row => row.description && row.description.toUpperCase().indexOf(searchText.toUpperCase()) > -1);
  };

  _exports.filterResultsBySearch = filterResultsBySearch;

  const checkUser = (arrOfResults, isBusReportUser) => isBusReportUser ? arrOfResults : arrOfResults.filter(i => !i.includes('Months'));
  /**
   * @module Components/Dashboard/Alarm/AlarmHistory
   * @prop {String} elementId - ID attribute for div component wrapper
   * @prop {Array} systemList - user systems
   * @example
   * {@lang xml} {{ dashboard/alarm/alarm-history
  		systemList=null,
  		alarmList=null
  }}
   */


  var _default = Ember.Component.extend(_withFsm.default, defaultProperties, _commonFsmState.commonMixin, {
    currentLocation: Ember.inject.service(),
    locationsIsOpen: false,
    searchText: '',
    tooltipOpen: false,
    resultsPerDate: 'Last 24 Hours',
    resultsPerPage: 30,
    isBusinessReportingUser: (0, _emberHelpers.computedApplyFunction)(({
      paidDataServicesAdmin,
      paidDataServices
    }) => {
      const permissions = [paidDataServicesAdmin, paidDataServices];
      return permissions.every(Boolean);
    }, 'currentLocation.permissions'),
    visibleDate: (0, _emberHelpers.computedApplyFunction)((resultsPerDateOptions, isBusinessReportingUser) => checkUser(resultsPerDateOptions, isBusinessReportingUser), 'resultsPerDateOptions', 'isBusinessReportingUser'),
    allHistoryRecords: null,
    filteredResults: (0, _emberHelpers.computedApplyFunction)(filterResultsBySearch, 'alarmList', 'searchText', 'resultsPerDate'),
    elementId: 'dashboardAlarmHistory',
    actions: {
      expandCollapseData(tooltipId) {
        (0, _jquery.default)("#".concat(tooltipId, " .collapsed-data")).toggle();
        (0, _jquery.default)("#".concat(tooltipId, " .collapse-icon")).toggleClass('expand-icon unexpand-icon');
      }

    },

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    changeLocationHandler() {
      this.get('fsm').dispatch(_commonFsmState.FETCH_DATA_ACTION, true);
    },

    /**
     * Unbind touch event
     * @private
     */
    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)('body').off('touchstart');
    },

    [_commonFsmState.SUCCESS_ACTION](alarmList) {
      this.set('alarmList', alarmList);
      this.set('allHistoryRecords', alarmList);
      (0, _jquery.default)('body').on('touchstart', () => Ember.run(() => (0, _jquery.default)('.row-tooltip').hide()));
    },

    [_commonFsmState.FETCH_DATA_ACTION](forceRequest) {
      return this.deliverDataAndDispatch('alarmData.alarmHistoryData', alarmDataList => alarmDataList.getAlarmHistory(), _commonFsmState.SUCCESS_ACTION, _commonFsmState.FAILURE_ACTION, false, false, {
        forceRequest
      });
    }

  });

  _exports.default = _default;
});