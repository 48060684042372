define("adt-wss/pods/components/modal-window/component", ["exports", "adt-wss-common/classes/status-message", "adt-wss-common/mixins/with-fsm", "jquery", "adt-wss-common/utils/fp"], function (_exports, _statusMessage, _withFsm, _jquery, _fp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Modal action - used in {@link ModalWindow}
   * @typedef ModalActionObject
   * @prop {String|ComputedProperty} caption - text for button
   * @prop {String|ComputedProperty} color - color for button use with 'stl'
   * @prop {Boolean|ComputedProperty} disabled - automatically activates if context has property IS_LOADING
   * @prop {Boolean|ComputedProperty} hidden
   * @prop {Boolean|ComputedProperty} bodyClasses
   * @prop {Sting[]|ComputedProperty} classes - css classes
   * @prop {String|ComputedProperty} stl - style of button: 'outline', 'text'
   * @prop {Function} callback
   */

  /**
   * Modal Window ShowModal Payload - used in {@link ModalWindow}
   * @typedef showModalPayload
   * @prop {String} name - name of modal window - `app/templates/modal-windows/{{name-of-template-or-path}}`
   * @prop {String|ComputedProperty} title - text on the header of modal-window
   * @prop {Object} body - Modal body template context. Better use context of current module - `body: this`
   * @prop {Object[]|ComputedProperty} statusMessages - messages on the top of window
   * @prop {Boolean|ComputedProperty} isLoading - show spinner - automatically activates if context has property IS_LOADING
   * @prop {Boolean} useOutsideClick - allow to call onClose function by click on outside the window
   * @prop {Function} onClose - call function on click on X icon of modal window
   * @prop {Function} onEnter - call function on ENTER to submit field data
   * @prop {ModalActionObject[]} actions - style of button: 'outline', 'text'.
   * @prop {Function} callback - call on button click
   * @prop {String} disclaimer - message to go in footer of modal
   */
  const resolvePropertyForLoadingState = (property, context = {}) => {
    if (property instanceof Object || !Ember.isNone(property)) {
      return property;
    }

    return 'IS_LOADING' in context ? Ember.computed.readOnly('IS_LOADING') : property;
  };

  const autoDisableButton = (button, body) => (0, _fp.omix)(button, {
    disabled: resolvePropertyForLoadingState(button.disabled, body)
  });

  const wrapButton = body => button => Ember.ObjectProxy.extend((0, _fp.omix)(autoDisableButton(button, body)), {
    content: body
  }).create();
  /**
   * **Modal Window Component** - listen **show** or **hide** event from {@link ModalWindowDispatcher}
   * and render modal templates from ```app/templates/modal-windows/``` directory
   *
   * --------------------------
   * @example
   * //mwd: inject('modalWindowDispatcher'),
   * const mwd = this.get('mwd');
   * const body = {
  	  name: 'Oleg',
  	  surname: 'Sudavnyi'
  	};
   	mwd.show({
  	name: 'hello-world',
  	title: 'Hello World Modal',
  	body,
  	isLoading: true,
  	useOutsideClick: true,
  	onClose() {
  		this.set('isActive', false);
  	},
  	statusMessages: [{type: 'success', 'Congratilations'}],
  
  	actions: [{
  		caption: 'Go Back',
  		stl: 'outline',
  		callback () {
  			const addMessageToListOfMessages = true;
  			//body has link to all properties and could be changed inside your modal window template
  			mwd.showSussess('other usage ' + body.name, addMessageToListOfMessages)
  		}
  	},
  	{
  		caption: 'Done',
  		color: computedApplyFunction(name => name === 'Oleg' ? 'green' 'red', 'name'),
  		classes: ['hello-button'],
  		disabled: computed(function(){
  			returtn this.get('name') !== 'Oleg'; // from body object (context)
  		}, 'name'),
  		callback() {
  			mwd.get('showError')('Oh no');
  		}
  	}]
  });
   * @module Components/ModalWindow
   * @see module:Components/Stupid/ButtonComponent
   * @prop {String} elementId - ID attribute for div component wrapper
   * @prop {Boolean} isActive - display component or not. Also add class 'fade'
   * @prop {Boolean} isLoading - display loading spinner
   * @prop {Function} backButton - display button back
   * @prop {Boolean} isFooterLine - show gray footer line
   * @prop {Boolean} hideTitle - hide header of modal window
   * @prop {String} title - Title of modal window. Default: 'Modal window'.
   * @prop {String} prefix - name of folder for modal window templates. Default: modal-windows.
   * @prop {Object} modalWindowDispatcher - Ember Eventer service for sharing messages between components.
   * @prop {ModalActionObject[]} modalActions - buttons for modal window.
   * @prop {Classes/StatusMessage[]} statusMessages - messages in the top of modal window
  
   */


  var _default = Ember.Component.extend(_withFsm.default, {
    elementId: 'modalWindow',
    classNameBindings: ['isActive:fade'],
    prefix: 'modal-windows',
    modalWindowDispatcher: Ember.inject.service(),
    title: 'Modal window',
    isFooterLine: true,
    hideTitle: false,
    isLoading: false,
    modalActions: null,

    /**
     * Makes subscribtions and add default values
     */
    init() {
      this._super(...arguments);

      const mwd = this.get('modalWindowDispatcher');
      mwd.on('show', this, 'showWindow');
      mwd.on('hide', this, 'hideWindow');
      mwd.on('loading', this, 'setLoadingState');
      mwd.on('setMessage', this, 'setMessage');
      mwd.on('clearMessages', this, 'clearMessages');
      mwd.on('showError', this, 'showError');
      mwd.on('showSuccess', this, 'showSuccess');
      mwd.on('showWarn', this, 'showWarn');
      mwd.on('showInfo', this, 'showInfo');
      mwd.on('setProperty', this, 'set');
      this.set('boundOnEscHandler', this.get('onEscHandler').bind(this));
    },

    /**
     * clear messages from the top of modal window
     * pass a type to __only__ clear that type
     * @param {String} [type] - error | success | warn | info
     */
    clearMessages(type) {
      const statusMessages = this.get('dynamicProps.statusMessages');

      if (statusMessages) {
        let out = [];

        if (type) {
          out = statusMessages.map(m => m.type !== type);
        }

        this.set('dynamicProps.statusMessages', out);
      }
    },

    /**
     * show messages in the top of modal window
     * @param {String} type - error | success | warn | info
     * @param {String} text -  text of message
     * @param {Boolean} isPush - add message instead of replace existing
     */
    setMessage(type, text, isPush) {
      if (isPush) {
        this.get('dynamicProps.statusMessages').pushObject(new _statusMessage.default(text, type));
      } else {
        this.set('dynamicProps.statusMessages', [new _statusMessage.default(text, type)]);
      }
    },

    /**
     * show error message in the top of modal window
     * @param {String} text -  text of message
     * @param {Boolean} isPush - add message instead of replace existing
     */
    showError(text, isPush) {
      this.setMessage('error', text, isPush);
    },

    /**
     * show sucess messge in the top of modal window
     * @param {String} text -  text of message
     * @param {Boolean} isPush - add message instead of replace existing
     */
    showSuccess(text, isPush) {
      this.setMessage('success', text, isPush);
    },

    /**
     * show warn messag in the top of modal window
     * @param {String} text -  text of message
     * @param {Boolean} isPush - add message instead of replace existing
     */
    showWarn(text, isPush) {
      this.setMessage('warn', text, isPush);
    },

    /**
     * show info messag in the top of modal window
     * @param {String} text -  text of message
     * @param {Boolean} isPush - add message instead of replace existing
     */
    showInfo(text, isPush) {
      this.setMessage('error', text, isPush);
    },

    /**
     * set default values to buttons or icon close
     * @private
     */
    willRender() {
      this._super(...arguments);

      const onClose = this.get('onClose');
      const hideWindow = this.get('hideWindow');
      const modalActions = this.get('modalActions');
      const useOutsideClick = this.get('useOutsideClick');
      this.set('close', () => {
        if (onClose) onClose.apply(this, arguments);
        if (!modalActions || onClose && useOutsideClick) hideWindow.apply(this, arguments);
        document.removeEventListener('keydown', this.get('boundOnEscHandler'), false);
      });
    },

    /**
     * Changes loading state
     * @param {Boolean} state
     */
    setLoadingState(state) {
      this.set('isLoading', state);
    },

    /**
     * Removes subscribtions
     * @private
     */
    willDestroyElement() {
      this._super(...arguments);

      const mwd = this.get('modalWindowDispatcher');
      mwd.off('show', this, 'showWindow');
      mwd.off('hide', this, 'hideWindow');
      mwd.off('loading', this, 'setLoadingState');
      mwd.off('setMessage', this, 'setMessage');
      mwd.off('showError', this, 'showError');
      mwd.off('showSuccess', this, 'showSuccess');
      mwd.off('showWarn', this, 'showWarn');
      mwd.off('showInfo', this, 'showInfo');
      mwd.off('setProperty', this, 'set');
    },

    /**
     * show window and set parameters. Render modal tempates with body context.
     * @param  {showModalPayload} payload - parameters to configure modal window
     */
    showWindow(payload) {
      const {
        name,
        title = 'history',
        body = {},
        onClose,
        onEnter,
        actions,
        statusMessages = [],
        isLoading,
        isFooterLine = true,
        hideTitle,
        useOutsideClick,
        onOpen = () => 1,
        backButton,
        disclaimer,
        bigCallback,
        bodyClasses
      } = payload;
      const prefix = this.get('prefix');
      const modalActions = (actions || []).map(wrapButton(body));
      const dynamicProps = Ember.ObjectProxy.extend({
        content: body,
        title,
        statusMessages,
        isLoading: resolvePropertyForLoadingState(isLoading, body),
        isFooterLine,
        disclaimer,
        bodyClasses
      }).create();
      this.setProperties({
        isActive: true,
        dynamicProps,
        name,
        body,
        onClose,
        onEnter,
        useOutsideClick,
        hideTitle,
        modalActions,
        backButton,
        bigCallback,
        isBackButton: backButton instanceof Function,
        templateName: "".concat(prefix, "/").concat(name)
      });
      document.addEventListener('keydown', this.get('boundOnEscHandler'), false);
      onOpen.call(this);
    },

    /**
     * Just hides window. Called on click to X icon.
     * @private
     */
    hideWindow() {
      this.set('isActive', false);
    },

    /**
     * @private
     * Opens confirmation bootstrap window and close this modal window.
     * @param  {Boolean} toCloseModal - parameters to close modal window with form inside on ESC
     */
    closeOnEsc(toCloseModal) {
      this.set('confirmMessageOpened', false);

      if (typeof toCloseModal === 'undefined') {
        this.set('confirmMessageOpened', true);
      } else if (toCloseModal) {
        this.get('close')();
      }
    },

    /**
     * Closes Bootstrap modal-window when Enter key is fired.
     */
    closeOnEnter() {
      this.set('confirmMessageOpened', false);
      this.get('close')();
    },

    /**
     * Checks if modal window contains embedded forms or not. Called on click to Escape key.
     */
    onEscHandler(e) {
      if (e.keyCode === 27) {
        if ((0, _jquery.default)('.modal-container').find('input').length || (0, _jquery.default)('.modal-container').find('select').length) {
          this.closeOnEsc();
        } else {
          this.get('close')();
        }
      }
    },

    actions: {
      /**
       * Calls "onClose" function passed to modal window as callback to background click.
       * @private
       */
      hideWindow({
        target: {
          className
        }
      }) {
        if (className.indexOf('modal-container') !== -1 && this.get('useOutsideClick')) {
          this.get('close')();
        }
      },

      /**
       * makes valid class name for modal window body
       * preferences/travel-reminder -> preferences--travel-reminder
       * a/b/c/d-e -> a--b--c--d-e
       */
      makeClassName(name) {
        return name.replace(/\//g, '--');
      },

      onHideConfirmMessageHandler() {
        this.set('confirmMessageOpened', false);
      }

    }
  });

  _exports.default = _default;
});