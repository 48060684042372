define("adt-wss/pods/components/dashboard/account/payment-options/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/classes/status-message", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/fp", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/common-fsm-state", "adt-wss/pods/components/dashboard/account/payment-options/constants", "adt-wss/pods/components/dashboard/account/payment-options/controls-flow", "adt-wss/pods/components/dashboard/account/one-time-payment/component", "adt-wss/pods/components/dashboard/account/payment-options/validator"], function (_exports, _withFsm, _statusMessage, _emberHelpers, _fp, _appDispatcher, _commonFsmState, _constants, _controlsFlow, _component, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fsmModel = {
    transitions: {
      [_commonFsmState.IDLE_STATE]: {
        [_constants.SELECT_PAYMENT_METHOD_ACTION]: _constants.PAYMENT_AMOUNT_ENTRY_STATE,
        [_constants.ENTER_PAYMENT_AMOUNT_ACTION]: _constants.PAYMENT_AMOUNT_ENTRY_STATE,
        [_constants.SELECT_FLEXFI_PAYMENT_ACTION]: _constants.FLEXFI_PAYMENT_SELECTION_STATE,
        [_constants.RESET_STATE_ACTION]: _commonFsmState.IDLE_STATE
      },
      [_constants.FLEXFI_PAYMENT_PAYMENT_STATE]: {
        [_constants.SELECT_PAYMENT_METHOD_ACTION]: _constants.PAYMENT_METHOD_SELECTION_STATE,
        [_constants.RESET_STATE_ACTION]: _commonFsmState.IDLE_STATE
      },
      [_constants.PAYMENT_AMOUNT_ENTRY_STATE]: {
        [_constants.SELECT_PAYMENT_METHOD_ACTION]: _constants.PAYMENT_METHOD_SELECTION_STATE,
        [_constants.RESET_STATE_ACTION]: _commonFsmState.IDLE_STATE
      },
      [_constants.PAYMENT_METHOD_SELECTION_STATE]: {
        [_constants.CONFIRM_PAYMENT_DETAILS_ACTION]: _constants.PAYMENT_DETAILS_CONFIRMATION_STATE,
        [_constants.RESET_STATE_ACTION]: _commonFsmState.IDLE_STATE,
        [_constants.TRANSITION_TO_PAYMENT_AMOUNT_ENTRY_STATE]: _constants.PAYMENT_AMOUNT_ENTRY_STATE,
        [_constants.TRANSITION_TO_FLEXFI_PAYMENT_SELECTION_STATE]: _constants.FLEXFI_PAYMENT_SELECTION_STATE
      },
      [_constants.PAYMENT_DETAILS_CONFIRMATION_STATE]: {
        [_commonFsmState.UPDATE_DATA_ACTION]: _commonFsmState.UPDATING_STATE,
        [_constants.RESET_STATE_ACTION]: _commonFsmState.IDLE_STATE,
        [_constants.TRANSITION_TO_PAYMENT_METHOD_SELECTION_STATE]: _constants.PAYMENT_METHOD_SELECTION_STATE
      },
      [_constants.FLEXFI_PAYMENT_SELECTION_STATE]: {
        [_constants.SELECT_PAYMENT_METHOD_ACTION]: _constants.PAYMENT_METHOD_SELECTION_STATE,
        [_constants.RESET_STATE_ACTION]: _commonFsmState.IDLE_STATE
      },
      [_commonFsmState.UPDATING_STATE]: {
        [_commonFsmState.SUCCESS_ACTION]: _commonFsmState.IDLE_STATE,
        [_commonFsmState.FAILURE_ACTION]: _constants.PAYMENT_DETAILS_CONFIRMATION_STATE
      }
    }
  };
  /**
   * @module Components/Dashboard/Account/PaymentOptions
   * @prop {Boolean} flexFiPreselect
   * @example
   * {@lang xml} {{ dashboard/account/PaymentOptions
  		flexFiPreselect=flexfi
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    fsmModel,
    csrInfo: Ember.inject.service(),
    billingData: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    flexfiData: Ember.inject.service(),
    router: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    dsm: Ember.inject.service('dashboard-status-messages'),
    amount: '0.00',
    noteNo: null,
    nextButtonDisabled: false,
    cardFormVisible: true,
    isIvr: false,
    ivrInit: false,
    ivrType: null,
    agentExtension: Ember.computed.oneWay('csrInfo.csr.agentIvrContactNo'),
    dashboardStatusMessages: Ember.computed.alias('dsm.statusMessages'),
    billInfo: Ember.computed.readOnly('billingData.billingModel.billInfo'),
    balance: Ember.computed.readOnly('billingData.billingModel.billInfo.balance'),
    billingSystem: Ember.computed.readOnly('billingData.billingModel.billInfo.billingSystem'),
    accountNumber: Ember.computed.oneWay('currentLocation.profileInfo.accountId'),
    installmentPlanInfo: Ember.computed.readOnly('flexfiData.installmentData'),
    forceMoveToPaymentAmountState: (0, _emberHelpers.computedApplyFunction)((b, f) => parseFloat(b) <= 0 && !f, 'balance', 'flexFiPreselect'),
    currentState: (0, _emberHelpers.computedApplyFunction)(f => f ? _constants.PAYMENT_AMOUNT_ENTRY_STATE : _commonFsmState.IDLE_STATE, 'forceMoveToPaymentAmountState'),
    selectedPaymentFlow: (0, _emberHelpers.computedApplyFunction)((options = [], selectedId) => (options.find(o => o.id === selectedId) || {}).paymentFlow, 'options', 'data.selectedOptionId'),
    options: (0, _emberHelpers.computedApplyFunction)((balance = 0, installmentPlanInfo = []) => [{
      title: "$".concat(balance),
      id: _constants.BALANCE_DUE_PAYMENT_ID,
      subTitle: '(Monthly monitoring + other charges)',
      visible: true
    }, {
      title: 'Other Amount',
      id: _constants.OTHER_AMOUNT_PAYMENT_ID,
      visible: true
    }, {
      title: 'FlexFi Payment',
      id: _constants.FLEXFI_PAYMENT_ID,
      visible: Boolean(installmentPlanInfo.length)
    }].map((o, i) => (0, _fp.omix)(o, {
      paymentFlow: String(i)
    })), 'balance', 'installmentPlanInfo'),
    controls: (0, _emberHelpers.computedApplyFunction)((id, state) => {
      if (![id, state].every(Boolean)) {
        return false;
      }

      return {
        back: _controlsFlow.backFlow[id][state],
        forward: _controlsFlow.forwardFlow[id][state]
      };
    }, 'data.selectedOptionId', 'currentState'),
    selectedOptionId: (0, _emberHelpers.computedApplyFunction)(p => p ? _constants.FLEXFI_PAYMENT_ID : null, 'flexFiPreselect'),
    isIdleState: (0, _emberHelpers.isState)(_commonFsmState.IDLE_STATE),
    isUpdatingState: (0, _emberHelpers.isState)(_commonFsmState.UPDATING_STATE),
    isPaymentAmountEntryState: (0, _emberHelpers.isState)(_constants.PAYMENT_AMOUNT_ENTRY_STATE),
    isPaymentDetailsConfirmationState: (0, _emberHelpers.isState)(_constants.PAYMENT_DETAILS_CONFIRMATION_STATE),
    isPaymentMethodSelectionState: (0, _emberHelpers.isState)(_constants.PAYMENT_METHOD_SELECTION_STATE),
    isFlexFiPaymentSelectionState: (0, _emberHelpers.isState)(_constants.FLEXFI_PAYMENT_SELECTION_STATE),

    init(...args) {
      this._super(...args);

      this.data = {};
      this.errors = {};
      this.paymentDate = new Date();
      this.currentDateObj = (0, _component.computeStartPaymentDate)();
      this.changeSelectedOption = this.changeSelectedOption.bind(this);
      this.selectAccount = this.selectAccount.bind(this);
      this.setFlexFiPlan = this.setFlexFiPlan.bind(this);
      this.setCardFormVisibility = this.setCardFormVisibility.bind(this);
      this.disableNextButton = this.disableNextButton.bind(this);
      this.showError = this.mwd.showError.bind(this.mwd);
      this.selectAccount({
        accountType: null
      });

      this.moveToForwardStep = () => this.dispatch(this.get('controls.forward'));

      this.initProcess();

      if (this.get('forceMoveToPaymentAmountState')) {
        this.changeSelectedOption(_constants.OTHER_AMOUNT_PAYMENT_ID);
      }
    },

    /**
     * Sets dashboard status message
     *
     * @function
     * @param {Object} options
     * @param {String} options.head - title
     * @param {String} options.text - message
     * @param {String} options.type - type of message
     * @param {String?} options.type - type of message
    */
    showMessage({
      head,
      text,
      type,
      textToCopy,
      targetName
    }) {
      this.set('dashboardStatusMessages', [new _statusMessage.default({
        head,
        text
      }, {
        type,
        static: true
      }, 50000, textToCopy, targetName)]);
    },

    /**
     * Creates copy for editable object
     * Sets `data` property
     *
     * @function
     */
    prepareData() {
      this.set('data', this.getProperties('selectedOptionId', 'amount', 'noteNo', 'paymentDate'));
      this.selectAccount({
        accountType: null
      });
    },

    /**
     * Handler for closing a modal window
     * Resets modal window state
     *
     * @function
     */
    onCloseModalWindow() {
      this.disableNextButton(false);
      this.setCardFormVisibility(false);
      this.dispatch(_constants.RESET_STATE_ACTION);
      this.mwd.hide();
      this.router.transitionTo('dashboard.account.payments');
    },

    /**
     * Handler for chaning option
     * Resets `data.{noteNo, paymentDate}` for each selection
     *
     * @function
     * @param {String|Number} id
     */
    changeSelectedOption(id = null) {
      this.set('data.amount', this.get(id === _constants.BALANCE_DUE_PAYMENT_ID ? 'balance' : 'amount'));
      this.set('data.noteNo', this.noteNo);
      this.set('data.paymentDate', this.paymentDate);
      this.set('data.selectedOptionId', id);
    },

    /**
     * Handler for setting FlexFi Plan
     * Sets `data.{amount, noteNo}` for each selection
     *
     * @function
     * @param {Object} param
     * @param {String} param.amount
     * @param {Number} param.noteNo
     */
    setFlexFiPlan({
      amount,
      noteNo
    }) {
      this.set('data.amount', amount);
      this.set('data.noteNo', noteNo);
    },

    /**
     * Selects account
     * Sets `data.account` prop
     *
     * @function
     * @param {Object} account
     */
    selectAccount(account) {
      this.set('data.account', account);
    },

    [_constants.RESET_STATE_ACTION]() {
      this.disableNextButton(false);
      return this.prepareData();
    },

    /**
     * Interstitial action when transiting TO payment method selection
     */
    [_constants.TRANSITION_TO_PAYMENT_METHOD_SELECTION_STATE]() {// this.set('isIvr', this.get('paymentTab') == 'ivr');
      // this.set('ivrInit', true);
      // return false;
    },

    /**
     * Shows a warning message if the amount is greater than the balance due.
     *
     * @function
     */
    [_constants.CONFIRM_PAYMENT_DETAILS_ACTION]() {
      const {
        selectedOptionId,
        amount
      } = this.data;
      const parsedAmt = parseFloat(amount);
      const balance = parseFloat(this.get('balance'));

      const fn = () => {
        if (selectedOptionId === _constants.FLEXFI_PAYMENT_ID || parsedAmt <= balance) {
          return false;
        }

        return this.mwd.showWarn(_constants.AMOUNT_GREATER_THAN_DUE_MSG);
      };

      if (this.get('isIvr')) {
        return this.get('billingData').getPayConfDetails_v2({
          flowId: 'ONETIME_PAY',
          paymentType: this.get('ivrType') || 'ivrPayment'
        }).then(data => {
          this.selectAccount({
            type: data.type,
            number: "******".concat(data.lastFourPaymentNo)
          });
          this.set('ivrType', data.paymentType);
          return fn();
        }).catch(results => {
          this.get('mwd').showErrors(results.errors);
          return this.get('fsm').dispatch(_constants.TRANSITION_TO_PAYMENT_METHOD_SELECTION_STATE);
        });
      }

      return fn();
    },

    /**
     * Builds payload and sends http request
     * Calls 'auth/bill/payment'
     *
     * @function
     * @returns {Promise}
     */
    [_commonFsmState.UPDATE_DATA_ACTION]() {
      const {
        account,
        amount,
        noteNo,
        paymentDate
      } = this.data;
      return this.commonDispatcher(() => this.billingData.payBill((0, _fp.omix)({
        paymentDetails: {
          noteNo,
          paymentAmount: amount,
          postDate: paymentDate.toUSAString('/'),
          selectedSavedAccountId: account.id,
          deviceType: 'Web'
        },
        paymentType: this.get('isIvr') ? this.get('ivrType') || 'ivrPayment' : null
      }, this.billingData.getPaymentDetails(account))));
    },

    /**
     * Handler for failed updating.
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     * @param {Classes/AjaxResponse} results - object with errors from BE response
     */
    [_commonFsmState.FAILURE_ACTION]({
      errors
    } = {
      errors: []
    }) {
      const [text] = errors;

      if (text === _constants.RE_ENTER_PAYMENT_REQUEST_ERR) {
        this.onCloseModalWindow();
        return this.showMessage({
          text,
          type: 'error'
        });
      }

      return this.mwd.showError(text);
    },

    /**
     * Handler for successful updating.
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     */
    [_commonFsmState.SUCCESS_ACTION](data) {
      this.mwd.hide();
      this.router.transitionTo('dashboard.account.payments');
      this.showMessage({
        head: "Payment successfully submitted for account ".concat(this.get('accountNumber'), "."),
        text: "Confirmation: ".concat(data.confirmationNumber),
        textToCopy: data.confirmationNumber,
        type: 'success',
        targetName: this.get('router.currentRouteName')
      });
      this.get('appDispatcher').sendEvent(_appDispatcher.ONE_TIME_PAYMENT_SUCCESSFUL, data);
    },

    [_constants.SELECT_PAYMENT_METHOD_ACTION]() {
      this.selectAccount(this.get('data.account') || {
        accountType: null
      });
    },

    disableNextButton(value = false) {
      this.set('nextButtonDisabled', value);
    },

    setCardFormVisibility(value = false) {
      this.set('cardFormVisible', value);
    },

    resetErrorsState() {
      this.mwd.clearMessages();
      this.set('errors', {});
    },

    /**
     * Wraps usual `fsm.dispatch`
     * Resets errors state for each call
     *
     * @function
     * @param {...*}
     */
    dispatch(...args) {
      this.resetErrorsState();
      this.fsm.dispatch(...args);
    },

    /**
     * Shows modal window
     * Contains logic of showing/hiding action buttons
     * Handler for `Next` button includes data validation logic
     *
     * @function
     */
    initProcess() {
      const onClose = () => this.onCloseModalWindow();

      this.prepareData();
      this.mwd.show({
        name: 'payment-options',
        title: 'Make a Payment',
        body: this,
        bodyClasses: (0, _emberHelpers.computedApplyFunction)((v, ms) => v && ms ? ['card-form'] : [], 'cardFormVisible', 'isPaymentMethodSelectionState'),
        onClose,
        isFooterLine: (0, _emberHelpers.computedApplyFunction)((state, isSmartphone) => state !== _constants.PAYMENT_DETAILS_CONFIRMATION_STATE && !isSmartphone, 'currentState', 'media.isSmartphone'),
        disclaimer: (0, _emberHelpers.computedApplyFunction)(s => s === _constants.PAYMENT_METHOD_SELECTION_STATE ? _constants.ENCRYPTED_INFORMATION_MSG : null, 'currentState'),
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          hidden: (0, _emberHelpers.computedApplyFunction)((backAction, forceMoveToPaymentAmountState, state) => !(state === _constants.PAYMENT_AMOUNT_ENTRY_STATE && forceMoveToPaymentAmountState), 'controls.back', 'forceMoveToPaymentAmountState', 'currentState'),
          callback: onClose
        }, {
          caption: 'Back',
          stl: 'outline',
          hidden: (0, _emberHelpers.computedApplyFunction)((backAction, forceMoveToPaymentAmountState, state) => !backAction || state === _constants.PAYMENT_AMOUNT_ENTRY_STATE && forceMoveToPaymentAmountState, 'controls.back', 'forceMoveToPaymentAmountState', 'currentState'),
          callback: () => this.dispatch(this.get('controls.back'))
        }, {
          caption: 'Next',
          hidden: (0, _emberHelpers.computedApplyFunction)((cs, nextAction) => cs !== _commonFsmState.IDLE_STATE && !nextAction, 'currentState', 'controls.forward'),
          disabled: (0, _emberHelpers.computedApplyFunction)((cs, id, d, acc, pmss, isIvr, ivrInit) => {
            if (cs === _commonFsmState.IDLE_STATE && !id) {
              return true;
            }

            if (pmss) {
              if (isIvr) {
                if (!ivrInit) return true;
                return false;
              }

              return !acc;
            }

            return d;
          }, 'currentState', 'data.selectedOptionId', 'nextButtonDisabled', 'data.account.accountType', 'isPaymentMethodSelectionState', 'isIvr', 'ivrInit'),
          callback: () => {
            const nextAction = this.get('controls.forward');
            const currentState = this.get('currentState');
            const paymentType = this.get('data.account.paymentType');
            const newInstance = this.get('data.account.newInstance');
            this.resetErrorsState();

            if ((0, _validator.stateUnderValidation)(currentState, {
              type: paymentType,
              newInstance
            })) {
              const validation = (0, _validator.validate)(currentState, {
                amount: [this.data.amount, this.get('balance'), this.get('billingSystem')],
                date: [this.data.paymentDate, this.currentDateObj],
                accountType: [this.data.account.accountType],
                routingNumber: [this.data.account.routingNumber],
                accountNumber: [this.data.account.accountNumber, this.data.account.accountNumber2],
                accountNumber2: [this.data.account.accountNumber2, this.data.account.accountNumber]
              });
              this.set('errors', validation.fields);

              if (validation.failed) {
                this.mwd.showErrors(validation.messages);
                return false;
              }
            }

            return this.fsm.dispatch(nextAction);
          }
        }, {
          caption: 'Submit Payment',
          hidden: (0, _emberHelpers.computedApplyFunction)(s => s !== _constants.PAYMENT_DETAILS_CONFIRMATION_STATE, 'currentState'),
          callback: () => this.dispatch(_commonFsmState.UPDATE_DATA_ACTION)
        }]
      });
    }

  });

  _exports.default = _default;
});