define("adt-wss/services/web-users", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/http-resources", "adt-wss/classes/web-user"], function (_exports, _myadtHelpers, _httpResources, _webUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Services/WebUsers
   * @prop {Classes/WebUser[]} webUsers
   */
  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)({
    webUsers: null
  }), {
    apiService: Ember.inject.service(),
    webUsers: null,

    /**
     * Retrieves list of users from API
     * @return {Promise<Classes/WebUser[]>}
     */
    getWebUsers() {
      return this.get('apiService').request({
        resource: _httpResources.myADT.getAllWebUsers
      }).then(({
        data
      }) => {
        const out = (data || []).map(u => {
          u.admin = !!u.admin;
          return _webUser.default.create(u);
        });
        this.set('webUsers', out);
        return out;
      });
    },

    /**
     * Adds a new user!
     * @param {Object} user
     * @param {String} user.email
     * @param {Boolean} user.admin
     * @param {String} user.userComments
     * @param {String} user.department
     * @param {String} user.title
     * @param {String} user.siteType
     */
    addWebUser(user) {
      return this.get('apiService').request({
        resource: _httpResources.myADT.addWebUser,
        data: user
      }).then(() => this.getWebUsers());
    },

    /**
     * Updates existing user info
     * @param {Object} user
     * @param {String} user.id
     * @param {Boolean} user.pending
     * @param {Boolean} user.admin
     * @param {String} user.title
     * @param {String} user.department
     * @param {String} user.userComments
     */
    updateUser(user) {
      return this.get('apiService').request({
        resource: _httpResources.myADT.updateWebUser,
        data: {
          admin: user.admin,
          title: user.title,
          department: user.department,
          userComments: user.userComments
        },
        params: {
          dataId: user.id,
          pending: user.pending
        }
      }).then(() => this.getWebUsers());
    },

    /**
     * Removes user
     * @param {Object} user
     * @param {String} user.id
     * @param {Boolean} user.pending
     */
    deleteUser(user) {
      return this.get('apiService').request({
        resource: _httpResources.myADT.deleteWebUser,
        params: {
          dataId: user.id,
          pending: user.pending
        }
      }).then(() => this.getWebUsers());
    }

  });

  _exports.default = _default;
});