define("adt-wss/pods/components/dashboard/account/billing-address/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss/mixins/on-form-esc-handler", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/classes/validator", "adt-wss-common/classes/status-message", "adt-wss/pods/components/dashboard/account/billing-address/constants"], function (_exports, _withFsm, _onFormEscHandler, _myadtHelpers, _validator, _statusMessage, constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MELISSACHECK_ERROR_KEY = _exports.MELISSACHECK_WARNING_KEY = _exports.SUCCESSFULLY_UPDATED_MESSAGE = _exports.GENERIC_ERROR_MESSAGE = _exports.SUCCESS_ACTION = _exports.FAILURE_ACTION = _exports.UPDATE_DATA_ACTION = _exports.CANCEL_EDIT_MODE_ACTION = _exports.SET_EDIT_MODE_ACTION = _exports.UPDATING_STATE = _exports.EDIT_STATE = _exports.IDLE_STATE = _exports.defaultProps = void 0;
  const validator = new _validator.default({
    name: {
      label: 'Name',
      rules: ['isNotEmpty', 'maxLength?50']
    },
    addressLine1: {
      label: 'Address Line 1',
      rules: ['isNotEmpty', 'maxLength?35']
    },
    addressLine2: {
      label: 'Address Line 2',
      rules: ['maxLength?35']
    },
    city: {
      label: 'City',
      rules: ['isNotEmpty', 'maxLength?35']
    },
    state: {
      label: 'State',
      rules: ['lengthOfNSymbols?2']
    },
    zipCode: {
      label: 'Zip code',
      rules: ['isZipCode']
    }
  }, {
    messageCustomizer: (value, label) => "".concat(label, ": ").concat(value)
  });
  const defaultProps = {
    elementId: 'billingAddress',
    style: '',
    name: '',
    addressLine1: 'Test 1',
    addressLine2: '',
    city: '',
    zipCode: '',
    state: '',
    phone: '',
    alert: v => v,
    setOverlap: () => {},
    submitEdit: () => {
      console.error('Please pass submitEdit method to dashboard/account/billing-address component');
    }
  };
  _exports.defaultProps = defaultProps;
  const {
    IDLE_STATE,
    EDIT_STATE,
    UPDATING_STATE,
    SET_EDIT_MODE_ACTION,
    CANCEL_EDIT_MODE_ACTION,
    UPDATE_DATA_ACTION,
    FAILURE_ACTION,
    SUCCESS_ACTION,
    GENERIC_ERROR_MESSAGE,
    SUCCESSFULLY_UPDATED_MESSAGE,
    MELISSACHECK_WARNING_KEY,
    MELISSACHECK_ERROR_KEY
  } = constants;
  _exports.MELISSACHECK_ERROR_KEY = MELISSACHECK_ERROR_KEY;
  _exports.MELISSACHECK_WARNING_KEY = MELISSACHECK_WARNING_KEY;
  _exports.SUCCESSFULLY_UPDATED_MESSAGE = SUCCESSFULLY_UPDATED_MESSAGE;
  _exports.GENERIC_ERROR_MESSAGE = GENERIC_ERROR_MESSAGE;
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
  _exports.FAILURE_ACTION = FAILURE_ACTION;
  _exports.UPDATE_DATA_ACTION = UPDATE_DATA_ACTION;
  _exports.CANCEL_EDIT_MODE_ACTION = CANCEL_EDIT_MODE_ACTION;
  _exports.SET_EDIT_MODE_ACTION = SET_EDIT_MODE_ACTION;
  _exports.UPDATING_STATE = UPDATING_STATE;
  _exports.EDIT_STATE = EDIT_STATE;
  _exports.IDLE_STATE = IDLE_STATE;
  const fsmModel = {
    scrollToTop: false,
    transitions: {
      [IDLE_STATE]: {
        [SET_EDIT_MODE_ACTION]: EDIT_STATE
      },
      [EDIT_STATE]: {
        [CANCEL_EDIT_MODE_ACTION]: IDLE_STATE,
        [UPDATE_DATA_ACTION]: UPDATING_STATE
      },
      [UPDATING_STATE]: {
        [FAILURE_ACTION]: EDIT_STATE,
        [SUCCESS_ACTION]: IDLE_STATE
      }
    }
  };
  /**
   * @module Components/Dashboard/Account/BillingAddress
   * @prop {String} elementId - ID attribute for div component wrapper
   * @prop {String} style - HTML Atribute
   * @prop {String} addressLine1 - First address line
   * @prop {String} addressLine2 - Second address line
   * @prop {String} city - City
   * @prop {String} name - Customer's name
   * @prop {String} zipCode - Zip code
   * @prop {String} state - State
   * @prop {String} phone - Phone
   * @prop {StateMachine} fsm
   * @prop {Function} submitEdit - Function which save changes
   * @prop {Function} alert - Function which called after every update attempt
   * @prop {Object} data - Data which used in edit mode and will be sent on the request
   * @prop {Function} setOverlap - Set value for visibility background overlap
   * @example
   * {@lang xml} {{ dashboard/account/billing-address
  		elementId='billingAddress'
  		style='"width: 600px"'
  		name='John Doe'
  		addressLine1='Test 1'
  		addressLine2=''
  		city='Boca Raton'
  		zipCode='33346'
  		state='Florida'
  		phone='6148644070'
  		submitEdit=(action 'submit')
  		alert=(action 'setMessages')
  		setOverlap=(action 'setOverlap')
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, _onFormEscHandler.default, {
    validator,
    currentLocation: Ember.inject.service(),
    fsmModel,
    tagName: 'section',
    errors: null,
    messages: null,
    mwd: Ember.inject.service('modal-window-dispatcher'),
    classNames: ['billing-address', 'account-widget-section'],
    classNameBindings: ['IS_EDIT_STATE:active'],
    states: _myadtHelpers.default.stateMapping,
    data: null,
    currentState: IDLE_STATE,
    activeESCState: EDIT_STATE,
    cancelESCState: CANCEL_EDIT_MODE_ACTION,
    IS_EDIT_STATE: Ember.computed.equal('currentState', EDIT_STATE),
    IS_UPDATING_STATE: Ember.computed.equal('currentState', UPDATING_STATE),
    IS_IDLE_STATE: Ember.computed.equal('currentState', IDLE_STATE),
    generalAddress: Ember.computed('city', 'state', 'zipCode', function () {
      return "".concat(this.get('city'), ", ").concat(this.get('state'), " ").concat(this.get('zipCode'));
    }),
    isEditStateChanged: Ember.observer('IS_EDIT_STATE', function () {
      this.setOverlap(this.get('IS_EDIT_STATE'));
    }),
    isConMon: Ember.computed.oneWay('currentLocation.isContractMonNoBillNoContractAccount'),

    init() {
      this._super(...arguments);

      this.get('validator').onValidate(({
        messages: errors,
        values
      }) => {
        this.set('errors', errors);
        this.set('messages', values);
      });
      this.onEscHandler = this.onEscHandler.bind(this);
    },

    keyDown(e) {
      this.onEscHandler(e);
    },

    /**
     * Cleans messages and alerts
     * Triggered on `SET_EDIT_MODE_ACTION`, `CANCEL_EDIT_MODE_ACTION`
     *
     * @function
     */
    resetAlert() {
      this.set('errors', null);
      this.set('messages', null);
      this.alert(null);
    },

    /**
     * Creates copy for editable object
     * Triggered on `SET_EDIT_MODE_ACTION`
     *
     * @function
     */
    prepareEdit() {
      const props = this.getProperties(['addressLine1', 'addressLine2', 'city', 'zipCode', 'state']);
      this.set('data', { ...props
      });
    },

    /**
     * Handler for changing state to `EDIT_STATE`
     * Resets messages, alerts and prepares environment
     *
     * @function
     */
    [SET_EDIT_MODE_ACTION]() {
      this.resetAlert();
      this.prepareEdit();
    },

    /**
     * Handler for changing state to `IDLE_STATE` from `EDIT_STATE`
     * Resets messages, alerts
     *
     * @function
     */
    [CANCEL_EDIT_MODE_ACTION]() {
      this.resetAlert();
    },

    /**
     * Sends HTTP request if data has passed validation, in another case returns rejected Promise
     * Calls '/api/v2/auth/bill/billingAddress/updateCustomerBillingAddress'
     *
     * @function
     * @param {Object} data
     * @returns {Promise}
     */
    [UPDATE_DATA_ACTION](data) {
      const {
        validator: val,
        fsm
      } = this.getProperties('validator', 'fsm');
      const validationFailed = val.validate(data);

      if (validationFailed) {
        return Ember.RSVP.Promise.resolve(fsm.dispatch(FAILURE_ACTION));
      }

      return this.submitEdit(data).then(() => fsm.dispatch(SUCCESS_ACTION)).catch(r => fsm.dispatch(FAILURE_ACTION, r));
    },

    /**
     * Handler for successful updating.
     * Calls `alert` method in order to show a success message
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     */
    [SUCCESS_ACTION]() {
      this.alert([new _statusMessage.default(SUCCESSFULLY_UPDATED_MESSAGE, 'success', true)]);
    },

    /**
     * Handler for failed updating.
     * Calls `alert` method in order to show a success message
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     * @param {Classes/AjaxResponse} results - object with errors from BE response
     * @returns {Boolean|undefined} If results are `null` it means that requested data hasn't passed the FE validation,
     * 	in another case data hasn't passed BE validation.
     */
    [FAILURE_ACTION](results) {
      if (!results) return false;
      const {
        errors = [GENERIC_ERROR_MESSAGE]
      } = results || {};
      return this.alert(errors.map(e => new _statusMessage.default(e, [MELISSACHECK_ERROR_KEY, MELISSACHECK_WARNING_KEY].some(t => t === e) ? 'warn' : 'error')));
    },

    actions: {
      /**
       * Handles changing of state select
       * Updates `state` property for the editable object
       *
       * @function
       * @param {String} stateCode
       */
      selectState(stateCode) {
        this.set('data.state', stateCode);
      },

      /**
       * Cleans needed field of the editable object
       *
       * @function
       */
      clearField(key) {
        this.set(['data', key].join('.'), '');
      }

    }
  });

  _exports.default = _default;
});