define("adt-wss/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "adt-wss/config/environment", "adt-wss/services/app-dispatcher"], function (_exports, _applicationRouteMixin, _environment, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    apiService: Ember.inject.service(),
    myadtSecurity: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    ssoInfo: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    csrInfo: Ember.inject.service(),
    userIdle: Ember.inject.service(),
    router: Ember.inject.service(),
    csrIdleCounter: 0,
    routeAfterAuthentication: _environment.default.routeAfterAuthentication,

    beforeModel() {
      this._super(...arguments);

      const URL = window.location.pathname + window.location.search;
      return this.checkAuthAndSsoEnabled().then(data => {
        const session = this.get('session');

        if (data.adtUser || data.csrUser) {
          if (!session.isAuthenticated) {
            return session.authenticate('authenticator:myadt');
          }

          return data;
        }

        return Ember.RSVP.Promise.reject();
      }).then(data => {
        if (!this.get('myadtSecurity.picProvided')) {
          return this.startAuth();
        }

        return data;
      }).then(data => {
        if (data.csrUser) {
          this.get('router').transitionTo(URL);
        }

        return data;
      }).catch(() => {
        if (this.get('session.isAuthenticated')) {
          this.get('session').invalidate(false, 'ApplicationRoute.noAuth');
        }

        console.log('Not logged in.');
      });
    },

    checkAuthAndSsoEnabled() {
      return this.get('ssoInfo').getIsSsoEnabled().then(() => this.get('authInfo').getAuthInfo());
    },

    startAuth() {
      return Ember.RSVP.hash({
        security: this.get('myadtSecurity').start(),
        profileInfo: this.get('currentLocation').getAccountInfo(true)
      });
    },

    init() {
      this._super(...arguments);

      if (_environment.default.environment === 'production') {
        this.get('userIdle').on('idleChanged', isIdle => {
          this.get('apiService').combined.heartbeat();

          if (isIdle && this.get('session.isAuthenticated')) {
            if (this.get('csrInfo.isCsr')) {
              if (this.get('csrIdleCounter') === 95) {
                this.get('appDispatcher').sendEvent(_appDispatcher.SHOW_TIMEOUT_MODAL_WINDOW);
              } else {
                this.incrementProperty('csrIdleCounter');
              }
            } else {
              this.get('appDispatcher').sendEvent(_appDispatcher.SHOW_TIMEOUT_MODAL_WINDOW);
            }
          }
        });
      }
    }

  });

  _exports.default = _default;
});