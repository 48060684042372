define("adt-wss/pods/dashboard/shop/productsservices/battery/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zPVl5s0F",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"dashboard-order-battery\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"widgets-row\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"dashboard/shop/shop-battery\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/shop/productsservices/battery/template.hbs"
    }
  });

  _exports.default = _default;
});