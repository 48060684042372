define("adt-wss/initializers/app-version", ["exports", "ember-cli-app-version/initializer-factory", "adt-wss/config/environment"], function (_exports, _initializerFactory, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    APP: {
      name,
      version
    }
  } = _environment.default;
  var _default = {
    name: 'App Version',
    initialize: (0, _initializerFactory.default)(name, version)
  };
  _exports.default = _default;
});