define("adt-wss/templates/modal-windows/unenroll-paperless-statements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y9vp6HJU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"paperless-statements-modal-wrap\"],[8],[0,\"\\n\\t\"],[7,\"h5\",true],[10,\"class\",\"paperless-statements-modal-title\"],[8],[0,\"Enroll Account #\"],[1,[24,[\"body\",\"accountId\"]],false],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"Do you wish to receive paper billing statements by mail for this account?\"],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[7,\"strong\",true],[8],[0,\"A $1 monthly fee will be added for paper statements.\"],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/unenroll-paperless-statements.hbs"
    }
  });

  _exports.default = _default;
});