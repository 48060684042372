define("adt-wss/pods/dashboard/account/payments/onetimepayment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cF9MGBbz",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"dashboard/account/payment-options\",null,[[\"flexFiPreselect\"],[[24,[\"flexfi\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/account/payments/onetimepayment/template.hbs"
    }
  });

  _exports.default = _default;
});