define("adt-wss/pods/components/dashboard/account/billing-history/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/myadt-helpers", "adt-wss/pods/components/dashboard/account/billing-history/constants"], function (_exports, _withFsm, _myadtHelpers, constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const dateRanges = [{
    value: 3,
    text: 'Past 3 Months'
  }, {
    value: 6,
    text: 'Past 6 Months'
  }, {
    value: 9,
    text: 'Past 9 Months'
  }, {
    value: 12,
    text: 'Past 12 Months'
  }, {
    value: 24,
    text: 'Past 24 Months'
  }];
  const {
    IDLE_STATE,
    FETCHING_STATE,
    GET_HISTORY_ACTION,
    VIEW_HISTORY_ACTION,
    RESOLVE_GET_HISTORY_ACTION,
    REJECT_GET_HISTORY_ACTION
  } = constants;
  const fsmModel = {
    scrollToTop: false,
    transitions: {
      idle: {
        [GET_HISTORY_ACTION]: FETCHING_STATE,
        [VIEW_HISTORY_ACTION]: IDLE_STATE
      },
      [FETCHING_STATE]: {
        [RESOLVE_GET_HISTORY_ACTION]: IDLE_STATE,
        [REJECT_GET_HISTORY_ACTION]: IDLE_STATE
      }
    }
  };
  /** */

  /**
   * @module Components/Dashboard/Account/BillingHistory
   * @prop {String} currentState
   * @prop {StateMachine} fsm
   * @prop {Object} dateRange date range for visible history lines in table
   * @prop {Int} dateRange.value
   * @prop {String} dateRange.text
   * @prop {Object[]} dateRanges
   * @prop {StatusMessage[]} statusMessages
   * @prop {Computed<ARHistoryLine[]>} billingHistory
   * @prop {Computed<ARHistoryLine[]>} visibleHistory history lines within the date range
   * @prop {String} accountId passed from template
   * @prop {String} billingSystem passed from template
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    billingData: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    contactData: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    classNames: ['billing-history'],
    fsmModel,
    currentState: IDLE_STATE,
    dateRange: dateRanges[3],
    dateRanges,
    statusMessages: null,
    paperless: Ember.computed.oneWay('contactData.paperlessOption'),
    billingHistory: Ember.computed.readOnly('billingData.billingHistory'),
    visibleHistory: Ember.computed('billingHistory', 'dateRange', function () {
      const range = this.get('dateRange.value') || 12;
      const start = Date.getDateAfterNDays(range * -30);
      const history = this.get('billingHistory');
      return (history || []).filter(l => l.dateObj > start);
    }),
    isFetching: Ember.computed.equal('currentState', FETCHING_STATE),

    /**
     * Runs API call then dispatches resolve/reject method
     */
    [GET_HISTORY_ACTION]() {
      this.get('billingData').getBillingHistory().then(() => this.get('fsm').dispatch(RESOLVE_GET_HISTORY_ACTION)).catch(() => this.get('fsm').dispatch(REJECT_GET_HISTORY_ACTION));
    },

    /**
     * Starts download of AR history XLS (Excel) file
     */
    [VIEW_HISTORY_ACTION]() {
      const {
        accountId,
        billingSystem,
        rootURL
      } = this.getProperties('accountId', 'billingSystem', 'rootURL');
      const rootPath = (0, _myadtHelpers.getOrigin)() + rootURL;
      const csrUser = this.get('authInfo.authInfo.csrUser');
      const searchId = sessionStorage.getItem('chudlySearchId');
      let url = "".concat(rootPath, "api/auth/bill/history?customerNumber=").concat(accountId, "&billingSystem=").concat(billingSystem);

      if (csrUser) {
        url += "&searchId=".concat(searchId || '');
      }

      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Billing', 'AR History Download']);
      window.open(url);
    },

    init() {
      this._super(...arguments);

      if (!this.get('billingHistory')) this.get('fsm').dispatch(GET_HISTORY_ACTION);
      if (!this.get('paperless')) this.get('contactData').getPMOC();
    }

  });

  _exports.default = _default;
});