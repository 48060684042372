define("adt-wss/templates/modal-windows/system-test-wizard/arm-system-instruction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8WYsqV2B",
    "block": "{\"symbols\":[\"pr\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"test-system-wizard-info-modal test-wizard-instruction\"],[10,\"role\",\"region\"],[10,\"aria-label\",\"Arm your system in the 'Away' mode as you normally would when leaving your home.\\n\\tIf testing a motion detector you'll need to open/close the door as if you were leaving the house.\\n\\tIf you don't open/close the door, your system will be set to Armed Stay and you won't be able to test the motion detector(s)\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"test-system-wizard-progress\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[8],[0,\"Progress\"],[9],[0,\"\\n\"],[4,\"bs-progress\",null,null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"bar\"]],\"expected `pr.bar` to be a contextual component but found a string. Did you mean `(component pr.bar)`? ('adt-wss/templates/modal-windows/system-test-wizard/arm-system-instruction.hbs' @ L7:C5) \"],null]],[[\"value\"],[20]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"test-wizard-info-text system-test-arm-system-instruction-info\"],[8],[0,\"\\n\\t\\t\"],[7,\"h4\",true],[10,\"class\",\"test-wizard-info-title\"],[8],[0,\"Arm your system\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"\\n\\t\\t\\tArm your system in the \"],[7,\"strong\",true],[8],[0,\"Away\"],[9],[0,\" mode as you normally would when leaving your home\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"test-wizard-info-description\"],[8],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"\\n\\t\\t\\tIf testing a motion detector you'll need to open/close the door as if you were leaving the house.\\n\\t\\t\\tIf you don't open/close the door, your system will be set to Armed Stay and you won't be able to test the motion detector(s)\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/system-test-wizard/arm-system-instruction.hbs"
    }
  });

  _exports.default = _default;
});