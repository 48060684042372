define("adt-wss/pods/components/dashboard/preinstall-widget/due-installation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d4vpgtK+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"due-to-installation\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"installation-info\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"small\"],[8],[0,\"DUE AT INSTALLATION:\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"price\"],[8],[7,\"span\",true],[8],[1,[28,\"currency\",[[24,[\"installationData\",\"dueAtInstallation\"]]],null],false],[9],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"estimated\"],[8],[7,\"span\",true],[8],[0,\"(Estimated)\"],[9],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"row-item \",[28,\"if\",[[24,[\"media\",\"isSmartphone\"]],\"pointer\"],null]]]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"blue-link angle-bracket-link\"],[3,\"action\",[[23,0,[]],\"billingSummary\",[24,[\"installationData\"]]]],[8],[0,\"View billing summary\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/preinstall-widget/due-installation/template.hbs"
    }
  });

  _exports.default = _default;
});