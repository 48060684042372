define("adt-wss/pods/components/stupid/usage-agreement/component", ["exports", "adt-wss-common/utils/ember-helpers"], function (_exports, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    extraClasses: [],
    seleniumId: '',
    inputId: "usageAgreementField-".concat(Math.floor(Math.random() * 1000)),
    checked: false
  };
  /**
   * @module Components/Stupid/UsageAgreement
   * @prop {String[]} classes
   * @prop {String} seleniumId
   * @prop {String} seleniumId
   * @prop {String} inputId
   * @prop {Boolean} checked
   * @example
   * {@lang xml} {{stupid/usage-agreement
   *   extraClasses=['class1', 'class2']
   *   seleniumId='test'
   *   inputId='id'
   *   checked=checked
   * }}
   */

  var _default = Ember.Component.extend(defaultProps, {
    defaultWrapClass: 'checkbox-wrapper',
    classes: (0, _emberHelpers.computedApplyFunction)((d, e) => [d].concat(e).join(' '), 'defaultWrapClass', 'extraClasses')
  });

  _exports.default = _default;
});