define("adt-wss/pods/components/suggested-features/component", ["exports", "adt-wss/mixins/with-fsm", "adt-wss/utils/fp", "adt-wss/utils/ember-helpers"], function (_exports, _withFsm, _fp, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const suggestedFeatures = [{
    type: 'device',
    title: 'Device feature 1',
    count: 658,
    voted: false,
    tip: 'Device feature 1 description',
    completed: false
  }, {
    type: 'device',
    title: 'Device feature 2',
    count: 1254,
    voted: true,
    tip: 'Device feature 2 description',
    completed: false
  }, {
    type: 'device',
    title: 'Device feature 3',
    count: 6546,
    voted: false,
    tip: 'Device feature 3 description',
    completed: false
  }, {
    type: 'device',
    title: 'Device feature 4',
    count: 128,
    voted: true,
    tip: 'Device feature 4 description',
    completed: false
  }, {
    type: 'device',
    title: 'Device feature 5',
    count: 987,
    voted: false,
    tip: 'Device feature 5 description',
    completed: true
  }, {
    type: 'settings',
    title: 'Settings feature 1',
    count: 6548,
    voted: false,
    tip: 'Settings feature 1 description',
    completed: false
  }, {
    type: 'settings',
    title: 'Settings feature 2',
    count: 3546,
    voted: false,
    tip: 'Settings feature 2 description',
    completed: true
  }, {
    type: 'apps',
    title: 'Application feature 1',
    count: 2568,
    voted: false,
    tip: 'Application feature 1 description',
    completed: false
  }, {
    type: 'apps',
    title: 'Application feature 2',
    count: 633,
    voted: true,
    tip: 'Application feature 2 description',
    completed: false
  }];
  const viewOptions = ['Most Popular', 'The Newest', 'Completed'];

  var _default = Ember.Component.extend(_withFsm.default, {
    mwd: Ember.inject.service('modal-window-dispatcher'),
    viewOptions,
    suggestedFeatures,
    filterValue: 'Most Popular',
    features: (0, _emberHelpers.computedApplyFunction)(suggestedFeatures => suggestedFeatures.sort((prev, next) => next.count - prev.count), 'suggestedFeatures'),
    actions: {
      vote(index) {
        const currentFeature = this.get('suggestedFeatures')[index];
        const newCurrentFeature = (0, _fp.omix)(currentFeature, {
          voted: !currentFeature.voted,
          count: currentFeature.voted ? currentFeature.count - 1 : currentFeature.count + 1
        });
        const suggestedFeatureNew = this.get('suggestedFeatures').map((el, i) => {
          if (i === index) {
            return newCurrentFeature;
          }

          return el;
        });
        this.set('suggestedFeatures', suggestedFeatureNew);
      },

      loadMore() {},

      suggest() {
        const mwd = this.get('mwd');

        const onClose = () => {
          mwd.hide();
        };

        const onEnter = () => {
          mwd.hide();
        };

        mwd.show({
          name: 'suggest-feature',
          title: 'Suggest Your Idea',
          onClose,
          onEnter,
          actions: [{
            caption: 'Cancel',
            stl: 'outline',
            callback: onClose
          }, {
            caption: 'Submit',
            callback: onEnter
          }]
        });
      }

    }
  });

  _exports.default = _default;
});