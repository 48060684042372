define("adt-wss/pods/components/ps/myadt-login-acc/component", ["exports", "adt-wss-common/utils/localization", "adt-wss-common/utils/myadt-helpers", "adt-wss/pods/components/ps/myadt-login-acc/constants", "adt-wss-common/mixins/with-fsm"], function (_exports, _localization, _myadtHelpers, constants, _withFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    fieldErrors: {
      acctNum: false,
      zip: false
    }
  };
  const {
    IDLE_STATE,
    FETCHING_ACCOUNT_STATE,
    LOCATE_ACTION,
    RESOLVE_LOCATE_ACTION,
    REJECT_LOCATE_ACTION
  } = constants;
  const fsmModel = {
    transitions: {
      [IDLE_STATE]: {
        [LOCATE_ACTION]: FETCHING_ACCOUNT_STATE
      },
      [FETCHING_ACCOUNT_STATE]: {
        [RESOLVE_LOCATE_ACTION]: IDLE_STATE,
        [REJECT_LOCATE_ACTION]: IDLE_STATE
      }
    }
  };
  /**
   * Account Registration Component
   * @module Components/MyADTLoginAcc
   * @prop {Services/ExpressPayService} EPS expresspay-service
   *
   * @prop {String} currentState for StateMachine
   * @prop {Boolean} isFetching
   * @prop {Int|String} adtAcctNo
   * @prop {String[]} errorMessages
   * @prop {Object} fieldErrors
   * @prop {String} chatId generated by chat window
   *
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    attributeBindings: ['selenium-id'],
    'selenium-id': 'expresspay-form',
    EPS: Ember.inject.service('expresspay-service'),
    router: Ember.inject.service(),
    fsmModel,
    currentState: IDLE_STATE,
    rejectCount: 0,
    chatId: '',
    billingZipCode: '',
    adtAcctNo: '',
    errorMessages: null,
    isFetching: Ember.computed('currentState', function () {
      const state = this.get('currentState');
      return /ing/i.test(state);
    }),

    /**
     * Resets form to account search
     * Clears errors
     */
    reset() {
      this.setProperties({
        errorMessages: null,
        fieldErrors: {
          acctNum: false,
          zip: false
        }
      });
    },

    /**
     * Find billing data based on account number and zip code
     * Dispatches [RESOLVE_LOCATE_ACTION] or [REJECT_LOCATE_ACTION]
     * Includes validation
     */
    [LOCATE_ACTION]() {
      // eslint-disable-next-line prefer-const
      let {
        adtAcctNo,
        billingZipCode,
        chatId
      } = this.getProperties('adtAcctNo', 'billingZipCode', 'chatId');
      const {
        EPS,
        fsm
      } = this.getProperties('EPS', 'fsm');
      const errors = [];
      this.reset();
      adtAcctNo = adtAcctNo.trim();
      billingZipCode = billingZipCode.trim();
      chatId = window.sessionStorage.getItem("pypestream-webchat-chat-id");
      const isPS = this.set('isPS', true);

      if (adtAcctNo && billingZipCode) {
        if (/^[\d]*$/.test(adtAcctNo) && /^[\d]{5}$/.test(billingZipCode)) {
          return EPS.getBillInfo(adtAcctNo, billingZipCode, isPS, chatId).then(data => {
            return this.get('fsm').dispatch(RESOLVE_LOCATE_ACTION);
          }).catch(results => {
            return this.get('fsm').dispatch(REJECT_LOCATE_ACTION, results);
          });
        }

        if (!/^[\d]*$/.test(adtAcctNo)) {
          this.set('fieldErrors.acctNum', true);
          errors.push((0, _localization.toExpandedString)('Registration.accountNumberMustBeNumeric'));
        }

        if (!/^[\d]{5}$/.test(billingZipCode)) {
          this.set('fieldErrors.zip', true);
          errors.push((0, _localization.toExpandedString)('Payment.errorCCZipCodeLength'));
        }
      } else {
        if (!adtAcctNo) this.set('fieldErrors.acctNum', true);
        if (!billingZipCode) this.set('fieldErrors.zip', true);
        errors.push((0, _localization.toExpandedString)('General.errorAllFieldsRequired'));
      }

      return this.get('fsm').dispatch(REJECT_LOCATE_ACTION, errors);
    },

    /**
     * Advances after locations are retrieved.
     * If only one location, dispatches `SELECT_LOCATION_ACTION`.
     * @param {Object} data
     */
    [RESOLVE_LOCATE_ACTION]() {
      this.redirectPage();
    },

    /**
     * Pass-through, riggers `handleErrors`
     * @param {ApiResponse} results
     */
    [REJECT_LOCATE_ACTION](results) {
      this.incrementProperty('rejectCount');
      this.handleErrors(results);
    },

    /**
     * Displays error messages and error states
     * @param {Object} results
     * @param {String[]|String} errors
     */
    handleErrors(results) {
      this.set('errorMessages', (0, _myadtHelpers.genericErrorHandler)(results));

      if (this.get('rejectCount') >= 4) {
        const detail = {
          'accError': (0, _myadtHelpers.genericErrorHandler)(results)
        };
        window.top.postMessage(detail, '*');
        this.redirectPage();
      }
    },

    redirectPage() {
      this.set('isFetching', true);
      window.setTimeout(function () {
        if (/www.myadt|\/\/myadt.com/i.test(document.location.href)) {
          window.location.replace('https://webchat.pypestream.com/redirect.html');
        } else {
          window.location.replace('https://webchat-sandbox.pypestream.com/redirect.html');
        }
      }, 1000);
    },

    actions: {
      /**
       * locating the accounts
       */
      locate() {
        this.get('fsm').dispatch(LOCATE_ACTION);
      },

      /**
       * Passthrough to {@link Classes/StateMachine.dispatch}
       * @param {String} action
       * @param {*} data
       * @param {Boolean} scroll
       */
      dispatch(action, data, scroll) {
        this.get('fsm').dispatch(action, data, scroll);
      }

    },

    init() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});