define("adt-wss/pods/components/dashboard/manage/add-report/component", ["exports", "ember-copy", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/classes/validator", "adt-wss-common/utils/common-fsm-state", "adt-wss/pods/components/dashboard/manage/add-report/schematics", "adt-wss/pods/components/dashboard/manage/add-report/constants"], function (_exports, _emberCopy, _withFsm, _emberHelpers, _myadtHelpers, _validator, _commonFsmState, _schematics, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fsmModel = {
    transitions: {
      [_commonFsmState.IDLE_STATE]: {
        [_constants.START_ADDING_REPORT_ACTION]: _constants.LOCATIONS_LIST_STATE
      },
      [_constants.LOCATIONS_LIST_STATE]: {
        [_constants.NEXT_ACTION]: _constants.REPORT_ADJUSTMENT_STATE,
        [_constants.CLOSE_MODAL_WINDOW_ACTION]: _commonFsmState.IDLE_STATE
      },
      [_constants.REPORT_ADJUSTMENT_STATE]: {
        [_constants.NEXT_ACTION]: _constants.FINAL_STATE,
        [_constants.GO_BACK_ACTION]: _constants.LOCATIONS_LIST_STATE,
        [_constants.CLOSE_MODAL_WINDOW_ACTION]: _commonFsmState.IDLE_STATE
      },
      [_constants.FINAL_STATE]: {
        [_constants.GO_BACK_ACTION]: _constants.REPORT_ADJUSTMENT_STATE,
        [_constants.UPDATE_DATA_ACTION]: _constants.UPDATING_STATE,
        [_constants.CLOSE_MODAL_WINDOW_ACTION]: _commonFsmState.IDLE_STATE
      },
      [_constants.UPDATING_STATE]: {
        [_commonFsmState.SUCCESS_ACTION]: _commonFsmState.IDLE_STATE,
        [_commonFsmState.FAILURE_ACTION]: _constants.FINAL_STATE
      }
    }
  };
  const defaultProps = {
    /**
     * Handler for form submitting
     * @param {Object} data
     * @return {void}
     */

    /* eslint-disable no-unused-vars */
    onSubmit: data => console.error('Please pass the "onSubmit" param to "dashboard/manage/add-report" component'),
    locations: []
  };
  /**
   * @module Components/Dashboard/Manage/AddReport
   * @param {Function} onSubmit - handler for submitting
   * @param {Object[]} locations - list of locations
   * @param {report} report - for adding it's empty object
   * @example
   * {@lang xml} {{dashboard/manage/add-report
  		onSubmit=(action 'submit')
  		locations=locations
  		report=report
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, (0, _emberHelpers.initializeObjects)(defaultProps), {
    mwd: Ember.inject.service('modal-window-dispatcher'),
    fsmModel,
    reportAdjustmentFormSchema: _schematics.reportAdjustmentFormSchema,
    tagName: 'button',
    classNames: ['add-business-report'],
    modalName: 'Create Business Report',
    saveBtnName: 'Create Report',
    MAX_EMAILS_COUNT: 10,
    IS_IDLE_STATE: (0, _emberHelpers.isState)(_commonFsmState.IDLE_STATE),
    IS_LOCATIONS_LIST_STATE: (0, _emberHelpers.isState)(_constants.LOCATIONS_LIST_STATE),
    IS_REPORT_ADJUSTMENT_STATE: (0, _emberHelpers.isState)(_constants.REPORT_ADJUSTMENT_STATE),
    IS_FINAL_STATE: (0, _emberHelpers.isState)(_constants.FINAL_STATE),
    IS_UPDATING_STATE: (0, _emberHelpers.isState)(_constants.UPDATING_STATE),

    init(...args) {
      this._super(...args);

      this.initializeValidators();
      this.selectLocationHandler = this.selectLocationHandler.bind(this);
      this.changePeriodHandler = this.changePeriodHandler.bind(this);
      this.selectDayHandler = this.selectDayHandler.bind(this);
      this.selectEventHandler = this.selectEventHandler.bind(this);
      this.selectAllEventsHandler = this.selectAllEventsHandler.bind(this);
      this.addEmailAddress = this.addEmailAddress.bind(this);
      this.removeEmailAddress = this.removeEmailAddress.bind(this);
      this.createEmailPlaceholder = this.createEmailPlaceholder.bind(this);
      this.prepareInitialData();
    },

    /**
     * Sets unique `report` prop for each instance of component
     *
     * @function
     */
    prepareInitialData() {
      this.set('report', (0, _emberCopy.copy)(_schematics.reportSchema, true));
    },

    /**
     * Initializes validator for each step of creation.
     * Adds `onValidate` listener for each validator.
     * Sets `dataGettersTable` object for getting data of precise step.
     * Triggered on `init`
     *
     * @function
     */
    initializeValidators() {
      const mwd = this.get('mwd');
      this.validationStepsRules = {
        [_constants.LOCATIONS_LIST_STATE]: new _validator.default({
          addressIds: {
            rules: ['isNotEmptyArray'],
            staticInstruction: 'Please select a location or location group'
          }
        }),
        [_constants.REPORT_ADJUSTMENT_STATE]: new _validator.default({
          eventTypes: {
            rules: ['isNotEmptyArray'],
            staticInstruction: 'Please select at least one event.'
          },
          sendEmailOnDays: {
            rules: ['isNotEmptyArray'],
            staticInstruction: 'Please select at least one day.'
          }
        }),
        [_constants.FINAL_STATE]: new _validator.default({
          name: {
            rules: ['isNotEmpty'],
            staticInstruction: 'Please enter a name for the report.'
          },
          email: {
            isArray: true,
            rules: ['isEmail'],
            staticInstruction: 'Please enter a valid email address.'
          }
        })
      };
      this.dataGettersTable = {
        [_constants.LOCATIONS_LIST_STATE]: () => ({
          addressIds: this.get('report.addressIds')
        }),
        [_constants.FINAL_STATE]: () => ({
          name: this.get('report.name'),
          email: this.get('report.email')
        }),
        [_constants.REPORT_ADJUSTMENT_STATE]: () => ({
          eventTypes: this.get('report.eventTypes'),
          sendEmailOnDays: this.get('report.sendEmailOnDays')
        })
      };
      Object.values(this.validationStepsRules).forEach(validator => validator.onValidate(({
        messages,
        values = []
      }) => {
        this.set('errors', messages);

        if (!values[0]) {
          return mwd.clearMessages();
        }

        return mwd.showError(values[0]);
      }));
    },

    /**
     * Updates `report.addressIds` prop
     *
     * @function
     */
    selectLocationHandler(list = []) {
      this.set('report.addressIds', list);
    },

    /**
     * Handler for checkbox selecting (monthly and quarter frequencies)
     * Triggered on `selectDayHandler`
     *
     * @function
     */
    selectRegularEntity(value, list = []) {
      if (list.includes(value)) {
        return list.filter(o => o !== value);
      }

      return list.concat(value);
    },

    /**
     * Handler for checkboxes selecting (weekly frequency)
     * Triggered on `selectDayHandler`
     *
     * @function
     */
    selectWeekDay(day, list = []) {
      return list.includes(day) ? [] : [day];
    },

    /**
     * Determines handler for click on day checkbox
     * Updates `report.sendEmailOnDay` prop
     *
     * @function
     */
    selectDayHandler(day, frequency) {
      const selector = 'report.sendEmailOnDays';
      const chosenDays = this.get(selector);
      const handler = parseInt(frequency, 10) === _constants.WEEKLY_PERIOD ? 'selectWeekDay' : 'selectRegularEntity';
      this.set(selector, this[handler](day, chosenDays));
    },

    /**
     * Handler for selecting event types
     * Updates `report.eventTypes` prop
     *
     * @function
     */
    selectEventHandler(event) {
      const selector = 'report.eventTypes';
      const chosenEventsList = this.get(selector);
      this.set(selector, this.selectRegularEntity(event, chosenEventsList));
    },

    /**
     * Handler for selecting all event types
     * Updates `report.eventTypes` prop
     *
     * @function
     */
    selectAllEventsHandler() {
      const selector = 'report.eventTypes';
      const chosenEventsList = this.get(selector);
      const {
        eventTypes
      } = _schematics.reportAdjustmentFormSchema;
      let newList = [];

      if (chosenEventsList.length !== eventTypes.length) {
        newList = eventTypes.map(e => e.value);
      }

      this.set(selector, newList);
    },

    /**
     * Adds a new email field if count of emails less than `MAX_EMAILS_COUNT`
     * Updates `report.email` prop
     *
     * @function
     */
    addEmailAddress() {
      const list = this.get('report.email');
      const MAX_EMAILS_COUNT = this.get('MAX_EMAILS_COUNT');

      if (list.length >= MAX_EMAILS_COUNT) {
        return false;
      }

      return list.pushObject();
    },

    /**
     * Remove an email.
     * Updates `report.email` prop
     *
     * @function
     * @param {Number} index - index of element which should be deleted
     */
    removeEmailAddress(index) {
      const selector = 'report.email';
      const list = this.get(selector);
      this.set(selector, list.filter((e, i) => i !== index));
    },

    /**
     * Updates frequency and cleans up `report.sendEmailOnDays` property;
     *
     * @function
     * @param {String} value
     */
    changePeriodHandler(value) {
      this.set('report.days', value);
      this.set('report.sendEmailOnDays', []);
    },

    /**
     * Resets report to initial state and resets errors
     * Updates `report` and `errors` props
     *
     * @function
     */
    resetDataState() {
      this.set('report', (0, _emberCopy.copy)(_schematics.reportSchema, true));
      this.set('errors', null);
    },

    /**
     * Forms placeholder for each email field
     * Increases the first alphabet
     *
     * @function
     * @return {String}
     */
    createEmailPlaceholder(index) {
      const A_CHAR_CODE = 97;
      const PLACEHOLDER = 'smith@abc-corp.com';
      return String.fromCharCode(A_CHAR_CODE + index).concat(PLACEHOLDER);
    },

    /**
     * Click handler for the current component.
     * Dispatches `START_ADDING_REPORT_ACTION`
     *
     * @function
     */
    click() {
      this.get('fsm').dispatch(_constants.START_ADDING_REPORT_ACTION);
    },

    /**
     * Pre-dispatch hook.
     * Validates data before dispatch.
     * Triggered on `START_ADDING_REPORT_ACTION`
     *
     * @function
     * @param {String} action - needed action
     * @param {Any} finalPayload - Payload which will be passed to `action`
     */
    preDispatch(action, finalPayload) {
      const {
        currentState,
        fsm
      } = this.getProperties('currentState', 'fsm');
      const validationStepRule = this.validationStepsRules[currentState];
      const data = this.dataGettersTable[currentState]();

      if (validationStepRule.validate(data)) {
        // `true` if validation is failed
        return false;
      }

      return fsm.dispatch(action, finalPayload);
    },

    /**
     * Closes modal window and resets state
     *
     * @function
     */
    [_constants.CLOSE_MODAL_WINDOW_ACTION]() {
      const mwd = this.get('mwd');
      this.resetDataState();
      mwd.hide();
    },

    /**
     * Opens modal window.
     * There is only one modal window for three steps.
     * Visibility of buttons is adjusted by `hidden` property for objects of `actions` array
     *
     * @function
     */
    [_constants.START_ADDING_REPORT_ACTION]() {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');

      const onEnter = () => {
        const cs = this.get('currentState');

        if (cs === _constants.FINAL_STATE) {
          return this.preDispatch(_constants.UPDATE_DATA_ACTION, this.get('report'));
        }
      };

      mwd.show({
        title: this.modalName,
        name: 'create-update-business-report',
        body: this,
        onClose: () => fsm.dispatch(_constants.CLOSE_MODAL_WINDOW_ACTION),
        onEnter,
        actions: [{
          caption: 'Go Back',
          name: 'go-back',
          stl: 'outline',
          hidden: (0, _emberHelpers.computedApplyFunction)(cs => ![_constants.REPORT_ADJUSTMENT_STATE, _constants.FINAL_STATE].some(s => s === cs), 'currentState'),
          callback: () => fsm.dispatch(_constants.GO_BACK_ACTION)
        }, {
          caption: 'Next',
          name: 'next',
          hidden: (0, _emberHelpers.computedApplyFunction)(cs => ![_constants.LOCATIONS_LIST_STATE, _constants.REPORT_ADJUSTMENT_STATE].some(s => s === cs), 'currentState'),
          callback: () => this.preDispatch(_constants.NEXT_ACTION)
        }, {
          caption: this.saveBtnName,
          name: 'create-report',
          hidden: (0, _emberHelpers.computedApplyFunction)(cs => cs !== _constants.FINAL_STATE, 'currentState'),
          callback: () => this.preDispatch(_constants.UPDATE_DATA_ACTION, this.get('report'))
        }]
      });
    },

    /**
     * Clears all modal's messages
     *
     * @function
     */
    [_constants.GO_BACK_ACTION]() {
      this.get('mwd').clearMessages();
    },

    /**
     * Handler for click on `Create Report` button
     * Assumes that `onSubmit` method returns {Promise}
     *
     * @function
     * @param {Object} data - data which will be passed as request body
     * @return {Promise}
     */
    [_constants.UPDATE_DATA_ACTION](data) {
      return this.commonDispatcher(() => this.onSubmit(data));
    },

    /**
     * Handler for successful updating
     * Calls `CLOSE_MODAL_WINDOW_ACTION` directly (without fsm dispatching)
     *
     * @function
     */
    [_commonFsmState.SUCCESS_ACTION]() {
      this[_constants.CLOSE_MODAL_WINDOW_ACTION]();
    },

    /**
     * Handler for failed updating
     * Shows errors from the `results` param
     *
     * @function
     * @param {Object} results - errors from the BE
     */
    [_commonFsmState.FAILURE_ACTION](results) {
      this.get('mwd').showError((0, _myadtHelpers.genericErrorHandler)(results)[0]);
    }

  });

  _exports.default = _default;
});