define("adt-wss/pods/components/dashboard/account/payment-options/controls-flow", ["exports", "adt-wss-common/utils/common-fsm-state", "adt-wss/pods/components/dashboard/account/payment-options/constants"], function (_exports, _commonFsmState, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.forwardFlow = _exports.backFlow = void 0;
  const backFlow = {
    [_constants.BALANCE_DUE_PAYMENT_ID]: {
      [_constants.PAYMENT_AMOUNT_ENTRY_STATE]: _constants.RESET_STATE_ACTION,
      [_constants.PAYMENT_METHOD_SELECTION_STATE]: _constants.TRANSITION_TO_PAYMENT_AMOUNT_ENTRY_STATE,
      [_constants.PAYMENT_DETAILS_CONFIRMATION_STATE]: _constants.TRANSITION_TO_PAYMENT_METHOD_SELECTION_STATE
    },
    [_constants.OTHER_AMOUNT_PAYMENT_ID]: {
      [_constants.PAYMENT_AMOUNT_ENTRY_STATE]: _constants.RESET_STATE_ACTION,
      [_constants.PAYMENT_METHOD_SELECTION_STATE]: _constants.TRANSITION_TO_PAYMENT_AMOUNT_ENTRY_STATE,
      [_constants.PAYMENT_DETAILS_CONFIRMATION_STATE]: _constants.TRANSITION_TO_PAYMENT_METHOD_SELECTION_STATE
    },
    [_constants.FLEXFI_PAYMENT_ID]: {
      [_constants.FLEXFI_PAYMENT_SELECTION_STATE]: _constants.RESET_STATE_ACTION,
      [_constants.PAYMENT_METHOD_SELECTION_STATE]: _constants.TRANSITION_TO_FLEXFI_PAYMENT_SELECTION_STATE,
      [_constants.PAYMENT_DETAILS_CONFIRMATION_STATE]: _constants.TRANSITION_TO_PAYMENT_METHOD_SELECTION_STATE
    }
  };
  _exports.backFlow = backFlow;
  const forwardFlow = {
    [_constants.BALANCE_DUE_PAYMENT_ID]: {
      [_commonFsmState.IDLE_STATE]: _constants.ENTER_PAYMENT_AMOUNT_ACTION,
      [_constants.PAYMENT_AMOUNT_ENTRY_STATE]: _constants.SELECT_PAYMENT_METHOD_ACTION,
      [_constants.PAYMENT_METHOD_SELECTION_STATE]: _constants.CONFIRM_PAYMENT_DETAILS_ACTION
    },
    [_constants.OTHER_AMOUNT_PAYMENT_ID]: {
      [_commonFsmState.IDLE_STATE]: _constants.ENTER_PAYMENT_AMOUNT_ACTION,
      [_constants.PAYMENT_AMOUNT_ENTRY_STATE]: _constants.SELECT_PAYMENT_METHOD_ACTION,
      [_constants.PAYMENT_METHOD_SELECTION_STATE]: _constants.CONFIRM_PAYMENT_DETAILS_ACTION
    },
    [_constants.FLEXFI_PAYMENT_ID]: {
      [_commonFsmState.IDLE_STATE]: _constants.SELECT_FLEXFI_PAYMENT_ACTION,
      [_constants.FLEXFI_PAYMENT_SELECTION_STATE]: _constants.SELECT_PAYMENT_METHOD_ACTION,
      [_constants.PAYMENT_METHOD_SELECTION_STATE]: _constants.CONFIRM_PAYMENT_DETAILS_ACTION
    }
  };
  _exports.forwardFlow = forwardFlow;
});