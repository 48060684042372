define("adt-wss/pods/ps/expresspay/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PSExpressPayController = (_dec = Ember.computed('cc'), (_class = (_temp = class PSExpressPayController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      this.queryParams = ['pmtAmt', 'pmtDate', 'cc', 'uID'];
    }

    get isCC() {
      return this.cc === 'true';
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "isCC", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isCC"), _class.prototype)), _class));
  _exports.default = PSExpressPayController;
});