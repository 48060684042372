define("adt-wss/pods/components/dashboard/shop/kit-order-summary/component", ["exports", "adt-wss/pods/components/dashboard/shop/battery-order-summary/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    isStandardShipping: Ember.computed.equal('shipping', 'standard')
  });

  _exports.default = _default;
});