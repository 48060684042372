define("adt-wss/pods/components/dashboard/account/contract-renewal/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/common-fsm-state", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/fp", "adt-wss/services/promotion-data", "adt-wss/pods/components/dashboard/account/contract-renewal/constants"], function (_exports, _withFsm, _emberHelpers, _commonFsmState, _myadtHelpers, _fp, _promotionData, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    offers: null,
    showAlert: v => v,
    onSuccess: v => v
  };
  const fsmModel = {
    transitions: {
      [_commonFsmState.IDLE_STATE]: {
        [_constants.SHOW_RENEWAL_OPTIONS_ACTIONS]: _constants.RENEWAL_OPTIONS_STATE
      },
      [_constants.RENEWAL_OPTIONS_STATE]: {
        [_constants.CLOSE_RENEWAL_OPTIONS_ACTION]: _commonFsmState.IDLE_STATE,
        [_constants.RENEW_ACTION]: _constants.SHOW_CONTRACT_RENEWAL_MODAL_STATE
      },
      [_constants.SHOW_CONTRACT_RENEWAL_MODAL_STATE]: {
        [_constants.CLOSE_RENEWAL_MODAL_ACTION]: _constants.RENEWAL_OPTIONS_STATE,
        [_constants.CONTINUE_RENEWAL_ACTION]: _constants.SIGN_PROCESS_STATE
      },
      [_constants.SIGN_PROCESS_STATE]: {
        [_constants.ESCAPE_SIGN_PROCESS_ACTION]: _constants.RENEWAL_OPTIONS_STATE
      }
    }
  };
  /**
   * @module Components/Dashboard/Account/ContractRenewal
   * @prop {Array} offers - API response with available contract renewal offers
   * @prop {Function} showAlert
   * @prop {Function} sendEventTag
   * @prop {Function} onSuccess
   * @example
   * {@lang xml} {{ dashboard/account/contract-renewal
  		showAlert=(action 'showAlert')
  		onSuccess=(action 'hideRenewalOffers')
  		sendEventTag=(action 'sendEventTag')
  		offers=offers
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    mwd: Ember.inject.service('modal-window-dispatcher'),
    currentLocation: Ember.inject.service(),
    fsmModel,
    accountNumber: Ember.computed.oneWay('currentLocation.profileInfo.accountId'),
    CONTRACT_RENEWAL_INSTRUCTIONS: _constants.CONTRACT_RENEWAL_INSTRUCTIONS,
    selected: null,
    maxOfferAmount: (0, _emberHelpers.computedApplyFunction)(offers => Math.max(...offers.map(o => o.creditAmount)), 'offers'),
    classNames: ['contract-renewal'],
    IS_SIGN_PROCESS_STATE: (0, _emberHelpers.isState)(_constants.SIGN_PROCESS_STATE),
    IS_IDLE_STATE: (0, _emberHelpers.isState)(_commonFsmState.IDLE_STATE),
    IS_RENEWAL_OPTIONS_STATE: (0, _emberHelpers.isState)(_constants.RENEWAL_OPTIONS_STATE),
    IS_SHOW_CONTRACT_RENEWAL_MODAL_STATE: (0, _emberHelpers.isState)(_constants.SHOW_CONTRACT_RENEWAL_MODAL_STATE),
    selectedUdf2Option: (0, _emberHelpers.computedApplyFunction)((offers, selectedOfferId) => {
      const offer = (offers || []).find(o => o.offerId === selectedOfferId);

      if (!offer) {
        return null;
      }

      return "".concat(offer.renewalDurationInYears, "_YR_CONT_RNWL_$").concat(offer.creditAmount, "_CREDIT");
    }, 'offers', 'selected'),

    init(...args) {
      this._super(...args);

      this.concatParams = this.concatParams.bind(this);
      this.concatYearDuration = this.concatYearDuration.bind(this);
    },

    /**
     * Concatenates element of array
     *
     * @function
     * @param {Array} data - array of available offers
     * @param {String} prop - specific property which needs to be concatenated
     * @returns {String}
    */
    concatParams(data, prop) {
      return data.map((el, i, arr) => {
        const computeProp = prop === 'creditAmount' ? "$".concat(el[prop]) : "".concat(el[prop]);
        return arr.length - 1 === i ? computeProp : "".concat(computeProp, " or");
      }).join(' ');
    },

    /**
    * Creates new properties in offers array and concatenates it
    * Calls `concatParams` function
    *
    * @function
    * @param {Array} offers - array of available offers
    * @param {String} stringDurationInYears - duration in years
    * @returns {String}
    */
    concatYearDuration(offers, stringDurationInYears) {
      const computeOffers = offers.map(el => (0, _fp.omix)(el, {
        stringDurationInYears: Ember.get(_constants.YEARS_HASH_TABLE, el.renewalDurationInYears)
      }));
      return this.concatParams(computeOffers, stringDurationInYears);
    },

    /**
    * Sends GA tracking and opens offers options block
    */
    [_constants.SHOW_RENEWAL_OPTIONS_ACTIONS]() {
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Contract Renewal 2', 'Renewal Options']);
    },

    [_constants.CONTINUE_RENEWAL_ACTION]() {
      this[_constants.CLOSE_RENEWAL_MODAL_ACTION]();

      this.sendEventTag({
        subEventTypeId: _promotionData.eventTagValues.CLICKED_CONTINUE_BUTTON_ON_CONTRACT_MODAL,
        udf2: this.get('selectedUdf2Option')
      });
    },

    /**
    * Closes renewal modal window
    */
    [_constants.CLOSE_RENEWAL_MODAL_ACTION]() {
      const mwd = this.get('mwd');
      mwd.hide();
    },

    /**
    * Closes renewal offers section and sets selected value to null
    */
    [_constants.CLOSE_RENEWAL_OPTIONS_ACTION]() {
      this.sendEventTag({
        subEventTypeId: _promotionData.eventTagValues.ACCOUNT_DOCUMENT_CLOSE_BTN,
        udf2: this.get('selectedUdf2Option')
      });
      this.set('selected', null);
    },

    [_constants.RENEW_ACTION]() {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');

      const onClose = () => {
        fsm.dispatch(_constants.CLOSE_RENEWAL_MODAL_ACTION);
        this.sendEventTag({
          subEventTypeId: _promotionData.eventTagValues.CONTRACT_RENEWAL_MODAL_CLOSE_BTN,
          udf2: this.get('selectedUdf2Option')
        });
      };

      mwd.show({
        name: 'contract-renewal',
        title: 'Contract Renewal',
        onClose,
        body: this,
        bigCallback: true,
        isFooterLine: !this.get('media.isSmartphone'),
        actions: [{
          caption: 'Close',
          stl: 'outline',
          callback: onClose
        }, {
          caption: 'Continue',
          callback: () => fsm.dispatch(_constants.CONTINUE_RENEWAL_ACTION)
        }]
      });
    },

    actions: {
      /**
       * Sends event tag and dispatches `SHOW_RENEWAL_OPTIONS_ACTIONS` event
       *
       * @function
       */
      showRenewalOptions() {
        this.sendEventTag({
          subEventTypeId: _promotionData.eventTagValues.CLICKED_RENEWAL_OPTIONS
        });
        this.fsm.dispatch(_constants.SHOW_RENEWAL_OPTIONS_ACTIONS);
      },

      /**
       * Triggered on change of checkbox
       * @function
      */
      onSelectChange(value) {
        this.set('selected', this.get('selected') === value ? null : value);
      },

      /**
      * Gets years duration world by years duration number
      * Calls `concatParams` function
      *
      * @function
      * @param {Number} durationInYears - duration in years
      * @returns {String}
      */
      getYearString(durationInYears) {
        return "".concat(Ember.get(_constants.YEARS_HASH_TABLE, durationInYears), " ").concat(durationInYears === 1 ? 'year' : 'years');
      },

      /**
       * Opens modal window for Loyalty offer
       *
       * @function
      */
      showLoyaltyOfferModal() {
        this.sendEventTag({
          subEventTypeId: _promotionData.eventTagValues.CLICKED_LEARN_MORE_LOYALTY_OFFER
        });
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Contract Renewal 2', 'Loyalty Offer Modal', 'Account Documents']);
        const mwd = this.get('mwd');

        const onClose = () => {
          mwd.hide();
        };

        mwd.show({
          name: 'loyalty-offer-contract-renewal',
          title: 'Loyalty Offer',
          onClose,
          isFooterLine: !this.get('media.isSmartphone'),
          bigCallback: true,
          body: this,
          actions: [{
            caption: 'Done',
            callback: onClose
          }]
        });
      }

    }
  });

  _exports.default = _default;
});