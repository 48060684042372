define("adt-wss/pods/components/ps/credit-enquiryform/component", ["exports", "adt-wss-common/utils/localization", "adt-wss-common/utils/myadt-helpers", "adt-wss/pods/components/ps/credit-enquiryform/constants", "adt-wss-common/mixins/with-fsm"], function (_exports, _localization, _myadtHelpers, constants, _withFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    IDLE_STATE,
    FETCHING_STATE,
    SUBMIT_ACTION,
    RESOLVE_SUBMIT_ACTION,
    REJECT_SUBMIT_ACTION,
    COMPLETED_STATE,
    SUBMIT_SUCCESS_ACTION
  } = constants;
  const fsmModel = {
    transitions: {
      [IDLE_STATE]: {
        [SUBMIT_ACTION]: FETCHING_STATE
      },
      [FETCHING_STATE]: {
        [RESOLVE_SUBMIT_ACTION]: COMPLETED_STATE,
        [REJECT_SUBMIT_ACTION]: IDLE_STATE
      },
      [COMPLETED_STATE]: {
        [SUBMIT_SUCCESS_ACTION]: IDLE_STATE
      }
    }
  };
  /**
   * Credit Enquiry Component
   * @module Components/CreditEnquiryForm
   *
   * @prop {String} currentState for StateMachine
   * @prop {Boolean} isFetching
   * @prop {Int|String} taxNumLast4
   * @prop {String} dateOfBirth
   * @prop {String[]} errorMessages
   * @prop {Object} fieldErrors
   * @prop {String} chatId generated by page URL
   *
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    attributeBindings: ['selenium-id'],
    'selenium-id': 'credit-enquiryform',
    apiService: Ember.inject.service(),
    fsmModel,
    currentState: IDLE_STATE,
    chatID: '',
    taxNumLast4: '',
    dateOfBirth: '',
    errorMessages: null,
    isFetching: Ember.computed('currentState', function () {
      const state = this.get('currentState');
      return /ing/i.test(state);
    }),
    successCount: 0,
    blockSubmit: false,

    /**
     * Clears errors
     */
    reset() {
      this.setProperties({
        errorMessages: null,
        fieldErrors: {
          dob: false,
          ssn: false
        }
      });
    },

    /**
     * Submits date of birth, last four ssn and chatID to API
     * Dispatches [RESOLVE_SUBMIT_ACTION] or [REJECT_SUBMIT_ACTION]
     * Includes validation
     */
    [SUBMIT_ACTION]() {
      // eslint-disable-next-line prefer-const
      let {
        dateOfBirth,
        taxNumLast4
      } = this.getProperties('dateOfBirth', 'taxNumLast4');
      const path = window.location.pathname;
      const chatID = path.substring(path.lastIndexOf('/') + 1);
      const errors = [];
      this.reset();
      taxNumLast4 = taxNumLast4.trim();

      if (dateOfBirth && taxNumLast4) {
        const dob = dateOfBirth.toISOString().split('T')[0];

        if (dob && /^[\d]{4}$/.test(taxNumLast4)) {
          this.incrementProperty('successCount');

          if (this.get('successCount') > 1) {
            this.set('blockSubmit', true);
            return this.get('fsm').dispatch(REJECT_SUBMIT_ACTION);
          }

          return this.get('apiService').myADT.credCheckForm({
            chatID,
            dob,
            taxNumLast4
          }).then(response => {
            return this.get('fsm').dispatch(RESOLVE_SUBMIT_ACTION, response);
          }).catch(results => {
            return this.get('fsm').dispatch(REJECT_SUBMIT_ACTION, results);
          });
        }

        if (!/^[\d]{4}$/.test(taxNumLast4)) {
          this.set('fieldErrors.ssn', true);
          errors.push('Invalid SSN value');
        }
      } else {
        if (!dateOfBirth) {
          this.set('fieldErrors.dob', true);
          errors.push('Please select date from popup');
        }

        if (!taxNumLast4) this.set('fieldErrors.ssn', true);
        errors.push((0, _localization.toExpandedString)('General.errorAllFieldsRequired'));
      }

      return this.get('fsm').dispatch(REJECT_SUBMIT_ACTION, errors);
    },

    /**
     * Advances after submission is success.
     */
    [RESOLVE_SUBMIT_ACTION](response) {//pending close tab on form submit 
    },

    /**
     * Pass-through, triggers `handleErrors`
     * @param {ApiResponse} results
     */
    [REJECT_SUBMIT_ACTION](results) {
      this.handleErrors(results);
    },

    /**
     * Displays error messages and error states
     * @param {Object} results
     * @param {String[]|String} errors
     */
    handleErrors(results) {
      this.set('errorMessages', (0, _myadtHelpers.genericErrorHandler)(results));
    },

    actions: {
      /**
       * Dispatches [SUBMIT_ACTION]
       */
      submit() {
        this.get('fsm').dispatch(SUBMIT_ACTION);
      }

    },

    init() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});