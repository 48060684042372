define("adt-wss/pods/dashboard/alarm/controller", ["exports", "adt-wss/mixins/dashboard-status-messages", "adt-wss-common/utils/ember-helpers"], function (_exports, _dashboardStatusMessages, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const links = [{
    route: 'dashboard.alarm.system',
    text: 'Alarm System',
    hideIfNoPic: true
  }, {
    route: 'dashboard.alarm.systemtest',
    text: 'System Test',
    hideIfNoPic: true
  }, {
    route: 'dashboard.alarm.history',
    text: 'Alarm History',
    hideIfNoPic: true
  }, {
    route: 'dashboard.alarm.appointments',
    text: 'Service Appointments'
  }];

  var _default = Ember.Controller.extend(_dashboardStatusMessages.default, {
    myadtSecurity: Ember.inject.service(),
    links: (0, _emberHelpers.computedApplyFunction)(picProvided => links.filter(l => {
      let out = true;
      out = !(l.hideIfNoPic && !picProvided);
      return out;
    }), 'myadtSecurity.picProvided')
  });

  _exports.default = _default;
});