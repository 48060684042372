define("adt-wss/services/promotion-data", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/fp"], function (_exports, _myadtHelpers, _fp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.eventTagValues = void 0;
  const eventTagValues = {
    ONLINE_CONTRACT_RENEWAL: 'ONCONREN',
    ALERT_DISPLAYED: 'ALRTDISP',
    ALERT_CLOSED: 'ALRTCLSD',
    CLICKED_REMIND_ME_LATER: 'CLKDRLTR',
    CLICKED_LEARN_MORE: 'CLKDLNMR',
    CLICKED_RENEWAL_OPTIONS: 'CLKDROPT',
    CLICKED_LEARN_MORE_LOYALTY_OFFER: 'CLKDLMLO',
    ACCOUNT_DOCUMENT_CLOSE_BTN: 'ADOCCLBT',
    CONTRACT_RENEWAL_MODAL_CLOSE_BTN: 'CRMCBTN',
    CLICKED_CONTINUE_BUTTON_ON_CONTRACT_MODAL: 'CLKDCBCR',
    DOCUSIGN_MODAL_LOAD: 'DOCUSNML',
    DOCUSIGN_MODAL_LOAD_FAILURE: 'DOCUSNLF',
    PHASE2_BATCH1: 'PHASE2_BATCH1'
  };
  _exports.eventTagValues = eventTagValues;
  const clearCacheArgs = {
    promotions: null,
    renewalOffers: null,
    content: null,
    formInfo: null
  };
  /**
   * Data about user's promotions and active sweepstake promos.
   * @module Services/SweepstakeData
   * @prop {SweepstakesFormInfo} formInfo data for sweepstake form
   * @prop {{Array<Object>}} promotions array of active promotions for user
   * @prop {SweepstakeContent} SweepstakeContent data content for sweepstake-promos tab
   */

  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)(clearCacheArgs), {
    apiService: Ember.inject.service(),
    eventTagging: Ember.inject.service(),
    promotions: null,
    formInfo: null,
    content: null,
    sweepStakeSubmitted: false,
    renewalOffers: null,
    systemPlanDetails: null,

    /**
     * Generates contract renewal document details
     * Calls '/api/v2/contractRenewalWithOffers' endpoint
     *
     * @function
     * @param {Object} data
     * @returns {Promise}
     */
    generateContractRenewalDocDetails(data) {
      return this.apiService.myADT.generateContractRenewalDocDetails(data);
    },

    /**
     * Allows BE to double-check signature with the DocuSign resource
     * Calls '/api/v2/contractRenewalWithOffers' endpoint
     *
     * @function
     * @param {Object} data
     * @returns {Promise}
     */
    contractRenewalWithOffers(data) {
      return this.apiService.myADT.contractRenewalWithOffers(data);
    },

    /**
     * Processes form info response to properly populate
     * @param {Object} formInfo API response
     * @return {Promise}
     */
    processFormInfo(formInfo) {
      return new Promise(resolve => {
        const firstName = Ember.String.capitalize((formInfo.firstName || '').toLowerCase());
        const lastName = Ember.String.capitalize((formInfo.lastName || '').toLowerCase());
        const phoneNumber = (0, _myadtHelpers.addPhoneMask)(formInfo.phoneNumber, '-');
        const zipCodeSplited = (formInfo.zipcode || '').match(/\d{5}/g);
        const zipcode = zipCodeSplited ? zipCodeSplited[0] : '';

        const _formInfo = (0, _fp.omix)(formInfo, {
          lastName,
          firstName,
          phoneNumber,
          zipcode
        });

        this.set('formInfo', _formInfo);
        Ember.run(null, resolve, _formInfo);
      });
    },

    /**
     * Get Promo info from API and sets `promotions`
     * @return {Promise}
     */
    getPromoInfo() {
      return this.get('apiService').myADT.getPromotionInfo().then(response => {
        this.set('promotions', response.data.promotions);
        return response.data;
      });
    },

    /**
     * Get Contract Renewal Alert Content
     * @return {Promise}
     */
    getContractRenewalAlertContent(contractRenewalPromoElement) {
      return this.get('apiService').myADT.getContractRenewalAlertContent({
        promotionType: contractRenewalPromoElement.promotionType
      }).then(response => {
        const promotions = this.get('promotions').map(el => {
          if (el.promotionType.indexOf('ONLINE_CONTRACT_RENEWAL') >= 0) {
            el.data = response.data;
          }

          return el;
        });
        this.set('promotions', promotions);
        return response.data;
      });
    },

    /**
     * Define if contract renewal promo should be shown
     * @return {Promise}
     */
    showContractRenewal() {
      return this.getPromoInfo().then(response => {
        const contractRenewalPromoElement = (response.promotions || []).find(el => el.promotionType.includes('ONLINE_CONTRACT_RENEWAL'));

        if (contractRenewalPromoElement && !contractRenewalPromoElement.maxDispositionReached) {
          return this.getContractRenewalAlertContent(contractRenewalPromoElement).then(contractRenewalAlertContent => {
            if (!contractRenewalAlertContent) {
              const newPromotions = response.promotions.filter(el => el.promotionType !== contractRenewalPromoElement.promotionType);
              this.set('promotions', newPromotions);
            }
          });
        }
      });
    },

    /**
    * Get Contract Renewal Offers Content
    * @param {String} promotionType type of promotion
    * @return {Promise}
    */
    getRenewalOffers(promotionType) {
      return this.get('apiService').myADT.getRenewalOffers({
        promotionType
      }).then(resp => {
        this.set('renewalOffers', resp.data);
        return resp.data;
      });
    },

    /**
     * Define if contract renewal offers should be shown
     * @return {Promise}
     */
    showRenewalOffers() {
      const contractRenewalPromoElement = (this.get('promotions') || []).find(el => el.promotionType.includes('ONLINE_CONTRACT_RENEWAL'));

      if (contractRenewalPromoElement) {
        return this.getRenewalOffers(contractRenewalPromoElement.promotionType);
      }

      return Promise.resolve();
    },

    /**
     * Get content for Sweepstake tab from API and sets `data`
     * @returns {Promise}
     */
    getSweepstakeContent() {
      return this.get('apiService').myADT.getSweepstakeContent().then(response => {
        const content = response.data.map(el => (0, _fp.omix)(el, {
          drawDate: Date.createDate(el.drawDate).toAltString('full'),
          endDate: Date.createDate(el.endDate).toAltString('full'),
          submittedDate: el.submittedDate === null ? el.submittedDate : Date.createDate(el.submittedDate).toAltString('full'),
          presubmissionNotificationMsg: el.submittedDate === null ? el.presubmissionNotificationMsg : ''
        }));
        this.set('content', content[0] || []);
        return content;
      });
    },

    /**
     * Get data for sweepstake form and calls processFormInfo on resolve
     * @returns {Promise}
     */
    getSweepstakeFormInfo() {
      return this.get('apiService').myADT.getSweepstakeFormInfo().then(response => this.processFormInfo(response.data));
    },

    /**
     * Submit form for sweepstake and sets sweepStakeSubmitted property to true
     * @param {Object} data info from sweepstake form
     */
    submitSweepstakeForm(data) {
      const phoneNumber = (0, _myadtHelpers.removePhoneMask)(data.phoneNumber);
      const zipCode = data.zipcode;
      const info = (0, _fp.omix)((0, _fp.omit)(data, 'zipcode'), {
        phoneNumber,
        zipCode
      });
      return this.get('apiService').myADT.submitSweepstakeForm(info).then(() => this.set('sweepStakeSubmitted', true)).then(() => this.getSweepstakeContent());
    },

    /**
     *	Disable promotions for user if successful submitted promotion
     * @param {Object} promotions promotions object with promotionType property
     */
    incrementDispositionCounter(promotionType) {
      return this.get('apiService').myADT.incrementDispositionCounter({
        promotionType
      });
    },

    /**
     * Dispatches event tag
     * Calls '/api/auth/customerEvent/tagCustomerEvent'
     *
     * @function
     * @param {Object} payload
     * @returns {Promise}
     */
    sendEventTag(payload) {
      return this.eventTagging.sendEventTag((0, _fp.omix)(payload, {
        eventTypeId: eventTagValues.ONLINE_CONTRACT_RENEWAL
      }), false, eventTagValues.PHASE2_BATCH1);
    },

    getSystemPlanDetails(sysNoValue) {
      const data = {
        systemNo: sysNoValue
      };
      return this.get('apiService').myADT.getSystemPlanDetails(data).then(response => {
        this.set('systemPlanDetails', response.data);
        return response.data;
      });
    }

  });

  _exports.default = _default;
});