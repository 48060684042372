define("adt-wss/pods/components/index/main-nav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('isContractMode', Number(window.sessionStorage.getItem('contractMode')));
    }

  });

  _exports.default = _default;
});