define("adt-wss/pods/components/myadt-login/component", ["exports", "adt-wss-common/utils/localization", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers"], function (_exports, _localization, _myadtHelpers, _withFsm, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fsmModel = {
    transitions: {
      idle: {
        authenticate: 'fetchingLogin',
        startPasswordReset: 'passwordReset',
        startRegistration: 'register'
      },
      fetchingLogin: {
        resolveAuth: 'fetchingCustData',
        rejectAuth: 'idle'
      },
      fetchingCustData: {
        resolveCustData: 'loggedIn',
        rejectCustData: 'loggedIn'
      },
      loggedIn: null,
      loginFailure: {
        authenticate: 'fetchingLogin',
        startPasswordReset: 'passwordReset'
      },
      passwordReset: {
        returnToLogin: 'idle'
      },
      register: {
        returnToLogin: 'idle'
      },
      premiumRegistration: {}
    },
    context: void 0
  };
  /**
   * MyADT Login Component
   * @module Components/MyADTLogin
   * @prop {String} currentState
   * @prop {Computed<Boolean>} isFetching
   * @prop {String} loginUserId
   * @prop {String} loginPassword
   * @prop {Boolean} loginUserIdError
   * @prop {Boolean} deactivatedAccountError
   * @prop {Boolean} loginPasswordError
   * @prop {Boolean} showPasswordReset
   * @prop {Boolean} rememberMe
   * @prop {Boolean} agreeToTermsAndConditions
   * @prop {String} loginUser
   * @prop {String} resetUUID
   * @prop {Boolean} isPremiumRegistration
   * @prop {String} uuid
   * @prop {String} email
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    attributeBindings: ['selenium-id'],
    'selenium-id': 'myadt-login',
    session: Ember.inject.service(),
    apiService: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    billingData: Ember.inject.service(),
    router: Ember.inject.service(),
    myadtSecurity: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    contactData: Ember.inject.service(),
    alarmData: Ember.inject.service(),
    fsmModel,
    currentState: (0, _emberHelpers.computedApplyFunction)(premReg => premReg ? 'premiumRegistration' : 'idle', 'isPremiumRegistration'),
    loginUserId: '',
    loginPassword: '',
    loginUserIdError: false,
    deactivatedAccountError: false,
    loginPasswordError: false,
    errorMessages: null,
    showPasswordReset: false,
    rememberMe: false,
    agreeToTermsAndConditions: false,
    loginUser: null,
    resetUUID: null,
    rendered: false,
    isFetching: Ember.computed('currentState', function () {
      return /ing/i.test(this.get('currentState'));
    }),

    /** Shows password reset form */
    startPasswordReset() {
      this.set('showPasswordReset', true);
    },

    /**
     * Runs `session.authenticate`.
     * Resolves with `resolveAuth`
     */
    authenticate() {
      const {
        fsm,
        loginPassword
      } = this.getProperties('fsm', 'loginPassword');
      const ERROR_DEACTIVATED = 'MyADT.Login.Error.Deactivated';
      const ERROR_DEACTIVATED_PARTIAL = 'MyADT.Login.Error.DeactivatedPartial';
      let loginUserId = this.get('loginUserId');
      sessionStorage.removeItem('myADT_logoutReason');
      loginUserId = loginUserId.trim();

      if (loginUserId && loginUserId.isValidEmail() && loginPassword) {
        return this.get('session').authenticate('authenticator:myadt', loginUserId, loginPassword).then(() => {
          this.set('errorMessages', null);

          if (this.get('rememberMe')) {
            localStorage.setItem('myADT_rememberMe', loginUserId);
          } else {
            localStorage.removeItem('myADT_rememberMe');
          }

          return fsm.dispatch('resolveAuth');
        }).catch(results => {
          this.set('deactivatedAccountError', results.errors.includes(ERROR_DEACTIVATED));
          this.set('errorMessages', (results.errors || []).map(e => (0, _localization.toExpandedString)(e === ERROR_DEACTIVATED ? ERROR_DEACTIVATED_PARTIAL : e)));
          return fsm.dispatch('rejectAuth', results);
        });
      }

      const errors = [];

      if (loginUserId && loginUserId.isValidEmail()) {
        this.set('loginUserIdError', false);
      } else {
        this.set('loginUserIdError', true);

        if (!loginUserId) {
          errors.push((0, _localization.toExpandedString)('UserCredentials.userIdRequired'));
        } else if (!loginUserId.isValidEmail()) {
          errors.push((0, _localization.toExpandedString)('Login.errorInvalidEmail'));
        }
      }

      if (!loginPassword) {
        this.set('loginPasswordError', true);
        errors.push((0, _localization.toExpandedString)('UserCredentials.passwordRequired'));
      }

      this.set('errorMessages', errors);
      return fsm.dispatch('rejectAuth', {
        data: null
      });
    },

    resolveAuth() {
      const {
        redirect,
        fsm
      } = this.getProperties('redirect', 'fsm');

      if (redirect) {
        this.get('router').transitionTo(redirect);

        if (!/dashboard/.test(redirect)) {
          return this.startAuth().then(() => fsm.dispatch('resolveCustData')).catch(results => fsm.dispatch('rejectCustData', results));
        }
      }
    },

    rejectAuth(results) {
      return results.data;
    },

    resolveCustData() {// this.send('getUserData');
    },

    startAuth() {
      return Ember.RSVP.hash({
        security: this.get('myadtSecurity').start(),
        profileInfo: this.get('currentLocation').getAccountInfo(true)
      });
    },

    invalidateSession(reason) {
      if (this.get('session.isAuthenticated')) {
        this.get('session').invalidate(false, reason);
      }
    },

    actions: {
      dispatch() {
        this.get('fsm').dispatch(...arguments);
      },

      contactUs() {
        const message = 'customer inquiry';

        try {
          const pypemsg = [{
            attribute: 'error',
            label: 'error',
            value: {
              message
            },
            visible: 'wpm'
          }];
          Pypestream('config', {
            passthrough: JSON.stringify(pypemsg)
          });
          console.log("Pypestream msg sent: ".concat(message));
        } catch (e) {
          window.open('https://www.adt.com/contact-adt', '_blank');
        }
      },

      authenticate() {
        this.authenticate(...arguments);
      },

      returnToLogin() {
        this.set('currentState ', 'idle');
      },

      validateUserId(email) {
        const errors = [];
        const emailTrimmed = email.trim();

        if (emailTrimmed && !emailTrimmed.isValidEmail()) {
          this.set('loginUserIdError', true);
          errors.push((0, _localization.toExpandedString)('Login.errorInvalidEmail'));
        } else {
          this.set('loginUserIdError', false);
        }

        this.set('errorMessages', errors);
      }

    },

    clearErrors() {
      this.set('errorMessages', null);
    },

    handleErrors(results) {
      const errors = (0, _myadtHelpers.genericErrorHandler)(results);
      this.set('errorMessages', errors);
    },

    returnToLogin() {
      this.set('currentState ', 'idle');
    },

    init() {
      this._super(...arguments);

      if (localStorage.getItem('myADT_rememberMe')) {
        this.set('loginUserId', localStorage.getItem('myADT_rememberMe'));
        this.set('rememberMe', true);
      }

      if (sessionStorage.getItem('myADT_logoutReason')) {
        const reason = JSON.parse(sessionStorage.getItem('myADT_logoutReason'));
        if (reason.timestamp - Date.now() < 60000) this.set('errorMessages', reason.reason);
      }
    },

    didReceiveAttrs() {
      this._super(...arguments);

      const attemptedTransition = this.get('session.attemptedTransition');
      if (attemptedTransition) this.set('redirect', attemptedTransition);
    },

    didRender() {
      const loginUserIdEl = document.getElementById('loginUserId');

      if (!this.get('rendered')) {
        this.set('rendered', true);

        if (!loginUserIdEl) {
          return false;
        }

        loginUserIdEl.focus();
      }
    }

  });

  _exports.default = _default;
});