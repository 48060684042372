define("adt-wss/templates/modal-windows/sweepstakes-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CgxRg6Yt",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"dashboard/sweepstake-form\",null,[[\"contentData\",\"formState\",\"onClose\",\"title\"],[[24,[\"body\",\"sweepStakeInfo\"]],[24,[\"body\",\"formState\"]],[24,[\"onClose\"]],\"Enter to Win\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/sweepstakes-modal.hbs"
    }
  });

  _exports.default = _default;
});