define("adt-wss/pods/dashboard/manage/users/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.REJECT_DELETE_USER_ACTION = _exports.RESOLVE_DELETE_USER_ACTION = _exports.CANCEL_DELETE_USER_ACTION = _exports.SUBMIT_DELETE_USER_ACTION = _exports.START_DELETE_USER_ACTION = _exports.REJECT_REMOVE_USER_ADMIN_ACTION = _exports.RESOLVE_REMOVE_USER_ADMIN_ACTION = _exports.CANCEL_REMOVE_USER_ADMIN_ACTION = _exports.SUBMIT_REMOVE_USER_ADMIN_ACTION = _exports.START_REMOVE_USER_ADMIN_ACTION = _exports.REJECT_ADD_USER_ADMIN_ACTION = _exports.RESOLVE_ADD_USER_ADMIN_ACTION = _exports.CANCEL_ADD_USER_ADMIN_ACTION = _exports.SUBMIT_ADD_USER_ADMIN_ACTION = _exports.START_ADD_USER_ADMIN_ACTION = _exports.REJECT_ADD_USER_ACTION = _exports.RESOLVE_ADD_USER_ACTION = _exports.SUBMIT_ADD_USER_ACTION = _exports.CANCEL_ADD_USER_ACTION = _exports.ADD_USER_NEXT_STEP_ACTION = _exports.START_ADD_USER_ACTION = _exports.SUBMITTING_DELETE_USER_STATE = _exports.DELETE_USER_STATE = _exports.SUBMITTING_REMOVE_USER_ADMIN_STATE = _exports.REMOVE_USER_ADMIN_STATE = _exports.SUBMITTING_ADD_USER_ADMIN_STATE = _exports.ADD_USER_ADMIN_STATE = _exports.SUBMITTING_NEW_USER_STATE = _exports.ADD_USER_STEP3_STATE = _exports.ADD_USER_STEP2_STATE = _exports.ADD_USER_STEP1_STATE = _exports.CANCEL_ACTION = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const CANCEL_ACTION = 'CANCEL_ACTION';
  _exports.CANCEL_ACTION = CANCEL_ACTION;
  const ADD_USER_STEP1_STATE = 'ADD_USER_STEP1_STATE';
  _exports.ADD_USER_STEP1_STATE = ADD_USER_STEP1_STATE;
  const ADD_USER_STEP2_STATE = 'ADD_USER_STEP2_STATE';
  _exports.ADD_USER_STEP2_STATE = ADD_USER_STEP2_STATE;
  const ADD_USER_STEP3_STATE = 'ADD_USER_STEP3_STATE';
  _exports.ADD_USER_STEP3_STATE = ADD_USER_STEP3_STATE;
  const SUBMITTING_NEW_USER_STATE = 'SUBMITTING_NEW_USER_STATE';
  _exports.SUBMITTING_NEW_USER_STATE = SUBMITTING_NEW_USER_STATE;
  const ADD_USER_ADMIN_STATE = 'ADD_USER_ADMIN_STATE';
  _exports.ADD_USER_ADMIN_STATE = ADD_USER_ADMIN_STATE;
  const SUBMITTING_ADD_USER_ADMIN_STATE = 'SUBMITTING_ADD_USER_ADMIN_STATE';
  _exports.SUBMITTING_ADD_USER_ADMIN_STATE = SUBMITTING_ADD_USER_ADMIN_STATE;
  const REMOVE_USER_ADMIN_STATE = 'REMOVE_USER_ADMIN_STATE';
  _exports.REMOVE_USER_ADMIN_STATE = REMOVE_USER_ADMIN_STATE;
  const SUBMITTING_REMOVE_USER_ADMIN_STATE = 'SUBMITTING_REMOVE_USER_ADMIN_STATE';
  _exports.SUBMITTING_REMOVE_USER_ADMIN_STATE = SUBMITTING_REMOVE_USER_ADMIN_STATE;
  const DELETE_USER_STATE = 'DELETE_USER_STATE';
  _exports.DELETE_USER_STATE = DELETE_USER_STATE;
  const SUBMITTING_DELETE_USER_STATE = 'SUBMITTING_DELETE_USER_STATE';
  _exports.SUBMITTING_DELETE_USER_STATE = SUBMITTING_DELETE_USER_STATE;
  const START_ADD_USER_ACTION = 'START_ADD_USER_ACTION';
  _exports.START_ADD_USER_ACTION = START_ADD_USER_ACTION;
  const ADD_USER_NEXT_STEP_ACTION = 'ADD_USER_NEXT_STEP_ACTION';
  _exports.ADD_USER_NEXT_STEP_ACTION = ADD_USER_NEXT_STEP_ACTION;
  const CANCEL_ADD_USER_ACTION = 'CANCEL_ADD_USER_ACTION';
  _exports.CANCEL_ADD_USER_ACTION = CANCEL_ADD_USER_ACTION;
  const SUBMIT_ADD_USER_ACTION = 'SUBMIT_ADD_USER_ACTION';
  _exports.SUBMIT_ADD_USER_ACTION = SUBMIT_ADD_USER_ACTION;
  const RESOLVE_ADD_USER_ACTION = 'RESOLVE_ADD_USER_ACTION';
  _exports.RESOLVE_ADD_USER_ACTION = RESOLVE_ADD_USER_ACTION;
  const REJECT_ADD_USER_ACTION = 'REJECT_ADD_USER_ACTION';
  _exports.REJECT_ADD_USER_ACTION = REJECT_ADD_USER_ACTION;
  const START_ADD_USER_ADMIN_ACTION = 'START_ADD_USER_ADMIN_ACTION';
  _exports.START_ADD_USER_ADMIN_ACTION = START_ADD_USER_ADMIN_ACTION;
  const SUBMIT_ADD_USER_ADMIN_ACTION = 'SUBMIT_ADD_USER_ADMIN_ACTION';
  _exports.SUBMIT_ADD_USER_ADMIN_ACTION = SUBMIT_ADD_USER_ADMIN_ACTION;
  const CANCEL_ADD_USER_ADMIN_ACTION = 'CANCEL_ADD_USER_ADMIN_ACTION';
  _exports.CANCEL_ADD_USER_ADMIN_ACTION = CANCEL_ADD_USER_ADMIN_ACTION;
  const RESOLVE_ADD_USER_ADMIN_ACTION = 'RESOLVE_ADD_USER_ADMIN_ACTION';
  _exports.RESOLVE_ADD_USER_ADMIN_ACTION = RESOLVE_ADD_USER_ADMIN_ACTION;
  const REJECT_ADD_USER_ADMIN_ACTION = 'REJECT_ADD_USER_ADMIN_ACTION';
  _exports.REJECT_ADD_USER_ADMIN_ACTION = REJECT_ADD_USER_ADMIN_ACTION;
  const START_REMOVE_USER_ADMIN_ACTION = 'START_REMOVE_USER_ADMIN_ACTION';
  _exports.START_REMOVE_USER_ADMIN_ACTION = START_REMOVE_USER_ADMIN_ACTION;
  const SUBMIT_REMOVE_USER_ADMIN_ACTION = 'SUBMIT_REMOVE_USER_ADMIN_ACTION';
  _exports.SUBMIT_REMOVE_USER_ADMIN_ACTION = SUBMIT_REMOVE_USER_ADMIN_ACTION;
  const CANCEL_REMOVE_USER_ADMIN_ACTION = 'CANCEL_REMOVE_USER_ADMIN_ACTION';
  _exports.CANCEL_REMOVE_USER_ADMIN_ACTION = CANCEL_REMOVE_USER_ADMIN_ACTION;
  const RESOLVE_REMOVE_USER_ADMIN_ACTION = 'RESOLVE_REMOVE_USER_ADMIN_ACTION';
  _exports.RESOLVE_REMOVE_USER_ADMIN_ACTION = RESOLVE_REMOVE_USER_ADMIN_ACTION;
  const REJECT_REMOVE_USER_ADMIN_ACTION = 'REJECT_REMOVE_USER_ADMIN_ACTION';
  _exports.REJECT_REMOVE_USER_ADMIN_ACTION = REJECT_REMOVE_USER_ADMIN_ACTION;
  const START_DELETE_USER_ACTION = 'START_DELETE_USER_ACTION';
  _exports.START_DELETE_USER_ACTION = START_DELETE_USER_ACTION;
  const SUBMIT_DELETE_USER_ACTION = 'SUBMIT_DELETE_USER_ACTION';
  _exports.SUBMIT_DELETE_USER_ACTION = SUBMIT_DELETE_USER_ACTION;
  const CANCEL_DELETE_USER_ACTION = 'CANCEL_DELETE_USER_ACTION';
  _exports.CANCEL_DELETE_USER_ACTION = CANCEL_DELETE_USER_ACTION;
  const RESOLVE_DELETE_USER_ACTION = 'RESOLVE_DELETE_USER_ACTION';
  _exports.RESOLVE_DELETE_USER_ACTION = RESOLVE_DELETE_USER_ACTION;
  const REJECT_DELETE_USER_ACTION = 'REJECT_DELETE_USER_ACTION';
  _exports.REJECT_DELETE_USER_ACTION = REJECT_DELETE_USER_ACTION;
});