define("adt-wss/pods/components/dashboard/shop/battery-checkout/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SUCCESS_PRICE_DATA_ACTION = _exports.SUCCESS_BATTERY_DATA_ACTION = _exports.SUBMIT_ORDER_ACTION = _exports.FETCH_PRICE_DATA_ACTION = _exports.FETCH_BATTERY_DATA_ACTION = _exports.CONFIRMATION_STATE = _exports.LOADING_PRICE_DATA_STATE = void 0;
  const LOADING_PRICE_DATA_STATE = 'LOADING_PRICE_DATA_STATE';
  _exports.LOADING_PRICE_DATA_STATE = LOADING_PRICE_DATA_STATE;
  const CONFIRMATION_STATE = 'CONFIRMATION_STATE';
  _exports.CONFIRMATION_STATE = CONFIRMATION_STATE;
  const FETCH_BATTERY_DATA_ACTION = 'FETCH_BATTERY_DATA_ACTION';
  _exports.FETCH_BATTERY_DATA_ACTION = FETCH_BATTERY_DATA_ACTION;
  const FETCH_PRICE_DATA_ACTION = 'FETCH_PRICE_DATA_ACTION';
  _exports.FETCH_PRICE_DATA_ACTION = FETCH_PRICE_DATA_ACTION;
  const SUBMIT_ORDER_ACTION = 'SUBMIT_ORDER_ACTION';
  _exports.SUBMIT_ORDER_ACTION = SUBMIT_ORDER_ACTION;
  const SUCCESS_BATTERY_DATA_ACTION = 'SUCCESS_BATTERY_DATA_ACTION';
  _exports.SUCCESS_BATTERY_DATA_ACTION = SUCCESS_BATTERY_DATA_ACTION;
  const SUCCESS_PRICE_DATA_ACTION = 'SUCCESS_PRICE_DATA_ACTION';
  _exports.SUCCESS_PRICE_DATA_ACTION = SUCCESS_PRICE_DATA_ACTION;
});