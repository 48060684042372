define("adt-wss/pods/components/dashboard/account/manage-easypay/component", ["exports", "adt-wss/pods/components/dashboard/account/easypay-enroll/component", "adt-wss/pods/components/dashboard/account/saved-accounts/component", "adt-wss-common/classes/status-message", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/fp", "adt-wss-common/utils/myadt-helpers", "adt-wss/pods/components/dashboard/account/one-time-payment/component", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/common-fsm-state", "adt-wss/pods/components/dashboard/account/easypay-enroll/constants", "adt-wss/pods/components/dashboard/account/manage-easypay/constants"], function (_exports, _component, _component2, _statusMessage, _emberHelpers, _fp, _myadtHelpers, _component3, _appDispatcher, _commonFsmState, _constants, _constants2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable import/order */
  const fsmModel = (0, _fp.mergeDeep)(_component.fsmModel, {
    transitions: {
      [_commonFsmState.IDLE_STATE]: {
        [_commonFsmState.FETCH_DATA_ACTION]: _commonFsmState.LOADING_STATE,
        [_constants2.VIEW_FIRST_STEP_ACTION]: _constants2.VIEW_FIRST_STEP_STATE
      },
      [_constants2.VIEW_FIRST_STEP_STATE]: {
        [_constants2.CHOOSE_PAYMENT_ACTION]: _constants2.CHOOSE_PAYMENT_STATE,
        [_commonFsmState.FETCH_DATA_ACTION]: _commonFsmState.LOADING_STATE,
        [_constants2.CANCEL_FIRST_STEP_ACTION]: _commonFsmState.IDLE_STATE,
        [_constants2.VIEW_FIRST_STEP_ACTION]: _constants2.VIEW_FIRST_STEP_STATE,
        [_constants.CONFIRM_START_ACTION]: _constants.ENROLL_PAYMENT_METHOD_STATE,
        [_constants.START_ENROLL_ACTION]: _constants.ENROLL_INIT_STATE
      },
      [_constants2.CHOOSE_PAYMENT_STATE]: {
        [_constants.START_EDIT_EXP_ACTION]: _constants.EDIT_EXP_STATE,
        [_constants.CONFIRM_START_ACTION]: _constants.ENROLL_PAYMENT_METHOD_STATE,
        [_constants2.VIEW_FIRST_STEP_ACTION]: _constants2.VIEW_FIRST_STEP_STATE
      },
      [_constants.ENROLL_INIT_STATE]: {
        [_commonFsmState.FETCH_DATA_ACTION]: _commonFsmState.LOADING_STATE,
        [_constants.CANCEL_ACTION]: _constants.ENROLL_INIT_STATE,
        [_constants.STEP_BACK_ACTION]: _constants.ENROLL_INIT_STATE
      },
      [_commonFsmState.LOADING_STATE]: {
        [_constants2.SUCCESS_FETCH_ACTION]: _constants2.CHOOSE_PAYMENT_STATE,
        [_constants2.FAILURE_FETCH_ACTION]: _constants2.CHOOSE_PAYMENT_STATE,
        [_commonFsmState.SUCCESS_ACTION]: _commonFsmState.IDLE_STATE,
        [_constants.EDIT_SUCCESS_ACTION]: _commonFsmState.IDLE_STATE
      },
      [_constants.ENROLL_PAYMENT_METHOD_STATE]: {
        [_constants.CANCEL_ACTION]: _constants.ENROLL_INIT_STATE
      },
      [_constants.EDIT_EXP_STATE]: {
        [_constants.CANCEL_ACTION]: _constants.ENROLL_INIT_STATE
      },
      [_constants.ENROLL_VALIDATION_STATE]: {
        [_constants.CANCEL_ACTION]: _constants.ENROLL_INIT_STATE
      },
      [_constants.ENROLL_CONFIRMATION_STATE]: {
        [_constants.CANCEL_ACTION]: _constants.ENROLL_INIT_STATE
      }
    }
  });
  const ACH_TYPE = 'ACH';
  const UNAVAILABLE_VALUE = 'Unavailable';

  const handleCardNumber = v => v === UNAVAILABLE_VALUE ? v : "***".concat(v.slice(-4));

  const determAvailableValue = v => v || UNAVAILABLE_VALUE;

  const determAvailableValueByKey = (key, ...handlers) => (0, _fp.pipe)((0, _fp.get)(key), determAvailableValue, ...handlers);
  /**
   * @module Components/Dashboard/Account/manageEasypay
   * @prop {Function} setPendingMessageVisibility
   * @example
   * {@lang xml} {{ dashboard/account/manage-easypay
  		setPendingMessageVisibility=(action "setPendingMessageVisibility")
  }}
   */

  /**
   * Extends EasyPay enrollment component with new features/requirements
   */


  var _default = _component.default.extend([..._component2.default.PrototypeMixin.mixins].pop(), {
    fsmModel,
    tagName: 'div',
    easypayDetails: null,
    classNames: ['manage-easypay-module'],
    cardNumber: (0, _emberHelpers.computedApplyFunction)(determAvailableValueByKey('ccNumber', handleCardNumber), 'easypayDetails'),
    expDate: (0, _emberHelpers.computedApplyFunction)(determAvailableValueByKey('ccExpDate'), 'easypayDetails'),
    accountType: (0, _emberHelpers.computedApplyFunction)((easypayDetails, achEnrollmentType) => {
      const {
        ccType
      } = easypayDetails || {
        ccType: null
      };
      const ccTypeValue = determAvailableValue(ccType);
      return achEnrollmentType ? ACH_TYPE : ccTypeValue;
    }, 'easypayDetails', 'IS_ACH_ENROLLMENT_TYPE'),
    currentState: _constants2.VIEW_FIRST_STEP_STATE,
    initAction: _constants2.VIEW_FIRST_STEP_ACTION,
    IS_ACH_ENROLLMENT_TYPE: Ember.computed.equal('easypayDetails.enrollmentType', ACH_TYPE.toLowerCase()),
    IS_LOADING_STATE: (0, _emberHelpers.isState)(_commonFsmState.LOADING_STATE),
    appDispatcher: Ember.inject.service(),
    opened: false,
    firstStepTitle: 'Manage EasyPay',
    nextButtonDisabled: true,
    paymentOptions: null,
    selectedPay: null,

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
      if (this.get('isEnrolled')) this.selectPayment('monthly');
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
      this.setPendingMessageVisibility(true);
    },

    [_constants2.VIEW_FIRST_STEP_ACTION]() {
      if (!this.get('isEnrolled')) {
        this.set('firstStepTitle', 'Update EasyPay Payment Method');
      } else if (this.get('flexfiInstallmentData').length === 0) {
        return this.get('fsm').dispatch(_commonFsmState.FETCH_DATA_ACTION);
      }

      return this.set('opened', true);
    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      return this.get('fsm').dispatch(_commonFsmState.FETCH_DATA_ACTION, true);
    },

    [_commonFsmState.FETCH_DATA_ACTION](forceRequest) {
      return this.deliverDataAndDispatch('manageEasypay.details', manageEasypay => manageEasypay.getEasyPayDetails(), _constants2.SUCCESS_FETCH_ACTION, _constants2.FAILURE_FETCH_ACTION, false, false, {
        forceRequest
      });
    },

    /**
     * Handles successful enrollment
     * @method SUCCESS_ACTION
     * @param {Object} data
     * @param {String} data.confirmationNumber
     * @param {String} data.bankName
     * @param {String} data.notificationMessage
     * @param {String} data.pendingPaymentAmt
     * @param {String} data.balanceDueAmt
     * @param {String} data.newChargesAmt
     * @param {Boolean} data.balanceDue
     * @param {Boolean} data.paymentMadeToday
     * @param {Boolean} data.ccpayment
     */
    [_commonFsmState.SUCCESS_ACTION](data) {
      const {
        csrInfo,
        selectedPay,
        easypayDetails,
        isEnrolled
      } = this.getProperties('csrInfo', 'selectedPay', 'easypayDetails', 'isEnrolled');
      const type = this.get('paymentType') === 'bank' ? 'ACH' : 'CC';
      const successMsg = {
        head: selectedPay == 'monthly' ? 'EasyPay payment method successfully updated.' : "You have successfully updated EasyPay for FlexFi ".concat(selectedPay + 1, "."),
        text: '<p>Automatic payments won\'t begin until your next billing cycle.</p>'
      };

      if (selectedPay == 'monthly' && !isEnrolled) {
        successMsg.head = 'You successfully enrolled in EasyPay.';
      } else if (selectedPay !== 'monthly' && easypayDetails.noAutoPayDetails) {
        successMsg.head = "You have successfully enrolled in EasyPay for FlexFi ".concat(selectedPay + 1, ".");
      }

      let msgType = 'success';

      if (csrInfo.isCsr) {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', "CSR ".concat(type, " Easypay Enrollment"), "CSR Team:".concat(csrInfo.csr.team), "CSR Username:".concat(csrInfo.csr.username)]);
      }

      if (data) {
        if (data.confirmationNumber) {
          successMsg.head += " Transaction Number: ".concat(data.confirmationNumber);
        }

        if (data.ccpayment) {
          (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Easypay Enrollment', 'Enrollment Complete', 'Credit Card']);
        }

        if (data.notificationMessage) successMsg.text += "<p class=\"sub-text\">".concat(data.notificationMessage, "</p>");
        if (data.balanceDue) msgType = 'warn';
      }

      this.get('appDispatcher').sendEvent(_appDispatcher.EASYPAY_ENROLL_EVENT, null, this);
      return this.successWrap(successMsg, msgType);
    },

    /**
     * Success response for CC expiration update
     */
    [_constants.EDIT_SUCCESS_ACTION]() {
      const csrInfo = this.get('csrInfo');
      const successMsg = this.get('selectedPay') == 'monthly' ? 'EasyPay payment method successfully updated.' : "You have successfully updated EasyPay for FlexFi ".concat(this.get('selectedPay') + 1);

      if (csrInfo.isCsr) {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CSR Manage Easypay updateCCExpDate', "CSR Team:".concat(csrInfo.csr.team), "CSR Username:".concat(csrInfo.csr.username)]);
      }

      return this.successWrap(successMsg);
    },

    successWrap(message, type = 'success') {
      this.reset();
      this.get('mwd').hide();
      this.get('router').transitionTo('dashboard.account.payments.index');
      this.set('dsm.statusMessages', [new _statusMessage.default(message, type)]);
    },

    back() {
      if (this.get('flexfiInstallmentData').length === 0) {
        return this.send('closeModal');
      }

      return this.get('fsm').dispatch(_constants2.VIEW_FIRST_STEP_ACTION);
    },

    selectPayment(index) {
      this.set('selectedPay', index);

      if (!Number.isNaN(parseInt(index, 10))) {
        const flexfiInstallmentData = this.get("flexfiInstallmentData.".concat(index));

        if (flexfiInstallmentData.autoPayDetails) {
          this.set('paymentOptions', (0, _fp.omix)(flexfiInstallmentData.autoPayDetails, {
            noteNo: flexfiInstallmentData.noteNo
          }));
        } else {
          this.set('paymentOptions', (0, _fp.omix)(flexfiInstallmentData, {
            noAutoPayDetails: true
          }));
        }
      } else {
        this.set('paymentOptions', {});
        if (index !== 'monthly') return this.set('nextButtonDisabled', true);
      }

      return this.set('nextButtonDisabled', false);
    },

    enrollEasyPay() {
      this.set('opened', false);
      return this.get('fsm').dispatch(_constants.START_ENROLL_ACTION);
    },

    [_constants2.SUCCESS_FETCH_ACTION](data) {
      this.set('easypayDetails', data);
      this.set('nextButtonDisabled', false);
      this.set('opened', true);
    },

    [_constants2.FAILURE_FETCH_ACTION](error) {
      console.log('error', error);
      this.set('dsm.statusMessages', [new _statusMessage.default(error, 'error')]);
    },

    actions: {
      renderPaymentIcon(accountType) {
        let acctType = accountType === ACH_TYPE ? 'Bank' : accountType;
        if (/amex/i.test(acctType)) acctType = 'Amex';
        return (0, _component3.renderPaymentIcon)(acctType);
      },

      selectPayment() {
        return this.selectPayment(...arguments);
      },

      next() {
        const paymentOptions = this.get('paymentOptions');
        let action;

        if (paymentOptions.noAutoPayDetails) {
          this.set('easypayDetails', paymentOptions);
          return this.send('advanceChild', this.get('paymentType'));
        }

        if (paymentOptions.prepayNumber) {
          this.set('easypayDetails', paymentOptions);
          action = this.get('fsm').dispatch(_constants2.CHOOSE_PAYMENT_ACTION);
        } else {
          action = this.get('fsm').dispatch(_commonFsmState.FETCH_DATA_ACTION);
        }

        return action;
      },

      back() {
        if (this.get('flexfiInstallmentData').length === 0) {
          return this.send('closeModal');
        }

        return this.get('fsm').dispatch(_constants2.VIEW_FIRST_STEP_ACTION);
      },

      enrollEasyPay() {
        this.set('opened', false);
        return this.get('fsm').dispatch(_constants.START_ENROLL_ACTION);
      },

      closeModal() {
        this.set('opened', false);
        return this.get('router').transitionTo('dashboard.account.payments.index');
      },

      advanceChild(...args) {
        this.set('opened', false);
        this.advance(...args);
      }

    }
  });
  /* eslint-enable import/order */


  _exports.default = _default;
});