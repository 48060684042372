define("adt-wss/pods/index/usageagreement/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    apiService: Ember.inject.service(),

    model() {
      return new Ember.RSVP.Promise(resolve => {
        this.get('apiService').request({
          resource: {
            url: 'login/termsAndConditions',
            contract: 'login.termsAndConditions.txt',
            method: 'GET'
          }
        }).then(({
          data
        }) => resolve(data), ({
          data
        }) => resolve(data));
      });
    }

  });

  _exports.default = _default;
});