define("adt-wss/services/modal-window-dispatcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mediator between application and modal-window component
   * @module Services/ModalWindowDispatcher
   * @see modaule:Components/ModalWindow
  * @example
   * //mwd: inject('modalWindowDispatcher'),
   * const mwd = this.get('mwd');
   * mwd.show({
  	name: 'hello-world',
  	title: 'Hello World Modal',
  	body:{
  		name: 'Oleg',
  		surname: 'Sudavnyi'
  	},
  	isLoading: true,
  	onClose: function () {
  		this.set('isActive', false);
  	},
  	statusMessages: [{type: 'success', 'Congratilations'}],
  
  	actions: [{
  		caption: 'Go Back',
  		stl: 'outline',
  		callback() {
  			// parentView === {{stupid/button-component}}
  			const addMessageToListOfMessages = true;
  			mwd.showSussess('other usage', addMessageToListOfMessages)
  		}
  	},
  	{
  		caption: 'Done',
  		color: 'danger',
  		classes: ['hello-button'],
  		callback() {
  			mwd.get('showError')('Oh no');
  
  		}
  	}]
  });
   */
  var _default = Ember.Service.extend(Ember.Evented, {
    /**
     * Send action to show modal window with parameters to render modal window
     * @param  {showModalPayload} payload
     */
    show(payload) {
      this.trigger('show', payload);
    },

    /**
     * Send action to hide modal window
     * @param  {*} payload [description]
     */
    hide(payload) {
      this.trigger('hide', payload);
    },

    /**
     * Send action to show loading spinner on modal window
     */
    startLoading() {
      this.trigger('loading', true);
    },

    /**
     * Send action to hide loading spinner on modal window
     */
    stopLoading() {
      this.trigger('loading', false);
    },

    /**
     * Send the action to set a property variable for modal window
     * @param {String}  name - Name of property
     * @param {Any}  value - Value for property
     */
    setProperty(name, value) {
      this.trigger('setProperty', name, value);
    },

    /**
     * Send action to show message on the top of modal window
     * @param {String}  message - text which needs display
     * @param {String}  type - type of message: error | success | warn | info
     * @param {Boolean} isPush - add message to existing messages instead of replace them
     */
    setMessage(message, type, isPush) {
      this.trigger('setMessage', message, type, isPush);
    },

    /**
     * Send action to hide messages from modal window
     * @param {String} [type] - type of message: error | success | warn | info
     */
    clearMessages(type) {
      this.trigger('clearMessages', type);
    },

    /**
     * Send action to show error message on the top of modal window
     * @param {String}  message - text which needs display
     * @param {Boolean} isPush - add message to existing messages instead of replace them
     */
    showError(message, isPush) {
      this.trigger('showError', message, isPush);
    },

    /**
     * Show error messages in the top of modal window
     * @param {String[]} errors - Array of errors
     */
    showErrors(errors) {
      errors.forEach(text => this.showError(text, true));
    },

    /**
     * Send action to show error message on the top of modal window
     * @param {String}  message - text which needs display
     * @param {Boolean} isPush - add message to existing messages instead of replace them
     */
    showSuccess(message, isPush) {
      this.trigger('showSuccess', message, isPush);
    },

    /**
     * Send action to show error message on the top of modal window
     * @param {String}  message - text which needs display
     * @param {Boolean} isPush - add message to existing messages instead of replace them
     */
    showWarn(message, isPush) {
      this.trigger('showWarn', message, isPush);
    },

    /**
     * Send action to show error message on the top of modal window
     * @param {String}  message - text which needs display
     * @param {Boolean} isPush - add message to existing messages instead of replace them
     */
    showInfo(message, isPush) {
      this.trigger('showInfo', message, isPush);
    }

  });

  _exports.default = _default;
});