define("adt-wss/pods/components/ps/easy-pay/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EDIT_FAILURE_ACTION = _exports.EDIT_SUCCESS_ACTION = _exports.SUBMIT_SWITCH_ACTION = _exports.SUBMIT_EDIT_EXP_ACTION = _exports.STEP_BACK_ACTION = _exports.LOADED_PAYMENT_FORM_ACTION = _exports.CANCEL_ACTION = _exports.SUBMIT_ACTION = _exports.START_EDIT_EXP_ACTION = _exports.VALIDATION_FAILURE_ACTION = _exports.VALIDATION_SUCCESS_ACTION = _exports.VALIDATE_EASYPAY_ACTION = _exports.CONFIRM_START_ACTION = _exports.SET_PAYMENT_DETAILS_ACTION = _exports.SET_PAYMENT_TYPE_ACTION = _exports.START_ENROLL_ACTION = _exports.EDIT_EXP_STATE = _exports.ENROLL_VALIDATION_STATE = _exports.ENROLL_CONFIRMATION_STATE = _exports.ENROLL_STATE = void 0;
  const ENROLL_STATE = 'ENROLL_STATE';
  _exports.ENROLL_STATE = ENROLL_STATE;
  const ENROLL_CONFIRMATION_STATE = 'ENROLL_CONFIRMATION_STATE';
  _exports.ENROLL_CONFIRMATION_STATE = ENROLL_CONFIRMATION_STATE;
  const ENROLL_VALIDATION_STATE = 'ENROLL_VALIDATION_STATE';
  _exports.ENROLL_VALIDATION_STATE = ENROLL_VALIDATION_STATE;
  const EDIT_EXP_STATE = 'EDIT_EXP_STATE';
  _exports.EDIT_EXP_STATE = EDIT_EXP_STATE;
  const START_ENROLL_ACTION = 'START_ENROLL_ACTION';
  _exports.START_ENROLL_ACTION = START_ENROLL_ACTION;
  const SET_PAYMENT_TYPE_ACTION = 'SET_PAYMENT_TYPE_ACTION';
  _exports.SET_PAYMENT_TYPE_ACTION = SET_PAYMENT_TYPE_ACTION;
  const SET_PAYMENT_DETAILS_ACTION = 'SET_PAYMENT_DETAILS_ACTION';
  _exports.SET_PAYMENT_DETAILS_ACTION = SET_PAYMENT_DETAILS_ACTION;
  const CONFIRM_START_ACTION = 'CONFIRM_START_ACTION';
  _exports.CONFIRM_START_ACTION = CONFIRM_START_ACTION;
  const VALIDATE_EASYPAY_ACTION = 'VALIDATE_EASYPAY_ACTION';
  _exports.VALIDATE_EASYPAY_ACTION = VALIDATE_EASYPAY_ACTION;
  const VALIDATION_SUCCESS_ACTION = 'VALIDATION_SUCCESS_ACTION';
  _exports.VALIDATION_SUCCESS_ACTION = VALIDATION_SUCCESS_ACTION;
  const VALIDATION_FAILURE_ACTION = 'VALIDATION_FAILURE_ACTION';
  _exports.VALIDATION_FAILURE_ACTION = VALIDATION_FAILURE_ACTION;
  const START_EDIT_EXP_ACTION = 'START_EDIT_EXP_ACTION';
  _exports.START_EDIT_EXP_ACTION = START_EDIT_EXP_ACTION;
  const SUBMIT_ACTION = 'SUBMIT_ACTION';
  _exports.SUBMIT_ACTION = SUBMIT_ACTION;
  const CANCEL_ACTION = 'CANCEL_ACTION';
  _exports.CANCEL_ACTION = CANCEL_ACTION;
  const LOADED_PAYMENT_FORM_ACTION = 'LOADED_PAYMENT_FORM_ACTION';
  _exports.LOADED_PAYMENT_FORM_ACTION = LOADED_PAYMENT_FORM_ACTION;
  const STEP_BACK_ACTION = 'STEP_BACK_ACTION';
  _exports.STEP_BACK_ACTION = STEP_BACK_ACTION;
  const SUBMIT_EDIT_EXP_ACTION = 'SUBMIT_EDIT_EXP_ACTION';
  _exports.SUBMIT_EDIT_EXP_ACTION = SUBMIT_EDIT_EXP_ACTION;
  const SUBMIT_SWITCH_ACTION = 'SUBMIT_SWITCH_ACTION';
  _exports.SUBMIT_SWITCH_ACTION = SUBMIT_SWITCH_ACTION;
  const EDIT_SUCCESS_ACTION = 'EDIT_SUCCESS_ACTION';
  _exports.EDIT_SUCCESS_ACTION = EDIT_SUCCESS_ACTION;
  const EDIT_FAILURE_ACTION = 'EDIT_FAILURE_ACTION';
  _exports.EDIT_FAILURE_ACTION = EDIT_FAILURE_ACTION;
});