define("adt-wss/pods/dashboard/loading/route", ["exports", "adt-wss/routes/loading"], function (_exports, _loading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _loading.default.extend({});

  _exports.default = _default;
});