define("adt-wss/pods/components/ps/myadt-login-pic/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.REJECT_LOCATE_ACTION = _exports.RESOLVE_LOCATE_ACTION = _exports.LOCATE_ACTION = _exports.FETCHING_ACCOUNT_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const FETCHING_ACCOUNT_STATE = 'FETCHING_ACCOUNT_STATE';
  _exports.FETCHING_ACCOUNT_STATE = FETCHING_ACCOUNT_STATE;
  const LOCATE_ACTION = 'LOCATE_ACTION';
  _exports.LOCATE_ACTION = LOCATE_ACTION;
  const RESOLVE_LOCATE_ACTION = 'RESOLVE_LOCATE_ACTION';
  _exports.RESOLVE_LOCATE_ACTION = RESOLVE_LOCATE_ACTION;
  const REJECT_LOCATE_ACTION = 'REJECT_LOCATE_ACTION';
  _exports.REJECT_LOCATE_ACTION = REJECT_LOCATE_ACTION;
});