define("adt-wss/pods/components/dashboard/alarm/system-test-wizard/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.STARTED_FAILURE_ACTION = _exports.STARTED_SUCCESSFUL_ACTION = _exports.COMPLETE_ACTION = _exports.RESOLVE_ACTION = _exports.TRANSITION_TO_SILENCE_ALARM_INSTRUCTION_ACTION = _exports.BACK_TO_STOPWATCH_ACTION = _exports.TRANSITION_TO_NO_SIGNALS_RECEIVED_INSTRUCTION_ACTION = _exports.INIT_STOPWATCH_ACTION = _exports.TRANSITION_TO_TRIGGER_SENSORS_INSTRUCTION_ACTION = _exports.TRANSITION_TO_ARM_SYSTEM_INSTRUCTION_ACTION = _exports.START_ACTION = _exports.CANCEL_START_ACTION = _exports.SET_DURATION_ACTION = _exports.INITIALIZE_ACTION = _exports.REJECT_ACTION = _exports.START_PENDING_STATE = _exports.FINAL_STATE = _exports.SILENCE_ALARM_INSTRUCTION_STATE = _exports.NO_SIGNALS_RECEIVED_INSTRUCTION_STATE = _exports.STOPWATCH_STATE = _exports.TRIGGER_SENSORS_INSTRUCTION_STATE = _exports.ARM_SYSTEM_INSTRUCTION_STATE = _exports.STARTED_STATE = _exports.PRE_START_STATE = _exports.DURATION_SETTINGS_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const DURATION_SETTINGS_STATE = 'DURATION_SETTINGS_STATE';
  _exports.DURATION_SETTINGS_STATE = DURATION_SETTINGS_STATE;
  const PRE_START_STATE = 'PRE_START_STATE';
  _exports.PRE_START_STATE = PRE_START_STATE;
  const STARTED_STATE = 'STARTED_STATE';
  _exports.STARTED_STATE = STARTED_STATE;
  const ARM_SYSTEM_INSTRUCTION_STATE = 'ARM_SYSTEM_INSTRUCTION_STATE';
  _exports.ARM_SYSTEM_INSTRUCTION_STATE = ARM_SYSTEM_INSTRUCTION_STATE;
  const TRIGGER_SENSORS_INSTRUCTION_STATE = 'TRIGGER_SENSORS_INSTRUCTION_STATE';
  _exports.TRIGGER_SENSORS_INSTRUCTION_STATE = TRIGGER_SENSORS_INSTRUCTION_STATE;
  const STOPWATCH_STATE = 'STOPWATCH_STATE';
  _exports.STOPWATCH_STATE = STOPWATCH_STATE;
  const NO_SIGNALS_RECEIVED_INSTRUCTION_STATE = 'NO_SIGNALS_RECEIVED_INSTRUCTION_STATE';
  _exports.NO_SIGNALS_RECEIVED_INSTRUCTION_STATE = NO_SIGNALS_RECEIVED_INSTRUCTION_STATE;
  const SILENCE_ALARM_INSTRUCTION_STATE = 'SILENCE_ALARM_INSTRUCTION_STATE';
  _exports.SILENCE_ALARM_INSTRUCTION_STATE = SILENCE_ALARM_INSTRUCTION_STATE;
  const FINAL_STATE = 'FINAL_STATE';
  _exports.FINAL_STATE = FINAL_STATE;
  const START_PENDING_STATE = 'START_PENDING_STATE';
  _exports.START_PENDING_STATE = START_PENDING_STATE;
  const REJECT_ACTION = 'REJECT_ACTION';
  _exports.REJECT_ACTION = REJECT_ACTION;
  const INITIALIZE_ACTION = 'INITIALIZE_ACTION';
  _exports.INITIALIZE_ACTION = INITIALIZE_ACTION;
  const SET_DURATION_ACTION = 'SET_DURATION_ACTION';
  _exports.SET_DURATION_ACTION = SET_DURATION_ACTION;
  const CANCEL_START_ACTION = 'CANCEL_START_ACTION';
  _exports.CANCEL_START_ACTION = CANCEL_START_ACTION;
  const START_ACTION = 'START_ACTION';
  _exports.START_ACTION = START_ACTION;
  const TRANSITION_TO_ARM_SYSTEM_INSTRUCTION_ACTION = 'TRANSITION_TO_ARM_SYSTEM_INSTRUCTION_ACTION';
  _exports.TRANSITION_TO_ARM_SYSTEM_INSTRUCTION_ACTION = TRANSITION_TO_ARM_SYSTEM_INSTRUCTION_ACTION;
  const TRANSITION_TO_TRIGGER_SENSORS_INSTRUCTION_ACTION = 'TRANSITION_TO_TRIGGER_SENSORS_INSTRUCTION_ACTION';
  _exports.TRANSITION_TO_TRIGGER_SENSORS_INSTRUCTION_ACTION = TRANSITION_TO_TRIGGER_SENSORS_INSTRUCTION_ACTION;
  const INIT_STOPWATCH_ACTION = 'INIT_STOPWATCH_ACTION';
  _exports.INIT_STOPWATCH_ACTION = INIT_STOPWATCH_ACTION;
  const TRANSITION_TO_NO_SIGNALS_RECEIVED_INSTRUCTION_ACTION = 'TRANSITION_TO_NO_SIGNALS_RECEIVED_INSTRUCTION_ACTION';
  _exports.TRANSITION_TO_NO_SIGNALS_RECEIVED_INSTRUCTION_ACTION = TRANSITION_TO_NO_SIGNALS_RECEIVED_INSTRUCTION_ACTION;
  const BACK_TO_STOPWATCH_ACTION = 'BACK_TO_STOPWATCH_ACTION';
  _exports.BACK_TO_STOPWATCH_ACTION = BACK_TO_STOPWATCH_ACTION;
  const TRANSITION_TO_SILENCE_ALARM_INSTRUCTION_ACTION = 'TRANSITION_TO_SILENCE_ALARM_INSTRUCTION_ACTION';
  _exports.TRANSITION_TO_SILENCE_ALARM_INSTRUCTION_ACTION = TRANSITION_TO_SILENCE_ALARM_INSTRUCTION_ACTION;
  const RESOLVE_ACTION = 'RESOLVE_ACTION';
  _exports.RESOLVE_ACTION = RESOLVE_ACTION;
  const COMPLETE_ACTION = 'COMPLETE_ACTION';
  _exports.COMPLETE_ACTION = COMPLETE_ACTION;
  const STARTED_SUCCESSFUL_ACTION = 'STARTED_SUCCESSFUL_ACTION';
  _exports.STARTED_SUCCESSFUL_ACTION = STARTED_SUCCESSFUL_ACTION;
  const STARTED_FAILURE_ACTION = 'STARTED_FAILURE_ACTION';
  _exports.STARTED_FAILURE_ACTION = STARTED_FAILURE_ACTION;
});