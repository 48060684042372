define("adt-wss/pods/components/dashboard/alarm/system-test-wizard-stopwatch/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss/pods/components/dashboard/alarm/system-test-wizard-stopwatch/constants"], function (_exports, _withFsm, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    duration: 60,
    onStart: () => console.warn('Please pass onStart method to dashboard/alarm/sytem-test-wizard-stopwatch'),
    onFinish: () => console.warn('Please pass onStart method to dashboard/alarm/sytem-test-wizard-stopwatch')
  };
  const fsmModel = {
    transitions: {
      [_constants.IDLE_STATE]: {
        [_constants.COUNTDOWN_ACTION]: _constants.RUNNING_STATE
      },
      [_constants.RUNNING_STATE]: {
        [_constants.STOP_ACTION]: _constants.IDLE_STATE
      }
    }
  };

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    fsmModel,
    classNames: ['system-test-wizard-stopwatch'],
    countdown: Ember.computed.oneWay('duration'),
    currentState: _constants.IDLE_STATE,
    IS_RUNNING_STATE: Ember.computed.equal('currentState', _constants.RUNNING_STATE),

    [_constants.STOP_ACTION]() {
      this.onFinish();
      this.cleanup();
    },

    [_constants.COUNTDOWN_ACTION]() {
      const fsm = this.get('fsm');
      this.cleanup();
      this.onStart();
      this.set('countdown', this.get('duration'));
      this.interval = setInterval(() => {
        if (this.decrementProperty('countdown') === 0) {
          fsm.dispatch(_constants.STOP_ACTION);
        }
      }, 1000);
    },

    cleanup() {
      clearInterval(this.interval);
    },

    didInsertElement() {
      const {
        element,
        duration,
        fsm
      } = this.getProperties('element', 'duration', 'fsm');
      const [circle] = element.getElementsByTagName('circle');
      circle.style.animationDuration = "".concat(duration, "s");
      circle.style.animationPlayState = 'initial';
      fsm.dispatch(_constants.COUNTDOWN_ACTION);
    },

    didDestroyElement() {
      this.cleanup();
    },

    actions: {
      resetCouter() {
        const fsm = this.get('fsm');
        fsm.dispatch(_constants.STOP_ACTION);
        Ember.run.next(() => fsm.dispatch(_constants.COUNTDOWN_ACTION));
      }

    }
  });

  _exports.default = _default;
});