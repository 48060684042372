define("adt-wss/pods/components/dashboard/account/primary-pmoc-editor/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/classes/status-message", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/state-comparator", "ember-copy", "adt-wss/pods/components/dashboard/account/primary-pmoc-editor/constants", "adt-wss/pods/components/dashboard/account/communication-preferences/constants"], function (_exports, _withFsm, _statusMessage, _emberHelpers, _myadtHelpers, _stateComparator, _emberCopy, _constants, _constants2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fsmModel = {
    scrollToTop: false,
    transitions: {
      [_constants.IDLE_STATE]: {
        [_constants.START_EDIT_ACTION]: _constants.EDIT_STATE,
        [_constants.DELETE_EMAIL_ACTION]: _constants.UPDATING_STATE
      },
      [_constants.EDIT_STATE]: {
        [_constants.DELETE_EMAIL_ACTION]: _constants.DELETING_STATE,
        [_constants.UPDATE_DATA_ACTION]: _constants.UPDATING_STATE,
        [_constants.CANCEL_EDIT_ACTION]: _constants.IDLE_STATE
      },
      [_constants.DELETING_STATE]: {
        [_constants.RESOLVE_DELETE_ACTION]: _constants.EDIT_STATE,
        [_constants.REJECT_DELETE_ACTION]: _constants.EDIT_STATE
      },
      [_constants.UPDATING_STATE]: {
        [_constants.RESOLVE_ACTION]: _constants.IDLE_STATE,
        [_constants.REJECT_ACTION]: _constants.EDIT_STATE
      }
    }
  };
  const phoneTypeOptions = [null, 'Mobile', 'Landline'];
  const formErrors = {
    emailError: false,
    phone1Error: false,
    phone2Error: false,
    phone1TypeError: false,
    phone2TypeError: false
  };
  const defPhoneConsents = {
    phone1: null,
    phone1Type: null,
    phone1BillingTextConsent: false,
    phone1SrvcApptTextConsent: false,
    phone2: null,
    phone2Type: null,
    phone2BillingTextConsent: false,
    phone2SrvcApptTextConsent: false
  };
  const defaultProps = {
    alert: v => v,
    email: {},
    successMessage: 'Success',
    erorMessage: 'Error',

    updateEmail() {
      console.error('Please pass updateEmail method to dashboard/account/primary-pmoc-editor component');
    },

    deleteEmail() {
      console.error('Please pass deleteEmail method to dashboard/account/primary-pmoc-editor component');
    },

    updatePhone() {
      console.error('Please pass updatePhone method to dashboard/account/primary-pmoc-editor component');
    },

    deleteAltPhone() {
      console.error('Please pass deleteAltPhone method to dashboard/account/primary-pmoc-editor component');
    }

  };
  /**
   * @module Components/Dashboard/Account/PrimaryEmailsEditor
   * @prop {PmocContact} email
   * @prop {PmocPhoneConsentsObject} phoneConsents
   * @prop {Function} alert
   * @prop {Function} submitUpdate
   * @prop {String} successMessage
   * @prop {String} errorMessage
   * @example
   * {@lang xml} {{ dashboard/account/primary-pmoc-editor
  		email={ email: 'some@test.com', pending: true }
  		phoneConsents={
  			phone1: '2145552345',
  			phone1Type: 'Mobile',
  			phone1BillingTextConsent: true,
  			phone1SrvcApptTextConsent: false,
  			phone2: '2145552346',
  			phone2Type: 'Mobile',
  			phone2BillingTextConsent: false,
  			phone2SrvcApptTextConsent: true,
  		}
  		alert=(action "setMessages")
  		updateEmail=(action "updatePrimaryEmail")
  		deleteEmail=(action "deletePrimaryEmail")
  		updatePhone=(action "updatePrimaryPhone")
  		deleteAltPhone=(action "deleteAltPhone")
  		errorMessage='Error message'
  		successMessage='Success message'
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    mwd: Ember.inject.service('modal-window-dispatcher'),
    contactData: Ember.inject.service(),
    fsmModel,
    validator: null,
    tagName: 'ul',
    classNames: ['primary-pmoc-editor', 'unstyled'],
    currentState: _constants.IDLE_STATE,
    IS_EDIT_STATE: (0, _emberHelpers.isState)(_constants.EDIT_STATE),
    IS_IDLE_STATE: (0, _emberHelpers.isState)(_constants.IDLE_STATE),
    IS_UPDATING_STATE: (0, _emberHelpers.isState)(_constants.UPDATING_STATE || _constants.DELETING_STATE),
    emailChanged: (0, _emberHelpers.computedApplyFunction)((a, b) => a !== b, 'email.email', 'primaryEmail.email'),
    formErrors,
    phoneTypeOptions,
    newPhoneConsents: defPhoneConsents,
    showPhone2: Ember.computed.bool('newPhoneConsents.phone2'),

    [_constants.START_EDIT_ACTION]() {
      const {
        mwd,
        fsm,
        showPhoneFields
      } = this.getProperties('mwd', 'fsm', 'showPhoneFields');
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);
      const body = this;

      const onClose = () => {
        fsm.dispatch(_constants.CANCEL_EDIT_ACTION);
        mwd.hide();
      };

      const onEnter = () => fsm.dispatch(_constants.UPDATE_DATA_ACTION, {
        email: body.newEmail,
        phoneConsents: body.newPhoneConsents
      }).then(() => {
        this.alert([new _statusMessage.default(this.get('successMessage'), 'success')]);
        this.get('mwd').hide();
        fsm.dispatch(_constants.RESOLVE_ACTION, null, true);
      }).catch(r => showErrors(r, _constants.REJECT_ACTION));

      const deleteAltPhone = () => {
        body.set('newPhoneConsents.phone2', null);
        body.set('newPhoneConsents.phone2Type', null);
        body.set('showPhone2', false);
      };

      const addContact = () => {
        body.set('showPhone2', true);
      };

      this.set('formErrors', {
        emailError: false,
        phone1Error: false,
        phone2Error: false,
        phone1TypeError: false,
        phone2TypeError: false,
        showPhone2: false
      });
      body.setProperties({
        newEmail: (0, _stateComparator.generateStateSchemaMiddleware)((0, _emberCopy.copy)(body.email, true)),
        newPhoneConsents: (0, _stateComparator.generateStateSchemaMiddleware)((0, _emberCopy.copy)(body.phoneConsents, true)),
        showPhone2: !!body.phoneConsents.phone2,
        deleteAltPhone,
        addContact,
        showPhoneFields
      });
      mwd.show({
        name: 'preferences/primary-pmoc-updater',
        title: 'Edit Email and Phone',
        body,
        onClose,
        onEnter,
        disclaimer: _constants2.DISCLAIMER_MESSAGE,
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: 'Save',
          callback: onEnter
        }]
      });
    },

    [_constants.UPDATE_DATA_ACTION]({
      email,
      phoneConsents
    }) {
      const errors = [];
      let isInvalidPhoneNumber = true;
      let isInvalidPhoneType = true;
      this.set('formErrors', {
        emailError: false,
        phone1Error: false,
        phone2Error: false,
        phone1TypeError: false,
        phone2TypeError: false,
        showPhone2: false
      });

      if (email.email && !email.email.isValidEmail()) {
        this.set('formErrors.emailError', true);
        errors.push('Generic.invalidEmailFormat');
      }

      if (this.get('showPhoneFields')) {
        if (phoneConsents.phone1) {
          if (!(0, _myadtHelpers.validatePhoneNumber)(phoneConsents.phone1)) {
            this.set('formErrors.phone1Error', true);
            errors.push('Contacts.invalidPhoneNumber');
            isInvalidPhoneNumber = false;
          }

          if (!phoneConsents.phone1Type) {
            this.set('formErrors.phone1TypeError', true);
            errors.push('Please choose a phone type');
            isInvalidPhoneType = false;
          }
        }

        if (phoneConsents.phone2) {
          if (!phoneConsents.phone1) {
            this.set('formErrors.phone1Error', true);
            errors.push('Please enter a primary phone number before entering an alternate.');
          }

          if (!(0, _myadtHelpers.validatePhoneNumber)(phoneConsents.phone2)) {
            this.set('formErrors.phone2Error', true);

            if (isInvalidPhoneNumber) {
              errors.push('Contacts.invalidPhoneNumber');
            }
          }

          if (!phoneConsents.phone2Type) {
            this.set('formErrors.phone2TypeError', true);

            if (isInvalidPhoneType) {
              errors.push('Please choose a phone type');
            }
          }
        }
      }

      if (errors.length) {
        this.get('mwd').clearMessages();
        return Promise.reject({
          errors
        });
      }

      return (0, _stateComparator.compareStatesEquality)(email, data => this.updateEmail(data)).then((0, _stateComparator.compareStatesEquality)(phoneConsents, data => this.updatePhone(data)));
    }

  });

  _exports.default = _default;
});