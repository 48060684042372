define("adt-wss/pods/components/help/help-search/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers"], function (_exports, _withFsm, _myadtHelpers, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_withFsm.default, {
    router: Ember.inject.service(),
    classNames: ['help-search'],
    attributeBindings: ['selenium-id'],
    'selenium-id': 'help-search',
    searchIsOpen: false,
    query: '',
    placeholderDesktop: 'Type your question or keywords',
    placeholderMobile: 'Search Help',
    placeholder: (0, _emberHelpers.computedIfElse)('media.isSmartphone', ['placeholderMobile', 'placeholderDesktop']),
    actions: {
      submitSearch() {
        const query = this.get('query');

        if (query) {
          (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Help Center', 'Search', query]);
          window.open("https://help.adt.com/s/global-search/".concat(query), '_blank' // Opens the link in a new window
          ); //this.get('router').transitionTo('help.search', query);
        }
      },

      clearSearch() {
        this.set('query', '');
      },

      toggleSearch() {
        const referAFriendBtn = document.getElementById('referAFriendBtn');

        if (this.get('navbarPosition')) {
          if (this.get('query')) {
            this.send('submitSearch');
          }

          if (!this.get('searchIsOpen')) {
            if (referAFriendBtn) referAFriendBtn.style.opacity = 0;
            setTimeout(() => {
              if (referAFriendBtn) referAFriendBtn.style.display = 'none';
              this.toggleProperty('searchIsOpen');
              document.getElementById('navbarHeaderSearchInput').focus();
            }, 500);
          } else {
            this.toggleProperty('searchIsOpen');

            if (referAFriendBtn) {
              referAFriendBtn.style.display = 'block';
              setTimeout(() => {
                referAFriendBtn.style.opacity = 1;
              }, 500);
            }
          }
        }
      }

    }
  });

  _exports.default = _default;
});