define("adt-wss/pods/components/myadt-login/forgot-password/component", ["exports", "adt-wss-common/classes/state-machine", "adt-wss-common/utils/localization"], function (_exports, _stateMachine, _localization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Forgot Password Component
   * @module Components/MyADTLogin/ForgotPassword
   * @prop {String} currentState
   * @prop {String} email
   * @prop {Boolean} emailError
   * @prop {String[]} errorMessages
   * @prop {Int} failedAttempts
   * @prop {Boolean} isFetching
   * @prop {String} securityQuestion
   * @prop {String} securityAnswer
   * @prop {Boolean} securityAnswerError
   * @prop {String} password1
   * @prop {String} password2
   * @prop {Boolean} password1error
   * @prop {Boolean} invalidPassword
   * @prop {Boolean} password2error
   * @prop {Guid} uuid
   */
  var _default = Ember.Component.extend({
    classNames: ['forgot-password-form'],
    attributeBindings: ['selenium-id'],
    'selenium-id': 'forgot-password',
    passwordService: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    currentState: 'idle',
    ssoInfo: Ember.inject.service(),
    isSsoEnabled: Ember.computed.readOnly('ssoInfo.isSsoEnabled'),
    email: '',
    emailError: false,
    errorMessages: null,
    failedAttempts: 0,
    isFetching: Ember.computed('currentState', function () {
      const state = this.get('currentState');
      return /fetching/.test(state) || /verifying/.test(state);
    }),
    securityQuestion: Ember.computed.readOnly('passwordService.securityQuestion'),
    securityAnswer: null,
    password1: '',
    password2: '',
    password1error: false,
    password2error: false,
    invalidPassword: false,
    uuid: null,

    returnToLogin() {
      if (this.get('parentState')) {
        this.set('parentState', 'idle');
      } else {
        this.get('router').transitionTo('index');
      }
    },

    submitPasswordResetEmail() {
      const {
        email,
        fsm
      } = this.getProperties('email', 'fsm');
      const errors = [];

      if (!email) {
        errors.push('Email must be entered');
        fsm.dispatch('rejectResetPasswordEmail', {
          errors
        });
      } else if (!email.isValidEmail()) {
        errors.push((0, _localization.toExpandedString)('Login.errorInvalidEmail'));
        fsm.dispatch('rejectResetPasswordEmail', {
          errors
        });
      } else {
        this.set('errorMessages', []);
        this.get('passwordService').verifyEmail(email).then(() => {
          fsm.dispatch('resolveResetPasswordEmail');
        }).catch(results => {
          fsm.dispatch('rejectResetPasswordEmail', results);
        });
      }
    },

    /**
     * Handle uncompleted registration
     *
     * @param {?String[]} errors
     * @returns {Boolean} if error relates to uncompleted registration returns `true`
     */
    handleUncompletedRegistration(errors) {
      const REGISTRATION_NOT_COMPLEATED = 'PasswordReset.userRegistrationNotCompleted';

      if (errors && errors[0] === REGISTRATION_NOT_COMPLEATED) {
        this.get('fsm').dispatch('transitionToUncompletedRegistrationWarn');
        return true;
      }

      return false;
    },

    resolveResetPasswordEmail() {
      const {
        email,
        currentState,
        fsm
      } = this.getProperties('email', 'currentState', 'fsm');

      if (/question/i.test(currentState)) {
        this.get('passwordService').getSecurityQuestion(email).then(() => {
          fsm.dispatch('resolveResetPasswordQuestion');
        }).catch(results => {
          if (this.handleUncompletedRegistration(results.errors)) {
            return false;
          }

          return fsm.dispatch('rejectResetPasswordQuestion', results);
        });
      } else {
        this.get('passwordService').sendResetLink(email, email).then(() => {
          fsm.dispatch('resolveSendResetEmail');
        }).catch(results => {
          if (this.handleUncompletedRegistration(results.errors)) {
            return false;
          }

          return fsm.dispatch('rejectSendResetEmail', results);
        });
      }
    },

    rejectResetPasswordEmail(results) {
      this.handleErrors(results);
    },

    rejectSendResetEmail(results) {
      this.handleErrors(results);
    },

    rejectResetPasswordQuestion(results) {
      this.handleErrors(results);
    },

    submitSecurityQuestion(data) {
      this.set('securityAnswerError', false);
      return this.get('passwordService').validateSecurityAnswer(data);
    },

    submitNewPassword() {
      const {
        password1,
        password2,
        invalidPassword,
        fsm
      } = this.getProperties('password1', 'password2', 'invalidPassword', 'fsm');
      this.set('password1error', false);
      this.set('password2error', false);

      if (!password1 || invalidPassword) {
        this.set('password1error', true);
        return fsm.dispatch('rejectResetPassword', {
          errors: [(0, _localization.toExpandedString)('PasswordReset.invalidPassword')]
        });
      }

      if (!password2 || password1 !== password2) {
        this.set('password2error', true);
        return fsm.dispatch('rejectResetPassword', {
          errors: [(0, _localization.toExpandedString)('PasswordReset.passwordNotMatching')]
        });
      }

      this.set('errorMessages', null);

      if (this.get('uuid')) {
        this.resetWithLink();
      } else {
        this.resetWithAnswer();
      }
    },

    failureSubmit({
      errors
    }) {
      this.set('securityAnswerError', true);
      this.set('errorMessages', [(0, _localization.toExpandedString)(errors[0])]);
    },

    resetWithAnswer() {
      const {
        email,
        securityAnswer,
        securityQuestion,
        password1,
        password2
      } = this.getProperties('email', 'securityAnswer', 'securityQuestion', 'password1', 'password2');
      const data = {
        loginId: email,
        password1,
        password2,
        securityAnswer,
        securityQuestion
      };
      this.get('passwordService').resetWithAnswer(data).then(() => {
        this.get('fsm').dispatch('resolveResetPassword', false);
      }, results => {
        this.get('fsm').dispatch('rejectResetPassword', results);
      });
    },

    resetWithLink() {
      const params = this.getProperties('uuid', 'password1', 'password2');
      this.get('passwordService').resetWithLink(params).then(({
        data
      }) => {
        this.get('fsm').dispatch('resolveResetPassword', {
          shouldLogin: true,
          data
        });
      }, results => {
        this.get('fsm').dispatch('rejectResetPassword', results);
      });
    },

    resolveResetPassword({
      shouldLogin,
      data
    }) {
      if (shouldLogin === true) {
        this.get('session').authenticate('authenticator:myadt', data.loginId, data.password1).then(() => {
          this.set('errorMessages', null);
          this.get('router').transitionTo('dashboard');
        }).catch(results => {
          this.set('errorMessages', results.errors);
          this.get('fsm').dispatch('rejectAuth', results);
        });
      }
    },

    rejectResetPassword({
      errors
    }) {
      let wrongAnswer = false;
      errors = errors.map(error => {
        if (/incorrect/i.test(error)) wrongAnswer = true;
        return (0, _localization.toExpandedString)(error);
      });

      if (wrongAnswer) {
        this.set('currentState', 'securityQuestion');
      }

      this.set('errorMessages', errors);
    },

    rejectAuth(results) {
      this.handleErrors(results);
    },

    handleErrors(results) {
      const errors = [];

      if (Array.isArray(results.errors)) {
        results.errors.forEach(error => {
          errors.push((0, _localization.toExpandedString)(error));
        });
      } else {
        errors.push((0, _localization.toExpandedString)(results.errors));
      }

      this.set('errorMessages', errors);
    },

    actions: {
      dispatch() {
        this.get('fsm').dispatch(...arguments);
      },

      validateEmail(email) {
        const errors = [];

        if (!email) {
          this.set('emailError', true);
          errors.push('Email must be entered');
        } else if (!email.isValidEmail()) {
          this.set('emailError', true);
          errors.push((0, _localization.toExpandedString)('Login.errorInvalidEmail'));
        } else {
          this.set('emailError', false);
        }

        this.set('errorMessages', errors);
      },

      submitSecurityAnswer() {
        const {
          securityAnswer,
          securityQuestion,
          email
        } = this.getProperties('securityAnswer', 'securityQuestion', 'email');
        const data = {
          username: email,
          questions: [{
            question: securityQuestion,
            answer: securityAnswer
          }]
        };

        if (!securityAnswer) {
          this.set('securityAnswerError', true);
          return this.set('errorMessages', [(0, _localization.toExpandedString)('PasswordReset.incorrectAnswer')]);
        }

        this.set('errorMessages', null);
        this.set('securityAnswerError', false);
        this.get('fsm').dispatch('submitSecurityQuestion', data).then(response => this.get('fsm').dispatch('successSubmit', response)).catch(errors => this.get('fsm').dispatch('failureSubmit', errors));
      },

      setPasswordValidationError(val) {
        this.set('password1error', val);
      },

      confirmPassword() {
        const {
          password1,
          password2
        } = this.getProperties('password1', 'password2');
        const ret = password2.length && password2 !== password1;
        this.set('password2error', ret);
      }

    },

    init() {
      this._super(...arguments);

      this.fsm = _stateMachine.default.create({
        transitions: {
          idle: {
            sendResetEmail: 'passwordResetEmail',
            startResetQuestion: 'passwordResetQuestion',
            returnToLogin: 'idle'
          },
          passwordResetEmail: {
            submitPasswordResetEmail: 'verifyingPasswordResetEmail',
            returnToLogin: 'idle'
          },
          passwordResetQuestion: {
            submitPasswordResetEmail: 'verifyingPasswordResetQuestion',
            returnToLogin: 'idle'
          },
          verifyingPasswordResetEmail: {
            resolveResetPasswordEmail: 'sendingPasswordResetEmail',
            rejectResetPasswordEmail: 'passwordResetEmail'
          },
          verifyingPasswordResetQuestion: {
            resolveResetPasswordEmail: 'fetchingPasswordResetQuestion',
            rejectResetPasswordEmail: 'passwordResetQuestion'
          },
          sendingPasswordResetEmail: {
            resolveSendResetEmail: 'resetPasswordEmailSent',
            rejectSendResetEmail: 'passwordResetEmail',
            transitionToUncompletedRegistrationWarn: 'uncompletedRegistrationWarn'
          },
          fetchingPasswordResetQuestion: {
            resolveResetPasswordQuestion: 'securityQuestion',
            rejectResetPasswordQuestion: 'passwordResetQuestion',
            transitionToUncompletedRegistrationWarn: 'uncompletedRegistrationWarn'
          },
          securityQuestion: {
            submitSecurityQuestion: 'verifyingSubmitAnswer',
            returnToLogin: 'idle'
          },
          verifyingSubmitAnswer: {
            successSubmit: 'resetPassword',
            failureSubmit: 'securityQuestion'
          },
          resetPassword: {
            returnToLogin: 'idle',
            submitNewPassword: 'fetchingResetPassword'
          },
          resetPasswordEmailSent: {
            returnToLogin: 'idle'
          },
          fetchingResetPassword: {
            resolveResetPassword: 'resetPasswordSuccess',
            rejectResetPassword: 'resetPassword'
          },
          resetPasswordSuccess: {
            rejectAuth: 'resetPasswordSuccess',
            returnToLogin: 'idle'
          },
          uncompletedRegistrationWarn: {
            returnToLogin: 'idle'
          }
        },
        context: this
      });

      if (this.get('uuid')) {
        this.set('currentState', 'resetPassword');
      }

      console.log(this.get('uuid'));
    }

  });

  _exports.default = _default;
});