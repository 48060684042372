define("adt-wss/templates/modal-windows/other-retailers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R7WBlgXH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"other-retailers\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"retailer-link\"],[8],[7,\"a\",true],[10,\"href\",\"https://www.amazon.com/s/ref=nb_sb_noss?url=node%3D3180341&field-keywords=battery&rh=n%3A172282%2Cn%3A524136%2Cn%3A3180341%2Ck%3Abattery\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[7,\"img\",true],[11,\"src\",[29,[[22,\"rootURL\"],\"images\\\\v2\\\\logos\\\\logo-amazon.jpg\"]]],[10,\"alt\",\"Amazon\"],[8],[9],[9],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"retailer-link\"],[8],[7,\"a\",true],[10,\"href\",\"https://www.batteriesplus.com/battery/security\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[7,\"img\",true],[11,\"src\",[29,[[22,\"rootURL\"],\"images\\\\v2\\\\logos\\\\logo-batteries-bulbs.jpg\"]]],[10,\"alt\",\"Batteries + Bulbs\"],[8],[9],[9],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"retailer-link\"],[8],[7,\"a\",true],[10,\"href\",\"https://www.walmart.com/search/?query=alarm%20battery\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[7,\"img\",true],[11,\"src\",[29,[[22,\"rootURL\"],\"images\\\\v2\\\\logos\\\\logo-walmart.jpg\"]]],[10,\"alt\",\"Walmart\"],[8],[9],[9],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"retailer-link\"],[8],[7,\"a\",true],[10,\"href\",\"https://www.radioshack.com/search?q=alarm+battery%20\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[7,\"img\",true],[11,\"src\",[29,[[22,\"rootURL\"],\"images\\\\v2\\\\logos\\\\logo-radio-shack.JPG\"]]],[10,\"alt\",\"Radio Shack\"],[8],[9],[9],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/other-retailers.hbs"
    }
  });

  _exports.default = _default;
});