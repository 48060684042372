define("adt-wss/pods/components/dashboard/manage/add-location/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.REGISTRATION_ACTION = _exports.REGISTRATION_STATE = _exports.REJECT_ACTION = _exports.RESOLVE_ACTION = _exports.GO_BACK_ACTION = _exports.CANCEL_ACTION = _exports.REJECT_SUBMIT_ACTION = _exports.RESOLVE_SUBMIT_ACTION = _exports.SUBMIT_ACTION = _exports.SELECT_LOCATION_ACTION = _exports.REJECT_LOCATE_ACTION = _exports.RESOLVE_LOCATE_ACTION = _exports.LOCATE_ACTION = _exports.START_ACTION = _exports.LOADING_STATE = _exports.SET_NAME_STATE = _exports.CHOOSE_LOCATION_STATE = _exports.ADD_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const ADD_STATE = 'ADD_STATE';
  _exports.ADD_STATE = ADD_STATE;
  const CHOOSE_LOCATION_STATE = 'CHOOSE_LOCATION_STATE';
  _exports.CHOOSE_LOCATION_STATE = CHOOSE_LOCATION_STATE;
  const SET_NAME_STATE = 'SET_NAME_STATE';
  _exports.SET_NAME_STATE = SET_NAME_STATE;
  const LOADING_STATE = 'LOADING_STATE';
  _exports.LOADING_STATE = LOADING_STATE;
  const START_ACTION = 'START_ACTION';
  _exports.START_ACTION = START_ACTION;
  const LOCATE_ACTION = 'LOCATE_ACTION';
  _exports.LOCATE_ACTION = LOCATE_ACTION;
  const RESOLVE_LOCATE_ACTION = 'RESOLVE_LOCATE_ACTION';
  _exports.RESOLVE_LOCATE_ACTION = RESOLVE_LOCATE_ACTION;
  const REJECT_LOCATE_ACTION = 'REJECT_LOCATE_ACTION';
  _exports.REJECT_LOCATE_ACTION = REJECT_LOCATE_ACTION;
  const SELECT_LOCATION_ACTION = 'SELECT_LOCATION_ACTION';
  _exports.SELECT_LOCATION_ACTION = SELECT_LOCATION_ACTION;
  const SUBMIT_ACTION = 'SUBMIT_ACTION';
  _exports.SUBMIT_ACTION = SUBMIT_ACTION;
  const RESOLVE_SUBMIT_ACTION = 'RESOLVE_SUBMIT_ACTION';
  _exports.RESOLVE_SUBMIT_ACTION = RESOLVE_SUBMIT_ACTION;
  const REJECT_SUBMIT_ACTION = 'REJECT_SUBMIT_ACTION';
  _exports.REJECT_SUBMIT_ACTION = REJECT_SUBMIT_ACTION;
  const CANCEL_ACTION = 'CANCEL_ACTION';
  _exports.CANCEL_ACTION = CANCEL_ACTION;
  const GO_BACK_ACTION = 'GO_BACK_ACTION';
  _exports.GO_BACK_ACTION = GO_BACK_ACTION;
  const RESOLVE_ACTION = 'RESOLVE_ACTION';
  _exports.RESOLVE_ACTION = RESOLVE_ACTION;
  const REJECT_ACTION = 'REJECT_ACTION';
  _exports.REJECT_ACTION = REJECT_ACTION;
  const REGISTRATION_STATE = 'REGISTRATION_STATE';
  _exports.REGISTRATION_STATE = REGISTRATION_STATE;
  const REGISTRATION_ACTION = 'REGISTRATION_ACTION';
  _exports.REGISTRATION_ACTION = REGISTRATION_ACTION;
});