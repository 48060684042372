define("adt-wss/pods/components/dashboard/manage/location-widget/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/classes/status-message", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers", "adt-wss/pods/components/dashboard/manage/location-widget/constants", "jquery"], function (_exports, _withFsm, _statusMessage, _emberHelpers, _myadtHelpers, constants, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    IDLE_STATE,
    EDIT_NAME_STATE,
    DELETE_STATE,
    SET_DEFAULT_STATE,
    START_EDIT_NAME_ACTION,
    START_DELETE_ACTION,
    SET_DEFAULT_ACTION,
    SWITCH_LOCATION_ACTION,
    SUBMIT_EDIT_NAME_ACTION,
    CONFIRM_DEFAULT_ACTION,
    CONFIRM_DELETE_ACTION,
    RESOLVE_EDIT_NAME_ACTION,
    RESOLVE_DELETE_ACTION,
    RESOLVE_SET_DEFAULT_ACTION,
    RESOLVE_SWITCH_LOCATION_ACTION,
    REJECT_EDIT_NAME_ACTION,
    REJECT_DELETE_ACTION,
    REJECT_SET_DEFAULT_ACTION,
    REJECT_SWITCH_LOCATION_ACTION,
    CANCEL_ACTION,
    LOADING_STATE
  } = constants;
  const fsmModel = {
    transitions: {
      [IDLE_STATE]: {
        [START_EDIT_NAME_ACTION]: EDIT_NAME_STATE,
        [SET_DEFAULT_ACTION]: SET_DEFAULT_STATE,
        [SWITCH_LOCATION_ACTION]: LOADING_STATE,
        [START_DELETE_ACTION]: DELETE_STATE
      },
      [EDIT_NAME_STATE]: {
        [SUBMIT_EDIT_NAME_ACTION]: LOADING_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      },
      [DELETE_STATE]: {
        [CONFIRM_DELETE_ACTION]: LOADING_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      },
      [SET_DEFAULT_STATE]: {
        [CONFIRM_DEFAULT_ACTION]: LOADING_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      },
      [LOADING_STATE]: {
        [CANCEL_ACTION]: IDLE_STATE,
        [RESOLVE_DELETE_ACTION]: IDLE_STATE,
        [REJECT_DELETE_ACTION]: DELETE_STATE,
        [RESOLVE_EDIT_NAME_ACTION]: IDLE_STATE,
        [REJECT_EDIT_NAME_ACTION]: EDIT_NAME_STATE,
        [RESOLVE_SET_DEFAULT_ACTION]: IDLE_STATE,
        [REJECT_SET_DEFAULT_ACTION]: IDLE_STATE,
        [RESOLVE_SWITCH_LOCATION_ACTION]: IDLE_STATE,
        [REJECT_SWITCH_LOCATION_ACTION]: IDLE_STATE
      }
    }
  };
  /**
   * @module Components/Dashboard/Manage/LocationWidget
   * @prop {Object} location from {@link Services/ManageAccountService.locations}
   * @prop {Int} ordinal	index + 1 for `displayTitle`
   * @prop {String} newLocationName value of edit name field
   * @prop {String} displayTitle uses `location.locationName` or 'Location 1', using `ordinal`
   * @prop {String} currentState
   * @prop {Boolean} isFetching
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    classNames: ['item'],
    classNameBindings: ['isRow:location-widget-row:location-widget', 'isCurrent:is-current'],
    mas: Ember.inject.service('manage-account-service'),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    currentLocation: Ember.inject.service(),
    ordinal: (0, _emberHelpers.computedApplyFunction)(x => x + 1, 'index'),
    newLocationName: Ember.computed.oneWay('location.locationName'),
    displayTitle: (0, _emberHelpers.computedApplyFunction)((name, street, street2) => name || street + (street2 ? ' ' + street2 : ''), 'location.locationName', 'location.siteLine1', 'location.siteLine2'),
    fsmModel,
    currentState: IDLE_STATE,
    isFetching: Ember.computed.equal('currentState', LOADING_STATE),

    /**
     * Sets currently viewed location
     * Calls {@link Services/CurrentLocation.changeAddress}
     * @emits Services/AppDispatcher.UPDATE_APP_STATE_EVENT
     */
    [SWITCH_LOCATION_ACTION]() {
      const {
        fsm,
        location: {
          webAddressId
        }
      } = this.getProperties('fsm', 'location');
      return this.get('currentLocation').changeAddress({
        webAddressId
      }).then(() => fsm.dispatch(RESOLVE_SWITCH_LOCATION_ACTION, webAddressId)).catch(results => fsm.dispatch(REJECT_SWITCH_LOCATION_ACTION, results));
    },

    [RESOLVE_SWITCH_LOCATION_ACTION]() {
      const displayTitle = this.get('displayTitle');
      return this.setStatusMessages([new _statusMessage.default("You are now managing ".concat(displayTitle), 'success')]);
    },

    [REJECT_SWITCH_LOCATION_ACTION](results) {
      const errors = (0, _myadtHelpers.genericErrorHandler)(results);
      return this.setStatusMessages(errors.map(m => new _statusMessage.default(m, 'error')));
    },

    // ===== EDIT NAME ===== //
    [START_EDIT_NAME_ACTION]() {
      (0, _jquery.default)('body').on('keyup.checkEscape', this.checkEscape);
      (0, _jquery.default)('.row-wrapper').addClass('disabled');
      setTimeout(() => {
        (0, _jquery.default)('input.location-name').focus();
      }, 10);
      return Ember.RSVP.Promise.resolve();
    },

    [CANCEL_ACTION]() {
      console.log(this.get('currentState'), ...arguments);
      (0, _jquery.default)('.row-wrapper').removeClass('disabled');
      return Ember.RSVP.Promise.resolve();
    },

    /**
     * Submits new name
     * activates on enter press while name `input` has focus
     * Validates for special characters
     */
    [SUBMIT_EDIT_NAME_ACTION]() {
      const {
        newLocationName,
        location,
        displayTitle,
        fsm
      } = this.getProperties('newLocationName', 'location', 'displayTitle', 'fsm');
      const changesMade = newLocationName !== this.get('location.locationName');

      if (!newLocationName && changesMade) {
        this.setStatusMessages([new _statusMessage.default('Location name cannot be deleted.', 'info')]);
        return fsm.dispatch(CANCEL_ACTION);
      }

      if (!newLocationName || !changesMade) {
        this.setStatusMessages([new _statusMessage.default('No changes were made.', 'info')]);
        return fsm.dispatch(CANCEL_ACTION);
      } else if (newLocationName.replace(/[\w\d\s,'.-]/ig, '')) {
        return fsm.dispatch(REJECT_EDIT_NAME_ACTION, {
          success: false,
          errors: ['Special characters are not permitted.']
        });
      }

      return this.get('mas').editLocationName(location.webAddressId, newLocationName).then(() => {
        const msg = "You've successfully changed \"".concat(location.locationName || displayTitle, "\" to \"").concat(newLocationName, "\"");
        this.setStatusMessages([new _statusMessage.default(msg, 'success')]);
      }).catch(results => fsm.dispatch(REJECT_EDIT_NAME_ACTION, results));
    },

    [REJECT_EDIT_NAME_ACTION](results) {
      const errors = (0, _myadtHelpers.genericErrorHandler)(results);
      return this.setStatusMessages(errors.map(m => new _statusMessage.default(m, 'error')));
    },

    // ===== SET DEFAULT LOCATION ===== //

    /**
     * Opens modal confirmation to set new default location
     */
    [SET_DEFAULT_ACTION]() {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);

      const onClose = () => {
        fsm.dispatch(CANCEL_ACTION);
        mwd.hide();
      };

      const callback = () => {
        fsm.dispatch(CONFIRM_DEFAULT_ACTION).then(() => {
          const displayTitle = this.get('displayTitle');
          const msg = "You have successfully set \"".concat(displayTitle, "\" as your default location.");
          this.setStatusMessages([new _statusMessage.default(msg, 'success')]);

          if (!this.get('isCurrent')) {
            this.refreshLocations(this.get('location').webAddressId);
          } else {
            this.refreshLocations();
          }

          return this.get('mwd').hide();
        }).then(() => fsm.dispatch(RESOLVE_SET_DEFAULT_ACTION)).catch(results => showErrors(results, REJECT_SET_DEFAULT_ACTION));
      };

      this.set('confirmationText', 'Please confirm you want this to be the default location.');
      mwd.show({
        name: 'default-delete-location',
        title: 'Default Location',
        body: this,
        onClose,
        statusMessages: [],
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: 'Update',
          callback
        }]
      });
    },

    /**
     * Makes API call to {@link Services/ManageAccountService.saveSite} to set new default location
     */
    [CONFIRM_DEFAULT_ACTION]() {
      const location = this.get('location');
      const data = {
        webAddressId: location.webAddressId,
        phone1: location.sitePhone1,
        phone2: location.sitePhone2,
        email: location.loginEmail,
        isDefault: true
      };
      return this.get('mas').saveSite(data);
    },

    // ===== DELETE LOCATION ===== //

    /**
     * Opens modal confirmation to delete location
     */
    [START_DELETE_ACTION]() {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);

      const onClose = () => this.get('fsm').dispatch(CANCEL_ACTION, {
        errors: ['Cannot delete default site.']
      }).then(() => mwd.hide());

      const callback = () => fsm.dispatch(CONFIRM_DELETE_ACTION).then(data => {
        fsm.dispatch(RESOLVE_DELETE_ACTION, data);
        mwd.hide();
      }).catch(results => showErrors(results, REJECT_DELETE_ACTION));

      if (this.get('location.isDefault')) {
        return onClose();
      }

      this.set('confirmationText', 'Please confirm you want to delete the following location.');
      return mwd.show({
        name: 'default-delete-location',
        title: 'Delete Location',
        body: this,
        onClose,
        statusMessages: [],
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: 'Delete Location',
          callback
        }]
      });
    },

    /**
     * Makes API call to {@link Services/ManageAccountService.deleteSite} to delete location
     */
    [CONFIRM_DELETE_ACTION]() {
      const location = this.get('location');

      if (location.isDefault) {
        return Ember.RSVP.Promise.reject({
          errors: ['Cannot delete default site.']
        });
      }

      return this.get('mas').deleteSite(location.webAddressId);
    },

    [RESOLVE_DELETE_ACTION]() {
      const displayTitle = this.get('displayTitle');
      const msg = "You successfully deleted '".concat(displayTitle, "'");
      this.setStatusMessages([new _statusMessage.default(msg, 'success')]);
      return this.refreshLocations();
    },

    /**
     * Cancels name edit if user presses `Esc`
     * Submits on `Enter`
     * @param {jQuery.Event} e
     */
    checkEscape(e) {
      if (e.keyCode === 27) {
        this.set('newLocationName', '');
        (0, _jquery.default)('body').off('keyup.checkEscape');
        return this.get('fsm').dispatch(CANCEL_ACTION);
      } else if (e.keyCode === 13) {
        (0, _jquery.default)('body').off('keyup.checkEscape');
        (0, _jquery.default)('.row-wrapper').removeClass('disabled');
        return this.get('fsm').dispatch(SUBMIT_EDIT_NAME_ACTION);
      }

      return Ember.RSVP.Promise.resolve();
    },

    actions: {
      checkEscape(e) {
        return this.checkEscape(e);
      }

    },

    init() {
      this._super(...arguments);

      this.checkEscape = this.checkEscape.bind(this);
    }

  });

  _exports.default = _default;
});