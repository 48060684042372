define("adt-wss/pods/components/dashboard/account/one-time-payment/payment-messages/component", ["exports", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/myadt-helpers"], function (_exports, _appDispatcher, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Universal display for warning and API messages
   * @module Components/Dashboard/Account/OneTimePayment/PaymentMessages
   */
  var _default = Ember.Component.extend({
    ET: Ember.inject.service('event-tagging'),

    /** Closes enroll in EasyPay reminder */
    actions: {
      showEasyPayEnrollModal() {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'EasyPay Promotion', 'Enroll in EasyPay', '1 time payment #2']);
        this.get('appDispatcher').sendEvent(_appDispatcher.SHOW_EASYPAY_ENROLL_EVENT);
        this.get('appDispatcher').sendEvent(_appDispatcher.HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT);
      },

      remindMeLater() {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'EasyPay Promotion', 'Remind me Later', '1 time payment #2']);
        this.get('ET').sendEventTag({
          eventTypeId: 'EZPAYPRO',
          subEventTypeId: 'CLKDRLTR',
          udf2: '1 x PAYMENT'
        }, false, '1');
        this.get('appDispatcher').sendEvent(_appDispatcher.HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT);
      }

    }
  });

  _exports.default = _default;
});