define("adt-wss/pods/dashboard/manage/businessreporting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SUG8greg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"section-content business-reporting-wrap\"],[8],[0,\"\\n\\t\"],[7,\"header\",true],[10,\"class\",\"business-reporting-info\"],[8],[0,\"\\n\\t\\t\"],[7,\"h3\",true],[10,\"class\",\"business-reporting-title\"],[8],[0,\"\\n\\t\\t\\tADT Business Reporting\\n\"],[4,\"if\",[[24,[\"businessReportingStatus\",\"enrolled\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"email-preferences-link blue-link\",\"dashboard.account.preferences\"]],{\"statements\":[[0,\"Email Preferences\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"business-reportion-description\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"p\",true],[8],[0,\"Receive customizable security reports, remote panel testing, event notification and user access management.\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"p\",true],[8],[0,\"\\n\\t\\t\\t\\tADT Business Reporting provides advanced monitoring for deeper analysis of your security account.\\n\\t\\t\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"target\",\"route\",\"model\"],[\"blue-link\",\"_blank\",\"help.alpha\",320]],{\"statements\":[[0,\"Learn More\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"businessReportingStatus\",\"enrolled\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"dashboard/manage/enroll-business-reporting\",null,[[\"notify\"],[[28,\"action\",[[23,0,[]],\"setStatusMessage\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"dashboard/manage/reports-list\",null,[[\"notify\"],[[28,\"action\",[[23,0,[]],\"setStatusMessage\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/manage/businessreporting/template.hbs"
    }
  });

  _exports.default = _default;
});