define("adt-wss/pods/components/dashboard/alarm/system-test-wizard/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/fp", "adt-wss-common/utils/myadt-helpers", "adt-wss/pods/components/dashboard/alarm/system-test-wizard/constants"], function (_exports, _withFsm, _fp, _myadtHelpers, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const showLocalModalWindow = (ctx, params) => ctx.get('mwd').show((0, _fp.omix)({
    title: 'System test',
    body: ctx,
    onClose: () => ctx.get('fsm').dispatch(_constants.REJECT_ACTION)
  }, params));

  const COUNT_DOWN_NEXT_BUTTON = 'countdown-next-btn';
  const fsmModel = {
    transitions: {
      [_constants.IDLE_STATE]: {
        [_constants.INITIALIZE_ACTION]: _constants.DURATION_SETTINGS_STATE,
        [_constants.REJECT_ACTION]: _constants.IDLE_STATE
      },
      [_constants.DURATION_SETTINGS_STATE]: {
        [_constants.SET_DURATION_ACTION]: _constants.PRE_START_STATE,
        [_constants.REJECT_ACTION]: _constants.IDLE_STATE
      },
      [_constants.PRE_START_STATE]: {
        [_constants.START_ACTION]: _constants.START_PENDING_STATE,
        [_constants.CANCEL_START_ACTION]: _constants.DURATION_SETTINGS_STATE,
        [_constants.REJECT_ACTION]: _constants.IDLE_STATE
      },
      [_constants.START_PENDING_STATE]: {
        [_constants.STARTED_SUCCESSFUL_ACTION]: _constants.STARTED_STATE,
        [_constants.STARTED_FAILURE_ACTION]: _constants.PRE_START_STATE
      },
      [_constants.STARTED_STATE]: {
        [_constants.TRANSITION_TO_ARM_SYSTEM_INSTRUCTION_ACTION]: _constants.ARM_SYSTEM_INSTRUCTION_STATE,
        [_constants.REJECT_ACTION]: _constants.IDLE_STATE
      },
      [_constants.ARM_SYSTEM_INSTRUCTION_STATE]: {
        [_constants.TRANSITION_TO_TRIGGER_SENSORS_INSTRUCTION_ACTION]: _constants.TRIGGER_SENSORS_INSTRUCTION_STATE,
        [_constants.REJECT_ACTION]: _constants.IDLE_STATE
      },
      [_constants.TRIGGER_SENSORS_INSTRUCTION_STATE]: {
        [_constants.TRANSITION_TO_ARM_SYSTEM_INSTRUCTION_ACTION]: _constants.ARM_SYSTEM_INSTRUCTION_STATE,
        [_constants.INIT_STOPWATCH_ACTION]: _constants.STOPWATCH_STATE,
        [_constants.REJECT_ACTION]: _constants.IDLE_STATE
      },
      [_constants.STOPWATCH_STATE]: {
        [_constants.TRANSITION_TO_TRIGGER_SENSORS_INSTRUCTION_ACTION]: _constants.TRIGGER_SENSORS_INSTRUCTION_STATE,
        [_constants.TRANSITION_TO_NO_SIGNALS_RECEIVED_INSTRUCTION_ACTION]: _constants.NO_SIGNALS_RECEIVED_INSTRUCTION_STATE,
        [_constants.REJECT_ACTION]: _constants.IDLE_STATE
      },
      [_constants.NO_SIGNALS_RECEIVED_INSTRUCTION_STATE]: {
        [_constants.TRANSITION_TO_SILENCE_ALARM_INSTRUCTION_ACTION]: _constants.SILENCE_ALARM_INSTRUCTION_STATE,
        [_constants.BACK_TO_STOPWATCH_ACTION]: _constants.STOPWATCH_STATE,
        [_constants.REJECT_ACTION]: _constants.IDLE_STATE
      },
      [_constants.SILENCE_ALARM_INSTRUCTION_STATE]: {
        [_constants.RESOLVE_ACTION]: _constants.FINAL_STATE,
        [_constants.TRANSITION_TO_NO_SIGNALS_RECEIVED_INSTRUCTION_ACTION]: _constants.NO_SIGNALS_RECEIVED_INSTRUCTION_STATE,
        [_constants.REJECT_ACTION]: _constants.IDLE_STATE
      },
      [_constants.FINAL_STATE]: {
        [_constants.COMPLETE_ACTION]: _constants.IDLE_STATE,
        [_constants.REJECT_ACTION]: _constants.IDLE_STATE
      }
    }
  };
  const modals = {
    [_constants.DURATION_SETTINGS_STATE](ctx, dispatch) {
      showLocalModalWindow(ctx, {
        name: 'system-test-wizard/duration-settings',
        actions: [{
          caption: 'Next',
          callback: () => dispatch(_constants.SET_DURATION_ACTION)
        }]
      });
    },

    [_constants.PRE_START_STATE](ctx, dispatch) {
      showLocalModalWindow(ctx, {
        name: 'system-test-wizard/pre-start',
        actions: [{
          caption: 'Back',
          stl: 'outline',
          callback: () => dispatch(_constants.CANCEL_START_ACTION)
        }, {
          caption: 'Start test',
          callback: () => dispatch(_constants.START_ACTION)
        }]
      });
    },

    [_constants.STARTED_STATE](ctx, dispatch) {
      showLocalModalWindow(ctx, {
        name: 'system-test-wizard/started',
        actions: [{
          caption: 'Next',
          callback: () => dispatch(_constants.TRANSITION_TO_ARM_SYSTEM_INSTRUCTION_ACTION)
        }]
      });
    },

    [_constants.ARM_SYSTEM_INSTRUCTION_STATE](ctx, dispatch) {
      showLocalModalWindow(ctx, {
        name: 'system-test-wizard/arm-system-instruction',
        actions: [{
          caption: 'My System is Armed',
          callback: () => dispatch(_constants.TRANSITION_TO_TRIGGER_SENSORS_INSTRUCTION_ACTION)
        }]
      });
    },

    [_constants.TRIGGER_SENSORS_INSTRUCTION_STATE](ctx, dispatch) {
      showLocalModalWindow(ctx, {
        name: 'system-test-wizard/trigger-sensors-instruction',
        actions: [{
          caption: 'Back',
          stl: 'outline',
          callback: () => dispatch(_constants.TRANSITION_TO_ARM_SYSTEM_INSTRUCTION_ACTION)
        }, {
          caption: 'Done Triggering Sensors',
          callback: () => dispatch(_constants.INIT_STOPWATCH_ACTION)
        }]
      });
    },

    [_constants.STOPWATCH_STATE](ctx, dispatch) {
      showLocalModalWindow(ctx, {
        name: 'system-test-wizard/stopwatch',
        actions: [{
          caption: 'Back',
          stl: 'outline',
          callback: () => dispatch(_constants.TRANSITION_TO_TRIGGER_SENSORS_INSTRUCTION_ACTION)
        }, {
          caption: 'Next',
          name: COUNT_DOWN_NEXT_BUTTON,
          callback: () => dispatch(_constants.TRANSITION_TO_NO_SIGNALS_RECEIVED_INSTRUCTION_ACTION)
        }]
      });
    },

    [_constants.NO_SIGNALS_RECEIVED_INSTRUCTION_STATE](ctx, dispatch) {
      showLocalModalWindow(ctx, {
        name: 'system-test-wizard/no-signals-received-instruction',
        actions: [{
          caption: 'Back',
          stl: 'outline',
          callback: () => dispatch(_constants.BACK_TO_STOPWATCH_ACTION)
        }, {
          caption: 'Continue',
          callback: () => dispatch(_constants.TRANSITION_TO_SILENCE_ALARM_INSTRUCTION_ACTION)
        }]
      });
    },

    [_constants.SILENCE_ALARM_INSTRUCTION_STATE](ctx, dispatch) {
      showLocalModalWindow(ctx, {
        name: 'system-test-wizard/silence-alarm-instruction',
        actions: [{
          caption: 'Back',
          stl: 'outline',
          callback: () => dispatch(_constants.TRANSITION_TO_NO_SIGNALS_RECEIVED_INSTRUCTION_ACTION)
        }, {
          caption: 'Next',
          callback: () => dispatch(_constants.RESOLVE_ACTION)
        }]
      });
    },

    [_constants.FINAL_STATE](ctx, dispatch) {
      showLocalModalWindow(ctx, {
        name: 'system-test-wizard/final',
        actions: [{
          caption: 'Done',
          callback: () => dispatch(_constants.COMPLETE_ACTION)
        }]
      });
    }

  };
  const defaultProps = {
    modals,
    durationsList: [{
      text: '1 Hour',
      value: '1'
    }, {
      text: '2 Hour',
      value: '2'
    }, {
      text: '4 Hour',
      value: '4'
    }, {
      text: '8 Hour',
      value: '8'
    }, {
      text: '12 Hour',
      value: '12'
    }, {
      text: '1 Day',
      value: '24'
    }, {
      text: '2 Days',
      value: '48'
    }, {
      text: '3 Days',
      value: '72'
    }, {
      text: '4 Days',
      value: '96'
    }, {
      text: '5 Days',
      value: '120'
    }, {
      text: '6 Days',
      value: '144'
    }, {
      text: '7 Days',
      value: '168'
    }],
    resolve: () => console.warn('Please pass resolve method to dashboard/account/system-test-wizard component'),
    reject: () => console.warn('Please pass reject method to dashboard/account/system-test-wizard component'),
    putSystemOnTest: () => {
      console.warn('Please pass "putSystemOnTest" method to dashboard/account/system-test-wizard component');
      return Promise.resolve();
    }
  };
  /**
   * @module Components/Dashboard/Alarm/SystemTestWizard
   * @prop {Object[]} durationsList - List of durations
   * @prop {Object[]} modals - List of modal windows
   * @prop {Object} system - current iterted system
   * @prop {Function} resolve - Function which called after successful action
   * @prop {Function} reject - Function which called after cancel of some modal window
   * @prop {Function} putSystemOnTest
   * @example
   * {@lang xml} {{ dashboard/alarm/system-test-wizard
  		modals=modals
  		durationsList=[{ text: '1 hour', value: '1h' }]
  		resolve=(action 'resolve')
  		reject=(action 'reject')
  		putSystemOnTest=(action 'putSystemOnTest')
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    modals,
    fsmModel,
    duration: Ember.computed.oneWay('durationsList.0'),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    currentState: _constants.IDLE_STATE,
    IS_START_PENDING_STATE: Ember.computed.equal('currentState', _constants.START_PENDING_STATE),
    modalWindowChanged: Ember.observer('currentState', function () {
      const {
        modals: modalWindows,
        currentState
      } = this.getProperties('modals', 'currentState');
      const modal = modalWindows[currentState];

      if (!modal) {
        return false;
      }

      return modal(this, this.actions.dispatch.bind(this));
    }),

    [_constants.STARTED_FAILURE_ACTION](error) {
      console.log('System doesn\'t start', error);
    },

    [_constants.REJECT_ACTION]() {
      this.reject();
      this.closeModalWindowHandler();
    },

    [_constants.COMPLETE_ACTION]() {
      this.resolve();
      this.closeModalWindowHandler();
    },

    [_constants.STARTED_SUCCESSFUL_ACTION]() {
      const {
        systemNo
      } = this.get('system') || {};
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'System Test', 'PutSystemOnTest', "SystemNo: ".concat(systemNo)]);
    },

    [_constants.START_ACTION]() {
      const {
        value
      } = this.get('duration');
      return this.commonDispatcher(() => this.putSystemOnTest(value), _constants.STARTED_SUCCESSFUL_ACTION, _constants.STARTED_FAILURE_ACTION);
    },

    setDuration({
      value
    }) {
      this.set('duration', value);
    },

    resetDuration() {
      this.set('duration', this.get('durationsList.0'));
    },

    closeModalWindowHandler() {
      this.resetDuration();
      this.get('mwd').hide();
    }

  });

  _exports.default = _default;
});