define("adt-wss/pods/components/dashboard/account/contract-sign-process/component", ["exports", "adt-wss-common/utils/fp", "adt-wss-common/classes/status-message", "adt-wss-common/utils/ember-helpers", "adt-wss/services/app-dispatcher", "adt-wss-common/utils/myadt-helpers", "adt-wss/services/promotion-data", "adt-wss/pods/components/dashboard/account/contract-sign-process/constants"], function (_exports, _fp, _statusMessage, _emberHelpers, _appDispatcher, _myadtHelpers, _promotionData, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    showAlert: v => v,
    onClose: v => v,
    onSuccess: v => v,
    offerId: null
  };
  const docuSignEventHandlers = {
    [_constants.DOCUMENT_SIGNED_DOCUSIGN_EVENT]({
      data
    }) {
      this.onSuccess();
      this.appDispatcher.sendEvent(_appDispatcher.HIDE_CONTRACT_RENEWAL_ALERT, {
        requireRequest: false,
        forceDocumentsRequest: true
      });
      const successMsg = {
        head: 'You have successfully renewed your security protection.',
        text: "Contract has been emailed to ".concat(data.email, ". Please allow 24 hours for your new contract to display below.")
      };
      this.showAlert([new _statusMessage.default(successMsg, 'success')]);
      this.sendAnalyticEvent('Contract Successfully Renewed', 'Success');
    },

    [_constants.USER_CLOSED_DOCUSIGN_MODAL_EVENT]() {
      this.showAlert([new _statusMessage.default(_constants.WARN_MESSAGE, 'warn')]);
    },

    [_constants.DOCUMENT_CANCELED_DOCUSIGN_EVENT]() {
      this.showAlert([new _statusMessage.default(_constants.WARN_MESSAGE, 'warn')]);
    },

    [_constants.DOCUMENT_DECLINED_DOCUSIGN_EVENT]() {
      this.showAlert([new _statusMessage.default(_constants.WARN_MESSAGE, 'warn')]);
      this.appDispatcher.sendEvent(_appDispatcher.HIDE_CONTRACT_RENEWAL_ALERT, {
        requireRequest: false
      });
    },

    [_constants.DOCUSIGN_SESSION_EXPIRED_EVENT]({
      data
    }) {
      const erroMsg = {
        head: _constants.ERROR_MESSAGE_TITLE,
        text: "Please contact ADT at ".concat(data.contactPhoneNumber, " to complete your order.")
      };
      this.showAlert([new _statusMessage.default(erroMsg, 'error')]);
      this.sendAnalyticEvent('Timeout', 'DocuSign Timeout');
    },

    [_constants.DOCUMENT_EXPIRED_DOCUSIGN_EVENT]({
      data
    }) {
      const erroMsg = {
        head: _constants.ERROR_MESSAGE_TITLE,
        text: "Please contact ADT at ".concat(data.contactPhoneNumber, " to complete your order.")
      };
      this.showAlert([new _statusMessage.default(erroMsg, 'error')]);
    }

  };
  /**
   * @module Components/Dashboard/Account/ContractSignProcess
   * @prop {Number} offerId
   * @prop {String} eventTagUdf2
   * @prop {Function} showAlert
   * @prop {Function} onClose
   * @prop {Function} sendEventTag
   * @example
   * {@lang xml} {{ dashboard/account/contract-sign-process
  		onClose=(action 'onClose')
  		showAlert=(action 'setMessages')
  		onSuccess=(action 'hideRenewalOffers')
  		offerId=8
  		eventTagUdf2=selectedUdfOption
  		sendEventTag=sendEventTag
  }}
   */

  var _default = Ember.Component.extend(defaultProps, {
    promotionData: Ember.inject.service(),
    appDispatcher: Ember.inject.service(),
    media: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    iframeHeight: (0, _emberHelpers.computedApplyFunction)(media => (Object.entries({
      [_constants.TABLET_IFRAME_HEIGHT]: media.isTablet || media.isLargetTablet,
      [_constants.SMARTPHONE_IFRAME_HEIGHT]: media.isSmartphone
    }).find(([, value]) => value) || [null])[0], 'media'),

    sendAnalyticEvent(...args) {
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Contract Renewal 2', ...args]);
    },

    /**
     * Parses query params from string to plain object
     *
     * @function
     * @returns {Object}
     */
    parseQueryParams(params) {
      return params.slice(1).split('&').reduce((acc, param) => {
        const [key, value] = param.split('=');
        return (0, _fp.omix)(acc, {
          [key]: value
        });
      }, {});
    },

    /**
     * Handler for signature complete event
     *
     * @function
     * @returns {Promise}
     */
    docuSignCompleteEventHandler(queryParams) {
      const {
        event
      } = this.parseQueryParams(queryParams);
      const handlerExists = Object.keys(this.docuSignEventHandlers).includes(event);
      return this.onCloseModalWindowHandler(event).then(resp => handlerExists ? this.docuSignEventHandlers[event].call(this, resp) : null).catch(({
        data
      }) => {
        const erroMsg = {
          head: _constants.ERROR_MESSAGE_TITLE,
          text: "Please contact ADT at ".concat(data.contactPhoneNumber, " to complete your order.")
        };
        this.showAlert([new _statusMessage.default(erroMsg, 'error')]);
      });
    },

    /**
     * Handler for closing a modal window
     * Calls '/api/v2/contractRenewalWithOffers' endpoint
     *
     * @function
     * @returns {Promise}
     */
    onCloseModalWindowHandler(docuSignEventType) {
      this.mwd.hide();
      this.onClose();
      this.set('esignURL', null);
      return this.promotionData.contractRenewalWithOffers({
        docuSignEventType
      });
    },

    init(...args) {
      this._super(...args);

      this.docuSignEventHandlers = docuSignEventHandlers;
      window.docuSignCompleteEventHandler = this.docuSignCompleteEventHandler.bind(this);
    },

    didInsertElement() {
      this.mwd.show({
        name: 'contract-agreement-docu-sign',
        title: 'Contract Agreement (DocuSign)',
        body: this,
        onClose: () => {
          this.sendEventTag({
            subEventTypeId: _promotionData.eventTagValues.CONTRACT_RENEWAL_MODAL_CLOSE_BTN,
            udf2: this.eventTagUdf2
          });
          return this.onCloseModalWindowHandler(_constants.USER_CLOSED_DOCUSIGN_MODAL_EVENT).then(this.docuSignEventHandlers[_constants.USER_CLOSED_DOCUSIGN_MODAL_EVENT].bind(this));
        },
        actions: []
      });
      return this.promotionData.generateContractRenewalDocDetails({
        offerId: this.offerId
      }).then(({
        data
      }) => this.setProperties(data)).then(() => {
        this.sendEventTag({
          subEventTypeId: _promotionData.eventTagValues.DOCUSIGN_MODAL_LOAD,
          udf2: this.eventTagUdf2
        });
        this.sendAnalyticEvent('DocuSign Loads', 'Success');
      }).catch(({
        data,
        errors
      }) => {
        this.sendAnalyticEvent('Docusign Fails to Load', 'Failure');
        this.sendEventTag({
          subEventTypeId: _promotionData.eventTagValues.DOCUSIGN_MODAL_LOAD_FAILURE,
          udf2: this.eventTagUdf2
        });
        this.mwd.hide();
        this.onClose();
        this.showAlert([new _statusMessage.default({
          head: _constants.ERROR_MESSAGE_TITLE,
          text: _myadtHelpers.default.localizeStringWithParams(Object.entries(data || {}).map(([fieldName, fieldValue]) => ({
            fieldName,
            fieldValue
          })), errors[0])
        }, 'error')]);
      });
    },

    didDestroyElement() {
      window.docuSignCompleteEventHandler = null;
    }

  });

  _exports.default = _default;
});