define("adt-wss/pods/components/dashboard/alarm/alarm-system-info/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/fp", "adt-wss/services/app-dispatcher", "adt-wss-common/classes/status-message", "adt-wss-common/utils/ember-helpers", "adt-wss/pods/components/dashboard/alarm/alarm-system-info/constants"], function (_exports, _withFsm, _myadtHelpers, _fp, _appDispatcher, _statusMessage, _emberHelpers, constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.fsmModel = void 0;
  const {
    UPDATE_SYSTEM_ACTION,
    SUCCESS_SYSTEM_ACTION,
    FAILURE_SYSTEM_ACTION,
    IDLE_STATE,
    LOADING_STATE,
    ERROR_STATE,
    FETCH_DATA_ACTION,
    SUCCESS_ACTION,
    FAILURE_ACTION
  } = constants;
  const fsmModel = {
    transitions: {
      [IDLE_STATE]: {
        [FETCH_DATA_ACTION]: LOADING_STATE,
        [UPDATE_SYSTEM_ACTION]: LOADING_STATE
      },
      [ERROR_STATE]: {
        [FETCH_DATA_ACTION]: LOADING_STATE
      },
      [LOADING_STATE]: {
        [SUCCESS_ACTION]: IDLE_STATE,
        [FAILURE_ACTION]: ERROR_STATE,
        [SUCCESS_SYSTEM_ACTION]: IDLE_STATE,
        [FAILURE_SYSTEM_ACTION]: IDLE_STATE
      }
    }
  };
  /**
   * @module Components/Dashboard/Alarm/AlarmSystemInfo
   * @prop {Array} systemList - user systems
   * @example
   * {@lang xml} {{ dashboard/alarm/alarm-system-info
  		systemList=null
  }}
   */

  _exports.fsmModel = fsmModel;

  var _default = Ember.Component.extend(_withFsm.default, {
    mwd: Ember.inject.service('modal-window-dispatcher'),
    router: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    systemInformation: Ember.inject.service(),
    dsm: Ember.inject.service('dashboard-status-messages'),
    ET: Ember.inject.service('event-tagging'),
    statusMessages: Ember.computed.alias('dsm.statusMessages'),
    systemList: null,
    hasNoCodes: (0, _emberHelpers.computedApplyFunction)(systemList => {
      return !systemList[0].armingSequence && !systemList[0].disarmingSequence && !systemList[0].silenceBeepingSequence && !systemList[0].resetSystemSequence;
    }, 'systemList'),
    fsmModel,
    initAction: FETCH_DATA_ACTION,
    IS_LOADING: (0, _emberHelpers.isState)(LOADING_STATE),

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    replaceSplitProperty(prop) {
      return (prop || '').replace(/[[\]]/g, '').split(', ');
    },

    separateKeyCodes(systemList) {
      return (systemList || []).map(system => (0, _fp.omix)(system, {
        armingSequenceArr: this.replaceSplitProperty(system.armingSequence),
        disarmingSequenceArr: this.replaceSplitProperty(system.disarmingSequence),
        silenceBeepingSequenceArr: this.replaceSplitProperty(system.silenceBeepingSequence),
        resetSystemSequenceArr: this.replaceSplitProperty(system.resetSystemSequence),
        isCurrent: Boolean(system.isCurrent)
      }));
    },

    systemSetRequestDone(successMsg, msgType) {
      const mwd = this.get('mwd');
      mwd.hide();
      this.get('router').transitionTo('dashboard.alarm.system');
      this.set('statusMessages', [new _statusMessage.default(successMsg, msgType)]);
    },

    [UPDATE_SYSTEM_ACTION](system) {
      const systemInformation = this.get('systemInformation');
      const data = {
        csNo: system.csNo,
        systemDetailId: system.systemId,
        systemStandardizedName: system.systemStandardizedName
      };
      return this.commonDispatcher(() => systemInformation.setSystem(data, {
        systemId: system.systemId
      }), SUCCESS_SYSTEM_ACTION, FAILURE_SYSTEM_ACTION);
    },

    [SUCCESS_SYSTEM_ACTION]() {
      const successMsg = 'Thank you for confirming your system.';
      this.systemSetRequestDone(successMsg, 'success');
    },

    [FAILURE_SYSTEM_ACTION]() {
      const errorMsg = 'Sorry, we seem to be having technical issues assigning this system to your account. Please try again later.';
      this.systemSetRequestDone(errorMsg, 'error');
    },

    [SUCCESS_ACTION](systemListToParse) {
      const systemList = this.get('systemDetails') || systemListToParse;
      const sys = systemList[0] || null;

      if (sys && sys.systemStandardizedName) {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'CustomerProfile', 'System Displayed', sys.standardSystemName]);
      }

      this.set('systemList', this.separateKeyCodes(systemList));
    },

    [FETCH_DATA_ACTION](forceRequest) {
      return this.deliverDataAndDispatch('systemInformation.systemInfoListFull', systemInfo => systemInfo.getStandardAndSystemInfoLists().then(() => systemInfo.get('systemInfoListFull')), SUCCESS_ACTION, FAILURE_ACTION, false, false, {
        forceRequest
      });
    },

    changeLocationHandler() {
      this.get('fsm').dispatch(FETCH_DATA_ACTION, true);
    },

    openOrderModal(battery) {
      const mwd = this.get('mwd');

      const onClose = () => mwd.hide();

      const body = (0, _fp.omix)(battery, {
        showIframe: false
      });

      body.toggleShowIframe = () => Ember.set(body, 'showIframe', true);

      mwd.show({
        name: 'system-battery-confirm-modal',
        title: 'Important Battery Information',
        body,
        onClose,
        actions: [{
          caption: 'Retailers',
          stl: 'outline',
          callback: () => {
            mwd.hide();
            this.openRetailersModal();
          }
        }, {
          caption: 'Order Battery',
          callback: () => this.get('router').transitionTo('dashboard.shop.checkout.batteryid', battery.batteryID)
        }]
      });
    },

    openRetailersModal(battery) {
      const mwd = this.get('mwd');

      const onClose = () => mwd.hide();

      mwd.show({
        name: 'system-battery-retailers-modal',
        title: 'Shop Other Retailers',
        onClose,
        actions: [{
          caption: 'Close',
          stl: 'outline',
          callback: onClose
        }]
      });
      const eventTagInfo = {
        eventTypeId: 'BATTMODL',
        subEventTypeId: 'RETCLICK'
      };
      this.get('ET').sendEventTag(eventTagInfo);
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Products', 'Battery Retailers Modal', "Interested in: System: ".concat(battery.batteryName)]);
    },

    actions: {
      openOrderModal(battery) {
        this.openOrderModal(battery);
      },

      openRetailersModal(battery) {
        this.openRetailersModal(battery);
      },

      notYourSystem() {
        this.get('mwd').hide();
      }

    }
  });

  _exports.default = _default;
});