define("adt-wss/pods/components/dashboard/account/contacts/contact-dispatch/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers", "adt-wss-common/classes/status-message", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/common-fsm-state", "adt-wss/services/app-dispatcher", "adt-wss/pods/components/dashboard/account/contacts/contact-dispatch/constants"], function (_exports, _withFsm, _emberHelpers, _statusMessage, _myadtHelpers, _commonFsmState, _appDispatcher, constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    SUCCESSFUL_SAVE_MESSAGE,
    SUCCESSFUL_DELETE_MESSAGE,
    SUCCESSFUL_ADD_MESSAGE,
    SUCCESS_STATUS,
    ERROR_STATUS
  } = constants;
  const typesInfo = {
    postdispatch: {
      name: 'Post-Dispatch',
      optional: true,
      description: "\n\t\t\tThis list may include family members or neighbors.\n\t\t"
    },
    predispatch: {
      name: 'Pre-Dispatch',
      optional: false,
      description: "\n\t\t\tThis is to confirm the alarm wasn't an accident. ADT will require the verbal security password to cancel the alarm.\n\t\t\tFire and carbon monoxide alarms don't require pre-dispatch verification.\n\t\t"
    }
  };
  const defaultProps = {
    icon: 'dashboard',
    type: 'predispatch',
    contacts: [],
    setMessages: v => v,
    systemPhoneNumbersDuplication: false,

    determinePhoneDuplication() {
      console.warn('Please pass "determinePhoeDuplication" method for "contact-dispatch" component');
    },

    determinePhoneListDuplication() {
      console.warn('Please pass "determinePhoneListDuplication" method for "contact-dispatch" component');
    }

  };
  /**
   * @module Components/Dashboard/Account/Contacts/ContactDispatch
   * @prop {String} elementId - ID attribute for div component wrapper
   * @prop {Array} contacts - List of contacts
   * @prop {Function} determinePhoneDuplication
   * @prop {Function} determinePhoeListDuplication
   * @prop {Boolean} systemPhoneNumbersDuplication
   * @example
   * {@lang xml} {{ dashboard/account/contacts/contact-dispatch
   * contacts=[{ firstName: 'John', lastName: 'Doe', orderNum: 1, phoneNumbers: ['804-829-1423'] }]
   * setMessages=(action 'setMessages')
   * determinePhoneDuplication=(action 'determinePhoneDuplication')
   * determinePhoneListDuplication=(action 'determinePhoneListDuplication')
   * systemPhoneNumbersDuplication=true
  }}
   */

  var _default = Ember.Component.extend(_commonFsmState.commonMixin, _withFsm.default, defaultProps, {
    contactData: Ember.inject.service(),
    csrInfo: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    classNames: ['contact-dispatch', 'widget-section'],
    classNameBindings: ['editing', 'systemPhoneNumbersDuplication'],
    currentState: _commonFsmState.IDLE_STATE,
    editing: false,
    phonesDuplicationTable: Ember.computed.readOnly('contactData.phonesDuplicationTable'),
    loading: (0, _emberHelpers.isState)(_commonFsmState.LOADING_STATE),
    IS_IDLE_STATE: (0, _emberHelpers.isState)(_commonFsmState.IDLE_STATE),
    START_INDEX: Ember.computed.oneWay('contacts.0.position'),
    info: (0, _emberHelpers.computedApplyFunction)(t => typesInfo[t], 'type'),
    storageKey: (0, _emberHelpers.computedApplyFunction)(t => "contactData.".concat(t === defaultProps.type ? 'preDispatchContacts' : 'postDispatchContacts'), 'type'),
    isSplitIntegrationSystems: Ember.computed.oneWay('currentLocation.splitIntSystem'),

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    willDestroy() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'changeLocationHandler');
    },

    [_commonFsmState.FETCH_DATA_ACTION](forceRequest) {
      const {
        fsm,
        storageKey
      } = this.getProperties('fsm', 'storageKey');
      const deliverOptions = {
        forceRequest
      };
      return this.deliverData('contactData.addlContacts', contactData => contactData.getContacts(), deliverOptions).then(() => fsm.dispatch(_commonFsmState.SUCCESS_ACTION, this.get(storageKey))).catch(error => fsm.dispatch(_commonFsmState.FAILURE_ACTION, error));
    },

    [_commonFsmState.SUCCESS_ACTION](contacts) {
      this.set('contacts', contacts);
      this.set('items', contacts);
    },

    handleSuccessResponse(message) {
      this.setMessages([new _statusMessage.default(message, SUCCESS_STATUS)]);
      return this.get('fsm').dispatch(_commonFsmState.FETCH_DATA_ACTION);
    },

    changeLocationHandler() {
      this.get('fsm').dispatch(_commonFsmState.FETCH_DATA_ACTION, true);
    },

    handleErrors(results) {
      this.setMessages((0, _myadtHelpers.genericErrorHandler)(results).map(err => new _statusMessage.default(err, ERROR_STATUS)));
      return Promise.reject(results);
    },

    clearMessages() {
      this.setMessages([]);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('startTime', Date.now());
    },

    actions: {
      reorderItems(itemModels) {
        const {
          type,
          startTime,
          items
        } = this.getProperties('type', 'startTime', 'items');

        if (itemModels.every((el, i) => el.contactNo === items[i].contactNo)) {
          return false;
        }

        this.get('contactData').reorderContacts(itemModels, {
          type,
          startTime
        });
        return this.set('items', itemModels);
      },

      addNewContact() {
        this.actions.setEditStatus.call(this, true);
        this.get('items').pushObject({
          new: true,
          ecv: this.get('type') === defaultProps.type,
          manage: false,
          irregularOpen: false,
          position: this.get('items.length'),
          phoneNumbers: [{
            seqNo: '',
            type: null,
            number: '',
            extension: ''
          }],
          password: '',
          firstName: '',
          lastName: '',
          seqNo: ''
        });
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackPageview', '/dashboard/account/contacts.jsp/AddNewContactChosen']);
      },

      cancelAddingContact() {
        this.get('items').popObject();
      },

      setEditStatus(value) {
        this.set('editing', value);
      },

      editContact(data) {
        this.clearMessages();
        return this.get('contactData').updateAddlContact(data, {
          startTime: this.get('startTime')
        }).then(() => this.handleSuccessResponse(SUCCESSFUL_SAVE_MESSAGE)).catch(err => this.handleErrors(err));
      },

      deleteContact(data) {
        this.clearMessages();
        return this.get('contactData').removeContact(data, {
          startTime: this.get('startTime')
        }).then(() => this.handleSuccessResponse(SUCCESSFUL_DELETE_MESSAGE)).catch(err => this.handleErrors(err));
      },

      createContact(data) {
        this.clearMessages();
        return this.get('contactData').addAddlContact(data, {
          startTime: this.get('startTime')
        }).then(() => this.handleSuccessResponse(SUCCESSFUL_ADD_MESSAGE)).catch(err => this.handleErrors(err));
      },

      exportContactData() {
        this.get('contactData').exportContactData({
          startTime: this.get('startTime')
        });
      },

      printContactData() {
        this.get('contactData').printContactData();
      }

    }
  });

  _exports.default = _default;
});