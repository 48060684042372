define("adt-wss/services/account-data", ["exports", "adt-wss-common/services/account-data", "adt-wss-common/utils/myadt-helpers"], function (_exports, _accountData, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let Service = (_dec = Ember.inject.service('event-tagging'), (_class = (_temp = class Service extends _accountData.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "ET", _descriptor, this);

      _initializerDefineProperty(this, "customerInfo", _descriptor2, this);
    }

    getHttpSessionId(chatId) {
      return this.apiService.myADT.sessionIdForPSChatId({
        chatId
      }).then(({
        data
      }) => data);
    }

    sendDocumentAnalyticEvent(docType) {
      const content = {
        CONTRACTAMENDMENT: 'Rider',
        CONTRACT: 'Contract',
        INSPECTIONREPORT: 'Inspection_Report'
      };
      const eventTagInfo = {
        eventTypeId: 'VIEWCONT',
        subEventTypeId: 'VIEW'
      };
      this.ET.sendEventTag(eventTagInfo);

      _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'CustomerProfile', content[docType]]);
    }

    getAccountInfo() {
      const getAccountInfo = () => super.getAccountInfo;

      if (this.accountInfo) return Ember.RSVP.resolve(this.accountInfo);
      return getAccountInfo().call(this);
    }

    get hideContractDocuments() {
      return this.accountDocsResponse.hideContractDocuments;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ET", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "customerInfo", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Service;
});