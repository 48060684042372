define("adt-wss/templates/modal-windows/contract-agreement-docu-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DvIigqRu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"loading-spinner\",[[28,\"not\",[[24,[\"body\",\"esignURL\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"stupid/simple-iframe\",null,[[\"url\",\"iFrameID\",\"height\"],[[24,[\"body\",\"esignURL\"]],[28,\"concat\",[\"docuSignIframe\"],null],[24,[\"body\",\"iframeHeight\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/contract-agreement-docu-sign.hbs"
    }
  });

  _exports.default = _default;
});