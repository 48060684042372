define("adt-wss/pods/components/dashboard/flexfi-identifier/component", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers"], function (_exports, _emberHelpers, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Component/Dashboard/FlexFiIdentifier
   * @prop {Object} flexfiDetails passed
   * @prop {FlexFiFinancierDetails} flexfiDetails.financierData
   * @prop {FlexFiInstallmentDetails} flexfiDetails.installmentData
   */
  var _default = Ember.Component.extend({
    flexfiData: Ember.inject.service(),
    systemsExist: (0, _emberHelpers.computedApplyFunction)(s => (s || []).length > 0, 'csNo'),
    installmentData: Ember.computed.readOnly('flexfiData.installmentData'),
    financierData: Ember.computed.readOnly('flexfiData.financierData'),
    classNames: ['flex-fi-component', 'active'],

    init() {
      this._super(...arguments);

      this.initializeGAEvent();
    },

    initializeGAEvent() {
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'MyADT Billing', 'MyADT FlexFi', 'Active']);
    }

  });

  _exports.default = _default;
});