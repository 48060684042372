define("adt-wss/pods/components/dashboard/account/payment-amount-entry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PdwFBhc+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"dt\",true],[8],[0,\"Payment amount\"],[9],[0,\"\\n\"],[7,\"dd\",true],[8],[0,\"\\n\\t\"],[1,[28,\"stupid/amount-input\",null,[[\"value\",\"error\"],[[24,[\"paymentAmount\"]],[24,[\"errors\",\"amount\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"dt\",true],[8],[0,\"Payment date\"],[9],[0,\"\\n\"],[7,\"dd\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"errors\",\"date\"]],\"error\"],null]]]],[8],[0,\"\\n\\t\"],[1,[28,\"bootstrap-datepicker\",null,[[\"classNames\",\"value\",\"format\",\"startDate\",\"readonly\",\"autoclose\"],[\"payment-date\",[24,[\"paymentDate\"]],\"mm/dd/yyyy\",[24,[\"startDate\"]],true,true]]],false],[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"calendar-icon\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/account/payment-amount-entry/template.hbs"
    }
  });

  _exports.default = _default;
});