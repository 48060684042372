define("adt-wss/pods/components/timeout-modal-window/component", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss/services/app-dispatcher"], function (_exports, _myadtHelpers, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authInfo: Ember.inject.service(),
    session: Ember.inject.service(),
    apiService: Ember.inject.service(),
    userIdle: Ember.inject.service(),
    media: Ember.inject.service(),
    isIdle: Ember.computed.readOnly('userIdle.isIdle'),
    isCsr: Ember.computed.readOnly('authInfo.authInfo.isCsr'),
    isPSEmailLoginAuthenticated: Ember.computed.readOnly('authInfo.authInfo.isPSEmailLoginAuthenticated'),
    intervals: 0,
    intervalInstance: null,
    seconds: 60,
    opened: false,

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.SHOW_TIMEOUT_MODAL_WINDOW, this, 'open');
    },

    willDestroyElement() {
      this._super(...arguments);

      this.get('appDispatcher').unsubscribe(_appDispatcher.SHOW_TIMEOUT_MODAL_WINDOW, this, 'open');
    },

    timeout(skipGa) {
      if (skipGa) {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Login', 'session expired']);
      }

      const {
        isCsr,
        session
      } = this.getProperties('isCsr', 'session');

      if (isCsr) {
        return session.endImpersonation({
          reason: 'SESSION_TIMEOUT'
        });
      }

      return session.invalidate(false, 'inactivity');
    },

    open() {
      const intVal = setInterval(() => {
        if (this.get('seconds')) {
          this.decrementProperty('seconds');
        } else {
          clearInterval(intVal);
          this.timeout(true);
        }
      }, 1000);
      this.set('intervalInstance', intVal);
      this.set('opened', true);
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Login', 'Auto logged out message shown']);
    },

    no() {
      this.close(true);
      this.timeout();
    },

    yes() {
      this.close();
    },

    close(skipGa) {
      const intervalInstance = this.get('intervalInstance');
      clearInterval(intervalInstance);
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Login', "session ".concat(skipGa ? 'closed' : 'continued')]);
      this.set('opened', false);
    }

  });

  _exports.default = _default;
});