define("adt-wss/pods/components/password-validator/component", ["exports", "adt-wss-common/utils/ember-helpers"], function (_exports, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Widgets/PasswordValidator
   * @prop {String} password must be mutable value from parent component
   * @prop {Function|Boolean} setErrorState action or mutable value from parent component // if mutable value, should be separate from field error indicator
   * @prop {Boolean} [onlyOnFocus] hides validator until focus is on target input field
   * @example {{input
   * 		selenium-id="new-password"
   * 		id="password1"
   * 		enter=(action 'dispatch' 'submitNewPassword')
   * 		class="password1 show-password"
   * 		value=password1
   * 		type=(if showPassword "text" "password")}}
   * 	{{password-validator password=password1 setErrorState=(action (mut invalidPassword))}}
   */
  var _default = Ember.Component.extend({
    classNames: ['password-validator', 'password_validation'],
    classNameBindings: ['onlyOnFocus:focus-only', 'failing'],
    MIN_LENGTH: 8,
    MAX_LENGTH: 71,
    fitsMinLimit: (0, _emberHelpers.computedApplyFunction)((p, limit) => p.length >= limit, 'password', 'MIN_LENGTH'),
    fitsMaxLimit: (0, _emberHelpers.computedApplyFunction)((p, limit) => p.length <= limit, 'password', 'MAX_LENGTH'),
    hasNumberOrSymbol: (0, _emberHelpers.computedApplyFunction)((p, hasLetter) => /^(?=.*[A-Za-z])((?=.*\d)|(?=.*[\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e]))[A-Za-z\d\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e]+$/.test(p) && hasLetter || /^((?=.*\d)|(?=.*[\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e]))[\d\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e]+$/.test(p) && !hasLetter, 'password', 'hasLetter'),
    hasLetter: (0, _emberHelpers.computedApplyFunction)(p => /[a-zA-Z]/.test(p), 'password'),
    failing: (0, _emberHelpers.computedApplyFunction)((...args) => !args.every(Boolean), 'fitsMinLimit', 'fitsMaxLimit', 'hasLetter', 'hasNumberOrSymbol'),
    failObserver: Ember.observer('failing', function () {
      return this.setErrorState(this.failing);
    })
  });

  _exports.default = _default;
});