define("adt-wss/pods/components/dashboard/alarm/system-test-wizard-stopwatch/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.STOP_ACTION = _exports.COUNTDOWN_ACTION = _exports.IDLE_STATE = _exports.RUNNING_STATE = void 0;
  const RUNNING_STATE = 'RUNNING_STATE';
  _exports.RUNNING_STATE = RUNNING_STATE;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const COUNTDOWN_ACTION = 'COUNTDOWN_ACTION';
  _exports.COUNTDOWN_ACTION = COUNTDOWN_ACTION;
  const STOP_ACTION = 'STOP_ACTION';
  _exports.STOP_ACTION = STOP_ACTION;
});