define("adt-wss/pods/components/widget/tool-tip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jh1e3wiK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"\\t\"],[14,1,[[28,\"hash\",null,[[\"icon\",\"tip\"],[[28,\"component\",[\"widget/tool-tip/icon\"],[[\"iconType\",\"icon\"],[[24,[\"iconType\"]],[24,[\"icon\"]]]]],[28,\"component\",[\"widget/tool-tip/tip\"],[[\"position\",\"width\",\"height\",\"tipType\"],[[24,[\"tipPosition\"]],[24,[\"width\"]],[24,[\"height\"]],[24,[\"tipType\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[1,[28,\"component\",[\"widget/tool-tip/icon\"],[[\"iconType\",\"icon\"],[[24,[\"iconType\"]],[24,[\"icon\"]]]]],false],[0,\"\\n\\t\"],[1,[28,\"component\",[\"widget/tool-tip/tip\"],[[\"position\",\"tip\",\"width\",\"height\",\"tipType\"],[[24,[\"tipPosition\"]],[24,[\"tip\"]],[24,[\"width\"]],[24,[\"height\"]],[24,[\"tipType\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/widget/tool-tip/template.hbs"
    }
  });

  _exports.default = _default;
});