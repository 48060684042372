define("adt-wss/pods/components/dashboard/account/contacts/location-number/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ON_ENTER_ACTION = _exports.FAILURE_DELETE_ACTION = _exports.SUCCESSFUL_DELETE_ACTION = _exports.FAILURE_UPDATE_ACTION = _exports.SUCCESSFUL_UPDATE_ACTION = _exports.DELETE_ACTION = _exports.UPDATE_ACTION = _exports.CANCEL_EDIT_MODE_ACTION = _exports.CANCEL_PRE_DELETE_ACTION = _exports.SET_PRE_DELETE_MODE_ACTION = _exports.SET_EDIT_MODE_ACTION = _exports.ON_ENTER_STATE = _exports.PRE_DELETE_STATE = _exports.DELETING_STATE = _exports.UPDATING_STATE = _exports.EDIT_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const EDIT_STATE = 'EDIT_STATE';
  _exports.EDIT_STATE = EDIT_STATE;
  const UPDATING_STATE = 'UPDATING_STATE';
  _exports.UPDATING_STATE = UPDATING_STATE;
  const DELETING_STATE = 'DELETING_STATE';
  _exports.DELETING_STATE = DELETING_STATE;
  const PRE_DELETE_STATE = 'PRE_DELETE_STATE';
  _exports.PRE_DELETE_STATE = PRE_DELETE_STATE;
  const ON_ENTER_STATE = 'ON_ENTER_STATE';
  _exports.ON_ENTER_STATE = ON_ENTER_STATE;
  const SET_EDIT_MODE_ACTION = 'SET_EDIT_MODE_ACTION';
  _exports.SET_EDIT_MODE_ACTION = SET_EDIT_MODE_ACTION;
  const SET_PRE_DELETE_MODE_ACTION = 'SET_PRE_DELETE_MODE_ACTION';
  _exports.SET_PRE_DELETE_MODE_ACTION = SET_PRE_DELETE_MODE_ACTION;
  const CANCEL_PRE_DELETE_ACTION = 'CANCEL_PRE_DELETE_ACTION';
  _exports.CANCEL_PRE_DELETE_ACTION = CANCEL_PRE_DELETE_ACTION;
  const CANCEL_EDIT_MODE_ACTION = 'CANCEL_EDIT_MODE_ACTION';
  _exports.CANCEL_EDIT_MODE_ACTION = CANCEL_EDIT_MODE_ACTION;
  const UPDATE_ACTION = 'UPDATE_ACTION';
  _exports.UPDATE_ACTION = UPDATE_ACTION;
  const DELETE_ACTION = 'DELETE_ACTION';
  _exports.DELETE_ACTION = DELETE_ACTION;
  const SUCCESSFUL_UPDATE_ACTION = 'SUCCESSFUL_UPDATE_ACTION';
  _exports.SUCCESSFUL_UPDATE_ACTION = SUCCESSFUL_UPDATE_ACTION;
  const FAILURE_UPDATE_ACTION = 'FAILURE_UPDATE_ACTION';
  _exports.FAILURE_UPDATE_ACTION = FAILURE_UPDATE_ACTION;
  const SUCCESSFUL_DELETE_ACTION = 'SUCCESSFUL_DELETE_ACTION';
  _exports.SUCCESSFUL_DELETE_ACTION = SUCCESSFUL_DELETE_ACTION;
  const FAILURE_DELETE_ACTION = 'FAILURE_DELETE_ACTION';
  _exports.FAILURE_DELETE_ACTION = FAILURE_DELETE_ACTION;
  const ON_ENTER_ACTION = 'ON_ENTER_ACTION';
  _exports.ON_ENTER_ACTION = ON_ENTER_ACTION;
});