define("adt-wss/pods/components/dashboard/alarm/alarm-system-info/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FAILURE_ACTION = _exports.SUCCESS_ACTION = _exports.FETCH_DATA_ACTION = _exports.ERROR_STATE = _exports.LOADING_STATE = _exports.IDLE_STATE = _exports.FAILURE_SYSTEM_ACTION = _exports.SUCCESS_SYSTEM_ACTION = _exports.UPDATE_SYSTEM_ACTION = void 0;
  const UPDATE_SYSTEM_ACTION = 'UPDATE_SYSTEM_ACTION';
  _exports.UPDATE_SYSTEM_ACTION = UPDATE_SYSTEM_ACTION;
  const SUCCESS_SYSTEM_ACTION = 'SUCCESS_SYSTEM_ACTION';
  _exports.SUCCESS_SYSTEM_ACTION = SUCCESS_SYSTEM_ACTION;
  const FAILURE_SYSTEM_ACTION = 'FAILURE_SYSTEM_ACTION';
  _exports.FAILURE_SYSTEM_ACTION = FAILURE_SYSTEM_ACTION;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const LOADING_STATE = 'LOADING_STATE';
  _exports.LOADING_STATE = LOADING_STATE;
  const ERROR_STATE = 'ERROR_STATE';
  _exports.ERROR_STATE = ERROR_STATE;
  const FETCH_DATA_ACTION = 'FETCH_DATA_ACTION';
  _exports.FETCH_DATA_ACTION = FETCH_DATA_ACTION;
  const SUCCESS_ACTION = 'SUCCESS_ACTION';
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
  const FAILURE_ACTION = 'FAILURE_ACTION';
  _exports.FAILURE_ACTION = FAILURE_ACTION;
});