define("adt-wss/pods/ps/auth/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "adt-wss-common/utils/route-helpers"], function (_exports, _authenticatedRouteMixin, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    myadtSecurity: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    dataInitialized: false,

    /**
     * Validates PIC and gets data necessary for the
     * dashboard to run.
     * Uses `call` and `apply` to deal with scoping
     * issues that appeared at runtime during testing
     */
    beforeModel() {
      this._super(...arguments);

      this.startup();
    },

    startup() {
      const {
        session,
        myadtSecurity,
        authInfo
      } = this.getProperties('session', 'myadtSecurity', 'authInfo');

      const startSecurity = () => myadtSecurity.start.apply(myadtSecurity);

      const logout = () => {
        if (session.isAuthenticated) {
          session.invalidate(false, 'noAcctInfo');
        }
      };

      const resolveReceivedData = (0, _routeHelpers.promiseResolver)(resolved => {
        this.set('dataInitialized', true);
        return resolved;
      });

      if (!myadtSecurity.picProvided) {
        return authInfo.getAuthInfo().then(startSecurity).then(resolveReceivedData).catch(logout);
      }

      return Ember.RSVP.Promise.resolve();
    }

  });

  _exports.default = _default;
});