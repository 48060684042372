define("adt-wss/pods/components/dashboard/manage/delete-report/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/common-fsm-state", "adt-wss-common/utils/ember-helpers", "adt-wss/pods/components/dashboard/manage/delete-report/constants"], function (_exports, _withFsm, _myadtHelpers, _commonFsmState, _emberHelpers, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fsmModel = {
    transitions: {
      [_commonFsmState.IDLE_STATE]: {
        [_constants.OPEN_MODAL_ACTION]: _constants.MODAL_WINDOW_STATE
      },
      [_constants.MODAL_WINDOW_STATE]: {
        [_constants.DELETE_ACTION]: _constants.DELETING_STATE,
        [_constants.CLOSE_MODAL_WINDOW_ACTION]: _commonFsmState.IDLE_STATE
      },
      [_constants.DELETING_STATE]: {
        [_commonFsmState.SUCCESS_ACTION]: _commonFsmState.IDLE_STATE,
        [_commonFsmState.FAILURE_ACTION]: _constants.MODAL_WINDOW_STATE
      }
    }
  };
  const defaultProps = {
    /**
     * Handler for form submitting
     * @param {Object} data
     * @return {void}
     */

    /* eslint-disable no-unused-vars */
    onSubmit: data => console.error('Please pass the "onSubmit" param to "dashboard/manage/delete-report" component')
  };
  /**
   * @module Components/Dashboard/Manage/DeleteReport
   * @param {Function} onSubmit - handler for submitting
   * @param {Number} id - report id
   * @param {String} name - report name
   * @example
   * {@lang xml} {{dashboard/manage/delete-report
  		onSubmit=deleteReport
  		id=report.id
  		name=report.name
  }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    mwd: Ember.inject.service('modal-window-dispatcher'),
    tagName: 'a',
    fsmModel,
    IS_DELETING_STATE: (0, _emberHelpers.isState)(_constants.DELETING_STATE),

    /**
     * Click handler for the current component.
     * Dispatches `OPEN_MODAL_ACTION`
     *
     * @function
     */
    click() {
      this.get('fsm').dispatch(_constants.OPEN_MODAL_ACTION);
    },

    /**
     * Shows modal `Delete Report` modal window
     *
     * @function
     */
    [_constants.OPEN_MODAL_ACTION]() {
      const {
        fsm,
        mwd
      } = this.getProperties('fsm', 'mwd');
      mwd.show({
        title: 'Delete Report',
        name: 'delete-business-report',
        body: this,
        onClose: () => fsm.dispatch(_constants.CLOSE_MODAL_WINDOW_ACTION),
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: () => fsm.dispatch(_constants.CLOSE_MODAL_WINDOW_ACTION)
        }, {
          caption: 'Delete Report',
          callback: () => fsm.dispatch(_constants.DELETE_ACTION, this.getProperties('id', 'name'))
        }]
      });
    },

    /**
     * Closes modal window and clears modal's messages
     *
     * @function
     */
    [_constants.CLOSE_MODAL_WINDOW_ACTION]() {
      const mwd = this.get('mwd');
      mwd.hide();
      mwd.clearMessages();
    },

    /**
     * Handler for click on `Delete Report` button
     * Assumes that `onSubmit` method returns {Promise}
     *
     * @function
     * @param {Object} data - data which will be passed as request body
     * @return {Promise}
     */
    [_constants.DELETE_ACTION](data) {
      return this.commonDispatcher(() => this.onSubmit(data));
    },

    /**
     * Handler for successful updating
     * Calls `CLOSE_MODAL_WINDOW_ACTION` directly (without fsm dispatching)
     *
     * @function
     */
    [_commonFsmState.SUCCESS_ACTION]() {
      this[_constants.CLOSE_MODAL_WINDOW_ACTION]();
    },

    /**
     * Handler for failed updating
     * Shows errors from the `results` param
     *
     * @function
     * @param {Object} results - errors from the BE
     */
    [_commonFsmState.FAILURE_ACTION](results) {
      this.get('mwd').showError((0, _myadtHelpers.genericErrorHandler)(results)[0]);
    }

  });

  _exports.default = _default;
});