define("adt-wss/templates/modal-windows/preferences/monitoring-reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iIAVQfyB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"loading-spinner\",[[24,[\"body\",\"IS_UPDATING_STATE\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"section\",true],[10,\"class\",\"comm-pref-reminders-description\"],[8],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"Sign up to receive monitoring reports\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[1,[28,\"stupid/inline-errors-messages\",null,[[\"messages\"],[[24,[\"messages\"]]]]],false],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"reminder-content\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"email\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"widget/input-wrapper\",null,[[\"selenium-id\",\"label\",\"inputId\",\"inputClass\",\"onEnter\",\"inputVal\"],[\"Recipient-email\",\"Recipient Email\",\"recipientEmail\",\"email\",[28,\"action\",[[23,0,[]],[24,[\"onEnter\"]]],null],[24,[\"body\",\"data\",\"email\"]]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"frequency\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"widget/custom-select\",null,[[\"items\",\"optValue\",\"label\",\"optionKey\",\"valueKey\",\"type\"],[[24,[\"body\",\"timeRangeOptions\"]],[28,\"mut\",[[24,[\"body\",\"frequency\"]]],null],\"Frequency\",\"text\",\"value\",\"labeled\"]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"divider\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/preferences/monitoring-reports.hbs"
    }
  });

  _exports.default = _default;
});