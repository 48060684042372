define("adt-wss/pods/dashboard/account/payments/route", ["exports", "adt-wss-common/utils/route-helpers", "adt-wss/services/app-dispatcher", "adt-wss-common/classes/status-message", "jquery"], function (_exports, _routeHelpers, _appDispatcher, _statusMessage, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.scrollToModule = void 0;

  const scrollToModule = () => {
    const go = () => {
      const scrollTo = (0, _jquery.default)('.balance-info').offset().top + (0, _jquery.default)('.balance-info').outerHeight();
      (0, _jquery.default)('html, body').animate({
        scrollTop: scrollTo
      }, 500);
    };

    if ((0, _jquery.default)('.balance-info').length) {
      go();
    } else {
      const to = setInterval(() => {
        if ((0, _jquery.default)('.balance-info').length) {
          clearInterval(to);
          go();
        }
      }, 100);
    }
  };

  _exports.scrollToModule = scrollToModule;

  var _default = Ember.Route.extend({
    currentLocation: Ember.inject.service(),
    contactData: Ember.inject.service(),
    billingData: Ember.inject.service(),
    manageEasypay: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    flexfiData: Ember.inject.service(),
    dsm: Ember.inject.service('dashboard-status-messages'),
    makeDeliverData: _routeHelpers.makeDeliverData,
    hashSettled: Ember.RSVP.hashSettled,
    useResolvedOnly: _routeHelpers.useResolvedOnly,

    model() {
      const contactDeliverData = this.makeDeliverData(this, 'contactData');
      const savedAccountsDeliverData = this.makeDeliverData(this, 'billingData');
      const manageEasyPayDeliverData = this.makeDeliverData(this, 'manageEasypay');
      const flexfiDeliverData = this.makeDeliverData(this, 'flexfiData');
      const paperlessOption = contactDeliverData('paperlessOption', contactData => contactData.getPMOC().then(r => r.paperlessOption));
      const savedAccounts = savedAccountsDeliverData('savedAccounts', billingData => billingData.getSavedAccounts());
      const easyPayDetails = manageEasyPayDeliverData('details', manageEasypay => manageEasypay.getEasyPayDetails());
      const flexfiDetails = flexfiDeliverData('data', flexfiData => flexfiData.getFlexFiDetails());
      const {
        permissions,
        profileInfo
      } = this.get('currentLocation');
      const billingModel = this.get('billingData.billingModel');
      const enrolledInEasyPay = this.get('billingData.billingModel.billingOptions.enrolledInEasyPay');
      const authInfo = this.get('authInfo.authInfo');
      return this.hashSettled({
        permissions,
        profileInfo,
        billingModel,
        savedAccounts,
        enrolledInEasyPay,
        paperlessOption,
        authInfo,
        easyPayDetails,
        flexfiDetails
      }).then(this.useResolvedOnly);
    },

    afterModel() {
      if (!this.get('currentLocation.permissions.billing')) {
        this.transitionTo('dashboard.account.profile');
      }

      if (this.get('flexfiData.missingEasyPay')) {
        const statusMessages = this.get('dsm.StatusMessages') || [];
        const msg = new _statusMessage.default({
          head: 'Set up EasyPay for your Monthly Loan Installments',
          text: 'EasyPay enrollment is required to make Monthly Loan Installments. Enroll in EasyPay to get started.'
        }, 'warn');
        msg.addButton({
          text: 'Enroll',
          route: 'dashboard.account.payments.manageeasypay'
        });
        statusMessages.push(msg);
        this.set('dsm.statusMessages', statusMessages);
      }
    },

    actions: {
      routeRefresh() {
        this.refresh();
      }

    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      this.refresh();
    },

    activate() {
      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    },

    deactivate() {
      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    }

  });

  _exports.default = _default;
});