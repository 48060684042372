define("adt-wss/pods/components/dashboard/manage/add-location/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss/pods/components/dashboard/manage/add-location/constants", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/classes/status-message", "adt-wss-common/utils/fp", "adt-wss-common/utils/ember-helpers"], function (_exports, _withFsm, constants, _myadtHelpers, _statusMessage, _fp, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    IDLE_STATE,
    ADD_STATE,
    LOADING_STATE,
    CHOOSE_LOCATION_STATE,
    SET_NAME_STATE,
    START_ACTION,
    LOCATE_ACTION,
    RESOLVE_LOCATE_ACTION,
    REJECT_LOCATE_ACTION,
    SELECT_LOCATION_ACTION,
    SUBMIT_ACTION,
    RESOLVE_SUBMIT_ACTION,
    REJECT_SUBMIT_ACTION,
    CANCEL_ACTION,
    GO_BACK_ACTION,
    REGISTRATION_STATE,
    REGISTRATION_ACTION
  } = constants;
  const fsmModel = {
    transitions: {
      [IDLE_STATE]: {
        [START_ACTION]: REGISTRATION_STATE
      },
      [REGISTRATION_STATE]: {
        [REGISTRATION_ACTION]: ADD_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      },
      [ADD_STATE]: {
        [LOCATE_ACTION]: LOADING_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      },
      [LOADING_STATE]: {
        [RESOLVE_LOCATE_ACTION]: CHOOSE_LOCATION_STATE,
        [REJECT_LOCATE_ACTION]: ADD_STATE,
        [RESOLVE_SUBMIT_ACTION]: IDLE_STATE,
        [REJECT_SUBMIT_ACTION]: SET_NAME_STATE
      },
      [CHOOSE_LOCATION_STATE]: {
        [SELECT_LOCATION_ACTION]: SET_NAME_STATE,
        [GO_BACK_ACTION]: ADD_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      },
      [SET_NAME_STATE]: {
        [SUBMIT_ACTION]: LOADING_STATE,
        [GO_BACK_ACTION]: CHOOSE_LOCATION_STATE,
        [CANCEL_ACTION]: IDLE_STATE
      }
    }
  };
  /**
   * Module to serve the link and modal for adding a location
   * at /dashboard/manage/index
   * @module Components/AddLocation
   * @prop {String} currentState
   * @prop {Computed<Boolean>} isFetching condition for loading spinner
   * @prop {RegistrationSite[]} siteList
   * @prop {RegistrationSite} selectedLocation
   * @prop {String} newLocationName value of input field
   * @prop {String} sitePhone
   * @prop {String} pic verbal security password
   * @prop {String|Int} customerNumber only used if `checkAffiliations` response requires
   * @prop {Boolean} sitePhoneError used for form field
   * @prop {Boolean} picError used for form field
   * @prop {Boolean} customerNumberError used for form field
   * @prop {Boolean} agreement assent to site usage agreement
   * @prop {Boolean} reqCustNum true if `checkAffiliations` response requires
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    classNames: ['add-location-widget'],
    mwd: Ember.inject.service('modal-window-dispatcher'),
    registrationService: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    currentState: IDLE_STATE,
    isFetching: Ember.computed.equal('currentState', LOADING_STATE),
    siteList: Ember.computed.oneWay('registrationService.siteList'),
    selectedLocation: null,
    newLocationName: '',
    sitePhone: '',
    pic: '',
    customerNumber: null,
    sitePhoneError: false,
    picError: false,
    customerNumberError: false,
    agreement: false,
    reqCustNum: false,
    fsmModel,

    [REGISTRATION_ACTION](registrationType) {
      if (registrationType) {
        this.set(registrationType, true);
      }
    },

    /**
     * Opens modal and begins Add Location
     */
    [START_ACTION]() {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);

      const onClose = () => {
        this.get('fsm').dispatch(CANCEL_ACTION);
        mwd.hide();
      };
      /**
       * Uses a switch statement to check `currentState`
       * and act accordingly on "Next" button click
       */


      const onEnter = () => {
        mwd.clearMessages();
        const currentState = this.get('currentState');

        switch (currentState) {
          case ADD_STATE:
            {
              const validation = this.validateLocateAction();

              if (!validation.valid) {
                this.setProperties({
                  sitePhoneError: validation.sitePhoneError,
                  customerNumberError: validation.customerNumberError,
                  picError: validation.picError
                });
                showErrors(validation);
                return false;
              }

              return fsm.dispatch(LOCATE_ACTION).then(data => fsm.dispatch(RESOLVE_LOCATE_ACTION, data)).catch(results => showErrors(results, REJECT_LOCATE_ACTION));
            }

          case CHOOSE_LOCATION_STATE:
            {
              if (!this.get('siteList').some(s => s.isSelected)) {
                mwd.showError('Please choose a location', false);
                return false;
              }

              return fsm.dispatch(SELECT_LOCATION_ACTION);
            }

          case SET_NAME_STATE:
            {
              return fsm.dispatch(SUBMIT_ACTION).then(data => fsm.dispatch(RESOLVE_SUBMIT_ACTION, data)).catch(results => showErrors(results, REJECT_SUBMIT_ACTION));
            }
        }
      };

      this.resetAll();
      const title = 'Add Location';
      const body = this;
      mwd.show({
        name: 'add-new-location',
        title,
        body,
        isFooterLine: false,
        onClose,
        onEnter,
        statusMessages: [],
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          classes: (0, _emberHelpers.computedApplyFunction)(curState => curState === REGISTRATION_STATE ? 'button-no-border' : '', 'currentState'),
          callback: onClose
        }, {
          caption: (0, _emberHelpers.computedApplyFunction)(curState => curState === SET_NAME_STATE ? 'Add Location' : 'Next', 'currentState'),
          classes: (0, _emberHelpers.computedApplyFunction)(curState => curState === REGISTRATION_STATE ? 'hide-desktop hide-tablet hide-mobile' : '', 'currentState'),
          callback: onEnter
        }]
      });
    },

    /**
     * Pass-through to {@link Classes/StateMachine.dispatch}
     * @param {String} action
     * @param {*} params
     */
    dispatchAction(comp, action, params) {
      comp.get('fsm').dispatch(action, params);
    },

    /**
     * Calls {@link Services/RegistrationService.checkAffiliations} to get list
     * of eligible sites
     * Requires site phone number, security password, and usage agreement
     */
    [LOCATE_ACTION]() {
      const {
        sitePhone,
        pic,
        agreement,
        customerNumber,
        adtGo,
        cyberSecurity
      } = this.getProperties('sitePhone', 'pic', 'agreement', 'customerNumber', 'adtGo', 'cyberSecurity');
      const params = {
        sitePhone: sitePhone.trim(),
        securityPassword: pic.trim(),
        siteUsageChecked: agreement,
        customerNumber: (customerNumber || '').trim(),
        adtGo,
        cyberSecurity
      };
      let errors = [];
      return this.get('registrationService').checkAffiliations(params).then(data => {
        data.siteRegistrations.forEach(site => {
          if (Ember.get(site, 'recoverableRegistrationErrors.length')) {
            _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Add Location', 'Prompted For Billing Account', site.recoverableRegistrationErrors[0]]);
          }

          if (site.unrecoverableRegistrationErrors.length) {
            site.unrecoverableRegistrationErrors.forEach(error => {
              if (!errors.some(e => e === error)) errors.push(error);
            });
          }
        });
        const hasEligibleSites = data.siteRegistrations.some(s => !s.unrecoverableRegistrationErrors.length);

        if (!hasEligibleSites) {
          if (!errors.length) errors = ['No eligible sites were found.'];
          return Ember.RSVP.Promise.reject({
            success: false,
            errors
          });
        }

        return data;
      });
    },

    /**
     * Sets `reqCustNum` true if `checkAffiliations` response requires
     */
    [REJECT_LOCATE_ACTION](results) {
      if ((results.errors || []).includes('Registration.customerNumberNeededVisitMyAdtcom')) {
        this.set('reqCustNum', true);
        this.set('customerNumberError', true);
      }
    },

    /**
     * Submits new location to {@link Services/RegistrationService.addLocation}
     * Requires `selectedLocation`, `newLocationName`, `sitePhone`, and `pic`
     * to be set
     */
    [SUBMIT_ACTION]() {
      const {
        newLocationName,
        sitePhone,
        pic
      } = this.getProperties('newLocationName', 'sitePhone', 'pic');
      const selectedLocations = this.set('selectedLocation', this.get('siteList').filter(s => s.isSelected));
      selectedLocations.forEach(selectedLocation => {
        selectedLocation.address.locationName = newLocationName;
        selectedLocation.webAddress.locationName = newLocationName;
        selectedLocation.shouldRegister = true;
      });
      return this.get('registrationService').addLocation({
        sitePhone,
        securityPassword: pic,
        siteUsageChecked: true,
        siteRegistrations: selectedLocations.map(s => (0, _fp.omit)(s, 'isSelected'))
      });
    },

    /**
     * Changes to new location on resolve
     * @param {Object} data
     * @param {Int} data.success new location's webAddressId
     */
    [RESOLVE_SUBMIT_ACTION](data) {
      const {
        newLocationName
      } = this.getProperties('newLocationName');
      const selectedLocations = this.set('selectedLocation', this.get('siteList').filter(s => s.isSelected));
      const selectedLocationsUpdated = selectedLocations.map(s => (0, _fp.omit)(s, 'isSelected'));
      let msg = '';
      selectedLocationsUpdated.forEach(selectedLocation => {
        const name = newLocationName || selectedLocation.address.addressLine1;

        if (selectedLocationsUpdated.length === 2) {
          msg = 'You have successfully added the locations to your account.';
        } else {
          msg = "You successfully added '".concat(name, "' to your account.");
        }
      });
      this.set('statusMessages', [new _statusMessage.default(msg, 'success', 3000)]);
      this.get('mwd').hide();
      this.refreshLocations(data.success);
    },

    /**
     * Validates phone number, password, and usage agreement
     * if `checkAffiliations` returns error requiring customer number,
     * validates that as well
     */
    validateLocateAction() {
      // eslint-disable-next-line prefer-const
      let {
        sitePhone,
        pic,
        agreement,
        customerNumber
      } = this.getProperties('sitePhone', 'pic', 'agreement', 'customerNumber');
      const fe = {
        errors: [],
        valid: true,
        sitePhoneError: false,
        picError: false,
        customerNumberError: false
      };
      this.setProperties({
        sitePhoneError: false,
        picError: false,
        errorMessages: null
      });
      sitePhone = sitePhone.trim();
      pic = pic.trim();

      if (!sitePhone || !pic) {
        if (!sitePhone) fe.sitePhoneError = true;
        if (!pic) fe.picError = true;
        fe.errors.push('General.errorAllFieldsRequired');
      } else {
        if (this.get('reqCustNum') && !customerNumber) {
          fe.errors.push('General.errorAllFieldsRequired');
          fe.customerNumberError = true;
        }

        if (sitePhone && !_myadtHelpers.default.validatePhoneNumber(sitePhone)) {
          fe.errors.push('Contacts.invalidPhoneNumber');
          fe.sitePhoneError = true;
        }

        if (pic && (pic.length > 10 || pic.length < 3)) {
          fe.errors.push('CommonPassword.errorPasswordLength');
          fe.picError = true;
        }

        if (!agreement) {
          fe.errors.push('CreateBillingAccountForm.errorSiteUsageNotChecked');
          fe.valid = false;
        }

        if (fe.errors.length) {
          fe.valid = false;
        }
      }

      return fe;
    },

    /**
     * Validates phone numbers using {@link Utils/MyADTHelpers.validatePhoneNumber}
     * @param {Int|String} phone
     * @return {Boolean}
     */
    validatePhone(phone) {
      let ret = true;
      if (!phone) ret = false;

      if (phone && !_myadtHelpers.default.validatePhoneNumber(phone)) {
        this.set('errorMessages', [this.l('Contacts.invalidPhoneNumber')]);
        ret = false;
      }

      this.set('sitePhoneError', !ret);
      return ret;
    },

    /**
     * Makes sure pic is 3-10 characters
     * @param {Int|String} phone
     * @return {Boolean}
     */
    validatePic(pic) {
      let ret = true;

      if (!pic || pic.length > 10 || pic.length < 3) {
        ret = false;
      }

      this.set('picError', !ret);
      return ret;
    },

    click() {
      this.get('fsm').dispatch(START_ACTION);
    },

    /**
     * Resets form values
     */
    resetAll() {
      this.setProperties({
        selectedLocation: null,
        newLocationName: '',
        sitePhone: '',
        pic: '',
        customerNumber: null,
        sitePhoneError: false,
        picError: false,
        customerNumberError: false,
        agreement: false,
        reqCustNum: false,
        adtGo: false,
        cyberSecurity: false
      });
    }

  });

  _exports.default = _default;
});