define("adt-wss/pods/dashboard/account/payments/index/controller", ["exports", "adt-wss/pods/dashboard/account/payments/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    currentBillingTab: 'billingHistory',
    lastBillingTab: 'billingStatements',
    showingModule: Ember.computed('currentBillingTab', function () {
      return /module/i.test(this.get('currentBillingTab'));
    }),

    showBillingHistory() {
      this.set('currentBillingTab', 'billingHistory');
    },

    showBillingStatements() {
      this.set('currentBillingTab', 'billingStatements');
    },

    reset() {
      this.setProperties({
        currentBillingTab: 'billingStatements',
        lastBillingTab: 'billingStatements'
      });
    }

  });

  _exports.default = _default;
});