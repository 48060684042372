define("adt-wss/pods/index/resetpassword/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/r+wAdPx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"resetPassword\"],[8],[1,[22,\"myadt-login/forgot-password\"],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/index/resetpassword/template.hbs"
    }
  });

  _exports.default = _default;
});