define("adt-wss/pods/components/stupid/button-component/component", ["exports", "adt-wss-common/mixins/with-fsm"], function (_exports, _withFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.defaultProperties = void 0;
  const defaultProperties = {
    color: 'blue'
  };
  /**
   * @module Components/Stupid/ButtonComponent
   * @property {String} caption - text on button
   * @property {String} color="blue" - background or border color of button
   * @property {String} stl="fill" - style os button: fill | outline | text
   * @property {String<ComputedProperty>} buttonStyle - `button-${color}?-${stl}`
   * @example
   * {@lang xml} <div class="button">{{stupid/button-component caption="clickMe" color="green" stl="outline"}}</div>
   */

  _exports.defaultProperties = defaultProperties;

  var _default = Ember.Component.extend(_withFsm.default, defaultProperties, {
    classNames: ['button'],
    tagName: 'button',
    classNameBindings: ['buttonStyle', 'size'],
    attributeBindings: ['disabled'],
    buttonStyle: Ember.computed('color', 'stl', function () {
      const color = this.get('color') || defaultProperties.color;
      const stl = this.get('stl');
      return "button-".concat(color).concat(stl ? '-' + stl : '');
    }),

    click() {
      (this.get('onClick') || (() => 1))(...arguments);
    }

  });

  _exports.default = _default;
});