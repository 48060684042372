define("adt-wss/services/flexfi-data", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers"], function (_exports, _myadtHelpers, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * FlexFi Service Module
   * @module Services/FlexFi
   *
   * @prop {FlexFiFinancierDetails} financierData
   * @prop {FlexFiInstallmentDetails} installmentData
   */
  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)({
    data: null
  }), {
    apiService: Ember.inject.service(),
    financierData: null,
    installmentData: null,
    data: null,
    sendAnalyticEvent: _myadtHelpers.sendAnalyticEvent,
    missingEasyPay: (0, _emberHelpers.computedApplyFunction)(instData => instData.length && (instData || []).filter(i => !i.autoPayDetails).length, 'installmentData'),
    noEasyPay: (0, _emberHelpers.computedApplyFunction)(instData => instData.length && !(instData || []).filter(i => i.autoPayDetails).length, 'installmentData'),

    /**
     * Get FlexFi financier details from API and sets `financierData`
     * @return {Promise}
     */
    getFlexFiFinancierDetails() {
      return this.get('apiService').myADT.getFlexFiFinancierDetails().then(({
        data
      }) => this.set('financierData', data));
    },

    /**
     * Get FlexFi financier details from API and sets `installmentData`
     * @return {Promise}
     */
    getFlexFiInstallmentDetails() {
      return this.get('apiService').myADT.getFlexFiInstallmentDetails().then(({
        data
      }) => {
        const out = (data.flexFiInstallmentInfoList || []).map(x => {
          const i = {};
          Object.keys(x).forEach(k => {
            i[k] = x[k];
          });
          i.lastPaymentDate = x.latestPaymentDate;
          i.lastPaymentDateObj = Date.createDate(i.lastPaymentDate).setMidnight();
          i.finalPaymentDateObj = Date.createDate(x.finalPaymentDate).setMidnight();
          return i;
        });
        return this.set('installmentData', out);
      });
    },

    /**
     * Get both financier and installment details from API
     * @return {Promise}
     */
    getFlexFiDetails() {
      const out = {};
      return this.getFlexFiFinancierDetails().then(data => {
        out.financierData = data;
        return this.getFlexFiInstallmentDetails();
      }).then(data => {
        out.installmentData = data;
        return this.set('data', out);
      });
    }

  });

  _exports.default = _default;
});