define("adt-wss/pods/components/scrollable-tabs/component", ["exports", "adt-wss-common/utils/ember-helpers", "jquery"], function (_exports, _emberHelpers, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.sum = _exports.calcRight = _exports.calcLeft = _exports.constOffset = void 0;
  const constOffset = 8;
  _exports.constOffset = constOffset;

  const calcLeft = (tabsLength, tabsOffset, tabsWidth) => tabsLength > tabsWidth && Math.abs(tabsOffset) > constOffset;

  _exports.calcLeft = calcLeft;

  const calcRight = (tabsLength, tabsOffset, tabsWidth) => tabsLength > tabsWidth && Math.abs(tabsLength + (tabsOffset - constOffset - tabsWidth)) > constOffset;

  _exports.calcRight = calcRight;

  const sum = arr => arr.reduce((acc, value) => acc + value, 0);

  _exports.sum = sum;

  var _default = Ember.Component.extend({
    classNames: ['scrollable-tabs'],
    isLeft: (0, _emberHelpers.computedApplyFunction)(calcLeft, 'tabsLength', 'tabsOffset', 'tabsWidth'),
    isRight: (0, _emberHelpers.computedApplyFunction)(calcRight, 'tabsLength', 'tabsOffset', 'tabsWidth'),
    tabsWidth: 0,
    tabsLength: 0,
    tabsOffset: 0,

    update() {
      const tabsWidth = (0, _jquery.default)('.nav-tabs').width();
      const tabsLength = sum((0, _jquery.default)('.nav-tabs li').toArray().map(e => (0, _jquery.default)(e).width()));
      const tabsOffset = (0, _jquery.default)('.nav-tabs').offset().left;
      this.setProperties({
        tabsLength,
        tabsOffset,
        tabsWidth
      });
    },

    didRender() {
      this._super(...arguments);

      this.update();
    },

    actions: {
      scroll(dir) {
        const {
          tabsWidth,
          tabsOffset
        } = this.getProperties('tabsWidth', 'tabsOffset');
        const to = Math.abs(tabsOffset);
        const newOffset = dir === 'left' ? to - tabsWidth : to + tabsWidth;
        (0, _jquery.default)('.section-tab-nav').scrollLeft(newOffset);
        this.update();
      }

    }
  });

  _exports.default = _default;
});