define("adt-wss/pods/components/dashboard/manage/add-location/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VACZU0JI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[10,\"alt\",\"plus\"],[11,\"src\",[29,[[22,\"rootURL\"],\"images/v2/icons/plus-slim.svg\"]]],[8],[9],[0,\"\\n\"],[7,\"h2\",true],[8],[0,\"Add\"],[7,\"span\",true],[10,\"class\",\"hide-mobile\"],[8],[0,\" Location\"],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/dashboard/manage/add-location/template.hbs"
    }
  });

  _exports.default = _default;
});