define("adt-wss/pods/components/myadt-login/register/component", ["exports", "adt-wss-common/utils/localization", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/fp", "adt-wss/pods/components/myadt-login/register/constants", "adt-wss-common/mixins/with-fsm"], function (_exports, _localization, _myadtHelpers, _fp, constants, _withFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.questions = void 0;
  const questions = [{
    value: null,
    text: 'Choose a Security Question'
  }, {
    value: 17,
    text: 'Who was your childhood best friend?'
  }, {
    value: 18,
    text: 'Where did you meet your significant other?'
  }, {
    value: 19,
    text: 'What phone number do you most remember?'
  }, {
    value: 20,
    text: 'What is your maternal grandmother\'s maiden name?'
  }, {
    value: 21,
    text: 'What is your paternal grandmother\'s maiden name?'
  }, {
    value: 22,
    text: 'What was your first job or company?'
  }, {
    value: 23,
    text: 'What is your 3rd grade or favorite teacher\'s name?'
  }, {
    value: 24,
    text: 'What is your oldest cousin\'s name?'
  }, {
    value: 25,
    text: 'What was your childhood nickname?'
  }, {
    value: 26,
    text: 'Where did your parents meet?'
  }, {
    value: 28,
    text: 'Where did you have your first kiss?'
  }, {
    value: 29,
    text: 'Who did you share your first kiss with?'
  }, {
    value: 30,
    text: 'Where was your wedding reception held?'
  }, {
    value: 31,
    text: 'What was your favorite place to visit as a child?'
  }, {
    value: 32,
    text: 'Where was your mother-/father-in-law born?'
  }, {
    value: 34,
    text: 'What was the first concert you attended?'
  }, {
    value: 36,
    text: 'Where do you want to retire?'
  }, {
    value: 37,
    text: 'Where was your favorite vacation?'
  }];
  _exports.questions = questions;
  const {
    IDLE_STATE,
    FETCHING_ACCOUNT_STATE,
    CHOOSE_LOCATION_STATE,
    DETAILS_STATE,
    SUBMITTING_STATE,
    VERIFY_STATE,
    COMPLETED_STATE,
    LOCATE_ACTION,
    INIT_PREMIUM_ACTION,
    RESOLVE_LOCATE_ACTION,
    REJECT_LOCATE_ACTION,
    SELECT_LOCATION_ACTION,
    SUBMIT_ACTION,
    RESOLVE_SUBMIT_ACTION,
    REJECT_SUBMIT_ACTION,
    CONFIRM_EMAIL_ACTION,
    RETURN_TO_LOGIN_ACTION,
    RETURN_TO_REGISTRATION_ACTION,
    GO_BACK_ACTION,
    GO_TO_VERIFY_ACTION,
    LOGGING_IN_STATE,
    REJECT_AUTH_ACTION
  } = constants;
  const fsmModel = {
    transitions: {
      [IDLE_STATE]: {
        [LOCATE_ACTION]: FETCHING_ACCOUNT_STATE,
        [RETURN_TO_LOGIN_ACTION]: IDLE_STATE,
        [RETURN_TO_REGISTRATION_ACTION]: IDLE_STATE,
        [INIT_PREMIUM_ACTION]: DETAILS_STATE
      },
      [FETCHING_ACCOUNT_STATE]: {
        [RESOLVE_LOCATE_ACTION]: CHOOSE_LOCATION_STATE,
        [REJECT_LOCATE_ACTION]: IDLE_STATE
      },
      [CHOOSE_LOCATION_STATE]: {
        [SELECT_LOCATION_ACTION]: DETAILS_STATE,
        [GO_BACK_ACTION]: IDLE_STATE,
        [RETURN_TO_LOGIN_ACTION]: IDLE_STATE
      },
      [DETAILS_STATE]: {
        [SUBMIT_ACTION]: SUBMITTING_STATE,
        [GO_BACK_ACTION]: CHOOSE_LOCATION_STATE,
        [RETURN_TO_LOGIN_ACTION]: IDLE_STATE,
        [RETURN_TO_REGISTRATION_ACTION]: IDLE_STATE
      },
      [SUBMITTING_STATE]: {
        [GO_TO_VERIFY_ACTION]: VERIFY_STATE,
        [RESOLVE_SUBMIT_ACTION]: LOGGING_IN_STATE,
        [REJECT_SUBMIT_ACTION]: DETAILS_STATE
      },
      [VERIFY_STATE]: {
        [CONFIRM_EMAIL_ACTION]: SUBMITTING_STATE,
        [GO_BACK_ACTION]: DETAILS_STATE,
        [RETURN_TO_LOGIN_ACTION]: IDLE_STATE
      },
      [LOGGING_IN_STATE]: {
        [REJECT_AUTH_ACTION]: IDLE_STATE
      },
      [COMPLETED_STATE]: {
        [RETURN_TO_LOGIN_ACTION]: IDLE_STATE
      }
    }
  };
  /**
   * Account Registration Component
   * @module Components/MyADTLogin/Registration
   * @prop {String} sitePhone primary phone number, used for location lookup
   * @prop {String} pic Verbal Security Password / Personal Identification Code
   * @prop {String} firstName
   * @prop {String} lastName
   * @prop {String} emailAddress if `isPremiumRegistration`, this is disabled, and the value is received from query string
   * @prop {String} confirmEmailAddress
   * @prop {String} password
   * @prop {String} confirmPassword
   * @prop {Int|String} customerNumber displayed as 'account number',
   * @prop {Int|String} question1 Security question id
   * @prop {String} secretAnswer1
   * @prop {Boolean} siteUsageChecked terms and conditions acceptance
   * @prop {Boolean} modalView - located in modal window
   * @prop {Function} onClose
   * @prop {String} uuid GUID generated for email; skips steps 1 and 2
   * @prop {Computed<Boolean>} isPremiumRegistration indicates registration from email link, based on presence of `uuid`, skips to 'DETAILS_STATE' state
   * @prop {Object[]} questions options for the question1 select element
   *
   * @prop {String[]} errorMessages
   * @prop {Boolean} sitePhoneError toggles error state on form field
   * @prop {Boolean} picError toggles error state on form field
   * @prop {Object} detailsErrors props toggle error state on form fields
   * @prop {Boolean} detailsErrors.firstName
   * @prop {Boolean} detailsErrors.lastName
   * @prop {Boolean} detailsErrors.emailAddress
   * @prop {Boolean} detailsErrors.confirmEmailAddress
   * @prop {Boolean} detailsErrors.password
   * @prop {Boolean} detailsErrors.confirmPassword
   * @prop {Boolean} detailsErrors.question
   * @prop {Boolean} detailsErrors.answer
   * @prop {Boolean} hideBackBtn
   *
   * @prop {Computed<RegistrationSite[]>} siteList
   * @prop {Computed<Boolean>} isFetching returns true if fetching from API
   * @prop {String} parentState mutable string passed from parent component, allows `RETURN_TO_LOGIN_ACTION` to work by altering state of login component
   */

  var _default = Ember.Component.extend(_withFsm.default, {
    classNames: ['register-form'],
    registrationService: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    ssoInfo: Ember.inject.service(),
    fsmModel,
    currentState: IDLE_STATE,
    chosenSite: null,
    sitePhone: '',
    pic: '',
    customerNumber: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    confirmEmailAddress: '',
    password: '',
    confirmPassword: '',
    question1: questions[0],
    secretAnswer1: '',
    uuid: null,
    siteUsageChecked: false,
    isPremiumRegistration: Ember.computed.bool('uuid'),
    errorMessages: null,
    modalView: false,
    onClose: null,
    sitePhoneError: false,
    picError: false,
    customerNumberError: false,
    reqCustNum: false,
    invalidPassword: false,
    isSsoEnabled: false,
    questions,
    siteList: Ember.computed.readOnly('registrationService.siteList'),
    isFetching: Ember.computed('currentState', function () {
      const state = this.get('currentState');
      return /ing/i.test(state);
    }),

    /**
     * Creates data object for API submissions
     * Trims string/number fields, except passwords (already validated)
     * @return {Object}
     */
    prepareData() {
      const {
        sitePhone,
        pic,
        firstName,
        lastName,
        emailAddress,
        confirmEmailAddress,
        password,
        confirmPassword,
        question1,
        secretAnswer1,
        uuid
      } = this.getProperties('sitePhone', 'pic', 'firstName', 'lastName', 'emailAddress', 'confirmEmailAddress', 'password', 'confirmPassword', 'question1', 'secretAnswer1', 'uuid');
      let chosenSite = this.get('chosenSite');

      if (chosenSite) {
        ['isEligible', 'canView'].forEach(key => {
          chosenSite = _fp.default.omit(chosenSite, key);
        });
      }

      return {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        sitePhone: (0, _myadtHelpers.removePhoneMask)(sitePhone),
        emailAddress: emailAddress.trim(),
        confirmEmailAddress: confirmEmailAddress.trim(),
        securityPassword: pic.trim(),
        password,
        confirmPassword,
        question1: this.get('isSsoEnabled') ? question1.text.toString() : question1.value.toString(),
        secretAnswer1: secretAnswer1.trim(),
        siteUsageChecked: true,
        siteRegistrations: chosenSite ? [chosenSite] : null,
        uuid
      };
    },

    /**
     * Method to require user to submit customer number
     * in order to disambiguate account
     */
    requireCustNum() {
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Registration', 'Cust_no requested']);
      this.set('reqCustNum', true);
    },

    /**
     * Method to get security questions if SSO is enabled
     */
    getSsoSecurityQuestions() {
      return this.get('ssoInfo').getIsSsoEnabled().then(data => {
        if (data) {
          this.set('isSsoEnabled', data);
          return this.get('ssoInfo').getSsoSecurityQuestions().then(securityQuestions => {
            this.set('questions', securityQuestions);
          });
        }
      });
    },

    /**
     * Invokes `registrationService.checkAffiliations`.
     * Validation included
     * dispatches `RESOLVE_LOCATE_ACTION` or `REJECT_LOCATE_ACTION`
     * TODO: separate validation and implement like {@link Components/AddLocation.validateLocateAction}
     */
    [LOCATE_ACTION]() {
      // eslint-disable-next-line prefer-const
      this.getSsoSecurityQuestions();
      let {
        sitePhone,
        pic,
        customerNumber,
        siteUsageChecked
      } = this.getProperties('sitePhone', 'pic', 'customerNumber', 'siteUsageChecked');
      let errors = [];
      this.setProperties({
        sitePhoneError: false,
        picError: false,
        errorMessages: null
      });
      sitePhone = sitePhone.trim();
      pic = pic.trim();

      if (!sitePhone || !pic) {
        if (!sitePhone) this.set('sitePhoneError', true);
        if (!pic) this.set('picError', true);
        return this.get('fsm').dispatch(REJECT_LOCATE_ACTION, {
          errors: ['General.errorAllFieldsRequired']
        });
      }

      if (this.get('reqCustNum') && !customerNumber) {
        this.set('customerNumberError', true);
        return this.get('fsm').dispatch(REJECT_LOCATE_ACTION, {
          errors: ['General.errorAllFieldsRequired']
        });
      }

      if (customerNumber && !(0, _myadtHelpers.validateCustomerNumber)(customerNumber)) {
        errors.push('Contacts.invalidCustomerNumber');
        this.set('customerNumberError', true);
      }

      if (sitePhone && !this.validateRegisterPhoneNum(sitePhone)) {
        errors.push('Contacts.invalidPhoneNumber');
        this.set('sitePhoneError', true);
      }

      if (pic && (pic.length > 10 || pic.length < 3)) {
        errors.push('CommonPassword.errorPasswordLength');
        this.set('picError', true);
      }

      if (errors.length) {
        return this.get('fsm').dispatch(REJECT_LOCATE_ACTION, {
          errors
        });
      }

      return this.get('registrationService').checkAffiliations({
        sitePhone,
        siteUsageChecked,
        securityPassword: pic,
        customerNumber
      }).then(data => {
        data.siteRegistrations.forEach(site => {
          if (_fp.default.get(site, 'recoverableRegistrationErrors.length')) {
            (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Registration', 'Prompted For Billing Account', site.recoverableRegistrationErrors[0]]);
          }

          if (site.unrecoverableRegistrationErrors.length) {
            site.unrecoverableRegistrationErrors.forEach(error => {
              if (!errors.some(e => e === error)) errors.push(error);
            });
          }
        });
        const hasEligibleSites = data.siteRegistrations.some(s => !s.unrecoverableRegistrationErrors.length);

        if (!hasEligibleSites) {
          if (!errors.length) errors = ['No eligible sites were found.'];
          return this.get('fsm').dispatch(REJECT_LOCATE_ACTION, {
            errors
          });
        }

        return this.get('fsm').dispatch(RESOLVE_LOCATE_ACTION, data);
      }).catch(results => {
        if ((results.errors || []).includes('Registration.customerNumberNeededVisitMyAdtcom')) {
          this.requireCustNum();
        }

        results.errors.forEach(error => (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Regsistration', error]));
        return this.get('fsm').dispatch(REJECT_LOCATE_ACTION, results);
      });
    },

    /**
     * Advances after locations are retrieved.
     * If only one location, dispatches `SELECT_LOCATION_ACTION`.
     * @param {Object} data
     */
    [RESOLVE_LOCATE_ACTION](data) {
      if (data.accountNumber) this.set('customerNumber', data.accountNumber);

      if (data.siteRegistrations.length === 1) {
        return this.get('fsm').dispatch(SELECT_LOCATION_ACTION, data.siteRegistrations[0]);
      }

      return false;
    },

    /**
     * Sets `chosenSite`.
     * @param {RegistrationSite} site
     */
    [SELECT_LOCATION_ACTION](site) {
      site.shouldRegister = true;
      this.set('chosenSite', site);
    },

    /**
     * Returns to previous step
     */
    [GO_BACK_ACTION]() {
      if (this.get('currentState') === CHOOSE_LOCATION_STATE && this.get('siteList').length === 1) {
        this.set('errorMessages', null);
        this.get('fsm').dispatch(GO_BACK_ACTION);
      }
    },

    /**
     * Starts registration.
     * If `isPremiumRegistration` immediately invokes {@link Services/RegistrationService.registerAddUser}
     * and dispatches `RESOLVE_SUBMIT_ACTION` or `REJECT_SUBMIT_ACTION`,
     * else steps to state `VERIFY_STATE`.
     * If form is invalid, dispatches `REJECT_SUBMIT_ACTION`.
     */
    [SUBMIT_ACTION]() {
      const validation = this.validateForm();

      if (validation.isValid) {
        this.get('fsm').dispatch(GO_TO_VERIFY_ACTION);
      } else {
        this.get('fsm').dispatch(REJECT_SUBMIT_ACTION, validation);
      }
    },

    /**
     * Triggered by customer interaction in `VERIFY_STATE` state.
     * Invokes {@link Services/RegistrationService.registerUser}
     * and dispatches `RESOLVE_SUBMIT_ACTION` or `REJECT_SUBMIT_ACTION`.
     * If form is invalid, dispatches `REJECT_SUBMIT_ACTION`.
     */
    [CONFIRM_EMAIL_ACTION]() {
      const apiMethod = this.get('isPremiumRegistration') ? 'registerAddUser' : 'registerUser';
      this.get('registrationService')[apiMethod](this.prepareData()).then(data => this.get('fsm').dispatch(RESOLVE_SUBMIT_ACTION, data)).catch(results => {
        if (!results.errors || results.errors.length === 0) {
          (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Registration', 'Unexpected Error Message Shown']);
        } else {
          results.errors.forEach(error => (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Regsistration', error]));
        }

        this.get('fsm').dispatch(REJECT_SUBMIT_ACTION, results);
      });
    },

    /**
     * Attemtps log in with `newReg: true` set in the options param.
     * Dispatches `rejectAuth` on failure.
     */
    [RESOLVE_SUBMIT_ACTION]({
      loginCredentials,
      registrationDetail
    }) {
      const userId = loginCredentials.userId || registrationDetail.emailAddress;
      const password = loginCredentials.password || registrationDetail.password;

      if (!userId || !password) {
        return this.RETURN_TO_LOGIN_ACTION();
      }

      this.get('session').authenticate('authenticator:myadt', userId, password, {
        newReg: true
      }).then(() => {
        this.set('errorMessages', null);
        this.get('router').transitionTo('dashboard');
      }).catch(results => {
        this.get('fsm').dispatch(REJECT_AUTH_ACTION, results);
      });
    },

    /**
     * Pass-through, riggers `handleErrors`
     * @param {ApiResponse} results
     */
    [REJECT_LOCATE_ACTION](results) {
      this.handleErrors(results);
    },

    /**
     * Pass-through, riggers `handleErrors`
     * @param {ApiResponse} results
     */
    [REJECT_SUBMIT_ACTION](results) {
      this.handleErrors(results);
    },

    /**
     * Pass-through, riggers `handleErrors`
     * @param {ApiResponse} results
     */
    [REJECT_AUTH_ACTION](results) {
      this.handleErrors(results);
    },

    /**
     * Returns to login state.
     * Redirects to 'index' route if no parent component's
     * state is available
     */
    [RETURN_TO_LOGIN_ACTION]() {
      this.setProperties({
        sitePhoneError: false,
        picError: false,
        errorMessages: null
      });

      if (this.get('parentState')) {
        this.set('parentState', 'idle');
      } else {
        if (this.get('modalView')) {
          return this.get('onClose')();
        }

        this.get('router').transitionTo('index');
      }
    },

    /**
     * Returns to registration state.
     */
    [RETURN_TO_REGISTRATION_ACTION]() {
      if (this.get('currentState') === IDLE_STATE) {
        this.setProperties({
          pic: '',
          sitePhone: '',
          customerNumber: '',
          sitePhoneError: false,
          picError: false,
          errorMessages: null,
          siteUsageChecked: false
        });
      }
    },

    /**
     * Validates the password field
     * `hideErrors` allows for other validation to complete instead of
     * setting `errorMessages` directly
     * @param {Boolean} hideErrors suppresses error generation
     * @returns {Object}
     * @example {
     * 	valid: false,
     * 	error: 'Passwords entered do not match',
     * 	fieldErrors: { password: false, confirmPassword: true }
     * }
     */
    validatePassword(hideErrors) {
      const {
        password,
        confirmPassword,
        invalidPassword,
        emailAddress
      } = this.getProperties('password', 'confirmPassword', 'invalidPassword', 'emailAddress');
      const fieldErrors = {
        password: false,
        confirmPassword: false
      };
      this.set('detailErrors.password', false);
      this.set('detailErrors.confirmPassword', false);

      if (invalidPassword || password === emailAddress) {
        this.set('detailErrors.password', true);
        fieldErrors.password = true;

        if (!hideErrors) {
          this.set('errorMessages', [(0, _localization.toExpandedString)('Registration.errorInvalidPassword')]);
        }

        return {
          valid: false,
          error: (0, _localization.toExpandedString)('Registration.errorInvalidPassword'),
          fieldErrors
        };
      }

      if (!confirmPassword || password !== confirmPassword) {
        this.set('detailErrors.confirmPassword', true);
        fieldErrors.confirmPassword = true;

        if (!hideErrors) {
          this.set('errorMessages', [(0, _localization.toExpandedString)('PasswordReset.passwordNotMatching')]);
        }

        return {
          valid: false,
          error: (0, _localization.toExpandedString)('PasswordReset.passwordNotMatching'),
          fieldErrors
        };
      }

      return {
        valid: true,
        error: null,
        fieldErrors
      };
    },

    /**
     * Validates the registration form and returns error info
     * @returns {Object}
     * @example {
     * 	valid: false,
     * 	errors: ['All fields are required.'],
     * 	fieldErrors: {
     * 		firstName: false,
     * 		lastName: true,
     * 		emailAddress: false,
     * 		confirmEmailAddress: false,
     * 		password: false,
     * 		confirmPassword: false,
     * 		question1: false,
     * 		answer1: false
     * 	}
     * }
     */
    validateForm() {
      const data = this.getProperties('firstName', 'lastName', 'emailAddress', 'confirmEmailAddress', 'password', 'confirmPassword', 'question1', 'secretAnswer1', 'siteUsageChecked');

      const details = _fp.default.omix(data, {
        question1: data.question1.value
      });

      const fieldErrors = {
        firstName: false,
        lastName: false,
        emailAddress: false,
        confirmEmailAddress: false,
        password: false,
        confirmPassword: false,
        question1: false,
        answer1: false
      };
      const errors = [];
      let isValid = true;
      let missingInfo = false;
      let siteUsageOnly = true;
      this.set('errorMessages', []);
      this.set('detailErrors', []); // Checks for empty field values

      Object.keys(details).forEach(fieldName => {
        if (Ember.isEmpty(details[fieldName])) {
          missingInfo = true;
          fieldErrors[fieldName] = true; // Different message if the only empty field
          // is the usage agreement checkbox

          if (fieldName !== 'siteUsageChecked') {
            siteUsageOnly = false;
          }
        }
      }); // if any fields are left blank, this is the only message that can display

      if (missingInfo) {
        this.set('detailErrors', fieldErrors);
        isValid = false; // if the only field empty is the usage agreement, a different message displays

        if (siteUsageOnly) {
          return {
            isValid,
            fieldErrors,
            errors: [(0, _localization.toExpandedString)('CreateBillingAccountForm.errorSiteUsageNotChecked')]
          };
        }

        return {
          isValid,
          fieldErrors,
          errors: [(0, _localization.toExpandedString)('General.errorAllFieldsRequired')]
        };
      }

      if (details.emailAddress && !details.emailAddress.isValidEmail()) {
        fieldErrors.emailAddress = true;
        isValid = false;
        errors.push('Registration.errorInvalidEmailAddress');
      }

      if (details.emailAddress && details.confirmEmailAddress && details.confirmEmailAddress.toLowerCase() !== details.emailAddress.toLowerCase()) {
        fieldErrors.emailAddress = true;
        fieldErrors.confirmEmailAddress = true;
        isValid = false;
        errors.push('Registration.errorInvalidConfirmEmailAddress');
      }

      const passwordVal = this.validatePassword(true);

      if (!passwordVal.valid) {
        errors.push(passwordVal.error);
        isValid = false;
      }

      fieldErrors.password = passwordVal.fieldErrors.password;
      fieldErrors.confirmPassword = passwordVal.fieldErrors.confirmPassword;
      return {
        isValid,
        fieldErrors,
        errors
      };
    },

    /**
     * Displays error messages and error states
     * @param {Object} results
     * @param {String[]|String} errors
     * @param {Object} [fieldErrors] field errors
     * @param {Boolean} [fieldErrors.firstName]
     * @param {Boolean} [fieldErrors.lastName]
     * @param {Boolean} [fieldErrors.emailAddress]
     * @param {Boolean} [fieldErrors.confirmEmailAddress]
     * @param {Boolean} [fieldErrors.password]
     * @param {Boolean} [fieldErrors.confirmPassword]
     * @param {Boolean} [fieldErrors.question1]
     * @param {Boolean} [fieldErrors.answer1]
     */
    handleErrors(results) {
      this.set('errorMessages', (0, _myadtHelpers.genericErrorHandler)(results));
      if (results.fieldErrors) this.set('detailErrors', results.fieldErrors);
    },

    validateRegisterPhoneNum(phone) {
      const updatedPhone = (0, _myadtHelpers.removePhoneMask)(phone);
      return !!updatedPhone && !/^[01]/.test(updatedPhone) && updatedPhone.length === 10;
    },

    actions: {
      /**
       * Checks to make sure the site usage agreement was accepted
       * before locating the accounts
       */
      locate() {
        if (!this.get('siteUsageChecked')) {
          return;
        }

        this.get('fsm').dispatch(LOCATE_ACTION);
      },

      /**
       * Pass-through to {@link Classes/StateMachine.dispatch}
       * @param {String} action
       * @param {*} params
       */
      dispatch(action, params, scroll) {
        this.get('fsm').dispatch(action, params, scroll);
      },

      /**
       * Validates phone numbers using `validateRegisterPhoneNumber` with less strict criteria
       * @param {Int|String} phone
       * @return {Boolean}
       */
      validatePhone(phone) {
        let ret = true;
        if (!phone) ret = false;

        if (phone && !this.validateRegisterPhoneNum(phone)) {
          this.set('errorMessages', [(0, _localization.toExpandedString)('Contacts.invalidPhoneNumber')]);
          ret = false;
        } else {
          this.set('errorMessages', null);
        }

        this.set('sitePhoneError', !ret);
        return ret;
      },

      /**
       * Makes sure account number is entered and has not more then 9 symbols
       * @param {Int|String} account number
       * @return {Boolean}
       */
      validateAccountNumber(customerNumber) {
        const result = !customerNumber || !(0, _myadtHelpers.validateCustomerNumber)(customerNumber);
        this.set('customerNumberError', result);
        return !result;
      },

      /**
       * Makes sure pic is 3-10 characters
       * @param {Int|String} phone
       * @return {Boolean}
       */
      validatePic(pic) {
        let ret = true;

        if (!pic || pic.length > 10 || pic.length < 3) {
          ret = false;
        }

        this.set('picError', !ret);
        return ret;
      },

      /**
       * Invokes `validatePassword`.
       * Used as focusOut event handler for
       * password and confirmPassword fields
       */
      validatePassword() {
        this.validatePassword();
      }

    },

    init() {
      this._super(...arguments);

      this.detailErrors = {
        firstName: false,
        lastName: false,
        emailAddress: false,
        confirmEmailAddress: false,
        password: false,
        confirmPassword: false,
        question1: false,
        answer1: false
      };

      if (this.get('uuid')) {
        this.getSsoSecurityQuestions();
        this.get('fsm').dispatch(INIT_PREMIUM_ACTION);
      }
    }

  });

  _exports.default = _default;
});