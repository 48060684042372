define("adt-wss/pods/components/dashboard/manage/enroll-business-reporting/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SUCCESSFUL_MESSAGE = _exports.ENROLLED_STATE = _exports.UPDATING_STATE = _exports.MODAL_WINDOW_STATE = _exports.CLOSE_MODAL_WINDOW_ACTION = _exports.UPDATE_DATA_ACTION = _exports.OPEN_MODAL_WINDOW_ACTION = void 0;
  const OPEN_MODAL_WINDOW_ACTION = 'OPEN_MODAL_WINDOW_ACTION';
  _exports.OPEN_MODAL_WINDOW_ACTION = OPEN_MODAL_WINDOW_ACTION;
  const UPDATE_DATA_ACTION = 'UPDATE_DATA_ACTION';
  _exports.UPDATE_DATA_ACTION = UPDATE_DATA_ACTION;
  const CLOSE_MODAL_WINDOW_ACTION = 'CLOSE_MODAL_WINDOW_ACTION';
  _exports.CLOSE_MODAL_WINDOW_ACTION = CLOSE_MODAL_WINDOW_ACTION;
  const MODAL_WINDOW_STATE = 'MODAL_WINDOW_STATE';
  _exports.MODAL_WINDOW_STATE = MODAL_WINDOW_STATE;
  const UPDATING_STATE = 'UPDATING_STATE';
  _exports.UPDATING_STATE = UPDATING_STATE;
  const ENROLLED_STATE = 'ENROLLED_STATE';
  _exports.ENROLLED_STATE = ENROLLED_STATE;
  const SUCCESSFUL_MESSAGE = 'You have successfully enrolled in Business Reporting.';
  _exports.SUCCESSFUL_MESSAGE = SUCCESSFUL_MESSAGE;
});