define("adt-wss/templates/modal-windows/email-billing-statement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "StNA5bMz",
    "block": "{\"symbols\":[],\"statements\":[[5,\"loading-spinner\",[],[[\"@loadingState\"],[[24,[\"body\",\"isFetching\"]]]],{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"form myadt-form\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"widget/input-wrapper\",null,[[\"label\",\"inputId\",\"inputClass\",\"inputVal\",\"onEnter\",\"error\"],[\"Email Address\",\"sendStatementEmailAddress\",\"send-statement-email-address\",[24,[\"body\",\"email\"]],[24,[\"body\",\"submitAction\"]],[24,[\"body\",\"emailError\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/email-billing-statement.hbs"
    }
  });

  _exports.default = _default;
});