define("adt-wss/templates/modal-windows/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4gng6K4S",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"registerAccount\"],[8],[0,\"\\n\\t\"],[1,[28,\"myadt-login/register\",null,[[\"modalView\",\"onClose\",\"onBack\"],[true,[24,[\"body\",\"onClose\"]],[28,\"mut\",[[24,[\"body\",\"hack\"]]],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/register.hbs"
    }
  });

  _exports.default = _default;
});