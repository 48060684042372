define("adt-wss/pods/components/widget/tool-tip/tip/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tip'],
    classNameBindings: ['tipType', 'position'],
    // TODO: find a better way to render dimensions
    // w/o `attributeBindings`
    attributeBindings: ['style'],
    style: Ember.computed('width', 'height', function () {
      let {
        width,
        height
      } = this.getProperties('width', 'height');
      if (Ember.typeOf(width) === 'number') width += 'px';
      if (Ember.typeOf(height) === 'number') height += 'px';
      return "width: ".concat(width, "; height: ").concat(height, ";");
    })
  });

  _exports.default = _default;
});