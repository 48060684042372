define("adt-wss/services/app-alerts", ["exports", "adt-wss-common/utils/route-helpers", "adt-wss-common/utils/fp", "adt-wss-common/utils/localization", "adt-wss-common/utils/myadt-helpers", "adt-wss/services/app-dispatcher"], function (_exports, _routeHelpers, _fp, _localization, _myadtHelpers, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Notifications store for the system;
   *
   * @module Services/AppAlerts
   * @prop {Object[]} alerts
   * @prop {String} alerts.[type]
   * @prop {String} alerts.text
   * @prop {String} alerts.[route]
   * @prop {String} alerts.[href]
   * @prop {Array<String[]>} analytics
   */
  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)({
    alerts: null,
    analytics: []
  }), {
    mwd: Ember.inject.service('modal-window-dispatcher'),
    apiService: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    alarmData: Ember.inject.service(),
    appDispatcher: Ember.inject.service(),
    appointmentData: Ember.inject.service(),
    systemInformation: Ember.inject.service(),
    billingData: Ember.inject.service(),
    accountData: Ember.inject.service(),
    promotionData: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    alerts: null,

    /**
     * Adds alert to list
     *
     * @function
     * @param {String|String[]} data
     * @param {Object} [opts]
     * @param {String} [opts.type] defaults to 'info'
     * @param {String} [opts.route]
     * @param {String} [opts.routeParam]
     * @param {String} [opts.href] indicates an absolute link, rather than route transition
     * @param {Function} [opts.action] method to be called on action clicked
     * @param {String} [opts.actionLinkText] text of action link
     * @param {Array} [opts.actionParams] [context, params] - context of app-alerts service and parameters for opts.action call
     */
    addAlerts(data, opts = {}, removeAlert) {
      const alerts = this.get('alerts');
      const toMix = {
        type: opts.type || 'info',
        route: opts.route,
        routeParam: opts.routeParam,
        href: opts.href,
        action: opts.action,
        actionParams: opts.actionParams,
        actionLinkText: opts.actionLinkText
      };
      const out = [].concat(data).map(i => (0, _fp.omix)(toMix, {
        text: (0, _localization.toExpandedString)(i)
      }));
      const alertsUpdated = removeAlert ? (alerts || []).filter(i => i.actionLinkText !== removeAlert) : alerts;
      return this.set('alerts', (alertsUpdated || []).concat(out));
    },

    /**
     * Adds GA Event
     *
     * @function
     * @param {String} category
     * @param {String} label
     */
    addAnalytics(category, label) {
      const ga = ['_trackEvent', 'Proactive Notifications', category, label];
      this.get('analytics').pushObject(ga);
    },

    /**
     * Gets all alerts
     *
     * @function
     * @async
     * @returns {Promise}
     */
    getAlerts() {
      return this._getAlerts().then(() => this.get('alerts'));
    },

    /**
     * Retrieves information from needed APIs by using `hashSettled` method
     * Returns only resolved promises
     *
     * @function
     * @async
     * @private
     * @returns {Promise}
     */
    _getAlerts() {
      this.set('alerts', []);
      const noSystems = this.get('currentLocation.noSystems');
      const isPreInstallCustomer = this.get('currentLocation.profileInfo.isPreInstallCustomer');

      if (noSystems) {
        return Ember.RSVP.hashSettled({
          returnMailFlag: this.checkReturnMailFlag(),
          checkHardDecline: this.checkHardDecline(),
          ccExpiration: this.checkCCExpiration(),
          cancellationInfo: this.getCancellationInfo(),
          systemsOutOfServiceInfo: this.systemsOutOfServiceInfo(),
          authAccountInfo: this.getAccountInfo(),
          promotionDataContent: this.getPromotionData()
        }).then(_routeHelpers.useResolvedOnly).catch(console.error);
      }

      if (isPreInstallCustomer) {
        return Ember.RSVP.hashSettled({
          getAppointments: this.getAppointments(),
          getLowBatterySignals: this.getLowBatterySignals(),
          travelReminders: this.getTravelReminders(),
          returnMailFlag: this.checkReturnMailFlag(),
          checkHardDecline: this.checkHardDecline(),
          ccExpiration: this.checkCCExpiration(),
          cancellationInfo: this.getCancellationInfo(),
          promotionDataContent: this.getPromotionData()
        }).then(_routeHelpers.useResolvedOnly).catch(console.error);
      }

      return Ember.RSVP.hashSettled({
        getAlarmHistory: this.getAlarmHistory(),
        getAppointments: this.getAppointments(),
        getLowBatterySignals: this.getLowBatterySignals(),
        travelReminders: this.getTravelReminders(),
        returnMailFlag: this.checkReturnMailFlag(),
        checkHardDecline: this.checkHardDecline(),
        ccExpiration: this.checkCCExpiration(),
        cancellationInfo: this.getCancellationInfo(),
        systemsOutOfServiceInfo: this.systemsOutOfServiceInfo(),
        authAccountInfo: this.getAccountInfo(),
        promotionDataContent: this.getPromotionData()
      }).then(_routeHelpers.useResolvedOnly).catch(console.error);
    },

    /**
     * Gets account information
     * Adds warn alerts by using {@link Services/AppAlerts.addAlerts}
     * Calls 'api/v2/auth/account/info' endpoint
     *
     * @function
     * @async
     * @returns {Promise}
     */
    getAccountInfo() {
      const delivery = (0, _routeHelpers.makeDeliverData2)('service:app-alert', this);
      const accountInfoPromise = delivery('accountData.accountInfo', accountData => accountData.getAccountInfo());
      return accountInfoPromise.then(({
        alertInfoList
      } = {
        alertInfoList: []
      }) => {
        if ((alertInfoList || []).length) {
          alertInfoList.forEach(a => {
            this.addAlerts(_myadtHelpers.default.localizeStringWithParams(a.messageAttribute, a.messageKey), {
              type: 'warn',
              route: 'dashboard.alarm.system'
            });
          });
        }
      });
    },

    /**
     * Gets sweepstakes content
     * Adds warn alerts by using {@link Services/AppAlerts.addAlerts}
     * Calls 'api/v2/auth/sweepstake/getSweepstakeContent' endpoint
     *
     * @function
     * @async
     * @returns {Promise}
     */
    getPromotionData() {
      const appAlerts = this;
      const delivery = (0, _routeHelpers.makeDeliverData2)('service:app-alerts', appAlerts);
      const promotionDataPromise = delivery('promotionData.content', promotionData => promotionData.getSweepstakeContent());
      return promotionDataPromise.then(result => {
        if (result.submittedDate) {
          const alertString = _myadtHelpers.default.localizeStringWithParams([{
            fieldName: 'submitDate',
            fieldValue: result.submittedDate
          }, {
            fieldName: 'daysToDraw',
            fieldValue: result.daysToDraw
          }, {
            fieldName: 'drawDate',
            fieldValue: result.drawDate
          }], 'Sweepstake.notification.postSubmission');

          appAlerts.addAlerts(alertString, {
            href: result.termsAndConditions,
            actionLinkText: 'See rules'
          }, 'Enter sweepstakes');
          this.appDispatcher.sendEvent(_appDispatcher.UPDATE_NOTIFICATION_MASSAGES, {
            drawDate: false
          });
        } else if (result.submittedDate === null) {
          appAlerts.addAlerts(result.presubmissionNotificationMsg || '', {
            action: appAlerts.showSweepstakesModal,
            actionParams: [appAlerts, result],
            actionLinkText: 'Enter sweepstakes'
          });
        }
      });
    },

    /**
     * Call sweepstakes modal
     * Adds warn alerts by using {@link Services/AppAlerts.addAlerts}
     * Calls 'api/v2/auth/sweepstake/getSweepstakeContent' endpoint
     *
     * @function
     * @param {Object} alert.action[0] as parameter
     */
    showSweepstakesModal(sweepStakeInfo) {
      if (sweepStakeInfo) {
        const mwd = this.get('mwd');

        const onClose = isSubmitted => {
          this.get('ET').sendEventTag({
            eventTypeId: 'SWEEPSTK',
            subEventTypeId: 'BNRCLSD'
          }, false, '3');

          if (isSubmitted) {
            this.appDispatcher.sendEvent(_appDispatcher.SET_SWEEPSTAKES_MODAL_DRAWING_TIME, {
              drawDate: sweepStakeInfo.drawDate
            });
          } else if (typeof isSubmitted === 'undefined') {
            this.get('promotionData').incrementDispositionCounter('SWEEPSTAKE');
            (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Sweepstakes', 'Banner Closed', 'Sweepstakes 3']);
          } else {
            (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Sweepstakes', 'Remind Me Later', 'Sweepstakes 3']);
          }

          mwd.hide();
        };

        mwd.show({
          name: 'sweepstakes-modal',
          title: 'ADT Sweepstakes',
          body: {
            sweepStakeInfo,
            formState: true
          },
          onClose,
          isFooterLine: false
        });
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Sweepstakes', 'Banner Displayed', 'Sweepstakes 3']);
      }
    },

    /**
     * Gets systems information
     * Adds warn alerts by using {@link Services/AppAlerts.addAlerts}
     * Calls 'api/v2/auth/alarmSystem/allCsListForCustSite' endpoint
     * Calls 'api/v2/alarmSystem/systemIdentification/getSystemIdentificationList' endpoint
     *
     * @function
     * @async
     * @returns {Promise}
     */
    systemsOutOfServiceInfo() {
      const delivery = (0, _routeHelpers.makeDeliverData2)('service:app-alerts', this);
      const systemInfoListFullPromise = delivery('systemInformation.systemInfoListFull', systemInformation => systemInformation.getStandardAndSystemInfoLists());
      return systemInfoListFullPromise.then(response => (response || []).map(sys => sys.alertInfoList)).then(result => [].concat(...result)).then(result => {
        if ((result || []).length) {
          result.forEach(a => {
            if (a) {
              const localizedString = _myadtHelpers.default.localizeStringWithParams(a.messageAttribute, a.messageKey);

              this.addAlerts(localizedString, {
                type: 'warn',
                route: 'dashboard.alarm.system'
              });
            }
          });
        }
      });
    },

    /**
     * Gets alarm history info
     * Adds warn alerts by using {@link Services/AppAlerts.addAlerts}
     * Calls 'api/v2/auth/history' endpoint
     *
     * @function
     * @async
     * @returns {Promise}
     */
    getAlarmHistory() {
      const delivery = (0, _routeHelpers.makeDeliverData2)('service:app-alerts', this);
      const alarmHistoryPromise = delivery('alarmData.alarmHistory', alarmData => alarmData.getAlarmHistory());
      return alarmHistoryPromise.then(list => {
        if (!(list || []).length) {
          this.addAnalytics('No Alarm Activity for 90 days', 'Top of Dashboard');
          this.addAlerts('AppAlert.noAlarmActivity', {
            type: 'warn',
            href: 'https://help.adt.com/s/article/test-your-system'
          });

          if (window.Pypestream) {
            const pypemsg = [{
              attribute: 'error',
              label: 'error',
              value: 'no activity 90 days',
              visible: 'wpm'
            }];
            window.Pypestream('config', {
              passthrough: JSON.stringify(pypemsg)
            });
            console.log('Pypestream msg sent: no activity 90 days');
          }
        }

        return true;
      });
    },

    /**
     * Gets appointment list
     * Adds warn alerts by using {@link Services/AppAlerts.addAlerts}
     * Calls 'api/v2/auth/appointment/serviceJobsForSite' endpoint
     *
     * @function
     * @async
     * @returns {Promise}
     */
    getAppointments() {
      /* eslint-disable max-len */
      const delivery = (0, _routeHelpers.makeDeliverData2)('service:app-alerts', this);
      const appointmentListPromise = delivery('appointmentData.appointmentList', appointmentData => appointmentData.getAppointmentList());
      return appointmentListPromise.then(list => {
        if ((list || []).length) {
          const out = list.map(appt => {
            if (appt.apptDateString == null && !appt.eligibleForSchedule) {
              return "Please call us at 1-800-238-2727 for questions regarding your service appointment (Job #".concat(appt.jobNo, ")");
            }

            if (appt.eligibleForSchedule) {
              this.addAnalytics('Service Appointments', 'Open Job');
              return 'You have requested service at this address. Please click here to schedule or cancel this appointment.';
            }

            const startHour = appt.apptStartDateObj.getFixedHour(true, true);
            const endHour = appt.apptEndDateObj.getFixedHour(true, true);
            const startMins = appt.apptStartDateObj.getFixedMinute();
            const endMins = appt.apptEndDateObj.getFixedMinute();
            const startAmPm = appt.apptStartDateObj.getAmPm();
            const endAmPm = appt.apptEndDateObj.getAmPm();
            const dateStr = appt.apptStartDateObj.toAltString();
            this.addAnalytics('Service Appointments', 'SvcApptDisplayedDashboard');
            return "Your appointment is scheduled for ".concat(dateStr, ", ").concat(startHour, ":").concat(startMins).concat(startAmPm, "-").concat(endHour, ":").concat(endMins).concat(endAmPm, ". (Job #").concat(appt.jobNo, ")");
          });
          this.addAlerts(out, {
            route: 'dashboard.alarm.appointments'
          });
        }

        return true;
      });
      /* eslint-enable max-len */
    },

    /**
     * Gets low battery signals within system information
     * Adds warn alerts by using {@link Services/AppAlerts.addAlerts}
     * Calls 'api/v2/auth/battery/lowBatterySignalCheckForSite' endpoint
     *
     * @function
     * @async
     * @returns {Promise}
     */
    getLowBatterySignals() {
      const delivery = (0, _routeHelpers.makeDeliverData2)('service:app-alerts', this);
      const lowBatteryPromise = delivery('systemInformation.lowBatterySignals', systemInformation => systemInformation.getLowBatterySignals());
      return lowBatteryPromise.then(obj => {
        const filtered = Object.keys(obj).filter(key => obj[key].total);
        const out = (filtered || []).map(i => obj[i].message);

        if (out.length) {
          this.addAlerts(out, {
            type: 'warn',
            href: 'https://help.adt.com/s/article/General-Battery-Help'
          });
        }

        return true;
      });
    },

    /**
     * Gets status hard decline within payment information
     * Adds warn alerts by using {@link Services/AppAlerts.addAlerts}
     * Calls 'api/v2/auth/paymentDeclines/easyPayHardDeclines' endpoint
     *
     * @function
     * @async
     * @returns {Promise}
     */
    checkHardDecline() {
      const delivery = (0, _routeHelpers.makeDeliverData2)('service:app-alerts', this);
      const hardDeclinePromise = delivery('billingData.hardDeclined', billingData => billingData.checkHardDecline());
      return hardDeclinePromise.then(data => {
        if (data.hardDeclined) {
          this.addAnalytics('EasyPay Hard Decline', 'Top of Dashboard');
          this.addAlerts('AppAlert.hardDecline', {
            type: 'warn',
            route: 'dashboard.account.payments'
          });
        }

        return true;
      });
    },

    /**
     * Gets billing information and checks if credit card is expired
     * Adds warn alerts by using {@link Services/AppAlerts.addAlerts}
     * Calls 'api/v2/auth/dashboardWidget/billingWidget' endpoint
     *
     * @function
     * @async
     * @returns {Promise}
     */
    checkCCExpiration() {
      const delivery = (0, _routeHelpers.makeDeliverData2)('service:app-alerts', this);
      const ccExpPromise = delivery('billingData.billingModel.easypayDetails', billingData => billingData.getBillingInfo());
      return ccExpPromise.then(easypayDetails => {
        if (easypayDetails && easypayDetails.ccDetails) {
          const ccDetails = easypayDetails.ccDetails;
          let msg = 'The Credit Card you enrolled in ADT EasyPay ';
          let month = ccDetails.expirationDateObj instanceof Date ? "in ".concat(ccDetails.expirationDateObj.getMonthString('full')) : null;

          if (ccDetails.expired) {
            month = month || 'recently';
            msg += "expired ".concat(month, ". Please update soon.");
            this.addAlerts(msg, {
              type: 'warn',
              route: 'dashboard.account.payments'
            });
            this.addAnalytics('CC Expired', 'Top of Dashboard');
          } else if (ccDetails.expiresSoon) {
            month = month || 'soon';
            msg += "expires ".concat(month, ". Please update soon.");
            this.addAlerts(msg, {
              type: 'warn',
              route: 'dashboard.account.payments'
            });
            this.addAnalytics('CC Expires Soon', 'Top of Dashboard');
          }
        }

        return true;
      });
    },

    /**
     * Gets travel reminders information
     * Adds warn alerts by using {@link Services/AppAlerts.addAlerts}
     * Calls 'api/v2/auth/vacation/getAllVacations' endpoint
     *
     * @function
     * @async
     * @returns {Promise}
     */
    getTravelReminders() {
      const delivery = (0, _routeHelpers.makeDeliverData2)('service:app-alerts', this);
      const travelReminderPromise = delivery('contactData.travelReminders', contactData => contactData.getTravelReminders());
      return travelReminderPromise.then(reminders => {
        if (!!reminders && reminders.length > 0) {
          this.addAnalytics('Travel Reminders', 'TravRemdrDisplayedTopDashboard');
          reminders.forEach(reminder => {
            const startDate = reminder.startDate.toAltString();
            const endDate = reminder.endDate.toAltString();
            const msg = "Travel Reminders scheduled for trip starting ".concat(startDate, " and ending ").concat(endDate, ".");
            this.addAlerts(msg, {
              route: 'dashboard.account.preferences'
            });
          });
        }

        return true;
      });
    },

    /**
     * Gets customer billing address
     * Adds warn alerts by using {@link Services/AppAlerts.addAlerts}
     * Calls 'api/v2/auth/bill/billingAddress/getCustomerBillingAddress' endpoint
     *
     * @function
     * @async
     * @returns {Promise}
     */
    checkReturnMailFlag() {
      const delivery = (0, _routeHelpers.makeDeliverData2)('service:app-alerts', this);
      const returnMailPromise = delivery('billingData.billingAddress.returnMailFlag', billingData => billingData.getBillingAddress());
      return returnMailPromise.then(returnMailFlag => {
        if (returnMailFlag) {
          this.addAnalytics('Return Mail', 'Top of Dashboard');
          this.addAlerts('AppAlert.returnMail', {
            type: 'warn',
            route: 'dashboard.account.profile'
          });
        }

        return true;
      });
    },

    /**
     * Gets cancellation information
     * Adds warn alerts by using {@link Services/AppAlerts.addAlerts}
     * Calls 'api/v2/auth/account/getCancellationInfo' endpoint
     *
     * @function
     * @async
     * @returns {Promise}
     */
    getCancellationInfo() {
      const delivery = (0, _routeHelpers.makeDeliverData2)('service:app-alerts', this);
      const cancellationInfoPromise = delivery('accountData.cancellationInfo', accountData => accountData.getCancellationInfo());
      return cancellationInfoPromise.then(cancellationInfo => {
        if (cancellationInfo && cancellationInfo.pendingCancellation) {
          const cancelDate = Date.createDate(cancellationInfo.cancelEffectiveDate).toAltString('full');
          this.addAnalytics('Pending cancel', cancellationInfo.cancelType);
          this.addAlerts("Your account is pending cancellation on ".concat(cancelDate), {
            type: 'warn',
            route: 'dashboard.account'
          });
        }

        return true;
      });
    },

    init() {
      this._super(...arguments);

      this.analytics = [];
    }

  });

  _exports.default = _default;
});