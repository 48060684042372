define("adt-wss/pods/help/route", ["exports", "adt-wss-common/mixins/scroll-route-to-top"], function (_exports, _scrollRouteToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollRouteToTop.default);

  _exports.default = _default;
});