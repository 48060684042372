define("adt-wss/pods/dashboard/account/payments/index/route", ["exports", "adt-wss-common/utils/route-helpers"], function (_exports, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentLocation: Ember.inject.service(),
    contactData: Ember.inject.service(),
    makeDeliverData: _routeHelpers.makeDeliverData,
    hashSettled: Ember.RSVP.hashSettled,
    useResolvedOnly: _routeHelpers.useResolvedOnly,

    model() {
      const contactDeliverData = this.makeDeliverData(this, 'contactData');
      const {
        paperlessVisible
      } = this.modelFor('dashboard.account');
      const paperlessOption = contactDeliverData('paperlessOption', contactData => contactData.getPMOC().then(r => r.paperlessOption));
      const {
        permissions,
        profileInfo
      } = this.get('currentLocation');
      const authInfo = this.get('authInfo.authInfo');
      return this.hashSettled({
        permissions,
        profileInfo,
        paperlessOption,
        authInfo,
        paperlessVisible
      }).then(this.useResolvedOnly);
    }

  });

  _exports.default = _default;
});