define("adt-wss/pods/components/dashboard/account/saved-accounts/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss-common/classes/status-message", "adt-wss-common/utils/common-fsm-state", "adt-wss/pods/components/dashboard/account/one-time-payment/component"], function (_exports, _withFsm, _myadtHelpers, _emberHelpers, _statusMessage, _commonFsmState, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.yearOpts = _exports.monthOpts = void 0;
  const MODAL_STATE = 'MODAL_STATE';
  const START_DELETE_ACTION = 'START_DELETE_ACTION';
  const START_EDIT_ACTION = 'START_EDIT_ACTION';
  const SUBMIT_EDIT_ACTION = 'SUBMIT_EDIT_ACTION';
  const SUBMIT_DELETE_ACTION = 'SUBMIT_DELETE_ACTION';
  const CANCEL_ACTION = 'CANCEL_ACTION';
  const EXIT_ACTION = 'EXIT_ACTION';
  const fsmModel = {
    transitions: {
      IDLE_STATE: {
        START_DELETE_ACTION: MODAL_STATE,
        START_EDIT_ACTION: MODAL_STATE,
        EXIT_ACTION: _commonFsmState.IDLE_STATE
      },
      MODAL_STATE: {
        SUBMIT_EDIT_ACTION: _commonFsmState.LOADING_STATE,
        SUBMIT_DELETE_ACTION: _commonFsmState.LOADING_STATE,
        CANCEL_ACTION: _commonFsmState.IDLE_STATE
      },
      LOADING_STATE: {
        SUCCESS_ACTION: _commonFsmState.IDLE_STATE,
        FAILURE_ACTION: MODAL_STATE
      }
    }
  };

  const monthOpts = function () {
    const out = [];
    let i = 1;

    while (i < 13) {
      out.push("0".concat(i).slice(-2));
      i++;
    }

    return out;
  }();

  _exports.monthOpts = monthOpts;

  const yearOpts = function () {
    const out = [];
    const thisYear = new Date().getFullYear();
    let year = new Date().getFullYear();

    while (year < thisYear + 10) {
      out.push(year);
      year++;
    }

    return out;
  }();
  /**
   * @module Component/Dashboard/Account/SavedAccounts
   * @prop {SavedAccount[]} accounts
   */


  _exports.yearOpts = yearOpts;

  var _default = Ember.Component.extend(_withFsm.default, {
    classNames: ['saved-account-module', 'flex', 'align-items-stretch'],
    billingData: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    dsm: Ember.inject.service('dashboard-status-messages'),
    router: Ember.inject.service(),
    billInfo: Ember.computed.readOnly('billingData.billingModel.billInfo'),
    accounts: Ember.computed.oneWay('billingData.savedAccounts'),
    statusMessages: Ember.computed.alias('dsm.statusMessages'),
    currentState: _commonFsmState.IDLE_STATE,
    isLoading: (0, _emberHelpers.computedApplyFunction)(cs => cs === _commonFsmState.LOADING_STATE, 'currentState'),
    fsmModel,

    /**
     * Receives action ('edit', or 'delete') and account
     * object, opens appropriate modal, and sends
     * correct action on submit
     * @param {String} action 'edit'|'delete'
     * @param {SavedAccount} account
     */
    openModal(action, account) {
      const {
        mwd,
        fsm
      } = this.getProperties('mwd', 'fsm');
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);
      const IS_EDIT = action === 'edit';
      let exp = [];

      const onClose = () => {
        this.get('fsm').dispatch(CANCEL_ACTION);
        mwd.hide();
      };

      if (account.expiryDate) {
        exp = account.expiryDate.split('/');
      }

      const body = {
        isLoading: false,
        account,
        yearOpts,
        monthOpts,
        yearError: false,
        monthError: false,
        expMonth: exp[0] || null,
        expYear: exp[1] || null
      };

      const callback = () => {
        const submit = IS_EDIT ? SUBMIT_EDIT_ACTION : SUBMIT_DELETE_ACTION;
        mwd.clearMessages();
        Ember.set(body, 'account.expiryDate', "".concat(body.expMonth, "/").concat(body.expYear));
        Ember.set(body, 'isLoading', true);
        return fsm.dispatch(submit, body.account).then(() => fsm.dispatch(_commonFsmState.SUCCESS_ACTION, action)).catch(results => {
          Ember.set(body, 'isLoading', false);
          return showErrors(results, _commonFsmState.FAILURE_ACTION);
        });
      };

      mwd.show({
        name: "".concat(action, "-saved-account"),
        title: IS_EDIT ? 'Edit Expiration Date' : 'Delete Payment Method',
        body,
        onClose,
        statusMessages: [],
        actions: [{
          caption: 'Cancel',
          stl: 'outline',
          callback: onClose
        }, {
          caption: IS_EDIT ? 'Update' : 'Delete',
          callback
        }]
      });
    },

    /**
     * @param {SavedAccount} account
     */
    [START_DELETE_ACTION](account) {
      this.openModal('delete', account);
    },

    /**
     * @param {SavedAccount} account
     */
    [START_EDIT_ACTION](account) {
      this.openModal('edit', account);
    },

    /**
     * @param {SavedAccount} account
     */
    [SUBMIT_EDIT_ACTION](account) {
      return this.get('billingData').updateSavedAccount(account);
    },

    /**
     * @param {SavedAccount} account
     */
    [SUBMIT_DELETE_ACTION](account) {
      return this.get('billingData').deleteSavedAccount(account);
    },

    /**
     * Closes modal and shows success message
     * @param {string} action 'edit'|'delete'
     */
    [_commonFsmState.SUCCESS_ACTION](action) {
      let message = 'Payment method successfully ';
      message += action === 'delete' ? 'deleted.' : 'updated.';
      this.set('statusMessages', [new _statusMessage.default(message, 'success', true)]);
      this.get('mwd').hide();
    },

    /**
     * Closes module and returns to billing statements/history
     */
    [EXIT_ACTION]() {
      this.get('router').transitionTo('dashboard.account.payments');
    },

    actions: {
      renderPaymentIcon: _component.renderPaymentIcon
    }
  });

  _exports.default = _default;
});