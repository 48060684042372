define("adt-wss/pods/dashboard/shop/checkout/batteryid/route", ["exports", "adt-wss-common/classes/status-message", "adt-wss-common/utils/myadt-helpers"], function (_exports, _statusMessage, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    batteryData: Ember.inject.service(),
    systemInformation: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    dsm: Ember.inject.service('dashboard-status-messages'),
    sendAnalyticEvent: _myadtHelpers.sendAnalyticEvent,
    genericErrorHandler: _myadtHelpers.genericErrorHandler,

    model(params) {
      const batteryID = parseInt(params.batteryid, 10) || 1;
      return this.checkBattery(Number(batteryID)).then(check => {
        if (!check) {
          return this.transitionTo('/dashboard/orders');
        }

        return this.getBatteryInformation(Number(batteryID));
      }).catch(results => {
        const errors = this.genericErrorHandler(results);
        this.transitionTo('/dashboard/orders');
        this.set('dsm.statusMessages', errors.map(error => new _statusMessage.default(error, 'error')));
      });
    },

    /**
     * Check to see if the battery id is in the full battery list
     * @param {Int} id
     */
    checkBattery(id) {
      const batteryList = this.get('batteryData.batteryList');

      const hasBattery = bl => bl.some(el => el.batteryID === id);

      if (batteryList) {
        return Ember.RSVP.Promise.resolve(hasBattery(batteryList));
      }

      return this.get('batteryData').getBatteryList().then(data => hasBattery(data.batteryList));
    },

    /**
     * Return the order data based on the battery ID,
     * CS number and standard shipping
     * @param {Int} batteryId
     */
    getBatteryInformation(batteryId) {
      const {
        batteryData,
        systemInformation
      } = this.getProperties('batteryData', 'systemInformation');
      const {
        csNo
      } = systemInformation.get('selectedSystem') || {};
      return batteryData.getBatteryOrderPricing({
        batteryId,
        csNo,
        shippingOptionSelected: 'standard'
      });
    },

    activate() {
      const eventTagInfo = {
        eventTypeId: 'ORDBATT',
        subEventTypeId: 'CHKOUT'
      };
      this.get('ET').sendEventTag(eventTagInfo);
      this.sendAnalyticEvent(['_trackPageView', '/dashboard/shop/checkout']);
    }

  });

  _exports.default = _default;
});