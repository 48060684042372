define("adt-wss/templates/modal-windows/sensor-battery-confirm-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8a/r+dEz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"padded-top\"],[8],[0,\"\\n\\t\"],[7,\"h4\",true],[10,\"class\",\"strong\"],[8],[0,\"Please ensure that the battery size you have selected will fit in your sensor or device\"],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"\\n\\t\\t\"],[7,\"a\",true],[10,\"class\",\"blue-link\"],[10,\"rel\",\"noopener\"],[10,\"target\",\"_blank\"],[10,\"href\",\"https://help.adt.com/s/article/Sensor-Battery-Replacement\"],[8],[0,\"Where is my battery located?\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/modal-windows/sensor-battery-confirm-modal.hbs"
    }
  });

  _exports.default = _default;
});