define("adt-wss/pods/dashboard/shop/productsservices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tiE9ylDG",
    "block": "{\"symbols\":[\"route\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"dashboardOrderProductsServices\"],[8],[0,\"\\n\\t\"],[7,\"nav\",true],[10,\"class\",\"sub-section-tab-nav\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"visibleLinks\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"blue-link\",[23,1,[\"route\"]]]],{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[23,1,[\"text\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"sub-section-content\"],[8],[1,[22,\"outlet\"],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/dashboard/shop/productsservices/template.hbs"
    }
  });

  _exports.default = _default;
});