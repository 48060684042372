define("adt-wss/pods/components/dashboard/account/billing-address/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SUCCESSFULLY_UPDATED_MESSAGE = _exports.MELISSACHECK_ERROR_KEY = _exports.MELISSACHECK_WARNING_KEY = _exports.GENERIC_ERROR_MESSAGE = _exports.SUCCESS_ACTION = _exports.FAILURE_ACTION = _exports.UPDATE_DATA_ACTION = _exports.CANCEL_EDIT_MODE_ACTION = _exports.SET_EDIT_MODE_ACTION = _exports.UPDATING_STATE = _exports.EDIT_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const EDIT_STATE = 'EDIT_STATE';
  _exports.EDIT_STATE = EDIT_STATE;
  const UPDATING_STATE = 'UPDATING_STATE';
  _exports.UPDATING_STATE = UPDATING_STATE;
  const SET_EDIT_MODE_ACTION = 'SET_EDIT_MODE_ACTION';
  _exports.SET_EDIT_MODE_ACTION = SET_EDIT_MODE_ACTION;
  const CANCEL_EDIT_MODE_ACTION = 'CANCEL_EDIT_MODE_ACTION';
  _exports.CANCEL_EDIT_MODE_ACTION = CANCEL_EDIT_MODE_ACTION;
  const UPDATE_DATA_ACTION = 'UPDATE_DATA_ACTION';
  _exports.UPDATE_DATA_ACTION = UPDATE_DATA_ACTION;
  const FAILURE_ACTION = 'FAILURE_ACTION';
  _exports.FAILURE_ACTION = FAILURE_ACTION;
  const SUCCESS_ACTION = 'SUCCESS_ACTION';
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
  const GENERIC_ERROR_MESSAGE = 'Generic.error';
  _exports.GENERIC_ERROR_MESSAGE = GENERIC_ERROR_MESSAGE;
  const MELISSACHECK_WARNING_KEY = 'MelissaCheck.service.hasWarnings.myAdt';
  _exports.MELISSACHECK_WARNING_KEY = MELISSACHECK_WARNING_KEY;
  const MELISSACHECK_ERROR_KEY = 'MelissaCheck.service.hasErrors.myAdt';
  _exports.MELISSACHECK_ERROR_KEY = MELISSACHECK_ERROR_KEY;
  const SUCCESSFULLY_UPDATED_MESSAGE = 'Billing address successfully updated';
  _exports.SUCCESSFULLY_UPDATED_MESSAGE = SUCCESSFULLY_UPDATED_MESSAGE;
});