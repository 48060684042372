define("adt-wss/mixins/on-form-esc-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Inserts `onEscHandler` in the component
   * to track ESC press and close form after that
   * @mixin onFormEscHandler
   */
  var _default = Ember.Mixin.create({
    escHandler: false,

    onClose() {
      this.mwd.hide();
      this.toggleEscHandler();
    },

    onEnter() {
      this.fsm.dispatch(this.cancelESCState);
      this.mwd.hide();
      this.toggleEscHandler();
    },

    toggleEscHandler() {
      this.toggleProperty('escHandler');
    },

    onEscHandler(e) {
      const keyESC = 27;
      const keyEnter = 13;
      const {
        currentState,
        escHandler
      } = this.getProperties('currentState', 'escHandler');

      if (currentState === this.activeESCState && e.keyCode === keyESC && !escHandler) {
        this.mwd.show({
          name: 'close-form',
          title: 'Close the form',
          actions: [{
            caption: 'Cancel',
            stl: 'outline',
            callback: () => this.onClose()
          }, {
            caption: 'Close',
            callback: () => this.onEnter()
          }]
        });
        this.toggleEscHandler();
      } else if (currentState === this.activeESCState && e.keyCode === keyESC && escHandler) {
        this.onClose();
      }

      if (currentState === this.activeESCState && e.keyCode === keyEnter) {
        this.onEnter();
      }
    }

  });

  _exports.default = _default;
});