define("adt-wss/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1QhyB2tX",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"salesforce-chat-include\"],false],[0,\"\\n\"],[1,[22,\"pypestream-layout\"],false],[0,\"\\n\"],[1,[22,\"csr-header\"],false],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[1,[22,\"modal-window\"],false],[0,\"\\n\"],[1,[22,\"timeout-modal-window\"],false],[0,\"\\n\"],[1,[22,\"session-logout-alert\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/templates/application.hbs"
    }
  });

  _exports.default = _default;
});