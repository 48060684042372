define("adt-wss/pods/help/controller", ["exports", "adt-wss-common/utils/myadt-helpers"], function (_exports, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const links = [{
    route: 'help.index',
    text: 'Help'
  }, {
    route: 'help.testyoursystem',
    text: 'Test Your System'
  }, {
    route: 'help.travelreminders',
    text: 'Travel Reminders'
  }, {
    route: 'help.batteries',
    text: 'Batteries'
  }, {
    route: 'help.systemmanuals',
    text: 'System Manuals'
  }, {
    route: 'help.faq',
    text: 'FAQ'
  }];

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    impersonationData: Ember.inject.service(),
    links,
    windowRedirect: _myadtHelpers.windowRedirect,
    sidebarOpened: Ember.computed.bool('media.isNotMobile'),
    actions: {
      closeSidebar() {
        this.set('sidebarOpened', false);
      },

      toggleSidebar() {
        this.toggleProperty('sidebarOpened');
      },

      logout() {
        const {
          session,
          impersonationData
        } = this.getProperties('session', 'impersonationData');

        if (impersonationData.isCsr) {
          return impersonationData.endImpersonation().then(response => {
            const {
              emailImpersonatedInCSRCustomerDashboard: impFromEmail
            } = response;
            const rootURL = (0, _myadtHelpers.getOrigin)() + this.get('rootURL').substr(1); // remove double slash

            const path = impFromEmail ? 'dashboard' : 'search';
            this.windowRedirect("".concat(rootURL, "/auth/csr/#/").concat(path));
          });
        }

        if (session.isAuthenticated) {
          return session.invalidate(true, {
            message: 'sign out from dashboard header'
          });
        }

        return false;
      }

    }
  });

  _exports.default = _default;
});