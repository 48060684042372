define("adt-wss/utils/iframeResizer", ["exports", "jquery", "adt-wss/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.factory = _exports.setupEventListeners = _exports.sendTriggerMsg = _exports.tabVisible = _exports.resizeIFrames = _exports.fixHiddenIFrames = _exports.debouce = _exports.createOutgoingMsg = _exports.trigger = _exports.syncResize = _exports.setSize = _exports.resetIFrame = _exports.unsetPagePosition = _exports.setPagePosition = _exports.getPagePosition = _exports.closeIFrame = _exports.chkCallback = _exports.iFrameListener = _exports.output = _exports.warn = _exports.info = _exports.log = _exports.isLogEnabled = _exports.formatLogHeader = _exports.getMyID = _exports.setupRequestAnimationFrame = _exports.removeEventListener = _exports.addEventListener = _exports.sendHash = void 0;

  /* eslint-disable no-use-before-define */

  /*
   * File: iframeResizer.js
   * Desc: Force iframes to size to content.
   * Requires: iframeResizer.contentWindow.js to be loaded into the target frame.
   * Doc: https://github.com/davidjbradshaw/iframe-resizer
   * Author: David J. Bradshaw - dave@bradshaw.net
   * Contributor: Jure Mav - jure.mav@gmail.com
   * Contributor: Reed Dadoune - reed@dadoune.com
   */
  const exports = {};
  let count = 0;
  let logEnabled = false;
  let hiddenCheckEnabled = false;
  let pagePosition = null;
  let requestAnimationFrame = window.requestAnimationFrame;
  let timer = null;
  const msgHeader = 'message';
  const msgHeaderLen = msgHeader.length;
  const msgId = '[iFrameSizer]'; // Must match iframe msg I

  const msgIdLen = msgId.length;
  const resetRequiredMethods = {
    max: 1,
    scroll: 1,
    bodyScroll: 1,
    documentElementScroll: 1
  };
  const settings = {};
  const defaults = {
    autoResize: true,
    bodyBackground: null,
    bodyMargin: null,
    bodyMarginV1: 8,
    bodyPadding: null,
    checkOrigin: true,
    inPageLinks: false,
    enablePublicMethods: true,
    heightCalculationMethod: 'bodyOffset',
    id: 'iFrameResizer',
    interval: 32,
    log: false,
    maxHeight: Infinity,
    maxWidth: Infinity,
    minHeight: 0,
    minWidth: 0,
    resizeFrom: 'parent',
    scrolling: false,
    sizeHeight: true,
    sizeWidth: false,
    tolerance: 0,
    widthCalculationMethod: 'scroll',
    closedCallback: () => undefined,
    initCallback: () => undefined,
    messageCallback: () => {
      warn('MessageCallback function not defined');
    },
    resizedCallback: () => undefined,
    scrollCallback: () => true
  };

  const sendHash = function () {
    const hash = window.location.hash.substring(1);
    const frame = (0, _jquery.default)('.search-iframe iframe');

    if (frame.length) {
      frame[0].contentWindow.postMessage({
        findElement: hash
      }, _environment.default.aemHostUrl);
    }
  };

  _exports.sendHash = sendHash;
  (0, _jquery.default)(window).on('hashchange', sendHash);

  const addEventListener = function addEventListener(obj, evt, func) {
    /* istanbul ignore else */
    // Not testable in PhantonJS
    if (window.addEventListener) {
      obj.addEventListener(evt, func, false);
    } else if (window.attachEvent) {
      // IE
      obj.attachEvent("on".concat(evt), func);
    }
  };

  _exports.addEventListener = addEventListener;

  const removeEventListener = function removeEventListener(el, evt, func) {
    /* istanbul ignore else */
    // Not testable in phantonJS
    if (window.removeEventListener) {
      el.removeEventListener(evt, func, false);
    } else if (window.detachEvent) {
      // IE
      el.detachEvent("on".concat(evt), func);
    }
  };

  _exports.removeEventListener = removeEventListener;

  const setupRequestAnimationFrame = function setupRequestAnimationFrame() {
    const vendors = ['moz', 'webkit', 'o', 'ms'];
    let x; // Remove vendor prefixing if prefixed and break early if not

    for (x = 0; x < vendors.length && !requestAnimationFrame; x += 1) {
      requestAnimationFrame = window["".concat(vendors[x], "RequestAnimationFrame")];
    }

    if (!requestAnimationFrame) {
      log('setup', 'RequestAnimationFrame not supported');
    }
  };

  _exports.setupRequestAnimationFrame = setupRequestAnimationFrame;

  const getMyID = function getMyID(iframeId) {
    let retStr = 'Host page: ' + iframeId;

    if (window.top !== window.self) {
      if (window.parentIFrame && window.parentIFrame.getId) {
        retStr = window.parentIFrame.getId() + ': ' + iframeId;
      } else {
        retStr = 'Nested host page: ' + iframeId;
      }
    }

    return retStr;
  };

  _exports.getMyID = getMyID;

  const formatLogHeader = function formatLogHeader(iframeId) {
    return msgId + '[' + getMyID(iframeId) + ']';
  };

  _exports.formatLogHeader = formatLogHeader;

  const isLogEnabled = function isLogEnabled(iframeId) {
    return settings[iframeId] ? settings[iframeId].log : logEnabled;
  };

  _exports.isLogEnabled = isLogEnabled;

  const log = function log(iframeId, msg) {
    output('log', iframeId, msg, isLogEnabled(iframeId));
  };

  _exports.log = log;

  const info = function info(iframeId, msg) {
    output('info', iframeId, msg, isLogEnabled(iframeId));
  };

  _exports.info = info;

  const warn = function warn(iframeId, msg) {
    output('warn', iframeId, msg, true);
  };

  _exports.warn = warn;

  const output = function output(type, iframeId, msg, enabled) {
    if (enabled === true) {
      console[type](formatLogHeader(iframeId), msg);
    }
  };

  _exports.output = output;

  const iFrameListener = function iFrameListener(event) {
    const resizeIFrame = function resizeIFrame() {
      const resize = function resize() {
        setSize(messageData);
        setPagePosition(iframeId);
      };

      ensureInRange('Height');
      ensureInRange('Width');
      syncResize(resize, messageData, 'init');
    };

    const processMsg = function processMsg() {
      const data = msg.substr(msgIdLen).split(':');
      return {
        iframe: settings[data[0]].iframe,
        id: data[0],
        height: data[1],
        width: data[2],
        type: data[3]
      };
    };

    const ensureInRange = function ensureInRange(Dimension) {
      const max = Number(settings[iframeId]["max".concat(Dimension)]);
      const min = Number(settings[iframeId]["min".concat(Dimension)]);
      const dimension = Dimension.toLowerCase();
      let size = Number(messageData[dimension]);
      log(iframeId, "Checking ".concat(dimension, " is in range ").concat(min, "-").concat(max));

      if (size < min) {
        size = min;
        log(iframeId, "Set ".concat(dimension, " to min value"));
      }

      if (size > max) {
        size = max;
        log(iframeId, "Set ".concat(dimension, " to max value"));
      }

      messageData[dimension] = size.toString();
    };

    const isMessageFromIFrame = function isMessageFromIFrame() {
      const checkAllowedOrigin = function checkAllowedOrigin() {
        const checkList = function checkList() {
          let i = 0;
          let retCode = false;
          log(iframeId, "Checking connection is from allowed list of origins: ".concat(checkOrigin));

          for (; i < checkOrigin.length; i++) {
            if (checkOrigin[i] === origin) {
              retCode = true;
              break;
            }
          }

          return retCode;
        };

        const checkSingle = function checkSingle() {
          const remoteHost = settings[iframeId].remoteHost;
          log(iframeId, "Checking connection is from: ".concat(remoteHost));
          return origin === remoteHost;
        };

        return checkOrigin.constructor === Array ? checkList() : checkSingle();
      };

      const origin = event.origin;
      const checkOrigin = settings[iframeId].checkOrigin;

      if (checkOrigin && origin.toString() !== 'null' && !checkAllowedOrigin()) {
        throw new Error("Unexpected message received from: ".concat(origin, " for ").concat(messageData.iframe.id, ". Message was: ").concat(event.data, ".\n\t\t\t\tThis error can be disabled by setting the checkOrigin: false option or by providing of array of trusted domains."));
      }

      return true;
    };

    const isMessageForUs = function isMessageForUs() {
      return msgId === msg.toString().substr(0, msgIdLen) && msg.substr(msgIdLen).split(':')[0] in settings;
    };

    const isMessageFromMetaParent = function isMessageFromMetaParent() {
      // Test if this message is from a parent above us. This is an ugly test, however, updating
      // the message format would break backwards compatibity.
      const retCode = messageData.type in {
        true: 1,
        false: 1,
        undefined: 1
      };

      if (retCode) {
        log(iframeId, 'Ignoring init message from meta parent page');
      }

      return retCode;
    };

    const getMsgBody = function getMsgBody(offset) {
      return msg.substr(msg.indexOf(':') + msgHeaderLen + offset);
    };

    const forwardMsgFromIFrame = function forwardMsgFromIFrame(msgBody) {
      log(iframeId, "MessageCallback passed: {iframe: ".concat(messageData.iframe.id, ", message: ").concat(msgBody, "}"));
      callback('messageCallback', {
        iframe: messageData.iframe,
        message: JSON.parse(msgBody)
      });
      log(iframeId, '--');
    };

    const getPageInfo = function getPageInfo() {
      const bodyPosition = document.body.getBoundingClientRect();
      const iFramePosition = messageData.iframe.getBoundingClientRect();
      return JSON.stringify({
        iframeHeight: iFramePosition.height,
        iframeWidth: iFramePosition.width,
        clientHeight: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
        clientWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
        offsetTop: parseInt(iFramePosition.top - bodyPosition.top, 10),
        offsetLeft: parseInt(iFramePosition.left - bodyPosition.left, 10),
        scrollTop: window.pageYOffset,
        scrollLeft: window.pageXOffset
      });
    };

    const sendPageInfoToIframe = function sendPageInfoToIframe(iframe, iframeId) {
      const debouncedTrigger = function debouncedTrigger() {
        trigger('Send Page Info', 'pageInfo:' + getPageInfo(), iframe, iframeId);
      };

      debouce(debouncedTrigger, 32);
    };

    const startPageInfoMonitor = function startPageInfoMonitor() {
      const setListener = function setListener(type, func) {
        const sendPageInfo = function sendPageInfo() {
          if (settings[id]) {
            sendPageInfoToIframe(settings[id].iframe, id);
          } else {
            stop();
          }
        };

        ['scroll', 'resize'].forEach(evt => {
          log(id, type + evt + ' listener for sendPageInfo');
          func(window, evt, sendPageInfo);
        });
      };

      const stop = function stop() {
        setListener('Remove ', removeEventListener);
      };

      const start = function start() {
        setListener('Add ', addEventListener);
      };

      const id = iframeId; // Create locally scoped copy of iFrame ID

      start();
      settings[id].stopPageInfo = stop;
    };

    const stopPageInfoMonitor = function stopPageInfoMonitor() {
      if (settings[iframeId] && settings[iframeId].stopPageInfo) {
        settings[iframeId].stopPageInfo();
        delete settings[iframeId].stopPageInfo;
      }
    };

    const checkIFrameExists = function checkIFrameExists() {
      let retBool = true;

      if (messageData.iframe === null) {
        warn(iframeId, "IFrame (".concat(messageData.id, ") not found"));
        retBool = false;
      }

      return retBool;
    };

    const getElementPosition = function getElementPosition(target) {
      const iFramePosition = target.getBoundingClientRect();
      getPagePosition(iframeId);
      return {
        x: Math.floor(Number(iFramePosition.left) + Number(pagePosition.x)),
        y: Math.floor(Number(iFramePosition.top) + Number(pagePosition.y))
      };
    };

    const scrollRequestFromChild = function scrollRequestFromChild(addOffset) {
      // Not testable in Karma

      /* istanbul ignore next */
      const reposition = function reposition() {
        pagePosition = newPosition;
        scrollTo();
        log(iframeId, '--');
      };

      const calcOffset = function calcOffset() {
        return {
          x: Number(messageData.width) + offset.x,
          y: Number(messageData.height) + offset.y
        };
      };

      const scrollParent = function scrollParent() {
        if (window.parentIFrame) {
          window.parentIFrame["scrollTo".concat(addOffset ? 'Offset' : '')](newPosition.x, newPosition.y);
        } else {
          warn(iframeId, 'Unable to scroll to requested position, window.parentIFrame not found');
        }
      };

      const offset = addOffset ? getElementPosition(messageData.iframe) : {
        x: 0,
        y: 0
      };
      const newPosition = calcOffset();
      log(iframeId, "Reposition requested from iFrame (offset x:".concat(offset.x, " y:").concat(offset.y, ")"));

      if (window.top !== window.self) {
        scrollParent();
      } else {
        reposition();
      }
    };

    const scrollTo = function scrollTo() {
      if (callback('scrollCallback', pagePosition) !== false) {
        setPagePosition(iframeId);
      } else {
        unsetPagePosition();
      }
    };

    const findTarget = function findTarget(location) {
      const jumpToTarget = function jumpToTarget() {
        const jumpPosition = getElementPosition(target);
        log(iframeId, "Moving to in page link (#".concat(hash, ") at x: ").concat(jumpPosition.x, " y: ").concat(jumpPosition.y));
        pagePosition = {
          x: jumpPosition.x,
          y: jumpPosition.y
        };
        scrollTo();
        log(iframeId, '--');
      };

      const jumpToParent = function jumpToParent() {
        if (window.parentIFrame) {
          window.parentIFrame.moveToAnchor(hash);
        } else {
          log(iframeId, 'In page link #' + hash + ' not found and window.parentIFrame not found');
        }
      };

      const hash = location.split('#')[1] || '';
      const hashData = decodeURIComponent(hash);
      const target = document.getElementById(hashData) || document.getElementsByName(hashData)[0];

      if (target) {
        jumpToTarget();
      } else if (window.top !== window.self) {
        jumpToParent();
      } else {
        log(iframeId, 'In page link #' + hash + ' not found');
      }
    };

    const callback = function callback(funcName, val) {
      return chkCallback(iframeId, funcName, val);
    };

    const actionMsg = function actionMsg() {
      if (settings[iframeId].firstRun) firstRun();

      switch (messageData.type) {
        case 'close':
          closeIFrame(messageData.iframe);
          break;

        case 'message':
          forwardMsgFromIFrame(getMsgBody(6));
          break;

        case 'scrollTo':
          scrollRequestFromChild(false);
          break;

        case 'scrollToOffset':
          scrollRequestFromChild(true);
          break;

        case 'pageInfo':
          sendPageInfoToIframe(settings[iframeId].iframe, iframeId);
          startPageInfoMonitor();
          break;

        case 'pageInfoStop':
          stopPageInfoMonitor();
          break;

        case 'inPageLink':
          findTarget(getMsgBody(9));
          break;

        case 'reset':
          resetIFrame(messageData);
          break;

        case 'init':
          resizeIFrame();
          callback('initCallback', messageData.iframe);
          callback('resizedCallback', messageData);
          break;

        default:
          resizeIFrame();
          callback('resizedCallback', messageData);
      }
    };

    const hasSettings = function hasSettings(iframeId) {
      let retBool = true;

      if (!settings[iframeId]) {
        retBool = false;
        warn(messageData.type + ' No settings for ' + iframeId + '. Message was: ' + msg);
      }

      return retBool;
    };

    const iFrameReadyMsgReceived = function iFrameReadyMsgReceived() {
      Object.keys(settings).forEach(iframeId => {
        trigger('iFrame requested init', createOutgoingMsg(iframeId), document.getElementById(iframeId), iframeId);
      });
    };

    const firstRun = function firstRun() {
      settings[iframeId].firstRun = false;
    };

    let msg = event.data;
    let messageData = {};
    let iframeId = null;

    if (msg === '[iFrameResizerChild]Ready') {
      iFrameReadyMsgReceived();
    } else if (isMessageForUs()) {
      messageData = processMsg();
      iframeId = messageData.id;

      if (!isMessageFromMetaParent() && hasSettings(iframeId)) {
        log(iframeId, "Received: ".concat(msg));

        if (checkIFrameExists() && isMessageFromIFrame()) {
          actionMsg();
        }
      }
    } else if (msg === 'ready') {
      sendHash();
    } else if (msg.setAnchor) {
      window.location.href = "#".concat(event.data.setAnchor);
    } else if (msg.offset) {
      const targetFrame = (0, _jquery.default)('.search-iframe iframe');

      if (targetFrame.length) {
        window.scrollTo(0, targetFrame.offset().top + msg.offset);
      }
    } else {
      info(iframeId, "Ignored: ".concat(msg));
    }
  };

  _exports.iFrameListener = iFrameListener;

  const chkCallback = function chkCallback(iframeId, funcName, val) {
    let func = null;
    let retVal = null;

    if (settings[iframeId]) {
      func = settings[iframeId][funcName];

      if (typeof func === 'function') {
        retVal = func(val);
      } else {
        throw new TypeError(funcName + ' on iFrame[' + iframeId + '] is not a function');
      }
    }

    return retVal;
  };

  _exports.chkCallback = chkCallback;

  const closeIFrame = function closeIFrame(iframe) {
    const iframeId = iframe.id;
    log(iframeId, 'Removing iFrame: ' + iframeId);
    iframe.parentNode.removeChild(iframe);
    chkCallback(iframeId, 'closedCallback', iframeId);
    log(iframeId, '--');
    delete settings[iframeId];
  };

  _exports.closeIFrame = closeIFrame;

  const getPagePosition = function getPagePosition(iframeId) {
    if (pagePosition === null) {
      pagePosition = {
        x: window.pageXOffset !== undefined ? window.pageXOffset : document.documentElement.scrollLeft,
        y: window.pageYOffset !== undefined ? window.pageYOffset : document.documentElement.scrollTop
      };
      log(iframeId, 'Get page position: ' + pagePosition.x + ',' + pagePosition.y);
    }
  };

  _exports.getPagePosition = getPagePosition;

  const setPagePosition = function setPagePosition(iframeId) {
    if (pagePosition !== null) {
      window.scrollTo(pagePosition.x, pagePosition.y);
      log(iframeId, 'Set page position: ' + pagePosition.x + ',' + pagePosition.y);
      unsetPagePosition();
    }
  };

  _exports.setPagePosition = setPagePosition;

  const unsetPagePosition = function unsetPagePosition() {
    pagePosition = null;
  };

  _exports.unsetPagePosition = unsetPagePosition;

  const resetIFrame = function resetIFrame(messageData) {
    const reset = function reset() {
      setSize(messageData);
      trigger('reset', 'reset', messageData.iframe, messageData.id);
    };

    log(messageData.id, "Size reset requested by ".concat(messageData.type === 'init' ? 'host page' : 'iFrame'));
    getPagePosition(messageData.id);
    syncResize(reset, messageData, 'reset');
  };

  _exports.resetIFrame = resetIFrame;

  const setSize = function setSize(messageData) {
    const setDimension = function setDimension(dimension) {
      messageData.iframe.style[dimension] = "".concat(messageData[dimension], "px");
      log(messageData.id, "IFrame (".concat(iframeId, ") ").concat(dimension, " set to ").concat(messageData[dimension], "px"));
    };

    const chkZero = function chkZero(dimension) {
      // FireFox sets dimension of hidden iFrames to zero.
      // So if we detect that set up an event to check for
      // when iFrame becomes visible.
      // Not testable in PhantomJS

      /* istanbul ignore next */
      if (!hiddenCheckEnabled && messageData[dimension] === '0') {
        hiddenCheckEnabled = true;
        log(iframeId, 'Hidden iFrame detected, creating visibility listener');
        fixHiddenIFrames();
      }
    };

    const processDimension = function processDimension(dimension) {
      setDimension(dimension);
      chkZero(dimension);
    };

    const iframeId = messageData.iframe.id;

    if (settings[iframeId]) {
      if (settings[iframeId].sizeHeight) {
        processDimension('height');
      }

      if (settings[iframeId].sizeWidth) {
        processDimension('width');
      }
    }
  };

  _exports.setSize = setSize;

  const syncResize = function syncResize(func, messageData, doNotSync) {
    // Not testable in PhantomJS

    /* istanbul ignore if */
    if (doNotSync !== messageData.type && requestAnimationFrame) {
      log(messageData.id, 'Requesting animation frame');
      requestAnimationFrame(func);
    } else {
      func();
    }
  };

  _exports.syncResize = syncResize;

  const trigger = function trigger(calleeMsg, msg, iframe, id) {
    /**
     * Will wait for an iframe to be ready
     * for DOM manipulation. Just listening for
     * the load event will only work if the iframe
     * is not already loaded. If so, it is necessary
     * to observe the readyState. The issue here is
     * that Chrome will initialize iframes with
     * "about:blank" and set its readyState to complete.
     * So it is furthermore necessary to check if it's
     * the readyState of the target document property.
     * Errors that may occur when trying to access the iframe
     * (Same-Origin-Policy) will be catched and the error
     * function will be called.
     * @param {jquery} $i - The jQuery iframe element
     * @param {function} successFn - The callback on success. Will
     * receive the jQuery contents of the iframe as a parameter
     * @param {function} errorFn - The callback on error
     */
    const onIframeReady = function ($i, successFn, errorFn) {
      try {
        const iCon = $i.first()[0].contentWindow,
              bl = "about:blank",
              compl = "complete";

        const callCallback = () => {
          try {
            const $con = $i.contents();

            if ($con.length === 0) {
              throw new Error("iframe inaccessible");
            }

            successFn($con);
          } catch (e) {
            // accessing contents failed
            errorFn();
          }
        };

        const observeOnload = () => {
          $i.on("load.jqueryMark", () => {
            try {
              const src = $i.attr("src").trim(),
                    href = iCon.location.href;

              if (href !== bl || src === bl || src === "") {
                $i.off("load.jqueryMark");
                callCallback();
              }
            } catch (e) {
              errorFn();
            }
          });
        };

        if (iCon.document.readyState === compl) {
          const src = $i.attr("src").trim(),
                href = iCon.location.href;

          if (href === bl && src !== bl && src !== "") {
            observeOnload();
          } else {
            callCallback();
          }
        } else {
          observeOnload();
        }
      } catch (e) {
        // accessing contentWindow failed
        errorFn();
      }
    };

    const postMessageToIFrame = function postMessageToIFrame() {
      const target = settings[id].targetOrigin;
      log(id, "[".concat(calleeMsg, "] Sending msg to iframe[").concat(id, "] (").concat(msg, ") targetOrigin: ").concat(target));
      iframe.contentWindow.postMessage(msgId + msg, target);
    };

    const iFrameNotFound = function iFrameNotFound() {
      info(id, "[".concat(calleeMsg, "] IFrame(").concat(id, ") not found"));

      if (settings[id]) {
        delete settings[id];
      }
    };

    const chkAndSend = function chkAndSend() {
      if (iframe && 'contentWindow' in iframe && iframe.contentWindow !== null) {
        // Null test for PhantomJS
        postMessageToIFrame();
      } else {
        iFrameNotFound();
      }
    };

    id = id || iframe.id;

    if (settings[id]) {
      chkAndSend();
    }
  };

  _exports.trigger = trigger;

  const createOutgoingMsg = function createOutgoingMsg(iframeId) {
    return iframeId + ':' + settings[iframeId].bodyMarginV1 + ':' + settings[iframeId].sizeWidth + ':' + settings[iframeId].log + ':' + settings[iframeId].interval + ':' + settings[iframeId].enablePublicMethods + ':' + settings[iframeId].autoResize + ':' + settings[iframeId].bodyMargin + ':' + settings[iframeId].heightCalculationMethod + ':' + settings[iframeId].bodyBackground + ':' + settings[iframeId].bodyPadding + ':' + settings[iframeId].tolerance + ':' + settings[iframeId].inPageLinks + ':' + settings[iframeId].resizeFrom + ':' + settings[iframeId].widthCalculationMethod;
  };

  _exports.createOutgoingMsg = createOutgoingMsg;

  const setupIFrame = function setupIFrame(iframe, options) {
    const setLimits = function setLimits() {
      const addStyle = function addStyle(style) {
        if (Infinity !== settings[iframeId][style] && settings[iframeId][style] !== 0) {
          iframe.style[style] = "".concat(settings[iframeId][style], "px");
          log(iframeId, "Set ".concat(style, " = ").concat(settings[iframeId][style], "px"));
        }
      };

      const chkMinMax = function chkMinMax(dimension) {
        if (settings[iframeId]["min".concat(dimension)] > settings[iframeId]["max".concat(dimension)]) {
          throw new Error("Value for min".concat(dimension, " can not be greater than max").concat(dimension));
        }
      };

      chkMinMax('Height');
      chkMinMax('Width');
      addStyle('maxHeight');
      addStyle('minHeight');
      addStyle('maxWidth');
      addStyle('minWidth');
    };

    const newId = function newId() {
      let id = options && options.id || defaults.id + count++;

      if (document.getElementById(id) !== null) {
        id += count++;
      }

      return id;
    };

    const ensureHasId = function ensureHasId(iframeId) {
      if (iframeId === '') {
        iframeId = newId();
        iframe.id = iframeId;
        logEnabled = (options || {}).log;
        log(iframeId, "Added missing iframe ID: ".concat(iframeId, " (").concat(iframe.src, ")"));
      }

      return iframeId;
    };

    const setScrolling = function setScrolling() {
      log(iframeId, "IFrame scrolling ".concat(settings[iframeId].scrolling ? 'enabled' : 'disabled', " for ").concat(iframeId));
      const notScrolling = settings[iframeId].scrolling === false;
      iframe.style.overflow = notScrolling ? 'hidden' : 'auto';
      iframe.scrolling = notScrolling ? 'no' : 'yes';
    }; // The V1 iFrame script expects an int, where as in V2 expects a CSS
    // string value such as '1px 3em', so if we have an int for V2, set V1=V2
    // and then convert V2 to a string PX value.


    const setupBodyMarginValues = function setupBodyMarginValues() {
      if (typeof settings[iframeId].bodyMargin === 'number' || settings[iframeId].bodyMargin === '0') {
        settings[iframeId].bodyMarginV1 = settings[iframeId].bodyMargin;
        settings[iframeId].bodyMargin = "".concat(settings[iframeId].bodyMargin, "px");
      }
    };

    const checkReset = function checkReset() {
      // Reduce scope of firstRun to function, because IE8's JS execution
      // context stack is borked and this value gets externally
      // changed midway through running this function!!!
      const firstRun = settings[iframeId].firstRun;
      const resetRequertMethod = settings[iframeId].heightCalculationMethod in resetRequiredMethods;

      if (!firstRun && resetRequertMethod) {
        resetIFrame({
          iframe,
          height: 0,
          width: 0,
          type: 'init'
        });
      }
    };

    const setupIFrameObject = function setupIFrameObject() {
      if (Function.prototype.bind) {
        settings[iframeId].iframe.iFrameResizer = {
          close: closeIFrame.bind(null, settings[iframeId].iframe),
          resize: trigger.bind(null, 'Window resize', 'resize', settings[iframeId].iframe),
          moveToAnchor: anchor => {
            trigger('Move to anchor', "moveToAnchor:".concat(anchor), settings[iframeId].iframe, iframeId);
          },
          sendMessage: message => {
            message = JSON.stringify(message);
            trigger('Send Message', "message:".concat(message), settings[iframeId].iframe, iframeId);
          }
        };
      }
    }; // We have to call trigger twice, as we can not be sure if all
    // iframes have completed loading when this code runs. The
    // event listener also catches the page changing in the iFrame.


    const init = function init(msg) {
      const iFrameLoaded = function iFrameLoaded() {
        trigger('iFrame.onload', msg, iframe);
        checkReset();
      };

      addEventListener(iframe, 'load', iFrameLoaded);
      trigger('init', msg, iframe);
    };

    const checkOptions = function checkOptions(opts) {
      if (typeof opts !== 'object') {
        throw new TypeError('Options is not an object');
      }
    };

    const copyOptions = function copyOptions(opts) {
      Object.keys(defaults).forEach(option => {
        settings[iframeId][option] = opts.hasOwnProperty(option) ? opts[option] : defaults[option];
      });
    };

    const getTargetOrigin = function getTargetOrigin(remoteHost) {
      return remoteHost === '' || remoteHost === 'file://' ? '*' : remoteHost;
    };

    const processOptions = function processOptions(opts) {
      opts = opts || {};
      settings[iframeId] = {
        firstRun: true,
        iframe,
        remoteHost: iframe.src.split('/').slice(0, 3).join('/')
      };
      checkOptions(opts);
      copyOptions(opts);
      settings[iframeId].targetOrigin = settings[iframeId].checkOrigin === true ? getTargetOrigin(settings[iframeId].remoteHost) : '*';
    };

    const beenHere = function beenHere() {
      return iframeId in settings && 'iFrameResizer' in iframe;
    };

    const iframeId = ensureHasId(iframe.id);

    if (!beenHere()) {
      processOptions(options);
      setScrolling();
      setLimits();
      setupBodyMarginValues();
      init(createOutgoingMsg(iframeId));
      setupIFrameObject();
    } else {
      warn(iframeId, 'Ignored iFrame, already setup.');
    }
  };

  const debouce = function debouce(fn, time) {
    if (timer === null) {
      timer = setTimeout(() => {
        timer = null;
        fn();
      }, time);
    }
  }; // Not testable in PhantomJS

  /* istanbul ignore next */


  _exports.debouce = debouce;

  const fixHiddenIFrames = function fixHiddenIFrames() {
    const checkIFrames = function checkIFrames() {
      const checkIFrame = function checkIFrame(settingId) {
        const chkDimension = function chkDimension(dimension) {
          return settings[settingId].iframe.style[dimension] === '0px';
        };

        const isVisible = function isVisible(el) {
          return el.offsetParent !== null;
        };

        if (isVisible(settings[settingId].iframe) && (chkDimension('height') || chkDimension('width'))) {
          trigger('Visibility change', 'resize', settings[settingId].iframe, settingId);
        }
      };

      Object.keys(settings).forEach(settingId => {
        checkIFrame(settingId);
      });
    };

    const mutationObserved = function mutationObserved(mutations) {
      log('window', "Mutation observed: ".concat(mutations[0].target, " ").concat(mutations[0].type));
      debouce(checkIFrames, 16);
    };

    const createMutationObserver = function createMutationObserver() {
      const target = document.querySelector('body');
      const conf = {
        attributes: true,
        attributeOldValue: false,
        characterData: true,
        characterDataOldValue: false,
        childList: true,
        subtree: true
      };
      const observer = new MutationObserver(mutationObserved);
      observer.observe(target, conf);
    };

    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
    if (MutationObserver) createMutationObserver();
  };

  _exports.fixHiddenIFrames = fixHiddenIFrames;

  const resizeIFrames = function resizeIFrames(event) {
    const resize = function resize() {
      sendTriggerMsg('Window ' + event, 'resize');
    };

    log('window', "Trigger event: ".concat(event));
    debouce(resize, 16);
  }; // Not testable in PhantomJS

  /* istanbul ignore next */


  _exports.resizeIFrames = resizeIFrames;

  const tabVisible = function tabVisible() {
    const resize = function resize() {
      sendTriggerMsg('Tab Visable', 'resize');
    };

    if (document.visibilityState !== 'hidden') {
      log('document', 'Trigger event: Visiblity change');
      debouce(resize, 16);
    }
  };

  _exports.tabVisible = tabVisible;

  const sendTriggerMsg = function sendTriggerMsg(eventName, event) {
    const isIFrameResizeEnabled = function isIFrameResizeEnabled(iframeId) {
      return settings[iframeId].resizeFrom === 'parent' && settings[iframeId].autoResize && !settings[iframeId].firstRun;
    };

    Object.keys(settings).forEach(iframeId => {
      if (isIFrameResizeEnabled(iframeId)) {
        trigger(eventName, event, document.getElementById(iframeId), iframeId);
      }
    });
  };

  _exports.sendTriggerMsg = sendTriggerMsg;

  const setupEventListeners = function setupEventListeners() {
    addEventListener(window, 'message', iFrameListener);
    addEventListener(window, 'resize', () => resizeIFrames('resize'));
    addEventListener(document, 'visibilitychange', tabVisible);
    addEventListener(document, '-webkit-visibilitychange', tabVisible); // Andriod 4.4

    addEventListener(window, 'focusin', () => resizeIFrames('focus')); // IE8-9

    addEventListener(window, 'focus', () => resizeIFrames('focus'));
  };

  _exports.setupEventListeners = setupEventListeners;

  const factory = function factory() {
    const init = function init(options, element) {
      const chkType = function chkType() {
        if (!element.tagName) {
          throw new TypeError('Object is not a valid DOM element');
        } else if (element.tagName.toUpperCase() !== 'IFRAME') {
          throw new TypeError("Expected <IFRAME> tag, found <".concat(element.tagName, ">"));
        }
      };

      if (element) {
        chkType();
        setupIFrame(element, options);
        iFrames.push(element);
      }
    };

    let iFrames;
    setupRequestAnimationFrame();
    setupEventListeners();
    return function iFrameResizeF(options, target) {
      iFrames = []; // Only return iFrames past in on this call

      switch (typeof target) {
        case 'undefined':
        case 'string':
          Array.prototype.forEach.call(document.querySelectorAll(target || 'iframe'), init.bind(undefined, options));
          break;

        case 'object':
          init(options, target);
          break;

        default:
          throw new TypeError("Unexpected data type (".concat(typeof target, ")"));
      }

      return iFrames;
    };
  };

  _exports.factory = factory;

  if (!_jquery.default.fn) {
    info('', 'Unable to bind to jQuery, it is not fully loaded.');
  } else {
    _jquery.default.fn.iFrameResize = function $iFrameResizeF(options) {
      const init = function init(index, element) {
        setupIFrame(element, options);
      };

      return this.filter('iframe').each(init).end();
    };
  }

  exports.factoryExport = factory();
  var _default = exports.factoryExport;
  _exports.default = _default;
});