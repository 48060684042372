define("adt-wss/pods/help/batteries/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      const uint = localStorage.myADT_system_names || '';
      return uint ? "batteries.uint=".concat(uint) : 'batteries';
    }

  });

  _exports.default = _default;
});