define("adt-wss/services/common-passwords", ["exports", "adt-wss-common/utils/http-resources", "adt-wss-common/utils/fp", "adt-wss-common/utils/myadt-helpers"], function (_exports, _httpResources, _fp, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Services/CommonPasswords
   * @prop {Object[]} allPasswords
   */
  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)({
    allPasswords: null
  }), {
    apiService: Ember.inject.service(),
    impersonationData: Ember.inject.service(),
    allPasswords: null,
    actions: {
      addPassword(item) {
        this.updatePassword(item);
      }

    },

    /**
     * Gets common passwords from API.
     * Sets `allPasswords`
     * @instance
     *
     * @type {ServiceCall}
     * @return {ApiResponse}
     */
    getData() {
      this.set('allPasswords', null);
      return this.get('apiService').request({
        resource: _httpResources.myADT.getCommonPasswordData
      }).then(({
        data
      }) => {
        const out = (data || []).map(password => (0, _fp.omix)(password, {
          editMode: false,
          addMode: false,
          existingPassword: password.password
        }));
        return this.set('allPasswords', out.length ? out : null);
      });
    },

    /**
     * Deletes password from list
     * @instance
     *
     * @type {ServiceCall}
     * @param {Object} data
     * @param {String} data.existingPassword
     * @param {String} data.passwordId
     * @param {Boolean} data.manage
     * @param {Boolean} data.irregularOpen
     * @return {ApiResponse}
     */
    removePassword(data) {
      const transactionType = 'PICU';
      const jobComments = 'Deleted common PIC';
      return this.get('apiService').request({
        resource: _httpResources.myADT.removeCommonPassword,
        data: {
          password: '',
          existingPassword: data.existingPassword,
          passwordId: data.passwordId,
          manage: data.manage,
          irregularOpen: data.irregularOpen
        }
      }).then(() => {
        this.get('impersonationData').createIncidentOrIssue(transactionType, jobComments);
      }).finally(() => this.getData());
    },

    /**
     * Updates or adds password.
     * Adds if `data.existingPassword` and `data.passwordId` are null
     * @instance
     *
     * @type {ServiceCall}
     * @param {Object} data
     * @param {String} data.password
     * @param {String} data.existingPassword
     * @param {String} data.passwordId
     * @param {Boolean} data.manage
     * @param {Boolean} data.irregularOpen
     * @return {ApiResponse}
     */
    updatePassword(data) {
      const transactionType = 'PICU';
      const jobComments = 'Updated common PIC';
      return this.get('apiService').request({
        resource: _httpResources.myADT.updateCommonPassword,
        data: {
          password: data.password,
          existingPassword: data.existingPassword,
          passwordId: data.passwordId,
          manage: data.manage,
          irregularOpen: data.irregularOpen
        }
      }).then(() => {
        this.get('impersonationData').createIncidentOrIssue(transactionType, jobComments);
        return _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'ManageSecurityPasswords', 'ChangeCurrentPassword']);
      }).finally(() => this.getData());
    }

  });

  _exports.default = _default;
});