define("adt-wss/services/manage-account-service", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/fp", "adt-wss/services/app-dispatcher"], function (_exports, _myadtHelpers, _fp, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const clearCacheArgs = {
    locations: null,
    locationGroups: null
  };
  /**
   * Service to manage locations
   * @module Services/ManageAccount
   * @prop {Location[]} locations
   * @prop {LocationGroup[]} locationGroups
   */

  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)(clearCacheArgs), {
    apiService: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    locations: null,
    locationGroups: null,

    /**
     * Adds reference to appropriate location group
     * to each location in response to `getLocations`
     * @param {Location[]} data
     * @param {LocationGroup[]} locationGroups
     * @returns {Object[]} locations
     */
    processLocations(data, locationGroups) {
      const locations = (data || []).map(loc => {
        const webAddressEquals = (0, _fp.eq)('webAddressId');

        const findGroup = g => (g.locations || []).some(webAddressEquals(loc));

        const group = (locationGroups || []).find(findGroup);
        return (0, _fp.omix)(loc, {
          locationGroup: group
        });
      });
      return locations.sort((l, r) => {
        if (!l.locationName) return 1;
        if (!r.locationName) return -1;
        return l.locationName.toLowerCase() < r.locationName.toLowerCase() ? -1 : 1;
      });
    },

    /**
     * Gets locations from API
     * Runs `processLocations` and populates `locations`
     * with the return
     * @returns {Promise}
     */
    getLocations() {
      this.set('locations', null);
      return this.get('apiService').myADT.getLocations().then(({
        data
      }) => {
        const locations = this.processLocations(data, this.get('locationGroups'));
        this.set('locations', locations);
        return locations;
      });
    },

    /**
     * Gets locations from API and populates `locationGroups`
     * @returns {Promise}
     */
    getLocationGroups() {
      this.set('locationGroups', null);
      return this.get('apiService').myADT.getLocationGroups().then(({
        data
      }) => {
        this.set('locationGroups', data);
        return data;
      });
    },

    /**
     * Runs `getLocationGroups` and `getLocations`
     * synchronously
     */
    getLocationsInfo() {
      return this.getLocationGroups().then(() => this.getLocations()).catch(() => this.getLocations());
    },

    /**
     * Deletes location, runs `getLocationsInfo` on resolve
     * Sets new default if default was deleted
     * @param {Int} webAddressId
     * @return {Promise}
     */
    deleteSite(webAddressId) {
      const currentLocation = this.get('currentLocation');
      return this.get('apiService').myADT.deleteSite({
        webAddressId
      }).then(({
        data
      }) => {
        const eventTagInfo = {
          eventTypeId: 'EDITLOCS',
          subEventTypeId: 'DELETE'
        };
        this.get('ET').sendEventTag(eventTagInfo);
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Manage Location', 'Delete Location', "Web Addr ID: ".concat(webAddressId)]);
        return data;
      }).then(data => {
        if (data.wasCurrent) {
          return currentLocation.changeAddress({
            webAddressId: data.newWebAddressId
          });
        }

        return true;
      }).then(() => currentLocation.getAddresses()).then(() => this.getLocationsInfo()).then(() => {
        const locations = this.get('locations');

        if (locations && locations.length && !locations.some(l => l.isDefault)) {
          return this.saveSite({
            webAddressId: locations[0].webAddressId,
            phone1: locations[0].sitePhone1,
            phone2: locations[0].sitePhone2,
            email: locations[0].loginEmail,
            isDefault: true
          });
        }

        return true;
      });
    },

    /**
     * Sets site as default, runs `getLocationsInfo` on resolve
     * @param {Object} site
     * @param {String} site.webAddressId
     * @param {String} site.phone1
     * @param {String} site.phone2
     * @param {String} site.email
     * @param {Boolean} site.isDefault
     */
    saveSite(site) {
      const phone1 = (0, _myadtHelpers.removePhoneMask)(site.phone1);
      const phone2 = (0, _myadtHelpers.removePhoneMask)(site.phone2);

      if (site.isDefault) {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Manage Location', 'SetDefault', "Web Addr ID: ".concat(site.webAddressId)]);
      }

      return this.get('apiService').myADT.saveSite((0, _fp.omix)(site, {
        phone1,
        phone2
      })).then(() => this.getLocationsInfo());
    },

    /**
     * Edit or delete the location name, runs `getLocationsInfo` on resolve
     * @param {String} webAddressId
     * @param {String} [locationName] passing empty will delete location name
     */
    editLocationName(webAddressId, locationName) {
      return this.get('apiService').myADT.editLocationName({
        webAddressId,
        locationName
      }).then(() => this.getLocationsInfo());
    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      return this.getLocationsInfo();
    },

    init() {
      this._super(...arguments);

      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    }

  });

  _exports.default = _default;
});