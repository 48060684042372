define("adt-wss/pods/components/dashboard/account/on-site-assessment/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/common-fsm-state", "adt-wss/services/app-dispatcher"], function (_exports, _withFsm, _commonFsmState, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProps = {
    systemPlanDetailsData: null
  };
  /**
   * @module Components/Dashboard/Account/OnSiteAssessment
   * @example
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    currentLocation: Ember.inject.service(),
    promotionData: Ember.inject.service(),
    appDispatcher: Ember.inject.service(),
    systemPlanDetails: Ember.computed.readOnly('promotionData.systemPlanDetails'),
    showOnSiteAssessmentCharge: Ember.computed.gt('systemPlanDetails.oac', 0),
    onSiteCharge: Ember.computed.readOnly('systemPlanDetails.oac'),
    hasQSP: Ember.computed.readOnly('systemPlanDetails.hasQsp'),
    classNames: ['on-site-assessment-widget', 'on-site-widget-section'],

    init(...args) {
      this._super(...args);
    }

  });

  _exports.default = _default;
});