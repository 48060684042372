define("adt-wss/pods/components/dashboard/account/service-appointments-notifications/component", ["exports", "adt-wss/pods/components/dashboard/account/billing-reminders/component", "ember-copy", "adt-wss-common/mixins/with-fsm", "adt-wss-common/classes/status-message", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/state-comparator", "adt-wss/pods/components/dashboard/account/service-appointments-notifications/constants", "adt-wss/pods/components/dashboard/account/communication-preferences/constants"], function (_exports, _component, _emberCopy, _withFsm, _statusMessage, _myadtHelpers, _emberHelpers, _stateComparator, _constants, _constants2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line import/order */
  const defaultProps = {
    emails: [],

    /* eslint-disable-next-line max-len */
    updateServiceAppointmentsPrefs() {
      console.error('Please pass updateServiceAppointmentsPrefs method to dashboard/account/service-appointments-notifications component');
    }

  };
  /**
   * @module Components/Dashboard/Account/ServiceAppointmentsNotifications
   * @prop {Object[]} emails
   * @prop {Function} updateServiceAppointmentsPrefs
   * @example
   * {@lang xml} {{ dashboard/account/service-appointments-notifications
  		emails=[{ email: 'test@test.com', pending: null }]
  		updateServiceAppointmentsPrefs=(action 'update')
  		phoneConsents=currentLocation.phoneConsents
  }}
   */

  var _default = _component.default.extend(_withFsm.default, defaultProps, {
    consented: (0, _emberHelpers.computedApplyFunction)(pc => pc ? pc.phone1SrvcApptTextConsent || pc.phone2SrvcApptTextConsent : false, 'phoneConsents'),

    /**
     * Updates data if data has passed the validation
     * Calls `/api/v2/auth/pmoc` endpoint
     *
     * @function
     * @param {Object} data
     * @param {Object[]} data.emails
     * @param {String} data.emails[].pending
     * @param {String} data.emails[].email
     * @returns {Promise}
     */
    [_constants.UPDATE_DATA_ACTION]({
      emails: originalEmails,
      primaryEmailsSubscription,
      phoneConsents,
      emailsSchema
    } = {
      emails: [],
      primaryEmailsSubscription: false,
      phoneConsents: {}
    }) {
      const {
        fsm,
        opted,
        primaryEmail
      } = this.getProperties('fsm', 'opted', 'primaryEmail');
      let emails = originalEmails;

      if (primaryEmail) {
        const subEmails = emails.filter(e => e.email !== primaryEmail.email);
        emails = [primaryEmail].concat(subEmails);
      }

      return (0, _stateComparator.compareStatesEquality)(originalEmails, () => this.updateServiceAppointmentsPrefs(emails, {
        primaryEmailsSubscription,
        originalEmails
      }), emailsSchema).then((0, _stateComparator.compareStatesEquality)(phoneConsents, data => this.updateServiceAppointmentConsents(data))).then(({
        stub
      } = {
        stub: false
      }) => {
        this.closeWindowHandler();
        return fsm.dispatch(_constants.RESOLVE_ACTION, stub, true);
      }).then(() => (0, _component.determOptTypeEvent)(opted, emails, {
        optInEventHandler: () => this.sendOptTypeTrackingEvents(true),
        optOutEventHandler: () => this.sendOptTypeTrackingEvents(false)
      }));
    },

    /**
     * Updates data if data has passed the validation
     * Calls `/api/v2/auth/pmoc` endpoint
     *
     * @function
     * @param {Object} data
     * @param {Object[]} data.emails
     * @param {String} data.emails[].pending
     * @param {String} data.emails[].email
     * @returns {Promise}
     */
    [_constants.UPDATE_EMAIL_ACTION]({
      emails,
      primaryEmailsSubscription
    } = {
      emails: [],
      primaryEmailsSubscription: false
    }) {
      const {
        fsm,
        opted,
        primaryEmail
      } = this.getProperties('fsm', 'opted', 'primaryEmail');

      if (primaryEmail) {
        const subEmails = emails.filter(e => e.email !== primaryEmail.email);
        emails = [primaryEmail].concat(subEmails);
      }

      return this.updateServiceAppointmentsPrefs(emails, {
        primaryEmailsSubscription
      }).then(() => fsm.dispatch(_constants.RESOLVE_ACTION, null, true)).then(() => (0, _component.determOptTypeEvent)(opted, emails, {
        optInEventHandler: () => this.sendOptTypeTrackingEvents(true),
        optOutEventHandler: () => this.sendOptTypeTrackingEvents(false)
      }));
    },

    /**
     * Updates data if data has passed the validation
     * Calls `/api/v2/auth/pmoc` endpoint
     *
     * @function
     * @param {Object} data
     * @param {Object[]} data.emails
     * @param {String} data.emails[].pending
     * @param {String} data.emails[].email
     * @returns {Promise}
     */
    [_constants.UPDATE_PHONE_ACTION](phoneConsents) {
      return this.updateServiceAppointmentConsents(phoneConsents).then(() => this.get('fsm').dispatch(_constants.RESOLVE_ACTION, null, true));
    },

    /**
     * Sends GA tracking
     * Triggered on `UPDATE_DATA_ACTION`
     *
     * @function
     * @returns {undefined}
     */
    sendOptTypeTrackingEvents(adding) {
      if (adding) {
        return (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Preferences', 'Opt-in Service Appointments Click', 'Service Appointments Opt-in']);
      }

      return (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Preferences', 'Opt-out Service Appointments Click', 'Service Appointments Opt-out']);
    },

    /**
     * Subscribes only primary emails
     * Calls `UPDATE_DATA_ACTION` with primary emails argument.
     *
     * @function
     * @returns {Promise}
     */
    subscribePrimaryPhone() {
      const {
        phoneConsents,
        fsm
      } = this.getProperties('phoneConsents', 'fsm');

      if (phoneConsents.phone1 && phoneConsents.phone1Type === 'Mobile') {
        Ember.set(phoneConsents, 'phone1SrvcApptTextConsent', true);
        return fsm.dispatch(_constants.UPDATE_PHONE_ACTION, phoneConsents).then(() => fsm.dispatch(_constants.RESOLVE_ACTION, null, true)).catch(results => {
          this.alert((0, _myadtHelpers.genericErrorHandler)(results).map(a => new _statusMessage.default(a, 'error')));
          return fsm.dispatch(_constants.REJECT_ACTION);
        });
      }

      return Promise.resolve(this.actions.openModalWindow.call(this));
    },

    actions: {
      /**
       * Opens modal window
       *
       * @function
       */
      openModalWindow() {
        const {
          emails: origEmails,
          primaryEmail
        } = this.getProperties('emails', 'primaryEmail');

        if (origEmails.length) {
          const em = origEmails.filter(e => e.email !== primaryEmail.email);
          this.set('emails', em);
        }

        const emailsForEditing = (0, _emberCopy.copy)(this.get('emails'), true) || [];
        const {
          mwd,
          fsm
        } = this.getProperties('mwd', 'fsm');
        const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);

        const closeModal = () => this.closeWindowHandler();

        const onEnter = () => {
          const emails = this.get('emailsForEditing');
          const {
            validator: val,
            newPhoneConsents: phoneConsents
          } = this.getProperties('validator', 'newPhoneConsents');
          const validationFailed = val.validate({
            emails: (emails || []).filter(i => !i.deleted).map(i => i.email)
          });

          if (validationFailed) {
            return false;
          }

          return fsm.dispatch(_constants.UPDATE_DATA_ACTION, {
            emails,
            phoneConsents,
            emailsSchema: (0, _stateComparator.getSchema)(emails)
          }).catch(results => showErrors(results, _constants.REJECT_ACTION));
        };

        this.set('emailsForEditing', (0, _stateComparator.generateStateSchemaMiddleware)(emailsForEditing));
        this.set('newPhoneConsents', (0, _stateComparator.generateStateSchemaMiddleware)((0, _emberCopy.copy)(this.get('phoneConsents'), true)));
        mwd.show({
          title: 'Service Appointment',
          name: 'preferences/service-appointments',
          body: this,
          onClose: closeModal,
          onEnter,
          isFooterLine: false,
          disclaimer: this.get('showPhoneFields') ? _constants2.DISCLAIMER_MESSAGE : null,
          bigCallback: true,
          actions: [{
            caption: 'Cancel',
            stl: 'outline',
            callback: closeModal
          }, {
            caption: 'Save',
            callback: onEnter
          }]
        });
      }

    }
  });

  _exports.default = _default;
});